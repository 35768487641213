import { isBetweenInclusive } from "@/utils/numbers";
import { SellCategoryCalculationParameter } from "@/utils/sell-category-calculation-parameter";

export class SellCategory {
	Name?:string;
	Active:boolean;
	CalculateViaPriceClause:boolean;
	CalculateViaCurrentProfitPercentage:boolean;
	CalculateViaParameters:boolean;
	CalculationParameters:SellCategoryCalculationParameter[] = [];
	LowerHandleValue:number;
	UpperHandleValue:number;

	constructor(data:any) {
		this.Name = data.Name;
		this.Active = data.Active;
		this.CalculateViaPriceClause = data.CalculateViaPriceClause;
		this.CalculateViaCurrentProfitPercentage = data.CalculateViaCurrentProfitPercentage;
		this.CalculateViaParameters = data.CalculateViaParameters;
		if (data.CalculationParameters) {
			this.CalculationParameters = data.CalculationParameters.map((c:any) => new SellCategoryCalculationParameter(c));
		}
		this.LowerHandleValue = data.LowerHandleValue;
		this.UpperHandleValue = data.UpperHandleValue;
	}

	getJSON() {
		return {
			Name: this.Name,
			Active: this.Active,
			CalculateViaPriceClause: this.CalculateViaPriceClause,
			CalculateViaCurrentProfitPercentage: this.CalculateViaCurrentProfitPercentage,
			CalculateViaParameters: this.CalculateViaParameters,
			CalculationParameters: this.CalculationParameters.map(c => c.getJSON()),
			LowerHandleValue: this.LowerHandleValue,
			UpperHandleValue: this.UpperHandleValue
		};
	}

	isValid():boolean {
		if (!this.CalculateViaParameters) {
			return true;
		}

		for (let i = 0; i < this.CalculationParameters.length; i++) {
			let param = this.CalculationParameters[i];
			if (!param.isValid()) {
				return false;
			}

			for (let c = 0; c < i; c++) {
				let thisParam = this.CalculationParameters[c];
				if (isBetweenInclusive(param.LowerValue, thisParam.LowerValue, thisParam.UpperValue)) {
					return false;
				}

				if (isBetweenInclusive(param.UpperValue, thisParam.LowerValue, thisParam.UpperValue)) {
					return false;
				}
			}
		}

		return true;
	}

	calculateProfitBasedOnParameters(buyPrice:number):number {
		if (!this.CalculateViaParameters) {
			throw new Error("Calculate via parameters not enabled");
		}
		for (let param of this.CalculationParameters) {
			if (isBetweenInclusive(buyPrice, param.LowerValue, param.UpperValue)) {
				return param.ProfitPercentage;
			}
		}
		return 0;
	}
}