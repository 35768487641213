
import Vue from "vue";
import { ISelectItem } from "winfakt-vue-components";
import Currency from "@/models/base/currency";
import {CurrencyService} from "@/services/currency-service";

export default Vue.extend({
	props: {
		value: Number,
		fancy: Boolean,
		light: Boolean,
		label: String,
		up: Boolean,
		selectWidth: String,
		activeOnly: Boolean,
		long: Boolean
	},
	computed: {
		currencies():Currency[] {
			return CurrencyService.getCurrencies();
		},
		items(): ISelectItem<number>[] {
			return this.currencies.map(c => {
				return {
					text: c.Prefix + (this.long ? "-"+c.Name : ""),
					value: c.ID
				} as ISelectItem<number>;
			});
		},
		computedLabel(): string {
			return this.label || this.$ct("label").toString();
		},
	},
	methods: {
		onInput(newValue:number) {
			if (newValue == this.value) return;
			this.$emit("input", newValue);
		}
	},
	i18nEx: {
		componentPrefix: "components.Currencies"
	}
});
