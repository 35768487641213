import { ServerConfig } from "@/config/config";
import { Axios } from "@/utils/axios";
import { advancedSearch } from "@/utils/browse/browse";
import { IGetResponse } from "@/utils/get-response";
import { IQueryParamsWithOptionalView } from "@/utils/query-params";
import { View } from "@/models/view";
import { FetchedTotal } from "@/utils/views/fetched-total";
import { DiverseLedger } from "@/models/base/diverse-ledger";
import { getModel } from "@/utils/models/model";

export class DiverseLedgerServiceClass {
	get diverseLedgersUrl(): string {
		return ServerConfig.host + "/diverse-ledgers";
	}

	async getSettings() {
		let result = await Axios.get(ServerConfig.host + "/browse/16", {
			params: {}
		});
		return result.data;
	}
	async get(id:number): Promise<DiverseLedger> {
		let result = await Axios.get(this.diverseLedgersUrl + "/" + id);
		return new DiverseLedger(result.data);
	}

	async getByGroupID(id:number): Promise<DiverseLedger[]> {
		let result = await Axios.get(this.diverseLedgersUrl + "/get-by-group-id/" + id);
		return result.data.map((s: any) => new DiverseLedger(s));
	}

	async getNextGroupID(): Promise<number> {
		let result = await Axios.get(this.diverseLedgersUrl + "/next-group-id");
		let d = result.data;
		return d.GroupID;
	}

	async getDiverseLedgers(query: IQueryParamsWithOptionalView): Promise<IGetResponse<DiverseLedger>> {
		let result = await advancedSearch(query, this.diverseLedgersUrl, getModel("DiverseLedger"));
		result.data.data = result.data.data.map((s: any) => new DiverseLedger(s));
		return result.data;
	}


	async getTotals(view:View):Promise<FetchedTotal[]>{
		let result = await Axios.get(`${this.diverseLedgersUrl}/view/${view.ID}/totals`);
		return (result.data || []).map((d:any)=>new FetchedTotal(d));
	}

	async delete(ledger: DiverseLedger[]): Promise<void> {
		await Axios.delete(this.diverseLedgersUrl, {
			data: ledger.map(p => p.GroupID)
		});
	}

	async saveLedgers(groupId:number, ledgers: DiverseLedger[]): Promise<void> {
		for (let ledger of ledgers) {
			ledger.GroupID = groupId;
		}
		await Axios.post(`${this.diverseLedgersUrl}/set-by-group-id/${groupId}`, ledgers.map(l=>l.getJSON()));
	}

};


export const DiverseLedgerService = new DiverseLedgerServiceClass();