
import { Browse } from "@/models/browse";
import { HumanFilter } from "@/utils/human-filter";
import Vue from "vue";
import AppFilterEdit from "../models/HumanFilterEdit.vue";
import { IModel } from "@/utils/models/model-interface";

export default Vue.extend({
	props: {
		filter: {
			type: Object as () => HumanFilter
		},
		edit: {
			type: Boolean
		},
		browse: {
			type: Object as () => Browse
		},
		model: {
			type: Object as ()=>IModel,
			required: true
		},
		removeIsOrOption: Boolean
	},
	computed: {
		title(): string {
			if (this.edit) {
				return "add-filter";
			}
			return "edit-filter";
		},
	},
	methods: {
		cancel() {
			this.$emit("cancel");
		},
		save() {
			this.$emit("save");
		},
	},
	components: {
		AppFilterEdit,
	},
	i18nEx: {
		componentPrefix: "components.view-editor"
	}
});
