
import Vue from "vue";
import { ISelectItem } from "winfakt-vue-components";
import { Unit } from "../../models/unit";
import { UnitService } from "../../services/unit-service";

export default Vue.extend({
	data() {
		return {
			input: "" as String,
			units: [] as Unit[],
		};
	},
	props: {
		value: {
			type: [Number, Object as () => Unit]
		},
		fancy: Boolean,
		label: String,
		nullable: {
			type: Boolean,
			default: true
		},
		useIds: Boolean
	},
	computed: {
		items(): ISelectItem<Unit | null>[] {
			let result: ISelectItem<Unit | null>[] = this.matchedUnits.map(u => {
				return {
					text: u.Name.getTranslation(this.$i18n.locale),
					value: u
				};
			});
			return result;
		},
		selectedValue(): String | null {
			let result = this.units.find(u => u.ID == this.value) as Unit || null;
			if (!result) {
				return null;
			}
			return result.Name.getTranslation(this.$i18n.locale);
		},
		matchedUnits(): Unit[] {
			return this.units.filter(c => c.Name.getTranslation(this.$i18n.locale).toString().startsWith(this.input.toLowerCase()));
		},
	},
	watch: {
		"selectedValue"() {
			if (this.selectedValue) {
				this.input = this.selectedValue;
			}
		}
	},
	methods: {
		onSelect(event: any | null) {
			return this.$emit("select", event.value as Unit);
		},
		onInput(event: Unit | null) {
			if (event == null) {
				return this.$emit("input", null);
			}
			if (this.useIds) {
				return this.$emit("input", event.ID);
			}
			return this.$emit("input", event);
		},
		onFocus() {
			this.$emit("focus");
		},
		onBlur() {
			//check if exists
			let result = this.units.find(u => u.Name.getTranslation(this.$i18n.locale).toString().toLowerCase() == this.input.toLowerCase()) as Unit || null;
			if (result) {
				this.$emit("select", result as Unit);
			}else{
				this.$emit("blur", this.input);
			}
		},
		onKeyup(event: KeyboardEvent) {
			this.$emit("keyup", event);
		}
	},
	mounted() {
	},
	created() {
		this.units = UnitService.getUnits();
	}
});
