export class HighlightStyle {
	BackgroundColor:string = "#00000000"
	Color:string = "#000000"
	Bold:boolean = false;
	Italic:boolean = false;
	Underline:boolean = false;

	public constructor(data?:any) {
		if (!data) return;

		this.BackgroundColor = data.BackgroundColor;
		this.Color = data.Color;
		this.Bold = data.Bold;
		this.Italic = data.Italic;
		this.Underline = data.Underline;
	}

	public getJSON(){
		return {
			BackgroundColor: this.BackgroundColor,
			Color: this.Color,
			Bold: this.Bold,
			Italic: this.Italic,
			Underline: this.Underline,
		};
	}

	public getStyle():any{
		return {
			backgroundColor: this.BackgroundColor,
			color: this.Color,
			fontWeight: this.Bold ? "bold" : "normal",
			textDecoration: this.Underline ? "underline" : "inherit",
			fontStyle: this.Italic ? "italic" : "normal"
		};
	}
}