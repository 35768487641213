
import Vue from "vue";
import { ISelectItem } from "winfakt-vue-components";
import { DossierConfigService } from "../services/dossier-config-service";
export default Vue.extend({
	props: {
		value: Array as ()=> number[],
		fancy: Boolean,
		label: [Object, String],
	},
	computed: {
		items():ISelectItem<number>[] {
			let config = DossierConfigService.getJournalsConfig();
			let result:ISelectItem<number>[] = config.getIds().map((id) => {
				return {
					text: config.getJournalName(id),
					value: id
				};
			});
			return result;
		}
	},
	methods: {
		onInput(value:number[]) {
			this.$emit("input", value);
		}
	}
});
