import { i18n } from "@/setup/i18n-setup";
import { TranslateResult } from "vue-i18n";
import { HumanFilter } from "../human-filter";
import { Model } from "./model";
import { IModel } from "./model-interface";

export class NamedFilter{
	public name:string;
	public canBeUsedAsDefaultViewFilter:boolean = false
	private filterToStringFunc:(filter:HumanFilter)=>TranslateResult
	private componentNameOverride:string = ""

	public constructor(data:{name:string, filterToString:(filter:HumanFilter)=>TranslateResult, canBeUsedAsDefaultViewFilter?:boolean, componentName?:string}){
		this.name = data.name;
		this.filterToStringFunc = data.filterToString;
		this.canBeUsedAsDefaultViewFilter = data.canBeUsedAsDefaultViewFilter || false;
		if (data.componentName){
			this.componentNameOverride = data.componentName;
		}
	}

	public filterToString(filter:HumanFilter):TranslateResult{
		return this.filterToStringFunc(filter);
	}

	public getFilterEditComponentName(model:Model):string {
		if (this.componentNameOverride) {
			return this.componentNameOverride;
		}
		return `${model.modelName.toLowerCase()}-${this.name}`;
	}

	public getDisplayName(model:IModel):TranslateResult {
		return i18n.t(`models.friendlyNames.${model.getName()}.${this.name}`);
	}
}
