import { SaleLayout } from "@/utils/sale-layouts/sale-layout";

export class SaleLayoutHistory {
	ID:number = 0;
	LayoutID:number = 0;
	DateCreated:Date = new Date()
	Data:SaleLayout = new SaleLayout();

	constructor(data?:any) {
		if (!data) {
			return;
		}
		this.ID = data.ID;
		this.LayoutID = data.LayoutID;
		this.DateCreated = new Date(data.DateCreated);
		this.Data = new SaleLayout(data.Data);
	}
}

