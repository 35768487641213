
import Vue from "vue";
import { Model } from "../../utils/models/model";
import { AskedFilter } from "../../utils/report-engine-settings/asked-filter";
import AppColumnSelect from "@/components/models/ColumnSelect.vue";
import { ModelNamedProperty } from "../../utils/models/named-property";
import { ISelectItem } from "winfakt-vue-components";
import { getValidOperatorsForFieldType, HumanFilter } from "@/utils/human-filter";
import { NamedFilter } from "@/utils/models/named-filter";
import { ModelPropertyType } from "@/utils/models/model-field";
import AppAskedFilterEditValue from "./AskedFilterEditValue.vue";
import { Browse } from "@/models/browse";
import AppCustomFilterSelector from "@/components/models/CustomFilterSelector.vue";

export default Vue.extend({
	props: {
		value: {
			type: Object as () => AskedFilter,
			required: true
		},
		model: {
			type: Object as () => Model,
			required: true
		},
		browse: {
			type: Object as ()=>Browse
		}
	},
	computed: {
		selectedField():ModelNamedProperty | undefined{
			return this.model.getFilterField(this.value.Field);
		},
		fieldPath():string{
			if (!this.selectedField) return "";
			return this.selectedField.path;
		},
		namedFilter():NamedFilter | undefined{
			return this.model.getAvailableNamedFilters().find(n=>n.name == this.value.Field);
		},
		isNamedFilter():boolean{
			return this.namedFilter != undefined;
		},
		confirmDisabled():boolean {
			return this.value.Field == "";
		},
		operators():ISelectItem<string>[]{
			if (!this.selectedField) return [];
			let operators = getValidOperatorsForFieldType(this.selectedField.field);
			return operators.map((op) => {
				return {
					text: this.$t("common.filters." + HumanFilter.getTranslationOperator(op)).toString(),
					value: op
				};
			});
		},
		valueAmount(): number {
			if (!this.selectedField) return 0;
			return HumanFilter.getValueAmount(this.value.Operator);
		},
		isVariableLength():boolean {
			return this.value.Operator == "in" || this.value.Operator == "not in";
		},
		selectedFieldType():ModelPropertyType{
			if (!this.selectedField){
				return "string";
			}
			return this.selectedField.field.type;
		},
		defaultFieldValue():string {
			if (this.selectedFieldType == "number") {
				return "";
			}
			if (this.selectedFieldType == "boolean") {
				return "false";
			}
			if (this.selectedFieldType == "date") {
				return new Date().toJSON();
			}
			return "";
		},
		fieldType():string {
			return this.value.getFilterValueType(this.model, this.browse);
		}
	},
	watch: {
		valueAmount: {
			handler(){
				this.value.Values = [];
				this.value.Labels = [];
				for (let i = 0; i < this.valueAmount; i++){
					this.value.Values.push(this.defaultFieldValue);
					this.value.Labels.push("");
				}
			}
		},
		defaultFieldValue(currentValue:string, prevValue:string){
			if (prevValue == currentValue) return;
			for (let i = 0; i < this.value.Values.length; i++){
				this.value.Values[i] = currentValue;
			}
		},
		selectedField: {
			immediate: true,
			handler(){
				if (!this.selectedField) return;
				this.value.FieldType = this.selectedField.field.type;
			}
		}
	},
	methods: {
		cancel() {
			this.$emit("cancel");
		},
		confirm() {
			this.$emit("confirm");
		},
		async onColumnSelect(value:string){
			this.value.Field = value;
			if (this.isNamedFilter) {
				this.value.FieldType = `named-filter/${this.model.getName()}/${this.value.Field}`;
			}else if (this.selectedField){
				this.value.FieldType = this.selectedField.field.type;
			}else{
				this.value.FieldType = "";
			}

		}
	},
	components: {
		AppColumnSelect,
		AppAskedFilterEditValue,
		AppCustomFilterSelector
	},
	i18nEx: {
		componentPrefix: "components.reportengine"
	}
});
