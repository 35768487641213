
import Vue from "vue";
import AppColumnSelectEditNode from "./ColumnSelectEditNode.vue";
import { IModel } from "../../utils/models/model-interface";
import { Browse } from "@/models/browse";
import { UserConfigService } from "@/services";
import { BrowseFavouriteColumns } from "@/models/browse-favourite-columns";

export default Vue.extend({
	props: {
		value: {
			type: String,
		},
		model: {
			type: Object as () => IModel,
			required: true
		},
		label: {
			type: String
		},
		filterText: {
			type: String
		},
		filterableOnly: {
			type: Boolean
		},
		isServerColumn: Boolean,
		nummericOnly: Boolean,
		browse: {
			type: Object as ()=>Browse
		},
		ignoredFields: {
			type: Array as ()=>string[],
			default: ()=>[]
		},
		favourites: Object as ()=>BrowseFavouriteColumns,
		showFavourites: Boolean,
		previewValue: {
			type: Object as any
		},
		isInitialRender: Boolean
	},
	computed: {
		ignoredPaths():string[] {
			return [...this.model.getIgnoredFields(), ...this.ignoredFields];
		},
		prefix():string {
			if (this.filterableOnly){
				return "this";
			}
			return "";
		},
		canFavourite():boolean {
			if (!this.favourites) return false;
			return UserConfigService.hasCurrentUserPermission((p)=>false);
		}
	},
	methods: {
		onInput(event:string) {
			this.$emit("input", event);
		},
		onName(name:string) {
			this.$emit("name", name);
		},
		async scrollToSelected(){
			await this.$nextTick();
			if (!this.value) return;
			let scroller = (this.$refs.scroller as Vue);
			if (!scroller) return;
			let els = scroller.$el.getElementsByClassName("active");
			for (let el of els){
				if (!el.classList.contains("w-list-item")) continue;
				(scroller as any).scrollTo(el);
				return;
			}
		}
	},
	mounted(){
		this.scrollToSelected();
	},
	components: {
		AppColumnSelectEditNode
	}
});
