import { model } from "@/utils/models/decorator-model";
import { field } from "@/utils/models/decorator-field";

@model("CrmHistoryOffer")

export default class CrmHistoryOffer {

	@field("string")
	public Message: string = "";
	public SaleID: number = 0;

	constructor(data?: any) {
		if (!data) return;
		this.Message = data.Message;
		this.SaleID = data.SaleID;
	}


	getJSON() {
		return {
			Message: this.Message,
			SaleID: this.SaleID,
		};
	}
}
