import { ReminderLayout } from "@/utils/reminder-layouts/reminder-layout";

export class ReminderLayoutConf {
	ID:number = 0;
	Name:string = "";
	Data:ReminderLayout = new ReminderLayout();

	constructor(data?:any) {
		if (!data) {
			return;
		}
		this.ID = data.ID;
		this.Name = data.Name;
		this.Data = new ReminderLayout(data.Data);
	}

	getJSON() {
		return {
			ID: this.ID,
			Name: this.Name,
			Data: this.Data.getJSON(),
		};
	}
}
