
import Vue from "vue";
import { ISelectItem } from "winfakt-vue-components";
import { DossierConfigService } from "../services/dossier-config-service";
export default Vue.extend({
	props: {
		value: Number,
		fancy: Boolean,
		label: [Object, String],
		noJournalOption: Boolean,
		noJournalOptionLabel: {
			type: String,
			default: ""
		},
		filterToJournalsWithSaleNumber: Boolean,
		filterToJournalsWithPurchaseNumber: Boolean,
		disabled: Boolean
	},
	computed: {
		items():ISelectItem<number>[] {
			let config = DossierConfigService.getJournalsConfig();
			let configIds = config.getIds();
			if (this.filterToJournalsWithSaleNumber) {
				configIds = configIds.filter(c=>config.getJournalConfig(c).SellFriendlyNumberConfig != null || c == 1);
			}
			if (this.filterToJournalsWithPurchaseNumber) {
				configIds = configIds.filter(c=>config.getJournalConfig(c).PurchaseFriendlyNumberConfig != null || c == 1);
			}
			let result:ISelectItem<number>[] = configIds.map((id) => {
				return {
					text: config.getJournalName(id),
					value: id
				};
			});

			if (this.noJournalOption){
				result.unshift({
					text: this.noJournalOptionLabel,
					value: 0
				});
			}

			return result;
		}
	},
	methods: {
		onInput(value:number) {
			this.$emit("input", value);
		}
	}
});
