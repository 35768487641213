export function specialLink(value: any): string {
	// eslint-disable-next-line max-len
	//let re = /(?!\S*(\.png|\.jpg|.jpeg\/))((https?:)\/\/[a-z0-9&#=.\/\-?_]+)/gi;
	let re = /((http|https):\/\/files\.taxi\/)+([\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?/gi;
	let r = "";
	r = value.replace(re, function(x:string){
		let y = x.replace(".htm","");
		if (y.indexOf("jpg") >0 || y.indexOf("jpeg")>0 || y.indexOf("png")>0) {
			return '<a href="'+x+'" target="_blank" class="w-img-link"><img src="'+y+'"></a>';
		}
		if (y.indexOf("mp4") >0) {
			return '<a href="'+y+'" target="_blank" class="w-img-link">'+y+" (Movie)</a>";
		}
		return '<a href="'+x+'" target="_blank" class="w-img-link">'+x+"</a>";


	});

	re = /((http|https):\/\/d\.pr\/)+([\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?/gi;
	r = r.replace(re, function(x:string){
		if (x.indexOf("/v/") >0) {
			return '<a href="'+x+'+" target="_blank" class="w-img-link">'+x+" (Video)</a> ";
		}
		return '<a href="'+x+'" target="_blank" class="w-img-link"><img src="'+x+"+"+'"></a>';


	});

	return r;
}