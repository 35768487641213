import { MODULE } from "@/config/modules";
import { AuthService } from "@/services";
import { AxiosError } from "axios";
import Axios from "axios";
import { BrowserInfo } from "../browser";
import { ElectronCtepEctError } from "./electron-ctep-ect-error";
import { ElectronCtepSaleResult } from "./electron-ctep-sale-result";
import { ElectronCtepSettings } from "./electron-ctep-settings";

function getSettingsKey():string{
	return `electron-ctep-settings-${AuthService.wfDossier.id}`;
}

const host = `${BrowserInfo.getElectronUrl()}/ctep`;

export class ElectronCtepClass {
	public getSettings():ElectronCtepSettings{
		if (!this.canRun()) return new ElectronCtepSettings();
		let storedJsonString =  localStorage.getItem(getSettingsKey());
		let storedSettings = null;
		if (storedJsonString) {
			storedSettings = JSON.parse(storedJsonString);
		}
		return new ElectronCtepSettings(storedSettings);
	}

	public setSettings(settings:ElectronCtepSettings){
		localStorage.setItem(getSettingsKey(), JSON.stringify(settings.getJSON()));
	}

	public async init(){
		if (!this.canRun()) return;
		let settings = this.getSettings();
		if (!settings.enabled) return;
		this.startService();
	}

	public async reinit(){
		if (!this.canRun()) return;
		await this.deinit();
		await this.init();
	}

	public async deinit(){
		if (!BrowserInfo.isElectron()) return;
		await this.stopService();
	}

	private async startService():Promise<void>{
		if (!this.canRun()) return;
		let settings = this.getSettings();
		let body:any = {
			port: settings.port,
			comPort: settings.comPort,
			connectUrl: settings.connectUrl
		};
		console.log(body.connectUrl);
		await Axios.post(`${host}/start-service/${settings.mode}`, body);
		await this.setSelfCertificationMode(settings.selfCertificationModeEnabled);
	}

	private async stopService():Promise<void>{
		if (!BrowserInfo.isElectron()) return;
		await Axios.post(`${host}/stop-service`);
	}

	public async isServiceRunning():Promise<boolean>{
		let result = await Axios.get(`${host}/is-service-runnig`);
		return result.data == "true";
	}

	private async setSelfCertificationMode(enabled:boolean){
		await Axios.post(`${host}/set-self-certification-mode`, {enabled});
	}

	private ectErrorHandler(err:any){
		let error = err as AxiosError;
		if (error.response){
			if (typeof(error.response.data) == "object") {
				throw new ElectronCtepEctError(error.response.data);
			}
		}
		throw err;
	}

	public async sendSaleTransaction(amount:number, reference:string):Promise<ElectronCtepSaleResult>{
		try{
			let result = await Axios.post(`${host}/send-sale-transaction`, {amount, reference}, {timeout: 120000});
			return new ElectronCtepSaleResult(result.data);
		}catch(err){
			this.ectErrorHandler(err);
			throw err;
		}
	}

	public async cancelSale(){
		try{
			await Axios.post(`${host}/cancel-sale`);
		}catch(err){
			this.ectErrorHandler(err);
		}
	}

	public async listSerialPorts():Promise<string[]>{
		let result = await Axios.get(`${host}/list-serial-ports`);
		return result.data;
	}

	private canRun():boolean {
		if (!BrowserInfo.isElectron()) return false;
		if (!this.hasModule()) return false;
		return true;
	}

	public hasModule():boolean {
		return AuthService.hasModule(MODULE.KOPPELING_MET_BANCONTACT);
	}
}

export const ElectronCtep = new ElectronCtepClass();