import { ObservationDescriptionActivity } from "./observation-description-activity";
import {Tire} from "./tire";

export class ObservationDescription {
	activities:ObservationDescriptionActivity[] = [];
	jobDescriptions:string[] = [];
	tyres:Tire[] = [];

	public constructor(data?:any){
		if (!data) return;
		this.activities = ((data.activities as any[]) || []).map(o=>new ObservationDescriptionActivity(o));
		this.jobDescriptions = data.jobDescription as string[] || [];
		this.tyres = ((data.tyres as any[]) || []).map(t=>new Tire(t));
	}

	public getJSON(){
		return {
			activities: this.activities.map(a=>a.getJSON()),
			jobDescriptions: [...this.jobDescriptions],
			tyres: this.tyres.map(t=>t.getJSON())
		};
	}

	public shouldHaveTires():boolean {
		return this.activities.findIndex(a=>a.mainActivity.toLowerCase() == "tyres") != -1;
	}

	public isValid():boolean {
		if (this.activities.length == 0) return false;
		if (this.jobDescriptions.length == 0) return false;
		for (let description of this.jobDescriptions) {
			if (description.length > 500) {
				return false;
			}
		}
		if (this.shouldHaveTires()){
			if (this.tyres.length == 0) {
				return false;
			}
			if (this.tyres.length > 6){
				return false;
			}
			for (let t of this.tyres) {
				if (t.quantity > 6) {
					return false;
				}
			}
		}else if (this.tyres.length != 0){
			return false;
		}
		return true;
	}
}