
import Vue from "vue";
import { TranslateResult } from "vue-i18n";
import AppMobileFullScreenContainer from "./MobileFullScreenContainer.vue";
import { printLongLocalDateTime } from "@/utils/pretty-print";
import { UserConfigService } from "@/services/user-config-service";

export default Vue.extend({
	data(){
		return {
			text: ""
		};
	},
	props: {
		title: [Object as ()=>TranslateResult, String],
		label: [Object as ()=>TranslateResult, String],
		value: String,
		name: String,
		editor: Boolean,
		forceEditor: Boolean,
		okText: {
			type: [Object as ()=>TranslateResult, String],
			default():TranslateResult{
				return this.$ct("common.save");
			}
		},
		cancelText: {
			type: [Object as ()=>TranslateResult, String],
			default():TranslateResult{
				return this.$ct("common.cancel");
			}
		}
	},
	computed: {
		showEditor():Boolean {
			return (UserConfigService.getSaleSettings().DescriptionEditor && this.editor) || this.forceEditor;
		},
		isHtml(): Boolean{
			let r = /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/;
			return r.test(this.value);
		}
	},
	watch: {
		value: {
			immediate: true,
			handler(){
				if(!this.isHtml && !this.value.includes("{__NEW_LINE__}")) {
					if(this.showEditor){
						this.text = this.value.replace(/(?:\r\n|\r|\n)/g, "<br>");
					}else{
						this.text = this.value.replace(/(?:\r\n|\r|\n)/g, "\n");
					}
				}else{
					this.text = this.value.replaceAll("{__NEW_LINE__}", "<br>").replaceAll("{__DOUBLE_SPACE__}", "&nbsp;&nbsp;");
				}
			}
		},
		text: {
			immediate: true,
			handler(){
				this.$emit("temp-text", this.text);
			}
		}
	},
	methods: {
		onKeyUp($event:KeyboardEvent){
			if(($event.ctrlKey || $event.metaKey) && $event.keyCode == 53){
				let target = $event.target as HTMLInputElement;
				let cp = target.selectionStart || 0;
				let space = cp != this.text.length ? "" : " ";
				this.text = this.text.slice(0, cp)
					+ space
					+ printLongLocalDateTime(new Date())
					+ " " +  this.text.slice(cp);
			}
			this.updateTextareaCursorPosition();

		},
		onInput(value: string){
			let regex = /style="(.*?)"/gm;
			value = value.replace(regex, "");
			//value = value.replace(/(?:\r\n)/g, "<br>");
			value = value.replaceAll(/<\/?span[^>]*>/g, "");
			value = value.replaceAll("<blockquote >", "<blockquote>");
			value = value.replaceAll("<br>", "{__NEW_LINE__}");
			value = value.replaceAll("<div>", "{__NEW_LINE__}");
			value = value.replaceAll("</div>", "");
			value = value.replaceAll("&nbsp;&nbsp;", "{__DOUBLE_SPACE__}");
			this.text = value;
		},
		close(){
			this.$emit("close");
		},
		confirm(){
			this.onInput(this.text);
			this.$emit("input", this.text);
			this.$emit("confirm");
			this.close();
		},
		updateTextareaCursorPosition(){
			if (!this.$refs.textarea) return;
			let el = (this.$refs.textarea as Vue).$el.getElementsByTagName("textarea")[0];
			if (!el) return;
			this.$emit("cursor-positoin", el.selectionStart);
		},
		onMouseUp(){
			this.updateTextareaCursorPosition();
		},
		onFocus(){
			this.updateTextareaCursorPosition();
		},
	},
	mounted(){
		(this.$refs.ok as any).focus();
	},
	components: {
		AppMobileFullScreenContainer,
	}
});
