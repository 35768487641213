export class OrderColumn {
	Column:string = "";
	ASC:boolean = true;

	constructor(data?:any) {
		if (!data) return;
		this.Column = data.Column;
		this.ASC = data.ASC;
	}

	getJSON():any {
		return {
			Column: this.Column,
			ASC: this.ASC
		};
	}
}