import { HumanFilter } from "@/utils/human-filter";

export class ViewSetting {
	ViewID: number = 0;
	OrderColumn: string = "";
	OrderAscending: boolean = false;
	Limit: number = 0;
	SearchQuery: string = "";
	AutoWildCardSearch:boolean = true;
	SearchByAllColumns:boolean = false;
	Filters: HumanFilter[] = [];

	constructor(data?: any) {
		if (!data) {
			return;
		}

		this.ViewID = data.ViewID;
		this.OrderColumn = data.OrderColumn;
		this.OrderAscending = data.OrderAscending;
		this.Limit = data.Limit;
		this.SearchQuery = data.SearchQuery || "";
		this.AutoWildCardSearch = data.AutoWildCardSearch || false;
		this.SearchByAllColumns = data.SearchByAllColumns || false;
		if (data.Filters) {
			this.Filters = JSON.parse(data.Filters);
		}

	}

	static getDefaultViewSetting(): ViewSetting {
		let setting = new ViewSetting();
		setting.OrderColumn = "ID";
		setting.OrderAscending = false;
		setting.Limit = 25;
		setting.Filters = [];
		setting.SearchQuery = "";
		setting.AutoWildCardSearch = false;
		setting.SearchByAllColumns = false;
		return setting;
	}
}
