
import { VatService } from "@/services";
import Vue from "vue";
import { TranslateResult } from "vue-i18n";
import { ISelectItem } from "winfakt-vue-components";

export default Vue.extend({
	props: {
		label: {
			type: [String, Object as ()=>TranslateResult]
		},
		value: Number,
		fancy: Boolean,
		disabled: Boolean
	},
	computed: {
		items():ISelectItem<number>[]{
			return VatService.getPurchaseVatRegimes().map(v => {
				return {value: v.getId(), text: this.$ct(`common.vat-regimes-purchase.${v.getId()}`)};
			});
		}
	}
});
