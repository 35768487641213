
import { IModel } from "@/utils/models/model-interface";
import { ReportEngineSettings } from "@/utils/report-engine-settings/report-engine-settings";
import { Summary } from "@/utils/report-engine-settings/summary";
import { SummaryRow } from "@/utils/report-engine-settings/summary-row";
import Vue from "vue";
import AppSummaryEditRow from "./SummaryEditRow.vue";

export default Vue.extend({
	data(){
		return {
			toEditIndex: -1
		};
	},
	props: {
		value: {
			type: Object as ()=>ReportEngineSettings,
			required: true
		},
		model: {
			type: Object as ()=>IModel,
			required: true
		}
	},
	computed: {
		settings():Summary{
			return this.value.Summary;
		},
	},
	methods: {
		addRow(){
			this.settings.Rows.push(new SummaryRow());
			this.toEditIndex = this.settings.Rows.length-1;
		},
		editRow(row:SummaryRow){
			this.toEditIndex = this.settings.Rows.indexOf(row);
		},
		deleteRow(row:SummaryRow){
			let i = this.settings.Rows.indexOf(row);
			if (i == -1) return;
			this.settings.Rows.splice(i, 1);
		}
	},
	components: {
		AppSummaryEditRow
	},
	i18nEx: {
		componentPrefix: "components.reportengine"
	}
});
