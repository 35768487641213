
import Vue from "vue";
export default Vue.extend({
	props: {
		firstIcon: [String, Array as ()=>string[]],
		icon: [String, Array as ()=>string[]]
	},
	computed: {
		clickable():boolean{
			return this.$listeners["click"] != null;
		}
	},
	methods: {
		onClick(event:Event){
			this.$emit("click",event);
		}
	}
});
