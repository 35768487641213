import { render, staticRenderFns } from "./ProductEditPriceRuleFilterItem.vue?vue&type=template&id=f16fb204&scoped=true"
import script from "./ProductEditPriceRuleFilterItem.vue?vue&type=script&lang=ts"
export * from "./ProductEditPriceRuleFilterItem.vue?vue&type=script&lang=ts"
import style0 from "./ProductEditPriceRuleFilterItem.vue?vue&type=style&index=0&id=f16fb204&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f16fb204",
  null
  
)

export default component.exports