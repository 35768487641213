
import Vue from "vue";
import { BrowseStateBase } from "@/utils/browse/browse-state-base";

export default Vue.extend({
	data() {
		return {
			search: "",
		};
	},
	props: {
		state: {
			required: true,
			type: Object as () => BrowseStateBase
		}
	},
	computed: {
		icon(): string{
			return this.search ? "times" : "";
		}
	},
	watch: {
		"state.querySettings.searchQuery"() {
			if(this.state.querySettings.searchQuery==""){
				this.search = "";
			}
		}
	},
	methods: {
		onFocus(){
			this.$emit("focus");
		},
		onClear(){
			this.search = "";
			this.$emit("enter",this.search);
		},
		onEnter(){
			this.$emit("enter",this.search);
		}
	}
});
