import { SaleLayoutFreeLinePlacement } from "./free-line";
import { SaleLayoutPaymentColSettings } from "./payment-col";

export class SaleLayoutPaymentsSettings {
	Cols:SaleLayoutPaymentColSettings[] = [];
	Placement:SaleLayoutFreeLinePlacement = "";

	public constructor(data?:any){
		if (!data) return;
		this.Cols = (data.Cols || []).map((d:any)=>new SaleLayoutPaymentColSettings(d));
		this.Placement = data.Placement;
	}

	public getJSON(){
		return {
			Cols: this.Cols.map(c=>c.getJSON()),
			Placement: this.Placement
		};
	}
}