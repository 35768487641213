import { field, filterOnly, filterValueInput } from "@/utils/models/decorator-field";
import { model } from "@/utils/models/decorator-model";
import { ProductGroupModel } from "./product-group-model";

@model("ProductGroupProduct")
export class ProductGroupProduct {
	@field("number")
	public ID:number = 0;
	@field("number")
	@filterValueInput("Product")
	@filterOnly()
	public ProductID:number = 0;
	@field("number")
	public ProductGroupID:number = 0;
	@field("has-one", "ProductGroup")
	public ProductGroup:ProductGroupModel | null = null;

	public constructor(data?:any){
		if (!data) return;
		this.ID = data.ID;
		this.ProductID = data.ProductID;
		this.ProductGroupID = data.ProductGroupID;
		if (data.ProductGroup) {
			this.ProductGroup = new ProductGroupModel(data.ProductGroup);
		}
	}

	public getJSON():any{
		return {
			ID: this.ID,
			ProductID: this.ProductID,
			ProductGroupID: this.ProductGroupID
		};
	}
}