import { UblUserConfig } from "@/plugins/ubl/models/ubl-user-config";

export class PluginUserConfig {
	public UBL:UblUserConfig = new UblUserConfig();

	constructor(data?:any){
		if (!data) return;
		this.UBL = new UblUserConfig(data.UBL);
	}

	getJSON(){
		return {
			UBL: this.UBL.getJSON()
		};
	}
}