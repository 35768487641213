import { GoTemplateDataProperty } from "@/utils/go-template-data-structure/property";
import { DisplayedPropertyType } from "./root";

export class GoTemplateDataLink extends GoTemplateDataProperty {
	public linkText:string = "";

	constructor(name:string, displayedName:string | {toString():string}, linkText:string | {toString():string}= "") {
		super();
		this.name = name;
		this.displayedName = displayedName.toString();
		this.linkText = linkText.toString();
	}

	getNodeType():DisplayedPropertyType {
		return "link";
	}

	getNodeArgs(): any[] {
		return [this.linkText || this.displayedName];
	}
}