export class TotalStyle {
	public Color:string = "#000000";
	public BackgroundColor:string = "#FFFFFF";
	public Bold:boolean = false;
	public Italic:boolean = false;
	public Underline:boolean = false;

	public constructor(data?:any){
		if (!data) return;
		this.Color = data.Color;
		this.BackgroundColor = data.BackgroundColor;
		this.Bold = data.Bold;
		this.Italic = data.Italic;
		this.Underline = data.Underline;
	}

	public getJSON():any{
		return {
			Color: this.Color,
			BackgroundColor: this.BackgroundColor,
			Bold: this.Bold,
			Italic: this.Italic,
			Underline: this.Underline
		};
	}

	getStyle():any{
		let obj:any = {
			color: this.Color,
			backgroundColor: this.BackgroundColor
		};
		if (this.Bold){
			obj.fontWeight = "bold";
		}
		if (this.Italic) {
			obj.fontStyle = "italic";
		}
		if (this.Underline){
			obj.textDecoration = "underline";
		}
		return obj;
	}
}