import {
	dateIsWithinDaysAgo,
	dateIsWithinMonthsAgo,
	dateIsWithinYearsAgo,
	dateIsOverDaysAgo,
	dateIsOverMonthsAgo,
	dateIsOverYearsAgo,
	dateIsWithinDays,
	dateIsWithinMonths,
	dateIsWithinYears,
	dateIsOverDays,
	dateIsOverMonths,
	dateIsOverYears,
	dateIsWithinHoursAgo,
	dateIsWithinMinutesAgo,
	dateIsOverHoursAgo,
	dateIsOverMinutesAgo,
	dateIsOverMinutes,
	dateIsOverHours,
	dateIsWithinMinutes,
	dateIsWithinHours
} from "../date-utils";

function compareAgainsNumAndFunction(val1:any, val2:string, func:(d:Date,a:number)=>boolean):boolean {
	let date = new Date(val1);
	let num = parseInt(val2 || "0");
	if (Number.isNaN(num)){
		return false;
	}
	return func(date, num);
}

export function conditionOperatorWithinDaysAgo(val1:any, val2:string):boolean {
	return compareAgainsNumAndFunction(val1,val2, dateIsWithinDaysAgo);
}
export function conditionOperatorWithinMonthsAgo(val1:any, val2:string):boolean {
	return compareAgainsNumAndFunction(val1,val2, dateIsWithinMonthsAgo);
}
export function conditionOperatorWithinYearsAgo(val1:any, val2:string):boolean {
	return compareAgainsNumAndFunction(val1,val2, dateIsWithinYearsAgo);
}
export function conditionOperatorOverDaysAgo(val1:any, val2:string):boolean {
	return compareAgainsNumAndFunction(val1,val2, dateIsOverDaysAgo);
}
export function conditionOperatorOverMonthsAgo(val1:any, val2:string):boolean {
	return compareAgainsNumAndFunction(val1,val2, dateIsOverMonthsAgo);
}
export function conditionOperatorOverYearsAgo(val1:any, val2:string):boolean {
	return compareAgainsNumAndFunction(val1,val2, dateIsOverYearsAgo);
}
export function conditionOperatorWithinDays(val1:any, val2:string):boolean {
	return compareAgainsNumAndFunction(val1,val2, dateIsWithinDays);
}
export function conditionOperatorWithinMonths(val1:any, val2:string):boolean {
	return compareAgainsNumAndFunction(val1,val2, dateIsWithinMonths);
}
export function conditionOperatorWithinYears(val1:any, val2:string):boolean {
	return compareAgainsNumAndFunction(val1,val2, dateIsWithinYears);
}
export function conditionOperatorOverDays(val1:any, val2:string):boolean {
	return compareAgainsNumAndFunction(val1,val2, dateIsOverDays);
}
export function conditionOperatorOverMonths(val1:any, val2:string):boolean {
	return compareAgainsNumAndFunction(val1,val2, dateIsOverMonths);
}
export function conditionOperatorOverYears(val1:any, val2:string):boolean {
	return compareAgainsNumAndFunction(val1,val2, dateIsOverYears);
}
export function conditionOperatorWithinHoursAgo(val1:any, val2:string):boolean {
	return compareAgainsNumAndFunction(val1,val2, dateIsWithinHoursAgo);
}
export function conditionOperatorWithinMinutesAgo(val1:any, val2:string):boolean {
	return compareAgainsNumAndFunction(val1,val2, dateIsWithinMinutesAgo);
}
export function conditionOperatorOverHoursAgo(val1:any, val2:string):boolean {
	return compareAgainsNumAndFunction(val1,val2, dateIsOverHoursAgo);
}
export function conditionOperatorOverMinutesAgo(val1:any, val2:string):boolean {
	return compareAgainsNumAndFunction(val1,val2, dateIsOverMinutesAgo);
}
export function conditionOperatorWithinHours(val1:any, val2:string):boolean {
	return compareAgainsNumAndFunction(val1,val2, dateIsWithinHours);
}
export function conditionOperatorWithinMinutes(val1:any, val2:string):boolean {
	return compareAgainsNumAndFunction(val1,val2, dateIsWithinMinutes);
}
export function conditionOperatorOverHours(val1:any, val2:string):boolean {
	return compareAgainsNumAndFunction(val1,val2, dateIsOverHours);
}
export function conditionOperatorOverMinutes(val1:any, val2:string):boolean {
	return compareAgainsNumAndFunction(val1,val2, dateIsOverMinutes);
}