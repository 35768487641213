import { ContactService, onLastOpenedItemSetFunction } from "@/utils/services/contact-service";
import { ServerConfig } from "@/config/config";
import { LastOpenedItem } from "@/utils/last-opened";

class SupplierServiceClass extends ContactService {
	protected routerPath: string = `/contacts/suppliers`;
	protected url: string = `${ServerConfig.host}/suppliers`;
	public lastOpenedItem = new LastOpenedItem("contacts/supplier", onLastOpenedItemSetFunction);
}

export const SupplierService = new SupplierServiceClass();