import Product from "./product";
import { field, filterOnly, filterValueInput, nullable } from "@/utils/models/decorator-field";
import { model } from "@/utils/models/decorator-model";

@model("ProductWriteOffRow")
export default class ProductWriteOffRow {
	@field("number")
	public ID:number = 0;

	@field("number")
	@filterValueInput("Product")
	@filterOnly()
	public ProductID:number = 0;

	@field("number")
	public WriteOffProductID:number = 0;

	@field("number")
	public Amount:number = 1;

	@field("has-one", "Product")
	@nullable()
	public Product:Product | null = null;

	@field("has-one", "Product")
	@nullable()
	public WriteOffProduct:Product | null = null;

	public constructor(data?: any) {
		if (!data) {
			return;
		}
		this.ID = data.ID;
		this.ProductID = data.ProductID;
		this.WriteOffProductID = data.WriteOffProductID;
		this.Amount = data.Amount;
		if (data.Product) {
			this.Product = new Product(data.Product);
		}
		if (data.WriteOffProduct) {
			this.WriteOffProduct = new Product(data.WriteOffProduct);
		}
	}

	public getJSON() {
		return {
			ID: this.ID,
			ProductID: this.ProductID,
			WriteOffProductID: this.WriteOffProductID,
			Amount: this.Amount,
		};
	}
}
