import { ImageStyledProperty } from "../documents/image-styled-property";

export class SaleLayoutDetailImage extends ImageStyledProperty{
	Prop:string = "";

	public constructor(data?:any){
		super(data);
		if (!data) return;
		this.Prop = data.Prop;
	}

	public getJSON(){
		return {
			...super.getJSON(),
			Prop: this.Prop,
		};
	}
}