import { v4 } from "uuid";
import { Condition } from "../condition/condition";
import { CustomTranslation } from "../custom-translation";
import { DocumentLayoutLogoBase } from "../documents/layout-logo-base";
import { getSaleMapPropSelectGroup } from "../prop-select/sale-map-prop-group";
import { SaleLayoutLogoPosition } from "./logo";

export enum BarcodeType {
	CODE128 = 0,
	CODE39 = 1,
	EAN13 = 2,
	EAN8 = 3
}

export class SaleLayoutBarcode extends DocumentLayoutLogoBase {
	public uuid:string = v4();
	public Placement:SaleLayoutLogoPosition = "Heading";
	HideOnPdfOnPrint:boolean = false;
	HideOnPdfOnEmailSend:boolean = false;
	HideOnPdfOnDownload:boolean = false;
	Conditions:Condition[] = [];
	Text:CustomTranslation = new CustomTranslation();
	Prop:string = "";
	BarcodeType:BarcodeType = BarcodeType.CODE128;


	public constructor(data?:any){
		super(data);
		if (!data) return;
		this.Placement = data.Placement;
		this.HideOnPdfOnPrint = data.HideOnPdfOnPrint || false;
		this.HideOnPdfOnEmailSend = data.HideOnPdfOnEmailSend || false;
		this.HideOnPdfOnDownload = data.HideOnPdfOnDownload || false;
		this.Conditions = (data.Conditions || []).map((c:any)=>new Condition(c));
		this.BarcodeType = data.BarcodeType || 0;

		this.Text = new CustomTranslation(data.Text);
		this.Prop = data.Prop;
	}

	public getJSON(){
		let d:any = super.getJSON();
		d.HideOnPdfOnPrint = this.HideOnPdfOnPrint;
		d.HideOnPdfOnEmailSend = this.HideOnPdfOnEmailSend;
		d.HideOnPdfOnDownload = this.HideOnPdfOnDownload;
		d.Conditions = this.Conditions.map(c=>c.getJSON());
		d.Placement = this.Placement;
		d.Prop = this.Prop;
		d.Text = this.Text.getJSON();
		d.BarcodeType = this.BarcodeType;
		return d;
	}

	public getImageUrl():string {
		return "/img/winfakt-be-barcode.png";
	}


	public getText(lang:string):string{
		return this.Text.getTranslation(lang) + this.getPropValue();
	}

	public getPropValue(): string {
		if (!this.Prop) return "";
		let field = getSaleMapPropSelectGroup().getPropSelectFromValue(this.Prop);
		if (field) {
			return field.text.toString();
		}
		return "";
	}
}