
import Vue from "vue";
import { Sketch } from "vue-color";
import { Swatches } from "vue-color";

const presetColors = [
	"#D0021B", "#F5A623", "#F8E71C", "#8B572A", "#7ED321",
	"#417505", "#BD10E0", "#9013FE", "#4A90E2", "#50E3C2",
	"#B8E986", "#000000", "#4A4A4A", "#9B9B9B", "#FFFFFF",
	"rgba(0,0,0,0)"
];

export default Vue.extend({
	data() {
		return {
			colors: {}as any,
			colorValue: "",
			opened: false
		};
	},
	props: {
		color: String,
		label: [Object, String],
		presetColors: Array,
		fancy: Boolean,
		compact: Boolean,
		up: Boolean,
		icon: String,
		iconTooltip: [String, Object],
		iconClickHandler: Function
	},
	computed: {
		presets():any{
			return this.presetColors?.length ? this.presetColors : presetColors;
		}
	},
	watch: {
		colorValue(val) {
			if (val) {
				this.updateColors(val);
				this.$emit("input", val);
				//document.body.style.background = val;
			}
		},
		opened() {
			if (this.opened) {
				this.onOpen();
			} else {
				this.onClose();
			}
		}
	},
	methods: {
		setColor(color: any) {
			this.updateColors(color);
			this.colorValue = color;
		},
		updateColors(color: any) {
			if (color.slice(0, 1) == "#") {
				this.colors = {
					hex: color
				};
			} else if (color.slice(0, 4) as any == "rgba") {
				let rgba = color.replace(/^rgba?\(|\s+|\)$/g, "").split(",");
				let hexR = (1 << 24) + (parseInt(rgba[0]) << 16);
				let hexG = (parseInt(rgba[1]) << 8);
				let hexB = parseInt(rgba[2]);
				let hex = "#" + (hexR + hexG + hexB).toString(16).slice(1);
				this.colors = {
					hex,
					a: rgba[3],
				};
			}
		},
		showPicker() {
			this.opened = true;
		},
		hidePicker() {
			this.opened = false;
		},
		togglePicker() {
			this.opened = !this.opened;
		},
		updateFromInput() {
			this.updateColors(this.colorValue);
		},
		updateFromPicker(color: any) {

			this.colors = color;
			if (color.rgba.a == 1) {
				this.colorValue = color.hex;
			} else {
				this.colorValue = "rgba(" + color.rgba.r + ", " + color.rgba.g + ", " + color.rgba.b + ", " + color.rgba.a + ")";
			}
		},
		onOpen() {
			window.addEventListener("mouseup", this.documentClick);
		},
		onClose() {
			window.removeEventListener("mouseup", this.documentClick);
		},
		documentClick(e: any) {
			let el = this.$refs.colorpicker as HTMLDivElement;
			if (!el)return;
			let target = e.target;
			if (el != target && !el.contains(target)) {
				this.hidePicker();
			}
		}
	},
	mounted() {
		this.setColor(this.color || "");
	},
	beforeDestroy() {
		this.hidePicker();
	},
	components: {
		"chrome-picker": Sketch,
		"chrome-picker-compact": Swatches
	}
});
