import { IModelProperty, ModelPropertyType } from "../models/model-field";

export enum ScriptTableStructureColumnType{
	STRING = "string",
	DATE = "date",
	FLOAT = "float",
	LONG_STRING = "long_string",
	BOOL = "bool",
	INT = "int"
}

function scriptTableTypeToModelPropertyType(columnType:ScriptTableStructureColumnType):ModelPropertyType{
	if (columnType == ScriptTableStructureColumnType.INT) {
		return "number";
	}
	if (columnType == ScriptTableStructureColumnType.FLOAT){
		return "number";
	}
	if (columnType == ScriptTableStructureColumnType.BOOL) {
		return "boolean";
	}
	if (columnType == ScriptTableStructureColumnType.LONG_STRING){
		return "string";
	}
	return columnType as ModelPropertyType;
}

export class ScriptTableStructureColumns {
	name:string = "";
	type:ScriptTableStructureColumnType = ScriptTableStructureColumnType.STRING;
	translatedName:string = "";

	constructor(data?:any){
		this.name = data.name;
		this.type = data.type;
		this.translatedName = data.translatedName;
	}

	toModelProperty():IModelProperty{
		let c = this;
		return {
			type: scriptTableTypeToModelPropertyType(c.type),
			nullable: true,
			isComputed: false,
			filterOnly: false,
			notFilterable: false,
			getTranslatedName():string{
				return c.translatedName;
			}
		};
	}

}