
import { Product } from "@/models/base";
import Vue from "vue";
import AppEditProduct from "./ProductEdit.vue";

export default Vue.extend({
	data() {
		return {
			p: new Product()
		};
	},
	props: {
		product: Object as () => Product
	},
	computed: {
		titleKey():string {
			return this.p.ID == 0 ? "title-new" : "title-edit";
		}
	},
	watch: {
		product: {
			immediate: true,
			handler(product: Product) {
				this.p = product;
			}
		}
	},
	methods: {
		onProductSave(product: Product) {
			this.p = product;
		},
		cancel() {
			this.$emit("cancel");
		},
		finish() {
			this.$emit("input", this.p);
		}
	},
	components: {
		AppEditProduct
	},
	i18nEx: {
		componentPrefix: "components.products"
	}
});
