import { TotalStyle } from "./total-style";

export class FetchedTotal {
	Text:string;
	Hidden:boolean;
	Style:TotalStyle;

	public constructor(data:any){
		this.Text = data.Text;
		this.Hidden = data.Hidden;
		this.Style = new TotalStyle(data.Style);
	}

	getStyle():any{
		return this.Style.getStyle();
	}
}