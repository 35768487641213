import { Field } from "./field";
import { TextStyledProperty } from "./text-styled-property";

export class FieldWithSeperateStyles extends Field {
	PropStyle:TextStyledProperty = new TextStyledProperty();
	AfterTextStyle:TextStyledProperty = new TextStyledProperty();

	public constructor(data?:any){
		super(data);
		if (!data)return;
		if (data.PropStyle){
			this.PropStyle = new TextStyledProperty(data.PropStyle);
		}else{
			this.PropStyle = new TextStyledProperty(data.Style);
		}
		if (data.AfterTextStyle){
			this.AfterTextStyle = new TextStyledProperty(data.AfterTextStyle);
		}else{
			this.AfterTextStyle = new TextStyledProperty(data.Style);
		}
	}

	public getJSON():any {
		return {
			...super.getJSON(),
			PropStyle: this.PropStyle.getJSON(),
			AfterTextStyle: this.AfterTextStyle.getJSON()
		};
	}
}