export interface IToken {
	dossierName: string;
	token: string;
}


function convertDate(date: Date | null): string | null {
	if (!date) return null;
	return date.getTime() + "";
}

function save(key: string, value: string | null) {
	if (!value) {
		return localStorage.removeItem(key);
	}
	localStorage.setItem(key, value);
}

let selectedDossier = localStorage.getItem("selected-dossier") || "";

export const tokenStore = {
	get accessTokens(): IToken[] {
		return JSON.parse(localStorage.getItem("access-tokens") || "[]");
	},
	set accessTokens(value: IToken[]) {
		save("access-tokens", JSON.stringify(value));
	},
	get tokenExpires(): Date | null {
		let res = localStorage.getItem("access-token-expires");
		if (res) {
			return new Date(parseInt(res));
		}
		return null;
	},
	set tokenExpires(value: Date | null) {
		save("access-token-expires", convertDate(value));
	},

	get refreshToken(): string | null {
		return localStorage.getItem("refresh-token");
	},
	set refreshToken(value: string | null) {
		save("refresh-token", value);
	},

	get refreshTokenExpires(): Date | null {
		let res = localStorage.getItem("refresh-token-expires");
		if (res) {
			return new Date(parseInt(res));
		}
		return null;
	},
	set refreshTokenExpires(value: Date | null) {
		save("refresh-token-expires", convertDate(value));
	},

	get selectedDossier(): string {
		return selectedDossier;
	},
	set selectedDossier(value: string) {
		save("selected-dossier", value);
		selectedDossier = value;
	},
	set firstName(value: string) {
		save("first-name", value);
	},
	get firstName(): string {
		return localStorage.getItem("first-name") || "";
	},

	get serverAndSystemTimeDifferenceInms():number {
		return parseInt(localStorage.getItem("auth-server-time-difference") || "0");
	},
	set serverTime(time:Date){
		let val = new Date().getTime() - time.getTime();
		save("auth-server-time-difference", `${val}`);
	},

	getSelectedToken(): IToken {
		let comp = this.accessTokens.find(t => t.dossierName == this.selectedDossier);
		if (!comp) {
			if (this.accessTokens.length == 0) {
				throw Error("YOU HAVE NO DOSSIERS");
			}
			this.selectedDossier = this.accessTokens[0].dossierName;
			comp = this.accessTokens[0];
		}
		return comp;
	},

	willTokenExpireInSeconds(seconds:number):boolean {
		if (!this.tokenExpires) return false;
		let now = new Date(new Date().getTime() - this.serverAndSystemTimeDifferenceInms);
		now.setSeconds(now.getSeconds() + seconds);
		let result = this.tokenExpires.getTime() < now.getTime();
		return result;
	},

	get socketId():string {
		return localStorage.getItem("socket-id") || "";
	}
};