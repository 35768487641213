import { model } from "@/utils/models/decorator-model";
import { field } from "@/utils/models/decorator-field";

@model("CrmHistoryAppointment")

export default class CrmHistoryAppointment {

	@field("string")
	public Message: string = "";

	@field("date")
	public TimeStamp: Date = new Date();

	constructor(data?: any) {
		if (!data) return;
		this.Message = data.Message;
		this.TimeStamp = new Date(data.TimeStamp);
	}


	getJSON() {
		return {
			Message: this.Message,
			TimeStamp: this.TimeStamp.toJSON(),
		};
	}
}
