
import Vue from "vue";
import { ReportEngineSettingsModel } from "../../models/report-engine-settings";
import { ReportService } from "../../services/report-service";
import { createConfirmBuilder } from "../../setup/confirm-builder";
import { StaticReport } from "../../models/static-report";
import { ReportEngineSettings } from "../../utils/report-engine-settings/report-engine-settings";

export default Vue.extend({
	data() {
		return {
			items: [] as ReportEngineSettingsModel[],
			staticItems: [] as StaticReport[],
			selectedItem: null as null | ReportEngineSettingsModel
		};
	},
	props: {
		browseId: {
			type: Number,
			required: true
		},
		overwriteFlag: {
			type: Boolean,
			required: false
		}
	},
	computed: {
		noItems():boolean{
			return this.items.length == 0 && this.staticItems.length == 0;
		}
	},
	methods: {
		close() {
			this.$emit("close");
		},
		async loadStatic(settings:ReportEngineSettings){
			if (this.overwriteFlag) {
				let confirm = await createConfirmBuilder.bind(this)(this.$ct("confirm-load")).setWarningTitle().setClass("warning").show();
				if (!confirm) {
					return;
				}
			}
			this.$emit("load", settings, null);
			this.close();
		},
		async load(item:ReportEngineSettingsModel){
			if (this.overwriteFlag) {
				let confirm = await createConfirmBuilder.bind(this)(this.$ct("confirm-load")).setWarningTitle().setClass("warning").show();
				if (!confirm) {
					return;
				}
			}
			this.$emit("load", item.Settings, item);
			this.close();
		},
		async deleteItem(item:ReportEngineSettingsModel) {
			let confirm = await createConfirmBuilder.bind(this)(this.$ct("confirm-delete-settings", {name: item.Name})).setWarningTitle().setClass("warning").show();
			if (!confirm) {
				return;
			}
			await ReportService.deleteReportEngineSettings([item]);
			// let result = await ReportService.getReportEngineSettingsByBrowseId(this.browseId);
			// this.items = result.savedReports;
			this.selectedItem = null;
		}
	},
	async mounted() {
		let result = await ReportService.getReportEngineSettingsByBrowseId(this.browseId);
		this.items = result.savedReports;
		this.staticItems = result.staticReports;
	},
	i18nEx: {
		componentPrefix: "components.reportengine"
	}
});
