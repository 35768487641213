
export function computePreloads(fields:string[]):string[] {
	let cols = fields.map(f => f.split("."));
	let preloads = cols.filter((c) => {
		return c.length > 1;
	}).map((c: string[]) => {
		c.pop();
		return c.join(".");
	});
	let result = preloads.filter((p: string, i: number) => {
		return preloads.indexOf(p) == i;
	});
	return result.filter((p, i) => result.indexOf(p) == i);
}

export function browseFieldPathToFilterFieldPath(browsePath:string):string {
	let str = browsePath.replace(/\./g, "_");
	let i = str.lastIndexOf("_");
	let preloadList = str.substr(0, i);
	if (preloadList.length != 0) {
		preloadList = `_${preloadList}`;
	}
	return `this${preloadList}.${str.substr(i + 1)}`;
}

export function filterFieldPathToBrowseFieldPath(filterPath:string):string {
	let str = filterPath.replace(/\_/g, ".");
	return str.substring("this_".length);
}

