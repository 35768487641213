
import { IQueryParams } from "@/utils/query-params";
import { ViewColumn } from "@/utils/views/view-column";
import Vue from "vue";
import { IDataTableColumn, IDataTableContextMenuEvent } from "winfakt-vue-components";
import { getModel, Model } from "@/utils/models/model";
import { BrowseStateSearch } from "@/utils/browse/browse-state-search";
import AppJsonViewerModal from "@/components/common/JsonViewerModal.vue";
import { AuthService } from "@/services/auth-service";
import { ViewService } from "@/services/view-service";
import AppBrowseContextMenu from "./BrowseContextMenu.vue";
import { UserConfigService } from "@/services/user-config-service";
import { Condition } from "@/utils/condition/condition";

export default Vue.extend({
	data() {
		return {
			spinnerShown: false,
			jsonViewerObject: null as any,
			UserConfigService
		};
	},

	props: {
		state: {
			required: true,
			type: Object as () => BrowseStateSearch
		},
	},

	computed: {
		useCheckboxes():boolean {
			if (!this.state.multiple) return false;
			return this.UserConfigService.getGeneralSettings().UseCheckboxesInViews;
		},
		useCompactView():boolean {
			return this.UserConfigService.getGeneralSettings().CompactView;
		},
		selectedItems():any[]{
			return this.state.selectedItems;
		},
		modelDefinition(): Model {
			return getModel(this.state.dataSource.modelName);
		},
		querySettings():IQueryParams {
			return this.state.querySettings;
		},
		items():any[] {
			return this.state.queryResults.data;
		},
		columns(): IDataTableColumn[] {
			return (this.state.querySettings.view.Columns || []).map((e: ViewColumn) => {
				return e.getDataTableColumn(this.modelDefinition);
			});
		}
	},

	watch: {
		"state.fetching"(value:boolean) {
			if (!value) {
				this.spinnerShown = false;
			}
		},
		"selectedItems.length"() {
			if (this.state.multiple) return;
			if (this.selectedItems.length > 0) {
				this.$emit("click", this.selectedItems[0]);
				this.selectedItems.splice(0, this.selectedItems.length - 1);
			}
		}
	},

	methods: {
		openJsonViewer(item:any){
			if (!AuthService.isDevModeEnabled) return;
			this.jsonViewerObject = item;
		},
		onContextMenuClick(event:IDataTableContextMenuEvent) {
			if (event.event.ctrlKey){
				return this.openJsonViewer(event.item);
			}
			if (this.$wf.isSmDown) return;
			this.state.contextMenu.onContextMenuClick(event, this.selectedItems);
		},
		onOrderChanged(order: { order: any, ascending: boolean }) {
			this.querySettings.orderBy = order.order;
			this.querySettings.orderDirection = !order.ascending ? "ASC" : "DESC";
			this.state.fetch();
		},
		onScroll(event:Event) {

			let table = event.target as HTMLElement;
			let lastChild = table.lastChild as HTMLElement;
			if (!lastChild || !lastChild.getBoundingClientRect) {
				return;
			}

			let tableRect = table.getBoundingClientRect();
			let childRect = lastChild.getBoundingClientRect();

			let tableBottomY = tableRect.top + tableRect.height;
			if (childRect.top < (tableBottomY + 4000) && !this.state.fetching) {
				this.state.infiniteScrollFetchMore();
			}
		},
		openItem(item:any) {
			this.$emit("click", item);
		},
		async onNewColumnWidth(columnIndex:number){
			let width = this.columns[columnIndex].width;
			let view = this.state.querySettings.view;
			if (!width) {
				view.Columns[columnIndex].Width = "100px";
			}else{
				view.Columns[columnIndex].Width = width;
			}
			if (view.ID == 0) return;
			await ViewService.setColumnWidths(view.ID, view.Columns.map(c=>c.Width));
		},
		getStyleFunction(item:any):any{
			for (let highlight of this.state.querySettings.view.Highlights){
				if (Condition.checkConditions(highlight.Conditions, item)){
					return highlight.Style.getStyle();
				}
			}
			return null;
		}
	},
	components: {
		AppJsonViewerModal,
		AppBrowseContextMenu
	},
	i18nEx: {
		componentPrefix: "components.browse"
	}
});
