
import { AuthService } from "@/services/auth-service";
import { PaymentService } from "@/services/payment-service";
//import { PlanModulePricingFactory } from "@/utils/payments/plan-module-pricing-factory";
import Vue from "vue";

export default Vue.extend({
	data(){
		return {
			isModuleAvailable: false
		};
	},
	props: {
		moduleId: {
			type: Number,
			required: true
		},
		metadata: {
			type: Object as any
		}
	},
	computed: {
		moduleName(): string {
			return this.$ct("common.modules." + this.moduleId).toString();
		},
		upgrade(): boolean {
			return this.moduleId < 1;
		},
		shouldUpgrade(): boolean{
			return AuthService.wfCompany.Tier<2;
		},
		buttonsDisabled():boolean{
			return !this.isOwner;
		},
		isOwner():boolean {
			return AuthService.wfCompany.OwnerID == AuthService.wfUser.ID;
		}
	},
	methods: {
		onBuyModule() {
			this.$router.push(`/account/activate?tier=2`);
			/*if (!this.isModuleAvailable) return;
			let mod = PlanModulePricingFactory.createFromModuleIdAndMetadata(this.moduleId, this.metadata);
			PaymentService.openBuyModule(mod);*/
		},
		async checkIsModuleAvailable(){
			if (!this.isOwner) return;
			let availableModules = await PaymentService.getAllAvailableModules(AuthService.wfCompany.Tier);
			let foundModule = availableModules.find(m=>m.ModuleID== this.moduleId);
			if (!foundModule) {
				this.isModuleAvailable = false;
				return;
			}
			if (foundModule.getPrice() == 0 || foundModule.getPermanentPrice() == 0) {
				this.isModuleAvailable = false;
				return;
			}
			this.isModuleAvailable = true;
			return;
		}
	},
	created(){
		this.checkIsModuleAvailable();
	},
	components: {},
	i18nEx: {
		componentPrefix: "components.common-MissingModule"
	}
});
