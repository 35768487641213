
import { v4 as  uuid } from "uuid";
import Vue from "vue";
import {ExtraArg} from "@/models/script";
import { TranslateResult } from "vue-i18n";

export default Vue.extend({
	data(){
		return {
			id: uuid(),
			selectedTab: 0,
			parsedValue: {
				startMonth: 1,
				endMonth: 12
			}
		};
	},
	props: {
		value: {
			type: String,
			required: true
		},
		label: String,
		arg: {
			type: Object as ()=>ExtraArg,
			required: true
		}
	},
	computed: {
		tabs():string[]{
			return [this.$ct("month-range").toString(), this.$ct("quarter-select").toString()];
		},
		startMonth():number{
			return this.parsedValue.startMonth;
		},
		endMonth():number{
			return this.parsedValue.endMonth;
		},
		hasBookYearRangeOption():boolean{
			return this.arg.hasOption("use-bookyear-months");
		}
	},
	watch: {
		value: {
			handler(){
				this.parsedValue = JSON.parse(this.value);
			}
		},
		selectedTab(){
			if (this.selectedTab != 1) return;
			this.setStartMonth(1);
			this.setEndMonth(12);
		}
	},
	methods: {
		onInput(){
			this.$emit("input", JSON.stringify(this.parsedValue));
		},
		setStartMonth(month:number){
			this.parsedValue.startMonth = month;
			this.onInput();
		},
		setEndMonth(month:number){
			this.parsedValue.endMonth = month;
			this.onInput();
		},
		isQuarterSelected(quarter:number):boolean{
			return this.parsedValue.startMonth / 3 == quarter;
		},
		selectQuarter(quarter:number){
			this.parsedValue.startMonth = (((quarter-1)*3)+1);
			this.parsedValue.endMonth = this.parsedValue.startMonth +2;
			this.onInput();
		},
		isYearSelected():boolean{
			return this.parsedValue.startMonth == 1 && this.parsedValue.endMonth == 12;
		},
		selectYear(){
			this.parsedValue.startMonth = 1;
			this.parsedValue.endMonth = 12;
			this.onInput();
		},
		getQuarterLabel(quarter:string):TranslateResult{
			if (this.hasBookYearRangeOption){
				return this.$ct(`bookyear-quarter.${quarter}`);
			}
			return this.$ct(`quarter.${quarter}`);
		}
	},
	created(){
		this.setStartMonth(1);
		this.setEndMonth(12);
	},
	i18nEx: {
		componentPrefix: "components.reportengine"
	}
});
