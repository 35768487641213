
import { getAllowedEditorHtmlTags } from "@/utils/common";
import Vue from "vue";
import { TranslateResult } from "vue-i18n";
import AppTextAreaModal from "./TextAreaModal.vue";

export default Vue.extend({
	data(){
		return {
			modalOpen: false
		};
	},
	props: {
		value: {
			type: String
		},
		editor: Boolean,
		disabled: Boolean,
		fancy: Boolean,
		label: [String, Object as ()=>TranslateResult],
		modalTitle: [String, Object as ()=>TranslateResult],
		okText: [String, Object as ()=>TranslateResult],
		cancelText: [String, Object as ()=>TranslateResult],
		forceModalEditor: Boolean
	},
	computed: {
		computedModalTitle():string | TranslateResult {
			if (!this.modalTitle) {
				return this.label;
			}
			return this.modalTitle;
		},
		hasNewLine(): boolean {
			return this.value.indexOf("\n") != -1
				|| this.value.includes("{__NEW_LINE__}");
		},
		hasHtml():boolean {
			if (!this.editor) {
				return false;
			}
			let tags = getAllowedEditorHtmlTags();
			for (let tag of tags) {
				if (this.value.includes(`<${tag}>`)){
					return true;
				}
			}
			return false;
		},
		displayedText():string {
			let v = this.value.replaceAll("{__NEW_LINE__}","\n");
			if (v.indexOf("\n") != -1) {
				v = `${v.split("\n")[0]}...`;
			}
			if (this.editor && this.hasHtml){
				let tags = getAllowedEditorHtmlTags();
				for (let tag of tags) {
					v = v.replaceAll(new RegExp(`<\/?${tag}>`, "g"), "");
				}
			}
			return v;
		},
	},
	watch: {
		modalOpen(){
			this.$emit("modal-open", this.modalOpen);
		},
	},
	methods: {
		onInputElInput(event:string){
			if (this.hasNewLine) return;
			if (event.indexOf("\n") != -1) return;
			this.onInput(event);
		},
		onInput(event:string){
			this.$emit("input", event);
		},
		onConfirm(){
			this.$emit("confirm");
		},
		onFocus(event:FocusEvent){
			if (this.hasNewLine || this.forceModalEditor){
				this.modalOpen = true;
			}
			this.$emit("focus", event);
			this.emitInputCursorPosition();
		},
		emitInputCursorPosition(){
			if (this.modalOpen) return;
			let ref = this.$refs.input as Vue;
			if (!ref) return;
			this.$emit("cursor-position", ref.$el.getElementsByTagName("input")[0].selectionStart);
		},
		editIconClicked(){
			if(this.disabled)
				return;
			this.modalOpen = true;
		},
		onKeyUp(event:KeyboardEvent){
			this.$emit("keyup", event);
			if (event.key == "F8" && !this.modalOpen) {
				this.editIconClicked();
			}
			this.emitInputCursorPosition();
		},
		onKeyUpCtrl(event:KeyboardEvent){
			this.$emit("keyupctrl", event);
		},
		focus(){
			let el = this.$refs.input as Vue;
			if (!el) return;
			(el as any).focus();
		},
		onMouseUp(){
			this.emitInputCursorPosition();
		},
		setModalText(text:string){
			let modal = this.$refs.modal as any;
			if (!modal) return;
			modal.text = text;
		}
	},
	components: {
		AppTextAreaModal
	}
});
