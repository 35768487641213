import { i18n } from "@/setup/i18n-setup";
import { getModel } from "../models/model";
import { IModel } from "../models/model-interface";
import { PropSelectGroup } from "./prop-select-group";
import { PropSelectItem } from "./prop-select-item";

// eslint-disable-next-line max-lines-per-function
export function convertIModelToConditionPropSelectGroup(model:IModel):PropSelectGroup{
	let result = new PropSelectGroup("", "", model.getName());
	let fields = model.getFilterFields();

	function getGroup(pathParts:string[]):PropSelectGroup{
		let currentGroup = result;
		for (let part of pathParts){
			let found = false;
			for (let group of currentGroup.groups){
				if (group.path == part){
					currentGroup = group;
					found = true;
					break;
				}
			}
			if (!found && currentGroup.modelName) {
				let model = getModel(currentGroup.modelName);
				let field = model.getField(part);
				let modelName = "";
				if (field) {
					modelName = field.field.modelName || "";
				};
				let groupName = `${currentGroup.name} ${i18n.t(`models.friendlyNames.${currentGroup.modelName}.${part}`)}`.trim();
				let group = new PropSelectGroup(groupName, part, modelName);
				currentGroup.groups.push(group);
				currentGroup = group;
			}
		}

		return currentGroup;
	}

	for (let field of fields){
		let parts = field.path.split(".");
		if (parts.length == 1){
			result.items.push(new PropSelectItem(field.getPropertyTranslation(), field.path, field.field.type, field.field.filterValueInput));
			continue;
		}

		let fieldName = parts.pop();
		let model = getModel(field.modelName);
		let f = model.getField(fieldName || "") || field;
		let group = getGroup(parts);
		group.items.push(new PropSelectItem(i18n.t(`models.friendlyNames.${field.modelName}.${fieldName}`), field.path, f.field.type, f.field.filterValueInput));
	}

	return result;
}