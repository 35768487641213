
import Vue from "vue";
import { TranslateResult } from "vue-i18n";
import AppSelectBankKind from "@/components/bank/SelectBankKind.vue";
import AppMultiSelectBankKind from "@/components/bank/MultiSelectBankKind.vue";
import { BankKind } from "@/models/base/bank";

export default Vue.extend({
	props: {
		value: {
			type: [String, Array as ()=>string[]],
			required: true
		},
		multi: Boolean,
		label: {
			type: [Object as ()=>TranslateResult, String],
			default():TranslateResult{
				if (this.multi) return this.$ct("bank-kinds");
				return this.$ct("bank-kind");
			}
		}
	},
	computed: {
		singleValue():BankKind | undefined {
			if (typeof(this.value) == "object"){
				return parseInt(this.value[0]);
			}
			return parseInt(this.value);
		}
	},
	methods: {
		setSingleValue(value:string){
			this.$emit("input", `${value}`);
		}
	},
	components: {
		AppSelectBankKind,
		AppMultiSelectBankKind
	},
	i18nEx: {
		componentPrefix: "components.models"
	}
});
