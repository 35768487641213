
import Vue from "vue";
import { AxiosErrorEmitter } from "@/utils/axios";
import { HttpError } from "./utils/services/http-error";
import AppMotd from "@/components/Motd.vue";
import {AuthService} from "@/services/auth-service";

export default Vue.extend({
	mounted() {
		AxiosErrorEmitter.on("error", (err: HttpError) => {
			requestAnimationFrame(() => {
				if (err.dontShow) return;
				if (err.key) {
					if (err.key == "UNSPECIFIED") {
						console.error(err.message);
						if (err.message == "Unkown error: Sending emails without an SMTP server is disabled for DEMO accounts."){
							this.$wf.notify(this.$ct("errors.FEATURE_NOT_AVAILABLE_IN_DEMO"), "danger", 3000, "exclamation-triangle");
							err.dontShow = true;
						}
					}else{
						this.$wf.notify(err.getText(), "danger", 3000, "exclamation-triangle");
					}

					if (err.key == "SES_LIMIT_EXCEEDED"){
						this.$wf.dialog(this.$ct("common.ses-limit-reached-info"), this.$ct("common.ok"), this.$ct("common.error"), "warning");
					}
				}
			});
		});
		AuthService.mainVueInstance = this;
	},
	components: {
		AppMotd
	}
});
