export class BookkeepingConfig {
	DefaultSaleCentral:string = "400000";
	DefaultPurchaseCentral:string = "440000";
	BookOpenClientsAndSuppliersAutomaticallyOnWaitLedger:boolean = false;

	public constructor(data?:any){
		if (!data) return;
		this.DefaultSaleCentral = data.DefaultSaleCentral;
		this.DefaultPurchaseCentral = data.DefaultPurchaseCentral;
		this.BookOpenClientsAndSuppliersAutomaticallyOnWaitLedger = data.BookOpenClientsAndSuppliersAutomaticallyOnWaitLedger;
	}

	public getJSON(){
		return {
			DefaultSaleCentral: this.DefaultSaleCentral,
			DefaultPurchaseCentral: this.DefaultPurchaseCentral,
			BookOpenClientsAndSuppliersAutomaticallyOnWaitLedger: this.BookOpenClientsAndSuppliersAutomaticallyOnWaitLedger
		};
	}
}