
import Vue from "vue";
import AppBrowseToFileSettings from "./BrowseToFileSettings.vue";
import AppBrowseToFileProgress from "./BrowseToFileProgress.vue";
import {BrowseStateBase} from "@/utils/browse/browse-state-base";
import { IPrintBrowseSettings, PrintBrowseRunner } from "@/utils/browse/browse-to-file";

export default Vue.extend({
	data(){
		return {
			runner: null as null | PrintBrowseRunner
		};
	},
	props: {
		state: {
			type: Object as ()=>BrowseStateBase,
			required: true
		},
	},
	methods: {
		async printBrowse(data:IPrintBrowseSettings){
			if (this.runner) return;
			try{
				let parent = this.$parent;
				if (!parent) return;
				parent = parent.$parent;
				if (!parent) return;
				let table = parent.$el.getElementsByClassName("browse-table").item(0);
				if (!table) return;
				let head = table.getElementsByTagName("thead").item(0);
				if (!head) return;
				let body = table.getElementsByTagName("tbody").item(0);
				if (!body) return;
				this.runner = new PrintBrowseRunner(data, this.state, head, body);
			}catch(err){
				this.$wf.notify(err, "danger", 3000, "exclamataion-triangle");
			}
		},
		close(){
			this.state.browseToFileModalOpen = false;
		}
	},
	components: {
		AppBrowseToFileSettings,
		AppBrowseToFileProgress
	},
	i18nEx: {
		componentPrefix: "components.browse"
	}
});
