import { AwsDocument } from "@/models/base/aws-document";
import { v4 } from "uuid";

export enum ModalType {
	IFRAME = "iframe"
}

export interface IModal {
	uid:string;
	type:ModalType;
	data:any;
	title?:string;
	options:any;
	width?:number;
	height?:number;
}

export interface IFrameModalOptions {
	padded?:boolean;
	width?:number;
	height?:number;
}

export class ModalServiceClass {
	public modals:IModal[] = [];
	public openImages:string[] = [];
	public openImageLists:string[][] = [];
	public openedAwsDocumentStates:AwsDocument[][] = [];

	public openIframeModal(link:string, title:string, options:IFrameModalOptions = {}){
		this.modals.push({type: ModalType.IFRAME, data: link, title, uid: v4(), options, width: options.width, height: options.height});
	}

	public openImageUrl(imageUrl:string){
		if (!imageUrl) return;
		this.openImages.push(imageUrl);
	}

	public openImageUrlList(imageUrls:string[]){
		if (imageUrls.length == 0) return;
		this.openImageLists.push(imageUrls);
	}

	public openAwsDocuments(documents:AwsDocument[]) {
		this.openedAwsDocumentStates.push(documents);
	}
}

export const ModalService = new ModalServiceClass();