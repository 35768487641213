import { InboxreaderPurchaseFile } from "./inboxreader-purchase-file";

export class InboxreaderPurchase {
	ID:number = 0;
	Text:string = "";
	Subject:string = "";
	From:string = "";
	OriginalFrom:string = "";
	To:string = "";
	DateCreated:Date = new Date();
	MessageID:string = ""
	Files:InboxreaderPurchaseFile[] = [];
	FilesCount:number = 0;

	public constructor(data?:any){
		if (!data) return;
		this.ID = data.ID;
		this.Text = data.Text;
		this.Subject = data.Subject;
		this.From = data.From;
		this.OriginalFrom = data.OriginalFrom;
		this.To = data.To;
		this.DateCreated = new Date(data.DateCreated);
		this.MessageID = data.MessageID;
		this.Files = (data.Files || []).map((d:any)=>new InboxreaderPurchaseFile(d));
		this.FilesCount = data.FilesCount;
	}
}