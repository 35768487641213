import { CustomTranslation } from "@/utils/custom-translation";


export class SaleLayoutTextSettings {
	public PageNumber = new CustomTranslation();
	public Duplicate = new CustomTranslation();

	constructor(data?: any) {
		if (!data) {
			return;
		}
		this.PageNumber = new CustomTranslation(data.PageNumber);
		this.Duplicate = new CustomTranslation(data.Duplicate);
	}

	public getJSON() {
		return {
			PageNumber: this.PageNumber.getJSON(),
			Duplicate: this.Duplicate.getJSON(),
		};
	}
}
