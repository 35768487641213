import { i18n } from "@/setup/i18n-setup";

export class HttpError {
	message:string;
	key:string;
	data:string[];
	status:number;

	isNetworkError:boolean = false;
	dontShow:boolean = false;

	constructor(data:any, status:number){
		this.message = data.message;
		this.key = data.key;
		this.data = data.data || [];
		this.status = status;
	}

	getText():string{
		let data = [...this.data];
		if (this.key == "MISSING_MODULE") {
			data[0] = i18n.t(`common.modules.${data[0]}`).toString();
		}
		let text = i18n.t(`errors.${this.key}`).toString();
		if (text == ""){
			return this.message;
		}
		for (let i = this.data.length - 1; i >= 0; i--){
			let reg = new RegExp(`\\$wf${i}`, "g");
			text = text.replace(reg, data[i]);
		}
		return text;
	}
}