
export class ContactAutofill {
	public ondernemingsnummer?: string;
	public status?: string;
	public rechtstoestand?: string;
	public beginDatum?: string;
	public maatschappelijkeNaam?: string;
	public address?: string;
	public postal?: string;
	public city?: string;
	public tel?: string;
	public fax?: string;
	public email?: string;
	public webaddress?: string;
	public type?: string;
	public rechtsvorm?: string;
	public firstName?: string;
	public lastName?: string;
	public country?: string;

	constructor(data: any) {
		for (let key of Object.keys(data)) {
			(this as any)[key] = data[key];
		}
	}

	public static splitAddressIntoAddressAndNumber(value:string):{address:string, nr:string} {
		let split = value.trim().split(/\s+/);
		let nr = split.pop() || "";
		let result = {
			address: split.join(" ") || nr,
			nr
		};
		if (result.nr == result.address) {
			result.nr = "";
		}
		return result;
	}
}
