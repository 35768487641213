
import { AskedFilter } from "@/utils/report-engine-settings/asked-filter";
import Vue from "vue";
import AppFilterInput from "@/components/models/FilterInput.vue";

export default Vue.extend({
	props: {
		filter: {
			type: Object as ()=>AskedFilter,
			required: true
		},
		fieldType: {
			type: String,
			required: true
		},
	},
	computed: {
		valueAmount(): number {
			return this.filter.getValueAmount();
		},
	},
	methods: {
		addValue() {
			this.filter.Values.push("");
		},
		removeValue(i: number) {
			this.filter.Values.splice(i, 1);
		},
	},
	components: {
		AppFilterInput
	},
	i18nEx: {
		componentPrefix: "components.models"
	}
});
