
import { Vat } from "@/models/base";
import { VatService } from "@/services/vat-service";
import Vue from "vue";
import { TranslateResult } from "vue-i18n";
export default Vue.extend({
	props: {
		value: {
			type: Object as ()=>Vat,
			required: true
		}
	},
	computed: {
		title():TranslateResult {
			if (!this.value) return "";
			if (this.value.ID == 0) {
				return this.$ct("add-new-vat-percentage");
			}
			return this.$ct("edit-vat-percentage");
		},
	},
	methods: {
		cancel(){
			this.$emit("close");
		},
		async save(){
			if (!this.value) return;
			this.value.Name = this.value.Value.toString();
			if (this.value.ID == 0) {
				var result = await VatService.createVat(this.value);
			}else{
				var result = await VatService.updateVat(this.value);
			}
			this.$emit("input", result);
			this.$emit("close");
		}
	},
	i18nEx: {
		componentPrefix: "components.sale-settings"
	}
});
