
import Vue from "vue";
import { UserInfo } from "@/models/dossier/user-info";
import { Contact } from "@/models/base";
import { ISelectItem } from "winfakt-vue-components";
import { DossierService } from "@/services/dossier-service";


export default Vue.extend({
	data(){
		return {
			users: [] as UserInfo[],
		};
	},
	props: {
		contact: {type: Object as () => Contact},
	},
	computed: {
		items():ISelectItem<number>[] {
			let result:ISelectItem<number>[] = this.users.map((u) => {
				return {
					text: u.Firstname ? (u.Firstname + " " + u.Lastname) : u.Email,
					value: u.ID
				};
			});
			return result;
		}
	},
	methods: {
		onInput(value:number) {
			this.contact.DefaultCreatorID = value;
		}
	},
	async created(){
		this.users = await DossierService.getUsers();

	},
	i18nEx: {
		componentPrefix: "components.contacts"
	}
});
