export class StockMovement {
	Type:"IN" | "OUT" = "IN";
	ID:number = 0;
	ProductID:number = 0;
	LocationID:number = 0;
	Qty:number = 0;
	Sku:string = "";
	Name:string = "";
	DateCreated:Date = new Date();
	IsBeginStock:string = "";
	SaleNr:string = "";

	Location1Qty:number = 0;
	Location2Qty:number = 0;
	Location3Qty:number = 0;
	Location4Qty:number = 0;
	Location5Qty:number = 0;
	Location6Qty:number = 0;
	Location7Qty:number = 0;
	Location8Qty:number = 0;
	Location9Qty:number = 0;

	public constructor(data?:any){
		if (!data) return;
		this.Type = data.Type;
		this.ID = data.ID;
		this.ProductID = data.ProductID;
		this.LocationID = data.LocationID;
		this.Qty = data.Qty;
		this.Sku = data.Sku;
		this.Name = data.Name;
		this.DateCreated = new Date(data.DateCreated);
		this.IsBeginStock = data.IsBeginStock;
		this.SaleNr = data.SaleNr;
		(this as any)[`Location${this.LocationID}Qty`] = this.Qty;
	}

	static isSameRecord(val1:StockMovement, val2:StockMovement):boolean{
		return val1.ID == val2.ID && val1.Type == val2.Type;
	}
}