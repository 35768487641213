import { DocumentLayoutLogoBase } from "./layout-logo-base";

export type DocumentLayoutLogoPosition = "Heading" | "Body" | "Footer";
export class DocumentLayoutLogo extends DocumentLayoutLogoBase {
	public Placement: DocumentLayoutLogoPosition = "Heading";

	public constructor(data?:any){
		super(data);
		if (!data) return;
		this.Placement = data.Placement;
	}

	public getJSON(){
		let result = super.getJSON() as any;
		result.Placement = this.Placement;
		return result;
	}
}