import { StringStyledProperty } from "./documents/string-styled-property";

export class ReportHeading {
	Heading1: StringStyledProperty = new StringStyledProperty();
	Heading2: StringStyledProperty = new StringStyledProperty();
	Heading3: StringStyledProperty = new StringStyledProperty();

	constructor(data?:any) {
		if (!data) return;
		this.Heading1 = new StringStyledProperty(data.Heading1);
		this.Heading2 = new StringStyledProperty(data.Heading2);
		this.Heading3 = new StringStyledProperty(data.Heading3);
	}

	getJSON() {
		return {
			Heading1: this.Heading1.getJSON(),
			Heading2: this.Heading2.getJSON(),
			Heading3: this.Heading3.getJSON(),
		};
	}

	getHeadings():StringStyledProperty[] {
		let result = [] as StringStyledProperty[];
		for (let i = 1; i <= 3; i++) {
			result.push(this[("Heading" + i) as keyof ReportHeading] as StringStyledProperty);
		}
		return result;
	}
}