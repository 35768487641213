import { v4 } from "uuid";
import { Model } from "../models/model";

export enum BarcodeFormat {
    CODE128="CODE128",
    CODE128A="CODE128A",
    CODE128B="CODE128B",
    CODE128C="CODE128C",
	UPC="UPC",
    EAN13="EAN13",
	EAN8="EAN8",
	EAN5="EAN5",
	EAN2="EAN2",
    CODE39="CODE39",
    ITF14="ITF14",
    MSI="MSI",
	MSI10="MSI10",
	MSI11="MSI11",
	MSI1010="MSI1010",
	MSI1110="MSI1110",
    pharmacode="pharmacode",
    codabar="codabar",
}

export class Barcode{
	key:string = v4();
	Format:BarcodeFormat = BarcodeFormat.EAN13;
	Prop:string = "";
	PreText:string = "";
	PostText:string = ""

	X:number = 0;
	Y:number = 0;
	Width:number = 10;
	Height:number = 10;

	public constructor(data?:any) {
		if (!data) return;
		this.Format = data.Format || BarcodeFormat.EAN13;
		this.Prop = data.Prop;
		this.PreText = data.PreText;
		this.PostText = data.PostText;
		this.X = data.X;
		this.Y = data.Y;
		this.Width = data.Width;
		this.Height = data.Height;
	}


	public getJSON():any{
		return {
			Format: this.Format,
			Prop: this.Prop,
			PreText: this.PreText,
			PostText: this.PostText,
			X: this.X,
			Y: this.Y,
			Width: this.Width,
			Height: this.Height
		};
	}

	public getPropName(model:Model):string{
		if (this.Prop){
			let field = model.getField(this.Prop);
			if (field) {
				return field.getPropertyTranslation();
			}
		}
		return "";
	}

	public getAsText(model:Model):string{
		return `${this.PreText}${this.getPropName(model)}${this.PostText}`;
	}

	public getName(model:Model):string{
		return this.getAsText(model) || "LEEGE BARCODE";
	}

	private computePropText(record:any):string {
		if (!this.Prop) return "";
		let propParts = this.Prop.split(".");
		let propValue = record;
		for (let part of propParts){
			if (!propValue) return "";
			propValue = propValue[part];
		}
		return `${propValue || ""}`;
	}

	private computeStyle():string{
		return `width:${this.Width}mm;height:${this.Height}mm;left:${this.X}mm;top:${this.Y}mm`;
	}

	public compute(record:any):string{
		let value =  `${this.PreText}${this.computePropText(record)}${this.PostText}`;
		if (!value) return "";
		let div = document.createElement("div");
		div.className = "barcode-container";
		div.style.setProperty("width", `${this.Width}mm`);
		div.style.setProperty("height", `${this.Height}mm`);
		div.style.setProperty("left", `${this.X}mm`);
		div.style.setProperty("top", `${this.Y}mm`);
		let svg = document.createElement("svg");
		svg.setAttribute("style", this.computeStyle());
		svg.className = "barcode";
		svg.setAttribute("jsbarcode-format", this.Format);
		svg.setAttribute("jsbarcode-value", value);
		svg.setAttribute("preserveAspectRatio", "none");
		svg.setAttribute("jsbarcode-displayValue", "false");
		div.appendChild(svg);
		return div.outerHTML;
	}
}