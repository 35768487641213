
import Vue from "vue";
import { ReportEngineSettingsModel } from "../../models/report-engine-settings";
import { ReportService } from "../../services/report-service";
import { createConfirmBuilder } from "../../setup/confirm-builder";
import { ReportEngineSettings } from "../../utils/report-engine-settings/report-engine-settings";

export default Vue.extend({
	data() {
		return {
			items: [] as ReportEngineSettingsModel[],
			name: ""
		};
	},
	props: {
		settings: {
			type: Object as () => ReportEngineSettings,
			required: true
		},
		browseId: {
			type: Number,
			required: true
		}
	},
	methods: {
		cancel() {
			this.$emit("close");
		},
		async save() {
			if (this.name.match(/^\s*$/)) {
				this.$wf.notify(this.$ct("error-name-empty").toString(), "danger", 3000, "exclamation-triangle");
				return;
			}

			let settings = new ReportEngineSettingsModel();
			settings.Name = this.name;
			settings.BrowseID = this.browseId;
			settings.Settings = this.settings;

			settings = await ReportService.createReportEngineSetting(settings);
			this.$emit("save", settings);
			this.$emit("close");
		},
		async overwriteSave(item:ReportEngineSettingsModel) {
			let confirm = await createConfirmBuilder.bind(this)(this.$ct("confirm-overwrite-save", {name: item.Name})).setWarningTitle().setClass("warning").show();
			if (!confirm) {
				return;
			}
			item.Settings = this.settings;
			item = await ReportService.updateReportEngineSettings(item);
			this.$emit("save", item);
			this.$emit("close");
		}
	},
	async mounted() {
		let result = await ReportService.getReportEngineSettingsByBrowseId(this.browseId);
		this.items = result.savedReports;
	},
	i18nEx: {
		componentPrefix: "components.reportengine"
	}
});
