
import Vue from "vue";
import AppMissingModule from "@/components/common/MissingModule.vue";
import { TranslateResult } from "vue-i18n";

export default Vue.extend({
	props: {
		moduleId: {
			type: Number,
			required: true
		},
		title: {
			type: [String, Object as ()=>TranslateResult],
		},
		disableMaxWidth: Boolean
	},
	computed: {
		moduleName(): string {
			return this.$ct("common.modules." + this.moduleId).toString();
		},
		upgrade(): boolean {
			return this.moduleId < 1;
		},
		shownTitle():String | TranslateResult{
			if (this.title) {
				return this.title;
			}
			return this.$ct("title");
		},
		maxWidth():string{
			if (!this.disableMaxWidth){
				return "400";
			}
			return "";
		}
	},
	methods: {
		cancel(){
			this.$emit("close");
		}
	},
	components: {
		AppMissingModule
	},
	i18nEx: {
		componentPrefix: "components.common-MissingModule"
	}
});
