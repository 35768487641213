import { getFileUrl } from "@/utils/url";
import { v4 } from "uuid";

export class AWSFile {
	readonly uid:string = v4();
	ID:number;
	Path:string;
	Name:string;
	Token:string;
	ContentType:string;

	constructor(data:any) {
		this.ID = data.ID;
		this.Path = data.Path;
		this.Name = data.Name;
		this.Token = data.Token;
		this.ContentType = data.ContentType;
	}

	getUrl():string {
		return getFileUrl(this.Token);
	}

	static previewableContentTypes():string[] {
		return [
			"image/png",
			"image/gif",
			"image/jpg",
			"image/jpeg",
			"application/pdf",
			"application/octet-stream"
		];
	}

	canPreview():boolean {
		return AWSFile.previewableContentTypes().indexOf(this.ContentType) != -1;
	}
}