import { i18n } from "@/setup/i18n-setup";
import { PropSelectGroup } from "./prop-select-group";
import { PropSelectItem } from "./prop-select-item";

let rootfields = [
	"ID",
	"Plate",
	"Mileage",
	"Brand",
	"Chassis",
	"Engine",
	"Type",
	"ConstructionYear",
	"CommDate",
	"PurchaseDate",
	"InvoiceDate"
];

export function getCarMapPropSelectGroup():PropSelectGroup{
	let result = new PropSelectGroup("", "", "");
	for (let item of rootfields) {
		result.items.push(new PropSelectItem(i18n.t(`models.map.Car.${item}`).toString(), item));
	}
	return result;
}