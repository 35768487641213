
import { MODULE } from "@/config/modules";
import { AuthService } from "@/services/auth-service";
import { ReportEngineSettings, ReportType } from "@/utils/report-engine-settings/report-engine-settings";
import Vue from "vue";
import { ISelectItem } from "winfakt-vue-components";
export default Vue.extend({
	props: {
		value: {
			type: Object as ()=>ReportEngineSettings,
			required: true
		}
	},
	computed: {
		reportTypeItems():ISelectItem<ReportType>[]{
			let result = [{
				text: "PDF",
				value: "pdf"
			}] as ISelectItem<ReportType>[];

			if (AuthService.hasModule(MODULE.EXPORT_NAAR_EXCEL)){
				result.push({
					text: "Excel",
					value: "excel"
				});
			}

			return result;
		}
	},
	i18nEx: {
		componentPrefix: "components.reportengine"
	}
});
