export class GeneralDossierConfig {
	BookYearStartMonth: number = 0;
	BookYearStartDate:number = 1;
	HideInitModal: boolean = false;
	ShowQuickStart: boolean = false;
	DefaultBankTypeID: number = 1;
	DefaultConvertSaleToCreditNoteBankTypeID:number = 1;
	DefaultCashPaymentBankTypeID:number = 9;
	DefaultCashRoundingLedgerAccountCode:string = "499000";
	DefaultPaymentDifferenceBankTypeID:number = 8;
	DefaultLedgerAccountCode:string = ""
	UnitFieldName:string = "";
	AutoShopSynch:boolean = false;

	constructor(data?: any) {
		if (!data) return;
		this.BookYearStartMonth = data.BookYearStartMonth;
		this.BookYearStartDate = data.BookYearStartDate;
		this.HideInitModal = data.HideInitModal;
		this.ShowQuickStart = data.ShowQuickStart;
		this.DefaultBankTypeID = data.DefaultBankTypeID;
		this.DefaultConvertSaleToCreditNoteBankTypeID = data.DefaultConvertSaleToCreditNoteBankTypeID;
		this.DefaultCashPaymentBankTypeID = data.DefaultCashPaymentBankTypeID;
		this.DefaultCashRoundingLedgerAccountCode = data.DefaultCashRoundingLedgerAccountCode;
		this.DefaultPaymentDifferenceBankTypeID = data.DefaultPaymentDifferenceBankTypeID ? data.DefaultPaymentDifferenceBankTypeID : 8;
		this.DefaultLedgerAccountCode = data.DefaultLedgerAccountCode || "";
		this.UnitFieldName = data.UnitFieldName || "";
		this.AutoShopSynch = data.AutoShopSynch || false;
	}

	getJSON() {
		return {
			BookYearStartMonth: this.BookYearStartMonth,
			BookYearStartDate: this.BookYearStartDate,
			HideInitModal: this.HideInitModal,
			ShowQuickStart: this.ShowQuickStart,
			DefaultBankTypeID: this.DefaultBankTypeID,
			DefaultConvertSaleToCreditNoteBankTypeID: this.DefaultConvertSaleToCreditNoteBankTypeID,
			DefaultCashPaymentBankTypeID: this.DefaultCashPaymentBankTypeID,
			DefaultCashRoundingLedgerAccountCode: this.DefaultCashRoundingLedgerAccountCode,
			DefaultPaymentDifferenceBankTypeID: this.DefaultPaymentDifferenceBankTypeID,
			DefaultLedgerAccountCode: this.DefaultLedgerAccountCode,
			UnitFieldName: this.UnitFieldName,
			AutoShopSynch: this.AutoShopSynch
		};
	}
}