
import {Product} from "@/models/base";
import Vue from "vue";
import {AuthService} from "@/services";
import {MODULE} from "@/config/modules";
import AppProductEditIntrastatData from "./ProductEditIntrastatData.vue";

export default Vue.extend({
	props: {
		product: {
			type: Object as () => Product,
			required: true
		}
	},
	computed: {
		hasLeeggoedModule(): boolean {
			return AuthService.hasModule(MODULE.DRANKENCENTRALE);
		},
	},
	components: {
		AppProductEditIntrastatData
	},
	i18nEx: {
		componentPrefix: "components.products"
	}
});
