
import Vue from "vue";
import { PrintBrowseRunner } from "@/utils/browse/browse-to-file";
import { TranslateResult } from "vue-i18n";

export default Vue.extend({
	data(){
		return {
			finished: 0
		};
	},
	props: {
		runner: {
			type: Object as ()=>PrintBrowseRunner,
			required: true
		}
	},
	computed: {
		progressValue():number{
			if (this.runner.totalRowCount == 0) return 0;
			return this.finished/this.runner.totalRowCount * 100;
		},
		progressText():TranslateResult{
			return this.$ct("browse-to-file-progress", {count: this.runner.totalRowCount, finished: this.finished});
		}
	},
	methods: {
		cancel(){
			this.runner.cancel();
		}
	},
	async mounted(){
		this.runner.on("progress", (count:number)=>this.finished = count);
		try{
			await this.runner.run();
		}catch(err){
			if (!this.runner.canceled) {
				this.$wf.notify(err, "danger", 3000, "exclamataion-triangle");
			}
		}
		this.$emit("close");
	},
	i18nEx: {
		componentPrefix: "components.browse"
	}
});
