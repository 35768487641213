import { GoTemplateDataProperty, PropType } from "@/utils/go-template-data-structure/property";

export class GoTemplateDataObject extends GoTemplateDataProperty {
	name:string = "";
	properties:GoTemplateDataProperty[] = [];
	type = "object" as PropType;

	constructor(name:string, properties:GoTemplateDataProperty[]) {
		super();
		this.name = name;
		this.properties = properties;
		this.properties.forEach(c => {
			c.parent = this;
		});
	}
}
