
import Vue from "vue";
import VueI18n from "vue-i18n";
import {IModel} from "@/utils/models/model-interface";
import AppColumnSelectEdit from "./ColumnSelectEdit.vue";
import { Browse } from "@/models/browse";
import { BrowseService } from "@/services";
import { BrowseFavouriteColumns } from "@/models/browse-favourite-columns";

export default Vue.extend({
	data() {
		return {
			search: "",
			showFavourites: false,
			// Used to automatically open all folders of the selected value
			isInitialRender: true,
			BrowseService
		};
	},
	props: {
		value: {
			type: String,
		},
		model: {
			type: [String, Object as () => IModel],
			required: true
		},
		browse: {
			type: Object as ()=>Browse
		},
		label: [String, Object as () => VueI18n.LocaleMessage],
		isFilter: {
			type: Boolean
		},
		isServerColumn: Boolean,
		nummericOnly: Boolean,
		ignoredFields: {
			type: Array as ()=>string[],
			default: ()=>[]
		},
		previewValue: {
			type: Object as any
		}
	},
	computed: {
		_label(): string | VueI18n.LocaleMessage {
			if (this.label) {
				return this.label;
			}
			return this.$ct("label");
		},
		favourites():BrowseFavouriteColumns | null{
			if (!this.browse) return null;
			let result = this.BrowseService.getFavouriteColumnsByBrowseId(this.browse.ID);
			if (!result) return null;
			return result;
		}
	},
	watch: {
		"favourites.length"(){
			if (!this.favourites){
				this.showFavourites = false;
				return;
			}
			if (this.favourites.Columns.length == 0){
				this.showFavourites = false;
			}
		}
	},
	methods: {
		onInput(value: string) {
			this.$emit("input", value);
		},
		onLabel(value: string) {
			this.$emit("inputLabel", value);
		},
		onName(name:string) {
			this.$emit("inputLabel", name);
		},
		async setShowFavourites(){
			this.showFavourites = false;
			this.isInitialRender = true;
			this.resetInitialRender();
		},
		async resetInitialRender(){
			setTimeout(() => {
				this.isInitialRender = false;
			}, 100);
		}
	},
	mounted(){
		if (!this.favourites){
			this.showFavourites = false;
			return;
		}
		if (this.favourites.Columns.length > 0){
			this.showFavourites = true;
		}
		this.resetInitialRender();
	},
	components: {
		AppColumnSelectEdit
	},
	i18nEx: {
		componentPrefix: "components.models"
	}
});
