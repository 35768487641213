
import { BrowseService } from "@/services/browse-service";
import { ReportService } from "@/services/report-service";
import Vue from "vue";
import { DossierConfigService } from "../../services/dossier-config-service";
import { ReportEngineSettings, ReportType } from "../../utils/report-engine-settings/report-engine-settings";
import AppCreateForm from "./CreateForm.vue";
import { IModel } from "../../utils/models/model-interface";
import {ScriptTableStructure} from "@/utils/report-engine-settings/script-table-structure";
import { Script } from "../../models/script";
import AppSelectSourceCard from "./SelectSourceCard.vue";
import {DefaultReportEngineWrapper} from "@/utils/report-engine-settings/default-wrapper";
import AppMissingModule from "@/components/common/MissingModule.vue";
import { AuthService } from "@/services/auth-service";
import { MODULE } from "@/config/modules";

export default Vue.extend({
	data() {
		return {
			settings: new ReportEngineSettings(),
			pendingChanges: false,
			model: null as null | IModel,
			toCreateType: "",
			scriptArgsModal: false,
			script: null as Script | null,
			missingExcelModuleModal: false
		};
	},
	props: {
		browseId: {
			type: Number,
			required: true
		}
	},
	computed: {
		excelModule():MODULE{
			return MODULE.EXPORT_NAAR_EXCEL;
		}
	},
	watch: {
		settings: {
			deep: true,
			handler(settings: ReportEngineSettings, oldSettings: ReportEngineSettings) {
				if (settings != oldSettings) return;
				this.pendingChanges = true;
			}
		}
	},
	methods: {
		async close() {
			if(this.pendingChanges){
				let message = this.$ct("are-you-sure-you-want-to-cancel-your-unsaved-changes-will-be-lost");
				let confirm = await this.$confirmBuilder().setWarningTitle().setMessage(message).show();
				if (!confirm) return;
				this.$emit("close");
			}else{
				this.$emit("close");
			}
		},
		async create(reportType:ReportType){
			if (reportType == "excel" && !AuthService.wfCompany.hasModule(this.excelModule)){
				this.missingExcelModuleModal = true;
				return;
			}

			if(!this.settings.Columns.length){

				if (reportType == "excel"){
					await this.$wf.dialog(this.$ct("no-fields-selected-export"), this.$ct("common.Ok"), this.$ct("common.attention"), "warning");
					return;
				}
				await this.$wf.dialog(this.$ct("no-fields-selected"), this.$ct("common.Ok"), this.$ct("common.attention"), "warning");
				return;



			}

			let wrapper = new DefaultReportEngineWrapper(new ReportEngineSettings(this.settings.getJSON()));
			ReportService.openCreateReportModal(wrapper, reportType);
			//this.$emit("close");
		},
		async dataSourceSelected(src:string){
			this.settings.DataSource = src;
			if (src == "") {
				this.model = null;
				this.script = null;
				return;
			}
			this.script = null;
			if (this.settings.isSourceTypeBrowse()) {
				let browses = await BrowseService.getBrowses();
				let browse = browses.find(b=>b.ID == this.settings.getBrowseIDFromDataSource());
				if (!browse){
					throw new Error("Browse not found");
				}
				this.model = browse.type;
			} else if (this.settings.isSourceTypeScript() || this.settings.isSourceTypeStandardScript()){
				this.script = await ReportService.getScript(this.settings.DataSource);
				this.model = new ScriptTableStructure(JSON.parse(this.script.getValueOfStandardArg("structure")));
			}
		},
		onSave(){
			this.pendingChanges = false;
		}
	},
	mounted() {
		this.settings.PageSettings = DossierConfigService.getDefaultReportConfig().PageSettings;
		if(this.browseId){
			console.log("SETTING BROWSE");
			this.dataSourceSelected("browse/"+this.browseId);
		}
	},
	components: {
		AppCreateForm,
		AppSelectSourceCard,
		AppMissingModule
	},
	i18nEx: {
		componentPrefix: "components.reportengine"
	}
});
