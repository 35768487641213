
import Vue from "vue";
import AppSaleTypeSelect from "@/components/sales/SaleTypeSelect.vue";
import AppSaleTypeMultiSelect from "@/components/sales/SaleTypeMultiSelect.vue";
import { TranslateResult } from "vue-i18n";

export default Vue.extend({
	data(){
		return {
			inputValues: [] as number[],
			ready: false
		};
	},
	props: {
		value: {
			type: [String, Array as ()=>string[]],
			required: true
		},
		multi: Boolean,
		label: {
			type: [Object as ()=>TranslateResult, String],
			default():TranslateResult{
				if (this.multi) return this.$ct("journals");
				return this.$ct("journal");
			}
		}
	},
	computed: {
		singleValue():number {
			if (this.multi) return 1;
			return this.inputValues[0];
		}
	},
	watch: {
		inputValues: {
			deep: true,
			handler(){
				if (!this.ready) return;
				if (this.multi && typeof(this.value) == "object") {
					this.value.splice(0, this.value.length);
					this.value.push(...(this.inputValues.map(v=>`${v}`)));
				}
			}
		}
	},
	methods: {
		parseNum(num:string):number{
			let result = parseInt(num || "0");
			if (Number.isNaN(result)) return 0;
			return result;
		},
		setValue(val:number){
			this.inputValues[0] = val;
			this.$emit("input", `${this.inputValues[0]}`);
		}
	},
	mounted(){
		if (typeof(this.value) == "string"){
			this.inputValues.push(this.parseNum(this.value));
		}else{
			this.inputValues.push(...this.value.filter(f=>f!="").map(v=>this.parseNum(v)));
		}
		this.$nextTick(()=>{
			this.ready = true;
		});
	},
	components: {
		AppSaleTypeSelect,
		AppSaleTypeMultiSelect
	},
	i18nEx: {
		componentPrefix: "components.models"
	}
});
