import { BorderStyle } from "../documents/styled-property";
import { TextAlignment, TextStyledProperty } from "../documents/text-styled-property";
import { TextIfValue } from "./text-if-value";

export type SettingsColumnType = "default" | "sum"

export interface ISumColumn {
	column:string;
	subtract:boolean;
}

export class SettingsColumn {
	Width:number = 32.0;
	Value:string = "";
	Total:boolean = false;
	Cumulative:boolean = false;
	Name:string = "";
	Fallback:string = "";
	WordWrap:boolean = false;
	TextIfValueSettings:TextIfValue[] = [];
	Style:TextStyledProperty = SettingsColumn.getDefaultStyle();

	constructor(data?:any) {
		if(!data)return;
		this.Width = data.Width;
		this.Value = data.Value;
		this.Total = data.Total;
		this.Name = data.Name;
		this.Cumulative = data.Cumulative;
		this.Fallback = data.Fallback;
		this.WordWrap = data.WordWrap || false;
		this.Style = new TextStyledProperty(data.Style);
		this.TextIfValueSettings = (data.TextIfValueSettings || []).map((t:any)=>new TextIfValue(t));
	}

	getColumnType():SettingsColumnType {
		if (this.Value.startsWith("{{sum}}")) return "sum";
		return "default";
	}

	getSumColumns():ISumColumn[] {
		if (this.getColumnType() != "sum") return [];
		let cols = this.Value.substring(7).split(",");
		return cols.filter(c=>c != "").map(c=>{
			let subtract = c.charAt(0) == "-";
			return {
				column: c.substring(1),
				subtract
			} as ISumColumn;
		});
	}

	getJSON():any {
		return {
			Width: this.Width,
			Value: this.Value,
			Total: this.Total,
			Name: this.Name,
			Cumulative: this.Cumulative,
			Fallback: this.Fallback,
			WordWrap: this.WordWrap,
			TextIfValueSettings: this.TextIfValueSettings.map(t=>t.getJSON()),
			Style: this.Style.getJSON()
		};
	}

	static getDefaultStyle():TextStyledProperty{
		let style = new TextStyledProperty();
		style.TextAlign = TextAlignment.LEFT;
		style.Color = "#000000";
		style.Font = "sans-serif";
		style.FontSize = 9;
		style.BackgroundColor = "#00000000";
		style.BorderBottomWidth = 0.5;
		style.BorderWidth = 0;
		style.BorderStyle = BorderStyle.SOLID;
		style.BorderColor = "#d5d5d5";
		style.PaddingLeft = 0.4;
		style.PaddingRight = 0.4;
		style.PaddingTop = 0.4;
		style.PaddingBottom = 0.4;
		return style;
	}
}