import { model } from "@/utils/models/decorator-model";
import { field } from "@/utils/models/decorator-field";
import { printCrmStatus } from "@/utils/pretty-print";


@model("CrmStatus")

export default class CrmStatus {
	@field("number")
	public ID: number = 0;

	@field("string")
	public Name: string = "";

	@field("string")
	public PrettyName: string = "";

	@field("number")
	public Position: number = 0;

	constructor(data?: any) {
		if (!data) return;
		this.ID = data.ID;
		this.Name = data.Name;
		this.Position= data.Position;
		this.PrettyName = printCrmStatus(this);
	}


	getJSON() {
		return {
			ID: this.ID,
			Name: this.Name,
			Position: this.Position,
			PrettyName: this.PrettyName,
		};
	}
}
