
import Vue from "vue";
import AppSelectJournal from "@/components/SelectJournal.vue";


export default Vue.extend({
	data(){
		return {
			val: 0
		};
	},
	props: {
		value: String,
		label: String
	},
	watch: {
		val: {
			immediate: true,
			deep: true,
			handler(){
				this.$emit("input", JSON.stringify(this.val));
			}
		},
	},
	methods: {
		onInput(value: Number){
			this.$emit("input", JSON.stringify(value));
		}
	},
	mounted(){
		this.$emit("input", JSON.stringify(this.val));
	},
	components: {
		AppSelectJournal
	}
});
