import { AWSFile } from "@/models/aws-file";

export class InboxreaderPurchaseFile {
	ID:number = 0;
	PurchaseID:number = 0;
	FileID:number = 0;
	File:AWSFile | null = null;

	public constructor(data?:any) {
		if(!data) return;
		this.ID = data.ID;
		this.PurchaseID = data.PurchaseID;
		this.FileID = data.FileID;
		if (data.File) {
			this.File = new AWSFile(data.File);
		}
	}

	getName():string{
		if (!this.File) return "ERROR";
		return this.File.Name;
	}
}