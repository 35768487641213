import { Condition } from "../condition/condition";

export enum SaleLayoutAttachmentPosition {
	BACK_SIDE = "back-side",
	END = "end"
}

export class SaleLayoutAttachment {
	FileName:string = ""
	PositionType:SaleLayoutAttachmentPosition = SaleLayoutAttachmentPosition.BACK_SIDE;
	AddOnPrint:boolean = true;
	AddOnEmailSend:boolean = true;
	AddOnDownload:boolean = true;
	Conditions:Condition[] = [];

	public constructor(data?:any){
		if (!data) return;
		this.FileName = data.FileName;
		this.PositionType = data.PositionType;
		this.AddOnPrint = data.AddOnPrint;
		this.AddOnDownload = data.AddOnDownload;
		this.AddOnEmailSend = data.AddOnEmailSend;
		this.Conditions = ((data.Conditions || []) as any[]).map(c=>new Condition(c));
	}

	public getJSON(){
		return {
			FileName: this.FileName,
			PositionType: this.PositionType,
			AddOnPrint: this.AddOnPrint,
			AddOnDownload: this.AddOnDownload,
			AddOnEmailSend: this.AddOnEmailSend,
			Conditions: this.Conditions.map(c=>c.getJSON())
		};
	}
}