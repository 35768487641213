/* eslint-disable max-lines-per-function */
import { SaleRow } from "@/models/base";
import { ProductService, VatService } from "@/services";
import { FrontendFunc } from "../frontend-func";
import { SaleEditState } from "./sale-edit-state";
import { SharedFuncReference } from "./shared-func-reference";

type AutoSaleRowSettingCheckFunction = (state:SaleEditState)=>string;
type AutoSaleRowSettingFunction = (state:SaleEditState, row:SaleRow)=>Promise<string>;

export class AutoSaleRowSetting {
	ID:number = 0;
	Description:string = "Nieuwe instelling";
	Funcs:FrontendFunc[] = [];
	Amount1:AutoSaleRowSettingFunction | null = null;
	Amount2:AutoSaleRowSettingFunction | null = null;
	ProductSku:AutoSaleRowSettingFunction | null = null;
	ProductDescription:AutoSaleRowSettingFunction | null = null;
	UnitPrice:AutoSaleRowSettingFunction | null = null;
	LeeggoedTax:AutoSaleRowSettingFunction | null = null;
	EcoboniTax:AutoSaleRowSettingFunction | null = null;
	AccijnzenTax:AutoSaleRowSettingFunction | null = null;
	VatID:AutoSaleRowSettingFunction | null = null;
	ShouldRunScript:AutoSaleRowSettingCheckFunction | null = null;
	VatRegimeFilter:number[] = [];
	SaleTypeFilter:number[] = [];
	SharedFuncList:SharedFuncReference[] = [];

	constructor(data:any) {
		this.ID = data.ID;
		this.Description = data.Description;
		this.Funcs = (data.Funcs || []).map((f:any)=>new FrontendFunc(f));
		this.SharedFuncList = (data.SharedFuncList || []).map((f:any)=>new SharedFuncReference(f));
		this.Amount1 = this.createFunction(data.Amount1);
		this.Amount2 = this.createFunction(data.Amount2);
		this.ProductSku = this.createFunction(data.ProductSku);
		this.ProductDescription = this.createFunction(data.ProductDescription);
		this.UnitPrice = this.createFunction(data.UnitPrice);
		this.LeeggoedTax = this.createFunction(data.LeeggoedTax);
		this.EcoboniTax = this.createFunction(data.EcoboniTax);
		this.AccijnzenTax = this.createFunction(data.AccijnzenTax);
		this.VatID = this.createFunction(data.VatID);
		this.ShouldRunScript = this.createCheckFunction(data.ShouldRunScript);
		this.VatRegimeFilter = [...(data.VatRegimeFilter || [])];
		this.SaleTypeFilter = [...(data.SaleTypeFilter || [])];
	}

	private createCheckFunction(value:string):AutoSaleRowSettingCheckFunction | null {
		if (!value) return null;
		let code = "";
		for (let func of this.Funcs) {
			let thisCodeFunc = `function ${func.Name}(${func.Params.map(p=>p.Name).join(",")}){\n${func.Code}\n};\n`;
			code += thisCodeFunc;
		}
		for (let func of this.SharedFuncList){
			let thisCodeFunc = `function ${func.Alias}(${func.SharedFunc.Params.map(p=>p.Name).join(",")}){\n${func.SharedFunc.Code}\n};\n`;
			code += thisCodeFunc;
		}
		code += `return \`${value}\`;`;
		return (new Function("return function(){}"))().constructor("state", code) as any as AutoSaleRowSettingCheckFunction;
	}

	private createFunction(value:string):AutoSaleRowSettingFunction | null {
		if (!value) return null;
		let code = "";
		for (let func of this.Funcs) {
			let thisCodeFunc = `function ${func.Name}(${func.Params.map(p=>p.Name).join(",")}){\n${func.Code}\n};\n`;
			code += thisCodeFunc;
		}
		for (let func of this.SharedFuncList){
			let thisCodeFunc = `function ${func.Alias}(${func.SharedFunc.Params.map(p=>p.Name).join(",")}){\n${func.SharedFunc.Code}\n};\n`;
			code += thisCodeFunc;
		}
		code += `return \`${value}\`;`;
		return (new Function("return async function(){}"))().constructor("state", "row", code) as any as AutoSaleRowSettingFunction;
	}

	private async runFunction(func:AutoSaleRowSettingFunction | null, state:SaleEditState, row:SaleRow):Promise<string> {
		if (!func) return "";
		return func(state, row);
	}

	private async runNumberFunc(func:AutoSaleRowSettingFunction | null, state:SaleEditState, row:SaleRow):Promise<number> {
		let result = await this.runFunction(func, state, row);
		if (!result){
			return 0;
		}
		return parseFloat(result);
	}

	private runSettingRemoveRow(state:SaleEditState){
		for (let i = state.sale.Rows.length-1;i>=0;i--){
			if (state.sale.Rows[i].CreatedByAutoSaleRowSettingID == this.ID) {
				state.sale.Rows.splice(i,1);
			}
		}
	}

	public async runSetting(state:SaleEditState){
		if (!this.shouldRun(state)){
			this.runSettingRemoveRow(state);
			return;
		}
		let row = new SaleRow();
		let foundIndex = -1;
		let highestDisplayOrder = 0;
		for (let i = 0; i < state.sale.Rows.length;i++){
			let foundRow = state.sale.Rows[i];
			if (foundRow.CreatedByAutoSaleRowSettingID == this.ID) {
				foundIndex = i;
				row = foundRow;
			}else if (foundRow.DisplayOrder > highestDisplayOrder){
				highestDisplayOrder = foundRow.DisplayOrder;
			}
		}
		row.CreatedByAutoSaleRowSettingID = this.ID;
		row.ProductSku = await this.runFunction(this.ProductSku, state, row);
		if (row.ProductSku){
			let foundRow = state.sale.Rows[foundIndex];
			if (foundRow && foundRow.Product && foundRow.Product.Sku != row.ProductSku){
				row.Product = state.sale.Rows[foundIndex].Product;
			}else{
				row.Product = await ProductService.getProductBySku(row.ProductSku);
			}
		}
		row.Amount1 = await this.runNumberFunc(this.Amount1, state, row);
		row.Amount2 = await this.runNumberFunc(this.Amount2, state, row);
		row.Amount = row.Amount1 * row.Amount2;
		row.ProductDescription = await this.runFunction(this.ProductDescription, state, row);
		row.UnitPrice = await this.runNumberFunc(this.UnitPrice, state, row);
		row.LeeggoedTax = await this.runNumberFunc(this.LeeggoedTax, state, row);
		row.EcoboniTax = await this.runNumberFunc(this.EcoboniTax, state, row);
		row.AccijnzenTax = await this.runNumberFunc(this.AccijnzenTax, state, row);
		let vatId = await this.runNumberFunc(this.VatID, state, row);
		if (vatId) {
			row.Vat = VatService.getVatById(vatId).Value;
		}else{
			row.Vat = 0;
		}
		row.DisplayOrder = highestDisplayOrder + 1;
		if (foundIndex!=-1){
			state.sale.Rows.splice(foundIndex,1);
		}
		state.sale.Rows.push(row);
		row.calculateTotals(state.sale);
	}

	private shouldRun(state:SaleEditState):boolean{
		if (this.SaleTypeFilter.length > 0) {
			if (this.SaleTypeFilter.indexOf(state.sale.TypeID) == -1) return false;
		}
		if (this.VatRegimeFilter.length > 0) {
			if (this.VatRegimeFilter.indexOf(state.sale.VatRegime) == -1) return false;
		}
		if (this.ShouldRunScript) {
			if (!this.ShouldRunScript(state)) return false;
		}
		return true;
	}
}