
import AppProductSkuAutocomplete from "@/components/products/ProductSkuAutocompleteWithoutModal.vue";
import { Product } from "@/models/base";
import { ProductWriteOffRow } from "@/models/base";
import Vue from "vue";

export default Vue.extend({
	data() {
		return {
			product: null as Product | null,
			Qty: 1
		};
	},
	props: {
		writeOffProduct: Object as () => ProductWriteOffRow
	},
	methods: {
		cancel() {
			this.$emit("close");
		},
		confirm() {
			if (!this.product) return;
			this.writeOffProduct.WriteOffProductID = this.product.ID;
			this.writeOffProduct.WriteOffProduct = this.product;
			this.writeOffProduct.Amount = this.Qty;
			this.$emit("confirm", this.writeOffProduct);
			this.$emit("close");
		}
	},
	mounted() {
		this.product = this.writeOffProduct.WriteOffProduct;
		this.Qty = this.writeOffProduct.Amount;
	},
	components: {
		AppProductSkuAutocomplete
	},
	i18nEx: {
		componentPrefix: "components.products"
	}
});
