
import Vue from "vue";
import { humanReadableByteCount } from "../utils/strings";
import { FileEventEmitter, FileEvent } from "../utils/file";

export default Vue.extend({
	props: {
		allowedExtensions: {
			type: Array as ()=>string[],
			default: ()=>[]
		},
		maxSize: {
			type: Number,
			default: 15e6
		},
		hideDefaultErrorMessages: Boolean,
		disabled: Boolean,
		multiple: {
			type: Boolean,
			default: false
		},
	},
	computed: {
		formatAllowed():string{
			return this.allowedExtensions.map((value) => {
				return "."+value;
			}).join(",");
		}
	},
	methods: {
		async onDrop(e: DragEvent) {
			e.stopPropagation();
			e.preventDefault();
			if (!e.dataTransfer) return;
			for (let i = 0; i < e.dataTransfer.items.length;i++){
				let item = e.dataTransfer.items[i];
				let file = item.getAsFile();
				if (!file) continue;
				let allowed = false;
				for (let extension of this.allowedExtensions){
					if (file.name.endsWith(extension)) {
						allowed = true;
						break;
					}
				}
				if (!allowed) continue;
				this.$emit("input", file);
				return;
			}
			var files = e.dataTransfer.files;
			if (files.length != 0) {
				this.$emit("input", files[0]);
				return;
			}
		},
		onPaste(file:File){
			this.$emit("input", file);
		},
		onInput(event: Event) {
			this.$emit("start");
			let input = event.target as HTMLInputElement;
			if (!input.files) return;
			if (input.files.length == 0) return;
			for (let i = 0; i < input.files.length; i++) {
				let item = input.files.item(i);
				if (!item) continue;

				if (!this.isFileValid(item)) continue;
				this.$emit("input", item);
			}
			this.$emit("finished");
		},
		getExtension(item:File):string{
			return item.name.split(".").pop() || "";
		},
		isFileValid(item:File):boolean{
			if (this.allowedExtensions.length > 0){
				let extension = this.getExtension(item);
				if (this.allowedExtensions.indexOf(extension.toLowerCase()) == -1) {
					this.$emit("error", {message: "invalid-extension", data: extension});
					if (!this.hideDefaultErrorMessages){
						this.$wf.notify(this.$ct("error.invalid-extension", {extension}), "warning", 6000, "exclamation-triangle");
					}
				}
			}

			if (this.maxSize != 0){

				if (item.size > this.maxSize){

					this.$emit("error", {message: "file-to-large", data: item.size});
					if (!this.hideDefaultErrorMessages){
						this.$wf.notify(this.$ct("error.file-to-large", {
							maxAllowed: humanReadableByteCount(this.maxSize),
							given: humanReadableByteCount(item.size)
						}), "danger", 6000, "exclamation-triangle");
					}
					return false;
				}
			}
			return true;
		},
		labelClick() {
			let input = this.$refs.input as HTMLInputElement;
			input.click();
		},
	},
	created(){
		FileEventEmitter.on(FileEvent.FILE_PASTED, this.onPaste);
	},
	destroyed(){
		FileEventEmitter.off(FileEvent.FILE_PASTED, this.onPaste);
	},
	i18nEx: {
		componentPrefix: "components.FileUpload"
	},
});
