import { View } from "@/models/view";
import { DossierConfigService } from "@/services/dossier-config-service";
import { CustomFilterValueInput } from "@/utils/models/decorator-field";
import { getModel, Model } from "@/utils/models/model";
import { Translation } from "@/utils/translation";
import { ExtraFieldNamesConfig } from "./dossier-configs/extra-field-names-config";

export interface IColumnNameOverride {
	key:string;
	label:string;
}

export enum BrowseID {
	CLIENTS = 1,
	SUPPLIERS = 2,
	PROSPECTS = 3,
	PRODUCTS = 4,
	STOCKIN = 5,
	SALES = 6,
	SALE_ROWS = 7,
	BANK = 8,
	PURCHASE = 9,
	SUBSCRIPTIONS = 10,
	CRM = 11,
	PURCHASE_DOCUMENTS = 12,
	CARS = 13,
	PURCHASE_LEDGERS = 14,
	PROSPECT_DOCUMENTS = 15,
	DIVERSE_LEDGERS = 16,
	PURCHASE_DOCUMENT_ROWS = 17
}

interface IFilterTypeOverride{
	Path:string;
	Type:CustomFilterValueInput;
}

export class Browse {
	public ID: BrowseID;
	public Table: string;
	public Name: Translation;
	public Views: View[] = [];
	public type: Model;
	public HasBookYearFilter:boolean = false;
	private filterTypeOverrides:IFilterTypeOverride[] = [];
	private columnNameOverrides:IColumnNameOverride[] = [];

	constructor(data: any) {
		this.ID = data.ID;
		this.Table = data.Table;
		this.Name = new Translation(data.Name);
		this.HasBookYearFilter = data.HasBookYearFilter;

		if (data.Views) {
			this.Views = data.Views.map((v: any) => new View(v)).sort((a: View, b: View) => {
				return a.DisplayOrder - b.DisplayOrder;
			});
		}
		this.setExtraFieldNameOverride();
		this.setFilterTypeOverrides();
		this.type = getModel(this.Table);
	}

	static getIconClassByBrowseId(browseId:number):string{
		switch(browseId){
		case BrowseID.CLIENTS:
			return "icon-group";
		case BrowseID.SUPPLIERS:
			return "icon-truck-scale";
		case BrowseID.PROSPECTS:
			return "icon-user-bubble";
		case BrowseID.PRODUCTS:
			return "icon-box2";
		case BrowseID.STOCKIN:
			return "icon-box-check2";
		case BrowseID.SALES:
			return "icon-bag-full";
		case BrowseID.SALE_ROWS:
			return "icon-clipboard-list";
		case BrowseID.BANK:
			return "icon-library";
		case BrowseID.PURCHASE:
			return "icon-bag-checkout";
		case BrowseID.SUBSCRIPTIONS:
			return "icon-refund";
		case BrowseID.CRM:
			return "icon-head-bubble";
		case BrowseID.PURCHASE_DOCUMENTS:
			return "icon-cart-checkout";
		case BrowseID.CARS:
			return "icon-car";
		case BrowseID.PROSPECT_DOCUMENTS:
			return "icon-file-user";
		case BrowseID.PURCHASE_DOCUMENT_ROWS:
			return "icon-clipboard-list";
		case BrowseID.DIVERSE_LEDGERS:
			return "icon-book";
		case BrowseID.PURCHASE_LEDGERS:
			return "icon-book";
		}
		return "";
	}

	public getColumnNameOverrides():IColumnNameOverride[] {
		return this.columnNameOverrides;
	}

	public setExtraFieldNameOverride(){
		if (this.ID == BrowseID.CLIENTS) {
			this.columnNameOverrides = Browse.getClientColumnNameOverrides();
		} else if (this.ID == BrowseID.SUPPLIERS) {
			this.columnNameOverrides = Browse.getSupplierColumnNameOverrides();
		} else if (this.ID == BrowseID.PROSPECTS) {
			this.columnNameOverrides = Browse.getProspectColumnNameOverrides();
		} else if (this.ID == BrowseID.PRODUCTS) {
			this.columnNameOverrides = Browse.getProductColumnNameOverrides();
		} else if (this.ID == BrowseID.STOCKIN) {
			this.columnNameOverrides = Browse.getStockInColumnNameOverrides();
		} else if (this.ID == BrowseID.SALES) {
			this.columnNameOverrides = Browse.getSaleColumnColumnNameOverrides();
		} else if (this.ID == BrowseID.SALE_ROWS) {
			this.columnNameOverrides = Browse.getSaleRowColumnNameOverrides();
		} else if (this.ID == BrowseID.PURCHASE_DOCUMENTS) {
			this.columnNameOverrides = Browse.getPurchaseDocumentColumnNameOverrides();
		} else if (this.ID == BrowseID.PROSPECT_DOCUMENTS) {
			this.columnNameOverrides = Browse.getProspectDocumentColumnNameOverrides();
		}
		return [];
	}

	public getFilterTypeOverrideByPath(browsePath:string):CustomFilterValueInput | null{
		for (let override of this.filterTypeOverrides){
			if (override.Path == browsePath){
				return override.Type;
			}
		}
		return null;
	}

	private setFilterTypeOverrides(){
		if (this.ID == BrowseID.CLIENTS){
			this.filterTypeOverrides = Browse.getClientFilterTypeOverrides();
		} else if (this.ID == BrowseID.PROSPECTS) {
			this.filterTypeOverrides = Browse.getProspectFilterTypeOverrides();
		} else if (this.ID == BrowseID.SUPPLIERS){
			this.filterTypeOverrides = Browse.getSupplierFilterTypeOverrides();
		} else if(this.ID == BrowseID.SALES){
			this.filterTypeOverrides = Browse.getSaleFilterTypeOverrides();
		} else if (this.ID == BrowseID.PURCHASE_DOCUMENTS) {
			this.filterTypeOverrides = Browse.getPurchaseDocumentFilterTypeOverrides();
		} else if (this.ID == BrowseID.PURCHASE){
			this.filterTypeOverrides = Browse.getPurchaseFilterTypeOverrides();
		}
	}

	// Returns null if no override available
	public getColumnNameOverride(browseFieldPath:string):string{
		let columnNameOverrides = this.getColumnNameOverrides();
		for (let columnNameOverride of columnNameOverrides) {
			if (columnNameOverride.key == browseFieldPath) {
				return columnNameOverride.label;
			}
		}
		return "";
	}

	private static getExtraFieldNameOverrides(extraFieldsConf:ExtraFieldNamesConfig):IColumnNameOverride[]{
		let activeFields = extraFieldsConf.getActiveFields();
		return activeFields.map(f=>{
			return {
				key: `ExtraFields.${f}`,
				label: extraFieldsConf[f] as string,
			} as IColumnNameOverride;
		});
	}

	private static getClientColumnNameOverrides():IColumnNameOverride[] {
		return this.getExtraFieldNameOverrides(DossierConfigService.getReadonlyExtraFieldNamesConfigs().ClientNames);
	}

	private static getSupplierColumnNameOverrides():IColumnNameOverride[] {
		return this.getExtraFieldNameOverrides(DossierConfigService.getReadonlyExtraFieldNamesConfigs().SupplierNames);
	}

	private static getProspectColumnNameOverrides():IColumnNameOverride[] {
		return this.getExtraFieldNameOverrides(DossierConfigService.getReadonlyExtraFieldNamesConfigs().ProspectNames);
	}

	private static getProductColumnNameOverrides():IColumnNameOverride[] {
		return this.getExtraFieldNameOverrides(DossierConfigService.getReadonlyExtraFieldNamesConfigs().ProductNames);
	}

	private static getStockInColumnNameOverrides():IColumnNameOverride[] {
		let productNameOverrides = this.getProductColumnNameOverrides();
		let contactNameOverrides = this.getSupplierColumnNameOverrides();
		return [...productNameOverrides.map(f=>{
			f.key = `Product.${f.key}`;
			return f;
		}),
		...contactNameOverrides.map(f=>{
			f.key = `Supplier.${f.key}`;
			return f;
		})];
	}

	private static getSaleColumnColumnNameOverrides():IColumnNameOverride[] {
		let result = this.getExtraFieldNameOverrides(DossierConfigService.getReadonlyExtraFieldNamesConfigs().SaleNames);
		let contactOverrides = this.getClientColumnNameOverrides().map(f=>{
			f.key = `Contact.${f.key}`;
			return f;
		});
		return [...result, ...contactOverrides];
	}

	private static getPurchaseDocumentColumnNameOverrides():IColumnNameOverride[] {
		let result = this.getExtraFieldNameOverrides(DossierConfigService.getReadonlyExtraFieldNamesConfigs().SaleNames);
		let contactOverrides = this.getSupplierColumnNameOverrides().map(f=>{
			f.key = `Contact.${f.key}`;
			return f;
		});
		return [...result, ...contactOverrides];
	}

	private static getProspectDocumentColumnNameOverrides():IColumnNameOverride[] {
		let result = this.getExtraFieldNameOverrides(DossierConfigService.getReadonlyExtraFieldNamesConfigs().SaleNames);
		let contactOverrides = this.getProspectColumnNameOverrides().map(f=>{
			f.key = `Contact.${f.key}`;
			return f;
		});
		return [...result, ...contactOverrides];
	}

	private static getSaleRowColumnNameOverrides():IColumnNameOverride[] {
		let result = this.getExtraFieldNameOverrides(DossierConfigService.getReadonlyExtraFieldNamesConfigs().SaleNames);
		return result.map(r=>{
			r.key = `Sale.${r.key}`;
			return r;
		});
	}

	private static getSaleFilterTypeOverrides():IFilterTypeOverride[] {
		return [{
			Path: "VatRegime",
			Type: "VatRegime"
		},{
			Path: "Contact.VatRegime",
			Type: "VatRegime"
		}];
	}

	private static getClientFilterTypeOverrides():IFilterTypeOverride[] {
		return [{
			Path: "VatRegime",
			Type: "VatRegime"
		}];
	}
	private static getProspectFilterTypeOverrides():IFilterTypeOverride[] {
		return this.getClientFilterTypeOverrides();
	}

	private static getPurchaseDocumentFilterTypeOverrides():IFilterTypeOverride[] {
		return [{
			Path: "VatRegime",
			Type: "PurchaseVatRegime"
		},{
			Path: "Contact.VatRegime",
			Type: "PurchaseVatRegime"
		}];
	}

	private static getSupplierFilterTypeOverrides():IFilterTypeOverride[] {
		return [{
			Path: "VatRegime",
			Type: "PurchaseVatRegime"
		}];
	}

	private static getPurchaseFilterTypeOverrides():IFilterTypeOverride[] {
		return [{
			Path: "Contact.VatRegime",
			Type: "PurchaseVatRegime"
		}];
	}
}
