export class DashboardIframeData {
	Title:string = "";
	ShowTitle:boolean = true;
	Url:string = "";


	public constructor(data?:any){
		if (!data)return;
		this.Url = data.Url;
		this.ShowTitle = data.ShowTitle;
		this.Title = data.Title;
	}

	public getJSON(){
		return {
			Url: this.Url.trim(),
			ShowTitle: this.ShowTitle || false,
			Title: this.Title.trim()
		};
	}
}