
import { Browse } from "@/models/browse";
import { Model } from "@/utils/models/model";
import { SettingsColumn, ISumColumn } from "@/utils/report-engine-settings/settings-column";
import AppColumnSelect from "@/components/models/ColumnSelect.vue";
import Vue from "vue";

export default Vue.extend({
	data(){
		return {
			addModalOpen: false,
			items: [] as ISumColumn[]
		};
	},
	props: {
		column: {
			type: Object as ()=>SettingsColumn,
			required: true
		},
		model: {
			type: Object as ()=>Model,
			required: true
		},
		browse: {
			type: Object as ()=>Browse,
			required: true
		}
	},
	watch: {
		"column.Value": {
			immediate: true,
			handler(){
				this.items = this.column.getSumColumns();
			}
		},
	},
	methods: {
		addColumnClicked(){
			this.addModalOpen = true;
		},
		onColumnSelected(value:string){
			this.items.push({column: value, subtract: false});
			this.storeItems();
			this.addModalOpen = false;
		},
		getColumnName(col:ISumColumn):string{
			let c = this.model.getField(col.column);
			if (!c) return "ERROR";
			return c.getPropertyTranslation();
		},
		getSubtractIcon(col:ISumColumn):string {
			if (col.subtract) {
				return "minus";
			}
			return "plus";
		},
		removeCol(i:number) {
			this.items.splice(i,1);
			this.storeItems();
		},
		toggleSubtract(col:ISumColumn){
			col.subtract = !col.subtract;
			this.storeItems();
		},
		storeItems(){
			let result = "{{sum}}";
			for (let i = 0; i < this.items.length; i++) {
				if (i > 0) {
					result += ",";
				}
				let col = this.items[i];
				if (col.subtract) {
					result += "-";
				}else{
					result += "+";
				}
				result += col.column;
			}
			this.column.Value = result;
		}
	},
	components: {
		AppColumnSelect
	},
	i18nEx: {
		componentPrefix: "components.reportengine"
	}
});
