export enum BrowseWritePermission {
	DENIED = 0,
	ALL = 1,
	WITHOUT_DELETE = 2
}

export class BrowsePermissions {
	CanRead:boolean = true;
	CanWrite:BrowseWritePermission = BrowseWritePermission.ALL;
	CanMakeViews:boolean = true;


	public constructor(data:any){
		if (!data) return;
		this.CanRead = data.CanRead;
		this.CanWrite = data.CanWrite;
		this.CanMakeViews = data.CanMakeViews;
	}

	getJSON(){
		return {
			CanRead: this.CanRead,
			CanWrite: this.CanWrite,
			CanMakeViews: this.CanMakeViews,
		};
	}

	hasWritePermissions():boolean {
		return this.CanWrite != BrowseWritePermission.DENIED;
	}

	hasDeletePermissions():boolean {
		return this.CanWrite == BrowseWritePermission.ALL;
	}
}
