import { ServerConfig } from "@/config/config";
import { refreshTokensIfRequired } from "../axios";
import { downloadBlob } from "../blob";
import { DocumentPageSettings } from "../documents/page-settings";
import { EventEmitter } from "../event-emitter";
import { tokenStore } from "../token-store";
import { BrowseStateBase } from "./browse-state-base";

export interface IPrintBrowseSettings {
	IsExcel:boolean;
	PageSettings:DocumentPageSettings;
	selectedOnly:boolean;
	fileName:string;
	title:string;
}



export class PrintBrowseRunner extends EventEmitter{
	private settings:IPrintBrowseSettings;
	private thead:HTMLElement;
	private tbody:HTMLElement;
	public get rowsList():HTMLCollection{
		if (this.settings.selectedOnly){
			return this.tbody.getElementsByClassName("selected");
		}
		return this.tbody.children;
	}
	private socket?:WebSocket;
	private row:number=0;
	private stepSize:number = 100;
	private done:boolean = false;
	private readonly maxRows:number = 10000;
	private browseState:BrowseStateBase;
	public totalRowCount:number = 0;
	public canceled:boolean = false;

	constructor(settings:IPrintBrowseSettings, browseState:BrowseStateBase, thead:HTMLElement, tbody:HTMLElement){
		super();
		this.settings = settings;
		this.browseState = browseState;
		this.thead = thead;
		this.tbody = tbody;
	}

	private getTheadColumnsJson(thead:HTMLElement):any[]{
		let result:any[] = [];
		let row = thead.children.item(0);
		if (!row) return result;
		for (let cell of row.children){
			let item = {
				Text: (cell as HTMLTableColElement).innerText,
				rightAlign: false,
				Width: cell.getBoundingClientRect().width
			};
			let text = cell.getElementsByClassName("text").item(0);
			if (text &&
				text.previousSibling &&
				(text.previousSibling as HTMLElement).classList &&
				(text.previousSibling as HTMLElement).classList.contains("w-spacer")) {
				item.rightAlign = true;
			}
			result.push(item);
		}
		return result;
	}

	private getTbodyColumnsJson():string[][]{
		let result:string[][] = [];
		for (let i = 0; i < this.stepSize; i++){
			if (this.row+i >= this.rowsList.length) break;
			let item = this.rowsList.item(this.row+i);
			if (!item) continue;
			let thisItem:string[] = [];
			for (let cell of item.children){
				thisItem.push((cell as HTMLTableColElement).innerText);
			}
			result.push(thisItem);
		}
		return result;
	}

	async cancel(){
		if (!this.socket) return;
		this.canceled = true;
		this.socket.close();
	}



	private async onMessage(ev:MessageEvent, socket:WebSocket, resolve:Function, reject:Function){
		if (typeof(ev.data) == "string" && ev.data.startsWith("ERROR: ")){
			reject(ev.data);
		}else if (ev.data == "authorized"){
			let result = this.getTheadColumnsJson(this.thead);
			socket.send(JSON.stringify({...this.settings, Columns: result}));
		} else if (ev.data == "request-data"){
			console.log("Got request for data");
			if (!this.settings.selectedOnly){
				console.log("Waiting for done fetching");
				await this.browseState.doneFetching();
			}
			let result = this.getTbodyColumnsJson();
			this.row += this.stepSize;
			this.row = Math.min(this.rowsList.length, this.row);
			console.log(this.row);
			this.emit("progress", this.row);
			socket.send(JSON.stringify(result));
			if (this.settings.selectedOnly) return;
			console.log("Not selected only");
			console.log(this.row + this.stepSize, this.rowsList.length);
			if (this.row + this.stepSize < this.rowsList.length) return;
			console.log(this.browseState.queryResults.records, this.rowsList.length, this.browseState.queryResults.records >= this.rowsList.length);
			if (this.browseState.queryResults.records <= this.rowsList.length) return;
			console.log("Fetching more");
			await this.browseState.infiniteScrollFetchMore();
		}else if (ev.data instanceof Blob){
			downloadBlob(ev.data, `${this.settings.fileName}.${this.settings.IsExcel ? "xlsx" : "pdf"}`);
			this.done = true;
			resolve();
		}else if (ev.data == "done"){
			this.done = true;
			resolve();
		}
	}

	async run():Promise<void>{
		if (this.socket) return;
		this.totalRowCount = Math.min(this.browseState.queryResults.records, this.maxRows);
		if (this.settings.selectedOnly){
			this.totalRowCount = this.rowsList.length;
		}
		let socket = new WebSocket(`${ServerConfig.host}/browse-to-file/create/${this.totalRowCount}`);
		this.socket = socket;
		return new Promise((resolve, reject)=>{
			socket.addEventListener("open", async (ev)=>{
				await refreshTokensIfRequired();
				socket.send(tokenStore.getSelectedToken().token);
			});
			socket.addEventListener("message", (ev)=>this.onMessage(ev, socket, resolve, reject));
			socket.addEventListener("error", (err)=>{
				reject(err);
			});
			socket.addEventListener("close", (ev)=>{
				if (!this.done) reject("ERROR");
			});
		});
	}
}

export async function printBrowse(settings:IPrintBrowseSettings, thead:HTMLElement, tbody:HTMLElement):Promise<void>{

}