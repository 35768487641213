
import Vue from "vue";
import AppSelectBankType from "@/components/bank/SelectBankType.vue";

export default Vue.extend({
	props: {
		value: String,
		label: String
	},
	computed: {
		val():number{
			return parseInt(this.value || "1");
		},
	},
	methods: {
		onInput(val:number){
			this.$emit("input", `${val}`);
		}
	},
	mounted(){
		if (`${this.val}` != this.value){
			this.onInput(1);
		}
	},
	components: {
		AppSelectBankType
	}
});
