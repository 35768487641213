
import { Product } from "@/models/base";
import { ProductService } from "@/services/product-service";
import Vue from "vue";
export default Vue.extend({
	data() {
		return {
			foundProducts: [] as Product[],
			text: "",
			onReadyToBlurHandlers: null as null | Function[],
		};
	},
	props: {
		value: Object as () => Product,
		label: [Object, String],
		autoFocus: Boolean,
		includeDisabled: Boolean,
		icon: String,
	},
	computed: {
		compLabel(): string {
			if (this.label) {
				if (typeof (this.label) == "string") {
					return this.label;
				}
				return this.label.toString();
			}
			return this.$ct("product-sku").toString();
		}
	},
	watch: {
		value: {
			immediate: true,
			handler(product: Product) {
				if (!product) {
					this.text = "";
					return;
				};
				this.text = product.Sku || "";
			}
		},
		async text(text: string) {
			this.$emit("text", text);
			if (text.match(/^\s*$/)) {
				this.foundProducts = [];
				return;
			}
			if (this.value && this.value.Sku == this.text) {
				return;
			}
			this.foundProducts = await ProductService.search(text, this.includeDisabled);
		}
	},
	methods: {
		focus() {
			(this.$refs.productSku as any).focus();
		},
		keyup(event: KeyboardEvent) {
			this.$emit("keyup", event);
		},
		async productSelected(product: Product) {
			this.onReadyToBlurHandlers = [];
			try{
				product = await ProductService.getProduct(product.ID);
				this.text = "";
				this.$emit("input", product);
			}catch(err){

			}
			for (let handler of this.onReadyToBlurHandlers){
				handler();
			}
		},
		async blur() {
			await this.isReadyToBlur();
			this.$emit("blur");
		},
		clear() {
			this.text = "";
		},
		async isReadyToBlur():Promise<void>{
			if (!this.onReadyToBlurHandlers) {
				return;
			}
			return new Promise((resolve)=>{
				if (!this.onReadyToBlurHandlers){
					return resolve();
				}
				this.onReadyToBlurHandlers.push(resolve);
			});
		},
		iconClicked(){
			this.$emit("icon-clicked");
		}
	},
	mounted() {
		if (this.autoFocus) {
			this.focus();
		}
	},
	i18nEx: {
		componentPrefix: "components.products"
	}
});
