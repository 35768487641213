
import { LedgerAccount } from "@/models/ledger-account";
import { LedgerAccountService } from "@/services/ledger-account-service";
import Vue from "vue";
export default Vue.extend({
	data(){
		return {
			input: "",
			allItems: [] as LedgerAccount[]
		};
	},
	props: {
		label: String,
		fancy: Boolean,
		value: String,
		includeGroups: Boolean,
		addRemoveButton: Boolean,
		disabled: Boolean
	},
	computed: {
		description(): string{
			let l = this.allItems.filter(f=>{
				if(f.Code == this.value){
					return f;
				}
			}) as LedgerAccount[];
			if(l[0]){
				return l[0].Code + " - " + l[0].Description;
			}
			return "";
		},
		filteredItems():LedgerAccount[] {
			let input = this.input.toLowerCase().trim();
			return this.allItems.filter(f=>{
				if (!this.includeGroups && f.isGroup()) {
					return false;
				}
				if (!input) {
					return true;
				}
				if (f.Code.indexOf(input) == 0) {
					return true;
				}
				if (f.Description.toLowerCase().indexOf(input) != -1) {
					return true;
				}
				if (f.GroupName.toLowerCase().indexOf(input) != -1) {
					return true;
				}
				return false;
			});
		},
		icon():string{
			if (this.addRemoveButton){
				return "times";
			}
			return "";
		}
	},
	watch: {
		value: {
			immediate: true,
			handler(){
				this.input = this.description || "";
			}
		}
	},
	methods: {
		onItemClick(item:LedgerAccount){
			this.$emit("input", item.Code);
		},
		onBlur(){
			this.input = this.description || "";
		},
		removeClicked(){
			this.$emit("input", "");
		},
		onFocus(){
			this.input = "";
		}
	},
	async mounted(){
		this.allItems = await LedgerAccountService.fetchLedgerAccounts();
		this.input = this.description || "";
	}
});
