import { SaleConfigCalendarToShowField } from "@/utils/sale-config-calendar-to-show-field";

export class SaleConfig {
	DefaultContactID:number = 0;
	NewSaleOnClose:boolean = false;
	DefaultMailSettingsID:number = 0;
	OpenCarSelectModalByDefault:boolean = false;
	DontUseLeeggoed:boolean = true;
	CalendarToShowFields:SaleConfigCalendarToShowField[] = [];

	public constructor(data?:any) {
		if (!data) return;
		this.DefaultContactID = data.DefaultContactID || 0;
		this.NewSaleOnClose = data.NewSaleOnClose || false;
		this.DefaultMailSettingsID = data.DefaultMailSettingsID;
		this.OpenCarSelectModalByDefault = data.OpenCarSelectModalByDefault;
		this.DontUseLeeggoed = data.DontUseLeeggoed || false;
		this.CalendarToShowFields =	(data.CalendarToShowFields || []).map((d:any)=> new SaleConfigCalendarToShowField(d));
	}

	public getJSON(){
		return {
			DefaultContactID: this.DefaultContactID,
			NewSaleOnClose: this.NewSaleOnClose,
			DefaultMailSettingsID: this.DefaultMailSettingsID,
			OpenCarSelectModalByDefault: this.OpenCarSelectModalByDefault,
			DontUseLeeggoed: this.DontUseLeeggoed,
			CalendarToShowFields: this.CalendarToShowFields.map(c=>c.getJSON())
		};
	}
}