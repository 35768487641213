export class CustomTranslationWithoutFallback {
	public Translations: {[key: string]: string} = {};

	constructor(data?: any) {
		if (!data) {
			return;
		}
		this.Translations = data.Translations || {};
	}

	public getTranslation(lang: string) {
		return this.Translations[lang] || "";
	}

	public setText(text:string) {
		this.Translations["nl-be"] = text;
		this.Translations.fr = text;
		this.Translations.en = text;
	}

	public getJSON(): any {
		for (let key of Object.keys(this.Translations)) {
			if ((this.Translations[key] || "").match(/^\s*$/)) {
				delete this.Translations[key];
			}
		}
		return {
			Translations: {...this.Translations},
		};
	}

	public clone():CustomTranslationWithoutFallback{
		return new CustomTranslationWithoutFallback(this.getJSON());
	}
}
