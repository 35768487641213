import { GoTemplateDataProperty } from "@/utils/go-template-data-structure/property";
import { convertTextToNode } from "@/utils/go-template-data-structure/text-to-nodes";
import { ISelectItem } from "winfakt-vue-components";

export type DisplayedPropertyType = "text" | "link"

export interface IDisplayedProp {
	value:string;
	type:DisplayedPropertyType;
	args:any[];
}

export class GoTemplateDataRoot {
	properties:GoTemplateDataProperty[] = [];

	constructor(properties:GoTemplateDataProperty[]) {
		this.properties = properties;
	}

	getDisplayedProperties(text:string, cursorPosition:number):ISelectItem<IDisplayedProp>[] {
		let node = convertTextToNode(text, this.properties);
		let bestNode = node.getBestNode(cursorPosition);
		return bestNode.availableProperties;
	}
}