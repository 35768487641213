import { getModelByConstructor } from "@/utils/models/model";
import { NamedFilter } from "./named-filter";

export function model(name:string):ClassDecorator{
	return function(target:Function){
		let model = getModelByConstructor(target, true);
		model.modelName = name;
		for (let key of Object.keys(model.fields)){
			if (!model.fields[key].modelName){
				model.fields[key].modelName = name;
			}
		}
	};
}

export function ignoredFields(val:string[]):ClassDecorator{
	return function(target:Function){
		let model = getModelByConstructor(target, true);
		model.getIgnoredFields = ()=>val;
	};
}

export function priorityFields(val:string[] | Promise<string[]>):ClassDecorator{
	return function(target:Function){
		let model = getModelByConstructor(target, true);
		if (Array.isArray(val)){
			model.getPriorityFields = ()=>val;
		}else{
			val.then((val:string[])=>{
				model.getPriorityFields = ()=>val;
			});
		}
	};
}

export function namedFilters(val:NamedFilter[]):ClassDecorator {
	return function(target:Function){
		let model = getModelByConstructor(target, true);
		model.getAvailableNamedFilters = ()=>val;
	};
}