import { ServerConfig } from "@/config/config";
import { Job } from "@/models/job";
import { ReportEngineSettingsModel } from "@/models/report-engine-settings";
import { ReportEngineSettings, ReportType } from "@/utils/report-engine-settings/report-engine-settings";
import Vue from "vue";
import { Axios } from "@/utils/axios";
import { StaticReport } from "@/models/static-report";
import { Script } from "@/models/script";
import { IReportEngineSettingsWrapper } from "@/utils/report-engine-settings/report-engine-settings-wrapper";

export interface IReportEngineSettingsList {
	savedReports:ReportEngineSettingsModel[];
	staticReports:StaticReport[];
}

export const ReportService = new Vue({
	data(){
		return {
			savedReports: {} as {[key:number]:IReportEngineSettingsList}
		};
	},
	computed: {
		url():string {
			return ServerConfig.host + "/report";
		},
		saleReportUrl():string {
			return ServerConfig.host + "/sale/reports";
		},
		engineSettingsUrl():string {
			return ServerConfig.host + "/report-engine-settings";
		}
	},
	methods: {
		async generateStaticReport(staticReport:StaticReport, reportType:ReportType, args:string[]):Promise<Job>{
			interface IAskedArgInput {
				Values:(string | null)[]
				Options:string[];
			}
			let AskedArgs = [] as IAskedArgInput[];
			for (let askedFilter of staticReport.Settings.AskedFilters){
				if (!askedFilter.OptionalFilterIsDisabled){
					AskedArgs.push({
						Values: askedFilter.Values,
						Options: askedFilter.Options
					});
				}else{
					AskedArgs.push({Values: [],Options: []});
				}
			}
			let url = this.url;
			if (reportType == "excel") {
				url = `${this.url}/excel`;
			}

			let u = `${url}/create-standard-report/${staticReport.browseId}/${staticReport.index}`;
			if(staticReport.library){
				u = `${url}/create-standard-report-by-name/${staticReport.library}/${staticReport.index}`;
			}

			let result = await Axios.post(u, {
				ScriptArgs: args,
				AskedArgs,
				BookYears: staticReport.Settings.BookYears
			});
			let job = new Job(result.data);
			this.notifyUserReportQueued(job);
			return job;
		},

		async generateCustomReport(reportSettings:ReportEngineSettings, args:string[] = []):Promise<Job> {
			let result = await Axios.post(`${this.url}`, {
				Settings: reportSettings.getJSON(),
				ScriptArgs: args
			});
			let job = new Job(result.data);
			this.notifyUserReportQueued(job);
			return job;
		},

		async generateCustomReportExcel(reportSettings:ReportEngineSettings, args:string[] = []):Promise<Job> {
			let result = await Axios.post(`${this.url}/excel`, {
				Settings: reportSettings.getJSON(),
				ScriptArgs: args
			});
			let job = new Job(result.data);
			this.notifyUserReportQueued(job);
			return job;
		},

		async getSavedReportEngineSettings():Promise<ReportEngineSettingsModel[]> {
			let result = await Axios.get(`${this.engineSettingsUrl}`);
			return result.data.data.map((r:any) => new ReportEngineSettingsModel(r));
		},

		async getReportEngineSettingsByBrowseId(browseId:number):Promise<IReportEngineSettingsList>{
			let result = await Axios.get(`${this.engineSettingsUrl}/by-browse/${browseId}`);
			let savedList = this.savedReports[browseId];
			if (!savedList) {
				savedList = {savedReports: [], staticReports: []};
				this.savedReports[browseId] = savedList;
			}
			savedList.savedReports.splice(0, savedList.savedReports.length);
			savedList.staticReports.splice(0, savedList.staticReports.length);
			savedList.savedReports.push(...result.data.SavedReports.map((r:any)=>new ReportEngineSettingsModel(r)));
			savedList.staticReports.push(...result.data.StaticReports.map((r:any, i:number)=>new StaticReport(r, browseId, i)));
			return savedList;
		},

		async getReportEngineSettingsByName(name:string):Promise<IReportEngineSettingsList>{
			let result = await Axios.get(`${this.engineSettingsUrl}/by-name/${name}`);
			let savedList = {savedReports: [], staticReports: []};
			savedList.staticReports.splice(0, savedList.staticReports.length);
			savedList.staticReports = result.data.StaticReports.map((r:any, i:number)=>new StaticReport(r, 0, i));
			return savedList;
		},

		async createReportEngineSetting(setting:ReportEngineSettingsModel):Promise<ReportEngineSettingsModel> {
			let result = await Axios.post(`${this.engineSettingsUrl}`, [setting.getJSON()]);
			this.getReportEngineSettingsByBrowseId(setting.BrowseID);
			return new ReportEngineSettingsModel(result.data[0]);
		},

		async updateReportEngineSettings(setting:ReportEngineSettingsModel):Promise<ReportEngineSettingsModel> {
			let result = await Axios.put(`${this.engineSettingsUrl}`, [setting.getJSON()]);
			this.getReportEngineSettingsByBrowseId(setting.BrowseID);
			return new ReportEngineSettingsModel(result.data[0]);
		},

		async deleteReportEngineSettings(settings:ReportEngineSettingsModel[]):Promise<void> {
			await Axios.delete(`${this.engineSettingsUrl}`, {
				data: settings.map(s => s.ID)
			});
			let uniqueBrowseIDs = settings.map(s=>s.BrowseID);
			uniqueBrowseIDs = uniqueBrowseIDs.filter((val, i)=>{
				return uniqueBrowseIDs.indexOf(val) == i;
			});
			for (let browseId of uniqueBrowseIDs){
				this.getReportEngineSettingsByBrowseId(browseId);
			}
		},

		openCreateReportModal(settings:IReportEngineSettingsWrapper, reportType:"pdf"|"excel"){
			this.$emit("create-report", settings, reportType);
		},

		notifyUserReportQueued(job:Job):void {
			this.$emit("report-queued", job);
		},

		async getReportEngineScripts():Promise<Script[]> {
			let response = await Axios.get(`${this.engineSettingsUrl}/scripts`);
			return response.data.map((s:any)=>new Script(s));
		},

		async getStandardReportEngineScripts():Promise<Script[]>{
			let response = await Axios.get(`${this.engineSettingsUrl}/standard-scripts`);
			return response.data.map((s:any)=>new Script(s));
		},

		async getScript(scriptName:string):Promise<Script>{
			let response = await Axios.get(`${this.engineSettingsUrl}/scripts/${scriptName.replace("/", "_")}`);
			return new Script(response.data);
		},

	}
});