
import Vue from "vue";
import { ISelectItem } from "winfakt-vue-components";
export default Vue.extend({
	props: {
		value: {
			type: Number,
		},
		fancy: Boolean,
		label: {
			type: [Object, String]
		},
		useGoTime: Boolean
	},
	computed: {
		items():ISelectItem<number>[] {
			return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(d => {
				let value = d;
				if (this.useGoTime) {
					value++;
				}
				return {
					value,
					text: this.$ct(`common.months.${d+1}`).toString()
				} as ISelectItem<number>;
			});
		}
	},
	methods: {
		setValue(val:number) {
			this.$emit("input", val);
		}
	}
});
