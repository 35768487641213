import { Margin } from "../documents/margin";

export class PageSettings {
	PageWidth:number = 58;
	PageHeight:number = 32;
	Width:number = 58;
	Height:number = 32;

	Margin:Margin = new Margin();

	LabelsPerRow:number = 1;
	RowsPerPage:number = 1;
	HorizontalSpacing:number = 0;
	VerticalSpacing:number = 0;

	public constructor(data?:any){
		if (!data) return;
		this.Width = data.Width;
		this.Height = data.Height;
		this.Margin = new Margin(data.Margin);
		this.LabelsPerRow = data.LabelsPerRow || 1;
		this.RowsPerPage = data.RowsPerPage || 1;
		this.HorizontalSpacing = data.HorizontalSpacing;
		this.VerticalSpacing = data.VerticalSpacing;
		this.PageWidth = data.PageWidth || this.getMinPageWidth();
		this.PageHeight = data.PageHeight || this.getMinPageHeight();
	}

	public getMinPageWidth():number{
		let labelWidths = this.Width * this.LabelsPerRow;
		labelWidths += this.HorizontalSpacing * (this.LabelsPerRow-1);
		return labelWidths + this.Margin.Left + this.Margin.Right;
	}

	public getMinPageHeight():number {
		let labelHeights = this.Height * this.RowsPerPage;
		labelHeights += this.VerticalSpacing * (this.RowsPerPage -1);
		return labelHeights + this.Margin.Top + this.Margin.Bottom;
	}

	public getJSON(){
		return {
			Width: this.Width,
			Height: this.Height,
			PageWidth: this.PageWidth,
			PageHeight: this.PageHeight,
			Margin: this.Margin.getJSON(),
			LabelsPerRow: this.LabelsPerRow,
			RowsPerPage: this.RowsPerPage,
			HorizontalSpacing: this.HorizontalSpacing,
			VerticalSpacing: this.VerticalSpacing,
		};
	}
}