import { ServerConfig } from "@/config/config";
import { UserInfo } from "@/models/dossier/user-info";
import { AuthService } from "@/services/auth-service";
import { Axios } from "@/utils/axios";
import { Dossier } from "@/models/dossier/dossier";
import { EventEmitter } from "@/utils/event-emitter";

export class DossierServiceClass extends EventEmitter{
	users:UserInfo[]=[];
	fetchingUsers:UserInfo[]=[];
	isFetchingUsers:boolean = false;
	private readonly url:string = `${ServerConfig.auth}/dossier`;

	constructor() {
		super();
		requestAnimationFrame(()=>{
			AuthService.on("logout", () => {
				this.users = [];
				this.fetchingUsers = [];
				this.isFetchingUsers = false;
			});
		});
	}

	async getUsers():Promise<UserInfo[]> {
		if (this.isFetchingUsers) {
			return new Promise((resolve, reject) => {
				let successHandler:Function | undefined;
				let failHandler:Function | undefined;

				successHandler = (users:UserInfo[]) => {
					if (failHandler){
						this.off("users-fetch-error", failHandler);
					}
					resolve(users);
				};

				failHandler = (err:Error) => {
					if (successHandler){
						this.off("users-fetched", successHandler);
					}
					reject(err);
				};
				this.once("users-fetched", successHandler);
				this.once("users-fetch-error", failHandler);
			});
		}
		return this.fetchUsers();
	}

	async fetchUsers():Promise<UserInfo[]>{
		this.isFetchingUsers = true;
		try {
			let result = await Axios.get(`${this.url}/users`);
			this.isFetchingUsers = false;
			let users = result.data.map((u:any) => new UserInfo(u));
			this.users.unshift(...users);
			this.users = this.users.filter((u, i) => this.users.findIndex(user => user.ID == u.ID) == i);
			this.emit("users-fetched", this.users);
		} catch(err) {
			this.emit("users-fetch-error", err);
			throw err;
		}
		this.isFetchingUsers = false;
		return this.users;
	}

	getUser(id:number):UserInfo {
		let user = this.users.find(u => u.ID == id);
		if (user) {
			return user;
		}

		let returnUser = new UserInfo();
		this.users.push(returnUser);
		returnUser.ID = id;
		this.fetchingUsers.push(returnUser);
		(async ()=>{
			try {
				let result = await Axios.get(`${this.url}/users/${id}`);
				let fetchedUser = new UserInfo(result.data);
				returnUser.ID = fetchedUser.ID;
				returnUser.Email = fetchedUser.Email;
				returnUser.Firstname = fetchedUser.Firstname;
				returnUser.Lastname = fetchedUser.Lastname;
				returnUser.Username = fetchedUser.Username;
			} catch(err) {
				let i = this.users.indexOf(returnUser);
				if (i != -1) {
					this.users.splice(i, 1);
				}
			}
		})();
		return returnUser;
	}

	async addUserToDossier(user:UserInfo):Promise<void>{
		await Axios.post(`${this.url}/users/${user.ID}/add-to-dossier`);
		try {
			await this.initUser(user);
		}catch(err){
		}
		this.fetchUsers();
	}

	async removeFromDossier(user:UserInfo):Promise<void>{
		await Axios.post(`${this.url}/users/${user.ID}/remove-from-dossier`);
		this.fetchUsers();
	}

	async initUser(user:UserInfo):Promise<void>{
		await Axios.post(`${ServerConfig.host}/user/init-user/${user.ID}`);
	}

	async getDossiers():Promise<Dossier[]>{
		let result = await Axios.get(`${this.url}`);
		return result.data.map((d:any)=>new Dossier(d));
	}

	async getDossier(id:number):Promise<Dossier>{
		let result = await Axios.get(`${this.url}/${id}`);
		return new Dossier(result.data);
	}

	async createDossier(dossier:Dossier):Promise<Dossier>{
		let result = await Axios.post(`${this.url}`, dossier.getJSON());
		return new Dossier(result.data);
	}

	async updateDossier(dossier:Dossier):Promise<Dossier> {
		let result = await Axios.put(`${this.url}/${dossier.id}`, dossier.getJSON());
		return new Dossier(result.data);
	}

	async init(){
		await this.fetchUsers();
	}
};

export const DossierService = new DossierServiceClass();