import { DossierService } from "@/services";
import { TextIfValue } from "../report-engine-settings/text-if-value";
import { Condition, Operator } from "../condition/condition";

export function createUserTextIfValueSettings(field:string):()=>Promise<TextIfValue[]> {
	return async ()=>{
		let users = await DossierService.getUsers();
		return users.map(u=>{
			let result = new TextIfValue();
			result.Text = u.getShortDisplayName();
			let condition = new Condition();
			condition.Prop = field;
			condition.Operator = Operator.EQ;
			condition.Value = `${u.ID}`;
			result.Conditions = [condition];
			return result;
		});
	};
}