import { ServerConfig } from "@/config/config";
import { BankType, SaleType, Vat } from "@/models/base";
import Currency from "@/models/base/currency";
import { BookYear } from "@/models/book-year";
import { Browse } from "@/models/browse";
import { BrowseFavouriteColumns } from "@/models/browse-favourite-columns";
import { CalculatedField } from "@/models/calculated-field";
import CrmStatus from "@/models/crm/crm-status";
import { DossierConfig } from "@/models/dossier-config";
import { Job } from "@/models/job";
import { Unit } from "@/models/unit";
import { UserConfig } from "@/models/user-config";
import { Axios } from "@/utils/axios";

export interface IInitAppResponse {
	CalculatedFields:CalculatedField[];
	BookYears:BookYear[];
	UserConfigs:UserConfig[];
	DossierConfigs:DossierConfig[];
	JobCount:number;
	MyJobs:Job[];
	MyJobIsRunning:boolean;
	Browses:Browse[];
	Vats:Vat[];
	Currencies:Currency[];
	SaleTypes:SaleType[];
	Units:Unit[];
	BankTypes:BankType[];
	CrmStatuses:CrmStatus[];
	HasUnreadNotifications:boolean;
	BrowseFavouriteColumns:BrowseFavouriteColumns[];
	AutoSaleRowSetting:any[];
}


export class InitServiceClass {
	public readonly URL:string = `${ServerConfig.host}/init`;

	public async fetchAllAppData():Promise<IInitAppResponse>{
		let result = await Axios.get(`${this.URL}/app`);
		let CalculatedFields = ((result.data.CalculatedFields || []) as any[]).map(c=>new CalculatedField(c));
		let BookYears = ((result.data.BookYears || []) as any[]).map(c=>new BookYear(c));
		let UserConfigs = ((result.data.UserConfigs || []) as any[]).map(c=>new UserConfig(c));
		let DossierConfigs = ((result.data.DossierConfigs || []) as any[]).map(c=>new DossierConfig(c.SettingsKey, c.Value));
		let JobCount:number = (result.data.JobCount) || 0;
		let MyJobs = ((result.data.MyJobs || []) as any[]).map(c=>new Job(c));
		let MyJobIsRunning = result.data.MyJobIsRunning == true;
		let Browses = ((result.data.Browses || []) as any[]).map(c=>new Browse(c));
		let Vats = ((result.data.Vats || []) as any[]).map(c=>new Vat(c));
		let Currencies = ((result.data.Currencies || []) as any[]).map(c=>new Currency(c));
		let SaleTypes = ((result.data.SaleTypes || []) as any[]).map(c=>new SaleType(c));
		let Units = ((result.data.Units || []) as any[]).map(c=>new Unit(c));
		let BankTypes = ((result.data.BankTypes || []) as any[]).map(c=>new BankType(c));
		let HasUnreadNotifications = result.data.HasUnreadNotifications == true;
		let CrmStatuses = ((result.data.CrmStatuses || []) as any[]).map(s=>new CrmStatus(s));
		let browseFavouriteColumns = ((result.data.BrowseFavouriteColumns || []) as any[]).map(b=>new BrowseFavouriteColumns(b));
		return {CalculatedFields,BookYears,UserConfigs,DossierConfigs,
			JobCount, MyJobs, MyJobIsRunning,
			Browses,Vats,Currencies,SaleTypes,Units,BankTypes,HasUnreadNotifications,
			CrmStatuses,
			BrowseFavouriteColumns: browseFavouriteColumns,
			AutoSaleRowSetting: result.data.AutoSaleRowSetting || []
		};
	}
}

export const InitService = new InitServiceClass();