
import { Browse } from "@/models/browse";
import { HumanFilter } from "@/utils/human-filter";
import { HumanFilterNode } from "@/utils/human-filter-node";
import Vue from "vue";
import AppHumanFilterListEditNode from "./HumanFilterListEditNode.vue";
import AppEditFilter from "@/components/view-editor/FilterEdit.vue";
import { IModel } from "@/utils/models/model-interface";

export default Vue.extend({
	data(){
		return {
			mainNode: new HumanFilterNode(),
			toEditNode: null as null | HumanFilterNode,
			toEditFilter: null as null | HumanFilter,
			draggedElementParent: null as null | HumanFilterNode,
			draggedElement: null as null | HumanFilterNode,
			emittedFilters: [] as HumanFilter[],
		};
	},
	props: {
		value: {
			type: Array as ()=>HumanFilter[],
			required: true
		},
		model: {
			type: Object as ()=>IModel,
			required: true
		},
		browse: {
			type: Object as ()=>Browse,
		}
	},
	computed: {
		filterModal():Boolean {
			if (this.toEditFilter){
				return true;
			}
			return false;
		}
	},
	watch: {
		value: {
			immediate: true,
			handler(){
				if (this.value == this.emittedFilters) return;
				this.mainNode = HumanFilterNode.fromFilters(this.value);
				this.mainNode.isMainNode = true;
			}
		}
	},
	methods: {
		addFilter(){
			let filter = new HumanFilter();
			filter.Field = this.model.getFilterFields()[0].pathToFilterPath();
			filter.Operator = "=";
			filter.Values = [""];
			this.toEditFilter = filter;
		},
		addGroup(){
			let group = new HumanFilterNode();
			this.mainNode.children.push(group);
		},
		onEditNode(node:HumanFilterNode){
			if (!node.filter) return;
			this.toEditNode = node;
			this.toEditFilter = new HumanFilter(node.filter.getJSON());
		},
		saveEditFilter(){
			if (!this.toEditFilter) return;
			if (!this.toEditNode){
				let node = new HumanFilterNode();
				node.filter = this.toEditFilter;
				this.mainNode.children.push(node);
			}else{
				this.toEditNode.filter = this.toEditFilter;
			}
			this.toEditNode = null;
			this.toEditFilter = null;
			this.emitChanges();
		},
		cancelEditFilter(){
			this.toEditNode = null;
			this.toEditFilter = null;
		},
		onDragStart(node:HumanFilterNode, parent:HumanFilterNode){
			this.draggedElement = node;
			this.draggedElementParent = parent;
		},
		onDragStop(){
			this.draggedElement = null;
			this.draggedElementParent = null;
		},
		onDrop(parentElement:HumanFilterNode, sibbling:HumanFilterNode | null){
			if (!parentElement) return;
			if (!this.draggedElementParent) return;
			if (!this.draggedElement) return;
			let i = this.draggedElementParent.children.indexOf(this.draggedElement);
			if (i != -1) {
				this.draggedElementParent.children.splice(i, 1);
			}
			if (sibbling){
				i = parentElement.children.indexOf(sibbling);
			}
			if (i == -1){
				i = 0;
			}
			if (parentElement.children.length == 0){
				parentElement.children.push(this.draggedElement);
			}else{
				parentElement.children.splice(i, 0, this.draggedElement);
			}
			this.emitChanges();
		},
		emitChanges(){
			this.emittedFilters = this.mainNode.toFilters();
			this.$emit("input", this.emittedFilters);
		}
	},
	components: {
		AppHumanFilterListEditNode,
		AppEditFilter
	},
	i18nEx: {
		componentPrefix: "components.models"
	}
});
