
import Vue from "vue";
import { TranslateResult } from "vue-i18n";
import { HumanFilter } from "@/utils/human-filter";
import { Car } from "@/models/base";
import AppCarAutocomplete from "@/components/cars/CarAutocomplete.vue";
import { CarService } from "@/services";

export default Vue.extend({
	data(){
		return {
			cars: [] as  Car[]
		};
	},
	props: {
		value: {
			type: [String, Array as ()=>string[]],
			required: true
		},
		valueIndex: {
			type: Number,
			default(){
				return 0;
			}
		},
		multi: Boolean,
		label: {
			type: [Object as ()=>TranslateResult, String],
			default():TranslateResult{
				if (this.multi) return this.$ct("car");
				return this.$ct("car");
			}
		},
		filter: {
			type: Object as ()=>HumanFilter
		}
	},
	computed: {
		singleValue():string | undefined {
			if (typeof(this.value) == "object"){
				return this.value[0];
			}
			return this.value;
		},
	},
	watch: {
		value: {
			deep: true,
			immediate: true,
			async handler(){
				let allCars = [...this.cars];
				if (!this.value) return;
				if (typeof(this.value) == "string"){
					if (allCars.length == 1 && `${allCars[0].ID}` == this.value) {
						return;
					}
					let car = await CarService.get(parseInt(this.value));
					if (this.value == `${car.ID}`) {
						this.cars = [car];
					}
					return;
				}
				let toFetchCarIds = this.value.filter(c=>this.cars.findIndex(a=>`${a.ID}` == c) == -1).map(c=>parseInt(c));
				let cars:Car[] = [];
				if (toFetchCarIds.length >0 ){
					cars = await CarService.getCarsByIds(toFetchCarIds);
				}
				let result:Car[] = [];
				for (let carId of this.value) {
					let foundCar = this.cars.find(c=>`${c.ID}` == carId);
					if (foundCar) {
						result.push(foundCar);
						continue;
					}
					foundCar = cars.find(c=>`${c.ID}` == carId);
					if (foundCar) {
						result.push(foundCar);
						continue;
					}
				}
			}
		}
	},
	methods: {
		setSingleValue(value:Car){
			this.$emit("input", `${value.ID}`);
			this.cars = [value];
		},
		removeCar(i:number){
			this.cars.splice(i, 1);
			this.$emit("input", this.cars.map(c=>`${c.ID}`));
		},
		addCar(value:Car){
			this.cars.push(value);
			this.$emit("input", this.cars.map(c=>`${c.ID}`));
		}
	},
	components: {
		AppCarAutocomplete,
	},
	i18nEx: {
		componentPrefix: "components.models"
	}
});
