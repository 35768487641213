export class Mandat {
	id: string;
	method: string;
	status: string;
	isActive:boolean;
	details: {
		cardExpiryDate: Date
		cardFingerprint: string
		cardHolder: string
		cardLabel: string
		cardNumber: string
		consumerAccount: string
		consumerBic: string
	};

	constructor(data: any) {
		this.id = data.id;
		this.method = data.method;
		this.status = data.status;
		this.details = data.details;
		this.isActive = data.isActive;
		if (data.details.cardExpiryDate) {
			this.details.cardExpiryDate = new Date(data.details.cardExpiryDate);
		}
	}

	formatCardLabel():string {
		return this.details.cardLabel ? this.details.cardLabel + " •••• " : this.details.consumerAccount;
	}

}
