
import { Condition, ConditionPropertyType, Operator } from "@/utils/condition/condition";
import { v4 } from "uuid";
import Vue from "vue";
import { TranslateResult } from "vue-i18n";
import { ISelectItem } from "winfakt-vue-components";
export default Vue.extend({
	data(){
		return {
			uid: v4()
		};
	},
	props: {
		condition: {
			type: Object as ()=>Condition,
			required: true,
		},
		propertyType: {
			type: Number as ()=>ConditionPropertyType,
			required: true
		},
		label: {
			type: [String, Object as ()=>TranslateResult]
		},
		fancy: Boolean,
		notFullWidth: Boolean
	},
	computed: {
		md():string{
			if (this.notFullWidth) {
				if (this.isBool){
					return "5";
				}
				return "2";
			}
			return "12";
		},
		isBool():boolean{
			return this.propertyType == ConditionPropertyType.BOOL;
		},
		booleanValue():boolean {
			return this.condition.Value == "true";
		},
		availableOperators():Operator[]{
			return Condition.getAvailableOperatorsOfType(this.propertyType);
		},
		items():ISelectItem<Operator>[]{
			return this.availableOperators.map(o=>{
				return {
					text: this.$ct(`common.condition.operators.${o}`),
					value: o
				};
			});
		},
		booleanItems():ISelectItem<boolean>[] {
			return [
				{
					text: this.$ct("common.True"),
					value: true
				},
				{
					text: this.$ct("common.False"),
					value: false
				}
			];
		},
	},
	watch: {
		isBool: {
			immediate: true,
			handler(){
				if (this.isBool){
					this.condition.Operator = Operator.EQ;
				}
			}
		}
	},
	methods: {
		setBooleanValue(val:Boolean) {
			this.condition.Operator = Operator.EQ;
			this.condition.Value = val ? "true" : "false";
		}
	}
});
