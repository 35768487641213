
import AppMissingModule from "@/components/common/MissingModule.vue";
import AppExtraFieldsEdit from "@/components/ExtraFieldsEdit.vue";
import { Product } from "@/models/base";
import { AuthService } from "@/services/auth-service";
import Vue from "vue";
import { ExtraFieldNamesConfig } from "../../models/dossier-configs/extra-field-names-config";
import { DossierConfigService } from "../../services/dossier-config-service";

export default Vue.extend({
	props: {
		product: Object as () => Product
	},
	computed: {
		extraFieldNameSettings(): ExtraFieldNamesConfig {
			return DossierConfigService.getExtraFieldNamesConfigs().ProductNames;
		}
	},
	methods: {
		isAllowed(value: number): Boolean {
			return AuthService.hasModule(value);
		}
	},
	components: {
		AppExtraFieldsEdit,
		AppMissingModule
	},
	i18nEx: {
		componentPrefix: "components.products"
	}
});
