export class SellCategoryCalculationParameter {
	LowerValue:number = 0;
	UpperValue:number = 0;
	ProfitPercentage:number = 0;

	constructor(data?:any) {
		if (!data) {
			return;
		}
		this.LowerValue = data.LowerValue;
		this.UpperValue = data.UpperValue;
		this.ProfitPercentage = data.ProfitPercentage;
	}

	getJSON() {
		return {
			LowerValue: this.LowerValue,
			UpperValue: this.UpperValue,
			ProfitPercentage: this.ProfitPercentage
		};
	}

	isValid():boolean {
		return this.LowerValue < this.UpperValue;
	}
}