import { i18n } from "@/setup/i18n-setup";

export function getPositionalPluralization(value:number):string {
	if (i18n.locale == "nl-be"){
		let val = parseFloat(`${value}`.slice(-2));
		if (value == -1){
			return "laatste";
		}
		if (val == 1 || val == 8 || val >= 20){
			return `${val}ste`;
		}
		return `${val}de`;
	}

	return `${value}`;
}