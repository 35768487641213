import { EventInput } from "@fullcalendar/core/structs/event";
import { EventSourceInput } from "@fullcalendar/core/structs/event-source";
import { GoogleService } from "./google-service";

const Axios = GoogleService.axios;
export class GoogleCalendarServiceClass{


	async getCalendars(): Promise<gapi.client.calendar.CalendarListEntry[]> {
		let res = await Axios.get("https://www.googleapis.com/calendar/v3/users/me/calendarList");
		return res.data.items;
	}

	async getCalendar(calendarId:string):Promise<gapi.client.calendar.CalendarListEntry>{
		let res = await Axios.get(`https://www.googleapis.com/calendar/v3/users/me/calendarList/${calendarId}`);
		return res.data;
	}

	async getCalendarEvents( calendarId: string, beginTime: Date, endTime: Date, timeZone?: string): Promise<gapi.client.calendar.Event[]> {
		let res = await Axios.get(`https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events`, {
			params: {
				timeMin: beginTime.toJSON(),
				timeMax: endTime.toJSON(),
				singleEvents: true,
				orderBy: "startTime",
				timeZone
			}
		});
		return res.data.items;
	}


	createNewCalendarListObject(): gapi.client.calendar.CalendarListEntry {
		return {
			description: ""
		} as gapi.client.calendar.CalendarListEntry;
	}

	createNewCalendarEventObject(): gapi.client.calendar.Event {
		return {
			id: "",
			start: {
				//dateTime: ""
			},
			end: {
				//dateTime: ""
			},
			summary: ""
		} as gapi.client.calendar.Event;
	}

	async createCalendar(summary: string, backgroundColor: string): Promise<gapi.client.calendar.CalendarListEntry> {
		let res = await Axios.post("https://www.googleapis.com/calendar/v3/calendars", {summary});
		res = await Axios.post(`https://www.googleapis.com/calendar/v3/users/me/calendarList`, {
			id: res.data.id, backgroundColor: backgroundColor.toLowerCase(), foregroundColor: "#ffffff"}, {
			params: {
				colorRgbFormat: true
			}
		});
		return res.data;
	}

	async updateCalendar(calendarId: string, summary: string, backgroundColor: string): Promise<gapi.client.calendar.CalendarListEntry> {
		await Axios.patch(`https://www.googleapis.com/calendar/v3/calendars/${calendarId}`, {summary});
		await Axios.patch(`https://www.googleapis.com/calendar/v3/users/me/calendarList/${calendarId}`, {backgroundColor: backgroundColor.toLowerCase()}, {
			params: {
				colorRgbFormat: true
			}
		});
		return this.getCalendar(calendarId);
	}

	async deleteCalendar(calendarId: string) {
		await Axios.delete(`https://www.googleapis.com/calendar/v3/calendars/${calendarId}`);
	}

	async getEvent(eventId: string, calendarId: string): Promise<gapi.client.calendar.Event> {
		let result = await Axios.get(`https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events/${eventId}`);
		return result.data;
	}

	async putEvent(event: gapi.client.calendar.Event, calendarId: string): Promise<gapi.client.calendar.Event> {
		let result = await Axios.put(`https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events/${event.id}`, event);
		return result.data;
	}

	async postEvent(event: gapi.client.calendar.Event, calendarId: string): Promise<gapi.client.calendar.Event> {
		let result = await Axios.post(`https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events`, event);
		return result.data;
	}

	async deleteEvent(event: gapi.client.calendar.Event, calendarId: string): Promise<void> {
		await Axios.delete(`https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events/${event.id}`);
	}

	gCalToFullCalEvents(events: gapi.client.calendar.Event[], calendar: gapi.client.calendar.CalendarListEntry): EventInput[] {
		return events.map(item => {
			return {
				id: item.id,
				title: item.summary,
				start: item.start.dateTime || item.start.date, // try timed. will fall back to all-day
				end: item.end.dateTime || item.end.date, // same
				//url: item.htmlLink,
				location: item.location,
				description: item.description,
				backgroundColor: calendar.backgroundColor,
				extendedProps: {
					id: item.id,
					isGoogle: true,
					calendarId: calendar.id
				}
			} as EventInput;
		});
	}

	createFullCalendarEventSource(calendar: gapi.client.calendar.CalendarListEntry): EventSourceInput {
		return {
			async events(info, onSuccess, onFail): Promise<EventInput[]> {
				let events = await GoogleCalendarService.getCalendarEvents(calendar.id, info.start, info.end, info.timeZone);
				return GoogleCalendarService.gCalToFullCalEvents(events, calendar);
			}
		} as EventSourceInput;
	}

};

export const GoogleCalendarService = new GoogleCalendarServiceClass();