import { getSaleMapPropSelectGroup } from "../prop-select/sale-map-prop-group";
import { Field } from "../documents/field";
import { v4 as uuid} from "uuid";

export type SaleLayoutFreeLinePlacement = "" | "AfterDetail" | "Totals" | "AboveTable";

export class SaleLayoutFreeLine extends Field{
	public Placement: SaleLayoutFreeLinePlacement = "Totals";
	public FirstPageOnly = false;
	public LastPageOnly = false;
	public Key:string = uuid();
	public Label:string = "";

	constructor(data?: any) {
		super(data);
		if (!data) {
			return;
		}
		this.Placement = data.Placement;
		this.FirstPageOnly = data.FirstPageOnly || false;
		this.LastPageOnly = data.LastPageOnly || false;
		this.Key = data.Key;
		this.Label = data.Label;
		if (!this.Key) {
			this.Key = uuid();
		}
	}

	public getJSON():any {
		return {
			...super.getJSON(),
			Placement: this.Placement,
			FirstPageOnly: this.FirstPageOnly,
			LastPageOnly: this.LastPageOnly,
			Label: this.Label,
			Key: this.Key
		};
	}

	public getText(lang:string):string{
		return this.Text.getTranslation(lang) + this.getPropValue();
	}

	public getPropValue(): string {
		if (!this.Prop) return "";
		let field = getSaleMapPropSelectGroup().getPropSelectFromValue(this.Prop);
		if (field) {
			return field.text.toString();
		}
		return "";
	}
}
