import { GridSettings } from "@/utils/sale-layouts/grid-settings";

export class EditorSettings {
	ShowMarginRuler:boolean = false;
	PreviewDocumentID:string = "";
	GridSettings: GridSettings = new GridSettings()

	public constructor(data?:any){
		if (!data) return;
		this.ShowMarginRuler = data.ShowMarginRuler || false;
		this.PreviewDocumentID = data.PreviewDocumentID || "";
		this.GridSettings = data.GridSettings;
	}

	public getJSON(){
		return {
			ShowMarginRuler: this.ShowMarginRuler,
			PreviewDocumentID: this.PreviewDocumentID,
			GridSettings: new GridSettings(this.GridSettings)
		};
	}
}