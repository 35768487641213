import { getFileUrl } from "@/utils/url";

export class DocumentPageSettings {
	public Width: number = 210;
	public Height: number = 297;
	public MarginTop: number = 20;
	public MarginBottom: number = 5;
	public MarginLeft = 20;
	public MarginRight = 20;
	public BackgroundImage: string = "";
	public DefaultFont: string = "Times New Roman";
	public DefaultFontSize: string = "12";
	public HeadingHeight: number = 30;
	public FooterHeight: number = 20;
	public DefaultLanguage: string = "nl-be";
	public DefaultBackgroundColor: string = "#d2d2d2";
	public DefaultBorderColor: string = "#000";
	public DefaultBorderSize: string = "1";
	public DetailHeaderBorders: number = 0;
	public CustomCss: string = "";

	constructor(data?: any) {
		if (!data) {
			return;
		}
		this.Width = data.Width;
		this.Height = data.Height;
		this.MarginTop = data.MarginTop;
		this.MarginBottom = data.MarginBottom;
		this.MarginLeft = data.MarginLeft;
		this.MarginRight = data.MarginRight;
		this.BackgroundImage = data.BackgroundImage;
		this.DefaultFont = data.DefaultFont;
		this.DefaultFontSize = data.DefaultFontSize;
		this.HeadingHeight = data.HeadingHeight;
		this.FooterHeight = data.FooterHeight;
		this.DefaultLanguage = data.DefaultLanguage;
		this.CustomCss = data.CustomCss;
		if (data.DefaultBackgroundColor) {
			this.DefaultBackgroundColor = data.DefaultBackgroundColor;
		}
		if(data.DefaultBorderColor){
			this.DefaultBorderColor = data.DefaultBorderColor;
		}
		if(data.DefaultBorderSize){
			this.DefaultBorderSize = data.DefaultBorderSize;
		}
		if(data.DetailHeaderBorders){
			this.DetailHeaderBorders = data.DetailHeaderBorders;
		}
	}

	public getJSON() {
		return {
			Width: this.Width,
			Height: this.Height,
			MarginTop: this.MarginTop,
			MarginBottom: this.MarginBottom,
			MarginLeft: this.MarginLeft,
			MarginRight: this.MarginRight,
			BackgroundImage: this.BackgroundImage,
			DefaultFont: this.DefaultFont,
			DefaultFontSize: this.DefaultFontSize,
			HeadingHeight: this.HeadingHeight,
			FooterHeight: this.FooterHeight,
			DefaultLanguage: this.DefaultLanguage,
			DefaultBackgroundColor: this.DefaultBackgroundColor,
			DefaultBorderColor: this.DefaultBorderColor,
			DefaultBorderSize: this.DefaultBorderSize,
			DetailHeaderBorders: this.DetailHeaderBorders,
			CustomCss: this.CustomCss
		};
	}

	public getBackgroundImageUrl(): string {
		if (this.BackgroundImage == "") {
			return "";
		}
		return getFileUrl(this.BackgroundImage);
	}
}


