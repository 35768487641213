export class Channel {
	ID: number = 0;
	Name: string = "";
	UserID: number = 0;
	Unread: number = 0;
	TimeStamp: Date = new Date();
	ConversationID: number = 0;

	constructor(data?: any) {
		if (!data) return;
		this.ID = data.ID;
		this.Name = data.Name;
		this.UserID = data.UserID;
		this.Unread = data.Unread;
		this.ConversationID = data.ConversationID;
		this.TimeStamp = new Date(data.TimeStamp * 1000);
	}

}