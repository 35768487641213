
import AppHumanFilterEditValueCustomInput from "@/components/models/HumanFilterEditValueCustomInput.vue";
import Vue from "vue";
import { AskedFilter } from "@/utils/report-engine-settings/asked-filter";

export default Vue.extend({
	data(){
		return {
			ready: false
		};
	},
	props: {
		filter: {
			type: Object as ()=>AskedFilter,
			required: true
		},
		fieldType: {
			type: String,
			required: true
		}
	},
	computed: {
		hasOneValue():boolean {
			return this.filter.getValueAmount() == 1;
		},
		hasTwoValues():boolean {
			return this.filter.getValueAmount() == 2;
		},
		hasMultipleValues():boolean {
			return this.filter.getValueAmount() == -1;
		},
		hasNoValues():boolean {
			return this.filter.getValueAmount() == 0;
		},
		filterValueInput():string {
			return this.fieldType;
		}
	},
	mounted(){
		this.ready = true;
	},
	components: {
		AppHumanFilterEditValueCustomInput
	},
	i18nEx: {
		componentPrefix: "components.models"
	}
});
