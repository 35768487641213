export enum MODULE {
	FACTURATIE                          = 1,
	AANKOOPFACTUREN                     = 2,
	BANKGEGEVENS_VERWERKEN              = 3,
	BTW_AANGIFTE_EN_LISTING             = 4,
	DAGONTVANGSTEN                      = 5,
	BOEKHOUDLIJSTEN                     = 6,
	PROSPECTEN                          = 7,
	PROJECTEN_BEHEER                    = 8,
	ETIKETTEN                           = 9,
	MULTIVALUTA                         = 10,
	WINKELMODULE                        = 11,
	IMPORT                              = 12,
	VERKOOPDOCUMENTEN_LAYOUT            = 13,
	WAGENPARKBEHEER_PLUS_AUTOKOSTEN     = 14,
	MULTITALEN                          = 15,
	GRAFIEKEN                           = 16,
	DRANKENCENTRALE                     = 17,
	BARCODE_VERWERKING                  = 19,
	MULTI_VOORRAAD                      = 20,
	VOORRAADBEHEER                      = 21,
	BEHEER_VAN_BINNENGEKOMEN_TELEFOONS  = 22,
	BEHEER_VAN_BUITENGAANDE_FAXEN       = 23,
	EXTRA_TABS                          = 24,
	BACKORDERBEHEER                     = 25,
	INTRASTAT                           = 26,
	EXTRA_VELDEN                        = 27,
	EXPORT_NAAR_EXTERN_BOEKHPAKKET      = 28,
	PRIJSBEDING                         = 29,
	LICENTIE_2E_PC                      = 30,
	WERKEN_MET_VERKOPERS                = 31,
	IVK_VERWERKING                      = 32,
	PROJECTEN_MET_DOORFACTURATIE        = 33,
	FOSTPLUS_VERWERKIGN                 = 34,
	TOT_99_BANKEN                       = 35,
	TOT_99_JOURNALEN                    = 36,
	KOPPELING_MET_BANCONTACT            = 37,
	LINK_ID_CARD                        = 38,
	INTERNETMODULE                      = 39,
	CRM                                 = 40,
	AUTOMATISCHE_HERINNERINGSBRIEVEN    = 41,
	UITGESTELD_AFDRUKKEN                = 42,
	PRINTSERVER                         = 43,
	ABONNEMENTEN		                = 44,
	TOT_9_LEVERANCIERS_BUI_ARTIKELEN    = 45,
	AFDRUKRESULTATEN_MAILEN_PDF         = 46,
	OFFERTE_WIZZARD                     = 47,
	EXPORT_NAAR_OUTLOOK                 = 48,
	MATENTABEL                          = 49,
	SNELEXPORT_ARTIKELEN_NAAR_VERKOOP   = 50,
	AFSCHRIJVINGSTABELLEN               = 51,
	OPBRENGSTEN_KOSTEN_CALCULATIE       = 52,
	WHATSNEW_NIET_UITVOEREN             = 53,
	LINK_MET_ISABEL                     = 54,
	TAKSENVERWERKING                    = 55,
	EXPORTEREN_GEGEVENS                 = 56,
	EXPORT_NAAR_EXCEL                   = 57,
	CARPASS                             = 58,
	AUTOMATISCHE_NIEUWSBRIEF            = 59,
	KLANTDOSSIER_HISTORIEK              = 60,
	WINFAKT_MAPS                        = 61,
	WEBSHOP                             = 62,
	DEALERIMPORT                        = 63,
	DOCUMENTENBEHEER                    = 64,
	VOORSCHOTVERWERKING                 = 65,
	UITGESTELD_MAILEN                   = 66,
	ONLINE_EUROPESE_BTW_NR_CONTROLE     = 67,
	ONLINE_BACKUP                       = 68,
	WINFAKT_CLIENT_SERVER_VIA_SQL       = 69,
	LINK_MET_COMPANYWEB                 = 70,
	AGENDA                       		= 71,
	KOPPELING_GRANIT                    = 72,
	SENDCLOUD                           = 73,
	ELECTRONISCHE_FACTURATIE            = 74,
	KOPPELING_KRAMP                     = 75,
	TAKEN                     			= 76,
	CHAT								= 77,
	MOLLIE								= 78,
	EXTRA_USERS                     	= 79,
	EXTRA_DOSSIERS                      = 80,
	DOCUMENTS                           = 81,
	EXTRA_SALE_TYPES                    = 82,
	CLIENT_PORTAL                       = 83,
	API_ACCESS = 84,
	CROSS_DOSSIER_ACCESS = 85,
	DIGITALE_HANDTEKENING = 86,
	CASHDRO = 87,
	TIMETRACKING = 88,
	INKOMENDE_AANKOPEN_UIT_MAILBOX = 89,
}