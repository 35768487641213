import { EventInput } from "@fullcalendar/core/structs/event";
import { Calendar } from "./calendar";
import { Sale } from "./base";
import { stringToHash } from "@/utils/strings";

export enum GetColorMethod {
	NONE = "",
	SALE_DELIVERY_ADDRESS_POSTAL_CODE = "saly-delivery-address-postal-code",
	COLOR_FIELD = "color-field"
}

export class CalendarEvent {
	ID: number = 0;
	Title: string = "";
	StartTime: Date = new Date();
	EndTime: Date = new Date();
	CalendarID: number = 0;
	Calendar: Calendar | null = null;
	AllDay: boolean = false;
	SaleID:number = 0;
	SaleBookYear:number = 0;
	Sale:Sale | null = null;
	StartTimeIsLinkedToSaleDeliveryDate:boolean = false;
	Color:string = "";
	GetColorMethod:GetColorMethod = GetColorMethod.NONE;

	constructor(data?: any) {
		if (!data) {
			return;
		}
		this.ID = data.ID;
		this.Title = data.Title;
		this.StartTime = new Date(data.StartTime);
		this.EndTime = new Date(data.EndTime);
		this.CalendarID = data.CalendarID;
		this.AllDay = data.AllDay;
		this.SaleID = data.SaleID;
		this.SaleBookYear = data.SaleBookYear;
		this.StartTimeIsLinkedToSaleDeliveryDate = data.StartTimeIsLinkedToSaleDeliveryDate;
		this.Color = data.Color || "";
		this.GetColorMethod = data.GetColorMethod || GetColorMethod.NONE;
		if (data.Calendar) {
			this.Calendar = new Calendar(data.Calendar);
		}
		if (data.Sale) {
			this.Sale = new Sale(data.Sale);
		}
	}

	getJSON() {
		return {
			ID: this.ID,
			Title: this.Title,
			AllDay: this.AllDay,
			StartTime: this.StartTime.toJSON(),
			EndTime: this.EndTime.toJSON(),
			CalendarID: this.CalendarID,
			SaleID: this.SaleID,
			SaleBookYear: this.SaleBookYear,
			StartTimeIsLinkedToSaleDeliveryDate: this.StartTimeIsLinkedToSaleDeliveryDate,
			Color: this.Color,
			GetColorMethod: this.GetColorMethod
		};
	}

	private static readonly colors:string[] = [
		"#D0021B", "#F5A623", "#F8E71C", "#8B572A", "#7ED321",
		"#417505", "#BD10E0", "#9013FE", "#4A90E2", "#50E3C2",
	]

	private getColorOverride():string {
		if (this.GetColorMethod == GetColorMethod.NONE) {
			return "";
		}
		if (this.GetColorMethod == GetColorMethod.COLOR_FIELD) {
			return this.Color;
		}
		if (this.GetColorMethod == GetColorMethod.SALE_DELIVERY_ADDRESS_POSTAL_CODE) {
			if (!this.Sale) return "";
			let postal = "";
			if (!this.Sale.DeliveryAddress) {
				if (!this.Sale.InvoiceAddress) return "";
				postal = this.Sale.InvoiceAddress.Postal;
			}else{
				postal = this.Sale.DeliveryAddress.Postal;
			}
			let hash = stringToHash(postal);
			let index = parseInt(hash.toString().split("").pop() || "0");
			return CalendarEvent.colors[index];
		}
		return "";
	}

	toFullCalendar(): EventInput {
		return {
			id: this.ID,
			title: this.Title,
			start: this.StartTime,
			end: this.EndTime,
			allDay: this.AllDay,
			url: "", // ServerConfig.host + "/calendar-event/" + this.ID,
			location: "",
			description: this.Title,
			extendedProps: {
				id: this.ID,
				isGoogle: false,
				start: this.StartTime,
				end: this.EndTime,
				sale: this.Sale,
				colorOverride: this.getColorOverride(),
			}
		};
	}
}