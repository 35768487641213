import { TaskStatus } from "@/models/tasks/task";
import { HumanFilter } from "../human-filter";

export class DashboardTasksData{
	public filters:HumanFilter[] = [];

	public constructor(data?:any){
		if (!data) {
			this.filters = [
				new HumanFilter({
					Field: "this.Status",
					Operator: "=",
					Values: [`${TaskStatus.PENDING}`]
				})
			];
			return;
		};
		if (typeof(data.filters) == "object") {
			data.filters = JSON.stringify(data.filters);
		}
		this.filters = (JSON.parse(data.filters || "[]") || []).map((f:any)=>new HumanFilter(f));
	}

	getJSON(){
		return {
			filters: JSON.stringify(this.filters.map(f=>f.getJSON()))
		};
	}
}