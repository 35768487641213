
import { PropSelectItem } from "@/utils/prop-select/prop-select-item";
import Vue from "vue";
export default Vue.extend({
	props: {
		item: {
			type: Object as ()=>PropSelectItem,
			required: true
		},
		hoveredValue: String
	},
	watch: {
		hoveredValue: {
			immediate: false,
			handler(){
				if (this.hoveredValue == this.item.value){
					this.scrollToSelf();
				}
			}
		}
	},
	methods: {
		onClick(){
			this.$emit("click", this.item.value);
		},
		scrollToSelf(){
			let parent = this.$el.parentElement;
			while(parent != null){
				if (parent.classList.contains("w-scroll-view")){
					let height = this.$el.clientHeight;
					let offsetTop = (this.$el as HTMLElement).offsetTop;
					let parentHeight = parent.clientHeight;
					let scrollPos = parent.scrollTop;
					if ((offsetTop + height) < (parentHeight + scrollPos) && offsetTop >= scrollPos) {
						return;
					}
					if ((offsetTop < scrollPos)){
						parent.scrollTo(0, offsetTop);
					}else {
						parent.scrollTo(0, (offsetTop + height) - parentHeight );
					}
					return;
				}

				parent = parent.parentElement;
			}
		}
	}
});
