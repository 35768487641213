import { Admin } from "@/models/admin";
import { UserInfo } from "@/models/dossier/user-info";
import { TicketMessageAttachement } from "@/models/tickets/attachement";
import { AuthSocketService } from "@/services/auth-socket-service";
import { ChatMessage } from "@/utils/chat-message";
import { AuthService } from "@/services/auth-service";
import { ChatMessageFile } from "@/utils/chat-message-file";
import Axios from "axios";
import { downloadBlob } from "@/utils/blob";

export class TicketMessage {
	id:number;
	userId:number;
	adminId:number;
	ticketId:number;
	content:string;
	timeCreated:Date;
	admin?:Admin;
	user?:UserInfo;
	attachements:TicketMessageAttachement[] = [];

	constructor(data:any) {
		this.id = data.id;
		this.userId = data.userId;
		this.adminId = data.adminId;
		this.ticketId = data.ticketId;
		this.content = data.content;
		this.timeCreated = new Date(data.timeCreated);
		if (data.admin) {
			this.admin = new Admin(data.admin);
		}
		if (data.user) {
			this.user = new UserInfo(data.user);
		}
		if (data.attachements) {
			this.attachements = data.attachements.map((d:any) => new TicketMessageAttachement(d));
		}
	}

	registerSocketEvents() {
		this.deregisterSocketEvents();
		AuthSocketService.on(`/tickets/message/${this.id}/update`, (data:any) => {
			this.userId = data.userId;
			this.adminId = data.adminId;
			this.content = data.content;
			this.ticketId = data.ticketId;
		}, this);
		AuthSocketService.on(`/tickets/message/${this.id}/attachement`, (data:any) => {
			this.attachements.push(new TicketMessageAttachement(data));
		}, this);
	}


	deregisterSocketEvents() {
		AuthSocketService.offAll(this);
	}

	getUserInfo():{username:string, profileImage:string} | null {
		if (this.admin) {
			return {
				username: this.admin.name,
				profileImage: this.admin.imageUrl
			};
		}
		if (this.user) {
			return {
				username: this.user.Firstname,
				profileImage: this.user.getProfileImgUrl()
			};
		}
		return null;
	}

	toChatMessage():ChatMessage {
		let message = new ChatMessage();
		message.id = `${this.id}`;
		message.message = this.content;
		message.userIsSender = this.userId == AuthService.wfUser.ID;
		message.dateSent = this.timeCreated;
		let userInfo = this.getUserInfo();
		if (userInfo){
			message.senderImageUrl = userInfo.profileImage;
			message.senderName = userInfo.username;
		}
		for (let attachement of this.attachements){
			let file = new ChatMessageFile();
			file.fileName = attachement.fileName;
			file.fileType = attachement.mimeType;
			file.fileUrl = encodeURI(attachement.attachementUrl);
			if (!file.isImage()){
				file.onFileClick = async function(){
					let result = await Axios.get(file.fileUrl, {responseType: "blob"});
					downloadBlob(new Blob([result.data], {type: file.fileType}), file.fileName);
				};
			}
			message.files.push(file);
		}

		return message;
	}
}