import { ServerConfig } from "@/config/config";
import { WfOnlineApiKey } from "@/models/wf-online-api-key";
import { Axios } from "@/utils/axios";

export class WfOnlineApiKeyServiceClass {
	public readonly url:string = `${ServerConfig.host}/api-keys`

	public async getKeys():Promise<WfOnlineApiKey[]>{
		let result = await Axios.get(`${this.url}`);
		return result.data.map((d:any)=>new WfOnlineApiKey(d));
	}

	public async createApiKey(keyName:string):Promise<string>{
		let result = await Axios.post(`${this.url}`, {name: keyName});
		return result.data.key;
	}

	public async deleteApiKey(keyId:number):Promise<void>{
		await Axios.delete(`${this.url}/${keyId}`);
	}
}

export const WfOnlineApiKeyService = new WfOnlineApiKeyServiceClass();