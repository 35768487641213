import { render, staticRenderFns } from "./ErrorMessageBox.vue?vue&type=template&id=47b399df&scoped=true"
import script from "./ErrorMessageBox.vue?vue&type=script&lang=ts"
export * from "./ErrorMessageBox.vue?vue&type=script&lang=ts"
import style0 from "./ErrorMessageBox.vue?vue&type=style&index=0&id=47b399df&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47b399df",
  null
  
)

export default component.exports