
import Vue from "vue";
export default Vue.extend({
	data() {
		return {
			show: false
		};
	},
	props: {
		delay: {
			type: Number,
			default: 500
		},
		value: {
			type: Boolean,
			default: false,
		}
	},
	mounted() {
		setTimeout(() => {
			this.show = true;
			this.$emit("input", true);
		}, this.delay);
		this.$emit("input", false);
	}
});
