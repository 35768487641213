import { PlanModule } from "./plan-module";
import { PriceFrequencyType } from "@/models/payment/pricing";

export class PlanModulePricing extends PlanModule {
	protected Price: number = 0.0;
	protected PermanentPrice = 0.0;
	Name: string = "";
	paymentFrequency: PriceFrequencyType = PriceFrequencyType.QUARTER;

	public constructor(data:any) {
		super(data);
		this.Price = data.Price;
		this.PermanentPrice = data.PermanentPrice;
		this.Name = data.Name;
	}

	getPrice(): number {
		return this.Price;
	}

	getPermanentPrice(): number {
		return this.PermanentPrice;
	}

	getComponentName(): string {
		return "default";
	}

	upgrade(metadata:any) {
		return;
	}

}