import { ServerConfig } from "@/config/config";
import { LedgerAccount } from "@/models/ledger-account";
import { Axios } from "@/utils/axios";

export class LedgerAccountServiceClass {
	public readonly url:string = `${ServerConfig.host}/ledger-account`;
	private ledgerAccountCache:LedgerAccount[] = [];

	private updateCache(account:LedgerAccount) {
		let i = this.ledgerAccountCache.findIndex(l=>l.Code == account.Code);
		if (i == -1){
			this.ledgerAccountCache.push(account);
		}else{
			this.ledgerAccountCache[i] = account;
		}
	}

	public async fetchLedgerAccounts():Promise<LedgerAccount[]> {
		if (this.ledgerAccountCache.length != 0) {
			return this.ledgerAccountCache.map(l=>new LedgerAccount(l.getJSON()));
		}
		let result = await Axios.get(this.url);
		this.ledgerAccountCache = result.data.map((l:any)=>new LedgerAccount(l));
		return result.data.map((l:any)=>new LedgerAccount(l));
	}

	public async createLedgerAccount(account:LedgerAccount):Promise<LedgerAccount> {
		let result = await Axios.post(this.url, account.getJSON());
		this.updateCache(new LedgerAccount(result.data));
		return new LedgerAccount(result.data);
	}

	public async update(account:LedgerAccount):Promise<void> {
		let response = await Axios.put(`${this.url}/${account.Code}`, account.getJSON());
		let result = new LedgerAccount(response.data);
		this.updateCache(result);
		for (let a of this.ledgerAccountCache) {
			if (a.GroupCode == account.Code) {
				a.Description = result.Description;
			}
		}
	}

	public async deleteLedgerAccount(account:LedgerAccount):Promise<void>{
		await Axios.delete(`${this.url}/${account.Code}`);
		let i = this.ledgerAccountCache.findIndex(l=>l.Code == account.Code);
		if (i != -1) {
			this.ledgerAccountCache.splice(i, 1);
		}
	}

	public async importCsv(file:File):Promise<void> {
		let form = new FormData();
		form.append("csv", file);
		await Axios.post(`${this.url}/import-csv`, form);
		this.ledgerAccountCache.splice(0, this.ledgerAccountCache.length);
		await this.fetchLedgerAccounts();
	}
}

export const LedgerAccountService = new LedgerAccountServiceClass();