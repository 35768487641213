export class Invoice {
	id:string = "";
	date:Date = new Date();
	amount:string = "";
	status:string = "";
	url:string = "";
	private isCancellable:boolean;

	constructor(data:any){
		this.id = data.id;
		this.date = new Date(data.date * 1000);
		this.amount = data.amount;
		this.status = data.status;
		this.isCancellable = data.isCancellable;
		this.url = data.url;
	}

	cancelable():boolean{
		return (this.status == "open" || this.status == "pending") && this.isCancellable;
	}
}