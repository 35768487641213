
export class CheckVies {
	public valid?: boolean;
	public name: string = "";
	public identifier: string = "";
	public timestamp: Date = new Date();
	constructor(data: any) {
		this.valid = data.valid;
		this.name = data.name || "";
		this.identifier = data.identifier || "";
		this.timestamp = data.timestamp;
	}
}
