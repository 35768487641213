export class TinySaleInfo {
	ID:number = 0;
	TypeID:number = 0;
	ComputedFriendlyID:string = "";

	public constructor(data?:any) {
		if (!data) return;
		this.ID = data.ID;
		this.TypeID = data.TypeID;
		this.ComputedFriendlyID = data.ComputedFriendlyID;
	}

	public getJSON(){
		return {
			ID: this.ID,
			TypeID: this.TypeID,
			ComputedFriendlyID: this.ComputedFriendlyID
		};
	}
}