
import AppSelectView from "@/components/browse/SelectView.vue";
import AppQuickSearch from "@/components/browse/BrowseQuickSearch.vue";
import Vue from "vue";
import { BrowseStateSearch } from "@/utils/browse/browse-state-search";
import AppReportsDropdown from "./BrowseReportsDropdown.vue";
import AppTag from "@/components/common/Tag.vue";
import { Sale } from "@/models/base";
import { IBrowseBaseDataSource } from "@/utils/browse/browse-base-data-source";
import { ISelectItem } from "winfakt-vue-components";

export default Vue.extend({
	data(){
		return {
			isPrintingBrowse: false
		};
	},
	props: {
		state: {
			required: true,
			type: Object as () => BrowseStateSearch
		}
	},
	computed: {
		reports():any[]{
			return [];
		},
		dataSource():IBrowseBaseDataSource<any> {
			return this.state.dataSource;
		},
		canView():boolean{
			return this.itemSelected;
		},
		itemSelected():boolean {
			return this.state.selectedItems.length == 1;
		},
		buttonBarItems():ISelectItem<string | Function>[] {
			return this.state.getButtonBarItems();
		},
		isFetching():boolean {
			return this.state.fetching;
		}
	},
	methods: {
		startSearch(val:string){
			this.state.selectedItems.splice(0, this.state.selectedItems.length);
			this.state.querySettings.searchQuery = val;
			this.state.fetch();
		},
		clearSearchQuery() {
			let filters = this.state.getFilters();
			filters.searchQuery = "";
			filters.filters = [];
			this.state.setFilters(filters);
			this.state.fetch();
		},
		infoClicked() {
			if(this.validateSaldoDocuments()){
				return;
			}
			if (this.dataSource.info) {
				this.dataSource.info(this.state.selectedItems[0]);
			}
		},
		validateSaldoDocuments(): Boolean{
			if(this.state.browse.ID==6){
				if(this.state.selectedItems[0] && (this.state.selectedItems[0] as Sale).isSaldoDocument()){
					this.$wf.dialog(this.$ct("saldo-warning"), this.$ct("common.Ok"), this.$ct("common.attention"), "info");
					return true;
				}
			}
			return false;
		},
		trigger(value:string | Function) {
			if (typeof(value) == "function"){
				value();
				return;
			}
			this.state.buttonBarItemTrigger(value);
		},
		clearFilters() {
			let filters = this.state.getFilters();
			filters.filters = [];
			this.state.setFilters(filters);
			this.state.fetch();
		},
		async refresh(){
			await this.state.fetch();
		}
	},
	components: {
		AppSelectView,
		AppReportsDropdown,
		AppTag,
		AppQuickSearch
	},
	i18nEx: {
		componentPrefix: "components.browse"
	}
});
