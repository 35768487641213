
import { StockLocationConfig } from "@/models/dossier-configs/stock-location";
import { Product } from "@/models/base";
import { ProductStock } from "@/models/base";
import { ProductWriteOffRow } from "@/models/base";
import { DossierConfigService } from "@/services/dossier-config-service";
import Vue from "vue";
import AppActualStock from "./ProductActualStock.vue";
import AppProductsProductEditWriteOff from "./ProductEditWriteOff.vue";

export default Vue.extend({
	data() {
		return {
			toEditWriteOff: undefined as undefined | ProductWriteOffRow,
			advancedStockHandling: true,
		};
	},
	props: {
		product: {
			required: true,
			type: Object as () => Product,
		}
	},
	computed: {
		productStock():ProductStock{
			if (!this.product.Stock){
				return new ProductStock();
			}
			return this.product.Stock;
		},
		actualStock():any {
			if (!this.productStock) {
				return;
			}
			return this.stockLocationConfig.getSortedLocationNames().map((l: string, i: number) => {
				return {
					text: l,
					value: i + 1,
					stock: this.productStock["Location" + (i + 1) + "Qty" as keyof ProductStock] as number
				} as any;
			});
		},
		stockLocationConfig():StockLocationConfig {
			return DossierConfigService.getStockLocationConfig();
		}
	},
	methods: {
		add() {
			this.toEditWriteOff = new ProductWriteOffRow();
		},
		onConfirmAdd(writeOffProduct: ProductWriteOffRow) {
			writeOffProduct.ProductID = this.product.ID;
			if (this.product.WriteOffProducts.indexOf(writeOffProduct) == -1) {
				this.product.WriteOffProducts.push(writeOffProduct);
			}
		},
		closeModal() {
			this.toEditWriteOff = undefined;
		},
		editEntry(writeOffProduct: ProductWriteOffRow) {
			this.toEditWriteOff = writeOffProduct;
		},
		removeEntry(index: number) {
			this.product.WriteOffProducts.splice(index, 1);
		}
	},
	components: {
		AppActualStock,
		AppProductsProductEditWriteOff
	},
	i18nEx: {
		componentPrefix: "components.products"
	}
});
