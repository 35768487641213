
import { ReportEngineSettings } from "@/utils/report-engine-settings/report-engine-settings";
import Vue from "vue";
import AppFileUpload from "@/components/FileUpload.vue";

export default Vue.extend({
	data(){
		return {
			fileName: "",
			value: null as null | ReportEngineSettings
		};
	},
	methods: {
		close(){
			this.$emit("close");
		},
		save(){
			if (!this.value) return;
			this.$emit("input", this.value);
			this.close();
		},
		async onFile(file:File) {
			let text = await file.text();
			let json = JSON.parse(text);
			if (!json) return;
			if (Array.isArray(json)){
				return;
			}
			this.fileName = file.name;
			this.value = new ReportEngineSettings(json);
		}
	},
	components: {
		AppFileUpload
	},
	i18nEx: {
		componentPrefix: "components.reportengine"
	}
});
