export class ReminderConfig {
	ShowAllInOverview: boolean = true;
	AutoCalculateCosts: boolean = true;
	AddCostFromDayOne: boolean = true;
	ReminderFromExpiration: boolean = true;
	AddCreditNotes: boolean = true;
	Intrests: number = 1;
	AdminCostFrom: number = 2;
	HCost1: number = 0;
	HCost2: number = 0;
	HCost3: number = 0;
	HCost4: number = 0;
	HPerc1: number = 0;
	HPerc2: number = 0;
	HPerc3: number = 0;
	HPerc4: number = 0;
	AutomaticRemindersEnabled:boolean = false;
	AutomaticReminderDays:number = -1;
	AutomaticSend1AfterDays:number = 30;
	AutomaticSend2AfterDays:number = 60;
	AutomaticSend3AfterDays:number = 90;
	AutomaticSend4AfterDays:number = 120;
	AutomaticGroupReminders:boolean = false;
	AutomaticReminderIncludeReadConfirm:boolean = false;
	AutomaticLayoutID:number = 0;
	IncludeSales:boolean = false;
	IncludeSalesForPrint:boolean = false;
	BccAddress:string = "";


	constructor(data?: any) {
		if (!data)return;
		this.ShowAllInOverview = data.ShowAllInOverview;
		this.AutoCalculateCosts = data.AutoCalculateCosts;
		this.AddCostFromDayOne = data.AddCostFromDayOne;
		this.ReminderFromExpiration = data.ReminderFromExpiration;
		this.AddCreditNotes = data.AddCreditNotes;
		this.Intrests = data.Intrests;
		this.AdminCostFrom = data.AdminCostFrom;
		this.HCost1 = data.HCost1;
		this.HCost2 = data.HCost2;
		this.HCost3 = data.HCost3;
		this.HCost4 = data.HCost4;
		this.HPerc1 = data.HPerc1;
		this.HPerc2 = data.HPerc2;
		this.HPerc3 = data.HPerc3;
		this.HPerc4 = data.HPerc4;
		this.AutomaticRemindersEnabled = data.AutomaticRemindersEnabled;
		this.AutomaticReminderDays = data.AutomaticReminderDays ? data.AutomaticReminderDays : -1;
		this.AutomaticSend1AfterDays = data.AutomaticSend1AfterDays;
		this.AutomaticSend2AfterDays = data.AutomaticSend2AfterDays;
		this.AutomaticSend3AfterDays = data.AutomaticSend3AfterDays;
		this.AutomaticSend4AfterDays = data.AutomaticSend4AfterDays;
		this.AutomaticGroupReminders = data.AutomaticGroupReminders;
		this.AutomaticLayoutID = data.AutomaticLayoutID;
		this.IncludeSales = data.IncludeSales;
		this.IncludeSalesForPrint = data.IncludeSalesForPrint;
		this.BccAddress = data.BccAddress;
		this.AutomaticReminderIncludeReadConfirm = data.AutomaticReminderIncludeReadConfirm || false;
	}

	public validateAutomaticSettings():boolean {
		if (this.AutomaticSend1AfterDays >= this.AutomaticSend2AfterDays){
			return false;
		}
		if (this.AutomaticSend2AfterDays >= this.AutomaticSend3AfterDays){
			return false;
		}
		if (this.AutomaticSend3AfterDays >= this.AutomaticSend4AfterDays){
			return false;
		}
		return true;
	}

	getJSON() {
		return {
			ShowAllInOverview: this.ShowAllInOverview,
			AutoCalculateCosts: this.AutoCalculateCosts,
			AddCostFromDayOne: this.AddCostFromDayOne,
			ReminderFromExpiration: this.ReminderFromExpiration,
			AddCreditNotes: this.AddCreditNotes,
			Intrests: this.Intrests,
			AdminCostFrom: this.AdminCostFrom,
			HCost1: this.HCost1,
			HCost2: this.HCost2,
			HCost3: this.HCost3,
			HCost4: this.HCost4,
			HPerc1: this.HPerc1,
			HPerc2: this.HPerc2,
			HPerc3: this.HPerc3,
			HPerc4: this.HPerc4,
			AutomaticRemindersEnabled: this.AutomaticRemindersEnabled,
			AutomaticSend1AfterDays: this.AutomaticSend1AfterDays,
			AutomaticSend2AfterDays: this.AutomaticSend2AfterDays - this.AutomaticSend1AfterDays,
			AutomaticSend3AfterDays: this.AutomaticSend3AfterDays - this.AutomaticSend2AfterDays,
			AutomaticSend4AfterDays: this.AutomaticSend4AfterDays - this.AutomaticSend3AfterDays,
			AutomaticGroupReminders: this.AutomaticGroupReminders,
			AutomaticReminderIncludeReadConfirm: this.AutomaticReminderIncludeReadConfirm,
			AutomaticLayoutID: this.AutomaticLayoutID,
			IncludeSales: this.IncludeSales,
			IncludeSalesForPrint: this.IncludeSalesForPrint,
			BccAddress: this.BccAddress
		};
	}
}