
import AppFileUpload from "@/components/FileUpload.vue";
import Vue from "vue";

export default Vue.extend({
	props: {
		sending: Boolean,
		label: String,
		allowedExtensions: {
			type: Array as () => string[],
			default: () => []
		},
		maxSize: {
			type: Number,
			default: ()=>15000000
		},
		disabled: Boolean,
		multiple: Boolean,
	},
	methods: {
		onInput(event: Event) {
			this.$emit("input", event);
		},
		onStart() {
			this.$emit("start");
		},
		onFinished(event: Event) {
			this.$emit("finished", event);
		},
		onError(error: { message: string, data: any }) {
			this.$emit("error", error);
		}
	},
	components: {
		AppFileUpload
	}
});
