import { getSaleMapPropSelectGroup } from "../prop-select/sale-map-prop-group";
import { FieldWithSeperateStyles } from "../documents/field-with-seperate-styles";

export class SaleLayoutTableLine extends FieldWithSeperateStyles {
	Position:number = 0.0;
	Width:number = 50.0;

	public constructor(data?:any){
		super(data);
		if (!data) {
			return;
		}
		this.Position = data.Position;
		this.Width = data.Width;
	}

	public getJSON():any{
		return {...super.getJSON(), Position: this.Position, Width: this.Width};
	}

	getText(lang:string):string{
		let val = this.getPropName();
		return this.Text.getTranslation(lang) + val + this.TextAfter.getTranslation(lang);
	}

	getPropName():string{
		if (!this.Prop) return "";
		let val = getSaleMapPropSelectGroup().getPropSelectFromValue(this.Prop);
		if (!val) return "";
		return val.text.toString();
	}

	getWidth():string{
		return `${this.Width}mm`;
	}
}