import { ServerConfig } from "@/config/config";
import { Contact, Sale } from "@/models/base";
import { SaleEmailTemplate } from "@/models/sale-email-template";
import { Axios } from "@/utils/axios";
import { EmailTemplateInfo } from "@/utils/email-template-info";
import { formdataFromIMail, IMail } from "@/utils/mail";
import { HttpError } from "@/utils/services/http-error";
import Vue from "vue";
import { AWSFileService } from "./aws-file-service";
import { MailConfig } from "@/utils/mail-config";
import { MailSettings } from "@/models/mail-settings";

const url = `${ServerConfig.host}/mail`;

export interface IComputedEmail {
	Body:string;
	Subject:string;
	Attachments:File[];
}

export const MessagesService = new Vue({
	computed: {
		saleUrl(): string {
			return ServerConfig.host + "/sale-template";
		},
		portalUrl(): string {
			return ServerConfig.host + "/portal-template";
		}
	},
	methods: {
		async autoMailConfig(e: string): Promise<MailConfig>{
			let result = await Axios.get(url+"/auto-config?email="+e);
			return new MailConfig(result.data);
		},
		async getSaleTemplates(): Promise<EmailTemplateInfo[]> {
			let result = await Axios.get(this.saleUrl);
			return result.data.map((r: any) => new EmailTemplateInfo(r));
		},
		async getSaleTemplate(id: number): Promise<SaleEmailTemplate> {
			let result = await Axios.get(this.saleUrl +"/"+ id);
			return new SaleEmailTemplate(result.data);
		},
		async setSaleTemplateOrder(templates:EmailTemplateInfo[]):Promise<void>{
			await Axios.post(`${this.saleUrl}/set-order`, templates.map(t=>t.ID));
		},
		async postSaleTemplates(templates: SaleEmailTemplate[]): Promise<SaleEmailTemplate[]> {
			let result = await Axios.post(this.saleUrl, templates.map(t => t.getJSON()));
			return result.data.map((r: any) => new SaleEmailTemplate(r));
		},
		async putSaleTemplate(template: SaleEmailTemplate): Promise<SaleEmailTemplate> {
			let result = await Axios.put(`${this.saleUrl}/${template.ID}`, template.getJSON());
			return new SaleEmailTemplate(result.data);
		},
		async deleteSaleTemplates(template: (SaleEmailTemplate | EmailTemplateInfo)): Promise<void> {
			await Axios.delete(`${this.saleUrl}/${template.ID}`);
		},
		async saleTemplateAddAttachment(template:SaleEmailTemplate, file:File):Promise<SaleEmailTemplate>{
			let data = new FormData();
			data.append("file", file, file.name);
			let result = await Axios.post(`${this.saleUrl}/${template.ID}/add-attachment`, data);
			return new SaleEmailTemplate(result.data);
		},
		async saleTemplateRemoveAttachment(template:SaleEmailTemplate, token:string):Promise<SaleEmailTemplate>{
			let result = await Axios.post(`${this.saleUrl}/${template.ID}/remove-attachment/${token}`);
			return new SaleEmailTemplate(result.data);
		},
		async sendMail(mail: IMail, settings:MailSettings): Promise<any> {
			let data = formdataFromIMail(mail);

			let p = new Promise((resolve, reject) => {
				let returned = false;
				setTimeout(() => {
					if (!returned) {
						returned = true;
						reject(new Error("timeout"));
					}
				}, 8000);
				Axios.post(`${url}/send/${settings.ID}`, data).then((result) => {
					if (!returned) {
						returned = true;
						resolve(result);
					}
				}).catch((error) => {
					if (!returned) {
						returned = true;
						reject(error);
					}
				});
			});

			return p;
		},
		async getComputedSaleMail(template: number | SaleEmailTemplate | EmailTemplateInfo, sale: Sale): Promise<IComputedEmail> {
			if (typeof(template) == "number") {
				var templateId = template;
			} else {
				var templateId = template.ID;
			}
			let temp = await this.getSaleTemplate(templateId);
			let result = await Axios.get(`${this.saleUrl}/${templateId}/compute/${sale.ID}`);
			let res = {
				Body: result.data.Body,
				Subject: result.data.Subject,
				Attachments: []
			} as IComputedEmail;
			for (let attachment of temp.Attachments){
				res.Attachments.push(await AWSFileService.fetchFile(attachment.Key, attachment.FileName));
			}
			return res;
		},
		async getComputedPortalMail(contact: Contact): Promise<IComputedEmail> {
			let result = await Axios.get(`${this.portalUrl}/compute-template/${contact.ID}`);
			return result.data;
		},
		async getSaleEmailTemplateByIDOrFirst(id:number):Promise<SaleEmailTemplate>{
			try{
				return await this.getSaleTemplate(id);
			}catch(hErr){
				let err = hErr as HttpError;
				if (err.status == 404){
					err.dontShow = true;
					let templates = await this.getSaleTemplates();
					let template = templates[0];
					return this.getSaleTemplate(template.ID);
				}
			}
			throw new Error("Unreachable code");
		}
	},
});