
import { HumanFilter } from "@/utils/human-filter";
import AppHumanFilterEditValueCustomInput from "./HumanFilterEditValueCustomInput.vue";
import Vue from "vue";
import { TranslateResult } from "vue-i18n";

export default Vue.extend({
	data(){
		return {
			ready: false
		};
	},
	props: {
		filter: {
			type: Object as ()=>HumanFilter,
			required: true
		},
		fieldType: String,
		labels: {
			type: Array as ()=>(string | TranslateResult)[],
		}
	},
	computed: {
		hasOneValue():boolean {
			return this.filter.getValueAmount() == 1;
		},
		hasTwoValues():boolean {
			return this.filter.getValueAmount() == 2;
		},
		hasMultipleValues():boolean {
			return this.filter.getValueAmount() == -1;
		},
		hasNoValues():boolean {
			return this.filter.getValueAmount() == 0;
		},
	},
	methods: {
		getLabel(i:number):string | TranslateResult{
			if (this.labels) {
				return this.labels[i];
			}
			if (this.hasOneValue) {
				return this.$ct("single-value");
			}
			if (this.hasTwoValues) {
				return this.$ct("value", {val: i+1});
			}
			return this.$ct("values");
		}
	},
	mounted(){
		this.ready = true;
	},
	components: {
		AppHumanFilterEditValueCustomInput
	},
	i18nEx: {
		componentPrefix: "components.models"
	}
});
