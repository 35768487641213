
import Vue from "vue";
import { HumanFilter } from "@/utils/human-filter";
import { SaleType } from "@/models/base";
import { ISelectItem } from "winfakt-vue-components";
import { SaleService } from "@/services/sale-service";

export default Vue.extend({
	data(){
		return {
			saleTypes: [] as SaleType[],
			selectedSaleTypes: [] as Number[],
		};
	},
	props: {
		value: {
			type: Object as ()=>HumanFilter,
			required: true
		},
		ignoredTypeIds: {
			type: Array as () => number[],
			default: () => []
		}
	},
	computed: {
		operator():string{
			if (this.value.Options.indexOf("not") == -1) {
				return "in";
			}
			return "not";
		},
		operators():ISelectItem<string>[]{
			return [
				{
					text: this.$ct("sale-type-in-list"),
					value: "in"
				},
				{
					text: this.$ct("sale-type-not-in-list"),
					value: "not-in"
				}
			];
		},
		label():string{
			return this.$ct("type-choose").toString();
		},
		items():ISelectItem<number>[] {
			return this.saleTypes.filter(t => this.ignoredTypeIds.indexOf(t.ID) == -1).map((l) => {
				return {
					text: l.Name.getTranslation(this.$i18n.locale),
					value: l.ID
				} as ISelectItem<number>;
			});
		},
	},
	methods: {
		onChange(typeID: number[]) {
			this.value.Values = typeID.map(String);
		},
		setOperator(value:string){
			let index = this.value.Options.indexOf("not");
			if (value == "in"){
				if (index == -1) return;
				this.value.Options.splice(index, 1);
			}else{
				if (index != -1) return;
				this.value.Options.push("not");
			}
		},
	},
	async mounted(){
		this.saleTypes = SaleService.getTypes();
		let ids = this.value.Values.map(v=>parseInt(v || "0")).filter(id=>!Number.isNaN(id) && id != 0);
		if (ids.length == 0) {
			this.value.Values = [];
			return;
		};
		this.selectedSaleTypes = this.value.Values.map(Number);
	},
	components: {

	},
	i18nEx: {
		componentPrefix: "components.sales",
	}
});
