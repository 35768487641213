import { ServerConfig } from "@/config/config";
import { LabelLayoutModel } from "@/models/label-layout/label-layout-model";
import { Axios } from "@/utils/axios";
import { IGetResponse } from "@/utils/get-response";
import { IPaginationData } from "@/utils/query-params";

export class LabelLayoutServiceClass {
	private readonly url:string = `${ServerConfig.host}/label-layouts`

	public async fetch():Promise<IGetResponse<LabelLayoutModel>>{
		let pagination:IPaginationData = {
			limit: 200,
			offset: 0,
			orderBy: "this.ID",
			orderDirection: "ASC"
		};
		let result = await Axios.get(this.url, {params: {
			limit: pagination.limit,
			offset: pagination.offset,
			order: pagination.orderBy,
			orderDirection: pagination.orderDirection
		}});
		return {
			data: (result.data || []).data.map((l:any)=>new LabelLayoutModel(l)),
			records: result.data.records
		};
	}

	public async getByBrowseId(browseId:number):Promise<LabelLayoutModel[]>{
		let result = await Axios.get(`${this.url}/by-browse/${browseId}`);
		return (result.data || []).map((l:any)=>new LabelLayoutModel(l));
	}

	public async getById(id:number):Promise<LabelLayoutModel>{
		let result = await Axios.get(`${this.url}/${id}`);
		return new LabelLayoutModel(result.data);
	}

	public async createLayout(layout:LabelLayoutModel):Promise<LabelLayoutModel>{
		let result = await Axios.post(this.url, layout.getJSON());
		return new LabelLayoutModel(result.data);
	}

	public async updateLayout(layout:LabelLayoutModel):Promise<LabelLayoutModel>{
		let result = await Axios.put(`${this.url}/${layout.ID}`, layout.getJSON());
		return new LabelLayoutModel(result.data);
	}

	public async deleteLayout(layout:LabelLayoutModel):Promise<void>{
		await Axios.delete(`${this.url}/${layout.ID}`);
	}
}

export const LabelLayoutService = new LabelLayoutServiceClass();