
import {Product} from "@/models/base";
import Vue from "vue";
import AppProductGroupSelectAutcomplete from "@/components/product-group/ProductGroupSelectAutocomplete.vue";
import {ProductGroupProduct} from "@/models/base/product-group-product";

export default Vue.extend({
	data() {
		return {
			value: null as null | number
		};
	},
	props: {
		product: {
			type: Object as () => Product,
			required: true
		},
		light: Boolean
	},
	watch: {
		"product.Groups": {
			immediate: true,
			deep: true,
			handler() {
				this.value = this.product.Groups.map(g => g.ProductGroupID)[0] || null;
			}
		},
	},
	methods: {
		onInputGroup(productGroupId: number | null) {
			if (productGroupId == null) {
				this.product.Groups = [];
				return;
			}
			let p = new ProductGroupProduct();
			p.ProductGroupID = productGroupId;
			this.product.Groups = [p];
			this.$emit("group",p.ProductGroupID);
		},
		onInputSubGroup(productGroupId: number | null) {
			if (productGroupId == null) {
				this.product.Groups = [];
				return;
			}
			let p = new ProductGroupProduct();
			p.ProductGroupID = productGroupId;
			this.product.Groups = [p];
			this.$emit("subgroup",p.ProductGroupID);
		}
	},
	components: {
		AppProductGroupSelectAutcomplete
	},
	i18nEx: {
		componentPrefix: "components.products"
	}
});
