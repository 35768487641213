import { Plan } from "@/utils/payments/plan";

export class Subscription {
	id: number;
	mandateId: string;
	amount: number;
	interval: string;
	nextPaymentDate: Date;
	plan:Plan;
	status:string;

	constructor(data: any) {
		this.id = data.id;
		this.mandateId = data.mandateId;
		this.amount = data.amount;
		this.interval = data.interval;
		this.nextPaymentDate = new Date(data.nextPaymentDate * 1000);
		this.plan = new Plan(data.plan);
		this.status = data.status;
	}

}
