import { GoTemplateDataProperty, PropType } from "@/utils/go-template-data-structure/property";

export class GoTemplateDataArray extends GoTemplateDataProperty {
	name:string = "";
	alias:string = "";
	indexAlias:string = "i";
	properties:GoTemplateDataProperty[] = [];
	type = "array" as PropType;

	constructor(name:string, alias:string, properties:GoTemplateDataProperty[], displayedName:string | {toString():string}) {
		super();
		this.name = name;
		this.alias = alias;
		this.properties = properties;
		this.displayedName = displayedName.toString();
		this.properties.forEach(p => {
			p.parent = this;
		});
	}

	getParentPath():string {
		if (!this.parent) {
			return "." + this.name;
		}
		return this.parent.getPath() + "." + this.name;
	}

	getPath():string {
		return "$" + this.alias;
	}
}