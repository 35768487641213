export enum AddToAutomaticOrderToSupplier {
	DISABLED = 0,
	ADD = 1,
	SUBTRACT = 2
}

export class StockLocationSettings {
	DisableOverwriteStockToNewBookYear:boolean = false;
	EnabledByDefaultForInventoryReport:boolean = false;
	AddToAutomaticOrderToSupplier:AddToAutomaticOrderToSupplier = AddToAutomaticOrderToSupplier.DISABLED;
	AddToTotalStock:boolean = false;

	public constructor(data?:any){
		if (!data) return;
		this.DisableOverwriteStockToNewBookYear = data.DisableOverwriteStockToNewBookYear || false;
		this.EnabledByDefaultForInventoryReport = data.EnabledByDefaultForInventoryReport || false;
		this.AddToAutomaticOrderToSupplier = data.AddToAutomaticOrderToSupplier || AddToAutomaticOrderToSupplier.DISABLED;
		this.AddToTotalStock = data.AddToTotalStock || false;
	}

	public getJSON(){
		return {
			DisableOverwriteStockToNewBookYear: this.DisableOverwriteStockToNewBookYear,
			EnabledByDefaultForInventoryReport: this.EnabledByDefaultForInventoryReport,
			AddToAutomaticOrderToSupplier: this.AddToAutomaticOrderToSupplier,
			AddToTotalStock: this.AddToTotalStock,
		};
	}
}