
import Vue from "vue";
import { ISelectItem } from "winfakt-vue-components";
import AppProductGroupSelect from "@/components/product-group/ProductGroupSelect.vue";
import { HumanFilter } from "@/utils/human-filter";

export default Vue.extend({
	props: {
		value: {
			type: Object as ()=>HumanFilter,
			required: true
		}
	},
	computed: {
		operator():string{
			if (this.value.Options.indexOf("not") == -1) {
				return "in";
			}
			return "not";
		},
		operators():ISelectItem<string>[]{
			return [
				{
					text: this.$ct("in-groups"),
					value: "in"
				},
				{
					text: this.$ct("not-in-groups"),
					value: "not in"
				}
			];
		},
		values():number[]{
			return this.value.Values.map(v=>parseInt(v || "0"));
		}
	},
	methods: {
		setOperator(value:string){
			let index = this.value.Options.indexOf("not");
			if (value == "in"){
				if (index == -1) return;
				this.value.Options.splice(index, 1);
			}else{
				if (index != -1) return;
				this.value.Options.push("not in");
			}
		},
		onInput(val:number[]){
			this.value.Values = val.map(v=>`${v}`);
		}
	},
	components: {
		AppProductGroupSelect
	},
	i18nEx: {
		componentPrefix: "components.products",
	}
});
