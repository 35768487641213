import { i18n } from "@/setup/i18n-setup";
import { field, hidden, computed, canBeFilteredNummerically } from "@/utils/models/decorator-field";
import { model } from "@/utils/models/decorator-model";

export enum LedgerAccountVatField{
	NONE = 0,
	AANKOPEN_HANDELSGOEDEREN = 81,
	DIENSTEN_OF_KOSTEN = 82,
	BEDRIJFSMIDDELEN = 83
}

export enum LedgerAccountBalans {
	ACTIVE = 0,
	PASSIVE = 1,
	OPBRENGSTEN = 2,
	COSTS = 3
}

export enum LedgerAccountType {
	NONE = 0,
	CLIENT = 1,
	SUPPLIER = 2
}

@model("LedgerAccount")
export class LedgerAccount {
	@field("string")
	@canBeFilteredNummerically()
	Code:string = "";
	@field("string")
	Description:string = "";
	@field("number")
	@hidden()
	Balans:LedgerAccountBalans = LedgerAccountBalans.ACTIVE;
	@field("string")
	@hidden()
	GroupCode:string = "";
	@field("string")
	GroupName:string = "";
	@field("number")
	@hidden()
	VatField:LedgerAccountVatField = LedgerAccountVatField.NONE;
	@field("number")
	@hidden()
	Type:LedgerAccountType = LedgerAccountType.NONE;

	@field("string")
	@computed()
	public get isActiveOrPassiveString():{toString():string} {
		if (this.Balans == LedgerAccountBalans.PASSIVE) {
			return i18n.t("models.LedgerAccount.passive");
		}else if (this.Balans == LedgerAccountBalans.ACTIVE){
			return i18n.t("models.LedgerAccount.active");
		}else if (this.Balans == LedgerAccountBalans.OPBRENGSTEN){
			return i18n.t("models.LedgerAccount.opbrengsten");
		}
		return i18n.t("models.LedgerAccount.costs");

	}


	@field("string")
	@computed()
	public get groupCodeShort():string {
		return this.GroupCode.replaceAll(".", "");
	}


	public constructor(data?:any){
		if (!data) return;
		this.Code = data.Code;
		this.Description = data.Description;
		this.Balans = data.Balans;
		this.GroupCode = data.GroupCode;
		this.GroupName = data.GroupName;
		this.VatField = data.VatField || 0;
		this.Type = data.Type;
	}

	public getJSON():any{
		return {
			Code: this.Code,
			Description: this.Description,
			Balans: this.Balans,
			GroupCode: this.GroupCode,
			GroupName: this.GroupName,
			VatField: this.VatField,
			Type: this.Type
		};
	}

	public isActive():boolean {
		return this.Balans == LedgerAccountBalans.ACTIVE;
	}

	public isGroup():boolean {
		return this.Code.endsWith(".");
	}

	private static compareCodeCharacter(charA:string, charB:string):number {
		if (charA == ".") {
			return -1;
		}
		if (charB == ".") {
			return 1;
		}
		return parseInt(charA) - parseInt(charB);
	}

	public static sortLedgerAccounts(accounts:LedgerAccount[]):LedgerAccount[] {
		return accounts.sort((a,b)=>{
			for (let i = 0; i < 6; i++) {
				let result = this.compareCodeCharacter(a.Code[i], b.Code[i]);
				if (result != 0) {
					return result;
				}
			}
			return 0;
		});
	}

	public static isValidCode(code:string):boolean {
		return /(^[1-9](([0-9\.])(?!\.[0-9])){5}$)/.test(code);
	}

	public toString():string {
		return `${this.Code} - ${this.Description}`;
	}
}