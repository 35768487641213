import { v4 } from "uuid";
import { Model } from "../models/model";
import { TextLine } from "./text-line";
import { BasicTextStyledProperty } from "../documents/basic-text-styled-property";
import { TextStyledProperty } from "../documents/text-styled-property";

export class TextBlock{
	key:string = v4();
	Name:string = "";
	Lines:TextLine[] = [];
	Width:number = 10;
	Height:number = 10;
	X:number = 0;
	Y:number = 0;

	public constructor(data?:any){
		if (!data) return;
		this.Lines = (data.Lines || []).map((l:any)=>new TextLine(l));
		this.Width = data.Width;
		this.Height = data.Height;
		this.X = data.X;
		this.Y = data.Y;
		this.Name = data.Name;
	}

	public getPreloads(model:Model):string[]{
		let result:string[] = [];
		for (let line of this.Lines) {
			result = result.concat(line.getPreloads(model));
		}
		return result;
	}

	public getName(model:Model):string{
		if (this.Name) return this.Name;
		if (this.Lines.length == 0) return "LEEGE BLOK";
		let text = this.Lines[0].getAsText(model);
		if (text) return text;
		return "LEEGE BLOK";
	}

	public getJSON(){
		return {
			Name: this.Name,
			Lines: this.Lines.map(l=>l.getJSON()),
			Width: this.Width,
			Height: this.Height,
			X: this.X,
			Y: this.Y
		};
	}

	private computeStyle():string{
		return `width:${this.Width}mm;height:${this.Height}mm;left:${this.X}mm;top:${this.Y}mm`;
	}

	public compute(record:any):string {
		let result = `<div class="block" style="${this.computeStyle()}"/>`;
		for (let line of this.Lines){
			result += line.compute(record);
		}
		result += "</div>";
		return result;
	}

	public applyStyleToAllLines(style:TextStyledProperty, preStyle:BasicTextStyledProperty, propStyle:BasicTextStyledProperty, postStyle:BasicTextStyledProperty){
		let json = style.getJSON();
		let preJson = preStyle.getJSON();
		let propJson = propStyle.getJSON();
		let postJson = postStyle.getJSON();
		for (let line of this.Lines) {
			line.Style = new TextStyledProperty(json);
			line.PreTextStyle = new BasicTextStyledProperty(preJson);
			line.PropStyle = new BasicTextStyledProperty(propJson);
			line.PostTextStyle = new BasicTextStyledProperty(postJson);
		}
	}
}