
import { StockLocationConfig } from "@/models/dossier-configs/stock-location";
import { DossierConfigService } from "@/services/dossier-config-service";
import Vue from "vue";
import { ISelectItem } from "winfakt-vue-components";
import { AuthService } from "@/services/auth-service";
import { MODULE } from "@/config/modules";

export default Vue.extend({
	props: {
		value: [Number, Array as ()=>number[]],
		label: [Object, String],
		disabled: Boolean,
		multi: Boolean,
		includeEmpty: Boolean
	},
	computed: {
		items(): ISelectItem<number>[] {
			let result:ISelectItem<number>[] = [];
			if (this.includeEmpty) {
				result.push({
					text: this.$ct("common.empty"),
					value: 0
				});
			}

			if(!AuthService.hasModule(MODULE.MULTI_VOORRAAD)){
				return result.concat([{
					text: this.stockLocationConfig.getSortedLocationNames()[0],
					value: 1
				} as ISelectItem<number>]);
			}

			return result.concat(this.stockLocationConfig.getSortedLocationNames().map((l:string, i:number) => {
				return {
					text: l,
					value: i + 1
				} as ISelectItem<number>;
			}));
		},
		compLabel(): string {
			if (this.label) {
				if (typeof(this.label) == "string") {
					return this.label;
				}
				return this.label.toString();
			}
			return this.$ct("label").toString();
		},
		stockLocationConfig():StockLocationConfig {
			return DossierConfigService.getStockLocationConfig();
		}
	},
	methods: {
		onChange(locationID: number | number[]) {
			this.$emit("input", locationID);
		},
		focus() {
			(this.$refs.select as any).focus();
		},
		blur() {
			(this.$refs.select as any).blur();
		},
		onKeyUp(event:KeyboardEvent) {
			this.$emit("keyup", event);
		}
	},
	async mounted() {
		if (this.multi){
			let value = this.value as number[];
			for (let i = value.length-1; i>=0;i--){
				if (!this.stockLocationConfig.isValidLocationID(value[i])) {
					value.splice(i, 1);
				}
			}
		}else  {
			if (this.includeEmpty && this.value == 0){
				return;
			}
			if (!this.stockLocationConfig.isValidLocationID(this.value as number)){
				this.$emit("input", 1);
			}
		}
	},
	i18nEx: {
		componentPrefix: "components.StockLocationSelect"
	}
});
