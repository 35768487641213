export class BillingAddress {
	public firstName:string = "";
	public lastName:string = "";
	public companyName:string = "";
	public vatNumber:string = "";
	public address:string = "";
	public place:string = "";
	public postal:string = "";
	public country:string = "BE";
	public tel:string = "";
	public email:string = "";

	constructor(data?:any){
		if (!data) return;
		this.firstName = data.firstName;
		this.lastName = data.lastName;
		this.companyName = data.companyName;
		this.vatNumber = data.vatNumber;
		this.address = data.address;
		this.place = data.place;
		this.postal = data.postal;
		this.country = data.country;
		this.tel = data.tel;
		this.email = data.email;
	}

	getJSON(){
		return {
			firstName: this.firstName,
			lastName: this.lastName,
			companyName: this.companyName,
			vatNumber: this.vatNumber,
			address: this.address,
			place: this.place,
			postal: this.postal,
			country: this.country,
			tel: this.tel,
			email: this.email,
		};
	}
}