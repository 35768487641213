
import { BorderStyle, StyledProperty } from "@/utils/documents/styled-property";
import Vue from "vue";
import { ISelectItem } from "winfakt-vue-components";
import AppColorPicker from "@/components/ColorPicker.vue";
import { TranslateResult } from "vue-i18n";
export default Vue.extend({
	data(){
		return {
			toggleGroup: false,
			allBorders: true
		};
	},
	props: {
		toEditStyle: {
			type: Object as ()=>StyledProperty,
			required: true
		}
	},
	computed: {
		borderStyleItems():ISelectItem<BorderStyle>[]{
			return [
				{
					text: this.$ct("border-style-none"),
					value: BorderStyle.NONE
				},
				{
					text: this.$ct("border-style-solid"),
					value: BorderStyle.SOLID
				},
				{
					text: this.$ct("border-style-hidden"),
					value: BorderStyle.HIDDEN
				},
				{
					text: this.$ct("border-style-dotted"),
					value: BorderStyle.DOTTED
				},
				{
					text: this.$ct("border-style-dashed"),
					value: BorderStyle.DASHED
				}
			];
		},
		borderText():string | TranslateResult{
			if (this.toEditStyle.BorderStyle == BorderStyle.NONE) {
				return this.$ct("common.none");
			}
			let borderStyle = (this.borderStyleItems.find(b=>b.value == this.toEditStyle.BorderStyle) || {text: this.$ct("common.none")}).text;
			if (this.allBorders) {
				return this.$ct("border-text-single-width", {borderStyle, width: this.toEditStyle.BorderWidth, color: this.toEditStyle.BorderColor});
			}
			return this.$ct("border-text-multi-width", {borderStyle, color: this.toEditStyle.BorderColor});
		}
	},
	watch: {
		"allBorders"(){
			if(!this.allBorders){
				this.toEditStyle.BorderWidth = 0;
			}
		},
		"toEditStyle.BorderWidth"(){
			if(this.allBorders){
				this.toEditStyle.BorderTopWidth = this.toEditStyle.BorderWidth;
				this.toEditStyle.BorderRightWidth = this.toEditStyle.BorderWidth;
				this.toEditStyle.BorderBottomWidth = this.toEditStyle.BorderWidth;
				this.toEditStyle.BorderLeftWidth = this.toEditStyle.BorderWidth;
			}
		}
	},
	mounted() {
		this.allBorders = !this.toEditStyle.hasMultipleBorderWidths();
	},
	components: {
		AppColorPicker
	},
	i18nEx: {
		componentPrefix: "components.layout-editor"
	}
});
