import Vue from "vue";
import { ServerConfig } from "@/config/config";
import { Axios } from "@/utils/axios";
import { Roadmap } from "@/models/roadmap";

export const RoadmapService = new Vue({
	computed: {},
	methods: {
		async get() {
			let result = await Axios.get(ServerConfig.auth + "/roadmap/changelog/WINFAKT_MAIN_APP", {
				params: {}
			});
			return result.data.map((r: any) => new Roadmap(r));
		},
		async getItem(id: number) {
			let result = await Axios.get(ServerConfig.auth + "/roadmap/" + id, {
				params: {}
			});
			return new Roadmap(result.data);
		}
	}
});