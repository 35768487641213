export class GridSettings {
	CellWidth:number = 10;
	CellHeight:number = 10;

	public constructor(data?:any){
		if (!data) return;
		this.CellWidth = data.CellWidth || 10;
		this.CellHeight = data.CellHeight || 10;
	}

	public getJSON(){
		return {
			CellWidth: this.CellWidth,
			CellHeight: this.CellHeight
		};
	}
}