
export class FriendlyNumberConfig {
	StartingNumber = 1;
	Prefix = "";
	NumberLength = 6;
	// Unused if 0
	EndNumber:number = 0;


	constructor(data?: any) {
		if (!data) return;
		this.StartingNumber = data.StartingNumber;
		this.Prefix = data.Prefix;
		this.NumberLength = data.NumberLength;
		this.EndNumber = data.EndNumber;
	}

	getJSON() {
		return {
			StartingNumber: this.StartingNumber,
			Prefix: this.Prefix,
			NumberLength: this.NumberLength,
			EndNumber: this.EndNumber
		};
	}
}
