export class DataSourcesConfig {
	ContactsDossierID:number = 0;
	ProductsDossierID:number = 0;

	public constructor(data?:any) {
		if (!data) return;
		this.ContactsDossierID = data.ContactsDossierID;
		this.ProductsDossierID = data.ProductsDossierID;
	}

	public getJSON(){
		return {
			ContactsDossierID: this.ContactsDossierID,
			ProductsDossierID: this.ProductsDossierID,
		};
	}
}