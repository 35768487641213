import { i18n } from "@/setup/i18n-setup";
import { TranslateResult } from "vue-i18n";
import { Model } from "./model";
import { IModel } from "./model-interface";
import { NamedFilter } from "./named-filter";

export class ContactAddressNamedFilter extends NamedFilter {
	public fieldName:string = "";
	public getFilterEditComponentName(model: Model): string {
		return "contact-address-field";
	}

	public getDisplayName(model:IModel):TranslateResult {
		return i18n.t(`models.friendlyNames.Contact.namedFilterHasAddressWithField`, {field: i18n.t(`models.friendlyNames.ContactAddress.${this.fieldName}`)});
	}
}