import { v4 } from "uuid";
import { IEventSubscription } from "../event-subscription";
import { tokenStore } from "../token-store";

export interface IBroadcastToDossierData {
	Event:string;
	EventID:string;
	Payload:any;
}

export interface IBroadcastFromClientData {
	EventID:string;
	SocketID:string;
	UserID:number;
	Payload:any;
}

export interface IOnClientDisconnectData {
	UserID:number;
	SocketID:string;
}

export abstract class SocketServiceBase {
	protected host:string;
	protected path:string;
	protected subs:IEventSubscription[] = [];
	private _isConnected:boolean = false;
	private setIsConnectedUid:string = "";
	private static readonly SET_IS_CONNECTED_TIMEOUT = 30000;

	private static readonly MAX_LOG_SIZE = 5000;
	private static logs:string[] = [];

	protected setIsConnected(val:boolean){
		let uid = v4();
		this.setIsConnectedUid = uid;
		if (val) {
			this._isConnected = true;
			return;
		}
		// Wait some time before showing disconnected error in case of a network hickup
		setTimeout(()=>{
			if (this.setIsConnectedUid != uid) return;
			this._isConnected = false;
		}, SocketServiceBase.SET_IS_CONNECTED_TIMEOUT);
	}

	public constructor(host:string, path:string){
		this.host = host;
		this.path = path;
	}


	on(event: string, handler: Function, ref?: any): void {
		this.addSub({event, handler, ref, once: false});
	}
	once(event: string, handler: Function, ref?: any): void {
		this.addSub({event, handler, ref, once: true});
	}
	off(event: string, handler: Function, ref?: any): void {
		this.removeSub({event, handler, ref, once: false});
	}
	offAll(ref: any): void {
		for (let i = this.subs.length-1; i >= 0; i--){
			let s = this.subs[i];
			if (s.ref == ref){
				this.subs.splice(i, 1);
			}
		}
	}

	protected log(event:string){
		SocketServiceBase.logs.push(`${new Date().toJSON()} ${this.host}: ${event}`);
		while (SocketServiceBase.logs.length > SocketServiceBase.MAX_LOG_SIZE) {
			SocketServiceBase.logs.shift();
		}
	}

	public static async downloadLog(){
		let AuthService =  (await import("@/services/auth-service")).AuthService;
		let time = await AuthService.getServerTime();
		let currentTime = new Date();
		let logs = this.logs.join("\n");
		logs = `SystemTime: ${currentTime.toJSON()}\nServerTime: ${time.toJSON()}\n${logs}`;
		let el = document.createElement("a");
		el.setAttribute("href", "data:text/plain;charset=utf-8,"+encodeURIComponent(logs));
		el.setAttribute("download", "socket-log.txt");
		el.style.display = "none";
		document.body.appendChild(el);
		el.click();
		el.remove();
	}

	protected addSub(sub:IEventSubscription){
		this.subs.push(sub);
	}

	protected removeSub(sub:IEventSubscription){
		for (let i = this.subs.length-1; i >= 0; i--){
			let s = this.subs[i];
			if (s.event == sub.event && s.handler == sub.handler && s.ref == sub.ref){
				this.subs.splice(i, 1);
			}
		}
	}

	protected triggerEvent(event:string, ...data:any){
		for (let i = this.subs.length - 1; i >= 0; i--){
			let sub = this.subs[i];
			if (!sub) return;
			if (sub.event != event) continue;
			if (sub.once){
				this.subs.splice(i, 1);
			}
			sub.handler(...data);
		}
	}

	broadcastToTabs(...data:any[]){
		return;
	}

	// Used by extra button scripts
	public getId():string{
		return tokenStore.socketId;
	}

	abstract emit(event:string, ...data:any):Promise<any>;
	abstract connect(dossierId:number):Promise<void>;
	abstract disconnect():Promise<void>;
	abstract updateQuery():void;

	public isConnected():boolean {
		return this._isConnected;
	}
}

window.addEventListener("keyup", (event)=>{
	if (!event.ctrlKey) return;
	if (!event.shiftKey) return;
	if (event.key != "F7") return;
	SocketServiceBase.downloadLog();
});