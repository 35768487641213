import { MODULE } from "@/config/modules";
import { AuthService } from "@/services";

export class CarpassConfig {
	Username:string = "";
	Password:string = "";
	KboNumber:string = "";

	public constructor(data?:any){
		if (!data) return;
		this.Username = data.Username;
		this.Password = data.Password;
		this.KboNumber = data.KboNumber || "";
	}

	public getJSON(){
		return {
			Username: this.Username,
			Password: this.Password
		};
	}

	public isConfigured():boolean{
		if (!AuthService.hasModule(MODULE.CARPASS)) {
			return false;
		}
		return this.Username != "" && this.Password != "" && this.KboNumber.trim() != "";
	}
}