import { ServerConfig } from "@/config/config";
import { ClosedPeriod } from "@/models/base/closed-period";
import { Axios } from "@/utils/axios";
import { BookYearService } from "./book-year-service";

export class ClosedPeriodServiceClass {
	public readonly url:string = `${ServerConfig.host}/closed-periods`
	private ClosedPeriods:ClosedPeriod[] = [];

	public constructor(){
		BookYearService.on("bookyear-changed", this.fetchClosedPeriods.bind(this));
	}

	public getClosedPeriods():ClosedPeriod[]{
		return this.ClosedPeriods;
	}

	public async fetchClosedPeriods():Promise<ClosedPeriod[]>{
		let result = await Axios.get(`${this.url}/by-bookyear/${BookYearService.selectedBookYear}`);
		this.ClosedPeriods.splice(0,this.ClosedPeriods.length);
		this.ClosedPeriods.push(...((result.data || []) as any[]).map(c=>new ClosedPeriod(c)));		return this.ClosedPeriods;
	}

	public async createClosedPeriod(period:ClosedPeriod):Promise<ClosedPeriod>{
		let result = await Axios.post(`${this.url}`, period.getJSON());
		await this.fetchClosedPeriods();
		return new ClosedPeriod(result.data);
	}

	public async deleteClosedPeriod(period:ClosedPeriod):Promise<void>{
		await Axios.delete(`${this.url}/${period.ID}`);
		await this.fetchClosedPeriods();
	}

	async init(){
		await this.fetchClosedPeriods();
	}
}

export const ClosedPeriodService = new ClosedPeriodServiceClass();