
import { Contact } from "@/models/base";
import { ContactAddress } from "@/models/base";
import Vue from "vue";
import { ISelectItem } from "winfakt-vue-components";
import AppAddress from "./ContactEditAddress.vue";

export default Vue.extend({
	data() {
		return {
			activeAddressTab: 0,
		};
	},
	props: {
		contact: {type: Object as () => Contact},
	},
	computed: {
		buttonBarItems():ISelectItem<string>[] {
			return [
				{text: "Maak hoofdadres", value: "switchMainAddress"},
				{text: "Verwijder", value: "removeAddress"}
			];
		},
		addressTabs(): any {
			return this.contact.Addresses.map((k: ContactAddress, v: number) => {
				if (k.Label.trim()){
					return k.Label;
				}
				return this.$ct("titles.addres") + " " + (1 + v);
			});
		},
		activeAddress(): ContactAddress {
			return this.contact.Addresses[this.activeAddressTab];
		},
		mainAddressSelected():boolean {
			return this.activeAddress.IsMainAddress;
		}
	},
	methods: {
		addContact() {
			let addr = new ContactAddress();
			addr.ContactID = this.contact.ID;
			this.contact.Addresses.push(addr);
			this.activeAddressTab = (this.contact.Addresses.length - 1);
		},
		switchMainAddress() {
			this.contact.Addresses.forEach(a => a.IsMainAddress = false);
			this.contact.MainAddress = this.activeAddress;
			this.contact.MainAddress.IsMainAddress = true;
		},
		removeAddress() {
			if (this.activeAddress.IsMainAddress)
				return false;
			this.contact.Addresses.splice(this.activeAddressTab, 1);
			this.activeAddressTab = 0;
		},
		trigger(value: string) {
			(this as any)[value]();
		},
		createNewMainAddress() {
			this.addContact();
		},
		selectPreviousTab(){
			if (this.activeAddressTab == 0) {
				this.activeAddressTab = this.contact.Addresses.length -1;
			}else{
				this.activeAddressTab--;
			}
		},
		selectNextTab(){
			if (this.activeAddressTab == this.contact.Addresses.length-1) {
				this.activeAddressTab = 0;
			}else{
				this.activeAddressTab++;
			}
		},
		onKeyDown(event:KeyboardEvent){
			if (!event.altKey) return;
			if (event.ctrlKey) return;
			if (event.shiftKey) return;
			if (event.key == "ArrowLeft") {
				event.preventDefault();
				event.stopPropagation();
				this.selectPreviousTab();
			} else if (event.key == "ArrowRight"){
				event.preventDefault();
				event.stopPropagation();
				this.selectNextTab();
			}
		}
	},
	created() {
		window.addEventListener("keydown", this.onKeyDown);
	},
	destroyed(){
		window.removeEventListener("keydown", this.onKeyDown);
	},
	components: {
		AppAddress
	},
	i18nEx: {
		componentPrefix: "components.contacts"
	}
});
