import { TranslateResult } from "vue-i18n";
import { PropSelectItem } from "./prop-select-item";

export class PropSelectGroup {
	name:string | TranslateResult;
	path:string;
	items:PropSelectItem[] = [];
	groups:PropSelectGroup[] = [];
	modelName:string;

	public constructor(name:string | TranslateResult, path:string, modelName:string = ""){
		this.name = name;
		this.path = path;
		this.modelName = modelName;
	}

	getPropSelectFromValue(value:string):PropSelectItem|null{
		for (let item of this.items){
			if (item.value == value){
				return item;
			}
		}

		for (let group of this.groups){
			let result = group.getPropSelectFromValue(value);
			if (result){
				return result;
			}
		}

		return null;
	}

	// Returns the first item in the list
	// Returns false if both items and groups are empty
	getFirstItem(filter:string = ""):string | boolean{
		for (let item of this.items){
			if (item.value.toLowerCase().indexOf(filter) != -1){
				return item.value;
			}
		}

		for (let group of this.groups){
			let value = group.getFirstItem(filter);
			if (value !== false){
				return value;
			}
		}

		return false;
	}

	// Returns the last item in the list
	// Returns false if both items and groups are empty
	getLastItem(filter:string = ""):string | boolean {
		let groups = [...this.groups].reverse();
		for (let group of groups){
			let item = group.getLastItem(filter);
			if (item !== false) {
				return item;
			}
		}

		let items = [...this.items].reverse();
		for (let item of items){
			if (item.value.indexOf(filter) != -1){
				return item.value;
			}
		}
		return false;
	}

	// Returns the next value in the list
	// Returns false if end is reached without coming across the provided value
	// Returns true if value is the last item in this list
	getNext(value:string, filter:string = ""):string | boolean {
		let isNext = false;
		for (let item of this.items){
			if (isNext && item.value.toLowerCase().indexOf(filter) != -1){
				return item.value;
			}

			if (item.value == value){
				isNext = true;
			}
		}

		for (let group of this.groups){
			if (isNext){
				let result = group.getFirstItem(filter);
				if (result !== false) {
					return result;
				}
			}

			let result = group.getNext(value, filter);
			if (result === true){
				isNext = true;
			}else if (typeof(result) == "string"){
				return result;
			}
		}

		if (isNext){
			return true;
		}
		return false;
	}

	// Returns the previous value in the list
	// Returns false if end is reached without coming across the provided value
	// Returns true if value is the last item in this list
	getPrevious(value:string, filter:string = ""):string | boolean {
		let isNext = false;
		let groups = [...this.groups].reverse();
		for (let group of groups){
			if (isNext){
				let result = group.getLastItem(filter);
				if (result !== false){
					return result;
				}
			}

			let result = group.getPrevious(value, filter);
			if (result === true){
				isNext = true;
			}else if (typeof(result) == "string"){
				return result;
			}
		}

		let items = [...this.items].reverse();
		for (let item of items){
			if (isNext && item.value.toLowerCase().indexOf(filter) != -1){
				return item.value;
			}

			if (item.value == value){
				isNext = true;
			}
		}

		if (isNext){
			return true;
		}
		return false;
	}

	flattenList():PropSelectItem[] {
		let result = [...this.items] as PropSelectItem[];
		for (let group of this.groups){
			result.push(...group.flattenList());
		}
		return result;
	}
}