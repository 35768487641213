import { AwsDocument } from "./aws-document";

export class ContactAwsDocument extends AwsDocument {
	ContactID:number = 0;

	public constructor(data?:any){
		super(data);
		if (!data) return;
		this.ContactID = data.ContactID;
	}
}