
import { SellCategories } from "@/models/dossier-configs/sell-categories";
import { DossierConfigService } from "@/services/dossier-config-service";
import Vue from "vue";
import { ISelectItem } from "winfakt-vue-components";
import { SellCategory } from "../utils/sell-category";

export default Vue.extend({
	props: {
		value: Number,
		fancy: Boolean,
		light: Boolean,
		label: String,
		up: Boolean,
		selectWidth: String,
		activeOnly: Boolean,
		disabled: Boolean
	},
	computed: {
		categories():SellCategories {
			return DossierConfigService.getSellCategories();
		},
		items(): ISelectItem<number>[] {
			if (this.activeOnly) {
				return this.categories.getActiveCategories().map(s => {
					return {
						text: this.getCategoryName(s.settings, s.id),
						value: s.id
					} as ISelectItem<number>;
				});
			}
			return this.categories.getCategories().map((s, i) => {
				return {
					text: this.getCategoryName(s, i + 1),
					value: i + 1
				} as ISelectItem<number>;
			});

		},
		computedLabel(): string {
			return this.label || this.$ct("label").toString();
		},
	},
	methods: {
		onInput(newValue:number) {
			if (newValue == this.value) return;
			this.$emit("input", newValue);
		},
		getCategoryName(cat:SellCategory, id:number):string {
			return cat.Name || this.$ct("category", {num: id}).toString();
		}
	},
	i18nEx: {
		componentPrefix: "components.SellCategorySelect"
	}
});
