
import { PropSelectGroup } from "@/utils/prop-select/prop-select-group";
import { PropSelectItem } from "@/utils/prop-select/prop-select-item";
import Vue from "vue";
import AppPropSelectGroup from "./PropSelectGroup.vue";

export default Vue.extend({
	data(){
		return {
			isFocused: false,
			input: "",
			hoveredValue: ""
		};
	},
	props: {
		items: {
			type: Object as ()=> PropSelectGroup,
			required: true
		},
		value: {
			type: String,
		},
		label: {
			type: String,
		},
		up: Boolean,
		fancy: Boolean,
		allowEmpty: Boolean,
		icon: String
	},
	computed: {
		_iconClickHandler():Function | undefined{
			return this.$listeners["icon-click"] as Function || undefined;
		},
		selectedProp():PropSelectItem | null {
			if (this.value == "" && this.allowEmpty){
				return new PropSelectItem(this.$ct("common.empty"), "");
			}
			let find = (group:PropSelectGroup):PropSelectItem | null=>{
				for (let item of group.items){
					if (item.value == this.value){
						return item;
					}
				}
				for (let g of group.groups){
					let result = find(g);
					if (result){
						return result;
					}
				}
				return null;
			};
			return find(this.items);
		}
	},
	watch: {
		value: {
			immediate: true,
			async handler(){
				this.input = "";
				if (this.selectedProp){
					this.input = this.selectedProp.text.toString();
				}
			}
		}
	},
	methods: {
		onFocus(){
			this.input = "";
			this.hoveredValue = this.value;
			if (this.hoveredValue == "" && !this.allowEmpty){
				let result = this.items.getFirstItem(this.input);
				if (typeof(result) == "string"){
					this.hoveredValue = result;
				}
			}
			this.isFocused = true;
		},
		doBlur(){
			this.isFocused = false;
			this.input = "";
			if (this.selectedProp) {
				this.input = this.selectedProp.text.toString();
			}
		},
		onBlur(){
			setTimeout(()=>{
				let focused = document.activeElement;
				if (!focused) {
					return this.doBlur();
				}
				while (focused.parentElement != null) {
					if (focused.parentElement == this.$el){
						this.isFocused = true;
						return;
					}
					focused = focused.parentElement;
				}

				this.doBlur();
			}, 100);
		},
		onInput(value:string){
			this.input = value;
		},
		getMaxHeight():number{
			let rect = this.$el.getBoundingClientRect();
			if (this.up){
				let result = rect.top - 5;
				if (result > 400) {
					result = 400;
				}
				return result;
			}
			let result = window.innerHeight - rect.bottom - 5;
			if (result > 400) {
				result = 400;
			}
			return result;
		},
		async onClick(value:string){
			// Next tick fixes an issue where the view doesn't get udpated because the watcher doesn't get triggered.
			if (value == ""){
				this.$emit("input", "a");
				await this.$nextTick();
				this.$emit("input", "");
				return;
			}
			this.$emit("input", "");
			await this.$nextTick();
			this.$emit("input", value);
		},
		onKeyUp(event:KeyboardEvent){
			if (event.key == "ArrowUp"){
				let result = this.items.getPrevious(this.hoveredValue, this.input.toLowerCase());
				if (typeof(result) == "string"){
					this.hoveredValue = result;
				}else if (result === true){
					if (this.allowEmpty){
						this.hoveredValue = "";
					}
				}
			}else if (event.key == "ArrowDown"){
				if (this.hoveredValue == "" && this.allowEmpty){
					let result = this.items.getFirstItem(this.input.toLowerCase());
					if (typeof(result) == "string"){
						this.hoveredValue = result;
					}
				}else{
					let result = this.items.getNext(this.hoveredValue, this.input.toLowerCase());
					if (typeof(result) == "string"){
						this.hoveredValue = result;
					}
				}
			}
		},
		onEnter(){
			this.onClick(this.hoveredValue);
		}
	},
	components: {
		AppPropSelectGroup
	}
});
