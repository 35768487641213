import { Condition } from "@/utils/condition/condition";

export enum SaleStockConfigQtyComesFromField {
	QTY = 0,
	DELIVERED = 1,
	BACKORDER = 2
}

export class SaleConfigStock {
	Filter:Condition[] = [];
	StockLocation: number = 0;
	Qty: number = 1;
	QtyComesFromField: SaleStockConfigQtyComesFromField = SaleStockConfigQtyComesFromField.QTY;

	constructor(data?: any) {
		if (!data) return;
		if (data.Filter != null) {
			if (typeof(data.Filter.map) != "function"){
				data.Filter = [];
			}
		}
		this.Filter = (data.Filter || []).map((c:any)=>new Condition(c));
		this.StockLocation = data.StockLocation;
		this.Qty = data.Qty;
		this.QtyComesFromField = data.QtyComesFromField || SaleStockConfigQtyComesFromField.QTY;
	}

	getJSON() {
		return {
			Filter: this.Filter.map(f=>f.getJSON()),
			StockLocation: this.StockLocation,
			Qty: this.Qty,
			QtyComesFromField: this.QtyComesFromField
		};
	}
}