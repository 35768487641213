import { CustomTranslationWithoutFallback } from "./custom-translation-without-fallback";

export class CustomTranslation extends CustomTranslationWithoutFallback {
	public Fallback: string = "";

	constructor(data?: any) {
		super(data);
		if (!data) {
			return;
		}
		this.Fallback = data.Fallback;
	}

	public getTranslation(lang: string) {
		return this.Translations[lang] || this.Fallback;
	}

	public getTranslationWithoutFallback(lang: string) {
		return this.Translations[lang] || "";
	}

	public setText(text:string) {
		super.setText(text);
		this.Fallback = text;
	}

	public getJSON(): any {
		let obj = super.getJSON();
		let length = Object.keys(this.Translations).length;
		if (length == 0) {
			this.Fallback = "";
		}
		return {
			...obj,
			Fallback: this.Fallback,
		};
	}

	public clone(): CustomTranslation {
		return new CustomTranslation(this.getJSON());
	}
}
