
import { ProductGroup } from "@/models/base/product-group";
import { ProductGroupService } from "@/services/product-group-service";
import Vue from "vue";

export default Vue.extend({
	data(){
		return {
			parentGroup: "",
			subGroup: "",
			topLevelGroups: [] as ProductGroup[],
			subLevelGroups: [] as ProductGroup[],
			creatingGroupsBusy: false,
			selectedGroup: null as ProductGroup | null,
			subLevelGroupParentId: 0
		};
	},
	props: {
		value: {
			type: [Object as ()=>ProductGroup, Number]
		},
		useId: Boolean,
		light: Boolean
	},
	computed: {
		filteredTopLevelGroups():ProductGroup[]{
			return this.topLevelGroups.filter(t=>t.Name.toLowerCase().indexOf(this.parentGroup.toLowerCase()) != -1);
		},
		filteredSubLevelGroups():ProductGroup[]{
			return this.subLevelGroups.filter(t=>t.Name.toLowerCase().indexOf(this.subGroup.toLowerCase()) != -1);
		},
		noParentGroup():boolean {
			if (this.value){
				return false;
			}
			return true;
		},
		selectedParentGroup():ProductGroup | null {
			if (!this.selectedGroup) {
				return null;
			}
			if (this.selectedGroup.ParentGroup){
				let id = this.selectedGroup.ParentGroup.ID;
				return this.topLevelGroups.find(p=>p.ID == id) || null;
			}
			return this.selectedGroup;
		}
	},
	watch: {
		value: {
			immediate: true,
			async handler(newVal:ProductGroup | number, prevVal:ProductGroup | number){
				if (!newVal) return;
				let newId = newVal;
				if (typeof(newVal) == "object") {
					newId = newVal.ID;
				}
				if (prevVal) {
					let prevId = prevVal;
					if (typeof(prevVal) == "object") {
						prevId = prevVal.ID;
					}
					if (prevId == newId) return;
				}
				await this.fetchSelectedGroup();
				this.fillValue();
			}
		},
		selectedParentGroup: {
			immediate: true,
			async handler(newVal:ProductGroup | null, prevVal:ProductGroup | null){
				if (!newVal) {
					this.subLevelGroups = [];
					return;
				}
				if (!prevVal || prevVal.ID != newVal.ID){
					this.subLevelGroups = await ProductGroupService.getChildrenOfGroup(newVal.ID);
				}
			}
		}
	},
	methods: {
		async fetchSelectedGroup(){
			if (this.value == null) {
				this.selectedGroup = null;
				return;
			}
			if (typeof(this.value) == "number"){
				if (this.value == 0) {
					this.selectedGroup = null;
					return;
				}
				this.selectedGroup = await ProductGroupService.getGroupByID(this.value);
				return;
			}
			this.selectedGroup = this.value;
			return null;
		},
		async emitGroup(value:ProductGroup){
			this.parentGroup = value.Name;
			this.emitInput(value);
			this.$emit("group", value.ID);
		},
		emitSubGroup(value:ProductGroup){
			this.subGroup = value.Name;
			this.emitInput(value);
			this.$emit("subgroup", value.ID);
		},
		emitInput(value:ProductGroup){
			this.$emit("input", this.useId ? value.ID : value);
		},
		async createSubGroup(groupName:string){
			if (this.creatingGroupsBusy) {
				return;
			}
			this.creatingGroupsBusy = true;
		},
		async createGroup(groupName:string){
			if (this.creatingGroupsBusy) {
				return;
			}
			this.creatingGroupsBusy = true;
			try{
				let message = this.$ct("this-group-does-not-exist-do-you-want-to-create-it", {name: this.parentGroup});
				let confirm = await this.$confirmBuilder().setMessage(message).setTitle(this.$ct("group-not-found")).show();
				if (!confirm) {
					this.parentGroup = "";
					this.subGroup = "";
					this.subLevelGroups = [];
					this.creatingGroupsBusy = false;
					return;
				};
				let group = new ProductGroup();
				group.Name = groupName;
				group = await ProductGroupService.addGroup(group);
				this.topLevelGroups = await ProductGroupService.getTopLevelGroups();
				this.emitInput(group);
			}catch(err){

			}
			this.creatingGroupsBusy = false;
		},
		fillValue(){
			if (!this.selectedGroup) {
				this.parentGroup = "";
				this.subGroup = "";
				this.subLevelGroups = [];
				return;
			}
			if (!this.selectedParentGroup) {
				return;
			}
			if (this.selectedGroup == this.selectedParentGroup) {
				this.parentGroup = this.selectedParentGroup.Name;
				this.subGroup = "";
			}else{
				this.parentGroup = this.selectedParentGroup.Name;
				this.subGroup = this.selectedGroup.Name;
			}
		},
		async onParentGroupBlur(){
			this.parentGroup = this.parentGroup.trim();
			if (this.parentGroup == ""){
				this.fillValue();
				return;
			}
			let foundGroup = this.topLevelGroups.find(g=>g.Name == this.parentGroup);
			if (!foundGroup) {
				await this.createGroup(this.parentGroup);
				return;
			}
			if (this.selectedParentGroup && this.selectedParentGroup.ID == foundGroup.ID) {
				return;
			}
			this.emitInput(foundGroup);
		},
		onParentGroupFocus(){
			this.parentGroup = "";
		},
		async onSubGroupBlur(){
			this.subGroup = this.subGroup.trim();
			if (this.subGroup == "" || !this.selectedParentGroup) {
				this.fillValue();
				return;
			}
			let foundGroup = this.subLevelGroups.find(g=>g.Name == this.subGroup);
			if (!foundGroup) {
				await this.createSubGroup(this.subGroup);
				return;
			}
			this.emitInput(foundGroup);
		},
		onSubGroupFocus(){
			this.subGroup = "";
		},
		clearGroup(){
			this.$emit("input", null);
		}
	},
	async created(){
		this.topLevelGroups = await ProductGroupService.getTopLevelGroups();
	},
	i18nEx: {
		componentPrefix: "components.products"
	}
});
