export class CashierSettings {
	ConnectedDisplay = "";
	DefaultStartCash = 0;

	constructor(data?:any) {
		if (!data) return;
		this.ConnectedDisplay = data.ConnectedDisplay;
		this.DefaultStartCash = data.DefaultStartCash;
	}

	getJSON() {
		return {
			ConnectedDisplay: this.ConnectedDisplay,
			DefaultStartCash: this.DefaultStartCash,
		};
	}
}