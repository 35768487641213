
import Vue from "vue";
import AppProductSkuAutocomplete from "@/components/products/ProductSkuAutocompleteBasic.vue";
import { TranslateResult } from "vue-i18n";
import { v4 } from "uuid";
import { ProductService } from "@/services/product-service";
import { Product } from "@/models/base";

export default Vue.extend({
	data(){
		return {
			products: [] as Product[],
			fetchThreadId: v4()
		};
	},
	props: {
		value: {
			type: [String, Array as ()=>string[]],
			required: true
		},
		multi: Boolean,
		label: {
			type: [Object as ()=>TranslateResult, String],
			default():TranslateResult{
				if (this.multi) return this.$ct("users");
				return this.$ct("user");
			}
		}
	},
	computed: {
		singleValue():Product | null {
			return this.products[0];
		},
		nummericValues():number[]{
			if (typeof(this.value) == "string"){
				let val = parseInt(this.value || "0");
				if (Number.isNaN(val)){
					val = 0;
				}
				if (val == 0){
					return [];
				}
				return [val];
			}
			return this.value.map(v=>{
				let val = parseInt(v || "0");
				if (Number.isNaN(val)){
					val = 0;
				}
				return val;
			}).filter(v=>v>0);
		}
	},
	watch: {
		value: {
			immediate: true,
			handler(){
				this.checkAndFetchProducts();
			}
		}
	},
	methods: {
		async checkAndFetchProducts(){
			let threadId = v4();
			this.fetchThreadId = threadId;
			let storedProducts = [...this.products];
			let notFoundProductIds = [];
			for (let val of this.nummericValues) {
				let product = storedProducts.find(f=>f.ID == val);
				if (!product){
					notFoundProductIds.push(val);
				}
			}

			if (notFoundProductIds.length > 0){
				let fetchedProducts = await ProductService.getProductsByIds(notFoundProductIds);
				storedProducts.push(...fetchedProducts);
			}
			if (this.fetchThreadId != threadId){
				return;
			}
			this.products = this.nummericValues.map(id=>storedProducts.find(s=>s.ID == id) || this.getErrorProduct());
		},
		getErrorProduct():Product{
			let c = new Product();
			c.Sku = "ERROR";
			return c;
		},
		setSingleValue(value:Product){
			this.$emit("input", `${value.ID}`);
		},
		addProduct(value:Product){
			let foundProduct = this.products.find(c=>c.ID == value.ID);
			if (foundProduct) return;
			this.products.push(value);
			let val = this.products.map(v=>`${v.ID}`).filter(v=>v != "0");
			this.$emit("input", val);
		},
		removeProduct(value:number){
			this.products.splice(value,1);
			let val = this.products.map(v=>`${v.ID}`).filter(v=>v != "0");
			this.$emit("input", val);
		}
	},
	components: {
		AppProductSkuAutocomplete
	},
	i18nEx: {
		componentPrefix: "components.models"
	}
});
