
import { BookYearService } from "@/services/book-year-service";
import { dateToSqlDateString } from "@/utils/strings";
import Vue from "vue";
import { TranslateResult } from "vue-i18n";
export default Vue.extend({
	data(){
		return {
			start: new Date(),
			end: new Date()
		};
	},
	props: {
		value: {
			type: String,
			required: true
		},
		label: String
	},
	computed: {
		startLabel():TranslateResult{
			return this.$ct("date-range-start", {label: this.label});
		},
		endLabel():TranslateResult{
			return this.$ct("date-range-end", {label: this.label});
		}
	},
	watch: {
		start(){
			this.setValues();
		},
		end(){
			this.setValues();
		}
	},
	methods: {
		setValues(){
			this.$emit("input", JSON.stringify({start: dateToSqlDateString(this.start), end: dateToSqlDateString(this.end)}));
		}
	},
	created(){
		let currentBookYear = BookYearService.selectedBookYear;
		this.start = BookYearService.getBookYearStartDate(currentBookYear);
		this.end = BookYearService.getBookYearEndDate(currentBookYear);
	},
	i18nEx: {
		componentPrefix: "components.reportengine"
	}
});
