
import { SettingsColumn } from "@/utils/report-engine-settings/settings-column";
import Vue from "vue";
import { ReportEngineSettings } from "../../utils/report-engine-settings/report-engine-settings";
import AppSelectColumns from "./SelectColumns.vue";
import { IModel } from "../../utils/models/model-interface";
import { Browse } from "@/models/browse";
import { TextStyledProperty } from "@/utils/documents/text-styled-property";
import AppStyleEditor from "@/components/layout-editor/StyleEditor.vue";

export default Vue.extend({
	data() {
		return {
			selectedProp: "",
			toEditColumnIndex: -1,
			toEditColumn: null as null | SettingsColumn,
			toEditStyle: null as null | TextStyledProperty
		};
	},
	props: {
		value: {
			type: Object as () => ReportEngineSettings,
			required: true
		},
		model: {
			type: Object as () => IModel,
			required: true
		}
	},
	computed: {
		browse():Browse | null {
			return this.value.getBrowseIfBrowseDataSource();
		}
	},
	methods: {
		onPropSelect(prop:string) {
			this.selectedProp = "";
		},
		editCol(i:number) {
			let col = this.value.Columns[i];
			this.toEditColumn = new SettingsColumn(col.getJSON());
			this.toEditColumnIndex = i;
		},
		removeCol(i:number) {
			let value = this.value.Columns[i];
			this.value.Columns.splice(i, 1);
			i = this.value.OrderGroupsTotalColumns.findIndex(v=>v.SettingsColumn == value);
			if (i != -1){
				this.value.OrderGroupsTotalColumns.splice(i, 1);
			}
		},
		addColumn() {
			let col = new SettingsColumn();
			this.toEditColumnIndex = -1;
			this.toEditColumn = col;
		},
		confirmEdit() {
			if (!this.toEditColumn) return;

			if (this.toEditColumnIndex == -1) {
				this.value.Columns.push(this.toEditColumn);
			} else {
				let oldColumn = this.value.Columns[this.toEditColumnIndex];
				this.value.Columns[this.toEditColumnIndex] = this.toEditColumn;
				for (let i = this.value.OrderGroupsTotalColumns.length-1; i >= 0; i--) {
					if (this.value.OrderGroupsTotalColumns[i].SettingsColumn == oldColumn){
						if (this.toEditColumn.Total) {
							this.value.OrderGroupsTotalColumns[i].SettingsColumn = this.toEditColumn;
						}else{
							this.value.OrderGroupsTotalColumns.splice(i,1);
						}
					}
				}
			}
			this.toEditColumn = null;
		},
		confirmEditStyle(style:TextStyledProperty){
			console.log("Confirm edit style");
			if (!this.toEditStyle) return;
			console.log("1");
			if (this.toEditColumnIndex == -1) {
				return;
			}
			console.log("2");
			this.value.Columns[this.toEditColumnIndex].Style = style;
			console.log(this.value.Columns[this.toEditColumnIndex].Style);
			this.toEditStyle = null;
		},
		editStyle(i:number){
			this.toEditColumnIndex = i;
			this.toEditStyle = this.value.Columns[i].Style;
		}
	},
	components: {
		AppSelectColumns,
		AppStyleEditor
	},
	i18nEx: {
		componentPrefix: "components.reportengine"
	}
});
