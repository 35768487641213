import { FriendlyNumberConfig } from "./friendly-number-config";

export class FriendlyNumberConfigs {

	Clients: FriendlyNumberConfig;
	Suppliers: FriendlyNumberConfig;
	Prospects: FriendlyNumberConfig;
	Purchase: FriendlyNumberConfig;
	SaleConfigs:{[key:string]:FriendlyNumberConfig} = {};

	constructor(data: any) {
		this.Clients = new FriendlyNumberConfig(data.Clients);
		this.Suppliers = new FriendlyNumberConfig(data.Suppliers);
		this.Prospects = new FriendlyNumberConfig(data.Prospects);
		this.Purchase = new FriendlyNumberConfig(data.Purchase);
		for (let key of Object.keys(data.SaleConfigs || {})){
			this.SaleConfigs[key] = new FriendlyNumberConfig(data.SaleConfigs[key]);
		}
	}

	getJSON() {
		let saleConfs:{[key:string]:any} = {};
		for (let key of Object.keys(this.SaleConfigs)){
			saleConfs[key] = this.SaleConfigs[key].getJSON();
		}
		return {
			Clients: this.Clients.getJSON(),
			Suppliers: this.Suppliers.getJSON(),
			Prospects: this.Prospects.getJSON(),
			Purchase: this.Purchase.getJSON(),
			SaleConfigs: saleConfs
		};
	}

	getNormalConfigKeys(): Array<keyof FriendlyNumberConfigs> {
		return [
			"Clients", "Suppliers", "Prospects", "Purchase"
		];
	}

	getSaleConfigKeys():string[]{
		return Object.keys(this.SaleConfigs);
	}

	setConfigKeyValue(key:keyof FriendlyNumberConfigs, value:FriendlyNumberConfig):void {
		(this as any)[key] = value;
	}
}