
export class BasicTextStyledProperty {
	public Color: string = "black";
	public FontSize: number = 12;
	public Font: string = "";
	public Italic: boolean = false;
	public Bold: boolean = false;
	public Underline: boolean = false;
	public LineThrough: boolean = false;

	public TextTransform: string = "none"


	constructor(data?: any) {
		if (!data) {
			return;
		}
		this.Color = data.Color;
		this.Font = data.Font;
		this.FontSize = data.FontSize;
		this.Italic = data.Italic;
		this.Underline = data.Underline;
		this.LineThrough = data.LineThrough;
		this.Bold = data.Bold;
		this.TextTransform = data.TextTransform || "none";
	}

	public getStyle(): any {
		let val = {
			"color": this.Color,
			"font-weight": this.Bold ? "bold" : "normal",
			"text-decoration": this.Underline ? "underline" : (this.LineThrough ?  "line-through" : "inherit" ),
			"font-style": this.Italic ? "italic" : "normal",
			"text-transform": this.TextTransform,
		} as any;
		val["font-size"] = (this.FontSize || 0) + "pt";
		if (this.Font != "" && this.Font != null) {
			val["font-family"] = this.Font;
		}
		return val;
	}

	public getJSON(): any {
		return {
			Color: this.Color,
			FontSize: this.FontSize,
			Font: this.Font,
			Bold: this.Bold,
			Underline: this.Underline,
			LineThrough: this.LineThrough,
			Italic: this.Italic,
			TextTransform: this.TextTransform,
		};
	}
}