export class ClientPortalPaymentQrConfig {
	Show:boolean = false;
	BankID:number = 0;

	public constructor(data?:any) {
		if (!data) return;
		this.Show = data.Show;
		this.BankID = data.BankID;
	}

	public getJSON(){
		return {
			Show: this.Show,
			BankID: this.BankID
		};
	}
}