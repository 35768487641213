export class ExtraFieldBaseConfig {
	public EditableViaBrowseMenu:boolean = false;

	public constructor(data?:any) {
		if (!data) return;
		this.EditableViaBrowseMenu = data.EditableViaBrowseMenu;
	}

	public getJSON():any{
		return {
			EditableViaBrowseMenu: this.EditableViaBrowseMenu
		};
	}
}