export class Margin {
	Left:number = 0;
	Right:number = 0;
	Top:number = 0;
	Bottom:number = 0;

	public constructor(data?:any){
		if (!data) return;
		this.Left = data.Left;
		this.Right = data.Right;
		this.Top = data.Top;
		this.Bottom = data.Bottom;
	}

	public getJSON(){
		return {
			Left: this.Left,
			Right: this.Right,
			Top: this.Top,
			Bottom: this.Bottom
		};
	}

	public getMarginStyle():any {
		return {
			marginLeft: `${this.Left}mm`,
			marginRight: `${this.Right}mm`,
			marginTop: `${this.Top}mm`,
			marginBottom: `${this.Bottom}mm`
		};
	}

	public getPaddingStyle():any {
		return {
			paddingLeft: `${this.Left}mm`,
			paddingRight: `${this.Right}mm`,
			paddingTop: `${this.Top}mm`,
			paddingBottom: `${this.Bottom}mm`
		};
	}
}