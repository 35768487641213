import { field, print, rightAlign } from "@/utils/models/decorator-field";
import { model } from "@/utils/models/decorator-model";
import { printHumanFloat } from "@/utils/pretty-print";

@model("ProductIntrastatData")
export class ProductIntrastatData{
	@field("number")
	ID:number = 0;

	// Lidstaat van herkomst
	@field("string")
	Excnt:string = "";

	// INSTELLING Standaard Gewest
	@field("string")
	Extreg:string = "";

	// Goederencode
	@field("string")
	Extgo:string = "";

	// Gewicht per eenheid
	@field("number")
	@rightAlign()
	@print((value:string)=>printHumanFloat(value))
	Exweight:number = 0.0;

	// Land van oorsprong
	@field("string")
	Excntori:string = ""

	public constructor(data?:any){
		if (!data) return;
		this.ID = data.ID;
		this.Excnt = data.Excnt;
		this.Extreg = data.Extreg;
		this.Extgo = data.Extgo;
		this.Exweight = data.Exweight;
		this.Excntori = data.Excntori;
	}

	public getJSON(){
		return {
			ID: this.ID,
			Excnt: this.Excnt,
			Extreg: this.Extreg,
			Extgo: this.Extgo,
			Exweight: this.Exweight,
			Excntori: this.Excntori
		};
	}
}