import { field } from "@/utils/models/decorator-field";
import { model } from "@/utils/models/decorator-model";

@model("BankBalance")

export default class BankBalance{
	@field("number")
	public TypeID:number = 1;

	@field("number")
	public Total:number = 0;

	@field("string")
	public Name:string = "";

	public constructor(data?:any){
		if (!data){
			return;
		}
		this.TypeID = data.TypeID;
		this.Total = data.Total;
		this.Name = data.Name;
	}

	public getJSON() {
		return {
			TypeID: this.TypeID,
			Total: this.Total,
			Name: this.Name,
		};
	}
}