import Vue from "vue";
import "@/utils/electron/electron";
import "@/utils/browser";

if (process.env.NODE_ENV == "development") {
	Vue.config.devtools = true;
}else{
	Vue.config.devtools = false;
}
//import VueApexCharts from "vue-apexcharts";
import components, { IWinfaktVueComponentsOptions } from "winfakt-vue-components";
import App from "./App.vue";
import router from "./router";
import "./plugins/cashdro/cashdro";
import { i18n, loadLanguageAsync } from "./setup/i18n-setup";


// style
import "./assets/css/css.scss";
import "./assets/css/settings.css";
import "./assets/css/transitions.css";
import "./assets/fonts/lindua.css";

import DelayedProgress from "@/components/common/DelayedProgress.vue";
import { createFilters } from "@/setup/filters";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { confirmBuilderEx } from "./setup/confirm-builder";
import { AuthService } from "./services/auth-service";
import AppScriptArgInput from "@/components/scripts/ScriptArgInput.vue";
AuthService.init();

Vue.config.productionTip = false;
Vue.use(components, {
	defaultDatatableCompare: (a: {ID:number}, b: {ID:number}) => a.ID == b.ID,
	iconFonts: [fab],
	defaultDatatableEmptyLabel: ():{toString():string}=>{
		return i18n.t("common.empty-list");
	}
} as IWinfaktVueComponentsOptions);

Vue.use(confirmBuilderEx);

Vue.component("AppDelayedProgress", DelayedProgress);
// Putting this in the component directly gives a weird error
Vue.component("AppScriptArgInput", AppScriptArgInput);

async function init() {
	createFilters(Vue);
	await loadLanguageAsync("nl-be");
	(window as any).wfApp = new Vue({
		render: (h) => h(App),
		i18n,
		router,
	}).$mount("#app");
}

init();


if (process.env.NODE_ENV == "development") {
	if (sessionStorage.getItem("dev-mode-style") != null) {
		if (sessionStorage.getItem("dev-mode-style") == "true") {
			window.document.body.classList.add("dev-mode");
		}
	}else if (process.env.VUE_APP_USE_DEV_MODE_STYLE == "true") {
		window.document.body.classList.add("dev-mode");
	}
	window.addEventListener("keydown", function(e: KeyboardEvent) {
		if (e.key == "F1" &&e.altKey){
			if (window.document.body.classList.contains("dev-mode")){
				window.document.body.classList.remove("dev-mode");
				sessionStorage.setItem("dev-mode-style", "false");
			}else{
				window.document.body.classList.add("dev-mode");
				sessionStorage.setItem("dev-mode-style", "true");
			}
			return;
		}
		if (e.keyCode == 123) {
			e.preventDefault();
			e.stopPropagation();
			debugger;
		}
	});
}

window.addEventListener("dragover", function(e) {
	e = e || event;
	e.preventDefault();
}, false);
window.addEventListener("drop", function(e) {
	e = e || event;
	e.preventDefault();
}, false);
