import { ServerConfig } from "@/config/config";
import { ProductGroup } from "@/models/base/product-group";
import { Axios } from "@/utils/axios";
import { MainSocketService } from "./main-socket-service";

const URL = `${ServerConfig.host}/product-group`;

export class ProductGroupServiceClass {
	private cache:ProductGroup[] = [];
	private topLevelGroupsFetched:boolean = false;
	private allGroupsFetched:boolean = false;

	public constructor(){
		MainSocketService.on("/product-groups-updated", ()=>{
			this.cache = [];
			this.topLevelGroupsFetched = false;
			this.allGroupsFetched = false;
		});
	}

	private mergeGroupToCache(topLevelGroup:ProductGroup){
		let foundGroup = this.cache.find(g=>g.ID == topLevelGroup.ID);
		if (!foundGroup){
			this.cache.push(topLevelGroup);
			return;
		}

		foundGroup.merge(topLevelGroup);
	}

	private mergeGroupsToCache(topLevelGroups:ProductGroup[]){
		for (let group of topLevelGroups){
			this.mergeGroupToCache(group);
		}
	}

	private findGroup(id:number, groupList:ProductGroup[]):ProductGroup | null {
		for (let group of groupList) {
			if (id == group.ID) return group;
			let foundGroup = this.findGroup(id, group.Children);
			if (foundGroup) return foundGroup;
		}
		return null;
	}

	public async getTopLevelGroups():Promise<ProductGroup[]>{
		if (this.topLevelGroupsFetched) return this.cache;
		let result = await Axios.get(`${URL}`);
		let groups = result.data.map((g:any)=>new ProductGroup(g));
		this.topLevelGroupsFetched = true;
		this.mergeGroupsToCache(groups);
		return this.cache;
	}

	public async getAllGroups():Promise<ProductGroup[]>{
		if (this.allGroupsFetched) return this.cache;
		let result = await Axios.get(`${URL}/all`);
		let groups = result.data.map((g:any)=>new ProductGroup(g));
		this.topLevelGroupsFetched = true;
		this.allGroupsFetched = true;
		this.mergeGroupsToCache(groups);
		return this.cache;
	}

	public async getGroupByID(id:number):Promise<ProductGroup>{
		let group = this.findGroup(id, this.cache);
		if (group) return group;
		let result = await Axios.get(`${URL}/${id}`);
		return new ProductGroup(result.data);
	}

	public async getChildrenOfGroup(groupId:number, level:number = 0):Promise<ProductGroup[]>{
		let result = await  Axios.get(`${URL}/${groupId}/fetch-children/${level}`);
		return new ProductGroup(result.data).Children;
	}

	public async getAllChildrenOfGroup(groupId:number):Promise<ProductGroup[]>{
		let result = await Axios.get(`${URL}/${groupId}/fetch-all-children`);
		return new ProductGroup(result.data).Children;
	}

	public async updateGroups(groups:ProductGroup[]):Promise<ProductGroup[]>{
		let result = await Axios.post(`${URL}/update-structure`, groups.map(g=>g.getJSON()));
		return result.data.map((g:any)=>new ProductGroup(g));
	}

	public async addGroup(group:ProductGroup):Promise<ProductGroup>{
		let result = await Axios.post(`${URL}/add-group`, group.getJSON());
		this.cache = [];
		return new ProductGroup(result.data);
	}

	public async addChildGroup(group:ProductGroup, parentGroupId:number):Promise<ProductGroup>{
		let result = await Axios.post(`${URL}/${parentGroupId}/add-child-group`, group.getJSON());
		return new ProductGroup(result.data);
	}
}

export const ProductGroupService = new ProductGroupServiceClass();