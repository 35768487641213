
import Vue from "vue";
import { ReportEngineSettings } from "../../utils/report-engine-settings/report-engine-settings";
import { IModel } from "../../utils/models/model-interface";
import AppSortingEdit from "./SortingEdit.vue";
import { OrderColumn } from "../../utils/report-engine-settings/order-column";
import { Browse } from "@/models/browse";

export default Vue.extend({
	data() {
		return {
			toEditSorting: null as null | OrderColumn,
		};
	},
	props: {
		value: {
			type: Object as () => ReportEngineSettings,
			required: true
		},
		model: {
			type: Object as () => IModel,
			required: true
		}
	},
	computed: {
		browse():Browse | null {
			return this.value.getBrowseIfBrowseDataSource();
		}
	},
	methods: {
		getSortingName(sorting:OrderColumn):string {
			let field = this.model.getField(sorting.Column);
			if (!field){
				return sorting.Column;
			}
			if (this.browse) {
				let override = this.browse.getColumnNameOverride(field.path);
				if (override) {
					return override;
				}
			}
			return field.getPropertyTranslation();
		},
		removeSorting(i:number) {
			this.value.OrderColumns.splice(i, 1);
		},
		editSorting(sorting:OrderColumn){
			this.toEditSorting = sorting;
		},
		addSorting(){
			this.toEditSorting = new OrderColumn();
		},
		editSortingConfirm(sorting:OrderColumn){
			if (!this.toEditSorting) return;
			let i = this.value.OrderColumns.indexOf(this.toEditSorting);
			if (i == -1){
				this.value.OrderColumns.push(sorting);
			}else{
				this.value.OrderColumns[i] = sorting;
			}
			this.toEditSorting = null;
		}
	},
	components: {
		AppSortingEdit
	},
	i18nEx: {
		componentPrefix: "components.reportengine"
	}
});
