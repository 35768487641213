import { v4 as uuid } from "uuid";
import { DocumentLayoutLogoPosition } from "./layout-logo";
import { Field } from "./field";
import { i18n } from "@/setup/i18n-setup";

export class FreeBlock {
	public Name:string = "";
	public uid = uuid();
	public Width:number = 50;
	public Height:number = 20;
	public X:number = 10;
	public Y:number = 10;
	public Placement:DocumentLayoutLogoPosition = "Heading";
	public Lines:Field[] = [];

	public constructor(data?:any){
		if (!data) return;
		this.Name = data.Name || "";
		this.Width = data.Width;
		this.Height = data.Height;
		this.X = data.X;
		this.Y = data.Y;
		this.Placement = data.Placement;
		this.Lines = (data.Lines || []).map((l:any)=>new Field(l));
	}

	public getJSON(){
		return {
			Name: (this.Name || "").trim(),
			Width: this.Width,
			Height: this.Height,
			X: this.X,
			Y: this.Y,
			Placement: this.Placement,
			Lines: this.Lines.map(l=>l.getJSON())
		};
	}


	public getName(index:number):string{
		if (this.Name) return this.Name;
		return i18n.t("components.sale-layout-editor.block-i", {number: index+1}).toString();
	}
}