import { Browse, BrowseID } from "@/models/browse";

export class BrowseSearchSettings {
	Clients: string[] = [];
	Supliers: string[] = [];
	Prospects: string[] = [];
	Products: string[] = [];
	Stock: string[] = [];
	Sales: string[] = [];
	Bank: string[] = [];
	SaleRows: string[] = [];
	Subscriptions: string[] = [];
	Purchase: string[] = [];
	SupplierPurchase: string[] = [];
	Cars: string[] = [];
	ProspectDocuments:string[] = [];
	DiverseLedgers:string[] = [];
	PurchaseDocumentSaleRows:string[] = [];
	Crm:string[] = [];
	SaleRowsExtraBookYearsToSearch:number = 0;

	constructor(data?: any) {
		if (!data) return;
		this.Clients = data.Clients || [];
		this.Supliers = data.Supliers || [];
		this.Prospects = data.Prospects || [];
		this.Products = data.Products || [];
		this.Stock = data.Stock || [];
		this.Sales = data.Sales || [];
		this.Bank = data.Bank || [];
		this.SaleRows = data.SaleRows || [];
		this.Subscriptions = data.Subscriptions || [];
		this.Purchase = data.Purchase || [];
		this.SupplierPurchase = data.SupplierPurchase || [];
		this.Cars = data.Cars || [];
		this.ProspectDocuments = data.ProspectDocuments || [];
		this.DiverseLedgers = data.DiverseLedgers || [];
		this.PurchaseDocumentSaleRows = data.PurchaseDocumentSaleRows || [];
		this.Crm = data.Crm || [];
		this.SaleRowsExtraBookYearsToSearch = data.SaleRowsExtraBookYearsToSearch || 0;
	}

	getJSON() {
		return {
			Clients: this.Clients,
			Supliers: this.Supliers,
			Prospects: this.Prospects,
			Products: this.Products,
			Stock: this.Stock,
			Sales: this.Sales,
			SaleRows: this.SaleRows,
			Bank: this.Bank,
			Subscriptions: this.Subscriptions,
			Purchase: this.Purchase,
			SupplierPurchase: this.SupplierPurchase,
			Cars: this.Cars,
			ProspectDocuments: this.ProspectDocuments,
			DiverseLedgers: this.DiverseLedgers,
			PurchaseDocumentSaleRows: this.PurchaseDocumentSaleRows,
			Crm: this.Crm,
			SaleRowsExtraBookYearsToSearch: this.SaleRowsExtraBookYearsToSearch
		};
	}
	// eslint-disable-next-line max-lines-per-function
	getSettings(browse:Browse | number):string[] {
		let browseId = 0;
		if (typeof(browse) == "number"){
			browseId = browse;
		}else{
			browseId = browse.ID;
		}
		if (browseId == BrowseID.CLIENTS) {
			return this.Clients;
		}
		if (browseId == BrowseID.SUPPLIERS) {
			return this.Supliers;
		}
		if (browseId == BrowseID.PROSPECTS) {
			return this.Prospects;
		}
		if (browseId == BrowseID.PRODUCTS) {
			return this.Products;
		}
		if  (browseId == BrowseID.STOCKIN) {
			return this.Stock;
		}
		if (browseId == BrowseID.SALES) {
			return this.Sales;
		}
		if (browseId == BrowseID.SALE_ROWS) {
			return this.SaleRows;
		}
		if (browseId == BrowseID.BANK) {
			return this.Bank;
		}
		if (browseId == BrowseID.PURCHASE) {
			return this.Purchase;
		}
		if (browseId == BrowseID.SUBSCRIPTIONS) {
			return this.Subscriptions;
		}
		if (browseId == BrowseID.PURCHASE_DOCUMENTS) {
			return this.SupplierPurchase;
		}
		if (browseId == BrowseID.CARS) {
			return this.Cars;
		}
		if (browseId == BrowseID.PROSPECT_DOCUMENTS) {
			return this.ProspectDocuments;
		}
		if (browseId == BrowseID.DIVERSE_LEDGERS){
			return this.DiverseLedgers;
		}
		if (browseId == BrowseID.PURCHASE_DOCUMENT_ROWS) {
			return this.PurchaseDocumentSaleRows;
		}
		if (browseId == BrowseID.CRM) {
			return this.Crm;
		}
		throw new Error("No such config");
	}
}