
export class GeneralUserConfig {
	SelectedLang: string = "nl-be";
	NotifyOnFrequencyDocumentCreated:boolean = false;
	NotifyOnFrequencyDocumentSent:boolean = false;
	NotifyOnReminderSent:boolean = false;
	RepeatBankSave:boolean = false;
	ShowSalesOrPurchasesInBankEdit:boolean = false;
	AutoAssignProductSku:boolean = false;
	AutoUpdateProductComposition:boolean = false;
	GetPurchasePriceFromProductWhenZero:boolean = false;
	UseCheckboxesInViews:boolean = false;
	CompactView:boolean = false;
	MaximizeEmailModal:boolean = false;
	BanksOrder:number[] = [];

	ClearF3SearchFilter:boolean = true
	DefaultProductsF3Browse:number = 0
	DefaultSalesF3Browse:number = 0
	DefaultSaleRowsF3Browse:number = 0
	DefaultBankSalesF3Browse:number = 0
	DefaultPurchaseF3Browse:number = 0

	constructor(data: any) {
		if (!data) return;
		this.SelectedLang = data.SelectedLang;
		this.NotifyOnFrequencyDocumentCreated = data.NotifyOnFrequencyDocumentCreated;
		this.NotifyOnFrequencyDocumentSent = data.NotifyOnFrequencyDocumentSent;
		this.NotifyOnReminderSent = data.NotifyOnReminderSent;
		this.RepeatBankSave = data.RepeatBankSave;
		this.ShowSalesOrPurchasesInBankEdit = data.ShowSalesOrPurchasesInBankEdit == undefined ? false : data.ShowSalesOrPurchasesInBankEdit;
		this.AutoAssignProductSku = data.AutoAssignProductSku;
		this.AutoUpdateProductComposition = data.AutoUpdateProductComposition;
		this.GetPurchasePriceFromProductWhenZero = data.GetPurchasePriceFromProductWhenZero;
		this.UseCheckboxesInViews = data.UseCheckboxesInViews;
		this.CompactView = data.CompactView;
		this.MaximizeEmailModal = data.MaximizeEmailModal;
		this.ClearF3SearchFilter = data.ClearF3SearchFilter == undefined ? true : data.ClearF3SearchFilter;
		this.DefaultProductsF3Browse = data.DefaultProductsF3Browse;
		this.DefaultSalesF3Browse = data.DefaultSalesF3Browse;
		this.DefaultSaleRowsF3Browse = data.DefaultSaleRowsF3Browse;
		this.DefaultPurchaseF3Browse = data.DefaultPurchaseF3Browse;
		this.DefaultBankSalesF3Browse = data.DefaultBankSalesF3Browse;
		if (this.SelectedLang == "") {
			this.SelectedLang = "nl-be";
		}
		this.BanksOrder = [...(data.BanksOrder || [])];
	}

	getJSON() {
		return {
			SelectedLang: this.SelectedLang,
			NotifyOnFrequencyDocumentCreated: this.NotifyOnFrequencyDocumentCreated,
			NotifyOnFrequencyDocumentSent: this.NotifyOnFrequencyDocumentSent,
			NotifyOnReminderSent: this.NotifyOnReminderSent,
			RepeatBankSave: this.RepeatBankSave,
			ShowSalesOrPurchasesInBankEdit: this.ShowSalesOrPurchasesInBankEdit,
			AutoAssignProductSku: this.AutoAssignProductSku,
			AutoUpdateProductComposition: this.AutoUpdateProductComposition,
			GetPurchasePriceFromProductWhenZero: this.GetPurchasePriceFromProductWhenZero,
			UseCheckboxesInViews: this.UseCheckboxesInViews,
			CompactView: this.CompactView,
			MaximizeEmailModal: this.MaximizeEmailModal,
			ClearF3SearchFilter: this.ClearF3SearchFilter,
			DefaultProductsF3Browse: this.DefaultProductsF3Browse,
			DefaultSalesF3Browse: this.DefaultSalesF3Browse,
			DefaultSaleRowsF3Browse: this.DefaultSaleRowsF3Browse,
			DefaultBankSalesF3Browse: this.DefaultBankSalesF3Browse,
			DefaultPurchaseF3Browse: this.DefaultPurchaseF3Browse,
			BanksOrder: this.BanksOrder
		};
	}
}