export class ChatUnreadConversation {
	ID: number = 0;
	ConversationID: number = 0;
	UserID: number = 0;
	Count: number = 0;

	constructor(data?: any) {
		if (!data) return;
		this.ID = data.ID;
		this.ConversationID = data.ConversationID;
		this.UserID = data.UserID;
		this.Count = data.Count;
	}

}