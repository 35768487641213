export function getEnumKeys(en:any):string[] {
	let keys = Object.keys(en);
	return keys;
	/*
	let end = keys.length / 2;
	let result:string[] = [];
	for (let i = 0; i < end; i++){
		result.push(en[i]);
	}
	return result;
	*/
}

export function getEnumValueFromKey(en:any, key:string):any{
	let keys = Object.keys(en);
	let i = keys.indexOf(key);
	let vals = Object.values(en);
	return vals[i];
}