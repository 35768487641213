export class ObservationDescriptionActivity {
	mainActivity:string = "";
	subActivity:string = "";

	public constructor(data?:any) {
		if (!data) return;
		this.mainActivity = data.mainActivity;
		this.subActivity = data.subActivity;
	}

	getJSON(){
		return {
			mainActivity: this.mainActivity,
			subActivity: this.subActivity
		};
	}

	getText(){
		return `${this.mainActivity} - ${this.subActivity}`;
	}
}