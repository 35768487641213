import { model } from "@/utils/models/decorator-model";
import { field } from "@/utils/models/decorator-field";

@model("CrmHistoryClose")

export default class CrmHistoryClose {

	@field("string")
	public Message: string = "";

	constructor(data?: any) {
		if (!data) return;
		this.Message = data.Message;
	}


	getJSON() {
		return {
			Message: this.Message,
		};
	}
}
