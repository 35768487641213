
import Vue from "vue";
import { ReportEngineSettings } from "../../utils/report-engine-settings/report-engine-settings";
import { IModel } from "../../utils/models/model-interface";
import AppAskedFilter from "./AskedFilters.vue";
import AppMiscSettings from "./MiscSettings.vue";

export default Vue.extend({
	props: {
		value: {
			type: Object as ()=>ReportEngineSettings,
			required: true,
		},
		model: {
			type: Object as ()=>IModel,
			required: true
		}
	},
	components: {
		AppAskedFilter,
		AppMiscSettings
	},
});
