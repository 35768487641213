
import Vue from "vue";
export default Vue.extend({
	props: {
		value: {
			required: true,
			type: [Object, Array]
		},
		title: {
			type: String,
			default():string{
				return this.$ct("common.inspect-object").toString();
			}
		}
	},
	methods: {
		close(){
			this.$emit("close");
		}
	}
});
