
import { Condition } from "@/utils/condition/condition";
import Vue from "vue";
import { getModel, Model } from "@/utils/models/model";
import AppBrowseConditionEdit from "@/components/models/BrowseConditionEdit.vue";
import { Browse } from "@/models/browse";

export default Vue.extend({
	props: {
		value: {
			type: Object as ()=>Condition,
			required: true
		},
		browse: {
			type: Object as ()=>Browse
		}
	},
	computed: {
		model():Model{
			return getModel("SaleRow");
		},
	},
	components: {
		AppBrowseConditionEdit
	}
});
