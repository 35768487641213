import { Module } from "./module";

export class ModuleExtraUsers extends Module {
	public getExtraUsers():number{
		return parseInt(this.MetaData["extra-users"] || "0") || 0;
	}

	public setExtraUsers(count:number):void {
		this.MetaData["extra-users"] = `${count}`;
	}
}