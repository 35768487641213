import { getAuthFileUrl } from "@/utils/url";

export class TicketMessageAttachement {
	id:number;
	fileName:string;
	attachementUrl:string;
	mimeType:string;

	constructor(data:any) {
		this.id = data.id;
		this.fileName = data.fileName;
		this.attachementUrl = getAuthFileUrl(data.attachementKey);
		this.mimeType = data.mimeType;
	}
}