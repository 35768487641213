export class Roadmap {
	id:number = 0;
	Title:string = "";
	Intro: string = "";
	Text: string = "";
	Status: number = 0;
	IsCommingSoon: boolean = false;
	Date:Date = new Date();

	constructor(data?:any) {
		if (!data) return;
		this.id = data.id;
		this.Title = data.title;
		this.Intro = data.intro;
		this.Text = data.text;
		this.Status = data.status;
		this.IsCommingSoon = data.isCommingSoon;
		this.Date = new Date(data.Date);
	}

}