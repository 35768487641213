import Sale from "./sale";

export class Deposit {
	public ID:number = 0;
	public ContactID:number = 0;
	public SaleID:number = 0;
	public SaleCreatedByDocumentNr:string = ""

	public Sale:Sale | null = null;

	public constructor(data?:any) {
		if (!data) return;
		this.ID = data.ID;
		this.ContactID = data.ContactID;
		this.SaleID = data.SaleID;
		this.SaleCreatedByDocumentNr = data.SaleCreatedByDocumentNr;
		this.Sale = new Sale(data.Sale);
	}
}