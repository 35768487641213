import { i18n } from "@/setup/i18n-setup";
import { StockLocationSettings } from "./stock-location-settings";

export class StockLocationConfig {
	static readonly MAX_ID = 9;
	ShowNegativeValuesOnInventoryReport:boolean = false;
	OutOfStockSelectedSupplierSelectGroup:boolean = false;

	Location1:string = "";
	Location2:string = "";
	Location3:string = "";
	Location4:string = "";
	Location5:string = "";
	Location6:string = "";
	Location7:string = "";
	Location8:string = "";
	Location9:string = "";

	SettingsLocation1:StockLocationSettings = new StockLocationSettings();
	SettingsLocation2:StockLocationSettings = new StockLocationSettings();
	SettingsLocation3:StockLocationSettings = new StockLocationSettings();
	SettingsLocation4:StockLocationSettings = new StockLocationSettings();
	SettingsLocation5:StockLocationSettings = new StockLocationSettings();
	SettingsLocation6:StockLocationSettings = new StockLocationSettings();
	SettingsLocation7:StockLocationSettings = new StockLocationSettings();
	SettingsLocation8:StockLocationSettings = new StockLocationSettings();
	SettingsLocation9:StockLocationSettings = new StockLocationSettings();

	constructor(data?:any) {
		if (!data) return;
		this.ShowNegativeValuesOnInventoryReport = data.ShowNegativeValuesOnInventoryReport || false;
		this.OutOfStockSelectedSupplierSelectGroup = data.OutOfStockSelectedSupplierSelectGroup || false;
		for (let i = 1; i <= StockLocationConfig.MAX_ID; i++) {
			(this as any)["Location" + i] = data["Location" + i];
			(this as any)["SettingsLocation" + i] = new StockLocationSettings(data["SettingsLocation" + i]);
		}
	}

	getJSON() {
		return {
			ShowNegativeValuesOnInventoryReport: this.ShowNegativeValuesOnInventoryReport,
			OutOfStockSelectedSupplierSelectGroup: this.OutOfStockSelectedSupplierSelectGroup,

			Location1: this.Location1,
			Location2: this.Location2,
			Location3: this.Location3,
			Location4: this.Location4,
			Location5: this.Location5,
			Location6: this.Location6,
			Location7: this.Location7,
			Location8: this.Location8,
			Location9: this.Location9,

			SettingsLocation1: this.SettingsLocation1.getJSON(),
			SettingsLocation2: this.SettingsLocation2.getJSON(),
			SettingsLocation3: this.SettingsLocation3.getJSON(),
			SettingsLocation4: this.SettingsLocation4.getJSON(),
			SettingsLocation5: this.SettingsLocation5.getJSON(),
			SettingsLocation6: this.SettingsLocation6.getJSON(),
			SettingsLocation7: this.SettingsLocation7.getJSON(),
			SettingsLocation8: this.SettingsLocation8.getJSON(),
			SettingsLocation9: this.SettingsLocation9.getJSON(),
		};
	}

	isValidLocationID(id:number):boolean {
		if (id < 1) {
			return false;
		}
		if (id > StockLocationConfig.MAX_ID) {
			return  false;
		}
		return true;
	}

	getLocationName(locationID:number):string {
		if (!this.isValidLocationID(locationID)) {
			throw new Error("Invalid location ID");
		}
		let locationName = (this as any)["Location" + locationID];
		if (!locationName.match(/^\s*$/)) {
			return locationName;
		}

		return i18n.tc("common.stock-location", locationID).toString();
	}

	getLocationAlias(locationID:number):string {
		if (!this.isValidLocationID(locationID)) {
			throw new Error("Invalid location ID");
		}
		return (this as any)["Location" + locationID];
	}

	getLocationSettings(locationID:number):StockLocationSettings {
		if (!this.isValidLocationID(locationID)) {
			throw new Error("Invalid location ID");
		}
		return new StockLocationSettings((this as any)["SettingsLocation" + locationID]);
	}

	getSortedLocationNames():string[] {
		let items = [] as string[];
		for (let i = 1; i <= StockLocationConfig.MAX_ID; i++) {
			items.push(this.getLocationName(i));
		}
		return items;
	}

	setLocationAlias(locationID: number, alias:string) {
		if (!this.isValidLocationID(locationID)) {
			throw new Error("Invalid location ID");
		}
		(this as any)["Location" + locationID] = alias;
	}

	setLocationSettings(locationID:number, settings:StockLocationSettings) {
		if (!this.isValidLocationID(locationID)) {
			throw new Error("Invalid location ID");
		}
		(this as any)["SettingsLocation" + locationID] = settings;
	}
}