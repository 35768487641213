import { v4 } from "uuid";
import { AwsDocument } from "./aws-document";

export class SaleAwsDocument extends AwsDocument {
	readonly uid:string = v4();

	SaleID:number = 0;
	BookYear:number = 0;
	ShowWithPrint:boolean = false;
	ShowWithSend:boolean = false;
	ShowWithDownload:boolean = false;
	DisplayOrder:number = 0;

	toUploadFile:File | null = null;

	public constructor(data?:any){
		super(data);
		if (!data) return;
		this.SaleID = data.SaleID;
		this.BookYear = data.BookYear;
		this.DisplayOrder = data.DisplayOrder;
		this.ShowWithPrint = data.ShowWithPrint;
		this.ShowWithSend = data.ShowWithSend;
		this.ShowWithDownload = data.ShowWithDownload;
		this.DisplayOrder = data.DisplayOrder;
	}

	public getJSON(){
		return {
			ID: this.ID,
			FileID: this.FileID,
			SaleID: this.SaleID,
			BookYear: this.BookYear,
			ShowWithPrint: this.ShowWithPrint,
			ShowWithSend: this.ShowWithSend,
			ShowWithDownload: this.ShowWithDownload,
			DisplayOrder: this.DisplayOrder,
		};
	}
}