
import { Condition } from "@/utils/condition/condition";
import AppFieldConditionEdit from "./ConditionEdit.vue";
import Vue from "vue";
import { PropSelectGroup } from "@/utils/prop-select/prop-select-group";

export default Vue.extend({
	data(){
		return {
			hiddenKeys: [] as string[],
		};
	},
	props: {
		conditions: {
			type: Array as ()=>Condition[],
			required: true
		},
		fields: {
			type: Object as ()=>PropSelectGroup,
			required: true
		}
	},
	methods: {
		async addCondition(){
			let condition = new Condition();
			this.hiddenKeys.push(condition.key);
			this.conditions.push(condition);
			await this.$nextTick();
			this.hiddenKeys.pop();
		},
		removeCondition(i:number){
			this.conditions.splice(i,1);
		},
		shouldShowKey(key:string):boolean{
			return this.hiddenKeys.indexOf(key) == -1;
		}
	},
	components: {
		AppFieldConditionEdit
	},
	i18nEx: {
		componentPrefix: "components.sale-layout-editor"
	}
});
