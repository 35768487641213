
import { Car } from "@/models/base";
import { CarService } from "@/services";
import Vue from "vue";
import { TranslateResult } from "vue-i18n";
export default Vue.extend({
	data(){
		return {
			input: "",
			foundCars: [] as Car[]
		};
	},
	props: {
		value: {
			type: Object as ()=>Car,
		},
		label: {
			type: [String, Object as ()=>TranslateResult]
		},
		fancy: Boolean,
		includeMileageInLabel: Boolean
	},
	watch: {
		async input(input:string){
			if (input.match(/^\s*$/)) {
				this.foundCars = [];
				return;
			}
			this.foundCars = await CarService.search(this.input);
		},
		value: {
			immediate: true,
			handler(){
				this.onBlur();
			}
		}
	},
	methods: {
		onBlur() {
			this.foundCars = [];
			if (this.value) {
				if (this.value.Chassis) {
					this.input = this.value.Chassis;
				}
				if (this.value.Plate) {
					this.input = this.value.Plate;
				}
				if (this.value.Plate && this.value.Chassis){
					this.input = `${this.value.Plate} ${this.value.Chassis}`;
				}
				if (this.includeMileageInLabel) {
					this.input += ` (${this.value.Mileage})km`;
				}
			}else{
				this.input = "";
			}
			this.$emit("blur");
		},
		onFocus() {
			this.input = "";
		},
		selectCar(car:Car){
			this.$emit("input", car);
		},
		clearCar(){
			this.$emit("input", null);
		}
	}
});
