import { i18n } from "@/setup/i18n-setup";
import { printLocalDate } from "@/utils/pretty-print";
import { field, filterValueInput } from "@/utils/models/decorator-field";
import { model } from "@/utils/models/decorator-model";
import { SaleService } from "@/services/sale-service";

export enum SaleHistoryEventType {
	Edit = 1,
	Send = 2,
	Download = 3,
	Print = 4,
	Reminder = 5,
	SaleTypeChanged = 6,
	EmailRead = 7,
	ReminderEmailRead = 8,
	SentWithBillit = 9
}

@model("SaleHistory")
export default class SaleHistory{
	@field("number")
	public ID:number = 0;

	@field("number")
	public SaleID: Number = 0;

	@field("date")
	public TimeStamp: Date = new Date();

	@field("number")
	public EventTypeID: SaleHistoryEventType = 1;

	@field("array")
	public Props: string[] = [];

	@field("number")
	@filterValueInput("BookYear")
	public BookYear: number = 0;


	public constructor(data?: any) {
		if (!data) {
			return;
		}
		this.ID = data.ID;
		this.SaleID = data.SaleID;
		this.TimeStamp = new Date(data.TimeStamp);
		this.EventTypeID = data.EventTypeID;
		this.Props = data.Props || [];
		this.BookYear = data.BookYear;
	}

	public getJSON() {
		return {
			ID: this.ID,
			SaleID: this.SaleID,
			TimeStamp: this.TimeStamp.toJSON(),
			EventTypeID: this.EventTypeID,
			Props: this.Props,
			BookYear: this.BookYear
		};
	}

	public getTimeStampString(): string {
		return printLocalDate(this.TimeStamp);
	}

	public getEventString(): string {
		if (this.EventTypeID == SaleHistoryEventType.Edit) {
			return i18n.t("models.SaleHistoryEventType.edit").toString();
		}
		if (this.EventTypeID == SaleHistoryEventType.Download) {
			return i18n.t("models.SaleHistoryEventType.download").toString();
		}
		if (this.EventTypeID == SaleHistoryEventType.Print) {
			return i18n.t("models.SaleHistoryEventType.print").toString();
		}
		if (this.EventTypeID == SaleHistoryEventType.Send) {
			return i18n.t("models.SaleHistoryEventType.send").toString();
		}
		if (this.EventTypeID == SaleHistoryEventType.Reminder) {
			return i18n.t("models.SaleHistoryEventType.reminder").toString();
		}
		if (this.EventTypeID == SaleHistoryEventType.SaleTypeChanged) {
			let originalType = SaleService.saleTypes.find(f=>`${f.ID}` == this.Props[0]);
			let originalName = originalType ? originalType.Name.getTranslation(i18n.locale) : "ERROR";
			let originalNumber = this.Props[1];
			let newType = SaleService.saleTypes.find(f=>`${f.ID}` == this.Props[2]);
			let newName = newType ? newType.Name.getTranslation(i18n.locale) : "ERROR";
			let newNumber = this.Props[3];
			return i18n.t("models.SaleHistoryEventType.sale-type-change", {originalType: originalName, originalNumber, newType: newName, newNumber}).toString();
		}
		if (this.EventTypeID == SaleHistoryEventType.EmailRead) {
			return i18n.t("models.SaleHistoryEventType.email-read").toString();
		}
		if (this.EventTypeID == SaleHistoryEventType.ReminderEmailRead){
			return i18n.t("models.SaleHistoryEventType.reminder-email-read").toString();
		}
		if (this.EventTypeID == SaleHistoryEventType.SentWithBillit){
			return i18n.t("models.SaleHistoryEventType.sent-with-billit", {id: this.Props[0]}).toString();
		}
		throw new Error("Invalid event type");
	}

	public getEventProps(): any {
		if (this.EventTypeID == SaleHistoryEventType.Send) {
			if (this.Props)
				return this.Props[0];
		}
		if (this.EventTypeID == SaleHistoryEventType.Reminder) {
			if (this.Props)
				return i18n.t("views.SaleInfo.reminder", {num: this.Props[0]}).toString();
		}
		if (this.EventTypeID == SaleHistoryEventType.EmailRead && this.Props.length >= 1){
			return i18n.t("views.SaleInfo.document-read");
		}
	}

	public hasExtraInfo():boolean {
		if (this.EventTypeID == SaleHistoryEventType.Reminder && this.Props.length >= 2){
			return true;
		}
		if (this.EventTypeID == SaleHistoryEventType.Send && this.Props.length >= 2) {
			return true;
		}
		if (this.EventTypeID == SaleHistoryEventType.EmailRead && this.Props.length >= 1) {
			return true;
		}
		return false;
	}

	public getExtraInfoComponentName():string{
		if (this.EventTypeID == SaleHistoryEventType.Reminder) {
			return "reminder-sent-info";
		}
		if (this.EventTypeID == SaleHistoryEventType.Send) {
			return "sent-info";
		}
		if (this.EventTypeID == SaleHistoryEventType.EmailRead) {
			return "email-read";
		}
		return "";
	}
}
