import { FavvTax} from "../taxes/favv";
export class TaxesConfig {
	FavvConfig: FavvTax = new FavvTax();

	public constructor(data?:any){
		if (!data) return;
		this.FavvConfig = new FavvTax(data.FavvConfig);
	}

	public getJSON():any{
		return {
			FavvConfig: this.FavvConfig,
		};
	}
}