import { ServerConfig } from "@/config/config";
import {BankType, Contact, Purchase} from "@/models/base";
import PurchaseExtraFields from "@/models/base/purchase-extra-fields";
import { Job } from "@/models/job";
import { View } from "@/models/view";
import { Axios } from "@/utils/axios";
import { downloadBlob } from "@/utils/blob";
import { advancedSearch } from "@/utils/browse/browse";
import { IGetResponse } from "@/utils/get-response";
import { HumanFilter } from "@/utils/human-filter";
import { IExtraFieldQuickEditViaMenuDataResult } from "@/utils/models/extra-fields";
import { getModel } from "@/utils/models/model";
import { INextAvailableIDResponse } from "@/utils/next-available-id-respone";
import { printWithCustomFormat } from "@/utils/pretty-print";
import { InboxreaderPurchase } from "@/utils/purchase/inboxreader-purchase";
import { IQueryParamsWithOptionalView } from "@/utils/query-params";
import { FetchedTotal } from "@/utils/views/fetched-total";

export type ExportToIsabelPriority = "Normal" | "High" | ""
export type ExportToIsabelCategoryPurpose = "" | "Dividend" | "IntraCompanyPayment" | "Interest" | "PensionPayment" | "SalaryPayment" | "SocialSecurityBenefit" | "SupplierPayment" | "TaxPayment" | "TreasuryPayment";
export interface IExportToIsabelOptions{
	executionDate:Date;
	priority:ExportToIsabelPriority;
	useExpirationDate:boolean;
	categoryPurpose:string;
}

export class PurchaseServiceClass {
	private get url(): string {
		return ServerConfig.host + "/purchase";
	}

	private get inboxreaderUrl():string {
		return `${this.url}/inboxreader`;
	}

	async getPurchases(query: IQueryParamsWithOptionalView): Promise<IGetResponse<Purchase>> {
		let result = await advancedSearch(query, this.url, getModel("Purchase"));
		result.data.data = result.data.data.map((r: any) => new Purchase(r));
		return result.data;
	}

	async getTotals(view:View):Promise<FetchedTotal[]>{
		let result = await Axios.get(`${this.url}/view/${view.ID}/totals`);
		return (result.data || []).map((d:any)=>new FetchedTotal(d));
	}

	async getPurchase(id: number): Promise<Purchase> {
		let result = await Axios.get(`${this.url}/${id}`, {});
		return new Purchase(result.data);
	}

	async delPurchases(purchase: Purchase[]): Promise<void> {
		await Axios.delete(this.url, {
			data: purchase.map(p => p.ID)
		});
	}

	async postPurchase(purchase:Purchase):Promise<Purchase> {
		let result = await Axios.post(`${this.url}`, purchase.getJSON());
		let p = new Purchase(result.data);
		for (let file of purchase.UnprocessedFiles){
			await this.addDocumentToPurchase(p,file);
		}
		return p;
	}

	async putPurchase(purchase:Purchase):Promise<Purchase> {
		let result = await Axios.put(`${this.url}/${purchase.ID}`, purchase.getJSON());
		let p = new Purchase(result.data);
		for (let file of purchase.UnprocessedFiles){
			await this.addDocumentToPurchase(purchase,file);
		}
		for (let file of purchase.ToDeleteFiles){
			await this.removeDocumentFromPurchase(purchase, file.ID);
		}
		return p;
	}

	async addDocumentToPurchase(purchase:Purchase, file:File):Promise<Purchase> {
		let data = new FormData();
		data.append("file", file, file.name);
		let result = await Axios.post(`${this.url}/${purchase.ID}/add-document`, data);
		return new Purchase(result.data);
	}

	async removeDocumentFromPurchase(purchase:Purchase, fileId:number):Promise<Purchase> {
		let result = await Axios.delete(`${this.url}/${purchase.ID}/remove-document/${fileId}`);
		return new Purchase(result.data);
	}

	async search(input:string):Promise<Purchase[]>{
		let result = await Axios.get(`${this.url}`, {
			params: {
				limit: 10,
				filters: [
					new HumanFilter({Field: "this.ComputedFriendlyID", Operator: "like", Values: [input]}),
					new HumanFilter({Field: "this_Contact_MainAddress.CompanyName", Operator: "like", Values: [input], IsOr: true})
				],
				preloads: ["Contact.MainAddress"]
			}
		});
		return result.data.data.map((p:any)=>new Purchase(p));
	}

	async getNextAvailableComputedID(journalId: number): Promise<INextAvailableIDResponse> {
		let result = await Axios.get(`${this.url}/get-next-computed-friendly-id/${journalId}`);
		return {
			friendlyId: result.data.FriendlyID,
			computedFriendlyId: result.data.ComputedFriendlyID
		};
	}

	async getByFriendlyId(id:string, bookyear?:number):Promise<Purchase>{
		let result = await Axios.get(`${this.url}/by-computed-id/${id}`, {headers: {BookYear: bookyear || ""}});
		return new Purchase(result.data);
	}

	async findByInvoiceAndContactId(invoiceId:string, contactId:number):Promise<Purchase> {
		let result = await Axios.get(`${this.url}/find-by-invoice-and-contact-id/${encodeURIComponent(invoiceId)}/${contactId}`);
		return new Purchase(result.data);
	}

	async exportToIsabel(bank:BankType, purchases:Purchase[], options:IExportToIsabelOptions){
		let result = await Axios.post(`${this.url}/export-to-isabel`, {
			PurchaseIDs: purchases.map(p=>p.ID),
			BankID: bank.ID,
			ExecutionDate: options.executionDate,
			UseExpirationDate: options.useExpirationDate,
			Priority: options.priority,
			CategoryPurpose: options.categoryPurpose,
		});
		let blob = new Blob([result.data], {type: "application.xml"});

		let fileName = `${printWithCustomFormat(new Date(), bank.SepaFileNameFormat)}.xml`;
		downloadBlob(blob, fileName);
	}


	async afpunten(contact?: Contact): Promise<Job> {
		let url = this.url + "/afpunten";
		if (contact) {
			url += contact.ID;
		}
		let result = await Axios.post(url);
		return new Job(result.data);
	}

	async saldosOverboeken(): Promise<Job> {
		let result = await Axios.post(`${this.url}/saldos-overboeken`);
		return new Job(result.data);
	}

	async fillDocumentIndexes():Promise<Job> {
		let result = await Axios.post(`${this.url}/fill-document-indexes`);
		return new Job(result.data);
	}

	async resetNumbering(journalId:number):Promise<void>{
		await Axios.post(`${this.url}/reset-numbering/${journalId}`);
	}

	public async batchEditExtraField(purchases:Purchase[], field:string, value:IExtraFieldQuickEditViaMenuDataResult) {
		let body = {
			ExtraFieldIDs: purchases.map(s=>s.ExtraFieldsID),
			Field: field,
			NumValue: value.numValue,
			TextValue: value.textValue,
			BoolValue: value.boolValue,
			TimeValue: value.timeValue
		};
		await Axios.post(`${this.url}/batch-edit-extra-field`, body);
	}

	public async salesHaveBanks(purchases:(Purchase|number)[]):Promise<boolean>{
		purchases = purchases.map(s=>typeof(s)=="object" ? s.ID : s);
		let result = await Axios.get(`${this.url}/have-banks`,{ params: {purchaseIds: JSON.stringify(purchases)}});
		return result.data.HaveBanks;
	}

	public async updateExtraFields(purchase:Purchase, extraFields:PurchaseExtraFields):Promise<PurchaseExtraFields>{
		let result = await Axios.put(`${this.url}/extra-fields/${purchase.ExtraFieldsID}`, extraFields.getJSON());
		return new PurchaseExtraFields(result.data);
	}

	public async setDefaultExtraFieldValues():Promise<void>{
		await Axios.post(`${this.url}/set-default-extra-field-values`);
	}

	public async getInboxreaderPurchases():Promise<InboxreaderPurchase[]>{
		let result = await Axios.get(this.inboxreaderUrl);
		return (result.data || []).map((r:any)=>new InboxreaderPurchase(r));
	}

	public async getInboxreaderPurchase(id:number):Promise<InboxreaderPurchase>{
		let result = await Axios.get(`${this.inboxreaderUrl}/${id}`);
		return new InboxreaderPurchase(result.data);
	}

	public async deleteInboxreaderPurchase(id:number):Promise<void>{
		await Axios.delete(`${this.inboxreaderUrl}/${id}`);
	}

	public async deleteInboxreaderPurchaseFile(id:number, fileId:number):Promise<void>{
		await Axios.delete(`${this.inboxreaderUrl}/${id}/file/${fileId}`);
	}

	public async getInboxreaderPurchaseCount():Promise<number>{
		let result = await Axios.get(`${this.inboxreaderUrl}/count`);
		return result.data || 0;
	}

	public async getInboxReaderAlias():Promise<string>{
		let result = await Axios.get(`${this.inboxreaderUrl}/alias`);
		return result.data as string;
	}

	public async isInboxReaderAliasAvailable(alias:string):Promise<boolean>{
		let result = await Axios.get(`${this.inboxreaderUrl}/is-alias-available/${alias}`);
		return result.data as boolean;
	}

	public async setInboxReaderAlias(alias:string):Promise<string>{
		let result = await Axios.post(`${this.inboxreaderUrl}/set-alias/${alias}`);
		return result.data as string;
	}
};

export const PurchaseService = new PurchaseServiceClass();