
import { BrowseState } from "@/utils/browse/browse-state";
import Vue from "vue";
export default Vue.extend({
	props: {
		state: {
			required: true,
			type: Object as () => BrowseState
		}
	},
	computed: {
		total(): number {
			return this.state.queryResults.records;
		}
	},
	i18nEx: {
		componentPrefix: "components.browse"
	}
});
