import { FrontendFuncParam } from "./frontend-func-param";

export class FrontendFunc {
	Name:string = "newFunc";
	Code:string = "";
	Params:FrontendFuncParam[] = [];

	public constructor(data:any){
		this.Name = data.Name;
		this.Code = data.Code;
		this.Params = (data.Params || []).map((d:any)=>new FrontendFuncParam(d));
	}

}