import { Tier } from "./tiers";
import { PriceFrequencyType } from "@/models/payment/pricing";
import { PlanModulePricing } from "./plan-module-pricing";
import { PlanModulePricingFactory } from "./plan-module-pricing-factory";
import { AuthService } from "@/services/auth-service";

export class Plan {
	tier:Tier = Tier.STARTER;
	paymentFrequency:PriceFrequencyType = PriceFrequencyType.QUARTER;
	modules:PlanModulePricing[] = [];

	constructor(data?:any){
		if (!data){
			return;
		}
		this.tier = data.tier;
		this.paymentFrequency = data.paymentFrequency;
		this.modules = (data.modules || []).map((m:any)=>PlanModulePricingFactory.create(m));
	}

	public isValid():boolean {
		if (this.modules.length > 0 && this.tier != Tier.ENTERPRISE){
			return false;
		}
		return true;
	}

	public getJSON(){
		return {
			tier: this.tier,
			paymentFrequency: this.paymentFrequency,
			modules: this.modules.map(m=>m.getJSON()),
		};
	}

	public getBuyRoute():string{
		if (!AuthService.wfCompany.IsDemo && !AuthService.wfCompany.isExpired() && AuthService.wfCompany.Tier != Tier.FREE){
			return this.getUpgradeRoute();
		}
		let modules = JSON.stringify(this.modules.map(m=>m.getJSON()));
		return `/account/activate?tier=${this.tier}&modules=${modules}`;
	}

	public getUpgradeRoute():string{
		let modules = JSON.stringify(this.modules.map(m=>m.getJSON()));
		return `/account/upgrade?tier=${this.tier}&modules=${modules}`;
	}

	public addModule(m:PlanModulePricing) {
		for (let oldModule of this.modules) {
			if (oldModule.ModuleID == m.ModuleID) {
				oldModule.upgrade(m.MetaData);
				return;
			}
		}
		this.modules.push(m);
	}

}