import { ChatMessage } from "@/models/chat/chat-message";
import { ChatUnreadConversation } from "@/models/chat/chat-unread-conversation";

export class Conversation {
	ID: number = 0;
	UserID: number = 0;
	DmUserID: number = 0;
	ChannelID: number = 0;
	TimeStamp: Date = new Date();
	Messages: ChatMessage[] = [];
	ChatUnreadConversation: ChatUnreadConversation[] = [];


	constructor(data?: any) {
		if (!data) return;
		this.ID = data.ID;
		this.UserID = data.UserID;
		this.DmUserID = data.DmUserID;
		this.ChannelID = data.ChannelID;
		this.TimeStamp = new Date(data.TimeStamp * 1000);

		if (data.Messages) {
			this.Messages = data.Messages.map((m:any) => new ChatMessage(m));
		}

		if (data.ChatUnreadConversation) {
			this.ChatUnreadConversation = data.ChatUnreadConversation.map((p:any) => new ChatUnreadConversation(p));
		}

	}

	getJSON() {
		return {
			ID: this.ID,
			UserID: this.UserID,
			DmUserID: this.DmUserID,
			ChannelID: this.ChannelID,
			TimeStamp: this.TimeStamp,
			Messages: this.Messages,
			ChatUnreadConversation: this.ChatUnreadConversation,
		};
	}

}