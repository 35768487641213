import { ServerConfig } from "@/config/config";
import { Axios } from "@/utils/axios";
import { advancedSearch } from "@/utils/browse/browse";
import { IGetResponse } from "@/utils/get-response";
import { IQueryParamsWithOptionalView } from "@/utils/query-params";
import { View } from "@/models/view";
import { FetchedTotal } from "@/utils/views/fetched-total";
import Car from "@/models/base/car";
import { CarAwsDocument } from "@/models/base/car-aws-document";
import { getModel } from "@/utils/models/model";

export class CarServiceClass {
	get url(): string {
		return ServerConfig.host + "/car";
	}


	async getSettings() {
		let result = await Axios.get(ServerConfig.host + "/browse/13", {
			params: {}
		});
		return result.data;
	}
	async get(id:number): Promise<Car> {
		let result = await Axios.get(this.url + "/" + id);
		return new Car(result.data);
	}

	async getCars(query: IQueryParamsWithOptionalView): Promise<IGetResponse<Car>> {
		let result = await advancedSearch(query, this.url, getModel("Car"));
		result.data.data = result.data.data.map((s: any) => new Car(s));
		return result.data;
	}
	async getTotals(view:View):Promise<FetchedTotal[]>{
		let result = await Axios.get(`${this.url}/view/${view.ID}/totals`);
		return (result.data || []).map((d:any)=>new FetchedTotal(d));
	}

	async delete(car: Car[]): Promise<void> {
		await Axios.delete(this.url, {
			data: car.map(p => p.ID)
		});
	}

	async postCar(car: Car): Promise<Car> {
		let result = await Axios.post(`${this.url}`, car.getJSON());
		let c = new Car(result.data);
		for (let file of car.UnprocessedFiles){
			await this.uploadDocument(c,file);
		}
		return c;
	}

	async search(input:string):Promise<Car[]>{
		let result = await Axios.get(`${this.url}/search/${input}`);
		return  ((result.data || []) as any[]).map(c=>new Car(c));
	}

	async getCarsByIds(carIds:number[]):Promise<Car[]>{
		let result = await Axios.get(`${this.url}/by-ids`, {
			params: {
				carIds: JSON.stringify(carIds)
			}
		});
		return (result.data as any[]).map(d=>new Car(d));
	}

	async putCar(car: Car): Promise<Car> {
		let result = await Axios.put(`${this.url}`, car.getJSON());
		let c = new Car(result.data);
		for (let file of car.UnprocessedFiles){
			await this.uploadDocument(c,file);
		}
		for (let file of car.toDeleteFiles) {
			await this.deleteDocument(c, file);
		}
		return c;
	}

	async uploadDocuments(car: Car): Promise<Car>{
		if(car.UnprocessedFiles.length){
			for (let file of car.UnprocessedFiles){
				await this.uploadDocument(car,file);
			}
		}
		return this.get(car.ID);
	}

	async deleteDocument(car:Car, document:CarAwsDocument){
		await Axios.delete(`${this.url}/${car.ID}/document/${document.ID}`);
	}

	public async uploadDocument(car:Car, file:File):Promise<CarAwsDocument>{
		let data = new FormData();
		data.append("file", file, file.name);
		let result = await Axios.post(`${this.url}/${car.ID}/document`, data);
		return new CarAwsDocument(result);
	}

};


export const CarService = new CarServiceClass();