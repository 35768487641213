
import Vue from "vue";
import { HumanFilter } from "@/utils/human-filter";
import { v4 } from "uuid";

export default Vue.extend({
	data(){
		return {
			id: v4()
		};
	},
	props: {
		value: {
			type: Object as ()=>HumanFilter,
			required: true
		},
	},
	computed: {
		computedValue(): any {
			return this.value.Values[0] == "1" || this.value.Values[0] == "true";
		},
		radioName():string{
			return `radio-${this.id}`;
		},
	},
	methods: {
		onBooleanInput(value:boolean){
			this.value.Values[0] = `${value}`;
		},
	},
	i18nEx: {
		componentPrefix: "components.models"
	}
});
