
import { BrowseStateBase } from "@/utils/browse/browse-state-base";
import Vue from "vue";
import { ISelectItem } from "winfakt-vue-components";
export default Vue.extend({
	props: {
		state: {
			type: Object as ()=>BrowseStateBase,
			required: true
		},
	},
	computed: {
		isRight():boolean{
			return this.mouseX > (document.body.clientWidth / 2);
		},
		x():number {
			if (!this.isRight){
				return this.mouseX;
			}
			return document.body.clientWidth - this.mouseX;
		},
		hasContextMenu():boolean {
			return this.state.contextMenu.items.length > 0;
		},
		opened():boolean {
			return this.state.contextMenu.opened && this.contextMenuItems.length > 0;
		},
		mouseX():number {
			return this.state.contextMenu.mouseX;
		},
		mouseY():number{
			return this.state.contextMenu.mouseY;
		},
		contextMenuItems():ISelectItem<string | Function>[] {
			return this.state.getContextMenuItems();
		},
	},
	methods: {
		onInput(value:Boolean){
			this.$emit("input", value);
		},
		onClick(value:string | Function){
			if (typeof(value) == "function") {
				value(this.state.contextMenu.item);
			}else{
				this.state.contextMenu.trigger(value);
			}
		},
		onMouseDown(event:MouseEvent){
			if (event.button != 0) return;
			if (!event.target) return;
			let listRef = this.$refs.list as Vue;
			if (!listRef) return;
			let listEl = listRef.$el;
			let target = event.target as HTMLElement;
			if (!listEl.contains(target)) {
				this.setContextMenuOpened(false);
			}
		},
		setContextMenuOpened(value:boolean){
			this.state.contextMenu.opened = value;
		}
	},
	created(){
		window.addEventListener("mousedown", this.onMouseDown);
	},
	destroyed(){
		window.removeEventListener("mousedown", this.onMouseDown);
	}
});
