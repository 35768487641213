import { TicketMessage } from "@/models/tickets/message";
import { TicketUnreadUser } from "@/models/tickets/unread-user";
import { AuthService } from "@/services/auth-service";
import { AuthSocketService } from "@/services/auth-socket-service";

export const TICKET_STATUS_HANDLED = 2;
export const TICKET_STATUS_CLOSED = 5;

export class Ticket {
	id:number = 0;
	clientId:number = 0;
	priority:number = 1;
	timeLastMessage:Date = new Date();
	timeCreated:Date = new Date();
	subject:string = "";
	statusId:number = 0;
	messages:TicketMessage[] = [];
	unreadUsers:TicketUnreadUser[] = [];
	departmentId:number = 3;

	constructor(data?:any) {
		if (!data) return;
		this.id = data.id;
		this.clientId = data.clientId;
		this.timeCreated = new Date(data.timeCreated);
		this.timeLastMessage = new Date(data.timeLastMessage);
		this.subject = data.subject;
		this.statusId = data.statusId;
		this.priority = data.priority;
		if(data.messages) {
			this.messages = data.messages.map((d:any) => new TicketMessage(d));
			this.sortMessages();
		}
		if (data.unreadUsers) {
			this.unreadUsers = data.unreadUsers.map((d:any) => new TicketUnreadUser(d));
		}
		this.departmentId = data.departmentId;
	}

	sortMessages() {
		this.messages = this.messages.sort((a, b) => {
			return a.timeCreated.getTime() - b.timeCreated.getTime();
		});
	}

	isRead():boolean {
		if (!AuthService.wfUser){
			return false;
		}
		for (let user of this.unreadUsers) {
			if (user.userId == AuthService.wfUser.ID) {
				return false;
			}
		}
		return true;
	}


	registerSocketEvents() {
		this.deregisterSocketEvents();
		AuthSocketService.on(`/ticket/${this.id}/unread`, (data:any) => {
			this.unreadUsers.push(new TicketUnreadUser(data));
		}, this);
		AuthSocketService.on(`/ticket/${this.id}/update`, (data:any) => {
			this.clientId = data.clientId;
			this.subject = data.subject;
			this.statusId = data.statusId;
			this.timeLastMessage = new Date(data.timeLastMessage);
		}, this);
		AuthSocketService.on(`/ticket/${this.id}/message-deleted`, (data:number)=>{
			let i = this.messages.findIndex(m=>m.id == data);
			if (i!=-1){
				this.messages.splice(i, 1);
			}
		});
		AuthSocketService.on(`/ticket/${this.id}/message`, (data:any) => {
			console.log("Ticket message");
			let message = new TicketMessage(data);
			message.registerSocketEvents();
			this.messages.push(message);
			this.sortMessages();
		}, this);
		AuthSocketService.on(`/ticket/${this.id}/read`, (data:any) => {
			let unreadUser = new TicketUnreadUser(data);
			for (let i = this.unreadUsers.length - 1; i >= 0; i--) {
				let unUser = this.unreadUsers[i];
				if (unUser && unUser.id == unreadUser.id) {
					this.unreadUsers.splice(i, 1);
				}
			}
		}, this);
		this.messages.forEach(m => m.registerSocketEvents());
	}

	deregisterSocketEvents() {
		AuthSocketService.offAll(this);
		this.messages.forEach(m => {
			m.deregisterSocketEvents();
		});
	}
}