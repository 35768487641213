
import { MODULE } from "@/config/modules";
import { Product } from "@/models/base";
import { AuthService } from "@/services";
import Vue from "vue";
export default Vue.extend({
	props: {
		product: {
			type: Object as ()=>Product,
			required: true
		},
		priceCategory: {
			type: Number,
			required: true
		}
	},
	computed: {
		startDate():Date | null {
			if (!this.product.PriceCategorySalesDates) return null;
			return this.product.PriceCategorySalesDates.getStartDateByPriceCategory(this.priceCategory);
		},
		endDate():Date | null {
			if (!this.product.PriceCategorySalesDates) return null;
			return this.product.PriceCategorySalesDates.getEndDateByPriceCategory(this.priceCategory);
		},
		isEnabled():boolean {
			if (!this.startDate || !this.endDate) {
				return false;
			}
			return true;
		},
		hasModule():boolean {
			return AuthService.hasModule(MODULE.PRIJSBEDING);
		}
	},
	methods: {
		setStartDate(date:Date){
			this.product.PriceCategorySalesDates.setStartDateByPriceCategory(this.priceCategory, date);
		},
		setEndDate(date:Date){
			this.product.PriceCategorySalesDates.setEndDateByPriceCategory(this.priceCategory, date);
		},
		toggle(){
			if (!this.isEnabled){
				this.enableSalesDates();
			}else{
				this.disableSalesDates();
			}
		},
		disableSalesDates(){
			this.product.PriceCategorySalesDates.setStartDateByPriceCategory(this.priceCategory, null);
			this.product.PriceCategorySalesDates.setEndDateByPriceCategory(this.priceCategory, null);
		},
		enableSalesDates(){
			this.product.PriceCategorySalesDates.setStartDateByPriceCategory(this.priceCategory, new Date());
			this.product.PriceCategorySalesDates.setEndDateByPriceCategory(this.priceCategory, new Date());
		}
	},
	i18nEx: {
		componentPrefix: "components.products"
	}
});
