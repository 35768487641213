
export enum PrintContext {
	PRINT = "print",
	EMAIL_SEND = "email-send",
	DOWNLOAD = "download"
}

export class SalePrintSettings {
	context:PrintContext = PrintContext.DOWNLOAD;
	isDuplicate:boolean = false;
	layoutId:number = 0;
	// Overrides copy count setting and only prints 1 copy in electron
	forceSingleCoppy:boolean = false;

	public constructor(context:PrintContext){
		this.context = context;
	}

	getHttpQuery():any {
		return {
			createContext: this.context,
			duplicate: `${this.isDuplicate}`
		};
	}

	getHttpQueryAsString():string{
		return `createContext=${this.context}&duplicate=${this.isDuplicate}`;
	}
}