import { ComputedOneOffPricing } from "./computed-one-off-pricing";

export class ComputedRecuringPricing extends ComputedOneOffPricing {
	months:number = 0.0;
	pricePerMonth:number = 0.0;
	priceWithDiscountPerMonth:number = 0.0;
	vatPerMonth:number = 0.0;
	totalPerMonth:number = 0.0;

	public constructor(data:any){
		super(data);
		this.months = data.months;
		this.pricePerMonth = data.pricePerMonth;
		this.priceWithDiscountPerMonth = data.priceWithDiscountPerMonth;
		this.vatPerMonth = data.vatPerMonth;
		this.totalPerMonth = data.totalPerMonth;
	}

}
