
import AppToolbarButton from "@/components/common/ToolbarButton.vue";
import AppSelectDossier from "@/components/SelectDossier.vue";
import { AuthService } from "@/services/auth-service";
import { ToolbarService } from "@/services/toolbar-service";
import Vue from "vue";
import { UserInfo } from "../../models/dossier/user-info";
import { tokenStore } from "../../utils/token-store";


export default Vue.extend({
	data() {
		return {
			changeDossierModal: false,
			ToolbarService,
			AuthService,
		};
	},
	props: {
		drawerButtonDisabled: Boolean,
	},
	computed: {
		options(): any[] {
			let data = [
				{value: "account", text: this.$ct("account").toString(),icon: "user"},
				{value: "settings", text: this.$ct("settings").toString(),icon: "cog"},
				{value: "logout", text: this.$ct("logout").toString(),icon: "sign-out-alt"}
			];
			if (tokenStore.accessTokens.length > 1) {
				data.splice(1, 0, {
					value: "changeDossier",
					text: `${this.$ct("change-dossier").toString()}`,
					icon: "archive"
				});
			}
			return data;
		},
		wfUser(): UserInfo | null {
			return AuthService.wfUser;
		},
		currentDossier(): string {
			return AuthService.wfDossier.name;
		},
		profileImgUrl(): string {
			return this.AuthService.wfUser.getProfileImgUrl();
		}
	},
	watch: {
		"$route.path"() {
			ToolbarService.title = "";
		},
	},
	methods: {
		logout() {
			AuthService.logout();
		},
		account() {
			this.$router.push("/account");
		},
		selectClicked(e: string) {
			if (typeof ((this as any)[e]) == "function") {
				(this as any)[e]();
			} else {
				this.$router.push("/"+e);
			}
		},
		changeDossier() {
			this.changeDossierModal = true;
		},
		toggleDrawer() {
			ToolbarService.drawer = !ToolbarService.drawer;
		}
	},
	components: {
		AppSelectDossier,
		AppToolbarButton
	},
	i18nEx: {
		componentPrefix: "views.TheToolbar"
	}
});
