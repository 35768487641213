
import Vue from "vue";
import AppInputWithTextareaModal from "@/components/common/InputWithTextareaModal.vue";
import { ExtraFieldsTextInputType, ExtraFieldTextConfig } from "@/models/dossier-configs/extra-field-text-config";
import { ISelectItem } from "winfakt-vue-components";
import AppContactSearch from "@/components/contacts/ContactSearchWithBrowse.vue";
import {Contact} from "@/models/base";


export default Vue.extend({
	props: {
		config: {
			type: Object as ()=>ExtraFieldTextConfig,
			required: true
		},
		value: {
			type: String,
			required: true
		},
		label: {
			type: String,
			required: true
		}
	},
	computed: {
		isDefault():boolean {
			return this.config.InputType == ExtraFieldsTextInputType.DEFAULT;
		},
		isAutocomplete():boolean {
			return this.config.InputType == ExtraFieldsTextInputType.AUTOCOMPLETE;
		},
		isContactAutoComplete():boolean {
			return this.config.InputType == ExtraFieldsTextInputType.CONTACT_AUTOCOMPLETE;
		},
		isSelect():boolean {
			return this.config.InputType == ExtraFieldsTextInputType.SELECT;
		},
		isMultiSelect():boolean {
			return this.config.InputType == ExtraFieldsTextInputType.MULTI_SELECT;
		},
		allAutocompleteItems():string[]{
			return this.config.InputMetaData.split("\n").filter(s=>s);
		},
		filteredAutocompleteItems():string[]{
			return this.allAutocompleteItems.filter(i=>i.toLowerCase().trim().startsWith(this.value.toLocaleLowerCase().trim()));
		},
		selectItems():ISelectItem<string>[]{
			return ExtraFieldTextConfig.metadataToSelectItemList(this.config.InputMetaData);
		},
		multiSelectValue():string[]{
			let v = this.value;
			while(v[0] == ";") {
				if (v[0] == ";"){
					v = v.substring(1);
				}
			}
			while(v[v.length-1] == ";"){
				if (v[v.length-1] == ";"){
					v = v.substring(0, v.length-1);
				}
			}
			return v.split(";");
		}
	},
	methods: {
		onValue(value:string){
			this.$emit("input", value);
		},
		setMultiSelectValue(value:string[]) {
			if (value.length == 0){
				this.$emit("input", "");
			}
			this.$emit("input", ";"+value.join(";")+";");
		},
		setContactValue(contact:Contact){
			this.$emit("input",contact.ComputedFriendlyID + " " + contact.MainAddress?.CompanyOrFullName);
		},
		clearValue(){
			this.$emit("input","");
		}
	},
	components: {
		AppInputWithTextareaModal,
		AppContactSearch
	}
});
