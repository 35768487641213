import { SocketMainThread } from "./socket-main-thread";
import { SocketServiceBase } from "./socket-service-base";
import { SocketWorkerInterface } from "./socket-worker-interface";

export function createSocketService(host:string, path:string):SocketServiceBase{
	if ((window as any).SharedWorker){
		console.log("Browser supports SharedWorker");
		return new SocketWorkerInterface(host, path);
	}
	console.warn("Browser does not support SharedWorkers. Falling back to a socket on the main thread. Multi-tabs is dissabled.");
	return new SocketMainThread(host, path);
}