
import { BookYearService } from "@/services/book-year-service";
import { ISelectItem } from "winfakt-vue-components/src/classes";
import Vue from "vue";
import { BookYear } from "@/models/book-year";


export default Vue.extend({
	data(){
		return {
			bookYears: [] as BookYear[]
		};
	},
	props: {
		value: {
			type: Array as ()=>String[],
			required: true
		},
		disabled: Boolean,
		label: String,
		fancy: Boolean,
		includeBookYear0: Boolean,
		addSelectedBookYearOption: Boolean,
		includeSelectAllOption: Boolean
	},
	computed: {
		items():ISelectItem<string>[]{
			let result = this.bookYears.map(b=>{
				return {
					text: `${b.Year}`,
					value: `${b.Year}`
				};
			});
			result.reverse();
			if (this.includeBookYear0) {
				result.unshift({
					text: "0",
					value: "0"
				});
			}
			if (this.addSelectedBookYearOption) {
				result.unshift({
					text: this.$ct("common.use-selected-bookyear").toString(),
					value: BookYear.SelectedBookYearValue
				});
			}
			return result;
		}
	},
	methods: {
		onInput(value:string[]){
			this.$emit("input", value);
		}
	},
	async created(){
		this.bookYears = await BookYearService.fetchBookYears();
	}
});
