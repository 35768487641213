import { Condition } from "../condition/condition";
import { DocumentLayoutLogoPosition } from "../documents/layout-logo";
import { DocumentLayoutLogoBase } from "../documents/layout-logo-base";

export type SaleLayoutLogoPosition = DocumentLayoutLogoPosition | "Requirements" | string

export class SaleLayoutLogo extends DocumentLayoutLogoBase {
	public Placement: SaleLayoutLogoPosition = "Heading";

	HideOnPdfOnPrint:boolean = false;
	HideOnPdfOnEmailSend:boolean = false;
	HideOnPdfOnDownload:boolean = false;
	Conditions:Condition[] = [];

	public constructor(data?:any){
		super(data);
		if (!data) return;
		this.Placement = data.Placement;
		this.HideOnPdfOnPrint = data.HideOnPdfOnPrint || false;
		this.HideOnPdfOnEmailSend = data.HideOnPdfOnEmailSend || false;
		this.HideOnPdfOnDownload = data.HideOnPdfOnDownload || false;
		this.Conditions = (data.Conditions || []).map((c:any)=>new Condition(c));
	}

	public getJSON(){
		let d:any = super.getJSON();
		d.HideOnPdfOnPrint = this.HideOnPdfOnPrint;
		d.HideOnPdfOnEmailSend = this.HideOnPdfOnEmailSend;
		d.HideOnPdfOnDownload = this.HideOnPdfOnDownload;
		d.Conditions = this.Conditions.map(c=>c.getJSON());
		d.Placement = this.Placement;
		return d;
	}

	public getExtraSettingsComponent():string{
		return "app-sale-layout-logo-extra-settings-modal";
	}
}