export const NotificationTypeNotify = "notify";
export const NotificationTypeError = "error";

export class Notification {
	ID:number = 0;
	Date:Date = new Date();
	Type:string = "";
	Title:string = "";
	Message:string = "";
	UserID:number = 0;
	IsRead:boolean = false;
	Data:any = {};
	DeletionDate:Date | null = null;
	HideForClient:boolean = false;

	constructor(data:any) {
		if (!data) return;
		this.ID = data.ID;
		this.Date = new Date(data.Date * 1000);
		this.Type = data.Type;
		this.Title = data.Title;
		this.Message = data.Message;
		this.UserID = data.UserID;
		this.IsRead = data.IsRead;
		this.Data = data.Data;
		this.HideForClient = data.HideForClient;
		if (data.DeletionDate) {
			this.DeletionDate = new Date(data.DeletionDate);
		}
	}
}