import { Condition } from "../condition/condition";
import { HumanFilter } from "../human-filter";
import { Model } from "../models/model";
import { filterFieldPathToBrowseFieldPath } from "../models/utils";
import { Translation } from "../translation";
import { TotalColumn } from "./total-column";
import { TotalStyle } from "./total-style";

export class ViewTotal {
	public Columns:TotalColumn[] = [new TotalColumn()];
	public Label:Translation = new Translation({"nl-be": "{value}", en: "{value}", fr: "{value}"});
	public Style:TotalStyle = new TotalStyle();
	public Filters:HumanFilter[] = [];
	public Preloads:string[] = [];
	public Conditions:Condition[] = [];

	public constructor(data?:any){
		if (!data) return;
		if (data.Columns !== undefined) {
			this.Columns = (data.Columns || []).map((c:any)=>new TotalColumn(c));
		}
		if (data.Label){
			this.Label = new Translation(data.Label);
		}
		if (data.Style) {
			this.Style = new TotalStyle(data.Style);
		}
		if (data.Filters) {
			this.Filters = (data.Filters || []).map((f:any)=>new HumanFilter(f));
		}
		if (data.Conditions){
			this.Conditions = (data.Conditions || []).map((c:any)=>new Condition(c));
		}
	}

	public computePreloads(){
		let cols =this.Columns.map((c) => {
			return filterFieldPathToBrowseFieldPath(c.Column).split(".");
		}).concat(this.Filters.map((f: HumanFilter) => {
			return filterFieldPathToBrowseFieldPath(f.Field).split(".");
		}));
		console.log(cols);
		let preloads = cols.filter((c) => {
			return c.length > 1;
		}).map((c: string[]) => {
			c.pop();
			return c.join(".");
		});
		this.Preloads = preloads.filter(p=>p!="");
		this.Preloads = preloads.filter((p, i) => preloads.indexOf(p) == i);
	}

	public getJSON(model:Model):any{
		this.computePreloads();
		return {
			Columns: this.Columns.map(c=>c.getJSON()),
			Label: this.Label.getJSON(),
			Style: this.Style.getJSON(),
			Filters: this.Filters.map(f=>f.getProcessedJson(model)),
			Preloads: [...this.Preloads],
			Conditions: this.Conditions.map(c=>c.getJSON())
		};
	}
}