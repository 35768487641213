
import { HumanFilter } from "@/utils/human-filter";
import { Model } from "@/utils/models/model";
import Vue from "vue";
import { TranslateResult } from "vue-i18n";
import AppHumanFilterEditValueDefaultColumn from "./HumanFilterEditValueDefaultColumn.vue";
import AppFilterInput from "./FilterInput.vue";

export default Vue.extend({
	data(){
		return {
			useFieldValue: false,
		};
	},
	props: {
		filter: {
			type: Object as ()=>HumanFilter,
			required: true
		},
		label: {
			type: [String, Object as ()=> TranslateResult]
		},
		fieldType: {
			type: String,
			required: true
		},
		model: {
			type: Object as ()=>Model,
		},
		valueIndex: {
			type: Number,
			required: true
		}
	},
	computed: {
		showUseTodaysDate():boolean {
			if (this.fieldType != "date") return false;
			if (HumanFilter.isRelativeDateOperator(this.filter.Operator)) return false;
			return true;
		},
		valueAmount(): number {
			return this.filter.getValueAmount();
		},
		inputDisabled():boolean {
			if (this.showUseTodaysDate){
				return this.isUseTodaysDateSelected();
			}
			return false;
		},
		useTodaysDateEnabled():boolean {
			if (!this.showUseTodaysDate) return false;
			if (this.filter.UseFieldValues[this.valueIndex]) return false;
			return true;
		},
	},
	watch: {
		useTodaysDateEnabled(){
			this.setUseTodaysDateSelected(false);
		},
		filter: {
			immediate: true,
			deep: true,
			handler(){
				this.useFieldValue = this.filter.UseFieldValues[this.valueIndex] || false;
			}
		}
	},
	methods: {
		isUseTodaysDateSelected():boolean{
			return this.filter.hasOption(HumanFilter.getReplaceWithTodaysDateOption(this.valueIndex));
		},
		setUseTodaysDateSelected(val:boolean) {
			if (!val){
				this.filter.removeOption(HumanFilter.getReplaceWithTodaysDateOption(this.valueIndex));
			}else{
				this.filter.addUniqueOption(HumanFilter.getReplaceWithTodaysDateOption(this.valueIndex));
			}
		},
		setUseFieldValue(val:boolean){
			let i = this.valueIndex;
			this.filter.UseFieldValues[i] = val;
			for (let index = this.filter.Options.length -1; index >= 0; index--) {
				let option = this.filter.Options[index];
				if (option.startsWith("replace")) {
					let parts = option.split(" ");
					if (parts.length < 3) {
						continue;
					}
					if (parts[1] != `${i}`) {
						continue;
					}
					this.filter.Options.splice(index, 1);
				}
			}
			this.useFieldValue = this.filter.UseFieldValues[i];
		},
		removeValue() {
			this.filter.Values.splice(this.valueIndex, 1);
			this.filter.UseFieldValues.splice(this.valueIndex, 1);
		},
	},
	components: {
		AppFilterInput,
		AppHumanFilterEditValueDefaultColumn
	},
	i18nEx: {
		componentPrefix: "components.models"
	}
});
