import { CustomTranslation } from "../custom-translation";
import { StringStyledProperty } from "./string-styled-property";

export class StringStyledPropertyWithFreeLine extends StringStyledProperty{
	FreeLineText:CustomTranslation = new CustomTranslation();

	public constructor(data?:any){
		super(data);
		if (!data) return;
		this.FreeLineText = new CustomTranslation(data.FreeLineText);
	}

	public getJSON(){
		return {
			...super.getJSON(),
			FreeLineText: this.FreeLineText.getJSON()
		};
	}
}