export class UblUserConfig {
	SendUBLInvoice:boolean = false;

	constructor(data?:any){
		if (!data) return;
		this.SendUBLInvoice = data.SendUBLInvoice;
	}

	getJSON(){
		return {
			SendUBLInvoice: this.SendUBLInvoice
		};
	}
}