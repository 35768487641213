import { TextStyledProperty } from "./text-styled-property";
export class StringStyledProperty {
	public Value: string = "";
	public Style: TextStyledProperty = new TextStyledProperty;
	constructor(data?: any) {
		if (!data) {
			return;
		}
		this.Value = data.Value;
		this.Style = new TextStyledProperty(data.Style);
	}
	public getJSON() {
		return {
			Value: this.Value,
			Style: this.Style,
		};
	}
}
