import { MODULE } from "@/config/modules";
import { IModuleMetaData, Module } from "./module";
import { ModuleExtraUsers } from "./module-extra-users";
import { ModuleOnlineBackup } from "./module-online-backup";

export class ModuleFactory {
	public static create(data:any, metadata?:IModuleMetaData):Module {
		if (data.ID == MODULE.EXTRA_USERS) {
			return new ModuleExtraUsers(data, metadata);
		}
		if (data.ID == MODULE.ONLINE_BACKUP) {
			return new ModuleOnlineBackup(data, metadata);
		}
		return new Module(data, metadata);
	}
}