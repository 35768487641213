export class TaskUnreadUser {
	ID:number = 0;
	TaskID:number = 0;
	UserID:number = 0;

	constructor(data?:any) {
		if (!data) return;
		this.ID = data.ID;
		this.TaskID = data.TaskID;
		this.UserID = data.UserID;
	}
}