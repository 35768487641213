import { ConditionPropertyType } from "@/utils/condition/condition";
import { getAllExtraFieldKeyNames, getExtraFieldCount, IExtraFields } from "@/utils/models/extra-fields";
import { ExtraFieldBooleanConfig } from "./extra-field-boolean-config";
import { ExtraFieldNumberConfig } from "./extra-field-number-config";
import { ExtraFieldTextConfig } from "./extra-field-text-config";
import { ExtraFieldTimeConfig } from "./extra-field-time-config";
import { ExtraFieldBaseConfig } from "./extra-field-base-config";
import { ISelectItem } from "winfakt-vue-components";
import { i18n } from "@/setup/i18n-setup";
import { AuthService } from "@/services";
import { MODULE } from "@/config/modules";

/* eslint-disable max-lines-per-function */
export class ExtraFieldNamesConfig {
	HideEditInBrowse:boolean = false;

	Num1: string = "";
	Num2: string = "";
	Num3: string = "";
	Num4: string = "";
	Num5: string = "";
	Num6: string = "";
	Num7: string = "";
	Num8: string = "";
	Num9: string = "";
	Num10: string = "";
	Num11: string = "";
	Num12: string = "";
	Num13: string = "";
	Num14: string = "";
	Num15: string = "";
	Num16: string = "";
	Num17: string = "";
	Num18: string = "";
	Num19: string = "";
	Num20: string = "";

	Text1: string = "";
	Text2: string = "";
	Text3: string = "";
	Text4: string = "";
	Text5: string = "";
	Text6: string = "";
	Text7: string = "";
	Text8: string = "";
	Text9: string = "";
	Text10: string = "";
	Text11: string = "";
	Text12: string = "";
	Text13: string = "";
	Text14: string = "";
	Text15: string = "";
	Text16: string = "";
	Text17: string = "";
	Text18: string = "";
	Text19: string = "";
	Text20: string = "";

	Bool1: string = "";
	Bool2: string = "";
	Bool3: string = "";
	Bool4: string = "";
	Bool5: string = "";
	Bool6: string = "";
	Bool7: string = "";
	Bool8: string = "";
	Bool9: string = "";
	Bool10: string = "";
	Bool11: string = "";
	Bool12: string = "";
	Bool13: string = "";
	Bool14: string = "";
	Bool15: string = "";
	Bool16: string = "";
	Bool17: string = "";
	Bool18: string = "";
	Bool19: string = "";
	Bool20: string = "";

	Time1: string = "";
	Time2: string = "";
	Time3: string = "";
	Time4: string = "";
	Time5: string = "";
	Time6: string = "";
	Time7: string = "";
	Time8: string = "";
	Time9: string = "";
	Time10: string = "";
	Time11: string = "";
	Time12: string = "";
	Time13: string = "";
	Time14: string = "";
	Time15: string = "";
	Time16: string = "";
	Time17: string = "";
	Time18: string = "";
	Time19: string = "";
	Time20: string = "";


	Num1Config: ExtraFieldNumberConfig = new ExtraFieldNumberConfig();
	Num2Config: ExtraFieldNumberConfig = new ExtraFieldNumberConfig();
	Num3Config: ExtraFieldNumberConfig = new ExtraFieldNumberConfig();
	Num4Config: ExtraFieldNumberConfig = new ExtraFieldNumberConfig();
	Num5Config: ExtraFieldNumberConfig = new ExtraFieldNumberConfig();
	Num6Config: ExtraFieldNumberConfig = new ExtraFieldNumberConfig();
	Num7Config: ExtraFieldNumberConfig = new ExtraFieldNumberConfig();
	Num8Config: ExtraFieldNumberConfig = new ExtraFieldNumberConfig();
	Num9Config: ExtraFieldNumberConfig = new ExtraFieldNumberConfig();
	Num10Config: ExtraFieldNumberConfig = new ExtraFieldNumberConfig();
	Num11Config: ExtraFieldNumberConfig = new ExtraFieldNumberConfig();
	Num12Config: ExtraFieldNumberConfig = new ExtraFieldNumberConfig();
	Num13Config: ExtraFieldNumberConfig = new ExtraFieldNumberConfig();
	Num14Config: ExtraFieldNumberConfig = new ExtraFieldNumberConfig();
	Num15Config: ExtraFieldNumberConfig = new ExtraFieldNumberConfig();
	Num16Config: ExtraFieldNumberConfig = new ExtraFieldNumberConfig();
	Num17Config: ExtraFieldNumberConfig = new ExtraFieldNumberConfig();
	Num18Config: ExtraFieldNumberConfig = new ExtraFieldNumberConfig();
	Num19Config: ExtraFieldNumberConfig = new ExtraFieldNumberConfig();
	Num20Config: ExtraFieldNumberConfig = new ExtraFieldNumberConfig();

	Text1Config:ExtraFieldTextConfig = new ExtraFieldTextConfig();
	Text2Config:ExtraFieldTextConfig = new ExtraFieldTextConfig();
	Text3Config:ExtraFieldTextConfig = new ExtraFieldTextConfig();
	Text4Config:ExtraFieldTextConfig = new ExtraFieldTextConfig();
	Text5Config:ExtraFieldTextConfig = new ExtraFieldTextConfig();
	Text6Config:ExtraFieldTextConfig = new ExtraFieldTextConfig();
	Text7Config:ExtraFieldTextConfig = new ExtraFieldTextConfig();
	Text8Config:ExtraFieldTextConfig = new ExtraFieldTextConfig();
	Text9Config:ExtraFieldTextConfig = new ExtraFieldTextConfig();
	Text10Config:ExtraFieldTextConfig = new ExtraFieldTextConfig();
	Text11Config:ExtraFieldTextConfig = new ExtraFieldTextConfig();
	Text12Config:ExtraFieldTextConfig = new ExtraFieldTextConfig();
	Text13Config:ExtraFieldTextConfig = new ExtraFieldTextConfig();
	Text14Config:ExtraFieldTextConfig = new ExtraFieldTextConfig();
	Text15Config:ExtraFieldTextConfig = new ExtraFieldTextConfig();
	Text16Config:ExtraFieldTextConfig = new ExtraFieldTextConfig();
	Text17Config:ExtraFieldTextConfig = new ExtraFieldTextConfig();
	Text18Config:ExtraFieldTextConfig = new ExtraFieldTextConfig();
	Text19Config:ExtraFieldTextConfig = new ExtraFieldTextConfig();
	Text20Config:ExtraFieldTextConfig = new ExtraFieldTextConfig();

	Bool1Config: ExtraFieldBooleanConfig = new ExtraFieldBooleanConfig();
	Bool2Config: ExtraFieldBooleanConfig = new ExtraFieldBooleanConfig();
	Bool3Config: ExtraFieldBooleanConfig = new ExtraFieldBooleanConfig();
	Bool4Config: ExtraFieldBooleanConfig = new ExtraFieldBooleanConfig();
	Bool5Config: ExtraFieldBooleanConfig = new ExtraFieldBooleanConfig();
	Bool6Config: ExtraFieldBooleanConfig = new ExtraFieldBooleanConfig();
	Bool7Config: ExtraFieldBooleanConfig = new ExtraFieldBooleanConfig();
	Bool8Config: ExtraFieldBooleanConfig = new ExtraFieldBooleanConfig();
	Bool9Config: ExtraFieldBooleanConfig = new ExtraFieldBooleanConfig();
	Bool10Config: ExtraFieldBooleanConfig = new ExtraFieldBooleanConfig();
	Bool11Config: ExtraFieldBooleanConfig = new ExtraFieldBooleanConfig();
	Bool12Config: ExtraFieldBooleanConfig = new ExtraFieldBooleanConfig();
	Bool13Config: ExtraFieldBooleanConfig = new ExtraFieldBooleanConfig();
	Bool14Config: ExtraFieldBooleanConfig = new ExtraFieldBooleanConfig();
	Bool15Config: ExtraFieldBooleanConfig = new ExtraFieldBooleanConfig();
	Bool16Config: ExtraFieldBooleanConfig = new ExtraFieldBooleanConfig();
	Bool17Config: ExtraFieldBooleanConfig = new ExtraFieldBooleanConfig();
	Bool18Config: ExtraFieldBooleanConfig = new ExtraFieldBooleanConfig();
	Bool19Config: ExtraFieldBooleanConfig = new ExtraFieldBooleanConfig();
	Bool20Config: ExtraFieldBooleanConfig = new ExtraFieldBooleanConfig();

	Time1Config: ExtraFieldTimeConfig = new ExtraFieldTimeConfig();
	Time2Config: ExtraFieldTimeConfig = new ExtraFieldTimeConfig();
	Time3Config: ExtraFieldTimeConfig = new ExtraFieldTimeConfig();
	Time4Config: ExtraFieldTimeConfig = new ExtraFieldTimeConfig();
	Time5Config: ExtraFieldTimeConfig = new ExtraFieldTimeConfig();
	Time6Config: ExtraFieldTimeConfig = new ExtraFieldTimeConfig();
	Time7Config: ExtraFieldTimeConfig = new ExtraFieldTimeConfig();
	Time8Config: ExtraFieldTimeConfig = new ExtraFieldTimeConfig();
	Time9Config: ExtraFieldTimeConfig = new ExtraFieldTimeConfig();
	Time10Config: ExtraFieldTimeConfig = new ExtraFieldTimeConfig();
	Time11Config: ExtraFieldTimeConfig = new ExtraFieldTimeConfig();
	Time12Config: ExtraFieldTimeConfig = new ExtraFieldTimeConfig();
	Time13Config: ExtraFieldTimeConfig = new ExtraFieldTimeConfig();
	Time14Config: ExtraFieldTimeConfig = new ExtraFieldTimeConfig();
	Time15Config: ExtraFieldTimeConfig = new ExtraFieldTimeConfig();
	Time16Config: ExtraFieldTimeConfig = new ExtraFieldTimeConfig();
	Time17Config: ExtraFieldTimeConfig = new ExtraFieldTimeConfig();
	Time18Config: ExtraFieldTimeConfig = new ExtraFieldTimeConfig();
	Time19Config: ExtraFieldTimeConfig = new ExtraFieldTimeConfig();
	Time20Config: ExtraFieldTimeConfig = new ExtraFieldTimeConfig();

	constructor(data?: any) {
		if (!data) return;
		this.HideEditInBrowse = data.HideEditInBrowse;

		this.Num1 = data.Num1;
		this.Num2 = data.Num2;
		this.Num3 = data.Num3;
		this.Num4 = data.Num4;
		this.Num5 = data.Num5;
		this.Num6 = data.Num6;
		this.Num7 = data.Num7;
		this.Num8 = data.Num8;
		this.Num9 = data.Num9;
		this.Num10 = data.Num10;
		this.Num11 = data.Num11;
		this.Num12 = data.Num12;
		this.Num13 = data.Num13;
		this.Num14 = data.Num14;
		this.Num15 = data.Num15;
		this.Num16 = data.Num16;
		this.Num17 = data.Num17;
		this.Num18 = data.Num18;
		this.Num19 = data.Num19;
		this.Num20 = data.Num20;

		this.Text1 = data.Text1;
		this.Text2 = data.Text2;
		this.Text3 = data.Text3;
		this.Text4 = data.Text4;
		this.Text5 = data.Text5;
		this.Text6 = data.Text6;
		this.Text7 = data.Text7;
		this.Text8 = data.Text8;
		this.Text9 = data.Text9;
		this.Text10 = data.Text10;
		this.Text11 = data.Text11;
		this.Text12 = data.Text12;
		this.Text13 = data.Text13;
		this.Text14 = data.Text14;
		this.Text15 = data.Text15;
		this.Text16 = data.Text16;
		this.Text17 = data.Text17;
		this.Text18 = data.Text18;
		this.Text19 = data.Text19;
		this.Text20 = data.Text20;

		this.Bool1 = data.Bool1;
		this.Bool2 = data.Bool2;
		this.Bool3 = data.Bool3;
		this.Bool4 = data.Bool4;
		this.Bool5 = data.Bool5;
		this.Bool6 = data.Bool6;
		this.Bool7 = data.Bool7;
		this.Bool8 = data.Bool8;
		this.Bool9 = data.Bool9;
		this.Bool10 = data.Bool10;
		this.Bool11 = data.Bool11;
		this.Bool12 = data.Bool12;
		this.Bool13 = data.Bool13;
		this.Bool14 = data.Bool14;
		this.Bool15 = data.Bool15;
		this.Bool16 = data.Bool16;
		this.Bool17 = data.Bool17;
		this.Bool18 = data.Bool18;
		this.Bool19 = data.Bool19;
		this.Bool20 = data.Bool20;

		this.Time1 = data.Time1;
		this.Time2 = data.Time2;
		this.Time3 = data.Time3;
		this.Time4 = data.Time4;
		this.Time5 = data.Time5;
		this.Time6 = data.Time6;
		this.Time7 = data.Time7;
		this.Time8 = data.Time8;
		this.Time9 = data.Time9;
		this.Time10 = data.Time10;
		this.Time11 = data.Time11;
		this.Time12 = data.Time12;
		this.Time13 = data.Time13;
		this.Time14 = data.Time14;
		this.Time15 = data.Time15;
		this.Time16 = data.Time16;
		this.Time17 = data.Time17;
		this.Time18 = data.Time18;
		this.Time19 = data.Time19;
		this.Time20 = data.Time20;


		this.Text1Config = new ExtraFieldTextConfig(data.Text1Config);
		this.Text2Config = new ExtraFieldTextConfig(data.Text2Config);
		this.Text3Config = new ExtraFieldTextConfig(data.Text3Config);
		this.Text4Config = new ExtraFieldTextConfig(data.Text4Config);
		this.Text5Config = new ExtraFieldTextConfig(data.Text5Config);
		this.Text6Config = new ExtraFieldTextConfig(data.Text6Config);
		this.Text7Config = new ExtraFieldTextConfig(data.Text7Config);
		this.Text8Config = new ExtraFieldTextConfig(data.Text8Config);
		this.Text9Config = new ExtraFieldTextConfig(data.Text9Config);
		this.Text10Config = new ExtraFieldTextConfig(data.Text10Config);
		this.Text11Config = new ExtraFieldTextConfig(data.Text11Config);
		this.Text12Config = new ExtraFieldTextConfig(data.Text12Config);
		this.Text13Config = new ExtraFieldTextConfig(data.Text13Config);
		this.Text14Config = new ExtraFieldTextConfig(data.Text14Config);
		this.Text15Config = new ExtraFieldTextConfig(data.Text15Config);
		this.Text16Config = new ExtraFieldTextConfig(data.Text16Config);
		this.Text17Config = new ExtraFieldTextConfig(data.Text17Config);
		this.Text18Config = new ExtraFieldTextConfig(data.Text18Config);
		this.Text19Config = new ExtraFieldTextConfig(data.Text19Config);
		this.Text20Config = new ExtraFieldTextConfig(data.Text20Config);

		this.Num1Config = new ExtraFieldNumberConfig(data.Num1Config);
		this.Num2Config = new ExtraFieldNumberConfig(data.Num2Config);
		this.Num3Config = new ExtraFieldNumberConfig(data.Num3Config);
		this.Num4Config = new ExtraFieldNumberConfig(data.Num4Config);
		this.Num5Config = new ExtraFieldNumberConfig(data.Num5Config);
		this.Num6Config = new ExtraFieldNumberConfig(data.Num6Config);
		this.Num7Config = new ExtraFieldNumberConfig(data.Num7Config);
		this.Num8Config = new ExtraFieldNumberConfig(data.Num8Config);
		this.Num9Config = new ExtraFieldNumberConfig(data.Num9Config);
		this.Num10Config = new ExtraFieldNumberConfig(data.Num10Config);
		this.Num11Config = new ExtraFieldNumberConfig(data.Num11Config);
		this.Num12Config = new ExtraFieldNumberConfig(data.Num12Config);
		this.Num13Config = new ExtraFieldNumberConfig(data.Num13Config);
		this.Num14Config = new ExtraFieldNumberConfig(data.Num14Config);
		this.Num15Config = new ExtraFieldNumberConfig(data.Num15Config);
		this.Num16Config = new ExtraFieldNumberConfig(data.Num16Config);
		this.Num17Config = new ExtraFieldNumberConfig(data.Num17Config);
		this.Num18Config = new ExtraFieldNumberConfig(data.Num18Config);
		this.Num19Config = new ExtraFieldNumberConfig(data.Num19Config);
		this.Num20Config = new ExtraFieldNumberConfig(data.Num20Config);

		this.Bool1Config = new ExtraFieldBooleanConfig(data.Bool1Config);
		this.Bool2Config = new ExtraFieldBooleanConfig(data.Bool2Config);
		this.Bool3Config = new ExtraFieldBooleanConfig(data.Bool3Config);
		this.Bool4Config = new ExtraFieldBooleanConfig(data.Bool4Config);
		this.Bool5Config = new ExtraFieldBooleanConfig(data.Bool5Config);
		this.Bool6Config = new ExtraFieldBooleanConfig(data.Bool6Config);
		this.Bool7Config = new ExtraFieldBooleanConfig(data.Bool7Config);
		this.Bool8Config = new ExtraFieldBooleanConfig(data.Bool8Config);
		this.Bool9Config = new ExtraFieldBooleanConfig(data.Bool9Config);
		this.Bool10Config = new ExtraFieldBooleanConfig(data.Bool10Config);
		this.Bool11Config = new ExtraFieldBooleanConfig(data.Bool11Config);
		this.Bool12Config = new ExtraFieldBooleanConfig(data.Bool12Config);
		this.Bool13Config = new ExtraFieldBooleanConfig(data.Bool13Config);
		this.Bool14Config = new ExtraFieldBooleanConfig(data.Bool14Config);
		this.Bool15Config = new ExtraFieldBooleanConfig(data.Bool15Config);
		this.Bool16Config = new ExtraFieldBooleanConfig(data.Bool16Config);
		this.Bool17Config = new ExtraFieldBooleanConfig(data.Bool17Config);
		this.Bool18Config = new ExtraFieldBooleanConfig(data.Bool18Config);
		this.Bool19Config = new ExtraFieldBooleanConfig(data.Bool19Config);
		this.Bool20Config = new ExtraFieldBooleanConfig(data.Bool20Config);

		this.Time1Config = new ExtraFieldTimeConfig(data.Time1Config);
		this.Time2Config = new ExtraFieldTimeConfig(data.Time2Config);
		this.Time3Config = new ExtraFieldTimeConfig(data.Time3Config);
		this.Time4Config = new ExtraFieldTimeConfig(data.Time4Config);
		this.Time5Config = new ExtraFieldTimeConfig(data.Time5Config);
		this.Time6Config = new ExtraFieldTimeConfig(data.Time6Config);
		this.Time7Config = new ExtraFieldTimeConfig(data.Time7Config);
		this.Time8Config = new ExtraFieldTimeConfig(data.Time8Config);
		this.Time9Config = new ExtraFieldTimeConfig(data.Time9Config);
		this.Time10Config = new ExtraFieldTimeConfig(data.Time10Config);
		this.Time11Config = new ExtraFieldTimeConfig(data.Time11Config);
		this.Time12Config = new ExtraFieldTimeConfig(data.Time12Config);
		this.Time13Config = new ExtraFieldTimeConfig(data.Time13Config);
		this.Time14Config = new ExtraFieldTimeConfig(data.Time14Config);
		this.Time15Config = new ExtraFieldTimeConfig(data.Time15Config);
		this.Time16Config = new ExtraFieldTimeConfig(data.Time16Config);
		this.Time17Config = new ExtraFieldTimeConfig(data.Time17Config);
		this.Time18Config = new ExtraFieldTimeConfig(data.Time18Config);
		this.Time19Config = new ExtraFieldTimeConfig(data.Time19Config);
		this.Time20Config = new ExtraFieldTimeConfig(data.Time20Config);
	}

	getJSON() {
		return {
			HideEditInBrowse: this.HideEditInBrowse,

			Num1: this.Num1,
			Num2: this.Num2,
			Num3: this.Num3,
			Num4: this.Num4,
			Num5: this.Num5,
			Num6: this.Num6,
			Num7: this.Num7,
			Num8: this.Num8,
			Num9: this.Num9,
			Num10: this.Num10,
			Num11: this.Num11,
			Num12: this.Num12,
			Num13: this.Num13,
			Num14: this.Num14,
			Num15: this.Num15,
			Num16: this.Num16,
			Num17: this.Num17,
			Num18: this.Num18,
			Num19: this.Num19,
			Num20: this.Num20,

			Text1: this.Text1,
			Text2: this.Text2,
			Text3: this.Text3,
			Text4: this.Text4,
			Text5: this.Text5,
			Text6: this.Text6,
			Text7: this.Text7,
			Text8: this.Text8,
			Text9: this.Text9,
			Text10: this.Text10,
			Text11: this.Text11,
			Text12: this.Text12,
			Text13: this.Text13,
			Text14: this.Text14,
			Text15: this.Text15,
			Text16: this.Text16,
			Text17: this.Text17,
			Text18: this.Text18,
			Text19: this.Text19,
			Text20: this.Text20,

			Bool1: this.Bool1,
			Bool2: this.Bool2,
			Bool3: this.Bool3,
			Bool4: this.Bool4,
			Bool5: this.Bool5,
			Bool6: this.Bool6,
			Bool7: this.Bool7,
			Bool8: this.Bool8,
			Bool9: this.Bool9,
			Bool10: this.Bool10,
			Bool11: this.Bool11,
			Bool12: this.Bool12,
			Bool13: this.Bool13,
			Bool14: this.Bool14,
			Bool15: this.Bool15,
			Bool16: this.Bool16,
			Bool17: this.Bool17,
			Bool18: this.Bool18,
			Bool19: this.Bool19,
			Bool20: this.Bool20,

			Time1: this.Time1,
			Time2: this.Time2,
			Time3: this.Time3,
			Time4: this.Time4,
			Time5: this.Time5,
			Time6: this.Time6,
			Time7: this.Time7,
			Time8: this.Time8,
			Time9: this.Time9,
			Time10: this.Time10,
			Time11: this.Time11,
			Time12: this.Time12,
			Time13: this.Time13,
			Time14: this.Time14,
			Time15: this.Time15,
			Time16: this.Time16,
			Time17: this.Time17,
			Time18: this.Time18,
			Time19: this.Time19,
			Time20: this.Time20,

			Num1Config: this.Num1Config.getJSON(),
			Num2Config: this.Num2Config.getJSON(),
			Num3Config: this.Num3Config.getJSON(),
			Num4Config: this.Num4Config.getJSON(),
			Num5Config: this.Num5Config.getJSON(),
			Num6Config: this.Num6Config.getJSON(),
			Num7Config: this.Num7Config.getJSON(),
			Num8Config: this.Num8Config.getJSON(),
			Num9Config: this.Num9Config.getJSON(),
			Num10Config: this.Num10Config.getJSON(),
			Num11Config: this.Num11Config.getJSON(),
			Num12Config: this.Num12Config.getJSON(),
			Num13Config: this.Num13Config.getJSON(),
			Num14Config: this.Num14Config.getJSON(),
			Num15Config: this.Num15Config.getJSON(),
			Num16Config: this.Num16Config.getJSON(),
			Num17Config: this.Num17Config.getJSON(),
			Num18Config: this.Num18Config.getJSON(),
			Num19Config: this.Num19Config.getJSON(),
			Num20Config: this.Num20Config.getJSON(),

			Text1Config: this.Text1Config.getJSON(),
			Text2Config: this.Text2Config.getJSON(),
			Text3Config: this.Text3Config.getJSON(),
			Text4Config: this.Text4Config.getJSON(),
			Text5Config: this.Text5Config.getJSON(),
			Text6Config: this.Text6Config.getJSON(),
			Text7Config: this.Text7Config.getJSON(),
			Text8Config: this.Text8Config.getJSON(),
			Text9Config: this.Text9Config.getJSON(),
			Text10Config: this.Text10Config.getJSON(),
			Text11Config: this.Text11Config.getJSON(),
			Text12Config: this.Text12Config.getJSON(),
			Text13Config: this.Text13Config.getJSON(),
			Text14Config: this.Text14Config.getJSON(),
			Text15Config: this.Text15Config.getJSON(),
			Text16Config: this.Text16Config.getJSON(),
			Text17Config: this.Text17Config.getJSON(),
			Text18Config: this.Text18Config.getJSON(),
			Text19Config: this.Text19Config.getJSON(),
			Text20Config: this.Text20Config.getJSON(),

			Bool1Config: this.Bool1Config.getJSON(),
			Bool2Config: this.Bool2Config.getJSON(),
			Bool3Config: this.Bool3Config.getJSON(),
			Bool4Config: this.Bool4Config.getJSON(),
			Bool5Config: this.Bool5Config.getJSON(),
			Bool6Config: this.Bool6Config.getJSON(),
			Bool7Config: this.Bool7Config.getJSON(),
			Bool8Config: this.Bool8Config.getJSON(),
			Bool9Config: this.Bool9Config.getJSON(),
			Bool10Config: this.Bool10Config.getJSON(),
			Bool11Config: this.Bool11Config.getJSON(),
			Bool12Config: this.Bool12Config.getJSON(),
			Bool13Config: this.Bool13Config.getJSON(),
			Bool14Config: this.Bool14Config.getJSON(),
			Bool15Config: this.Bool15Config.getJSON(),
			Bool16Config: this.Bool16Config.getJSON(),
			Bool17Config: this.Bool17Config.getJSON(),
			Bool18Config: this.Bool18Config.getJSON(),
			Bool19Config: this.Bool19Config.getJSON(),
			Bool20Config: this.Bool20Config.getJSON(),

			Time1Config: this.Time1Config.getJSON(),
			Time2Config: this.Time2Config.getJSON(),
			Time3Config: this.Time3Config.getJSON(),
			Time4Config: this.Time4Config.getJSON(),
			Time5Config: this.Time5Config.getJSON(),
			Time6Config: this.Time6Config.getJSON(),
			Time7Config: this.Time7Config.getJSON(),
			Time8Config: this.Time8Config.getJSON(),
			Time9Config: this.Time9Config.getJSON(),
			Time10Config: this.Time10Config.getJSON(),
			Time11Config: this.Time11Config.getJSON(),
			Time12Config: this.Time12Config.getJSON(),
			Time13Config: this.Time13Config.getJSON(),
			Time14Config: this.Time14Config.getJSON(),
			Time15Config: this.Time15Config.getJSON(),
			Time16Config: this.Time16Config.getJSON(),
			Time17Config: this.Time17Config.getJSON(),
			Time18Config: this.Time18Config.getJSON(),
			Time19Config: this.Time19Config.getJSON(),
			Time20Config: this.Time20Config.getJSON(),
		};
	}

	public hasActiveFields(): boolean {
		return this.getActiveFields().length > 0;
	}

	public getActiveFields(): (keyof ExtraFieldNamesConfig)[] {
		return getAllExtraFieldKeyNames().filter(k => {
			return typeof (this[k]) == "string" && this[k] != "";
		});
	}

	public static getAllFields(): (keyof ExtraFieldNamesConfig)[] {
		return getAllExtraFieldKeyNames();
	}

	public static fieldNameToConditionType(field: (keyof ExtraFieldNamesConfig)): ConditionPropertyType {
		if (field.startsWith("Num")) {
			return ConditionPropertyType.NUMBER;
		}
		if (field.startsWith("Text")) {
			return ConditionPropertyType.STRING;
		}
		if (field.startsWith("Time")) {
			return ConditionPropertyType.DATE;
		}
		if (field.startsWith("Bool")) {
			return ConditionPropertyType.BOOL;
		}
		return ConditionPropertyType.EMPTY;
	}

	public setDefaultValues(extraFields:IExtraFields) {
		let anyConf:any = this;
		let anyFields:any = extraFields;
		for (let i = 1; i <= getExtraFieldCount(); i++) {
			if (anyConf[`Num${i}`]){
				anyFields[`Num${i}`] = (anyConf[`Num${i}Config`] as ExtraFieldNumberConfig).DefaultValue;
			}
			if (anyConf[`Text${i}`]){
				anyFields[`Text${i}`] = (anyConf[`Text${i}Config`] as ExtraFieldTextConfig).DefaultValue;
			}
			if (anyConf[`Time${i}`]){
				anyFields[`Time${i}`] = (anyConf[`Time${i}Config`] as ExtraFieldTimeConfig).DefaultValue;
			}
			if (anyConf[`Bool${i}`]){
				anyFields[`Bool${i}`] = (anyConf[`Bool${i}Config`] as ExtraFieldBooleanConfig).DefaultValue;
			}
		}
	}

	public getFieldName(field:string):string {
		return (this as any)[field];
	}

	public getFieldConfig(field:string):ExtraFieldBaseConfig {
		return (this as any)[`${field}Config`];
	}

	public getFieldsEditableViaBrowseMenu():string[] {
		return this.getActiveFields().filter((field)=>{
			return ((this as any)[`${field}Config`] as ExtraFieldBaseConfig).EditableViaBrowseMenu;
		});
	}

	public getFieldsEditableViaBrowseMenuMenuItems(overrideLimit:boolean = false):ISelectItem<string>[] {
		let fields = this.getFieldsEditableViaBrowseMenu();
		let hasModule = AuthService.hasModule(MODULE.EXTRA_VELDEN);
		if (fields.length > 3 && !overrideLimit) {
			return [{
				text: i18n.t("common.browse.quick-edit-extra-fields-label"),
				value: "quickEditExtraFields",
				icon: "table",
				disabled: !hasModule
			}];
		}
		return fields.map(f=>{
			let icon = "calculator";
			if (f.startsWith("Text")) {
				icon = "comment";
			}else if(f.startsWith("Bool")) {
				icon = "check-square";
			}else if (f.startsWith("Time")) {
				icon = "calendar";
			}
			return {
				text: i18n.t("common.browse.quick-edit-extra-fields-label-field", {field: this.getFieldName(f)}),
				value: f,
				icon,
				disabled: !hasModule
			};
		});
	}
}