import { ServerConfig } from "@/config/config";
import { Axios } from "@/utils/axios";
import { EventEmitter } from "@/utils/event-emitter";
import Currency from "@/models/base/currency";
import { IInitAppResponse } from "./init-service";

export type CurrencyMap = {[key:string]:number}
export class CurrencyServiceClass extends EventEmitter {
	private currencies:Currency[] = [];
	private get url():string{
		return `${ServerConfig.host}/currency`;
	}
	getCurrencies():Currency[] {
		return this.currencies;
	}

	getCurrenciesMap():CurrencyMap{
		let result:CurrencyMap = {};
		for (let cur of this.currencies) {
			result[`${cur.ID}`] = cur.Rate;
		}
		return result;
	}

	async getCurrencieList():Promise<void>{
		let result = await Axios.get(ServerConfig.host + "/currency");
		this.currencies = result.data.data.map((data: any) => new Currency(data));
	}

	async saveCurrency(currency: Currency):Promise<void>{
		await Axios.put(ServerConfig.host + "/currency",[currency]);
	}

	async init(data:IInitAppResponse):Promise<void> {
		this.currencies = data.Currencies;
	}

};

export const CurrencyService = new CurrencyServiceClass();