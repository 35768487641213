
import { IModel } from "@/utils/models/model-interface";
import { SummaryCol } from "@/utils/report-engine-settings/summary-col";
import { SummaryRow } from "@/utils/report-engine-settings/summary-row";
import AppSummaryEditCol from "./SummaryEditCol.vue";
import Vue from "vue";

export default Vue.extend({
	data(){
		return {
			toEditIndex: -1,
			row: new SummaryRow()
		};
	},
	props: {
		value: {
			type: Object as ()=>SummaryRow,
			required: true
		},
		model: {
			type: Object as ()=>IModel,
			required: true
		}
	},
	computed: {
		sortableAttributes():any {
			return{
				tag: "w-list",
				disableX: true,
				keepInY: true,
				targetClass: "handle",
				items: this.row.Cols
			};
		}
	},
	watch: {
		value: {
			immediate: true,
			handler(){
				this.row = new SummaryRow(this.value);
			}
		}
	},
	methods: {
		addCol(){
			this.row.Cols.push(new SummaryCol());
			this.toEditIndex = this.row.Cols.length -1;
		},
		editCol(col:SummaryCol){
			this.toEditIndex = this.row.Cols.indexOf(col);
		},
		deleteCol(col:SummaryCol){
			let i = this.row.Cols.indexOf(col);
			if (i == -1) return;
			this.row.Cols.splice(i,1);
		},
		close(){
			this.$emit("close");
		},
		confirm(){
			this.$emit("input", this.row);
			this.close();
		}
	},
	components: {
		AppSummaryEditCol
	},
	i18nEx: {
		componentPrefix: "components.reportengine"
	}
});
