import { ServerConfig } from "@/config/config";
import { Calendar } from "@/models/calendar";
import { CalendarEvent } from "@/models/calendar-event";
import { Axios } from "@/utils/axios";
import "@fullcalendar/core";
import { EventInput } from "@fullcalendar/core/structs/event";
import { EventSourceInput } from "@fullcalendar/core/structs/event-source";
import Vue from "vue";


export const CalendarService = new Vue({
	computed: {
		calendarUrl():string {
			return ServerConfig.host + "/calendar";
		},
		eventUrl():string {
			return ServerConfig.host + "/calendar-event";
		}
	},
	methods: {
		async getCalendars():Promise<Calendar[]> {
			let res = await Axios.get(`${this.calendarUrl}`);
			return res.data.data.map((c:any) => new Calendar(c));
		},
		async postCalendars(calendars:Calendar[]):Promise<Calendar[]> {
			let res = await Axios.post(`${this.calendarUrl}`, calendars.map(c => c.getJSON()));
			return res.data.map((c:any) => new Calendar(c));
		},
		async putCalendars(calendars:Calendar[]):Promise<Calendar[]> {
			let res = await Axios.put(`${this.calendarUrl}`, calendars.map(c => c.getJSON()));
			return res.data.map((c:any) => new Calendar(c));
		},
		async deleteCalendars(calendars:Calendar[]):Promise<void> {
			await Axios.delete(`${this.calendarUrl}`, {
				data: calendars.map(c => c.ID)
			});
		},

		async getEventsByCalendarsInRange(calendars:Calendar[] | number[], minDate:Date, maxDate:Date):Promise<CalendarEvent[]> {
			if (calendars.length == 0) {
				return [];
			}
			if (typeof(calendars[0]) == "object") {
				calendars = (calendars as Calendar[]).map((c:Calendar) => c.ID) as number[];
			}
			let res = await Axios.get(`${this.eventUrl}/range`, {
				params: {
					timeMin: Math.round(minDate.getTime() / 1000),
					timeMax: Math.round(maxDate.getTime() / 1000),
					calendarIds: calendars.join(",")
				}
			});
			return res.data.map((e:any) => new CalendarEvent(e));
		},
		async getEvent(eventID:number | string):Promise<CalendarEvent> {
			let result = await Axios.get(`${this.eventUrl}/${eventID}`);
			return new CalendarEvent(result.data);
		},
		async postEvents(events:CalendarEvent[]):Promise<CalendarEvent[]> {
			let res = await Axios.post(`${this.eventUrl}`, events.map(e => e.getJSON()));
			return res.data.map((e:any) => new CalendarEvent(e));
		},
		async putEvents(events:CalendarEvent[]):Promise<CalendarEvent[]> {
			let res = await Axios.put(`${this.eventUrl}`, events.map(e => e.getJSON()));
			return res.data.map((e:any) => new CalendarEvent(e));
		},
		async deleteEvents(events:CalendarEvent[]):Promise<void> {
			await Axios.delete(`${this.eventUrl}`, {
				data: events.map(c => c.ID)
			});
		},

		createEventSource(calendars:Calendar[]):EventSourceInput {
			return {
				async events(info, onSuccess, onFail):Promise<EventInput[]> {
					let events = await CalendarService.getEventsByCalendarsInRange(calendars, info.start, info.end);
					return events.map(e => {
						let res = e.toFullCalendar();
						let cal = calendars.find(c => c.ID == e.CalendarID);
						if (cal) {
							res.backgroundColor = cal.Color;
						}
						return res;
					});
				}
			};
		},

		async getWidgetEvents():Promise<CalendarEvent[]> {
			let res = await Axios.get(`${this.eventUrl}/widget`);
			return res.data.map((e:any) => new CalendarEvent(e));
		}
	}
});