import { AWSFileService } from "@/services";

export enum DashboardImageAlign {
	Start = "start",
	Middle = "middle",
	End = "end"
}

export class DashboardImageData {
	AWSImageToken:string = "";
	BackgroundColor:string = "";
	HorizontalAlign:DashboardImageAlign = DashboardImageAlign.Middle;
	VerticalAlign:DashboardImageAlign = DashboardImageAlign.Middle;
	PaddingLeft:number = 0;
	PaddingRight:number = 0;
	PaddingTop:number = 0;
	PaddingBottom:number = 0;
	HideCardBorder:boolean = false;

	public constructor(data?:any) {
		if (!data) return;
		this.AWSImageToken = data.AWSImageToken;
		this.BackgroundColor = data.BackgroundColor;
		this.HorizontalAlign = data.HorizontalAlign;
		this.VerticalAlign= data.VerticalAlign;
		this.PaddingLeft = parseInt(data.PaddingLeft);
		this.PaddingRight = parseInt(data.PaddingRight);
		this.PaddingTop = parseInt(data.PaddingTop);
		this.PaddingBottom =parseInt(data.PaddingBottom);
		this.HideCardBorder = data.HideCardBorder == "true";
	}

	public getJSON(){
		return {
			AWSImageToken: this.AWSImageToken,
			BackgroundColor: this.BackgroundColor,
			HorizontalAlign: this.HorizontalAlign,
			VerticalAlign: this.VerticalAlign,
			PaddingLeft: `${this.PaddingLeft}`,
			PaddingRight: `${this.PaddingRight}`,
			PaddingTop: `${this.PaddingTop}`,
			PaddingBottom: `${this.PaddingBottom}`,
			HideCardBorder: `${this.HideCardBorder}`
		};
	}

	async getImageLink():Promise<string>{
		return AWSFileService.getLink(this.AWSImageToken);
	}
}