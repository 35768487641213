import { BrowseID } from "../browse";
import { BrowsePermissions } from "./browse-permissions";

export class Permissions {
	IsAdmin:boolean = false;
	AllowedModuleIDs:number[] = [];
	ClientPermissions:BrowsePermissions;
	SupplierPermissions:BrowsePermissions;
	ProspectPermissions:BrowsePermissions;
	ProductPermissions:BrowsePermissions;
	StockPermissions:BrowsePermissions;
	CrmPermissions:BrowsePermissions;
	SalePermissions:BrowsePermissions;
	SubscriptionPermissions:BrowsePermissions;
	SaleRowPermissions:BrowsePermissions;
	BankPermissions:BrowsePermissions;
	BookKeepingPermissions:BrowsePermissions;
	PurchasePermissions:BrowsePermissions;
	PurchaseDocumentPermissions:BrowsePermissions;
	ProspectDocumentPermissions:BrowsePermissions;
	PurchaseDocumentRowsPermissions:BrowsePermissions;
	CanReadProductPurchaseDetails:boolean = false;
	CanMakeReports:boolean = false;
	ManagableSaleTypes:number[] = [];
	CanSeeDashboard:boolean = false;

	 public constructor(data:any){
		this.IsAdmin = data.IsAdmin;
		this.AllowedModuleIDs = data.AllowedModuleIDs;
		this.CanMakeReports = data.CanMakeReports;
		this.ClientPermissions = new BrowsePermissions(data.ClientPermissions);
		this.SupplierPermissions = new BrowsePermissions(data.SupplierPermissions);
		this.ProspectPermissions = new BrowsePermissions(data.ProspectPermissions);
		this.ProductPermissions = new BrowsePermissions(data.ProductPermissions);
		this.StockPermissions = new BrowsePermissions(data.StockPermissions);
		this.CrmPermissions = new BrowsePermissions(data.CrmPermissions);
		this.SalePermissions = new BrowsePermissions(data.SalePermissions);
		this.BankPermissions = new BrowsePermissions(data.BankPermissions);
		this.BookKeepingPermissions = new BrowsePermissions(data.BookKeepingPermissions);
		this.PurchasePermissions = new BrowsePermissions(data.PurchasePermissions);
		this.SubscriptionPermissions = new BrowsePermissions(data.SubscriptionPermissions);
		this.SaleRowPermissions = new BrowsePermissions(data.SaleRowPermissions);
		this.PurchaseDocumentPermissions = new BrowsePermissions(data.PurchaseDocumentPermissions);
		this.ProspectDocumentPermissions = new BrowsePermissions(data.ProspectDocumentPermissions);
		this.PurchaseDocumentRowsPermissions = new BrowsePermissions(data.PurchaseDocumentRowsPermissions);
		this.CanReadProductPurchaseDetails = data.CanReadProductPurchaseDetails;
		this.ManagableSaleTypes = data.ManagableSaleTypes || [];
		this.CanSeeDashboard = data.CanSeeDashboard;
	}

	public getJSON(){
		return {
			IsAdmin: this.IsAdmin,
			AllowedModuleIDs: this.AllowedModuleIDs,
			ClientPermissions: this.ClientPermissions.getJSON(),
			SupplierPermissions: this.SupplierPermissions.getJSON(),
			ProspectPermissions: this.ProspectPermissions.getJSON(),
			ProductPermissions: this.ProductPermissions.getJSON(),
			StockPermissions: this.StockPermissions.getJSON(),
			CrmPermissions: this.CrmPermissions.getJSON(),
			SalePermissions: this.SalePermissions.getJSON(),
			SubscriptionPermissions: this.SubscriptionPermissions.getJSON(),
			SaleRowPermissions: this.SaleRowPermissions.getJSON(),
			BankPermissions: this.BankPermissions.getJSON(),
			BookKeepingPermissions: this.BookKeepingPermissions.getJSON(),
			PurchasePermissions: this.PurchasePermissions.getJSON(),
			PurchaseDocumentPermissions: this.PurchaseDocumentPermissions.getJSON(),
			ProspectDocumentPermissions: this.ProspectDocumentPermissions.getJSON(),
			PurchaseDocumentRowsPermissions: this.PurchaseDocumentRowsPermissions.getJSON(),
			CanReadProductPurchaseDetails: this.CanReadProductPurchaseDetails,
			ManagableSaleTypes: this.ManagableSaleTypes,
			CanMakeReports: this.CanMakeReports,
			CanSeeDashboard: this.CanSeeDashboard
		};
	}

	public getPermissionsByBrowseID(browseID:number):BrowsePermissions {
		if (browseID == BrowseID.PROSPECT_DOCUMENTS) {
			return this.ProspectDocumentPermissions;
		}

		if (browseID == BrowseID.PURCHASE_DOCUMENT_ROWS){
			return this.PurchaseDocumentRowsPermissions;
		}

		let permissions = [
			this.ClientPermissions,
			this.SupplierPermissions,
			this.ProspectPermissions,
			this.ProductPermissions,
			this.StockPermissions,
			this.SalePermissions,
			this.SaleRowPermissions,
			this.BankPermissions,
			this.BookKeepingPermissions,
			this.PurchasePermissions,
			this.SubscriptionPermissions,
			this.CrmPermissions,
		];
		return permissions[browseID-1];
	}
}