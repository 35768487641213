
import { ReportEngineSettings } from "@/utils/report-engine-settings/report-engine-settings";
import Vue from "vue";
export default Vue.extend({
	props: {
		settings: {
			type: Object as ()=>ReportEngineSettings,
			required: true
		}
	},
	computed: {
		disabled():boolean {
			return this.settings.RecordsPerColumn == 0;
		}
	},
	methods: {
		toggle(value:boolean){
			if (value){
				this.settings.RecordsPerColumn = 1;
			}else{
				this.settings.RecordsPerColumn = 0;
			}
		}
	},
	i18nEx: {
		componentPrefix: "components.reportengine"
	}
});
