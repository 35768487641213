import { Axios } from "@/utils/axios";
import { Contact, ContactExtraFields } from "@/models/base";
import { HumanFilter, getPreloadsFromFilters } from "../human-filter";
import { IQueryParamsWithOptionalView } from "../query-params";
import { advancedSearch } from "@/utils/browse/browse";
import { Job } from "@/models/job";
import { LastOpenedItem } from "../last-opened";
import { INextAvailableIDResponse } from "../next-available-id-respone";
import { HttpError } from "./http-error";
import { FetchedTotal } from "../views/fetched-total";
import { View } from "@/models/view";
import { ContactAwsDocument } from "@/models/base/contact-aws-document";
import { ContactPriceRule } from "@/models/base/contact-price-rule";
import { IGetResponse } from "../get-response";
import { IExtraFieldQuickEditViaMenuDataResult } from "../models/extra-fields";
import { downloadBlob } from "../blob";
import { getModel } from "../models/model";

export function onLastOpenedItemSetFunction(contact:Contact){
	contact.ID = 0;
	contact.ComputedFriendlyID = "";
	contact.FriendlyID = 0;
	contact.MainAddressID = 0;
	for (let address of [contact.MainAddress, ...contact.Addresses]){
		if (!address) continue;
		address.ID = 0;
		address.ContactID = 0;
	}

	contact.ExtraFieldsID = 0;
	return contact;
}

export abstract class ContactService {
	protected abstract url:string;
	protected abstract routerPath:string;
	public abstract lastOpenedItem:LastOpenedItem;

	selectedContacts = [] as Contact[];

	public async getContactsByIDs(ids: number[]): Promise<Contact[]> {
		let result = await Axios.get(`${this.url}`, {
			params: {
				limit: 10,
				filters: [
					new HumanFilter({
						Field: "this.ID",
						Operator: "in",
						Values: ids.map(v => `${v}`)
					})
				] as HumanFilter[]
			}
		});
		return result.data.data.map((c: any) => new Contact(c));
	}

	public async getContacts(query: IQueryParamsWithOptionalView): Promise<IGetResponse<Contact>> {
		let result = await advancedSearch(query, this.url, getModel("Contact"));
		let records = result.data.records as number;
		let contacts = (result.data.data || []).map((c: any) => new Contact(c));
		return {data: contacts, records};
	}

	async getTotals(view:View):Promise<FetchedTotal[]>{
		let result = await Axios.get(`${this.url}/view/${view.ID}/totals`);
		return (result.data || []).map((d:any)=>new FetchedTotal(d));
	}

	public async getContact(id: number): Promise<Contact> {
		let result = await Axios.get(`${this.url}/${id}`);
		return new Contact(result.data);
	}

	public async contactExists(CompanyName: string): Promise<Contact[]> {
		let filters = [];
		if (CompanyName) {
			filters.push(new HumanFilter({
				Field: "this_MainAddress.CompanyName",
				Operator: "=",
				Values: [CompanyName],
				IsOr: false
			}));
		}
		let preloads = getPreloadsFromFilters(filters);
		let result = await Axios.get(`${this.url}`, {
			params: {
				limit: 1,
				filters,
				preloads
			}
		});
		return result.data.data.map((c: any) => new Contact(c));
	}

	public async searchByName(name: string, includeInactive:boolean = false): Promise<Contact[]> {
		let result = await Axios.get(`${this.url}/search/${name}`, {params: {includeInactive}});
		return result.data.map((c: any) => new Contact(c));
	}

	public async postContact(contact: Contact): Promise<Contact> {
		let result = await Axios.post(`${this.url}`, contact.getJSON());
		let c = new Contact(result.data);
		for (let file of contact.UnprocessedFiles){
			await this.uploadDocument(c, file);
		}
		for (let file of contact.toDeleteFiles){
			await this.deleteDocument(c, file);
		}
		return c;
	}

	public async putContact(contact: Contact): Promise<Contact> {
		let result = await Axios.put(`${this.url}`, contact.getJSON());
		for (let file of contact.UnprocessedFiles){
			await this.uploadDocument(contact,file);
		}
		for (let file of contact.toDeleteFiles) {
			await this.deleteDocument(contact, file);
		}
		return new Contact(result.data);
	}

	public async delContacts(contacts: Contact[]): Promise<boolean> {
		try {
			await Axios.delete(`${this.url}`, {
				data: contacts.map((a) => a.ID),
			});
			return true;
		} catch (err) {
			return false;
		}
	}

	public async importContactsCSV(file:File, updateExisting:boolean,):Promise<Job> {
		let data = new FormData();
		data.append("file", file, file.name);
		data.append("update-existing", updateExisting ? "true" : "false");
		let result = await Axios.post(`${this.url}/import-csv`, data);
		return new Job(result);
	}

	public getEditRoute(contactId:number):string{
		return `${this.routerPath}/edit/${contactId}`;
	}

	public getInfoRoute(contactId:number):string{
		return `${this.routerPath}/info/${contactId}`;
	}

	public async getNextAvailableComputedID():Promise<INextAvailableIDResponse>{
		let result = await Axios.get(`${this.url}/get-next-computed-friendly-id`);
		return {
			friendlyId: result.data.FriendlyID,
			computedFriendlyId: result.data.ComputedFriendlyID
		};
	}

	public async isMollieCustomerIdValid(customerId:string):Promise<boolean> {
		try{
			await Axios.get(`${this.url}/is-mollie-customer-id-valid/${customerId}`);
			return true;
		}catch(err) {
			let e = err as HttpError;
			if (e.isNetworkError) {
				throw err;
			}
			return false;
		}
	}

	public async addPriceRule(contact:Contact, rule:ContactPriceRule):Promise<ContactPriceRule>{
		let result = await Axios.post(`${this.url}/${contact.ID}/price-rule`, rule.getJSON());
		rule = new ContactPriceRule(result.data);
		contact.PriceRules.push(rule);
		return rule;
	}

	public async updatePriceRule(contact:Contact, rule:ContactPriceRule):Promise<ContactPriceRule>{
		let result = await Axios.put(`${this.url}/${contact.ID}/price-rule/${rule.ID}`, rule.getJSON());
		rule = new ContactPriceRule(result.data);
		let i = contact.PriceRules.findIndex(r=>r.ID == rule.ID);
		if (i == -1) {
			return rule;
		}
		contact.PriceRules[i] = rule;
		return rule;
	}

	public async uploadDocument(contact:Contact, file:File):Promise<ContactAwsDocument>{
		let data = new FormData();
		data.append("file", file, file.name);
		let result = await Axios.post(`${this.url}/${contact.ID}/document`, data);
		return new ContactAwsDocument(result);
	}

	public async deleteDocument(contact:Contact, document:ContactAwsDocument):Promise<void>{
		await Axios.delete(`${this.url}/${contact.ID}/document/${document.ID}`);
	}

	public async resetNumbering(){
		await Axios.post(`${this.url}/reset-numbering`);
	}

	public async updateExtraFields(contact:Contact, extraFields:ContactExtraFields):Promise<ContactExtraFields>{
		let result = await Axios.put(`${this.url}/extra-fields/${contact.ExtraFieldsID}`, extraFields.getJSON());
		return new ContactExtraFields(result.data);
	}

	public async batchEditExtraField(contacts:Contact[], field:string, value:IExtraFieldQuickEditViaMenuDataResult) {
		let body = {
			ExtraFieldIDs: contacts.map(s=>s.ExtraFieldsID),
			Field: field,
			NumValue: value.numValue,
			TextValue: value.textValue,
			BoolValue: value.boolValue,
			TimeValue: value.timeValue
		};
		await Axios.post(`${this.url}/batch-edit-extra-field`, body);
	}

	// Counts how many contacts there are with vat equal to empty string (not null)
	public async getWithAmbigousVatCount():Promise<number>{
		let result = await Axios.get(`${this.url}/with-ambiguous-vat-count`);
		return result.data.Result;
	}

	public async getWithAmbigousVatTxt():Promise<void>{
		let result = await Axios.get(`${this.url}/with-ambiguous-vat-txt`);
		let blob = new Blob([result.data], {type: "apllication/txt"});
		downloadBlob(blob, "contacts-with-no-vat.txt");
	}

	public async getByVat(vat:string):Promise<Contact[]>{
		let result = await Axios.get(`${this.url}/by-vat/${vat}`);
		return ((result.data || []) as any[]).map(c=>new Contact(c));
	}

	public async setDefaultExtraFieldValues():Promise<void>{
		await Axios.post(`${this.url}/set-default-extra-field-values`);
	}
}