import { ComputedOneOffPricing } from "./computed-one-off-pricing";
import { ComputedRecuringPricing } from "./computed-recuring-pricing";

export class ComputedPricing {
	oneTimePrice:ComputedOneOffPricing;
	recuringPrice:ComputedRecuringPricing;

	public constructor(data:any){
		this.oneTimePrice = new ComputedOneOffPricing(data.oneTimePrice);
		this.recuringPrice = new ComputedRecuringPricing(data.recuringPrice);
	}
}