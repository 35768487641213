
import { BankKind } from "@/models/base/bank";
import Vue from "vue";
import { TranslateResult } from "vue-i18n";
import { ISelectItem } from "winfakt-vue-components";

export default Vue.extend({
	props: {
		value: {
			type: Number
		},
		label: {
			type: [String, Object as ()=>TranslateResult]
		},
		fancy: Boolean,
		disabled: Boolean
	},
	computed: {
		bankKinds():ISelectItem<number>[]{
			return [
				{text: this.$ct("bank-kinds.diverse"), value: BankKind.Diverse},
				{text: this.$ct("bank-kinds.sale"), value: BankKind.Sale},
				{text: this.$ct("bank-kinds.purchase"), value: BankKind.Purchase}
			];
		},
		computedLabel():string | TranslateResult{
			return this.label || this.$ct("labels.kind");
		}
	},
	watch: {
		value: {
			immediate: true,
			handler(){
				let i = this.bankKinds.map(b=>b.value).indexOf(this.value);
				if (i == -1) {
					this.onInput(this.bankKinds[0].value);
				}
			}
		}
	},
	methods: {
		onInput(value:number){
			this.$emit("input", value);
		}
	},
	i18nEx: {
		componentPrefix: "components.bank"
	}
});
