import { ServerConfig } from "@/config/config";
import { Channel } from "@/models/chat/channel";
import { ChatMessage } from "@/models/chat/chat-message";
import { ChatUnreadConversation } from "@/models/chat/chat-unread-conversation";
import { Conversation } from "@/models/chat/conversation";
import { Thread } from "@/models/chat/thread";
import { ThreadUser } from "@/models/chat/thread-user";
import { UserInfo } from "@/models/dossier/user-info";
import { Axios } from "@/utils/axios";
import Vue from "vue";

export const ChatService = new Vue({
	data() {
		return {
			users: [] as UserInfo[],
			channels: [] as Channel[]
		};
	},
	computed: {
		url(): string {
			return `${ServerConfig.host}/chat`;
		}
	},
	methods: {
		async openDisplay() {
			await Axios.post(`${this.url}/open-display`);
		},
		async getUsers(): Promise<UserInfo[]> {
			let result = await Axios.get(`${this.url}/users`);
			return result.data.map((u: any) => new UserInfo(u));
		},
		async getChannels(): Promise<Channel[]> {
			let result = await Axios.get(`${this.url}/channel`);
			return result.data.map((u: any) => new Channel(u.Channel));
		},
		async getAllConversations(): Promise<Conversation[]> {
			let result = await Axios.get(`${this.url}/conversations`);
			return result.data.map((u: any) => new Conversation(u));
		},
		async getUnreads(): Promise<ChatUnreadConversation[]> {
			let result = await Axios.get(`${this.url}/conversations/unread`);
			return result.data.map((u: any) => new ChatUnreadConversation(u));
		},
		async getConversation(userId: string): Promise<Conversation> {
			let result = await Axios.get(`${this.url}/user/${userId}`);
			return new Conversation(result.data);
		},
		async getChannelConversation(channelId: string): Promise<Conversation> {
			let result = await Axios.get(`${this.url}/channel/${channelId}`);
			return new Conversation(result.data);
		},
		async getThreadConversation(threadId: string): Promise<Conversation> {
			let result = await Axios.get(`${this.url}/thread/conversation/${threadId}`);
			return new Conversation(result.data);
		},
		async postMessage(id: number, message: string, file?: File | null, threadId?: number | 0): Promise<ChatMessage> {
			let data = new FormData();
			data.append("message", message);
			if (threadId) {
				data.append("threadId", threadId.toString());
			}
			if (file) {
				data.append("file", file);
			}
			let result = await Axios.post(`${this.url}/conversation/${id}/post-message`, data);
			return new ChatMessage(result.data);
		},
		//THREADS
		async getThreads(): Promise<Thread[]> {
			let result = await Axios.get(`${this.url}/thread`);
			return result.data.map(function(u: any) {
				let t = new Thread(u.Thread);
				t.Pinned = u.Pinned;
				return t;
			});
		},
		async getThread(id: number): Promise<Thread> {

			let result = await Axios.get(`${this.url}/thread/${id}`);
			let t = new Thread(result.data.Thread);
			t.Pinned = result.data.Pinned;
			return t;

		},
		async createThread(thread: Thread, conversation: Conversation): Promise<ThreadUser> {
			let result = await Axios.post(`${this.url}/thread/?ConversationID=` + conversation.ID, thread.getJSON());
			return new ThreadUser(result.data);
		},
		async updateThread(thread: Thread): Promise<Thread> {
			let result = await Axios.put(`${this.url}/thread/${thread.ID}`, thread.getJSON());
			return new Thread(result.data[0]);
		},
		async pinThread(thread: Thread) {
			await Axios.put(`${this.url}/thread/pin/${thread.ID}`); //return new Thread(result.data[0]);
		},

	},
	async created() {
		requestAnimationFrame(() => {


		});
	}
});