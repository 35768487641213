
import Vue from "vue";
import AppSelectBookYear from "@/components/bookyear/SelectBookYear.vue";
import AppMultiSelectBookYear from "@/components/bookyear/MultiSelectBookYear.vue";
import { TranslateResult } from "vue-i18n";
import { HumanFilter } from "@/utils/human-filter";
import { BookYear } from "@/models/book-year";

export default Vue.extend({
	props: {
		value: {
			type: [String, Array as ()=>string[]],
			required: true
		},
		valueIndex: {
			type: Number,
			default(){
				return 0;
			}
		},
		multi: Boolean,
		label: {
			type: [Object as ()=>TranslateResult, String],
			default():TranslateResult{
				if (this.multi) return this.$ct("bookyear");
				return this.$ct("bookyear");
			}
		},
		filter: {
			type: Object as ()=>HumanFilter
		}
	},
	computed: {
		singleValue():string | undefined {
			if (typeof(this.value) == "object"){
				return this.value[0];
			}
			return this.value;
		},
		addSelectedBookYearOption():boolean {
			return this.filter != null;
		}
	},
	watch: {
		value: {
			deep: true,
			immediate: true,
			handler(){
				if (!this.filter) return;
				let options:string[] = [];
				if (typeof(this.value) == "string") {
					if (this.value == BookYear.SelectedBookYearValue) {
						options = [`replace ${this.valueIndex} {{SelectedBookYear}}`];
					}
				}else if (Array.isArray(this.value)) {
					this.value.forEach((value, i)=>{
						if (value == BookYear.SelectedBookYearValue) {
							options.push(`replace ${i} {{SelectedBookYear}}`);
						}
					});
				}
				this.filter.Options = options;
			}
		}
	},
	methods: {
		setSingleValue(value:string){
			this.$emit("input", `${value}`);
		}
	},
	components: {
		AppSelectBookYear,
		AppMultiSelectBookYear
	},
	i18nEx: {
		componentPrefix: "components.models"
	}
});
