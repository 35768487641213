
import AppVatSelect from "@/components/SelectVat.vue";
import { Product } from "@/models/base";
import { Vat } from "@/models/base";
import { DossierConfigService } from "@/services/dossier-config-service";
import { SellCategory } from "@/utils/sell-category";

import Vue from "vue";

export default Vue.extend({
	data() {
		return {
			pricePerUnit: 0.0,
			currentFocus: "",
			currentPercentage: 21,
			computedValues: {
				bruto: 0,
				profit: 0,
				profitPercentage: 0
			},
			DossierConfigService
		};
	},
	props: {
		priceCategory: {
			type: Number,
			required: true
		},
		product: Object as () => Product,
	},
	computed: {
		config():SellCategory {
			return DossierConfigService.getSellCategories().getCategories()[this.priceCategory - 1];
		},
		unitPriceDisabled():boolean {
			return this.config.CalculateViaParameters;
		},
		brutoPriceDisabled():boolean{
			return this.config.CalculateViaParameters;
		},
		profitDisabled():boolean {
			return this.config.CalculateViaParameters;
		},
		profitPercentDisabled():boolean {
			return this.config.CalculateViaParameters;
		},
		decimals():number{
			return this.DossierConfigService.getDecimalSettings().Unit;
		}
	},
	watch: {
		config: {
			immediate: true,
			async handler(config:SellCategory):Promise<void> {
				if (config.CalculateViaParameters) {
					let supplier = this.product.getMainSupplier();
					if (!supplier) {
						this.pricePerUnit = 0;
						this.computedValues.profitPercentage = 0.0;
					} else {
						let percentage = config.calculateProfitBasedOnParameters((supplier.NettoPrice*supplier.CurrencyRate));
						await this.$nextTick();
						this.pricePerUnit = (supplier.NettoPrice*supplier.CurrencyRate) * (1 + (percentage / 100.0));
					}
				}
			}
		},
		priceCategory: {
			immediate: true,
			handler() {
				this.updateEverything();
			}
		},
		product: {
			immediate: true,
			handler() {
				this.updateEverything();
			}
		},
		pricePerUnit(newValue: number, oldValue: number) {
			(this.product["SellPrice" + this.priceCategory as keyof Product] as number) = newValue;
			if (this.currentFocus != "price/unit") return;
			this.computeBruto();
			this.computeProfit();
			this.computeProfitPercentage();
		},
		"computedValues.bruto"(value: number) {
			if (this.currentFocus != "bruto") return;
			this.pricePerUnit = value / ((100 + this.currentPercentage) / 100.0);
			this.computeProfit();
			this.computeProfitPercentage();
		},
		"computedValues.profit"(value: number) {
			if (this.currentFocus != "profit") return;
			let mainSupplier = this.product.getMainSupplier();
			if (!mainSupplier){
				mainSupplier = this.product.Suppliers.find(s=>s.IsMainSupplier == true) || null;
			};
			if (!mainSupplier){
				return;
			}
			this.pricePerUnit = (mainSupplier.NettoPrice * mainSupplier.CurrencyRate) + value;
			this.computeProfitPercentage();
			this.computeBruto();
		},
		"computedValues.profitPercentage"(value: number) {
			if (this.currentFocus != "profitPercent") return;
			let mainSupplier = this.product.getMainSupplier();
			if (!mainSupplier) return;
			this.pricePerUnit = (mainSupplier.NettoPrice * mainSupplier.CurrencyRate) * ((value + 100) / 100.0);
			this.computeProfit();
			this.computeBruto();
			this.computeProfit();
		},
		currentPercentage() {
			this.computeBruto();
		},
		"product.Vat": {
			immediate: true,
			handler(vat: Vat) {
				if (!vat) return;
				this.product.VatID = vat.ID;
				this.currentPercentage = vat.Value;
				this.computeBruto();
			}
		},
		"product.BuyPriceComesFromComposition"(){
			this.computeProfit();
		}
	},
	methods: {
		updateEverything() {
			let newValue = this.product["SellPrice" + this.priceCategory as keyof Product] as number;
			if (newValue == this.pricePerUnit) return;
			this.pricePerUnit = newValue;
			this.computeBruto();
			this.computeProfit();
			this.computeProfitPercentage();
		},
		computeBruto() {
			this.computedValues.bruto = this.pricePerUnit * (1.0 + (this.currentPercentage / 100.0));
		},
		computeProfit() {
			if (this.product.BuyPriceComesFromComposition) {
				this.computedValues.profit = this.pricePerUnit - this.product.getBuyPriceFromComposition();
				return;
			}
			let mainSupplier = this.product.getMainSupplier();
			if (!mainSupplier) {
				this.computedValues.profit = this.pricePerUnit;
			} else {
				this.computedValues.profit = this.pricePerUnit - ( mainSupplier.NettoPrice * mainSupplier.CurrencyRate);
			}
		},
		computeProfitPercentage() {
			if (this.product.BuyPriceComesFromComposition) {
				let buyPrice = this.product.getBuyPriceFromComposition();
				this.computedValues.profit = ((this.pricePerUnit - buyPrice));
				this.computedValues.profitPercentage =  buyPrice ? ((this.pricePerUnit - buyPrice) / buyPrice) * 100.0 : 0;
				return;
			}
			let mainSupplier = this.product.getMainSupplier();
			if (!mainSupplier) {
				this.computedValues.profitPercentage = 100;
			} else {
				let supPrice = (mainSupplier.NettoPrice * mainSupplier.CurrencyRate);
				this.computedValues.profitPercentage =  supPrice ? ((this.pricePerUnit - supPrice) / supPrice) * 100.0 : 0;
			}
		}
	},
	components: {
		AppVatSelect,
	},
	i18nEx: {
		componentPrefix: "components.products"
	}
});
