
import { IModel } from "@/utils/models/model-interface";
import { SummaryCol, ISummaryColProp, ISummaryColPropOperator, SummaryColPropOperators } from "@/utils/report-engine-settings/summary-col";
import {ISelectItem}from "winfakt-vue-components";
import AppColumnSelect from "@/components/models/ColumnSelect.vue";
import Vue from "vue";

export default Vue.extend({
	data(){
		return {
			selectedProp: "",
			props: [] as ISummaryColProp[],
			col: new SummaryCol()
		};
	},
	props: {
		value: {
			type: Object as ()=>SummaryCol,
			required: true
		},
		model: {
			type: Object as ()=>IModel,
			required: true
		}
	},
	computed: {
		operators():ISelectItem<ISummaryColPropOperator>[]{
			return SummaryColPropOperators.map(o=>{
				return {
					text: o,
					value: o
				} as ISelectItem<ISummaryColPropOperator>;
			}) as ISelectItem<ISummaryColPropOperator>[];
		}
	},
	watch: {
		value: {
			immediate: true,
			handler(){
				this.col = new SummaryCol(this.value.getJSON());
				this.props = this.col.getProps();
			}
		}
	},
	methods: {
		close(){
			this.$emit("close");
		},
		confirm(){
			this.col.setProps(this.props);
			this.$emit("input", this.col);
			this.close();
		},
		addCol(){
			this.props.push({operator: "+", path: this.selectedProp.split(".")});
			this.selectedProp = "";
		},
		getPropText(prop:ISummaryColProp):string{
			return SummaryCol.getPropText(prop, this.model);
		},
		removeProp(i:number){
			this.props.splice(i,1);
		}
	},
	components: {
		AppColumnSelect
	},
	i18nEx: {
		componentPrefix: "components.reportengine"
	}
});
