import { ChatMessageFile } from "./chat-message-file";

export class ChatMessage{
	id:string = "";
	message:string = "";
	senderName:string = "";
	senderImageUrl:string = "";
	userIsSender:boolean = false;
	files:ChatMessageFile[] = [];
	dateSent:Date = new Date();
}