
import { ServerConfig } from "@/config/config";
import { Contact } from "@/models/base";
import { ContactAutofill } from "@/models/contact-autofill";
import { View } from "@/models/view";
import { Axios } from "@/utils/axios";
import { advancedSearchStaticView } from "@/utils/browse/browse";
import { IGetResponse } from "@/utils/get-response";
import { getPreloadsFromFilters, HumanFilter } from "@/utils/human-filter";
import { browseFieldPathToFilterFieldPath } from "@/utils/models/utils";
import { IQueryParams } from "@/utils/query-params";
import { isStringNullOrWhitespace } from "@/utils/strings";
import {CheckVies} from "@/models/check-vies";

export class AllContactsServiceClass {
	protected readonly url:string = `${ServerConfig.host}/all-contacts`;


	public async getContactCount():Promise<Number>{
		let result = await Axios.get(`${this.url}`);
		return result.data.records;
	}
	public async getContactByID(id:number):Promise<Contact>{
		let result = await Axios.get(`${this.url}/${id}`);
		return new Contact(result.data);
	}

	public async getContactsByIDs(ids:number[]):Promise<Contact[]>{
		let result = await Axios.get(`${this.url}/by-ids`, {params: {ids}});
		return (result.data as any[]).map(c=> new Contact(c));
	}

	public async getContacts(query:IQueryParams):Promise<IGetResponse<Contact>>{
		let preloads = getPreloadsFromFilters(query.filters || []);
		let params = {
			limit: query.limit,
			order: browseFieldPathToFilterFieldPath(query.orderBy),
			orderDirection: query.orderDirection,
			filters: query.filters,
			offset: query.offset,
			searchQuery: query.searchQuery,
			preloads,
			orderBy: "this.ID",
			view: new View()
		} as IQueryParams;

		// TODO: Make view
		let result = await advancedSearchStaticView(new View(), params, `${this.url}/view`);
		let records = result.data.records as number;
		let contacts = (result.data.data || []).map((c: any) => new Contact(c));
		return {data: contacts, records};
	}

	public async searchByName(name: string, includeInactive:boolean = false, extraFilters:HumanFilter[] = []): Promise<Contact[]> {
		let preloads = getPreloadsFromFilters(extraFilters);
		let result = await Axios.get(`${this.url}/search/${name}`, {params: {
			includeInactive,
			filters: JSON.stringify(extraFilters.map(f=>f.getJSON())),
			preloads: JSON.stringify(preloads)
		}});
		return result.data.map((c: any) => new Contact(c));
	}

	public async autofillByVatFromEU(vat: string): Promise<ContactAutofill> {
		vat = vat.replace(".", "").replace(" ", "");
		let result = await Axios.get(`${this.url}/autofill-eu/${vat}`);
		return new ContactAutofill(result.data);
	}

	public async autoFillBe(number: string): Promise<ContactAutofill | null> {
		let result = await Axios.get(`${this.url}/autofill/${number}`);
		return new ContactAutofill(result.data);
	}

	public async checkVies(number: string,countrycode: string): Promise<CheckVies | null> {

		try {
			countrycode = countrycode == "GR" ? "EL" : countrycode;
			let result = await Axios.get(`${this.url}/check-vies/${number}/${countrycode}`);
			return new CheckVies(result.data);
		}catch(err){
		}
		return null;
	}

	public async autoFill(vatNumber:string):Promise<ContactAutofill | null> {
		if (isStringNullOrWhitespace(vatNumber)){
			return null;
		}
		if (vatNumber.match(/^NL|FR|DE|IT|GB/)) {
			return AllContactsService.autofillByVatFromEU(vatNumber);
		}
		return AllContactsService.autoFillBe(vatNumber.replace(/\D/g, "") || "");
	}
}

export const AllContactsService = new AllContactsServiceClass();