
import { Condition } from "@/utils/condition/condition";
import { Model } from "@/utils/models/model";
import { ModelNamedProperty } from "@/utils/models/named-property";
import Vue from "vue";
import AppColumnSelect from "./ColumnSelect.vue";


export default Vue.extend({
	data(){
		return {
			modalOpen: false,
			modalValue: ""
		};
	},
	props: {
		value: {
			type: Object as ()=>Condition,
			required: true
		},
		model: {
			type: Object as ()=>Model,
			required: true
		}
	},
	computed: {
		selectedField():ModelNamedProperty | undefined{
			return this.model.getField(this.value.Value);
		},
		inputText():string {
			if (!this.selectedField) return "ERROR";
			return this.selectedField.getPropertyTranslation();
		}
	},
	watch: {
		value: {
			immediate: true,
			handler(){
				if(!this.value.Value){
					this.value.Value = this.getDefaultField();
					return;
				}
				let field = this.model.getField(this.value.Value);
				if (!field){
					this.value.Value = this.getDefaultField();
					return;
				}
			}
		}
	},
	methods: {
		getDefaultField():string{
			return this.model.getFilterFields()[0].pathToFilterPath();
		},
		openModal(){
			this.modalValue = this.value.Value;
			this.modalOpen = true;
		},
		setModalValue(){
			this.value.Value = this.modalValue;
			this.modalOpen = false;
		}
	},
	components: {
		AppColumnSelect
	},
	i18nEx: {
		componentPrefix: "components.models"
	}
});
