import { render, staticRenderFns } from "./PageSettings.vue?vue&type=template&id=daca74bc&scoped=true"
import script from "./PageSettings.vue?vue&type=script&lang=ts"
export * from "./PageSettings.vue?vue&type=script&lang=ts"
import style0 from "./PageSettings.vue?vue&type=style&index=0&id=daca74bc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "daca74bc",
  null
  
)

export default component.exports