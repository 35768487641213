import { ReportEngineSettings } from "./report-engine-settings";
import { SettingsColumn } from "./settings-column";

export class OrderGroupTotalColumn {
	public ASC:boolean = false;
	public SettingsColumn:SettingsColumn;

	public constructor(settingsColumn:SettingsColumn, asc:boolean) {
		this.SettingsColumn = settingsColumn;
		this.ASC = asc;
	}

	public getJSON(settings:ReportEngineSettings){
		return {
			SettingsColumnIndex: settings.Columns.findIndex(s=>this.SettingsColumn == s),
			ASC: this.ASC
		};
	}

}