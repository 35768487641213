import { EventEmitter } from "./event-emitter";

export enum FileEvent {
	FILE_PASTED = "file-pasted"
}

export const FileEventEmitter = new EventEmitter();

document.addEventListener("paste", function(event:ClipboardEvent){
	if (!event.clipboardData) return;
	let items = event.clipboardData.items;
	for (let item of items){
		if (item.kind == "file"){
			let file = item.getAsFile();
			FileEventEmitter.emit(FileEvent.FILE_PASTED, file);
		}
	}
});

export function isTypeImage(mimeType:string):boolean{
	let keyWords = ["image", "png", "jpg", "gif"];
	for (let word of keyWords){
		if (mimeType.indexOf(word) != -1){
			return true;
		}
	}
	return false;
}