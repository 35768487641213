import { Axios } from "@/utils/axios";
import { ServerConfig } from "@/config/config";
import { Mandat } from "@/models/payment/mandat";
import { PriceFrequencyType, Pricing } from "@/models/payment/pricing";
import { Subscription } from "@/models/payment/subscription";
import { Plan } from "@/utils/payments/plan";
import { Invoice } from "@/models/payment/invoice";
import { HttpError } from "@/utils/services/http-error";
import { Tier } from "@/utils/payments/tiers";
import { PlanModule } from "@/utils/payments/plan-module";
import { PlanModulePricing } from "@/utils/payments/plan-module-pricing";
import { ComputedPricing } from "@/models/payment/computed-pricing";
import router from "@/router";
import { PlanModulePricingFactory } from "@/utils/payments/plan-module-pricing-factory";
import { AuthService } from "./auth-service";
import { Company } from "@/models/company";
import { BrowserInfo } from "@/utils/browser";

export interface IUpgradePricing {
	pricing: ComputedPricing;
	startDate: Date;
}

export class PaymentServiceClass {
	readonly pricingUrl: string = `${ServerConfig.auth}/pricing`;
	readonly mandatesUrl: string = `${ServerConfig.auth}/mandates`;
	readonly subscriptionUrl: string = `${ServerConfig.auth}/subscriptions`;
	readonly freeSubscriptionUrl: string = `${ServerConfig.auth}/free-subscription`;
	readonly lifetimeUrl: string = `${ServerConfig.auth}/lifetime-payments`;
	readonly invoiceUrl: string = `${ServerConfig.auth}/invoices`;
	readonly servicePackUrl: string = `${ServerConfig.auth}/service-pack`;
	readonly extendUrl:string = `${ServerConfig.auth}/extend-plan`

	async verifyCustomer(): Promise<any> {
		await Axios.get(`${this.pricingUrl}/verify`, {params: {}});
		return;
	}

	async addMandate(plan?: Plan): Promise<string> {
		let data = {plan: null} as any;
		if (plan) {
			data.plan = plan.getJSON();
		}
		let result = await Axios.post(`${this.mandatesUrl}`, data);
		return result.data.link;
	}

	async getMandates(): Promise<Mandat[]> {
		try {
			let result = await Axios.get(`${this.mandatesUrl}`, {params: {}});
			return result.data.map((m: any) => new Mandat(m)) || [];
		} catch (err) {
			let error = err as HttpError;
			if (error.key == "MOLLIE_CUSTOMER_ID_REQUIRED") {
				error.dontShow = true;
			}
			throw err;
		}
	}

	async deleteMandate(id: string): Promise<Mandat[]> {
		let result = await Axios.delete(`${this.mandatesUrl}/${id}`);
		return result.data.map((m: any) => new Mandat(m));
	}

	async setMandateAsActive(mandateId: string): Promise<void> {
		await Axios.post(`${this.mandatesUrl}/${mandateId}/set-active`);
	}

	async getActiveSubscription(): Promise<Subscription> {
		let result = await Axios.get(`${this.subscriptionUrl}`);
		return new Subscription(result.data);
	}

	async createSubscription(plan: Plan, mandateId: string, discountCode?: string): Promise<boolean> {
		let result = await Axios.post(`${this.subscriptionUrl}`, {plan: plan.getJSON(), mandateId, discountCode});

		if (result.data.PaymentUrl != "") {
			this.openUrl(result.data.PaymentUrl);
			return false;
		}
		return true;
	}

	async cancelSubscription(password: string): Promise<void> {
		await Axios.post(`${this.subscriptionUrl}/cancel`, {password});
	}

	async upgradeSubscription(tier: Tier, newModules: PlanModule[], mandateId: string): Promise<boolean> {
		let result = await Axios.post(`${this.subscriptionUrl}/upgrade`, {
			tier,
			mandateId,
			newModules: newModules.map(m => m.getJSON())
		});
		if (result.data.PaymentUrl != "") {
			this.openUrl(result.data.PaymentUrl);
			return false;
		}
		return true;
	}

	async calculateUpgradePrice(tier: Tier, newModules: PlanModule[]): Promise<IUpgradePricing> {

		let result;
		try {
			result = await Axios.post(`${this.subscriptionUrl}/calculate-upgrade-price`, {
				tier,
				newModules: newModules.map(m => m.getJSON())
			});
		}catch(err) {
			let error = err as HttpError;
			error.dontShow = true;
			throw err;
		}
		return {
			pricing: new ComputedPricing(result.data.pricing),
			startDate: new Date(result.data.startDate),
		};
	}

	async calculatePermanentUpgradePrice(plan:Plan):Promise<ComputedPricing>{
		let result = await Axios.post(`${this.lifetimeUrl}/calculate-upgrade-price`, {
			plan: plan.getJSON(),
		});
		return new ComputedPricing(result.data);
	}

	async permanentUpgrade(plan:Plan):Promise<void>{
		let result = await Axios.post(`${this.lifetimeUrl}/upgrade`, {
			plan: plan.getJSON(),
		});
		if (result.data.PaymentUrl != "") {
			this.openUrl(result.data.link);
		}
	}

	async getPricing(): Promise<Pricing> {
		let result;
		try {
			result = await Axios.get(`${this.pricingUrl}`, {params: {}});
		} catch (err) {
			let error = err as HttpError;
			if (error.key == "ERR_NOT_COMPANY_OWNER") {
				error.dontShow = true;
			}
			throw err;
		}
		return new Pricing(result.data);
	}

	async getAllAvailableModules(tier: Tier): Promise<PlanModulePricing[]> {
		let result = await Axios.get(`${this.pricingUrl}/modules/${tier}`);
		return result.data.map((m: any) => PlanModulePricingFactory.create(m));
	}

	async getPriceOfPlan(plan: Plan, discountCode?: string): Promise<ComputedPricing> {
		let result;
		try {
			result = await Axios.post(`${this.pricingUrl}/get-pricing-for-plan`, {
				plan: plan.getJSON(),
				discountCode
			});
		} catch (err) {
			let error = err as HttpError;
			if (error.key == "DISCOUNT_NOT_FOUND") {
				error.dontShow = true;
			}
			throw err;
		}
		return result.data;
	}

	async getCurrentPlan(): Promise<Plan> {
		let result = await Axios.get(`${this.subscriptionUrl}/plan`);
		return new Plan(result.data);
	}

	async getInvoices(): Promise<Invoice[]> {
		let result = await Axios.get(`${this.invoiceUrl}`);
		return result.data.map((i: any) => new Invoice(i));
	}

	async cancelInvoice(id: string): Promise<Invoice> {
		let result = await Axios.post(`${this.invoiceUrl}/${id}/cancel`);
		return new Invoice(result.data);
	}

	async changeFrequency(frequency: PriceFrequencyType): Promise<void> {
		await Axios.post(`${this.subscriptionUrl}/change-frequency`, {frequency});
	}

	async getPricingOfPermanentPlan(plan: Plan, discountCode?: string): Promise<ComputedPricing> {
		return new ComputedPricing((await Axios.post(`${this.lifetimeUrl}/get-pricing-of-plan`, {
			plan: plan.getJSON(),
			discountCode
		})).data);
	}

	private  openUrl(url:string){
		if (BrowserInfo.isElectron()){
			window.open(url);
		}else{
			location.href = url;
		}
	}

	async buyLifetimePlan(plan: Plan, discountCode?: string): Promise<void> {
		try {
			let result = await Axios.post(`${this.lifetimeUrl}/buy`, {plan: plan.getJSON(), discountCode});
			this.openUrl(result.data.link);
		} catch (err) {
			throw err;
		}
	}

	async getServicePackPrice(): Promise<{ price: number, priceExcVat: number, vat: number, canBuy: boolean }> {
		let result = await Axios.get(`${this.servicePackUrl}/pricing`);
		return result.data;
	}

	async buyServicePack(): Promise<void> {
		let result = await Axios.post(`${this.servicePackUrl}/buy`);
		this.openUrl(result.data.link);
	}

	async getExtendPricing():Promise<ComputedPricing>{
		let result = await Axios.get(`${this.extendUrl}/pricing`);
		return result.data;
	}

	async extendPlan():Promise<void>{
		let result = await Axios.post(`${this.extendUrl}/create-subscription`);
		if (result.data.responseType == "redirect"){
			this.openUrl(result.data.link);
			return;
		}
		router.push("/");
		setTimeout(()=>{
			location.reload();
		}, 1000);
	}

	async openBuyModule(mod:PlanModulePricing){
		if (AuthService.wfCompany.IsDemo || AuthService.wfCompany.isPermanentlyActive()) {
			router.push("/account/price");
			return;
		}
		try{
			let plan = new Plan();
			plan.tier = Tier.ENTERPRISE;
			plan.addModule(mod);
			router.push(plan.getUpgradeRoute());
		}catch(err){
			router.push("/account/price");
			return;
		}
	}

	async activateFreeTier():Promise<Company>{
		let result = await Axios.post(`${this.freeSubscriptionUrl}/activate-free-tier`);
		let company = new Company(result);
		AuthService.wfCompany = company;
		return company;
	}

	async requestEnterprise(){
		await Axios.post(`${this.subscriptionUrl}/request-enterprise`);
	}
};

export const PaymentService = new PaymentServiceClass();