export interface IUserConfig {
	ID:number;
	UserID: number;
	SettingsKey: string;
	Value: {getJSON(): any};
	getJSON():any;
}

export class UserConfig implements IUserConfig {
	ID:number;
	UserID:number;
	SettingsKey:string;
	Value:{getJSON():any};

	constructor(data: any) {
		this.ID = data.ID;
		this.UserID = data.UserID;
		this.SettingsKey = data.SettingsKey;
		this.Value = data.Value;
	}

	getJSON(this: IUserConfig) {
		return {
			ID: this.ID,
			UserID: this.UserID,
			SettingsKey: this.SettingsKey,
			Value: this.Value.getJSON()
		};
	}
}
