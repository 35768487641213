
import Vue from "vue";
import AppContactSearch from "@/components/contacts/ContactSearch.vue";
import { TranslateResult } from "vue-i18n";
import { Contact, ContactAddress } from "@/models/base";
import { v4 } from "uuid";
import { AllContactsService } from "@/services/all-contacts-service";

export default Vue.extend({
	data(){
		return {
			contacts: [] as Contact[],
			fetchThreadId: v4()
		};
	},
	props: {
		value: {
			type: [String, Array as ()=>string[]],
			required: true
		},
		multi: Boolean,
		label: {
			type: [Object as ()=>TranslateResult, String],
			default():TranslateResult{
				if (this.multi) return this.$ct("components.contacts.contacts");
				return this.$ct("components.contacts.contact");
			}
		}
	},
	computed: {
		singleValue():Contact | null {
			return this.contacts[0];
		},
		nummericValues():number[]{
			if (typeof(this.value) == "string"){
				let val = parseInt(this.value || "0");
				if (Number.isNaN(val)){
					val = 0;
				}
				if (val == 0){
					return [];
				}
				return [val];
			}
			return this.value.map(v=>{
				let val = parseInt(v || "0");
				if (Number.isNaN(val)){
					val = 0;
				}
				return val;
			}).filter(v=>v>0);
		}
	},
	watch: {
		value: {
			immediate: true,
			handler(){
				this.checkAndFetchContacts();
			}
		}
	},
	methods: {
		async checkAndFetchContacts(){
			let threadId = v4();
			this.fetchThreadId = threadId;
			let storedContacts = [...this.contacts];
			let notFoundContactIds = [];
			for (let val of this.nummericValues) {
				let contact = storedContacts.find(f=>f.ID == val);
				if (!contact){
					notFoundContactIds.push(val);
				}
			}

			if (notFoundContactIds.length > 0){
				let fetchedContacts = await AllContactsService.getContactsByIDs(notFoundContactIds);
				storedContacts.push(...fetchedContacts);
			}
			if (this.fetchThreadId != threadId){
				return;
			}
			this.contacts = this.nummericValues.map(id=>storedContacts.find(s=>s.ID == id) || this.getErrorContact());
		},
		getErrorContact():Contact{
			let c = new Contact();
			c.MainAddress = new ContactAddress();
			c.MainAddress.CompanyName = "ERROR";
			return c;
		},
		setSingleValue(value:Contact){
			this.$emit("input", `${value.ID}`);
		},
		addContact(value:Contact){
			let foundContact = this.contacts.find(c=>c.ID == value.ID);
			if (foundContact) return;
			this.contacts.push(value);
			let val = this.contacts.map(v=>`${v.ID}`).filter(v=>v != "0");
			this.$emit("input", val);
		},
		removeContact(value:number){
			this.contacts.splice(value,1);
			let val = this.contacts.map(v=>`${v.ID}`).filter(v=>v != "0");
			this.$emit("input", val);
		}
	},
	components: {
		AppContactSearch
	},
	i18nEx: {
		componentPrefix: "components.models"
	}
});
