
import { Model } from "@/utils/models/model";
import { ModelNamedProperty } from "@/utils/models/named-property";
import { browseFieldPathToFilterFieldPath, filterFieldPathToBrowseFieldPath } from "@/utils/models/utils";
import Vue from "vue";
import AppColumnSelect from "./ColumnSelect.vue";

export default Vue.extend({
	data(){
		return {
			modalOpen: false,
			modalValue: ""
		};
	},
	props: {
		value: String,
		label: String,
		model: {
			type: Object as ()=>Model,
			required: true
		}
	},
	computed: {
		selectedField():ModelNamedProperty | undefined{
			return this.model.getFilterField(this.value);
		},
		inputText():string {
			if (!this.selectedField) return "ERROR";
			return this.selectedField.getPropertyTranslation();
		}
	},
	watch: {
		value: {
			immediate: true,
			handler(){
				if(this.value == null){
					this.$emit("input", this.getDefaultField());
					return;
				}
				let field = this.model.getFilterField(this.value);
				if (!field){
					this.$emit("input", this.getDefaultField());
					return;
				}
			}
		}
	},
	methods: {
		getDefaultField():string{
			return this.model.getFilterFields()[0].pathToFilterPath();
		},
		openModal(){
			this.modalValue = filterFieldPathToBrowseFieldPath(this.value);
			this.modalOpen = true;
		},
		setModalValue(){
			this.$emit("input", browseFieldPathToFilterFieldPath(this.modalValue));
			this.modalOpen = false;
		}
	},
	components: {
		AppColumnSelect
	},
	i18nEx: {
		componentPrefix: "components.models"
	}
});
