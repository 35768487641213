
import AppMobileFullScreenContainer from "@/components/common/MobileFullScreenContainer.vue";
import AppToolbar from "@/components/common/ToolbarBase.vue";
import { Contact, ContactExtraFields } from "@/models/base";
import Vue from "vue";
import { createConfirmBuilder } from "../../setup/confirm-builder";
import AppTab2 from "./ContactEditAddresses.vue";
import AppTab1 from "./ContactEditDefault.vue";
import AppTab4 from "./ContactEditExtraInfo.vue";
import AppTab3 from "./ContactEditExtraFields.vue";
import AppTab5 from "./ContactEditAwsDocuments.vue";
import AppTab6 from "./ContactEditPriceRules.vue";
import { getContactServiceByGroupId } from "../../utils/services/contact-services";
import { ISelectItem } from "winfakt-vue-components";
import { getModel } from "@/utils/models/model";
import { v4 } from "uuid";
import {DossierConfigService} from "@/services";
import {ShopService} from "@/services/shop-service";

export default Vue.extend({
	data() {
		return {
			tab: 0,
			storedNextFriendlyId: {
				computedFriendlyId: "",
			},
			checkingVat: ""
		};
	},
	props: {
		value: {
			type: Object as () => Contact,
			required: true
		},
		drawerButtonDisabled: Boolean,
	},
	computed: {
		saveDisabled():boolean {
			return false;
		},
		currentTab(): string {
			return this.tabs[this.tab];
		},
		tabs(): any {
			return this.$ct("tabs");
		},
		contact(): Contact {
			return this.value;
		},
		title():string {
			let titleKey = "titles.";

			let titleGroups = ["client", "supplier", "prospect"];
			titleKey += titleGroups[this.value.GroupID - 1];
			titleKey += "-";

			if (this.value.ID) {
				titleKey += "edit";
			} else {
				titleKey += "new";
			}
			return this.$ct(titleKey).toString();
		},
		lastLoadedDisabled():boolean{
			if (this.value.ID != 0){
				return true;
			}
			let contactService = getContactServiceByGroupId(this.value.GroupID);
			return !contactService.lastOpenedItem.hasStoredItem();
		},
		dropDownItems():ISelectItem<string>[]{
			return [{
				text: this.$ct("load-last-opened"),
				value: "load-last-opened",
				disabled: this.lastLoadedDisabled
			}];
		}
	},
	methods: {
		setCalculatedFields(){
			if (!this.contact.ExtraFields){
				this.contact.ExtraFields = new ContactExtraFields();
			}
			let model = getModel("Contact");
			let fields = model.getCalculatedFields();
			console.log(fields);
			for (let field of fields) {
				field.storeExtraFieldValue(this.contact, this.contact.ExtraFields);
			}
		},
		async save() {
			if (this.saveDisabled) return;
			let contactService = getContactServiceByGroupId(this.value.GroupID);
			this.setCalculatedFields();
			if (this.contact.ID == 0 || this.contact.ID == undefined) {
				if (this.storedNextFriendlyId.computedFriendlyId != this.contact.ComputedFriendlyID) {
					this.contact.FriendlyID = 0;
				}
				var contact = (await contactService.postContact(this.contact));
			} else {
				var contact = (await contactService.putContact(this.contact));
			}

			if(DossierConfigService.getGeneralConfig().AutoShopSynch){
				await ShopService.contact(contact.ID);
			}

			contact = await contactService.getContact(contact.ID);
			this.$emit("input", contact);
			this.$emit("save");
			this.$wf.notify(this.$ct("save-success").toString(), "success", 3000, "check");
		},
		async cancel() {
			this.$emit("cancel");
		},
		async remove() {
			let contactService = getContactServiceByGroupId(this.value.GroupID);
			let confirm = await createConfirmBuilder.bind(this)(this.$ct("confirm-delete")).setTitle(this.$ct("common.warning")).setClass("warning").show();
			if (!confirm) return;
			let result = await contactService.delContacts([this.contact]);
			if (!result) {
				await this.$wf.dialog(this.$ct("error-cannot-delete"), this.$t("common.back"), this.$t("common.attention"), "warning");
			} else {
				this.$emit("delete");
				this.$wf.notify(this.$t("common.success").toString(), "success", 3000, "check");
			}
		},
		loadLastOpenedItem(){
			let contactService = getContactServiceByGroupId(this.value.GroupID);
			let contact = new Contact(contactService.lastOpenedItem.getLastOpenedItem());
			this.$emit("input", contact);
		},
		dropdownInput(value:string){
			if (value == "load-last-opened"){
				this.loadLastOpenedItem();
			}
		},
		async fetchFriendlyId(){
			if (this.contact.ID != 0) return;
			let contactService = getContactServiceByGroupId(this.contact.GroupID);
			let result = await contactService.getNextAvailableComputedID();
			this.storedNextFriendlyId.computedFriendlyId = result.computedFriendlyId;
			this.contact.FriendlyID = result.friendlyId;
			this.contact.ComputedFriendlyID = result.computedFriendlyId;
		},
		async checkIfVatExists():Promise<boolean>{
			let threadId = v4();
			try{
				this.checkingVat = threadId;
				if (!this.contact.Vat) return false;
				let contactService = getContactServiceByGroupId(this.contact.GroupID);
				let contacts = await contactService.getByVat(this.contact.Vat);
				if (this.checkingVat != threadId) return false;
				for (let contact of contacts) {
					if (contact.ID == this.contact.ID) {
						return true;
					}
				}
				if (contacts.length) {
					let message = this.$ct("exists-vat-confirm");
					await this.$wf.dialog(message, this.$ct("common.ok"), this.$ct("common.warning"), "warning");
				}
				return true;
			}catch(err){

			}finally{
				if (this.checkingVat == threadId) {
					this.checkingVat = "";
				}
			}
			return false;
		},
	},
	async mounted(){
		await this.fetchFriendlyId();
	},
	components: {
		AppTab1,
		AppTab2,
		AppTab3,
		AppTab4,
		AppTab5,
		AppToolbar,
		AppMobileFullScreenContainer,
		AppTab6
	},
	i18nEx: {
		componentPrefix: "components.contacts"
	}

});
