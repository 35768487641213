import { PrintFunction } from "./model-field";

export interface IProperty {
	name: string;
	path: string;
	active: boolean;
	print?:PrintFunction;
}


export function getValueByPath(value:any, path:string):any{
	let parts = path.split(".");
	let currentVal = value;
	for (let part of parts){
		if (!currentVal){
			return false;
		}
		currentVal = currentVal[part];
	}
	return currentVal;
}