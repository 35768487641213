import { Browse } from "@/models/browse";
import { i18n } from "@/setup/i18n-setup";
import {v4 as uuid} from "uuid";
import { TranslateResult } from "vue-i18n";
import { IModelProperty } from "./models/model-field";
import { IModel } from "./models/model-interface";
import { filterFieldPathToBrowseFieldPath } from "./models/utils";
import { getQuotedTexts, isStringNullOrWhitespace, removeDuplicatesFromStringList, splitByQuotesOrWhitespace } from "./strings";
import { Model } from "./models/model";

export type HumanFilterOperation = "=" | ">" | "<" | ">=" | "<=" |
	"!like" | "like" | "!=" | "^" | "!^" | "$" | "!$" |
	"in" | "between" | "between date" | "in" | "not in" | "is null" | "is not null" | "is date" | "not date" | "< date" | "> date" | ">= date" | "<= date" |
	"within days ago" |"within months ago" | "within years ago" |
	"over days ago" | "over months ago" | "over years ago" |
	"within days" | "within months" | "within years" |
	"over days" | "over months" | "over years"

export class HumanFilter{

	public static getTranslationOperator(operator: string): string {
		let operators = [
			{val: "=", text: "eq"}, {val: "!=", text: "neq"},
			{val: "<", text: "lt"}, {val: ">", text: "gt"},
			{val: ">=", text: "gte"}, {val: "<=", text: "lte"},
			{val: "between", text: "between"}, {val: "between date", text: "between date"},
			{val: "like", text: "like"}, {val: "!like", text: "!like"},
			{val: "^", text: "sw"}, {val: "!^", text: "nsw"},
			{val: "$", text: "ew"}, {val: "!$", text: "new"},
			{val: "is null", text: "is null"}, {val: "is not null", text: "is not null"},
			{val: "in", text: "in"}, {val: "not in", text: "not in"},
			{val: "is date", text: "is"},
			{val: "not date", text: "is not"},
			{val: "< date", text: "before"},
			{val: "> date", text: "after"},
			{val: ">= date", text: "after or at"},
			{val: "<= date", text: "before or at"},
			{val: "within days ago", text: "within days ago"},{val: "within months ago", text: "within months ago"},{val: "within years ago", text: "within years ago"},
			{val: "over days ago", text: "over days ago"},{val: "over months ago", text: "over months ago"},{val: "over years ago", text: "over years ago"},
			{val: "within days", text: "within days"},{val: "within months", text: "within months"},{val: "within years", text: "within years"},
			{val: "over days", text: "over days"},{val: "over months", text: "over months"},{val: "over years", text: "over years"},
		];
		let res = operators.find((o) => o.val == operator);
		if (!res) {
			throw Error("Invalid operator: " + operator);
		}
		return res.text;
	}

	public static getRelativeDateOperators():HumanFilterOperation[] {
		return ["within days ago", "within months ago" , "within years ago" ,
			"over days ago" , "over months ago" , "over years ago" ,
			"within days" , "within months" , "within years" ,
			"over days" , "over months" , "over years"];
	}

	public static isRelativeDateOperator(operator:HumanFilterOperation):boolean {
		return this.getRelativeDateOperators().indexOf(operator) != -1;
	}

	// eslint-disable-next-line max-lines-per-function
	public static getQuickFilters(fields: string[], searchQuery: string, model?:Model | null): HumanFilter[] {
		let allContains = false;
		if (searchQuery[0] == "%") {
			allContains = true;
			searchQuery = searchQuery.substring(1);
		}
		let parts = splitByQuotesOrWhitespace(searchQuery).filter(v=> !isStringNullOrWhitespace(v));
		let quotedParts = getQuotedTexts(searchQuery);
		let enableOrOperator = false;
		if (quotedParts.length > 0) {
			enableOrOperator = true;
		}
		let allFilters:HumanFilter[] = [];
		let prevPart = "";
		for (let part of parts){
			let originalPart = part;
			let operator = "^" as HumanFilterOperation;
			if (allContains) {
				operator = "like";
			} else if (part.startsWith("*")) {
				operator = "$";
				part = part.substring(1);
				if (part.endsWith("*")) {
					operator = "like";
					part = part.substring(0, part.length - 1);
				}
			} else if (part.endsWith("*")) {
				part = part.substring(0, part.length - 1);
			}

			let filters = fields.map((field, i) => {
				let filter = new HumanFilter({
					Field: field,
					Operator: operator as HumanFilterOperation,
					Values: [part],
					IsOr: i != 0,
				});
				HumanFilter.getQuickFiltersAddFloatHandling(filter, model);
				return filter;
			});
			if (filters.length > 0) {
				filters[0].StartWhereGroup = 1;
				filters[filters.length-1].CloseWhereGroup = 1;
				if (prevPart == "OF" && enableOrOperator) {
					filters[0].IsOr = true;
				}
			}
			allFilters.push(...filters);
			prevPart = originalPart;
		}
		return allFilters;
	}

	private static getQuickFiltersAddFloatHandling(filter:HumanFilter, model?:Model | null){
		if (!model) return;
		let field = model.getFilterField(filter.Field);
		if (!field) return;
		if (!field.field.decimals) return;
		filter.RoundDecimals = field.field.decimals;
	}

	public readonly key:string = uuid();
	public Field: string = "";
	public Operator: HumanFilterOperation = "=";
	public Values: string[] = [];
	public IsOr: boolean = false;
	public Options:string[] = [];
	public UseFieldValues:boolean[] = [];
	public StartWhereGroup:number = 0;
	public CloseWhereGroup:number = 0;
	public RoundDecimals:number = 0;

	constructor(data?: {
		Field: string, Operator?: HumanFilterOperation, Values?: string[],
		IsOr?: boolean, Options?:string[], UseFieldValues?:boolean[],
		StartWhereGroup?:number, CloseWhereGroup?:number
	}) {
		if (!data) {
			return;
		}
		this.Field = data.Field;
		this.Operator = data.Operator || "=";
		this.Values = data.Values || [];
		this.IsOr = data.IsOr || false;
		this.Options = data.Options || [];
		this.UseFieldValues = data.UseFieldValues || [];
		this.StartWhereGroup = data.StartWhereGroup || 0;
		this.CloseWhereGroup = data.CloseWhereGroup || 0;
		// Fixes issue from when where groups where stored as booleans
		if ((data.StartWhereGroup as any) === true) {
			this.StartWhereGroup = 1;
		}
		if ((data.CloseWhereGroup as any) === true) {
			this.CloseWhereGroup = 1;
		}
		this.makeUseFieldValuesArraySameLengthAsValues();
	}

	private makeUseFieldValuesArraySameLengthAsValues(){
		if (this.Values.length < this.UseFieldValues.length) {
			this.UseFieldValues.splice(this.Values.length);
		}
		if (this.Values.length > this.UseFieldValues.length) {
			for (let i = this.UseFieldValues.length; i < this.Values.length; i++) {
				this.UseFieldValues.push(false);
			}
		}
	}

	public getField():string{
		return this.Field;
	}

	public getText(): string {
		if (this.Operator == "between") {
			return this.Field + " BETWEEN " + this.Values[0] + " AND " + this.Values[1];
		}
		let values = this.Values.length;
		if (values == 0) {
			return this.Field + " " + this.Operator;
		}
		if (values > 1) {
			return this.Field + " " + this.Operator + " (" + this.Values.join(", ") + ")";
		}
		return this.Field + " " + this.Operator + " " + this.Values[0];
	}


	getJSON() {
		this.makeUseFieldValuesArraySameLengthAsValues();
		return {
			Field: this.Field,
			Operator: this.Operator,
			Values: [...this.Values],
			IsOr: this.IsOr,
			Options: [...this.Options],
			UseFieldValues: [...this.UseFieldValues],
			StartWhereGroup: this.StartWhereGroup,
			CloseWhereGroup: this.CloseWhereGroup,
			RoundDecimals: this.RoundDecimals
		};
	}

	getProcessedJson(model:Model){
		let js = this.getJSON();
		let field = model.getFilterField(this.Field);
		if (field) {
			if (field.field.type == "number"){
				js.Values = this.processValuesAsNumbers();
			}
		}
		return js;
	}

	private processValuesAsNumbers():any[] {
		return this.Values.map(v=>{
			if (v == null) return null;
			return v.replaceAll(",", ".");
		});
	}

	private operatorIsDateRelativeToNow():boolean {
		return ([
			"within days ago" ,"within months ago" , "within years ago" ,
			"over days ago" , "over months ago" , "over years ago" ,
			"within days" , "within months" , "within years" ,
			"over days" , "over months" , "over years"
		] as HumanFilterOperation[]).indexOf(this.Operator) > -1;
	}

	public getFilterValueType(model:IModel, browse:Browse | null):string{
		if (this.operatorIsDateRelativeToNow()) {
			return "number";
		}
		let path = filterFieldPathToBrowseFieldPath(this.Field);
		if (browse){
			let override = browse.getFilterTypeOverrideByPath(path);
			if (override) return override;
		}
		let field = model.getField(path);
		if (!field) {
			return "string";
		}
		if (field.field.filterValueInput) {
			return field.field.filterValueInput;
		}
		return field.field.type;
	}

	getTranslatedText(model: IModel, browse?:Browse | null):TranslateResult {
		let namedFilter = model.getAvailableNamedFilters().find((i)=>i.name.trim() == this.Field.trim());
		if (namedFilter) {
			return namedFilter.filterToString(this);
		}
		let prop = model.getFilterField(this.Field);
		let fieldName = "FIELD INVALID";
		if (prop) {
			fieldName = prop.getPropertyTranslation();
			if (browse) {
				let override = browse.getColumnNameOverride(prop.path);
				if (override) {
					fieldName = override;
				}
			}
		}
		if (this.operatorIsDateRelativeToNow()){
			let count =  parseInt(this.Values[0] || "0");
			return i18n.tc(`common.filters.computed.${this.Operator}`, count, {prop: fieldName});
		}
		if (this.Operator == "between") {
			return i18n.t("common.filters.computed.between", {
				prop: fieldName,
				val1: this.getTranslatedTextGetValue(model, browse, 0),
				val2: this.getTranslatedTextGetValue(model, browse, 1)
			});
		}
		if (this.Values.length == 0) {
			return i18n.t("common.filters.computed." + HumanFilter.getTranslationOperator(this.Operator), {prop: fieldName});
		}
		if (this.Values.length > 1) {
			return i18n.t("common.filters.computed." + HumanFilter.getTranslationOperator(this.Operator), {
				prop: fieldName, val: this.Values.map((v,i)=>this.getTranslatedTextGetValue(model, browse,i)).join(", ")
			});
		}
		return i18n.t("common.filters.computed." + HumanFilter.getTranslationOperator(this.Operator), {
			prop: fieldName, val: this.getTranslatedTextGetValue(model, browse, 0),
		});
	}

	private getTranslatedTextGetValue(model: IModel, browse:Browse | null | undefined, valueIndex:number):string{
		let val = this.Values[valueIndex];
		if (!val) {
			return "";
		}
		let useFieldValue = this.UseFieldValues[valueIndex];
		if (useFieldValue) {
			let prop = model.getFilterField(val);
			if (!prop){
				return val;
			}
			if (browse) {
				let override = browse.getColumnNameOverride(prop.path);
				if (override) {
					return override;
				}
			}
			return prop.getPropertyTranslation();
		}
		for (let option of this.Options) {
			if (option.startsWith("replace")) {
				let parts = option.split(" ");
				if (parts.length < 3) {
					continue;
				}
				if (parts[1] != `${valueIndex}`) {
					continue;
				}
				parts.shift();
				parts.shift();
				let val = parts.join(" ");
				if (val == "{{SelectedBookYear}}") {
					return i18n.t("common.selected-bookyear").toString();
				}
			}
		}
		return val;
	}


	public getValueAmount(): number {
		return HumanFilter.getValueAmount(this.Operator);
	}

	public static getValueAmount(operator:HumanFilterOperation):number{
		if (operator == "between" || operator == "between date") {
			return 2;
		}
		if (operator == "in" || operator == "not in") {
			return -1;
		}
		if (operator == "is null" || operator == "is not null") {
			return 0;
		}
		return 1;
	}

	public getPreloads():string[] {
		let result:string[] = [];
		if (this.Field.startsWith("this_")){
			result.push(this.Field.split(".")[0].replace(/\_/g, ".").substring(5));
		}
		this.makeUseFieldValuesArraySameLengthAsValues();
		for (let i in this.Values){
			if (this.UseFieldValues[i]){
				if ((this.Values[i] || "").startsWith("this_")){
					result.push(this.Values[i].split(".")[0].replace(/\_/g, ".").substring(5));
				}
			}
		}
		return result;
	}

	public hasOption(option:string):boolean {
		for (let o of this.Options){
			if (o == option) return true;
		}
		return false;
	}

	public removeOption(option:string){
		for (let i = this.Options.length-1; i >= 0; i--){
			if (this.Options[i] == option) {
				this.Options.splice(i, 1);
			}
		}
	}

	public addUniqueOption(option:string){
		for (let o of this.Options){
			if (o == option) return;
		}
		this.Options.push(option);
	}

	public static getReplaceWithTodaysDateOption(index:number):string{
		return `replace ${index} {{Today}}`;
	}

	public static getReplaceWithSelectedBookYearOption(index:number):string{
		return `replace ${index} {{SelectedBookYear}}`;
	}
}

export function getPreloadsFromFilters(filters: HumanFilter[]): string[] {
	let preloads = filters.map((f) => f.getPreloads()).flat();
	return preloads.filter((p: string, i: number) => {
		return preloads.indexOf(p) == i;
	});
}


export function getValidOperatorsForFieldType(field:IModelProperty):string[]{
	let fieldType = field.type;
	let nummericOperators:HumanFilterOperation[] = ["=", "!=", "<", ">", ">=", "<=", "between"];
	let stringOperators:HumanFilterOperation[] = ["=", "!=", "like", "!like", "^", "!^", "$", "!$"];
	let boolOperators:HumanFilterOperation[] = ["=", "!="];
	let dateOperators:HumanFilterOperation[] = ["is date", "not date", "< date", "> date", ">= date", "<= date", "between date",
		"within days ago" ,"within months ago" , "within years ago" ,
		"over days ago" , "over months ago" , "over years ago" ,
		"within days" , "within months" , "within years" ,
		"over days" , "over months" , "over years"
	];
	let nullableFilters:HumanFilterOperation[] = ["is null", "is not null"];
	let list = [] as HumanFilterOperation[];
	if (fieldType == "string") {
		list = list.concat(stringOperators);
	}
	if (fieldType == "number" || fieldType == "duration" || field.canBeFilteredNummerically) {
		nullableFilters = [];
		list = list.concat(nummericOperators);
	}
	if (fieldType == "boolean") {
		list = list.concat(boolOperators);
	}
	if (fieldType == "date") {
		list = list.concat(dateOperators);
	}
	if (fieldType) {
		list = nullableFilters.concat(list);
	}
	if (fieldType != "boolean") {
		list = list.concat("in", "not in");
	}
	// Remove duplicates
	removeDuplicatesFromStringList(list);
	return list;
}
