import { Thread } from "@/models/chat/thread";

export class ThreadUser {
	ID: number = 0;
	ThreadID: number = 0;
	Thread: Thread = new Thread();
	UserID: number = 0;
	IsOwner: boolean = false;
	Pinned: boolean = false;

	constructor(data?: any) {
		if (!data) return;
		this.ID = data.ID;
		this.ThreadID = data.ThreadID;
		this.Thread = data.Thread;
		this.UserID = data.UserID;
		this.IsOwner = data.IsOwner;
		this.Pinned = data.Pinned;
	}

}