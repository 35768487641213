
import Vue from "vue";
import { ISelectItem } from "winfakt-vue-components";
import { DocumentPageSettings } from "../../utils/documents/page-settings";
import AppColorPicker from "@/components/ColorPicker.vue";

export default Vue.extend({
	props: {
		value: {
			type: Object as () => DocumentPageSettings,
			required: true
		}
	},
	computed: {
		fontItems(): ISelectItem <string>[] {
			return [
				"Arial",
				"Comic Sans MS",
				"Georgia",
				"Open Sans",
				"Roboto",
				"Times New Roman",
				"Verdana",
				"Tahoma"
			].map(v => {
				return {
					text: v,
					value: v
				};
			});
		},
		fontSizes(): ISelectItem <string>[] {
			return [8,9,10, 11, 12, 13, 14, 15, 16, 17, 18].map(v => {
				return {
					text: v + "pt",
					value: v + ""
				};
			});
		},
		headerBorderOptions(): ISelectItem <number>[] {
			return Object.values(this.$ct("header-borders")).map((k, v) => {
				return {value: v, text: k};
			});
		}
	},
	components: {
		AppColorPicker
	},
	i18nEx: {
		componentPrefix: "components.document-settings"
	}
});
