
import { durationToString, isValidDurationString, stringToDuration } from "@/utils/duration";
import Vue from "vue";

export default Vue.extend({
	data(){
		return {
			computedValue: "00:00",
			currentInputValue: "00:00"
		};
	},
	props: {
		fancy: Boolean,
		value: {
			type: Number,
		},
		label: String
	},
	watch: {
		value: {
			immediate: true,
			handler(input:number){
				this.computedValue = durationToString(input);
				this.currentInputValue = this.computedValue;
			}
		}
	},
	methods: {
		onInput(value:string){
			this.currentInputValue = value;
		},
		onBlur(){
			if (!isValidDurationString(this.currentInputValue)) {
				this.currentInputValue = this.computedValue;
				return;
			}
			this.$emit("input", stringToDuration(this.currentInputValue));
		},
		onKeyUp(event:KeyboardEvent){
			this.$emit("keyup", event);
		}
	}
});
