
import Vue from "vue";
import { Script } from "../../models/script";
import { ISelectItem } from "winfakt-vue-components";
import { ReportService } from "../../services/report-service";
import { BrowseService } from "../../services/browse-service";
import { Browse } from "../../models/browse";

export default Vue.extend({
	data(){
		return {
			browses: [] as Browse[],
			scripts: [] as Script[],
			standardScripts: [] as Script[],
			sourcesLoadedCount: 0,
		};
	},
	props: {
		browseId: {
			type: Number,
			required: true
		}
	},
	computed: {
		sources():ISelectItem<string>[]{
			return [
				...this.browses.map(b=>{
					return {
						text: b.Name.getTranslation(this.$i18n.locale),
						value: `browse/${b.ID}`
					};
				}),
				...this.standardScripts.map(s=>{
					return {
						text: this.$ct(s.Name),
						value: s.Name
					};
				}),
				...this.scripts.map(s=>{
					return {
						text: s.Name,
						value: `script/${s.ID}`
					};
				}),
			];
		},
		sourcesLoaded():boolean{
			return this.sourcesLoadedCount >= 3;
		}
	},
	methods: {
		async loadBrowses(){
			try{
				this.browses = await BrowseService.getBrowses();
			}catch(err){
			}
			this.sourcesLoadedCount++;
		},
		async loadScripts(){
			try{
				this.scripts = await ReportService.getReportEngineScripts();
			}catch(err){
			}
			this.sourcesLoadedCount++;
		},
		async loadStandardScripts(){
			try{
				this.standardScripts = await ReportService.getStandardReportEngineScripts();
			}catch(err){
			}
			this.sourcesLoadedCount++;
		},
		selectSource(dataSource:string){
			this.$emit("source", dataSource);
		},
		cancel(){
			this.$emit("cancel");
		},
	},
	created(){
		this.loadBrowses();
		this.loadScripts();
		this.loadStandardScripts();
	},
	i18nEx: {
		componentPrefix: "components.reportengine"
	}
});
