
import { DossierConfigService } from "@/services/dossier-config-service";
import { CustomTranslationWithoutFallback } from "@/utils/custom-translation-without-fallback";
import Vue from "vue";
import { isoLangs } from "../utils/languages";
import {UserConfigService} from "@/services";
export default Vue.extend({
	data(){
		return {
			active: 0,
			maxHeight: 150
		};
	},
	props: {
		value: {
			type: Object as () => CustomTranslationWithoutFallback,
			required: true
		},
		useTextarea: Boolean,
		forceInput: Boolean
	},
	computed: {
		languages():string[]{
			return DossierConfigService.getLanguages().LanguageNames;
		},
		tabs(): string[] {
			return this.languages.map((l) => {
				return this.getLabel(l);
			});
		},
		showEditor():Boolean {
			return UserConfigService.getSaleSettings().DescriptionEditor && !this.forceInput;
		},
		lang():string{
			return this.languages[this.active];
		}
	},
	methods: {
		getLabel(lang:string):string{
			return isoLangs[lang].nativeName;
		},
		onInput(value: string, lang:string){
			let regex = /style="(.*?)"/gm;
			value = value.replace(regex, "");
			value = value.replaceAll("<blockquote >", "<blockquote>");
			value = value.replaceAll("<br>", "{__NEW_LINE__}");
			value = value.replaceAll("<div>", "{__NEW_LINE__}");
			value = value.replaceAll("</div>", "");
			value = value.replaceAll("&nbsp;&nbsp;", "{__DOUBLE_SPACE__}");
			this.value.Translations[lang] = value;
		},
		computeMaxHeight(){
			this.maxHeight = window.innerHeight - 210;
		}
	},
	created(){
		window.addEventListener("resize", this.computeMaxHeight);
		this.computeMaxHeight();
	},
	destroyed(){
		window.removeEventListener("resize", this.computeMaxHeight);
	}
});
