export class PdfImage {
	Image:Blob;

	public constructor(data:any){
		let decoded = atob(data.Image);
		let byteNumbers = new Array(decoded.length);
		for (let i = 0; i < decoded.length; i++) {
			byteNumbers[i] = decoded.charCodeAt(i);
		}
		this.Image = new Blob([new Uint8Array(byteNumbers)], {type: "image/jpg"});
	}
}