
import { Product } from "@/models/base";
import Vue from "vue";
import AppSellPrices from "./ProductEditSellPrices.vue";
import AppEditTranslatedNames from "./ProductEditTranslatedNames.vue";
import AppProductEditGroups from "./ProductEditGroups.vue";
import AppSelectUnit from "./SelectUnit.vue";
import AppImages from "./ProductEditImages.vue";
import AppProductEditAccounting from "./ProductEditAccounting.vue";
import { UserConfigService } from "@/services/user-config-service";
import { Unit } from "@/models/unit";
import { CustomTranslation } from "@/utils/custom-translation";
import { UnitService } from "@/services/unit-service";
import AppInputWithTextareaModal from "@/components/common/InputWithTextareaModal.vue";
import { ProductService } from "@/services";
import { TranslateResult } from "vue-i18n";
import { SaleConfigs } from "@/models/dossier-configs/sale-configs";
import {DossierConfigService} from "@/services";
import {DecimalConfig} from "@/models/dossier-configs/decimal-config";

export default Vue.extend({
	data() {
		return {
			translatedNamesModal: false,
			creatingBarCode: false,
			DossierConfigService
		};
	},
	props: {
		product: {
			type: Object as () => Product,
			required: true
		},
		autoAssignProductSku: Boolean
	},
	computed: {
		decimalSettings(): DecimalConfig {
			return DossierConfigService.getDecimalSettings();
		},
		showUnitSelect() {
			return UserConfigService.getSaleSettings().ShowUnits;
		},
		hasSkuError():boolean {
			if (!this.product.Sku) return false;
			return this.product.Sku.length > 20;
		},
		showEditor():Boolean {
			return UserConfigService.getSaleSettings().DescriptionEditor;
		},
		generateBarCodeIcon():string | undefined {
			if (this.product.BarCode) {
				return undefined;
			}
			return "magic";
		},
		saleConfigs():SaleConfigs{
			return this.DossierConfigService.getSaleConfigs();
		},
		showQty2():boolean{
			return this.saleConfigs.UseQty2;
		},
		skuAndBarcodeMd():number{
			if (this.showQty2){
				return 4;
			}
			return 6;
		},
		defaultValueQty2Label():TranslateResult{
			if (this.saleConfigs.Qty2Label){
				return this.$ct("labels.default-qty-2-value-custom-label", {label: this.saleConfigs.Qty2Label});
			}
			return this.$ct("labels.default-qty-2-value");
		}
	},
	methods: {
		setProductDescription(value: string) {
			this.product.Name = value;
			if (this.product.TranslatedName.Translations[this.$i18n.locale]) {
				this.product.TranslatedName.Translations[this.$i18n.locale] = value;
			}
		},
		verifySku(){
			this.$emit("verify-sku");
		},
		async onUnitBlur(value: any) {
			if (!this.product.UnitID && value) {
				let confirm = await
					this.$wf.confirm(this.$ct("unit-not-found",
						{unit: value}), this.$ct("common.yes"), this.$ct("common.no"), this.$ct("common.warning"), "info");
				if (confirm) {
					let c = new CustomTranslation();
					c.Translations[this.$i18n.locale] = value;
					let u = new Unit({Name: c});
					let r = await UnitService.postUnit(u);
					if(r){
						this.product.UnitID = r.ID;
					}
				}else{
					this.product.UnitID = null;
				}
			}
		},
		onUnitSelect(value: Unit) {
			this.product.UnitID = value.ID;
		},
		async generateRandomBarCode(){
			if (this.creatingBarCode) return;
			this.creatingBarCode = true;
			try{
				this.product.BarCode = await ProductService.generateRandomBarCode();
			}catch(err){

			}finally{
				this.creatingBarCode = false;
			}
		}
	},
	components: {
		AppSellPrices,
		AppEditTranslatedNames,
		AppProductEditGroups,
		AppSelectUnit,
		AppInputWithTextareaModal,
		AppImages,
		AppProductEditAccounting
	},
	i18nEx: {
		componentPrefix: "components.products"
	},
});
