export class PurchaseConfig{
	PurchasesFromInboxreaderEnabled:boolean = false;
	PurchasesFromInboxreaderAllowedFroms:string[] = [];

	public constructor(data?:any){
		if (!data) return;
		this.PurchasesFromInboxreaderEnabled = data.PurchasesFromInboxreaderEnabled || false;
		this.PurchasesFromInboxreaderAllowedFroms = data.PurchasesFromInboxreaderAllowedFroms || [];
	}

	public getJSON(){
		return {
			PurchasesFromInboxreaderEnabled: this.PurchasesFromInboxreaderEnabled,
			PurchasesFromInboxreaderAllowedFroms: [...this.PurchasesFromInboxreaderAllowedFroms]
		};
	}
}