import { IModelProperties, IModelProperty } from "@/utils/models/model-field";
import { ModelNamedProperty } from "@/utils/models/named-property";
import { IModel } from "./model-interface";
import { browseFieldPathToFilterFieldPath, filterFieldPathToBrowseFieldPath } from "./utils";
import { DefaultNamedProperty } from "./default-named-property";
import { NamedFilter } from "./named-filter";
import { CalculatedField } from "@/models/calculated-field";

export const models:Model[] = [];

export const maxGetFieldsDepth:number = 4;

export class Model implements IModel {
	public modelName: string = "";
	public constructorFunc:Function;
	public fields: IModelProperties = {};
	private calculatedFields:CalculatedField[] = [];

	// Used for faster deletion
	private calculatedFieldKeys:string[] = [];

	public constructor(constructorFunc:Function){
		this.constructorFunc = constructorFunc;
	}


	get hasOneAssociations(): IModelProperty[] {
		return Object.keys(this.fields).map((fieldName: string) => {
			return this.fields[fieldName];
		}).filter((field: IModelProperty) => field.type == "has-one");
	}

	public getIgnoredFields(): string[] {
		return [];
	}

	public getPriorityFields(): string[] {
		return [];
	}

	public getField(browseField: string, depth: number = 0):ModelNamedProperty | undefined {
		if (depth > maxGetFieldsDepth) {
			return;
		}
		let parts = browseField.split(".");
		if (parts.length == 1) {
			let field = this.fields[browseField];
			if (!field) return undefined;
			return new DefaultNamedProperty(browseField, field, this.modelName);
		}
		let association = this.fields[parts[0]];
		if (!association || !association.modelName) {
			return;
		}
		let firstElem = parts.shift();
		let model = getModel(association.modelName);
		let field = model.getField(parts.join("."), depth + 1);
		if (!field) {
			return undefined;
		}
		field.path = `${firstElem}.${field.path}`;
		return field;
	}

	public getName():string{
		return this.modelName;
	}

	public getFilterField(filterField: string):ModelNamedProperty | undefined {
		let field = this.filterFieldPathToBrowseFieldPath(filterField);
		return this.getField(field);
	}

	public getBrowseField(browseField:string):ModelNamedProperty | undefined {
		let allProps = this.getBrowseFields();
		return allProps.find(p => p.path == browseField);
	}

	getTopLevelFields():ModelNamedProperty[] {
		let result = [] as ModelNamedProperty[];
		for (let key of Object.keys(this.fields)) {
			let field = this.fields[key];
			if (field.hidden) {
				continue;
			}
			result.push(new DefaultNamedProperty(`this.${key}`, field, this.modelName));
		}
		return result;
	}

	getBrowseFields(depth: number = 0): ModelNamedProperty[] {
		if (depth > maxGetFieldsDepth) {
			return [];
		}
		let result: ModelNamedProperty[] = [];
		for (let key of Object.keys(this.fields)) {
			let field = this.fields[key];
			if (field.type == "has-many") continue;
			if (field.hidden) continue;

			if (field.type == "has-one") {
				let model = getModel(field.modelName || "");
				result = result.concat(model.getBrowseFields(depth + 1).map((f) => new DefaultNamedProperty(key + "." + f.path, f.field, f.modelName)));
			} else {
				result.push(new DefaultNamedProperty(key, field, this.modelName));
			}
		}
		// Remove duplicate fields e.g. Contact.MainAddress.Contact.ID
		result = result.filter((f) => {
			for (let fieldName of this.getIgnoredFields()) {
				let exp = new RegExp("^" + fieldName.replace(".", "\.") + "(\.|$)");
				if (f.path.match(exp)) {
					return false;
				}
			}
			return true;
		});
		return result;
	}

	getFilterFields():ModelNamedProperty[] {
		return this.getBrowseFields().filter(f=>{
			if (f.field.isComputed) return false;
			if (f.field.notFilterable) return false;
			return true;
		});
	}


	browseFieldPathToFilterFieldPath(path: string): string {
		return browseFieldPathToFilterFieldPath(path);
	}
	filterFieldPathToBrowseFieldPath(path: string): string {
		return filterFieldPathToBrowseFieldPath(path);
	}

	public getAvailableNamedFilters():NamedFilter[]{
		return [];
	}

	static setCalculatedFields(fields:CalculatedField[]){
		for (let model of models){
			for (let key of model.calculatedFieldKeys){
				delete model.fields[key];
			}
			model.calculatedFieldKeys = [];
		}
		for (let field of fields) {
			let model = getModel(field.Table);
			model.calculatedFields.push(field);
			let key = `calculated_field_${field.ID}`;
			model.fields[key] = field.toModelProperty();
			model.calculatedFieldKeys.push(key);
		}
	}

	getCalculatedFields():CalculatedField[]{
		return this.calculatedFields;
	}
}

export function getModel(modelName: string): Model {
	for (let model of models){
		if (model.modelName == modelName){
			return model;
		}
	}

	throw new Error("MODEL NOT FOUND: " + modelName + " " + Object.keys(models));
}

export function getModelByConstructor(constructorFunc:Function, createNew:boolean = false):Model{
	for (let model of models){
		if (model.constructorFunc == constructorFunc){
			return model;
		}
	}
	if (createNew){
		let model = new Model(constructorFunc);
		models.push(model);
		return model;
	}

	throw new Error("MODEL BY CONSTRUCTOR NOT FOUND");
}

