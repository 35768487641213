import { FolderUser } from "@/models/tasks/folder-user";
import { Task } from "@/models/tasks/task";
import { SocketEventManager } from "@/utils/socket-event-manager";

export class Folder {
	ID:number = 0;
	Name:string = "";
	FolderUsers:FolderUser[] = [];
	Tasks:Task[] = [];

	private socketEventManager?:SocketEventManager;

	constructor(data?:any) {
		if(!data) return;
		this.ID = data.ID;
		this.Name = data.Name;
		if (data.FolderUsers) {
			this.FolderUsers = data.FolderUsers.map((u:any) => new FolderUser(u));
		}
		if (data.Tasks) {
			this.Tasks = data.Tasks.map((t:any) => new Task(t));
		}
	}

	getJSON() {
		return {
			ID: this.ID,
			Name: this.Name
		};
	}

}