import { isTypeImage } from "./file";

export class ChatMessageFile {
	fileName:string = "";
	fileType:string = "";
	fileUrl:string = "";
	onFileClick:Function|null = null;

	isImage():boolean{
		return isTypeImage(this.fileType);
	}
}