import { ServerConfig } from "@/config/config";
import { Folder } from "@/models/tasks/folder";
import { FolderUser } from "@/models/tasks/folder-user";
import { Task, TaskStatus } from "@/models/tasks/task";
import { TaskMessage } from "@/models/tasks/task-message";
import { Axios } from "@/utils/axios";
import { EventSourceInput } from "@fullcalendar/core/structs/event-source";
import { EventInput } from "@fullcalendar/core/structs/event";
import { HumanFilter } from "@/utils/human-filter";
import { RepeatedTask } from "@/models/tasks/repeated-task";

export class TaskServiceClass{
	readonly folderUrl: string = `${ServerConfig.host}/tasks/folder`;
	readonly taskUrl: string = `${ServerConfig.host}/tasks/task`;
	readonly repeatedTaskUrl: string = `${ServerConfig.host}/tasks/repeated-task`;

	async getFolder(folderId: number): Promise<FolderUser> {
		let result = await Axios.get(`${this.folderUrl}/${folderId}`);
		return new FolderUser(result.data);
	}

	async getFolders(): Promise<FolderUser[]> {
		let result = await Axios.get(`${this.folderUrl}`);
		let folders = (result.data.map((f: any) => new FolderUser(f)) as FolderUser[]);
		return folders.sort((a, b) => a.OrderNumber - b.OrderNumber);
	}

	async createFolder(folder: Folder): Promise<FolderUser> {
		let result = await Axios.post(`${this.folderUrl}`, [folder.getJSON()]);
		return new FolderUser(result.data[0]);
	}

	async renameFolder(folder: Folder, newName: string): Promise<Folder> {
		let result = await Axios.post(`${this.folderUrl}/${folder.ID}/rename/${newName}`);
		return new Folder(result.data);
	}

	async deleteFolder(folder: Folder): Promise<void> {
		await Axios.delete(`${this.folderUrl}/${folder.ID}`);
	}

	async setOwnedFolderOrder(folders: Folder[]): Promise<void> {
		await Axios.post(`${this.folderUrl}/personal/sort`, folders.map(f => f.ID));
	}

	async setSharedFolderOrder(folders: Folder[]): Promise<void> {
		await Axios.post(`${this.folderUrl}/shared/sort`, folders.map(f => f.ID));
	}

	async addUsersToFolder(folder: Folder, users: number[]): Promise<Folder> {
		let result = await Axios.post(`${this.folderUrl}/${folder.ID}/add-users`, users);
		return new Folder(result.data);
	}

	async removeUserFromFolder(folder: Folder, users: number[]): Promise<Folder> {
		let result = await Axios.post(`${this.folderUrl}/${folder.ID}/remove-users`, users);
		return new Folder(result.data);
	}

	async getTasksByFolderId(folderId: number): Promise<Task[]> {
		let result = await Axios.get(`${this.taskUrl}/by-folder/${folderId}`);
		return result.data.map((t: any) => new Task(t));
	}

	async getCompletedTasksByFolderId(folderId: number): Promise<Task[]> {
		let result = await Axios.get(`${this.taskUrl}/by-folder-completed/${folderId}`);
		return result.data.map((t: any) => new Task(t));
	}

	async postTask(task: Task): Promise<Task> {
		let result = await Axios.post(`${this.taskUrl}`, task.getJSON());
		return new Task(result.data);
	}

	async putTask(task: Task): Promise<Task> {
		let result = await Axios.put(`${this.taskUrl}/${task.ID}`, task.getJSON());
		return new Task(result.data);
	}

	async deleteTask(task: Task): Promise<void> {
		await Axios.delete(`${this.taskUrl}/${task.ID}`);
	}

	async setTaskStatus(task: Task, status: TaskStatus): Promise<Task> {
		let url = "/set-pending";
		if (status == TaskStatus.UNCONFIRMED) {
			url = "/set-unconfirmed";
		} else if (status == TaskStatus.COMPLETED) {
			url = "/set-completed";
		}

		let result = await Axios.post(`${this.taskUrl}/${task.ID}${url}`);
		return new Task(result.data);
	}

	async sortTasks(folder: Folder, tasks: Task[]): Promise<Task[]> {
		let result = await Axios.post(`${this.taskUrl}/${folder.ID}/sort`, tasks.map(t => t.ID));
		return result.data.map((t: any) => new Task(t));
	}

	async getTask(taskId: number): Promise<Task> {
		let result = await Axios.get(`${this.taskUrl}/${taskId}`);
		return new Task(result.data);
	}

	async postMessage(taskId: number, message: string, file?: File | null): Promise<TaskMessage> {
		let data = new FormData();
		data.append("message", message);
		if (file) {
			data.append("file", file);
		}
		let result = await Axios.post(`${this.taskUrl}/${taskId}/post-message`, data);
		return new TaskMessage(result.data);
	}

	async markMessagesAsRead(taskId: number): Promise<void> {
		await Axios.post(`${this.taskUrl}/${taskId}/read-messages`);
	}


	async getWidgetTasks(filters:HumanFilter[]): Promise<Task[]> {
		let result = await Axios.get(`${this.taskUrl}/widget`, {params: {filters: filters.map(f=>f.getJSON())}});
		return result.data.map((t: any) => new Task(t));
	}

	async getTasksWithUnreadMessages(): Promise<Task[]> {
		let result = await Axios.get(`${this.taskUrl}/unread-messages`);
		let tasks = result.data.map((t: any) => new Task(t));
		return tasks;
	}

	async getTaskMessages(taskId: number, oldestId: number | null = null): Promise<TaskMessage[]> {
		if (oldestId) {
			var result = await Axios.get(`${this.taskUrl}/${taskId}/messages/${oldestId}`);
		} else {
			var result = await Axios.get(`${this.taskUrl}/${taskId}/messages`);
		}
		return result.data.map((m: any) => new TaskMessage(m));
	}

	async getTasksByFolderIdsAndBetweenDates(folderIds: number[], beginDate: Date, endDate: Date): Promise<Task[]> {
		let result = await Axios.get(`${this.taskUrl}/by-folders-and-between-dates`, {
			params: {
				folderIds: folderIds.map(f => `${f}`).join(","),
				beginDate: Math.floor(beginDate.getTime() / 1000),
				endDate: Math.ceil(endDate.getTime() / 1000)
			}
		});

		return result.data.map((t: any) => new Task(t));
	}

	async getRepeatedTasksByFolderId(folderId:number):Promise<RepeatedTask[]>{
		let result = await Axios.get(`${this.repeatedTaskUrl}/by-folder/${folderId}`);
		return (result.data || []).map((i:any)=>new RepeatedTask(i));
	}

	async createdRepeatedTask(task:RepeatedTask):Promise<RepeatedTask>{
		let result = await Axios.post(`${this.repeatedTaskUrl}`, task.getJSON());
		return new RepeatedTask(result.data);
	}

	async updateRepeatedTask(task:RepeatedTask):Promise<RepeatedTask>{
		let result = await Axios.put(`${this.repeatedTaskUrl}/${task.ID}`, task.getJSON());
		return new RepeatedTask(result.data);
	}

	async stopRepeatedTask(task:RepeatedTask):Promise<RepeatedTask> {
		let result = await Axios.delete(`${this.repeatedTaskUrl}/${task.ID}`);
		return new RepeatedTask(result.data);
	}

	createFullCalendarEventSource(folders: Folder[]): EventSourceInput {
		return {
			async events(info, onSuccess, onFail): Promise<EventInput[]> {
				if (folders.length == 0) {
					return [];
				}
				let tasks = await TaskService.getTasksByFolderIdsAndBetweenDates(folders.map(f => f.ID), info.start, info.end);

				return tasks.map(e => {
					let res = e.toFullCalendar();
					res.backgroundColor = "#4bd395";
					res.classNames = "task-item";
					return res;
				});

			}
		};
	}
};

export const TaskService = new TaskServiceClass();