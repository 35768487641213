export class Price {
	tier0: number = 0;
	tier1: number = 0;
	tier2: number = 0;

	constructor(data?:any){
		if (!data)return;
		this.tier0 = data.tier0;
		this.tier1 = data.tier1;
		this.tier2 = data.tier2;
	}

	getPriceOfTier(tier:number):number{
		if (tier == -1) {
			return 0;
		}
		if (tier == 0){
			return this.tier0;
		}
		if (tier == 1){
			return this.tier1;
		}
		return this.tier2;
	}
}