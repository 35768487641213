
import AppTranslationInput from "@/components/TranslationInput.vue";
import Vue from "vue";
import { CustomTranslation } from "../../utils/custom-translation";

export default Vue.extend({
	data() {
		return {
			translatedNames: new CustomTranslation()
		};
	},
	props: {
		value: {
			type: Object as () => CustomTranslation,
			required: true
		}
	},
	watch: {
		value: {
			immediate: true,
			handler() {
				this.translatedNames = new CustomTranslation(this.value.getJSON());
			}
		}
	},
	methods: {
		close() {
			this.$emit("close");
		},
		submit() {
			this.$emit("input", new CustomTranslation(this.translatedNames.getJSON()));
			this.close();
		}
	},
	components: {
		AppTranslationInput
	},
	i18nEx: {
		componentPrefix: "components.products"
	}
});
