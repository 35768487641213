import { v4 } from "uuid";
import { IModel } from "../models/model-interface";

export type ISummaryColPropOperator = "+" | "-" | "*" | "/"
export const SummaryColPropOperators:ISummaryColPropOperator[] = ["+" , "-" , "*", "/"];
export interface ISummaryColProp {
	path:string[];
	operator:ISummaryColPropOperator;
}

export class SummaryCol {
	uid:string = v4();

	PreText:string = "";
	PostText:string = "";
	Col:string = "";

	public constructor(data?:any) {
		if (!data) return;
		this.PreText = data.PreText;
		this.PostText = data.PostText;
		this.Col = data.Col.trim();
	}

	public getJSON(){
		return {
			PreText: this.PreText,
			PostText: this.PostText,
			Col: this.Col
		};
	}

	static getPropText(p:ISummaryColProp, model:IModel):string {
		let field = model.getField(p.path.join("."));
		if (field){
			return field.getPropertyTranslation();
		}
		return p.path.join(".");
	}

	public getText(model:IModel){
		let col = "value";
		if (this.Col == ""){
			col = "";
		}else{
			let props = this.getProps();
			col = props.map(p=>{
				return p.operator + SummaryCol.getPropText(p, model);
			}).join(" ");
			if (col.length > 0 && (SummaryColPropOperators as string[]).indexOf(col.charAt(0)) != -1){
				col = col.substring(1);
			}
		}
		return `${this.PreText} ${col} ${this.PostText}`;
	}

	getProps():ISummaryColProp[]{
		if (this.Col.length == 0){
			return [];
		}
		return this.Col.split(",").map(p=>{
			let operator = p.charAt(0);
			let prop = p.substring(1);
			if ((SummaryColPropOperators as string[]).indexOf(operator) == -1) {
				operator = "+";
				prop = p;
			}
			return {
				operator,
				path: prop.split(".")
			} as ISummaryColProp;
		});
	}

	setProps(props:ISummaryColProp[]) {
		this.Col = props.map(p=>{
			return p.operator + p.path.join(".");
		}).join(",");
	}
}