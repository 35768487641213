
import Vue from "vue";
import { ISelectItem } from "winfakt-vue-components";
import { ReportEngineSettings } from "../../utils/report-engine-settings/report-engine-settings";
import AppColumns from "./Columns.vue";
import AppFilters from "./Filters.vue";
import AppGroups from "./Groups.vue";
import AppLoadReportSettingsCard from "./LoadReportSettingsCard.vue";
import AppPageSettings from "./PageSettings.vue";
import AppSaveReportSettingsCard from "./SaveReportSettingsCard.vue";
// import AppOrderGroupTotals from "./OrderGroupTotals.vue";
import AppSorting from "./Sorting.vue";
import { IModel } from "../../utils/models/model-interface";
import AppAdvancedSettings from "./AdvacnedSettings.vue";
import { AuthService } from "@/services/auth-service";
import { downloadBlob } from "@/utils/blob";
import AppUploadReportSettingsCard from "./UploadReportSettingsCard.vue";
import AppSummaryEdit from "./SummaryEdit.vue";
import { TranslateResult } from "vue-i18n";
import { ReportService } from "@/services";
import { ReportEngineSettingsModel } from "@/models/report-engine-settings";


export default Vue.extend({
	data() {
		return {
			tab: 0,
			saveSettingsModalOpened: false,
			loadSettingsModalOpened: false,
			uploadSettingsModalOpened: false,
			loadedSettings: null as null | ReportEngineSettingsModel
		};
	},
	props: {
		value: {
			type: Object as () => ReportEngineSettings,
			required: true
		},
		model: {
			type: Object as () => IModel,
			required: true
		},
		browseId: {
			type: Number,
			required: true
		}
	},
	computed: {
		tabs(): TranslateResult[] {
			return [
				this.$ct("columns"),
				this.$ct("filters"),
				this.$ct("groups"),
				this.$ct("sorting"),
				//	this.$ct("total-sorting"),
				this.$ct("summary"),
				this.$ct("page-settings"),
				this.$ct("advanced-settings"),
			];
		},
		dropDownItems(): ISelectItem<string>[] {
			return [
				{
					text: this.$ct("save-settings").toString(),
					value: "openSaveModal"
				},
				{
					text: this.$ct("load-settings").toString(),
					value: "loadSettings"
				}
			];
		},
		isDevMode():boolean{
			return AuthService.isDevModeEnabled;
		}
	},
	methods: {
		async saveReportSettings() {
			if (!this.loadedSettings){
				this.saveAsReportSettings();
				return;
			}
			this.loadedSettings.Settings = this.value;
			await ReportService.updateReportEngineSettings(this.loadedSettings);
			this.$wf.notify(this.$ct("settings-saved"), "success", 3000, "check");
		},
		saveAsReportSettings(){
			this.saveSettingsModalOpened = true;
		},
		openSavedReports() {
			this.loadSettingsModalOpened = true;
		},
		onSettingsLoad(settings: ReportEngineSettings, model?:ReportEngineSettingsModel) {
			this.loadedSettings = model || null;
			this.$emit("source", settings.DataSource);
			this.$emit("input", settings);
		},
		onSave(settings:ReportEngineSettingsModel){
			this.loadedSettings = settings;
			this.$emit("save");
		},
		downloadSettings(){
			let content = JSON.stringify(this.value.getJSON());
			downloadBlob(new Blob([content], {type: "text/json"}), "custom-report.json");
		},
		openUploadSettingsModal(){
			this.uploadSettingsModalOpened = true;
		},
		async openChangeDataSourceModal(){
			let message = this.$ct("changing-the-data-source-will-reset-all-your-settings-are-you-sure-you-wanna-continue");
			let confirm = await this.$confirmBuilder().setWarningTitle().setMessage(message).show();
			if (!confirm) return;
			this.$emit("source", "");
			this.$emit("input", new ReportEngineSettings());
		}
	},
	components: {
		AppColumns,
		AppFilters,
		AppGroups,
		AppSorting,
		AppSaveReportSettingsCard,
		AppLoadReportSettingsCard,
		AppPageSettings,
		AppAdvancedSettings,
		//	AppOrderGroupTotals,
		AppUploadReportSettingsCard,
		AppSummaryEdit
	},
	i18nEx: {
		componentPrefix: "components.reportengine"
	}
});
