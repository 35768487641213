import { IDataTableContextMenuEvent, ISelectItem } from "winfakt-vue-components";
import { BrowseStateBase } from "./browse-state-base";

export class BrowseContextMenuState {
	private state:BrowseStateBase;

	mouseX:number = 0;
	mouseY:number = 0;
	item:any = null;
	opened:boolean = false;
	items:ISelectItem<string | Function>[] = [];

	public constructor(state:BrowseStateBase){
		this.state = state;
	}


	onContextMenuClick(event:IDataTableContextMenuEvent, selectedItems:any[]) {
		if (this.opened) {
			this.opened = false;
			return;
		}
		if (event.item == null && selectedItems.length == 0) {
			return;
		}
		this.mouseX = event.event.pageX;
		this.mouseY = event.event.pageY;
		this.item = event.item;
		this.opened = true;
	}

	trigger(value:string){
		this.opened = false;
		this.state.emit("context-menu-trigger", {value, item: this.item});
	}
}