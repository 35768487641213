import { ServerConfig } from "@/config/config";
import { TicketMessage } from "@/models/tickets/message";
import { Ticket } from "@/models/tickets/ticket";
import { AuthSocketService } from "./auth-socket-service";
import { Axios } from "@/utils/axios";

export class TicketServiceClass{
	public tickets:Ticket[] = [];
	public readonly url:string = ServerConfig.auth + "/tickets";
	public get unreadTickets():Ticket[] {
		return this.tickets.filter(t => !t.isRead());
	}

	public constructor(){
		AuthSocketService.on("/tickets/new", (data:any) => {

			let ticket = new Ticket(data);
			ticket.registerSocketEvents();
			this.tickets.push(ticket);
		});
	}

	async getTickets(force?:Boolean):Promise<Ticket[]> {
		if (this.tickets.length != 0 && !force) {
			return this.tickets;
		}
		let result = await Axios.get(`${this.url}`);
		this.tickets = result.data.map((t:any) => new Ticket(t));
		this.tickets.forEach(t => t.registerSocketEvents());
		return this.tickets;
	}

	async getTicket(id:number):Promise<Ticket> {
		let result = await Axios.get(`${this.url}/${id}`);
		return new Ticket(result.data);
	}

	async generateTicketUrl(id:number):Promise<string> {
		let result = await Axios.get(`${this.url}/generate-url/${id}`);
		return location.protocol + "//" + location.host + "/ticket-url/" + result.data.Token;
	}

	async newTicket(subject:string, content:string, attachements:File[],priority:string, departement:string):Promise<Ticket> {
		let data = new FormData();
		data.append("subject", subject);
		data.append("content", content);
		data.append("departement", departement);
		data.append("priority", priority);
		data.append("fileCount", `${attachements.length}`);
		for (let i = 0; i < attachements.length; i++) {
			data.append(`file${i}`, attachements[i], attachements[i].name);
		}
		let result = await Axios.post(`${this.url}`, data);
		return new Ticket(result.data);
	}

	async sendMessage(ticketId:number, content:string, attachements:File[]):Promise<TicketMessage> {
		let data = new FormData();
		data.append("content", content);
		data.append("fileCount", `${attachements.length}`);
		for (let i = 0; i < attachements.length; i++) {
			data.append(`file${i}`, attachements[i], attachements[i].name);
		}
		let result = await Axios.post(`${this.url}/${ticketId}/send-message`, data);
		return new TicketMessage(result.data);
	}

	async markAsRead(ticketId:number):Promise<Ticket> {
		let result = await Axios.post(`${this.url}/${ticketId}/mark-as-read`);
		return new Ticket(result.data);
	}

	async closeTicket(ticketId:number):Promise<Ticket> {
		let result = await Axios.post(`${this.url}/${ticketId}/close`);
		return new Ticket(result.data);
	}

	async archiveTicket(ticketId:number):Promise<Ticket> {
		let result = await Axios.post(`${this.url}/${ticketId}/archive`);
		return new Ticket(result.data);
	}

	async deleteTicket(ticketId:number):Promise<Ticket> {
		let result = await Axios.post(`${this.url}/${ticketId}/delete`);
		return new Ticket(result.data);
	}

	async openTicket(ticketId:number):Promise<Ticket> {
		let result = await Axios.post(`${this.url}/${ticketId}/open`);
		return new Ticket(result.data);
	}

	async deleteLastMessage(ticketId:number):Promise<Ticket>{
		let result = await Axios.delete(`${this.url}/${ticketId}/last-message`);
		return new Ticket(result.data);
	}

	async getTicketFromUrlToken(token:string):Promise<Ticket>{
		let result = await Axios.get(`${this.url}/url/${token}`);
		return new Ticket(result.data);
	}

	onLogout(){
		this.tickets = [];
	}

	async init(){
		this.getTickets();
	}
};

export const TicketService = new TicketServiceClass();