import Product from "./product";
import { field, filterOnly, filterValueInput, hidden, nullable } from "@/utils/models/decorator-field";
import { model } from "@/utils/models/decorator-model";
import { RowConfig } from "./sale-row";
import { CustomTranslation } from "@/utils/custom-translation";

@model("ProductCompositionRow")
export default class ProductCompositionRow{
	@field("number")
	public ID:number = 0;

	@field("number")
	@filterValueInput("Product")
	@filterOnly()
	public ProductID:number = 0;

	@field("number")
	public Amount:number = 1.0;

	@field("string")
	public ProductSku:string = "";

	@field("string")
	public ProductDescription:string = "";

	public TranslatedDescription:CustomTranslation = new CustomTranslation();

	@field("number")
	@hidden()
	// Deprecated
	public UnitBuyPrice:number = 0.0;

	@field("number")
	public UnitPrice:number = 0.0;

	@field("number")
	public Discount:number = 0.0;

	@field("number")
	public Vat:number = 21;

	@field("number")
	public DisplayOrder:number = 0;

	@field("has-one", "Product")
	public Product:Product | null = null;

	@field("number")
	@nullable()
	@filterValueInput("Product")
	@filterOnly()
	public ReferencedProductID:number | null = 0;

	@field("has-one", "Product")
	@nullable()
	public ReferencedProduct:Product | null = null;

	@field("json")
	@hidden()
	public RowConfig:RowConfig = {Bold: false, Hidden: false, SubTotal: false}

	@field("string")
	public UnitName:string = "";

	@field("string")
	public Duration:number = 0;

	public constructor(data?: any) {
		if (!data) {
			return;
		}
		this.ID = data.ID;
		this.Amount = data.Amount;
		this.Discount = data.Discount;
		this.DisplayOrder = data.DisplayOrder;
		if (data.Product) {
			this.Product = new Product(data.Product);
		}
		this.ProductDescription = data.ProductDescription;
		this.TranslatedDescription = new CustomTranslation(data.TranslatedDescription);
		this.ProductID = data.ProductID;
		this.ProductSku = data.ProductSku;
		if (data.ReferencedProduct) {
			this.ReferencedProduct = new Product(data.ReferencedProduct);
		}
		this.ReferencedProductID = data.ReferencedProductID;
		this.Vat = data.Vat;
		this.UnitBuyPrice = data.UnitBuyPrice;
		this.UnitPrice = data.UnitPrice;
		if (data.RowConfig){
			this.RowConfig = {Bold: data.RowConfig.Bold || false, Hidden: data.RowConfig.Hidden || false, SubTotal: data.RowConfig.SubTotal || false};
		}
		this.UnitName = data.UnitName;
		this.Duration = data.Duration;
	}

	public getJSON() {
		return {
			ID: this.ID,
			ProductID: this.ProductID,
			Amount: this.Amount,
			ProductSku: this.ProductSku,
			ProductDescription: this.ProductDescription,
			TranslatedDescription: this.TranslatedDescription.getJSON(),
			UnitPrice: this.UnitPrice,
			UnitBuyPrice: this.UnitBuyPrice,
			Discount: this.Discount,
			Vat: this.Vat,
			DisplayOrder: this.DisplayOrder,
			ReferencedProductID: this.ReferencedProductID,
			RowConfig: this.RowConfig,
			UnitName: this.UnitName,
			Duration: this.Duration
		};
	}

	public getTotalPrice() {
		return this.Amount * (this.UnitPrice - (this.UnitPrice * (this.Discount / 100)));
	}

	public getRowConfig():RowConfig{
		return this.RowConfig;
	}
}
