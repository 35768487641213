
import { Condition } from "@/utils/condition/condition";
import AppItemEdit from "./ProductEditPriceRuleFilterItemEdit.vue";

import Vue from "vue";
import {ProductPriceRule} from "@/models/base/product-price-rule";
import { Browse } from "@/models/browse";
export default Vue.extend({
	props: {
		value: {
			type: Object as ()=>Condition,
			required: true
		},
		expand: Boolean,
		browse: {
			type: Object as ()=>Browse
		}
	},
	computed: {
		asText():string{
			return ProductPriceRule.filterToString(this.value);
		}
	},
	methods: {
		edit(){
			this.$emit("edit");
		},
		deleteItem(){
			this.$emit("delete");
		}
	},
	components: {
		AppItemEdit
	}
});
