import { SaleService } from "@/services";
import { i18n } from "@/setup/i18n-setup";
import { v4 } from "uuid";
import { TranslateResult } from "vue-i18n";

export enum DashboardQuickAddButtonType {
	CLIENT = "client",
	SUPPLIER = "supplier",
	PROSPECT = "prospect",
	PRODUCT = "product",
	SALE = "sale",
	SUBSCRIPTION = "subscription",
	PURCHASE_DOCUMENT ="purchase-document",
	PURCHASE = "purchase",
	BANK = "bank",
	SCAN = "scan",
	AUTO = "cars",
	CASHIER = "cashier",

	CURRENCY = "currency"
}

export class DashboardQuickAddButton {
	Type:DashboardQuickAddButtonType = DashboardQuickAddButtonType.CLIENT;
	Param:string = "";
	key:string = v4();

	public constructor(data?:any) {
		if (!data) return;
		this.Type = data.Type;
		this.Param = data.Param;
	}

	public getJSON(){
		return {
			Type: this.Type,
			Param: this.Param
		};
	}

	public getIcon():string{
		if (this.Type == DashboardQuickAddButtonType.SCAN) {
			return "barcode";
		}
		if (this.Type == DashboardQuickAddButtonType.CASHIER) {
			return "cash-register";
		}
		if (this.Type == DashboardQuickAddButtonType.CURRENCY) {
			return "dollar-sign";
		}
		return "plus-circle";
	}

	public getLabel():TranslateResult | string {
		return DashboardQuickAddButton.getLabel(this.Type, this.Param);
	}

	// eslint-disable-next-line max-lines-per-function
	public static getLabel(buttonType:DashboardQuickAddButtonType, param:string):TranslateResult | string {
		let paramNum = parseInt(param);
		if (Number.isNaN(paramNum)){
			paramNum = 1;
		}
		if (buttonType == DashboardQuickAddButtonType.CLIENT){
			return i18n.t("common.browse.Client");
		}
		if (buttonType == DashboardQuickAddButtonType.SUPPLIER){
			return i18n.t("common.browse.Supplier");
		}
		if (buttonType == DashboardQuickAddButtonType.PROSPECT){
			return i18n.t("common.browse.Prospect");
		}
		if (buttonType == DashboardQuickAddButtonType.PRODUCT){
			return i18n.t("common.browse.Product");
		}
		if (buttonType == DashboardQuickAddButtonType.SALE) {
			if (paramNum == 0){
				return i18n.t("common.browse.Sale");
			}
			return SaleService.getTypeName(paramNum);
		}
		if (buttonType == DashboardQuickAddButtonType.SUBSCRIPTION) {
			return i18n.t("common.browse.Subscription");
		}
		if (buttonType == DashboardQuickAddButtonType.PURCHASE_DOCUMENT) {
			if (paramNum == 0){
				return i18n.t("common.browse.PurchaseDocument");
			}
			return SaleService.getTypeName(paramNum);
		}
		if (buttonType == DashboardQuickAddButtonType.PURCHASE){
			return i18n.t("common.browse.Purchase");
		}
		if (buttonType == DashboardQuickAddButtonType.BANK) {
			return i18n.t("common.browse.Bank");
		}
		if (buttonType == DashboardQuickAddButtonType.AUTO) {
			return i18n.t("common.browse.Car");
		}
		if (buttonType == DashboardQuickAddButtonType.CASHIER) {
			return i18n.t("common.cashier");
		}
		if (buttonType == DashboardQuickAddButtonType.CURRENCY) {
			return i18n.t("common.currency");
		}
		return i18n.t("views.HomeRoute.quick-add.scan");
	}

	// eslint-disable-next-line max-lines-per-function
	public getUrl():string {
		let paramNum = parseInt(this.Param);
		if (Number.isNaN(paramNum)){
			paramNum = 1;
		}
		if (this.Type == DashboardQuickAddButtonType.CLIENT) {
			return "/contacts/customers/new";
		}
		if (this.Type == DashboardQuickAddButtonType.SUPPLIER){
			return "/contacts/suppliers/new";
		}
		if (this.Type == DashboardQuickAddButtonType.PROSPECT){
			return "/contacts/prospects/new";
		}
		if (this.Type == DashboardQuickAddButtonType.PRODUCT) {
			return "/products/new";
		}
		if (this.Type == DashboardQuickAddButtonType.SALE) {
			return `/sales/new?typeId=${paramNum}`;
		}
		if (this.Type == DashboardQuickAddButtonType.SUBSCRIPTION) {
			return `/subscriptions/new`;
		}
		if (this.Type == DashboardQuickAddButtonType.PURCHASE_DOCUMENT) {
			return `/purchase-documents/new?typeId=${paramNum}`;
		}
		if (this.Type == DashboardQuickAddButtonType.PURCHASE){
			return "/purchase/new";
		}
		if (this.Type == DashboardQuickAddButtonType.BANK) {
			return `/bank/new`;
		}
		if (this.Type == DashboardQuickAddButtonType.AUTO) {
			return "/cars/new";
		}
		if (this.Type == DashboardQuickAddButtonType.CASHIER) {
			return "/cashier";
		}
		if (this.Type == DashboardQuickAddButtonType.CURRENCY) {
			return "/settings/sales/valuta";
		}
		return "/stock/scan";
	}
}