import { ILayoutImage } from "../layout-editor";
import { SaleLayoutLogoPosition } from "./logo";

export class PaymentQrSettings implements ILayoutImage{
	Width:number = 50;
	Height:number = 50;
	X:number = 50;
	Y:number = 50;
	Show:boolean = false;
	Placement:SaleLayoutLogoPosition = "Heading"
	BankID:number = 1;

	public constructor(data?:any) {
		if (!data) return;
		this.Width = data.Width || 20;
		this.Height = data.Height || 20;
		this.X = data.X;
		this.Y = data.Y;
		this.Show = data.Show;
		this.Placement = data.Placement;
		this.BankID = data.BankID || 1;
	}

	public getJSON():any{
		return {
			Width: this.Width,
			Height: this.Height,
			X: this.X,
			Y: this.Y,
			Show: this.Show,
			Placement: this.Placement,
			BankID: this.BankID
		};
	}

	public getImageUrl(): string {
		return "/img/winfakt-be-qr.png";
	}
}