import { Module } from "./module";

export enum ModuleOnlineBackupFrequency {
	MANUAL = 0,
	DAILY = 1,
}

export class ModuleOnlineBackup extends Module {
	public getLastBackupDate():Date | null {
		let unixDate = this.MetaData["last-backup-date"];
		if (!unixDate) {
			return null;
		}
		return new Date(parseInt(unixDate)*1000);
	}

	public setLastBackupDate(date:Date | null){
		if (date == null){
			this.MetaData["last-backup-date"] = "";
		}else{
			this.MetaData["last-backup-date"] = `${Math.round(date.getTime() /1000)}`;
		}
		return this;
	}
}