import { DossierConfigService } from "@/services/dossier-config-service";
import { i18n } from "@/setup/i18n-setup";
import { getAllExtraFieldKeyNames } from "../models/extra-fields";
import { getContactAddressMapPropSelectGroup } from "./contact-address-map-to-prop-select-group";
import { PropSelectGroup } from "./prop-select-group";
import { PropSelectItem } from "./prop-select-item";
import {getCarMapPropSelectGroup} from "@/utils/prop-select/car-map-prop-group";

let rootfields = [
	"ID",
	"NR",
	"Type",
	"TypeAndNumber",
	"CompanyName",
	"BookYear",
	"ToPrintMessage",
	"ToNotPrintMessage",
	"InvoiceDate",
	"ExpirationDate",
	"DeliveryDate",
	"TotalExcVAT",
	"TotalDiscount",
	"TotalIncVAT",
	"TotalVAT",
	"TotalOpen",
	"TotalPayed",
	"TotalBuy",
	"TotalLeegGoed",
	"TotalBase",
	"TotalItems",
	"Vat",
	"CommercialVat",
	"PaymentDays",
	"ContactComputedFriendlyID",
	"Phone",
	"SignedOnDate",
	"SignedBy",
	"CreatorName",
	"Ogm",
	"CreatedByFrequencyDocumentStartDate",
	"CreatedByFrequencyDocumentEndDate",
	"CreatedByFrequencyDocumentNextDate",
	"FrequencyPeriodFrom",
	"FrequencyPeriodTill",
	"PaymentBeforeDiscount",
	"PaymentBeforeDiscountPercent",
	"PaymentBeforeDiscountDate",
	"PaymentBeforeDiscountDays",
	"SaleCarMileage",
	"TotalEcoboniExcVat",
	"TotalEcoboniIncVat",
	"TotalAccijnzenExcVat",
	"TotalAccijnzenIncVat",
	"TotalExcVATEuro",
	"TotalDiscountEuro",
	"TotalVATEuro",
	"TotalIncVATEuro",
	"TotalOpenEuro"
];

// TODO: Add contact group select
// eslint-disable-next-line max-lines-per-function
export function getSaleMapPropSelectGroup(): PropSelectGroup {
	let result = new PropSelectGroup("", "", "");
	for (let item of rootfields) {
		result.items.push(new PropSelectItem(i18n.t(`models.map.Sale.${item}`).toString(), item));
	}

	let saleExtraFields = DossierConfigService.getExtraFieldNamesConfigs().SaleNames;
	let activeFields = saleExtraFields.getActiveFields();
	if (activeFields.length > 0) {
		let group = new PropSelectGroup(i18n.t(`models.map.Sale.extra-fields`).toString(), "");
		for (let field of activeFields) {
			group.items.push(new PropSelectItem(saleExtraFields[field] as string, `ExtraField${field}`));
		}
		result.groups.push(group);
	}

	activeFields = getAllExtraFieldKeyNames();
	if (activeFields.length > 0) {
		let group = new PropSelectGroup(i18n.t(`models.map.Sale.contact-extra-fields`).toString(), "");
		for (let field of activeFields) {
			group.items.push(new PropSelectItem(i18n.t(`models.map.ContactAddress.ExtraField${field}`), `InvoiceAddressExtraField${field}`));
		}
		result.groups.push(group);
	}

	let invoiceAddressMap = getContactAddressMapPropSelectGroup();
	invoiceAddressMap.name = i18n.t("models.map.Sale.invoice-address");
	for (let item of invoiceAddressMap.items) {
		item.value = `InvoiceAddress${item.value}`;
	}
	result.groups.push(invoiceAddressMap);

	let deliveryAddressMap = getContactAddressMapPropSelectGroup();
	deliveryAddressMap.name = i18n.t("models.map.Sale.delivery-address");
	for (let item of deliveryAddressMap.items) {
		item.value = `DeliveryAddress${item.value}`;
	}
	result.groups.push(deliveryAddressMap);

	let carMap = getCarMapPropSelectGroup();
	carMap.name = i18n.t("models.map.Sale.Car");
	for (let item of carMap.items) {
		item.value = `Car${item.value}`;
	}
	result.groups.push(carMap);

	return result;
}
