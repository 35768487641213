import { BrowseFavouriteColumn } from "@/utils/browse/browse-favourite-column";

export class BrowseFavouriteColumns {
	BrowseID:number = 0;
	Columns:BrowseFavouriteColumn[] = [];

	public constructor(data?:any){
		if (!data) return;
		this.BrowseID = data.BrowseID;
		this.Columns = ((data.Columns || []) as any[]).map(a=>new BrowseFavouriteColumn(a)).sort(function(a, b){
			for (let i = 0; i < a.PathParts.length;i++){
				if (a.PathParts[i] != b.PathParts[i]){
					if (a.PathParts.length < b.PathParts.length){
						return -1;
					}
					if (a.PathParts.length > b.PathParts.length){
						return 1;
					}
					return a.PathParts[i].localeCompare(b.PathParts[i] || "");
				}
			}
			return 0;
		});
		this.Columns = this.Columns.filter((val, i)=>{
			let foundIndex = this.Columns.findIndex(v=>v.Path == val.Path);
			return foundIndex == i;
		});
	}

	public getIndexOfPath(path:string):number {
		for (let i in this.Columns){
			if (this.Columns[i].Path == path) {
				return parseInt(i);
			}
		}
		return -1;
	}

	public getJSON(){
		return {
			BrowseID: this.BrowseID,
			Columns: [...this.Columns]
		};
	}
}