import { TextStyledProperty } from "@/utils/documents/text-styled-property";
import { ReminderColumnNames } from "./column-names";
import { Translation } from "@/utils/translation";
import { DocumentLayoutClientHeading } from "@/utils//documents/layout-client-heading";
import { DocumentLayoutHeading } from "@/utils//documents/layout-heading";
import { DocumentLayoutLogo, DocumentLayoutLogoPosition } from "@/utils//documents/layout-logo";
import { DocumentPageSettings } from "@/utils//documents/page-settings";
import { TranslatedStyledProperty } from "@/utils/documents/translated-styled-property";
import { FreeBlock } from "../documents/free-block";

export class ReminderLayout {
	public PageSettings: DocumentPageSettings = new DocumentPageSettings();
	public Heading: DocumentLayoutHeading = new DocumentLayoutHeading();
	public ClientHeading: DocumentLayoutClientHeading = new DocumentLayoutClientHeading();
	public DateStyle: TextStyledProperty = new TextStyledProperty();
	public TitleStyle: TextStyledProperty = new TextStyledProperty();
	public TextStyle: TextStyledProperty = new TextStyledProperty();
	public SignatureStyle: TextStyledProperty = new TextStyledProperty();
	public FreeLines: TranslatedStyledProperty = new TranslatedStyledProperty();
	public ColumnNames: ReminderColumnNames = new ReminderColumnNames();
	public Logos: DocumentLayoutLogo[] = [];
	public FreeBlocks:FreeBlock[] = [];

	constructor(data?: any) {
		if (!data) {
			return;
		}
		this.PageSettings = new DocumentPageSettings(data.PageSettings);
		this.Heading = new DocumentLayoutHeading(data.Heading);
		this.ClientHeading = new DocumentLayoutClientHeading(data.ClientHeading);
		this.Logos = (data.Logos || []).map((l: any) => new DocumentLayoutLogo(l));
		this.DateStyle = new TextStyledProperty(data.DateStyle);
		this.TitleStyle = new TextStyledProperty(data.TitleStyle);
		this.TextStyle = new TextStyledProperty(data.TextStyle);
		this.SignatureStyle = new TextStyledProperty(data.SignatureStyle);
		this.FreeLines = new TranslatedStyledProperty(data.FreeLines);
		this.ColumnNames = new ReminderColumnNames(data.ReminderColumnNames);
		this.FreeBlocks = (data.FreeBlocks || []).map((l:any)=>new FreeBlock(l));
	}

	public getJSON() {
		return {
			PageSettings: this.PageSettings.getJSON(),
			Heading: this.Heading.getJSON(),
			ClientHeading: this.ClientHeading.getJSON(),
			TitleStyle: this.TitleStyle.getJSON(),
			DateStyle: this.DateStyle.getJSON(),
			TextStyle: this.TextStyle.getJSON(),
			SignatureStyle: this.SignatureStyle.getJSON(),
			Logos: this.Logos.map((l) => l.getJSON()),
			FreeLines: this.FreeLines.getJSON(),
			ColumnNames: this.ColumnNames.getJSON(),
			FreeBlocks: this.FreeBlocks.map(f=>f.getJSON())
		};
	}

	public getLogosByPlacement(placement:DocumentLayoutLogoPosition):DocumentLayoutLogo[]{
		return this.Logos.filter(f=>f.Placement == placement);
	}

	public getFreeBlocksByPlacement(placement:DocumentLayoutLogoPosition):FreeBlock[]{
		return this.FreeBlocks.filter(f=>f.Placement == placement);
	}
}

// eslint-disable-next-line max-lines-per-function
export function getTableColumnNames(): Translation[] {
	return [
		new Translation({
			"nl-be": "Factuur",
		}),
		new Translation({
			"nl-be": "Datum",
			"de": "Datum",
			"en": "Date",
			"fr": "Date"
		}),
		new Translation({
			"nl-be": "Bedrag",
			"de": "Betrag",
			"en": "Amount",
			"fr": "Montant"
		}),
		new Translation({
			"nl-be": "Betaald",
			"de": "Schon bezahlt",
			"en": "Already paid",
			"fr": "Déjà payé"
		}),
		new Translation({
			"nl-be": "Interesten",
			"de": "Zinsen",
			"en": "Interest",
			"fr": "Intérêt"
		}),
		new Translation({
			"nl-be": "Kosten",
			"de": "Kosten",
			"en": "Costs",
			"fr": "Frais"
		}),
		new Translation({
			"nl-be": "Nog te betalen",
			"de": "Zu bezahlen",
			"en": "Outstanding amount",
			"fr": "Solde à payer"
		}),
		new Translation({
			"nl-be": "Vervaldag",
			"de": "Fälligheitstag",
			"en": "Due date",
			"fr": "Echéance"
		}),
	];
}
