import { i18n } from "@/setup/i18n-setup";
import { HumanFilter } from "../human-filter";
import { DashboardItemType } from "./dashboard-item";
import { BookYear } from "@/models/book-year";
import { BookYearService } from "@/services";

export class DashboardChartData {
	public bookYearOverride:string = BookYear.SelectedBookYearValue;
	public title:string = ""
	public filters:HumanFilter[] = [];

	public constructor(data?:any, type?:DashboardItemType) {
		if (!data) {
			this.filters = [
				new HumanFilter({
					Field: "this.TypeID",
					Operator: "in",
					Values: ["1","2"]
				})
			];
			if (type != undefined) {
				if (type != DashboardItemType.TOTAL_OPEN){
					this.filters.push(new HumanFilter({Field: "this.ComputedFriendlyID", Operator: "!^", Values: ["-"]}));
				}
				this.title = i18n.t(`components.dashboard.default-titles.${type}`).toString();
			}
			return;
		};
		if (typeof(data.filters) == "object") {
			data.filters = JSON.stringify(data.filters);
		}
		this.bookYearOverride = data.bookYearOverride || BookYear.SelectedBookYearValue;
		this.filters = (JSON.parse(data.filters || "[]") || []).map((f:any)=>new HumanFilter(f));
		this.title = data.title;
	}

	getJSON(){
		return {
			bookYearOverride: this.bookYearOverride,
			filters: JSON.stringify(this.filters.map(f=>f.getJSON())),
			title: this.title
		};
	}

	public getBookYearOverrideValue():number | undefined {
		if (this.bookYearOverride == BookYear.PreviousBookYearValue){
			return BookYearService.selectedBookYear - 1;
		}
		if (this.bookYearOverride == BookYear.SelectedBookYearValue){
			return undefined;
		}
		return parseInt(this.bookYearOverride);
	}
}