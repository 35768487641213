import { ServerConfig } from "@/config/config";
import { ApiKey } from "@/models/api-key";
import { Axios } from "@/utils/axios";

class ApiKeyServiceClass{
	public get url():string{
		return `${ServerConfig.auth}/api-key`;
	}

	public async getKeys():Promise<ApiKey[]>{
		let response = await Axios.get(this.url);
		return response.data.map((d:any)=>new ApiKey(d));
	}

	public async getKey(id:number):Promise<ApiKey>{
		let response = await Axios.get(`${this.url}/${id}`);
		return new ApiKey(response.data);
	}

	public async createApiKey(name:string):Promise<{key:ApiKey, token:string}>{
		let response = await Axios.post(`${this.url}`, {name});
		return {key: new ApiKey(response.data.key), token: response.data.token};
	}

	public async deleteApiKey(id:number):Promise<void>{
		await Axios.delete(`${this.url}/${id}`);
	}
}

export const ApiKeyService = new ApiKeyServiceClass();
