import { v4 } from "uuid";
import { Model } from "../models/model";
import { getFileUrl } from "../url";

export class Image {
	key:string = v4();
	Prop:string = "";
	PreText:string = "";
	PostText:string = "";
	ImageToken:string = "";
	UseImageToken:boolean = true;
	X:number = 0
	Y:number = 0
	Width:number = 10
	Height:number = 10

	public constructor(data?:any) {
		if (!data) return;
		this.Prop = data.Prop;
		this.PreText = data.PreText;
		this.PostText = data.PostText;
		this.ImageToken = data.ImageToken;
		this.UseImageToken = data.UseImageToken;
		this.X = data.X;
		this.Y = data.Y;
		this.Width = data.Width;
		this.Height = data.Height;
	}

	public getJSON(){
		return{
			Prop: this.Prop,
			PreText: this.PreText,
			PostText: this.PostText,
			ImageToken: this.ImageToken,
			UseImageToken: this.UseImageToken,
			X: this.X,
			Y: this.Y,
			Width: this.Width,
			Height: this.Height,
		};
	}

	public getEditorImageUrl():string {
		if (this.UseImageToken) {
			return getFileUrl(this.ImageToken);
		}
		return `/img/w.png`;
	}

	public getPropName(model:Model):string{
		if (this.Prop){
			let field = model.getField(this.Prop);
			if (field) {
				return field.getPropertyTranslation();
			}
		}
		return "";
	}

	public getAsText(model:Model):string{
		return `${this.PreText}${this.getPropName(model)}${this.PostText}`;
	}

	public getName(model:Model):string {
		if (this.UseImageToken) {
			return getFileUrl(this.ImageToken);
		}
		return this.getAsText(model);
	}

	private computePropText(record:any):string {
		if (this.UseImageToken) {
			return getFileUrl(this.ImageToken);
		}
		if (!this.Prop) return "";
		let propParts = this.Prop.split(".");
		let propValue = record;
		for (let part of propParts){
			if (!propValue) return "";
			propValue = propValue[part];
		}
		return `${propValue || ""}`;
	}

	private computeStyle():string{
		return `width:${this.Width}mm;height:${this.Height}mm;left:${this.X}mm;top:${this.Y}mm`;
	}

	public compute(record:any):string{
		let value =  `${this.PreText}${this.computePropText(record)}${this.PostText}`;
		if (!value) return "";
		let div = document.createElement("div");
		div.className = "image-container";
		div.style.setProperty("width", `${this.Width}mm`);
		div.style.setProperty("height", `${this.Height}mm`);
		div.style.setProperty("left", `${this.X}mm`);
		div.style.setProperty("top", `${this.Y}mm`);
		let img = document.createElement("img");
		img.setAttribute("style", this.computeStyle());
		img.className = "img";
		img.src = this.computePropText(record);
		div.appendChild(img);
		return div.outerHTML;
	}
}