import { ServerConfig } from "@/config/config";
import { Notification } from "@/models/notification";
import { Axios } from "@/utils/axios";
import { IInitAppResponse } from "./init-service";
import { MainSocketService } from "./main-socket-service";
import { EventEmitter } from "@/utils/event-emitter";

class NotificationServiceClass extends EventEmitter{
	hasPendingNotifications:boolean = false;
	initialized:boolean = false;
	readonly url:string = `${ServerConfig.host}/notifications`;

	async getNotifications(offset:number = 0):Promise<Notification[]> {
		let result = await Axios.get(`${this.url}`, {params: {offset}});
		return result.data.map((d:any) => new Notification(d));
	}

	async getHasPendingNotifications():Promise<boolean> {
		let result = await Axios.get(`${this.url}/has-unread-notifications`);
		this.hasPendingNotifications = result.data;
		return this.hasPendingNotifications;
	}

	async markNotificationsAsRead(notifications:Notification[] = []):Promise<void> {
		await Axios.post(`${this.url}/mark-as-read`, notifications.map(n => n.ID));
		await this.getHasPendingNotifications();
	}

	async deleteReadNotifications():Promise<void> {
		await Axios.post(`${this.url}/delete-all-read`);
		await this.getHasPendingNotifications();
	}

	init(data:IInitAppResponse) {
		if (!this.initialized) {
			this.initialized = true;
			MainSocketService.on("/notification/new", (data:any) => {
				let notification = new Notification(data);
				this.hasPendingNotifications = true;
				this.emit("new-notification", notification);
			});
			MainSocketService.on("user-connected", (data:any) => {

			});
		}
		this.hasPendingNotifications = data.HasUnreadNotifications;
	}

	async setHideFromClient(notification:Notification, hidden:boolean):Promise<Notification>{
		let result = await Axios.post(`${this.url}/${notification.ID}/set-hide-from-client`, {Enabled: hidden});
		return new Notification(result.data);
	}
};

export const NotificationService = new NotificationServiceClass();