import { ILayoutEntity } from "../layout-editor";
import { StringStyledPropertyWithFreeLine } from "./string-styled-property-with-free-line";

export class DocumentLayoutClientHeading implements ILayoutEntity {
	public Property1: StringStyledPropertyWithFreeLine = new StringStyledPropertyWithFreeLine();
	public Property2: StringStyledPropertyWithFreeLine = new StringStyledPropertyWithFreeLine();
	public Property3: StringStyledPropertyWithFreeLine = new StringStyledPropertyWithFreeLine();
	public Property4: StringStyledPropertyWithFreeLine = new StringStyledPropertyWithFreeLine();
	public Property5: StringStyledPropertyWithFreeLine = new StringStyledPropertyWithFreeLine();
	public Property6: StringStyledPropertyWithFreeLine = new StringStyledPropertyWithFreeLine();
	public Property7: StringStyledPropertyWithFreeLine = new StringStyledPropertyWithFreeLine();
	public Property8: StringStyledPropertyWithFreeLine = new StringStyledPropertyWithFreeLine();
	public Property9: StringStyledPropertyWithFreeLine = new StringStyledPropertyWithFreeLine();
	public X = 0;
	public Y = 0;
	public Width = 50;
	public Height = 50;
	public Show = true;

	constructor(data?: any) {
		if (!data) {
			return;
		}
		for (let i = 1; i <= 9; i++) {
			(this as any)["Property" + i] = new StringStyledPropertyWithFreeLine(data["Property" + i]);
		}
		this.X = data.X;
		this.Y = data.Y;
		this.Width = data.Width;
		this.Height = data.Height;
		this.Show = data.Show;
	}
	public getJSON() {
		let data = {
			X: this.X,
			Y: this.Y,
			Width: this.Width,
			Height: this.Height,
			Show: this.Show,
		} as any;
		for (let i = 1; i <= 9; i++) {
			data["Property" + i] = (this as any)["Property" + i].getJSON();
		}
		return data;
	}
}
