
import Vue from "vue";
import AppProductSkuAutocomplete from "@/components/products/ProductSkuAutocompleteWithoutModal.vue";
import { Product } from "@/models/base";
import { ProductService } from "@/services/product-service";

export default Vue.extend({
	data() {
		return {};
	},
	props: {
		value: String,
		label: String
	},
	computed: {
		items(): string[] {
			return JSON.parse(this.value || "[]") || [];
		}
	},
	methods: {
		onProduct(product: Product) {
			let products = [...this.items];
			for (let p of products) {
				if (p == product.Sku) {
					return;
				}
			}
			products.push(product.Sku || "");
			this.$emit("input", JSON.stringify(products));
		},
		removeProduct(item: string) {
			let products = [...this.items];
			let index = products.indexOf(item);
			if (index == -1) return;
			products.splice(index, 1);
			this.$emit("input", JSON.stringify(products));
		}
	},
	async created() {
		if (ProductService.selectedProducts.length > 0) {
			this.$emit("input", JSON.stringify(ProductService.selectedProducts.map(p => p.Sku)));
		}
	},
	components: {
		AppProductSkuAutocomplete
	},
	i18nEx: {
		componentPrefix: "components.reportengine"
	}
});
