import { FrontendFunc } from "../frontend-func";

export class SharedFuncReference {
	FuncID:number = 0;
	Alias:string = "";
	SharedFunc:FrontendFunc;

	public constructor(data:any){
		this.FuncID = data.FuncID;
		this.Alias = data.Alias;
		this.SharedFunc = new FrontendFunc(data.SharedFunc);
	}

}