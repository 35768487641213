import {
	highlightCell,
	printDayDiff,
	printHumanFloat,
	printHumanInt,
	printLocalDate,
	printDuration,
	cleanHtml
} from "@/utils/pretty-print";
import { autoLink } from "@/utils/auto-link";
import { specialLink } from "@/utils/special-link";
import Vue, { VueConstructor } from "vue";

export function createFilters(vue: VueConstructor<Vue>) {
	vue.filter("humanDuration", printDuration);
	vue.filter("humanFloat", printHumanFloat);
	vue.filter("cleanHtml", cleanHtml);
	vue.filter("humanInt", printHumanInt);
	vue.filter("localDate", printLocalDate);
	vue.filter("highlightCell", highlightCell);
	vue.filter("printDayDiff", printDayDiff);
	vue.filter("autoLink", autoLink);
	vue.filter("specialLink", specialLink);
}