import { dateToNullOrJson } from "@/utils/date-utils";
import { FrequencyType } from "../base";
import { IFrequencyObject } from "@/utils/frequency-object";

export class RepeatedTask implements IFrequencyObject{
	ID:number = 0;
	FolderID:number = 0;
	Text:string = ""
	Priority:number = 0;
	DueDate:number = 0;
	TimeStamp:Date = new Date();
	// Frequency fields
	StartDate:Date = new Date();
	FrequencyType:FrequencyType = FrequencyType.Weekly;
	FrequencyMoment:string = "";
	Interval:number = 1;
	NextExecutionDate:null | Date = null;
	EndDate:null | Date = null;

	public constructor(data?:any) {
		if (!data)return;
		this.ID = data.ID;
		this.FolderID = data.FolderID;
		this.Text = data.Text;
		this.Priority = data.Priority;
		this.DueDate = data.DueDate;
		this.TimeStamp = new Date(data.TimeStamp);
		this.StartDate = new Date(data.StartDate);
		this.FrequencyType = data.FrequencyType;
		this.FrequencyMoment = data.FrequencyMoment;
		this.Interval = data.Interval;
		if (data.NextExecutionDate){
			this.NextExecutionDate = new Date(data.NextExecutionDate);
		}
		if (data.EndDate){
			this.EndDate = new Date(data.EndDate);
		}
	}

	public getJSON(){
		return {
			ID: this.ID,
			FolderID: this.FolderID,
			Text: this.Text,
			Priority: this.Priority,
			DueDate: this.DueDate,
			TimeStamp: this.TimeStamp.toJSON(),
			StartDate: this.StartDate.toJSON(),
			FrequencyType: this.FrequencyType,
			FrequencyMoment: this.FrequencyMoment,
			Interval: this.Interval,
			NextExecutionDate: dateToNullOrJson(this.NextExecutionDate),
			EndDate: dateToNullOrJson(this.EndDate)
		};
	}
}