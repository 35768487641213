
import { getValidOperatorsForFieldType, HumanFilter, HumanFilterOperation } from "@/utils/human-filter";
import AppHumanFilterEditValue from "@/components/models/HumanFilterEditValue.vue";
import Vue from "vue";
import { getModel, Model } from "@/utils/models/model";
import { ISelectItem } from "winfakt-vue-components";
import { ContactAddressNamedFilter } from "@/utils/models/contact-address-named-filter";
import { ModelNamedProperty } from "@/utils/models/named-property";

export default Vue.extend({
	data(){
		return {
			ready: false,
			valueFilter: new HumanFilter()
		};
	},
	props: {
		value: {
			type: Object as ()=>HumanFilter,
			required: true
		}
	},
	computed: {
		addressModel():Model {
			return getModel("ContactAddress");
		},
		contactModel():Model {
			return getModel("Contact");
		},
		namedFilter():ContactAddressNamedFilter | undefined {
			return this.contactModel.getAvailableNamedFilters().find(c=>c.name == this.value.Field) as ContactAddressNamedFilter;
		},
		selectedField():ModelNamedProperty | undefined{
			if (!this.namedFilter) {
				return undefined;
			}
			return this.addressModel.getField(this.namedFilter.fieldName, 1);
		},
		fieldType():string {
			if (!this.selectedField) {
				return "string";
			}
			if (this.selectedField.field.filterValueInput){
				return this.selectedField.field.filterValueInput;
			}
			return this.selectedField.field.type;
		},
		operators(): ISelectItem<string>[] {
			if (!this.selectedField) return [];
			let validOperators = getValidOperatorsForFieldType(this.selectedField.field);
			return validOperators.map((op) => {
				return {
					text: this.$t("common.filters." + HumanFilter.getTranslationOperator(op)).toString(),
					value: op
				};
			});
		},
	},
	watch: {
		valueFilter: {
			deep: true,
			handler(){
				this.onValueFilterChange();
				let valueAmount = this.valueFilter.getValueAmount();
				if (valueAmount == -1) {
					return;
				}
				if (valueAmount < this.valueFilter.Values.length) {
					this.valueFilter.Values.splice(valueAmount);
				}
				if (valueAmount > this.valueFilter.Values.length) {
					for (let i = this.valueFilter.Values.length; i < valueAmount; i++){
						this.valueFilter.Values.push("");
					}
				}
			}
		},
	},
	methods: {
		setOperator(event:HumanFilterOperation){
			this.value.Options[0] = event;
			this.valueFilter.Operator = event;
			this.value.Operator = event;
		},
		onValueFilterChange(){
			this.value.Values = this.valueFilter.Values;
		}
	},
	mounted(){
		if (this.value.Options[0] == null || this.value.Options[0] == "") {
			this.value.Options[0] = "=";
		}
		this.valueFilter.Operator = this.value.Options[0] as HumanFilterOperation;
		this.valueFilter.Values = this.value.Values;
		this.ready = true;
	},
	components: {
		AppHumanFilterEditValue
	},
	i18nEx: {
		componentPrefix: "components.models"
	}
});
