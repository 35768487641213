export class TicketUnreadUser {
	id:number;
	userId:number;
	ticketId:number;

	constructor(data:any) {
		this.id = data.id;
		this.userId = data.userId;
		this.ticketId = data.ticketId;
	}
}