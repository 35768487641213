
import { HumanFilter } from "@/utils/human-filter";
import { Model } from "@/utils/models/model";
import Vue from "vue";
import { TranslateResult } from "vue-i18n";
import AppHumanFilterEditValueDefaultSingleValue from "./HumanFilterEditValueDefaultSingleValue.vue";

export default Vue.extend({
	props: {
		filter: {
			type: Object as ()=>HumanFilter,
			required: true
		},
		labels: {
			type: Array as ()=>(string | TranslateResult)[]
		},
		fieldType: {
			type: String,
			required: true
		},
		model: {
			type: Object as ()=>Model,
		}
	},
	computed: {
		valueAmount(): number {
			return this.filter.getValueAmount();
		},
	},
	methods: {
		addValue() {
			this.filter.Values.push("");
			this.filter.UseFieldValues.push(false);
		},
		getLabel(i:number):string | TranslateResult{
			if (this.labels) {
				return this.labels[i];
			}
			return this.filter.Values.length == 1 ? this.$ct("single-value") : this.$ct("value", {val: i + 1});
		},
	},
	components: {
		AppHumanFilterEditValueDefaultSingleValue
	},
	i18nEx: {
		componentPrefix: "components.models"
	}
});
