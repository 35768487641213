interface ISub {
	event:string;
	handler:Function;
	isOnce:boolean;
}

export class EventEmitter {
	subs:ISub[] = [];

	on(event:string, handler:Function) {
		this.subs.push({
			event,
			handler,
			isOnce: false
		});
	}

	off(event:string, handler:Function) {
		this.removeSub(event, handler);
	}

	once(event:string, handler:Function) {
		this.subs.push({
			event,
			handler,
			isOnce: true
		});
	}

	private removeSub(event:string, handler:Function) {
		for (let i = this.subs.length - 1; i >= 0; i--) {
			let sub = this.subs[i];
			if (sub.event == event && sub.handler == handler) {
				this.subs.splice(i, 1);
			}
		}
	}

	emit(event:string, ...args:any) {
		let subs = this.subs.filter(sub => sub.event == event);
		for (let sub of subs) {
			sub.handler(...args);
			if (sub.isOnce) {
				let index = this.subs.indexOf(sub);
				if (index != -1) {
					this.subs.splice(index, 1);
				}
			}
		}
	}

	public offAll(){
		this.subs = [];
	}

	public hasSubs():boolean {
		return this.subs.length>0;
	}
}