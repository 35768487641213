import { HumanFilter } from "../human-filter";

export class AskedFilter extends HumanFilter{
	FieldType:string = "string";
	IsOptional:boolean = false;
	Labels:string[] = [];
	OptionalFilterIsDisabled:boolean = false;

	constructor(data?:any){
		super(data);
		if (!data) return;
		this.FieldType = data.FieldType;
		this.IsOptional = data.IsOptional;
		this.Labels = data.Labels;
		this.OptionalFilterIsDisabled = data.OptionalFilterIsDisabled || false;
	}

	getJSON(){
		let res = {
			...super.getJSON(),
			FieldType: this.FieldType,
			IsOptional: this.IsOptional,
			Labels: this.Labels
		};
		if (this.OptionalFilterIsDisabled) {
			res.Values = [];
		}
		return res;
	}

}