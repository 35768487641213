
import Vue from "vue";
import { ExtraFieldNamesConfig } from "../models/dossier-configs/extra-field-names-config";
import { IExtraFields } from "../utils/models/extra-fields";
import { ExtraFieldNumberConfig } from "@/models/dossier-configs/extra-field-number-config";
import AppExtraFieldsEditText from "./ExtraFieldsEditText.vue";

export default Vue.extend({
	props: {
		value: {
			type: Object as () => IExtraFields,
			required: true
		},
		nameSettings: {
			type: Object as () => ExtraFieldNamesConfig,
			required: true
		},
	},
	computed: {
		locale(): string {
			return this.$i18n.locale;
		}
	},
	methods: {
		getLabel(type: string, count: number): string {
			let key = `${type}${count}` as keyof ExtraFieldNamesConfig;
			if ((this.nameSettings[key] as string) != "") {
				return this.nameSettings[key] as string;
			}
			return this.$ct(type, {prop: count}).toString();
		},
		getDecimals(count:number):number{
			return (this.nameSettings[`Num${count}Config` as keyof ExtraFieldNamesConfig] as ExtraFieldNumberConfig).Decimals;
		},
		isActive(type: string, count: number): boolean {
			let key = `${type}${count}` as keyof ExtraFieldNamesConfig;
			return this.nameSettings[key] != "";
		}
	},
	components: {
		AppExtraFieldsEditText
	},
	i18nEx: {
		componentPrefix: "components.ExtraFieldsEdit"
	}
});
