export const SidebarService = {
	state: {
		visibility: true
	},
	show() {
		this.state.visibility = true;
	},
	hide() {
		this.state.visibility = true;
	}
};