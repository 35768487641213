
import AppColumnSelect from "@/components/models/ColumnSelect.vue";
import { Browse } from "@/models/browse";
import { TextAlignment } from "@/utils/documents/text-styled-property";
import { IModel } from "@/utils/models/model-interface";
import { SettingsColumn, SettingsColumnType } from "@/utils/report-engine-settings/settings-column";
import { v4 } from "uuid";
import Vue from "vue";
import { ModelNamedProperty } from "../../utils/models/named-property";
import AppSelectFallbackColumn from "./SelectFallbackColumn.vue";
import AppTextIfValueSettings from "./TextIfValueSettings.vue";
import AppSelectColumnsSumColsConfig from "./SelectColumnsSumColsConfig.vue";
import { ISelectItem } from "winfakt-vue-components";
import { filterFieldPathToBrowseFieldPath } from "@/utils/models/utils";

export default Vue.extend({
	data(){
		return {
			editFallback: false,
			setDefaultTextIfValueSettingsThreadId: "",
		};
	},
	props: {
		value: {
			type: Object as () => SettingsColumn,
			required: true
		},
		model: {
			type: Object as () => IModel,
			required: true
		},
		browse: {
			type: Object as ()=>Browse
		}
	},
	computed: {
		selectedProp():ModelNamedProperty | undefined {
			return this.model.getField(this.value.Value);
		},
		propIsNotNumber():boolean {
			if (this.value.getColumnType() == "sum") {
				return false;
			}
			if (!this.selectedProp){
				return true;
			}
			if (this.selectedProp.field.type != "number"){
				return true;
			}
			return false;
		},
		confirmedDisabled():boolean {
			return this.value.Value == "";
		},
		fallbackName():string{
			if (this.value.Fallback == ""){
				return this.$ct("common.none").toString();
			}
			let field = this.model.getField(this.value.Fallback);
			if (!field) return "ERROR";
			return field.getPropertyTranslation();
		},
		columnType():SettingsColumnType{
			return this.value.getColumnType();
		},
		columnTypes():ISelectItem<SettingsColumnType>[]{
			return [
				{
					text: this.$ct("column-types.default"),
					value: "default"
				},
				{
					text: this.$ct("column-types.sum"),
					value: "sum"
				}
			];
		}
	},
	watch: {
		value: {
			deep: true,
			immediate: true,
			handler(){
				if (this.value.getColumnType() != "default") {
					this.value.Style.TextAlign = TextAlignment.RIGHT;
					return;
				}
				if (!this.selectedProp) {
					this.value.Total = false;
					this.value.Cumulative = false;
					return;
				}
				if (this.selectedProp.field.type != "number") {
					this.value.Total = false;
					this.value.Style.TextAlign = TextAlignment.LEFT;
					return;
				}
				this.value.Style.TextAlign = TextAlignment.RIGHT;
			}
		},
		selectedProp: {
			immediate: false,
			handler(){
				this.value.TextIfValueSettings = [];
				if (!this.selectedProp) return;
				if (this.selectedProp.field.defaultTextIfValueSettings) {
					this.setDefaultTextIfValueSettings();
				}
			}
		}
	},
	methods: {
		confirm() {
			this.$emit("confirm");
		},
		cancel() {
			this.$emit("cancel");
		},
		async setDefaultTextIfValueSettings(){
			if (!this.selectedProp) return;
			if (!this.selectedProp.field.defaultTextIfValueSettings) return;
			let threadId = v4();
			this.setDefaultTextIfValueSettingsThreadId = threadId;
			let settings = await this.selectedProp.field.defaultTextIfValueSettings();
			if (this.setDefaultTextIfValueSettingsThreadId != threadId) return;
			// Add preloaded fields
			for (let setting of settings) {
				let prefix = this.value.Value;
				let parts = prefix.split(".");
				parts.pop();
				for (let condition of setting.Conditions) {
					let conditionParts = condition.Prop.split(".");
					condition.Prop = parts.concat(conditionParts).join(".");
				}
			}
			this.value.TextIfValueSettings = settings;
		},
		setColumnType(cType:SettingsColumnType){
			if (cType == "default") {
				this.value.Value = filterFieldPathToBrowseFieldPath(this.model.getTopLevelFields()[0].path);
			}else if (cType == "sum") {
				this.value.Value = "{{sum}}";
			}
		}
	},
	components: {
		AppColumnSelect,
		AppSelectFallbackColumn,
		AppTextIfValueSettings,
		AppSelectColumnsSumColsConfig
	},
	i18nEx: {
		componentPrefix: "components.reportengine"
	}
});
