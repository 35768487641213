import { ServerConfig } from "@/config/config";
import { Axios } from "@/utils/axios";
import { downloadBlob } from "@/utils/blob";
import { VatDeclarationData } from "@/utils/vat-delcaration/data";
import { VatDeclarationResultData } from "@/utils/vat-delcaration/result-data";

export type IntraConsignmentData = {Period:{Month:number, Year:number}, List:any[]}
export type IVatListingData ={}

export class BookkeepingServiceClass {
	get url():string{
		return ServerConfig.host + "/bookkeeping";
	}

	async createVatDeclarationData(data:VatDeclarationData):Promise<VatDeclarationResultData>{
		let res = await Axios.post(`${this.url}/vat-declaration`, data.getJSON());
		return new VatDeclarationResultData(res.data);
	}

	async createVatDeclaration(data:VatDeclarationResultData):Promise<Blob>{
		let res = await Axios.post(`${this.url}/vat-declaration/download`, data.getJSON(), {responseType: "blob"});
		return new Blob([res.data]);
	}

	async createVatDeclarationPdf(data:VatDeclarationResultData):Promise<Blob>{
		let res = await Axios.post(`${this.url}/vat-declaration/pdf-download`, data.getJSON(), {responseType: "blob"});
		return new Blob([res.data]);
	}

	async getIntraConsignmentData(year:number, month:number, quarter:number):Promise<IntraConsignmentData> {
		let result = await Axios.post(`${this.url}/intra-consignment/generate-data`, {Year: year, Month: month, Quarter: quarter});
		return result.data as IntraConsignmentData;
	}

	async intraConsignmentDataToXml(data:IntraConsignmentData){
		let result = await Axios.post(`${this.url}/intra-consignment/data-to-xml`, data);
		let blob = new Blob([result.data], {type: "application/xml"});
		downloadBlob(blob, `intracommunautaire-opgave-${data.Period.Year}-${data.Period.Month}.xml`);
	}

	async intraConsignmentDataToPdf(data:IntraConsignmentData){
		let result = await Axios.post(`${this.url}/intra-consignment/data-to-pdf`, data, {responseType: "blob"});
		let blob = new Blob([result.data], {type: "application/pdf"});
		downloadBlob(blob, `intracommunautaire-opgave-${data.Period.Year}-${data.Period.Month}.pdf`);
	}

	async vatListingGenerateData(year:number):Promise<IVatListingData>{
		let result = await Axios.post(`${this.url}/vat-listing/generate-data`, {Year: year});
		return result.data;
	}

	async vatListingDownloadXml(vatListingData:IVatListingData, year:number):Promise<void>{
		let result = await Axios.post(`${this.url}/vat-listing/download`, vatListingData,  {responseType: "blob"});
		let blob = new Blob([result.data], {type: "application/xml"});
		downloadBlob(blob, `btw-listing-${year}.xml`);
	}

	async vatListingDownloadPdf(vatListingData:IVatListingData, year:number):Promise<void>{
		let result = await Axios.post(`${this.url}/vat-listing/download-pdf`, vatListingData,  {responseType: "blob"});
		let blob = new Blob([result.data], {type: "application/pdf"});
		downloadBlob(blob, `btw-listing-${year}.pdf`);
	}

	async createIntrastat(month:number, year:number):Promise<void>{
		let result = await Axios.post(`${this.url}/intrastat/create`, {Month: month, Year: year}, {responseType: "blob"});
		let blob = new Blob([result.data], {type: "application/xml"});
		downloadBlob(blob, `intrastat-${year}-${month}.xml`);
	}

}

export const BookkeepingService = new BookkeepingServiceClass();