import { LabelLayout } from "@/utils/label-layout/label-layout";

export class LabelLayoutData {
	ID:number = 0;
	Data:LabelLayout = new LabelLayout();

	public constructor(data?:any){
		if (!data) return;
		this.ID = data.ID;
		this.Data = new LabelLayout(data.Data);
	}

	public getJSON(){
		return {
			ID: this.ID,
			Data: this.Data.getJSON()
		};
	}
}