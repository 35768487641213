import { i18n } from "@/setup/i18n-setup";
import { v4 } from "uuid";
import { DashboardItem } from "./dashboard-item";
import { DashboardQuickAddButton } from "./dashboard-quick-add-button";

export class DashboardTab {
	uid:string = v4();
	Name:string = i18n.t("components.dashboard.new-tab").toString();
	Items:DashboardItem[] = [];
	QuickAddButtons:DashboardQuickAddButton[] = [];

	public constructor(data?:any){
		if (!data) return;
		this.Name = data.Name;
		this.Items = (data.Items as any[] || []).map(d=>new DashboardItem(d));
		this.QuickAddButtons = (data.QuickAddButtons as any[] || []).map(d=>new DashboardQuickAddButton(d));
	}

	public getJSON(){
		return {
			Name: this.Name,
			Items: this.Items.map(c=>c.getJSON()),
			QuickAddButtons: this.QuickAddButtons.map(b=>b.getJSON())
		};
	}
}