import { tokenStore } from "../token-store";
import { ISocketAction, ISocketMessage } from "./socket-worker-interfaces";
import { v4 as uuid } from "uuid";
import { SocketServiceBase } from "./socket-service-base";
import { AuthService } from "@/services/auth-service";


export class SocketWorkerInterface extends SocketServiceBase{
	private shouldConnect = false;
	private worker:SharedWorker | null = null;

	public constructor(host:string, path:string){
		super(host, path);
		this.onPortError = this.onPortError.bind(this);
		this.onPortMessage = this.onPortMessage.bind(this);
	}

	private onPortError(ev:ErrorEvent){
		console.error(ev);
	}

	private onPortMessage(ev:MessageEvent){
		if (!ev.data) return;
		if (ev.data.event == "log"){
			this.log("SOCKET WORKER:", ...ev.data.data);
			return;
		}
		let data:ISocketMessage = ev.data;
		if (data.event == "reconnect_attempt"){
			this.log("SOCKET RECONECT ATTEMPT:", ...ev.data.data);
			this.onReconnectAttempt();
			return;
		}
		if (ev.data.event == "heartbeat"){
			this.postMessage({method: "/heartbeat", args: []});
			return;
		}
		this.log("Event:", ...ev.data.data);
		this.triggerEvent(data.event, ...data.data);
	}

	private initPort(){
		if (!this.worker) return;
		this.worker.addEventListener("error", this.onPortError);
		this.worker.port.addEventListener("message", this.onPortMessage);
		this.on("/ready", ()=>{
			this.log("Recieved ready");
			this.setIsConnected(true);
		});
		this.on("reconnect_attempt", ()=>{
			this.log("On reconnect attempt");
			this.setIsConnected(false);
			this.updateQuery();
		});
		this.on("disconnect", async ()=>{
			this.log("Disconnected");
			this.setIsConnected(false);
			if (!this.shouldConnect) return;
			if (!tokenStore.tokenExpires) return;
			if (!tokenStore.willTokenExpireInSeconds(60)) {
				this.log("Tokens will not expire");
				this.updateQuery();
				return;
			}
			this.log("Socket service tokens about to expire");
			this.log("Force refreshing tokens");
			await AuthService.refreshTokens();
			this.log("Connecting");
		});
		this.on("/init-error", async (message:string)=>{
			this.log(this.host, "init-error", message);
			this.setIsConnected(false);
			if (message == "TOKEN_EXPIRED" || message == "ERR_TOKEN_EXPIRED"){
				await AuthService.refreshTokens();
				//this.triggerEvent(ERR_TOKEN_EXPIRED);
			}
		});
		this.worker.port.start();
	}

	broadcastToTabs(...data:any[]){
		this.postMessage({method: "broadcast-to-tabs", args: data});
	}

	emit(event: string, ...data: any): Promise<any> {
		return new Promise((resolve)=>{
			let id = uuid();
			this.postMessage({method: "emit", args: [event, ...data], actionId: id});
			this.once(`reply/${id}`, resolve);
		});
	}

	async onReconnectAttempt(){
		if (tokenStore.willTokenExpireInSeconds(10)){
			await AuthService.refreshTokens();
		}
		this.updateQuery();
	}

	public updateQuery(): void {
		this.postMessage({method: "updateQuery", args: [{token: tokenStore.getSelectedToken().token, id: tokenStore.socketId}]});
	}

	log(...data:any){
		console.info("Socket ", this.host, ...data);
	}


	public async connect(dossierId:number):Promise<void>{
		if (this.worker) {
			this.disconnect();
		}
		this.log("Connecting", this.host, dossierId);
		this.shouldConnect = true;
		let options:SocketIOClient.ConnectOpts = {
			host: this.host,
			transports: ["websocket"],
			upgrade: false,
			path: this.path,
			reconnection: true,
			reconnectionAttempts: Infinity,
			reconnectionDelay: 1000,
			reconnectionDelayMax: 5000,
			// timeout: 10000,
			query: {}
		};
		this.worker = new SharedWorker(new URL("./socket-shared-worker.ts", import.meta.url), {name: `wf-${dossierId}/${this.host}`});
		this.initPort();

		return new Promise((resolve, reject)=>{
			let ref = {};
			this.once("/init-error", (message:string)=>{
				reject(new Error(message));
			}, ref);

			this.once("/ready", ()=>{
				this.log("Got ready");
				this.setIsConnected(true);
				resolve();
			}, ref);
			this.log(tokenStore.socketId);
			this.postMessage({args: [options, tokenStore.getSelectedToken().token, tokenStore.socketId], method: "connect"});
		});
	}

	public async disconnect():Promise<void>{
		this.shouldConnect = false;
		if (!this.worker) return;
		this.postMessage({method: "port-disconnect", args: []});
		this.worker.port.close();
		this.worker.removeEventListener("error", this.onPortError);
		this.worker.port.removeEventListener("message", this.onPortMessage);
		this.log(this.worker);
	}

	private postMessage(data:ISocketAction){
		if (!this.worker) return;
		this.worker.port.postMessage(data);
	}

}
