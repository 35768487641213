import { ContactService } from "./contact-service";
import { CustomerService } from "@/services/customer-service";
import { SupplierService } from "@/services/supplier-service";
import { ProspectService } from "@/services/prospect-service";

export function getContactServiceByGroupId(groupId:number):ContactService {
	if (groupId == 1){
		return CustomerService;
	}
	if (groupId == 2){
		return SupplierService;
	}
	if (groupId == 3){
		return ProspectService;
	}
	throw new Error("Incorrect contact group ID");
}