
import { Product } from "@/models/base";
import Vue from "vue";
import AppAwsFilesEdit from "@/components/aws-files-modules/AwsFilesEdit.vue";


export default Vue.extend({
	props: {
		product: {
			type: Object as ()=>Product,
			required: true
		}
	},
	components: {
		AppAwsFilesEdit
	},
	i18nEx: {
		componentPrefix: "components.aws-documents"
	}
});
