
import { TaskStatus } from "@/models/tasks/task";
import { getEnumKeys, getEnumValueFromKey } from "@/utils/enum";
import Vue from "vue";
import { TranslateResult } from "vue-i18n";
import { ISelectItem } from "winfakt-vue-components";

export default Vue.extend({
	props: {
		value: {
			type: [String, Array as ()=>string[]],
			required: true
		},
		multi: Boolean,
		label: {
			type: [Object as ()=>TranslateResult, String],
			default():TranslateResult{
				if (this.multi) return this.$ct("statuses");
				return this.$ct("statuses");
			}
		}
	},
	computed: {
		taskStatuses(): ISelectItem<number>[] {
			return getEnumKeys(TaskStatus).map((s)=>{
				let val = getEnumValueFromKey(TaskStatus, s);
				return {
					value: val,
					text: this.$ct("models.TaskStatuses." + s).toString()
				};
			});
		},
		singleItemValue():number {
			if (typeof(this.value) == "string") {
				return this.parseNum(this.value);
			}
			return this.parseNum(this.value[0]);
		},
		multiItemValue():number[] {
			if (typeof(this.value) == "string") {
				return [this.parseNum(this.value)];
			}
			return this.value.map(v=>this.parseNum(v));
		}
	},
	methods: {
		parseNum(num:string):number{
			let result = parseInt(num || "0");
			if (Number.isNaN(result)) return 0;
			return result;
		},
		setSingleItemValue(val:number){
			this.$emit("input",[`${val}`]);
		},
		setMultiItemValue(val:number[]){
			this.$emit("input", val.map(v=>`${v}`));
		}
	}
});
