import { CustomTranslation } from "@/utils/custom-translation";
import { ExtraFieldBaseConfig } from "./extra-field-base-config";

export class ExtraFieldBooleanConfig extends ExtraFieldBaseConfig {
	DocumentYesText:CustomTranslation = new CustomTranslation();
	DocumentNoText:CustomTranslation = new CustomTranslation();
	DefaultValue:boolean = false;

	public constructor(data?:any){
		super(data);
		if (!data) return;
		this.DocumentNoText = new CustomTranslation(data.DocumentNoText);
		this.DocumentYesText = new CustomTranslation(data.DocumentYesText);
		this.DefaultValue = data.DefaultValue || false;
	}

	public getJSON(){
		return {
			...(super.getJSON()),
			DocumentNoText: this.DocumentNoText.getJSON(),
			DocumentYesText: this.DocumentYesText.getJSON(),
			DefaultValue: this.DefaultValue,
		};
	}

}