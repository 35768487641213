import { v4 } from "uuid";

export class SaleConfigCalendarToShowField {
	uid:string = v4();
	Prop:string = "";

	public constructor(data?:any) {
		if (!data) return;
		this.Prop = data.Prop;
	}

	getJSON(){
		return {
			Prop: this.Prop
		};
	}
}