
import Vue from "vue";
import CrmStatus from "@/models/crm/crm-status";
import { CrmService } from "@/services/crm-service";
import { TranslateResult } from "vue-i18n";

export default Vue.extend({
	data(){
		return{
			statuses: [] as CrmStatus[],
		};
	},
	props: {
		value: {
			required: true,
			type: [Number, Array as ()=>number[]]
		},
		multi: Boolean,
		fancy: Boolean,
		label: [String, Object as ()=>TranslateResult]
	},
	computed: {
		statusList(): any[]{
			return this.statuses.map(s => {
				return {
					text: s.Name,
					value: s.ID
				};
			});
		},
		val():number | number[] {
			if (!this.multi && typeof(this.value) != "number") {
				return this.value[0];
			}
			if (this.multi && typeof(this.value) == "number") {
				return [this.value];
			}
			return this.value;
		}
	},
	methods: {
		setVal(val:number | number[]){
			this.$emit("input", val);
		}
	},
	created(){
		this.statuses = CrmService.statuses;
	},
	components: {

	},
	i18nEx: {
		componentPrefix: "components.crm"
	}
});
	