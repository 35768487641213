export enum ObservationType {
	REPAIR_MAINTENANCE_SERVICE= "REPAIR_MAINTENANCE_SERVICE",
	ODOMETER_REPLACED= "ODOMETER_REPLACED",
	VEHICLE_HISTORY_DOCUMENT= "VEHICLE_HISTORY_DOCUMENT",
}

function formatDateDigits(val:number):string {
	let v = `${val}`;
	if (v.length == 1) {
		return `0${v}`;
	}
	return v;
}

export function getCarpassDate(date:Date | null):string {
	if (!date) return "";
	return `${date.getFullYear()}-${formatDateDigits(date.getMonth()+1)}-${formatDateDigits(date.getDate())}`;
}

export class Observation {
	vin:string ="";
	licensePlate:string = "";
	Unifier:number = 0;
	firstUseDate:Date | null = null;
	odometerReadingDate:Date = new Date();
	odometerReading:number = 0;
	observationType:ObservationType = ObservationType.REPAIR_MAINTENANCE_SERVICE;
	enterpriseOrganisation:string = "";
	enteredOrganisation:string = "";

	public constructor(data?:any){
		if (!data) return;

		this.vin = data.vin;
		this.licensePlate = data.licensePlate;
		this.Unifier = data.Unifier;
		if (data.firstUseDate) {
			this.firstUseDate = new Date(data.firstUseDate);
		}
		if (data.odometerReadingDate) {
			this.odometerReadingDate = new Date(data.odometerReadingDate);
		}
		this.odometerReading = data.odometerReading;
		this.observationType = data.observationType;
		this.enterpriseOrganisation = data.enterpriseOrganisation;
		this.enteredOrganisation = data.enteredOrganisation;
	}


	public getJSON(){
		return {
			vin: this.vin,
			licensePlate: this.licensePlate,
			Unifier: this.Unifier,
			firstUseDate: getCarpassDate(this.firstUseDate),
			odometerReadingDate: getCarpassDate(this.odometerReadingDate),
			odometerReading: this.odometerReading,
			observationType: this.observationType,
			enterpriseOrganisation: this.enterpriseOrganisation,
			enteredOrganisation: this.enteredOrganisation,
		};
	}
}