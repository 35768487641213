
import AppFileUpload from "@/components/FileUploadDropAndProgress.vue";
import PageSettingsBackgroundImageSettings
	from "@/components/document-settings/PageSettingsBackgroundImageSettings.vue";
import Vue from "vue";
import { ISelectItem } from "winfakt-vue-components";
import { AWSFile } from "../../models/aws-file";
import { AWSFileService } from "../../services/aws-file-service";
import { DocumentPageSettings } from "../../utils/documents/page-settings";

export default Vue.extend({
	data() {
		return {
			images: [] as AWSFile[],
			sending: false,
			editSettings: false
		};
	},
	props: {
		value: {
			type: Object as () => DocumentPageSettings,
			required: true
		}
	},
	computed: {
		imageItems(): ISelectItem<string>[] {
			let items = this.images.map(i => {
				return {
					text: i.Name,
					value: i.Token,
				};
			}) as ISelectItem<string>[];
			items.unshift({
				text: this.$ct("none").toString(),
				value: ""
			});
			return items;
		},
	},
	methods: {
		async onFileInput(file: File) {
			this.sending = true;
			await AWSFileService.uploadFile("/print-layouts", file);
			await this.fetchImages();
			this.sending = false;
		},
		async fetchImages() {
			this.images = await AWSFileService.listFiles("/print-layouts");
		}
	},
	async created() {
		await this.fetchImages();
	},
	components: {
		AppFileUpload,
		PageSettingsBackgroundImageSettings
	},
	i18nEx: {
		componentPrefix: "components.document-settings"
	}
});
