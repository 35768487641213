import { getAuthFileUrl } from "@/utils/url";

export class UserInfo {
	ID:number = 0;
	Username:string = "";
	Firstname:string = "";
	Lastname:string = "";
	Email:string = "";
	Online:boolean = false;
	ConversationID:number = 0;
	Unread: number = 0;
	Phone: string = "";
	DossierIDs: number[] = [];
	TotpEnabled:boolean = false;
	DateCreated:Date = new Date();
	ProfileImg:string = "";
	Activated:boolean = false;

	constructor(data?:any) {
		if (!data) return;
		this.ID = data.ID || data.UserID;
		this.Username = data.Username;
		this.Firstname = data.Firstname;
		this.Lastname = data.Lastname;
		this.Email = data.Email;
		this.Phone = data.Phone;
		this.Online = data.online;
		this.Unread = data.unread;
		this.ConversationID = data.ConversationID;
		if (data.DossierIDs){
			this.DossierIDs = data.DossierIDs;
		}
		this.TotpEnabled = data.TotpEnabled;
		this.DateCreated = new Date(data.DateCreated);
		this.ProfileImg = data.ProfileImg;
		this.Activated = data.Activated;
	}

	getUserInitials():string {
		return this.Firstname.substring(0, 1) + this.Lastname.substring(0, 1);
	}

	getUserString():string {
		return `${this.Firstname} ${this.Lastname} (${this.Email || this.Username})`;
	}

	getUserFullName():string {
		return `${this.Firstname} ${this.Lastname}`;
	}

	getShortDisplayName():string{
		let result = this.getUserFullName();
		if (result.trim()){
			return result;
		}
		result = this.Username;
		if (result.trim()){
			return result;
		}
		return this.Email;
	}

	hasAccessToDossier(dossierId:number):boolean {
		return this.DossierIDs.indexOf(dossierId) != -1;
	}

	getJSON(){
		return {
			ID: this.ID,
			Username: this.Username,
			Firstname: this.Firstname,
			Lastname: this.Lastname,
			Email: this.Email,
			Phone: this.Phone,
			DateCreated: this.DateCreated.toJSON(),
			ProfileImg: this.ProfileImg
		};
	}

	getProfileImgUrl():string{
		if (!this.ProfileImg){
			return "/img/profile.png";
		}
		return getAuthFileUrl(this.ProfileImg);
	}
}