import { StyledProperty } from "./styled-property";

export enum ImageFit {
	FILL = "fill",
	CONTAIN = "contain",
	COVER = "cover",
	NONE = "none"
}

export enum ImageHorizontalAlign {
	LEFT = "left",
	CENTER = "center",
	RIGHT = "right"
}

export enum ImageVerticalAlign {
	TOP = "top",
	MIDDLE = "baseline",
	BOTTOM = "bottom"
}

export class ImageStyledProperty extends StyledProperty {
	Fit:ImageFit = ImageFit.CONTAIN;
	Width:number = 10.0;
	Height:number = 10.0;
	HorizontalAlign:ImageHorizontalAlign = ImageHorizontalAlign.CENTER;
	VerticalAlign:ImageVerticalAlign = ImageVerticalAlign.MIDDLE;

	public constructor(data?:any) {
		super(data);
		if (!data) return;
		this.setValues(data);
	}

	public setValues(data:any){
		super.setValues(data);
		this.Fit = data.Fit;
		this.Width = data.Width;
		this.Height = data.Height;
		this.HorizontalAlign = data.HorizontalAlign;
		this.VerticalAlign = data.VerticalAlign;
	}

	public getStyle():any{
		return  {
			...super.getStyle(),
			width: `${this.Width}mm`,
			height: `${this.Height}mm`,
			lineHeight: `${this.Height - (this.getBorderHeight() + this.getPaddingHeight())}mm`,
			textAlign: this.HorizontalAlign,
			overflowX: "hidden",
			overflowY: "hidden",
			display: "block",
			boxSizing: "border-box"
		};
	}

	public getImgStyle():any {
		let width = "100%";
		let height = "100%";
		let maxWidth = "";
		let maxHeight = "";
		if (this.Fit == ImageFit.CONTAIN) {
			width = "";
			height = "";
			maxWidth = "100%";
			maxHeight = "100%";
		}
		return {
			width,
			height,
			maxWidth,
			maxHeight,
			objectFit: this.Fit,
			verticalAlign: this.VerticalAlign
		};
	}

	public getJSON():any {
		return {
			...super.getJSON(),
			Fit: this.Fit,
			Width: this.Width,
			Height: this.Height,
			HorizontalAlign: this.HorizontalAlign,
			VerticalAlign: this.VerticalAlign
		};
	}
}