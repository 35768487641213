export class ResultDataAddedFromPreviousDeclarations {
	Grid56:number = 0.0;
	Grid57:number = 0.0;
	Grid81:number = 0.0;
	Grid82:number = 0.0;
	Grid83:number = 0.0;
	Grid86:number = 0.0;
	Grid87:number = 0.0;
	Grid88:number = 0.0;

	public constructor(data?:any){
		if (!data) return;
		this.Grid56 = data.Grid56 || 0.0;
		this.Grid57 = data.Grid57 || 0.0;
		this.Grid81 = data.Grid81 || 0.0;
		this.Grid82 = data.Grid82 || 0.0;
		this.Grid83 = data.Grid83 || 0.0;
		this.Grid86 = data.Grid86 || 0.0;
		this.Grid87 = data.Grid87 || 0.0;
		this.Grid88 = data.Grid88 || 0.0;
	}

	public static getCoppiedFields():number[] {
		return [56,57,81,82,83,86,87,88];
	}

	private getNummericKeys():(keyof ResultDataAddedFromPreviousDeclarations)[] {
		return (Object.keys(this) as (keyof ResultDataAddedFromPreviousDeclarations)[]).filter((k)=>k.match("^Grid[0-9][0-9]$"));
	}

	public getNot0Values():{grid:number, value:number}[]{
		let keys = this.getNummericKeys();
		console.log(keys);
		return keys.map(k=>{
			return {
				grid: parseInt(k.replace("Grid", "")),
				value: this[k] as number
			};
		}).filter(k=>k.value != 0);
	}
}