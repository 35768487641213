import { CustomTranslation } from "@/utils/custom-translation";
import { ExtraFieldBaseConfig } from "./extra-field-base-config";

export class ExtraFieldTimeConfig extends ExtraFieldBaseConfig{
	DocumentFormat:string = "02/01/2006";
	EmptyText:CustomTranslation = new CustomTranslation();
	DefaultValue:Date | null = null;

	public constructor(data?:any) {
		super(data);
		if (!data) return;
		this.DocumentFormat = data.DocumentFormat;
		this.EmptyText = new CustomTranslation(data.EmptyText);
		if (data.DefaultValue) {
			this.DefaultValue = new Date(data.DefaultValue);
		}
	}

	public getJSON(){
		return {
			...(super.getJSON()),
			DocumentFormat: this.DocumentFormat,
			EmptyText: this.EmptyText,
			DefaultValue: this.DefaultValue ? this.DefaultValue.toJSON() : null
		};
	}
}