import { ignoredFields, model } from "@/utils/models/decorator-model";
import { LedgerAccount } from "../ledger-account";
import Contact from "./contact";
import { canBeFilteredNummerically, decimals, field, filterValueInput, hidden, nullable, print, rightAlign } from "@/utils/models/decorator-field";
import { printLocalDate } from "@/utils/pretty-print";
import { BookYearService } from "@/services/book-year-service";
import { Purchase, Sale } from ".";


@model("DiverseLedger")
@ignoredFields([
	"Contact.MainAddress.Contact"
])
export class DiverseLedger {

	@field("number")
	public ID:number = 0;

	@field("number")
	public JournalID:number = 1;

	@field("number")
	public GroupID:number = 1;

	@field("number")
	@filterValueInput("Contact")
	public ContactID:number = 0;

	@field("string")
	@canBeFilteredNummerically()
	public LedgerCode:string = ""

	@field("number")
	@decimals(2)
	@rightAlign()
	public Debet:number = 0;

	@field("number")
	@decimals(2)
	@rightAlign()
	public Credit:number = 0;

	@field("number")
	@rightAlign()
	public VatPeriod:number = 0;


	@field("string")
	public Comment:string = "";

	@field("date")
	@print(printLocalDate)
	public Date:Date = new Date();


	@field("number")
	@filterValueInput("BookYear")
	public BookYear:number = 0;

	@field("has-one", "LedgerAccount")
	@nullable()
	public LedgerAccount:LedgerAccount | null = null;

	@field("has-one", "Contact")
	public Contact: Contact | null = new Contact();

	@field("number")
	@hidden()
	public InvoiceID:number = 0;

	@field("number")
	@hidden()
	public FriendlySaleID:string = "";

	@field("has-one", "Sale")
	public Invoice:Sale | null = null;

	@field("number")
	@hidden()
	public PurchaseID:number = 0;

	@field("string")
	@hidden()
	public FriendlyPurchaseID:string = "";

	@field("has-one", "Purchase")
	public Purchase:Purchase | null = null;


	// eslint-disable-next-line max-lines-per-function
	public constructor(data?:any) {
		if (!data) return;
		this.ID = data.ID;
		this.JournalID = data.JournalID;
		this.GroupID = data.GroupID;
		this.ContactID = data.ContactID;
		this.LedgerCode = data.LedgerCode;
		this.Debet = data.Debet;
		this.Credit = data.Credit;
		this.Comment = data.Comment;
		this.Date = new Date(data.Date);

		if(data.BookYear){
			this.BookYear = data.BookYear;
		}else{
			this.BookYear = BookYearService.selectedBookYear;
		}
		if(data.VatPeriod){
			this.VatPeriod = data.VatPeriod;
		} else{
			this.VatPeriod = BookYearService.getAccountingPeriodFromDate(this.Date);
		}
		if (data.LedgerAccount){
			this.LedgerAccount = new LedgerAccount(data.LedgerAccount);
		}
		if (data.Contact){
			this.Contact = new Contact(data.Contact);
		}

		this.InvoiceID = data.InvoiceID;
		this.FriendlySaleID = data.FriendlySaleID;

		this.PurchaseID = data.PurchaseID;
		this.FriendlyPurchaseID = data.FriendlyPurchaseID;

		if (data.Invoice){
			this.Invoice = new Sale(data.Invoice);
		}
		if (data.Purchase){
			this.Purchase = new Purchase(data.Purchase);
		}

	}

	public getJSON(): any{
		return {
			ID: this.ID,
			JournalID: this.JournalID,
			GroupID: this.GroupID,
			ContactID: this.ContactID,
			LedgerCode: this.LedgerCode,
			Debet: this.Debet,
			Credit: this.Credit,
			Comment: this.Comment,
			Date: this.Date.toJSON(),
			BookYear: this.BookYear,
			LedgerAccount: this.LedgerAccount?.getJSON(),
			VatPeriod: this.VatPeriod,
			InvoiceID: this.InvoiceID,
			PurchaseID: this.PurchaseID
		};
	}

}