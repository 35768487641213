
import { ProductGroup } from "@/models/base/product-group";
import Vue from "vue";
export default Vue.extend({
	name: "AppProductGroupSelectNode",
	data(){
		return {
			opened: false
		};
	},
	props: {
		value: {
			type: Object as ()=>ProductGroup,
			required: true
		},
		useIds: Boolean,
		selectedValue: {
			type: [Object as ()=>ProductGroup, Array as ()=>ProductGroup[] | number[], Number]
		}
	},
	computed: {
		openIcon():string{
			if (this.opened) return "minus";
			return "plus";
		},
		hasChildren():boolean{
			return this.value.Children.length != 0;
		},
		selected():boolean {
			if (Array.isArray(this.selectedValue)){
				if (this.useIds) {
					return (this.selectedValue as number[]).indexOf(this.value.ID) != -1;
				}
				return (this.selectedValue as ProductGroup[]).indexOf(this.value) != -1;

			}
			if (this.useIds) {
				return this.value.ID == this.selectedValue;
			}
			return this.value == this.selectedValue;
		}
	},
	methods: {
		itemClicked(val:ProductGroup){
			this.$emit("click", val);
		}
	},
	async mounted(){
		this.value.fetchChildren();
	}
});
