
import Vue from "vue";
import AppAskedFilterEditValueDefault from "./AskedFilterEditValueDefault.vue";
import AppAskedFilterEditValueCustom from "./AskedFilterEditValueCustom.vue";
import { ModelPropertyType } from "@/utils/models/model-field";
import { AskedFilter } from "@/utils/report-engine-settings/asked-filter";

export default Vue.extend({
	props: {
		filter: {
			type: Object as ()=>AskedFilter,
			required: true
		},
		fieldType: {
			type: String,
			required: true
		},
		showLabelInputs: {
			type: Boolean
		}
	},
	computed: {
		isCustom():boolean {
			return (["string", "boolean", "number", "date"] as ModelPropertyType[]).findIndex(f=>f == this.fieldType) == -1;
		}
	},
	components: {
		AppAskedFilterEditValueDefault,
		AppAskedFilterEditValueCustom
	}
});
