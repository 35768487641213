
import { IModel } from "@/utils/models/model-interface";
import { TextIfValue } from "@/utils/report-engine-settings/text-if-value";
import Vue from "vue";
import { TranslateResult } from "vue-i18n";
import AppTextIfValueEdit from "./TextIfValueEdit.vue";

export default Vue.extend({
	data(){
		return {
			toEditItem: null as null | TextIfValue,
			toEditIndex: -1
		};
	},
	props: {
		settings: {
			type: Array as ()=>TextIfValue[],
			required: true
		},
		model: {
			type: Object as ()=>IModel,
			required: true
		}
	},
	methods: {
		editItem(i:number){
			this.toEditItem = this.settings[i];
			this.toEditIndex = i;
		},
		removeItem(i:number){
			this.settings.splice(i, 1);
		},
		addItem(){
			this.toEditIndex = -1;
			this.toEditItem = new TextIfValue();
		},
		closeEditor(){
			this.toEditIndex = -1;
			this.toEditItem = null;
		},
		onInput(val:TextIfValue){
			if (this.toEditIndex == -1){
				this.settings.push(val);
			}else{
				this.settings[this.toEditIndex] = val;
			}
		},
		getText(val:TextIfValue):TranslateResult{
			return val.toString((val:string)=>{
				let field = this.model.getField(val);
				if (!field){
					return "ERROR";
				}
				return field.getPropertyTranslation();
			});
		}
	},
	components: {
		AppTextIfValueEdit
	},
	i18nEx: {
		componentPrefix: "components.reportengine"
	}
});
