import {Product} from "@/models/base";
import {ProductService} from "@/services";

export class FavvTax {
	FavvProductSku:string =  "";
	FavvCost:number = 0;

	constructor(data?:any) {
		if (!data) return;
		this.FavvProductSku = data.FavvProductSku;
		this.FavvCost = data.FavvCost;
	}

	getJSON() {
		return {
			FavvProductSku: this.FavvProductSku,
			FavvCost: this.FavvCost
		};
	}
	async getProduct():Promise<Product>{
		if(this.FavvProductSku){
			return ProductService.findBySku(this.FavvProductSku);
		}
		return new Product();
	}
}