import { Browse } from "@/models/browse";
import { BrowseService } from "@/services/browse-service";
import { DocumentPageSettings } from "@/utils/documents/page-settings";
import { HumanFilter } from "@/utils/human-filter";
import { Group } from "@/utils/report-engine-settings/group";
import { OrderColumn } from "@/utils/report-engine-settings/order-column";
import { SettingsColumn } from "@/utils/report-engine-settings/settings-column";
import { AskedFilter } from "./asked-filter";
import { OrderGroupTotalColumn } from "./order-group-total-column";
import { Summary } from "./summary";

export type ReportType = "pdf" | "excel"

export class ReportEngineSettings {
	PageSettings:DocumentPageSettings = new DocumentPageSettings();
	Columns:SettingsColumn[] = [];
	Filters:HumanFilter[] = [];
	OrderColumns:OrderColumn[] = [];
	OrderGroupsTotalColumns:OrderGroupTotalColumn[] = [];
	Groups:Group[] = [];
	AskedFilters:AskedFilter[] = [];
	HideGroupedContents:boolean = false;
	HideDetailRows:boolean = false;
	DataSource:string = "";
	Title:string = "";
	SubTitle1:string = "";
	SubTitle2:string = "";
	FootText1:string = "";
	FootText2:string = "";
	AskTitle:boolean = false;
	AskSubTitle1:boolean = false;
	AskSubTitle2:boolean = false;
	DefaultType:ReportType = "pdf";
	Summary:Summary = new Summary();
	BookYears:number[] = [];

	RecordsPerColumn:number = 0;
	OnlyOneRecordPerPage:boolean = false;

	constructor(data?:any) {
		if(!data) return;
		this.PageSettings = new DocumentPageSettings(data.PageSettings);
		this.Columns = (data.Columns || []).map((c:any) => new SettingsColumn(c));
		this.Filters = (data.Filters || []).map((f:any) => new HumanFilter(f));
		this.OrderColumns = (data.OrderColumns || []).map((o:any) => new OrderColumn(o));
		this.OrderGroupsTotalColumns = (data.OrderGroupsTotalColumns || []).map((o:any)=>new OrderGroupTotalColumn(this.Columns[o.SettingsColumnIndex],o.ASC));
		this.Groups = (data.Groups || []).map((g:any) => new Group(g));
		this.AskedFilters = (data.AskedFilters || []).map((a:any)=> new AskedFilter(a));
		this.HideGroupedContents = data.HideGroupedContents;
		this.HideDetailRows = data.HideDetailRows;
		this.DataSource = data.DataSource;
		this.Title = data.Title;
		this.SubTitle1 = data.SubTitle1;
		this.SubTitle2 = data.SubTitle2;
		this.FootText1 = data.FootText1;
		this.FootText2 = data.FootText2;
		this.AskTitle = data.AskTitle || false;
		this.AskSubTitle1 = data.AskSubTitle1 || false;
		this.AskSubTitle2 = data.AskSubTitle2 || false;
		this.DefaultType = data.DefaultType || "pdf";
		this.RecordsPerColumn = data.RecordsPerColumn || 0;
		this.OnlyOneRecordPerPage = data.OnlyOneRecordPerPage || false;
		this.Summary = new Summary(data.Summary);
		this.BookYears = [...(data.BookYears || [])];
	}

	getJSON():any {
		return {
			Columns: this.Columns.map(c => c.getJSON()),
			Filters: this.Filters,
			OrderColumns: this.OrderColumns.map(o => o.getJSON()),
			OrderGroupsTotalColumns: this.OrderGroupsTotalColumns.map(o=>o.getJSON(this)).filter(s=>s.SettingsColumnIndex!=-1),
			Groups: this.Groups.map(g => g.getJSON()),
			AskedFilters: this.AskedFilters.map(a=>a.getJSON()),
			PageSettings: this.PageSettings.getJSON(),
			HideGroupedContents: this.HideGroupedContents,
			HideDetailRows: this.HideDetailRows,
			DataSource: this.DataSource,
			Title: this.Title,
			SubTitle1: this.SubTitle1,
			SubTitle2: this.SubTitle2,
			FootText1: this.FootText1,
			FootText2: this.FootText2,
			AskTitle: this.AskTitle,
			AskSubTitle1: this.AskSubTitle1,
			AskSubTitle2: this.AskSubTitle2,
			DefaultType: this.DefaultType,
			RecordsPerColumn: this.RecordsPerColumn,
			OnlyOneRecordPerPage: this.OnlyOneRecordPerPage,
			Summary: this.Summary.getJSON(),
			BookYears: this.BookYears
		};
	}

	isSourceTypeBrowse():boolean{
		return ReportEngineSettings.isSourceTypeBrowse(this.DataSource);
	}
	getBrowseIDFromDataSource():number{
		return ReportEngineSettings.getBrowseIDFromDataSource(this.DataSource);
	}
	isSourceTypeScript():boolean{
		return ReportEngineSettings.isSourceTypeScript(this.DataSource);
	}
	getScriptID():number{
		return ReportEngineSettings.getScriptID(this.DataSource);
	}
	isSourceTypeStandardScript():boolean{
		return ReportEngineSettings.isSourceTypeStandardScript(this.DataSource);
	}

	getBrowseIfBrowseDataSource():Browse | null {
		if (!this.isSourceTypeBrowse()) {
			return null;
		}
		let id = this.getBrowseIDFromDataSource();
		return BrowseService.getBrowseWithoutViewSettings(id);
	}

	isBookYearFilterRequired():boolean {
		if (this.isSourceTypeScript()) return false;
		let browseId = this.getBrowseIDFromDataSource();
		let browse = BrowseService.browses.find(b=>b.ID == browseId);
		if (!browse) return false;
		return browse.HasBookYearFilter;
	}

	static isSourceTypeBrowse(src:string):boolean {
		let exp = /^browse\/[0-9]+$/g;
		return exp.test(src);
	}

	static getBrowseIDFromDataSource(src:string):number {
		return parseInt(src.split("browse/")[1]);
	}

	static isSourceTypeScript(src:string):boolean {
		let exp = /^script\/[0-9]+$/;
		return exp.test(src);
	}

	static getScriptID(src:string):number{
		return parseInt(src.split("script/")[1]);
	}

	static isSourceTypeStandardScript(src:string):boolean {
		return /^standard-script\/[a-zA-Z0-9-]+$/.test(src);
	}
}