export class TotalColumn {
	Column:string = "this.ID";
	//Column:string = "";
	IsNegative:boolean = false;

	public constructor(data?:any){
		if (!data) return;
		this.Column = data.Column;
		this.IsNegative = data.IsNegative;
	}

	public getJSON():any{
		return {
			Column: this.Column,
			IsNegative: this.IsNegative
		};
	}
}