import { ServerConfig } from "@/config/config";
import { Axios } from "@/utils/axios";
import { LastOpenedItem } from "@/utils/last-opened";
import { ContactService, onLastOpenedItemSetFunction } from "@/utils/services/contact-service";
import { HttpError } from "@/utils/services/http-error";
import {IClientStats} from "@/utils/client-stats";




class CustomerServiceClass extends ContactService {
	protected routerPath: string = "/contacts/customers";
	protected url: string = `${ServerConfig.host}/clients`;
	public lastOpenedItem = new LastOpenedItem("contacts/customer", onLastOpenedItemSetFunction);

	public async getClientPortalUrl(clientId: number): Promise<string> {
		try {
			let response = await Axios.get(`${this.url}/create-client-portal-token/${clientId}`);
			return `${location.protocol}//${location.host}/client-portal/${response.data.URL}`;
		} catch (err) {
			let error = err as HttpError;
			if (error.key == "MISSING_MODULE") {
				error.dontShow = true;
				return "http";
			}
			throw err;
		}
	}

	public async getClientStats(clientId: number): Promise<IClientStats> {
		let response = await Axios.get(`${this.url}/${clientId}/stats`);
		return response.data as IClientStats;
	}

}

export const CustomerService = new CustomerServiceClass();