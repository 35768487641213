
import Vue from "vue";
import { SettingsColumn } from "@/utils/report-engine-settings/settings-column";
import { Model } from "@/utils/models/model";
import AppColumnSelect from "@/components/models/ColumnSelect.vue";

export default Vue.extend({
	data(){
		return {
			v: ""
		};
	},
	props: {
		value: {
			type: Object as () => SettingsColumn,
			required: true
		},
		model: {
			type: Object as () => Model,
			required: true
		}
	},
	methods: {
		cancel(){
			this.$emit("close");
		},
		confirm(){
			this.value.Fallback = this.v;
			this.$emit("close");
		}
	},
	mounted() {
		this.v = this.value.Fallback;
	},
	components: {
		AppColumnSelect
	},
	i18nEx: {
		componentPrefix: "components.reportengine"
	}
});
