import { field } from "@/utils/models/decorator-field";
import { model } from "@/utils/models/decorator-model";

@model("ContactGroup")
export default class ContactGroup{
	@field("number")
	public ID:number = 0;

	@field("string")
	public Name:string = "";

	public constructor(data?:any){
		if(!data){
			return;
		}
		this.ID = data.ID;
		this.Name = data.Name;
	}

	public getJSON(){
		return {
			ID: this.ID,
			Name: this.Name
		};
	}
}
