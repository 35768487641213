import { i18n } from "@/setup/i18n-setup";
import { ISelectItem } from "winfakt-vue-components";

export interface ILayoutEntity {
	X: number;
	Y: number;
	Height: number;
	Width: number;
}

export interface ILayoutImage extends ILayoutEntity {
	getImageUrl():string;
}


export interface IFormat {
	width: number;
	height: number;
}


// eslint-disable-next-line max-lines-per-function
export function getDefaultPageFormatsSelectItems():ISelectItem<IFormat>[]{
	return [
		{
			text: i18n.t("components.document-settings.formats.paperFormat", {format: "A3"}).toString(),
			value: {
				width: 297,
				height: 420
			} as IFormat
		},
		{
			text: i18n.t("components.document-settings.formats.paperFormat", {format: "A4"}).toString(),
			value: {
				width: 210,
				height: 297
			} as IFormat
		},
		{
			text: i18n.t("components.document-settings.formats.paperFormat", {format: "A5"}).toString(),
			value: {
				width: 148,
				height: 210
			} as IFormat
		},
		{
			text: i18n.t("components.document-settings.formats.paperFormatLandscape", {format: "A3"}).toString(),
			value: {
				height: 297,
				width: 420
			} as IFormat
		},
		{
			text: i18n.t("components.document-settings.formats.paperFormatLandscape", {format: "A4"}).toString(),
			value: {
				height: 210,
				width: 297
			} as IFormat
		},
		{
			text: i18n.t("components.document-settings.formats.paperFormatLandscape", {format: "A5"}).toString(),
			value: {
				height: 148,
				width: 210
			} as IFormat
		},
	];
}

export function compareFormat(a:IFormat, b:IFormat): boolean {
	return a.width == b.width && a.height == b.height;
}