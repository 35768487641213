
import Vue from "vue";
export default Vue.extend({
	props: {
		maxShowSize: {
			type: String,
			default: "sm",
			validator: (val:string) => ["xs", "sm", "md", "lg", "xl"].includes(val)
		}
	},
	computed: {
		showAsMobileActivity():boolean {
			if (this.maxShowSize == "xs") {
				return this.$wf.isXs;
			}
			if (this.maxShowSize == "sm") {
				return this.$wf.isSmDown;
			}
			if (this.maxShowSize == "md") {
				return this.$wf.isMdDown;
			}
			if (this.maxShowSize == "lg") {
				return this.$wf.isLgDown;
			}
			return true;
		}
	}
});
