import { IRoundDecimalsAdvancedOptions, roundDecimalsAdvanced } from "@/utils/numbers";
import { ExtraFieldBaseConfig } from "./extra-field-base-config";

export class ExtraFieldNumberConfig extends ExtraFieldBaseConfig{
	Decimals:number = 2;
	DecimalSeperator:string = ",";
	ThousandsSeperator:string = ".";
	ThousandsSeperatorInterval:number = 3;
	DefaultValue:number = 0.0;

	public constructor(data?:any){
		super(data);
		this.printValue = this.printValue.bind(this);
		if (!data) return;
		this.Decimals = data.Decimals || 0;
		this.DecimalSeperator = data.DecimalSeperator || "";
		this.ThousandsSeperatorInterval = data.ThousandsSeperatorInterval || 0;
		this.ThousandsSeperator = data.ThousandsSeperator || "";

		this.DefaultValue = data.DefaultValue;
	}

	public getJSON(){
		return {
			...(super.getJSON()),
			Decimals: this.Decimals,
			DecimalSeperator: this.DecimalSeperator,
			ThousandsSeperator: this.ThousandsSeperator,
			ThousandsSeperatorInterval: this.ThousandsSeperatorInterval,

			Defaultvalue: this.DefaultValue
		};
	}

	getRoundDecimalsAdvancedOptions():IRoundDecimalsAdvancedOptions{
		return {
			decimals: this.Decimals,
			decimalSeperator: this.DecimalSeperator,
			thousandSeperatorInterval: this.ThousandsSeperatorInterval,
			thousandsSeperator: this.ThousandsSeperator
		};
	}

	printValue(num:number):string{
		return roundDecimalsAdvanced(num, this.getRoundDecimalsAdvancedOptions());
	}
}