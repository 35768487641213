import { TaskAssignedUser } from "@/models/tasks/assigned-user";
import { TaskMessage } from "@/models/tasks/task-message";
import { TaskUnreadUser } from "@/models/tasks/task-unread-user";
import { EventInput } from "@fullcalendar/core/structs/event";
import { TaskService } from "@/services/task-service";
import { model } from "@/utils/models/decorator-model";
import { field, filterValueInput, nullable } from "@/utils/models/decorator-field";

export enum TaskStatus {
	PENDING = 0,
	UNCONFIRMED = 1,
	COMPLETED = 2
}

@model("Task")
export class Task {
	@field("number")
	ID:number = 0;
	FolderID:number = 0;
	@field("string")
	Text:string = "";
	OrderNumber:number = 0;
	@field("number")
	Priority:number = 0;
	@field("date")
	DueDate:Date | null = null;
	@field("date")
	@nullable()
	TimeStamp:Date = new Date();
	@field("number")
	@filterValueInput("TaskStatus")
	Status:TaskStatus = TaskStatus.PENDING;
	Messages:TaskMessage[] = [];
	MessagesUnreadUsers:TaskUnreadUser[] = [];
	AssignedUsers:TaskAssignedUser[] = [];

	constructor(data?:any) {
		if(!data) return;
		this.ID = data.ID;
		this.FolderID = data.FolderID;
		this.Text = data.Text;
		this.OrderNumber = data.OrderNumber;
		this.Priority = data.Priority;
		if (data.DueDate != null){
			this.DueDate = new Date(data.DueDate);
		}
		this.TimeStamp = new Date(data.TimeStamp);
		this.Status = data.Status;
		if (data.Messages) {
			this.Messages = data.Messages.map((m:any) => new TaskMessage(m));
			this.sortMessages();
		}
		if (data.MessagesUnreadUsers) {
			this.MessagesUnreadUsers = data.MessagesUnreadUsers.map((m:any) => new TaskUnreadUser(m));
		}
		if (data.AssignedUsers) {
			this.AssignedUsers = data.AssignedUsers.map((m:any) => new TaskAssignedUser(m));
		}
	}

	hasUnreadMessages(userId:number):boolean {
		return this.MessagesUnreadUsers.find(u => u.UserID == userId) != null;
	}

	sortMessages() {
		this.Messages = this.Messages.sort((a, b) => {
			return a.ID - b.ID;
		});
		this.Messages = this.Messages.filter((m, i) => this.Messages.findIndex(a => a.ID == m.ID) == i);
	}

	onUpdate(newTask:Task) {
		this.FolderID = newTask.FolderID;
		this.Text = newTask.Text;
		this.OrderNumber = newTask.OrderNumber;
		this.Priority = newTask.Priority;
		this.DueDate = newTask.DueDate;
		this.TimeStamp = newTask.TimeStamp;
		this.Status = newTask.Status;
	}

	getJSON() {
		return {
			ID: this.ID,
			FolderID: this.FolderID,
			Text: this.Text,
			OrderNumber: this.OrderNumber,
			Priority: this.Priority,
			DueDate: this.DueDate?.toJSON(),
			TimeStamp: this.TimeStamp.toJSON(),
			Status: this.Status,
			AssignedUsers: this.AssignedUsers.map(u => u.getJSON())
		};
	}

	getPriorityColor():string{
		return Task.getColorForPriority(this.Priority);
	}

	toFullCalendar():EventInput {
		return {
			id: this.ID,
			title: this.Text,
			start: this.DueDate || this.TimeStamp,
			end: this.DueDate || this.TimeStamp,
			allDay: true,
			url: "", // ServerConfig.host + "/calendar-event/" + this.ID,
			location: "",
			description: this.Text,
			backgroundColor: this.getPriorityColor(),
			color: "#FFFFFF",
			extendedProps: {
				id: this.ID,
				isTask: true,
			}
		};
	}

	static getColorForPriority(priority:number):string{
		if (priority == 0){
			return "var(--main-gray-3)";
		}
		if (priority == 1) {
			return "#ec4d32";
		}
		if (priority == 2) {
			return "#f8ab01";
		}
		if (priority == 3) {
			return "#26b62e";
		}
		if (priority == 4) {
			return "#2694a7";
		}
		return "var(--main-gray-3)";
	}

	async fetchMessages():Promise<void>{
		if (this.Messages.length > 0){
			this.sortMessages();
			var result = await TaskService.getTaskMessages(this.ID, this.Messages[0].ID);
		}else{
			var result = await TaskService.getTaskMessages(this.ID);
		}
		this.Messages.push(...result);
		this.sortMessages();
	}
}