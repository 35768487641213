
import { Condition, ConditionPropertyType, Operator } from "@/utils/condition/condition";
import Vue from "vue";
import AppPropSelectAutocomplete from "@/components/prop-select/PropSelectAutocomplete.vue";
import { PropSelectGroup } from "@/utils/prop-select/prop-select-group";
import { PropSelectItem } from "@/utils/prop-select/prop-select-item";
import AppConditionOperatorSelect from "@/components/models/ConditionOperatorSelect.vue";
import AppConditionValue from "@/components/models/ConditionValue.vue";

export default Vue.extend({
	props: {
		value: {
			type: Object as ()=>Condition,
			required: true
		},
		isFirst: Boolean,
		fields: {
			type: Object as ()=>PropSelectGroup,
			required: true
		},
	},
	computed: {
		selectedField():PropSelectItem | null {
			return this.fields.getPropSelectFromValue(this.value.Prop);
		},
		hasValue():boolean{
			if (this.fieldType == ConditionPropertyType.EMPTY) {
				return false;
			}
			return this.value.Operator != Operator.IS_EMPTY && this.value.Operator != Operator.IS_NOT_EMPTY;
		},
		fieldType():ConditionPropertyType{
			if (!this.selectedField){
				return ConditionPropertyType.EMPTY;
			}
			return Condition.convertModelFieldToConditionPropertyType(this.selectedField);
		},
		operatorMd():string{
			if (this.fieldType == ConditionPropertyType.BOOL) {
				return "7";
			}
			return "2";
		}
	},
	methods: {
		remove(){
			this.$emit("remove");
		}
	},
	components: {
		AppPropSelectAutocomplete,
		AppConditionOperatorSelect,
		AppConditionValue
	},
	i18nEx: {
		componentPrefix: "components.sale-layout-editor"
	}
});
