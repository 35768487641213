import { HumanFilter } from "@/utils/human-filter";
import { getModel } from "@/utils/models/model";
import { filterFieldPathToBrowseFieldPath } from "@/utils/models/utils";
import { Highlight } from "@/utils/views/highlight";
import { ViewColumn } from "@/utils/views/view-column";
import { ViewTotal } from "@/utils/views/view-total";

export class View {
	ID: number = 0;
	Table: string = "";
	Name: string = "";
	Columns: ViewColumn[] = [];
	Preloads: string[] = [];
	HumanFilters: HumanFilter[] = [];
	BrowseID: number = 0;
	DisplayOrder: number = 0;
	UserID: number = 0;
	MetaData: { [key: string]: any } = {};
	Totals: ViewTotal[] = [];
	Highlights:Highlight[] = [];

	constructor(data: any = null) {
		if (!data) return;
		this.ID = data.ID;
		this.Table = data.Table;
		this.Name = data.Name;
		if (data.Columns) {
			this.Columns = data.Columns.map((c: any) => new ViewColumn(c));
		}
		if (data.Preloads) {
			this.Preloads = data.Preloads;
		}
		if (data.HumanFilters) {
			this.HumanFilters = data.HumanFilters.map((f: any) => new HumanFilter(f));
		}
		this.BrowseID = data.BrowseID;
		this.DisplayOrder = data.DisplayOrder;
		this.UserID = data.UserID;
		this.MetaData = data.MetaData || {};
		this.Highlights = (data.Highlights || []).map((h:any)=>new Highlight(h));
		this.Totals = (data.Totals || []).map((t:any)=>new ViewTotal(t));
	}


	computePreloads() {
		let cols = this.Columns.map((c: ViewColumn) => {
			return c.Property.split(".");
		}).concat(this.HumanFilters.map((f: HumanFilter) => {
			return filterFieldPathToBrowseFieldPath(f.Field).split(".");
		}));
		let preloads = cols.filter((c) => {
			return c.length > 1;
		}).map((c: string[]) => {
			c.pop();
			return c.join(".");
		});
		let computedColumns = this.Columns.filter(c => c.IsComputed);
		if (computedColumns.length > 0) {
			let extraPreloads = computedColumns.map(c => c.ComputedPreloads).reduce((prev, current, i, arr) => {
				prev.push(...current);
				return prev;
			});
			preloads.push(...extraPreloads);
		}
		for (let highlight of this.Highlights){
			preloads.push(...highlight.getPreloads());
		}
		this.Preloads = preloads.filter(p=>p!="");
		this.Preloads = preloads.filter((p, i) => preloads.indexOf(p) == i);
	}

	getDefaultSettingsIndex(): number {
		if (this.MetaData == null) {
			return -1;
		}
		if (this.MetaData["default-view-index"] === null || this.MetaData["default-view-index"] == undefined) {
			return -1;
		}
		return parseInt(this.MetaData["default-view-index"]);
	}

	hasDefaultSettings(): boolean {
		return this.getDefaultSettingsIndex() != -1;
	}

	getJSON(): any {
		let model = getModel(this.Table);
		return {
			ID: this.ID,
			Table: this.Table,
			Name: this.Name,
			Columns: this.Columns,
			Preloads: this.Preloads,
			HumanFilters: this.HumanFilters.map(c => c.getProcessedJson(model)),
			BrowseID: this.BrowseID,
			DisplayOrder: this.DisplayOrder,
			UserID: this.UserID,
			MetaData: this.MetaData,
			Totals: this.Totals.map(t=>t.getJSON(model)),
			Highlights: this.Highlights.map(h=>h.getJSON())
		};
	}

	isValid():boolean {
		if (this.Name.trim() == "") return false;
		if (this.Columns.length == 0){
			return false;
		}
		return true;
	}
}
