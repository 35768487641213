export function roundTo8Decimals(num: number): number {
	return roundToDecimals(num, 8);
}

export function roundToDecimals(num:number, decimals:number):number{
	return parseFloat(num.toFixed(decimals));
}

export function roundPriceValues(num:number):number{
	return roundToDecimals(num, 2);
}

export function isBetween(num:number, num1:number, num2:number):boolean {
	if (num > num1 && num < num2) {
		return true;
	}
	return false;
}

export function isBetweenInclusive(num:number, num1:number, num2:number):boolean {
	if (num >= num1 && num <= num2) {
		return true;
	}
	return false;
}

export function isAboutEqual(num1:number, num2:number, maxDiff:number):boolean {
	return Math.abs(Math.abs(num1) - Math.abs(num2)) < maxDiff;
}

export function isAboutEqualPrice(num1:number, num2:number):boolean {
	return isAboutEqual(num1, num2, 0.01);
}

export function boolOrNumberToNumber(val:boolean | number):number {
	if (val == false) {
		return 0;
	}
	if (val == true){
		return 1;
	}
	return val as number;
}

export function pad(val:number, digits:number):string {
	let v = val.toString();
	for (let i = v.length; i<digits;i++){
		v = "0"+v;
	}
	return v;
}

export interface IRoundDecimalsAdvancedOptions {
	decimals:number;
	decimalSeperator:string;
	thousandsSeperator:string;
	thousandSeperatorInterval:number;
}

// eslint-disable-next-line max-lines-per-function
export function roundDecimalsAdvanced(number:number, options:IRoundDecimalsAdvancedOptions):string {
	number *= Math.pow(10, options.decimals);
	number = Math.round(number);
	number /= Math.pow(10, options.decimals);
	let wholeNumber = Math.floor(Math.abs(number));
	let wholeNumberString = "0";
	let passes = 0;
	if (options.thousandSeperatorInterval > 0) {
		while (wholeNumber >= 1) {
			wholeNumber /= Math.pow(10, options.thousandSeperatorInterval);
			let num = wholeNumber.toFixed(options.thousandSeperatorInterval).split(".")[1];
			if (passes == 0) {
				wholeNumberString = num;
			} else {
				wholeNumberString = num + options.thousandsSeperator + wholeNumberString;
			}
			wholeNumber = Math.floor(wholeNumber);
			passes++;
		}
	} else {
		wholeNumberString = wholeNumber.toFixed(0);
	}
	wholeNumberString = wholeNumberString.replaceAll(/^0+/g, "");
	if (wholeNumberString == "") {
		wholeNumberString = "0";
	}
	if (options.decimals == 0) {
		if (number < 0) {
			return "-" + wholeNumberString;
		}
		return wholeNumberString;
	}
	let decimalNumber = number - wholeNumber;
	let decimalNumberString = decimalNumber.toFixed(options.decimals);
	let decimalNumberStringRounded = decimalNumberString.split(".")[1];
	if (number < 0) {
		return wholeNumberString + options.decimalSeperator + decimalNumberStringRounded;
	}
	return "-" + wholeNumberString + options.decimalSeperator + decimalNumberStringRounded;
}