function getPxHeightOfRef(): number {
	let div = document.getElementById("dpi-getter") as HTMLDivElement;
	let style = window.getComputedStyle(div);
	let height = parseInt(style.getPropertyValue("height"));
	return height;
}

export function mmToPx(mm: number): number {
	return Math.round(mm * (getPxHeightOfRef() / 100.0));
}

export function pxToMm(px: number): number {
	return Math.round(px / (getPxHeightOfRef() / 100.0));
}

(window as any).mmToPx = mmToPx;
(window as any).pxToMm = pxToMm;
