
import Vue from "vue";
import { ICountry } from "../utils/country";

export default Vue.extend({
	data() {
		return {
			input: ""
		};
	},
	props: {
		label: {
			type: [String, Object]
		},
		value: {
			type: String
		},
		fancy: {
			type: Boolean
		},
		up: {
			type: Boolean
		}
	},
	computed: {
		countries(): ICountry[] {
			let lng = this.$i18n.locale;
			let lngs = require(`@/assets/json/countries/${lng}.json`) as ICountry[];
			return lngs;
		},
		matchedCountries(): ICountry[] {
			return this.countries.filter(c => c.name.toLowerCase().match(this.input.toLowerCase()) || c.code.match(this.input.toUpperCase()));
		},
		selectedCountry():ICountry | null{
			return this.countries.find(c=>c.code == this.value) || null;
		}
	},
	watch: {
		countries: {
			immediate: true,
			handler() {
				this.updateInput();
			}
		},
		value(value: string) {
			this.updateInput();
		}
	},
	methods: {
		countrySelected(country: ICountry) {
			this.$emit("input", country.code);
		},
		updateInput() {
			let country = this.countries.find(c => c.code == this.value);
			if (country) {
				this.input = country.name;
			}
		},
		onBlur(){
			if (this.selectedCountry){
				this.input = this.selectedCountry.name;
			}
		}
	}
});
