import { CustomTranslation } from "@/utils/custom-translation";
import { SaleConfig } from "./sale-config";

export class SaleConfigs {
	AutomaticallyDeleteImportedSales:boolean;
	AddNotToPrintToTotal:boolean = true;
	PdfCaching:boolean = false;
	PdfCachingByAction:boolean = false;
	UseQty2:boolean = false;
	ViesCheckExtraField:string = "";
	Qty2Label:string = "";
	ConvertToCreditNoteDescriptionTemplate:CustomTranslation = new CustomTranslation();
	ToShowStockQtyInProductSearch:number = 0;

	AddUpPriceRulesDiscount:boolean = false;
	Configs:{[key:string]:SaleConfig} = {};

	constructor(data:any) {
		for (let key of Object.keys(data.Configs || {})) {
			this.Configs[key] = new SaleConfig(data.Configs[key]);
		}
		this.AutomaticallyDeleteImportedSales = data.AutomaticallyDeleteImportedSales;
		this.PdfCaching = data.PdfCaching;
		this.PdfCachingByAction = data.PdfCachingByAction;
		this.UseQty2 = data.UseQty2;
		this.ViesCheckExtraField = data.ViesCheckExtraField || "";
		this.AddNotToPrintToTotal = data.AddNotToPrintToTotal == null ? true : data.AddNotToPrintToTotal;
		this.Qty2Label = data.Qty2Label;
		this.ConvertToCreditNoteDescriptionTemplate = new CustomTranslation(data.ConvertToCreditNoteDescriptionTemplate || {});
		this.ToShowStockQtyInProductSearch = data.ToShowStockQtyInProductSearch || 0;
		this.AddUpPriceRulesDiscount = data.AddUpPriceRulesDiscount;
	}

	getJSON():any {
		let configs:{[key:string]:any} = {};
		for (let key of Object.keys(this.Configs)) {
			configs[key] = this.Configs[key].getJSON();
		}
		return {
			Configs: configs,
			AutomaticallyDeleteImportedSales: this.AutomaticallyDeleteImportedSales,
			AddNotToPrintToTotal: this.AddNotToPrintToTotal,
			PdfCaching: this.PdfCaching,
			PdfCachingByAction: this.PdfCachingByAction,
			UseQty2: this.UseQty2,
			ViesCheckExtraField: this.ViesCheckExtraField,
			Qty2Label: this.Qty2Label.trim(),
			ConvertToCreditNoteDescriptionTemplate: this.ConvertToCreditNoteDescriptionTemplate.getJSON(),
			ToShowStockQtyInProductSearch: this.ToShowStockQtyInProductSearch || 0,
			AddUpPriceRulesDiscount: this.AddUpPriceRulesDiscount
		};
	}

	getConfig(saleTypeID:number):SaleConfig {
		let result = this.Configs[saleTypeID];
		if (!result) {
			throw new Error("Invalid type ID");
		}
		return result;
	}

	setConfig(saleTypeID:number, config:SaleConfig) {
		this.Configs[saleTypeID] = config;
	}
}