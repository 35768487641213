import { i18n } from "@/setup/i18n-setup";
import { CustomTranslation } from "@/utils/custom-translation";

export class ReminderTemplate {
	ID:number = 0;
	ReminderID: number = 0;
	Content: CustomTranslation = new CustomTranslation();
	Title: CustomTranslation = new CustomTranslation();
	Signature: CustomTranslation = new CustomTranslation();

	constructor(data?: any) {
		if (!data) {
			return;
		}
		this.ID = data.ID;
		this.ReminderID = data.ReminderID;
		this.Content = new CustomTranslation(data.Content);
		this.Title = new CustomTranslation(data.Title);
		this.Signature = new CustomTranslation(data.Signature);
	}

	getName():string {
		return i18n.t("models.ReminderTemplate.reminder", {num: this.ReminderID}).toString();
	}

	getJSON() {
		return {
			ID: this.ID,
			ReminderID: this.ReminderID,
			Content: this.Content.getJSON(),
			Title: this.Title.getJSON(),
			Signature: this.Signature.getJSON(),
		};
	}
}
