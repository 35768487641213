
import { BankType } from "@/models/base";
import { BankService } from "@/services/bank-service";
import { i18n } from "@/setup/i18n-setup";
import { isStringNullOrWhitespace } from "@/utils/strings";
import Vue from "vue";
import VueI18n from "vue-i18n";
import { ISelectItem } from "winfakt-vue-components";

export default Vue.extend({
	props: {
		value: {
			type: [Number, Object as ()=>BankType],
		},
		useIds: Boolean,
		fancy: Boolean,
		label: {
			type: [String, Object as ()=>VueI18n.TranslateResult],
			default: ():string=>{
				return i18n.t("components.bank.labels.account").toString();
			}
		},
		includeEmpty: Boolean,
		filterOnlyWithIban: Boolean,
		disabled: Boolean
	},
	computed: {
		bankTypeItems():ISelectItem<BankType | undefined>[]{
			let banks = BankService.getTypes();
			if (this.filterOnlyWithIban) {
				banks = banks.filter(b=>!isStringNullOrWhitespace(b.Iban) && !isStringNullOrWhitespace(b.Bic));
			}
			let result:ISelectItem<BankType | undefined>[] = banks.map(t => {
				let text = t.Name;
				if (t.Iban) {
					text = `${t.Name} - ${t.Iban}`;
				}
				return {
					text,
					value: t
				};
			});
			if (this.includeEmpty){
				result.unshift({
					text: this.$ct("common.empty"),
					value: undefined
				});
			}
			return result;
		},
		selectedValue():BankType | undefined {
			if (this.value == undefined){
				return undefined;
			}
			if (typeof(this.value) == "object") {
				return BankService.getTypes().find(b=>b.ID == (this.value as BankType).ID);
			}
			return BankService.getTypes().find(b => b.ID == this.value);
		}
	},
	methods: {
		onInput(event:BankType){
			if (!event) {
				return this.$emit("input", undefined);
			}
			if (this.useIds){
				return this.$emit("input", event.ID);
			}
			return this.$emit("input", new BankType(event.getJSON()));
		}
	},
	i18nEx: {
		componentPrefix: "components.bank"
	},
});
