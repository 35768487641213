
import { Contact } from "@/models/base";
import { ContactAddress } from "@/models/base";
import Vue from "vue";
import AppCreateContact from "./ContactEdit.vue";
import AppContactSearch from "./ContactSearch.vue";
import AppCustomerBrowse from "./CustomerSearchBrowse.vue";
import AppSupplierBrowse from "./SupplierSearchBrowse.vue";
import AppProspectBrowse from "./ProspectSearchBrowse.vue";
import { UserConfigService } from "../../services/user-config-service";
import { DossierConfigService } from "@/services/dossier-config-service";
import { AuthService } from "@/services/auth-service";
import { MODULE } from "@/config/modules";
import {BrowserInfo} from "@/utils/browser";

export default Vue.extend({
	data() {
		return {
			searchModal: false,
			createModal: false,
			newContact: new Contact(),
			selectedGroupId: 0,
			selectGroupModalOpen: false
		};
	},
	props: {
		groupId: {
			type: [Number, Array as () => number[]],
			required: true
		},
		value: {
			type: Object as () => Contact
		},
		label: {
			type: String
		},
		fancy: {
			type: Boolean
		},
		disableCreate: Boolean,
		disabled: Boolean,
		autoFocus: Boolean,
		topMargin: {
			type: Number,
			default: 0
		}
	},
	computed: {
		isMobile():boolean {
			return BrowserInfo.isMobile();
		},
		hasModuleProspecten(): boolean{
			return AuthService.hasModule(MODULE.PROSPECTEN);
		},
		searchButtonSize(): number {
			if (this.disableCreate) {
				return 12;
			}
			return 7;
		},
		canCreate(): boolean {
			return this.createableContactGroups.length > 0;
		},
		computedGroupIds(): number[] {
			if (typeof (this.groupId) == "object") {
				return this.groupId.filter(id => UserConfigService.hasCurrentUserPermission((p) => p.getPermissionsByBrowseID(id).CanRead));
			}
			return [this.groupId];
		},
		createableContactGroups(): number[] {
			return this.computedGroupIds.filter(id => UserConfigService.hasCurrentUserPermission((p) => p.getPermissionsByBrowseID(id).hasWritePermissions()));
		}
	},
	watch: {
		"newContact.ID"(id: number) {
			if (id != 0) {
				this.$emit("input", this.newContact);
				this.createModal = false;
			}
		},
	},
	methods: {
		focus() {
			(this.$refs.input as any).focus();
		},
		blur() {
			(this.$refs.input as any).blur();
		},
		onBlur() {
			this.$emit("blur");
		},
		onFocus() {
			this.$emit("focus");
		},
		onInput(contact: Contact) {
			this.$emit("input", contact);
		},
		openNewContactModal(groupId?: number) {
			if (groupId == undefined) {
				let groups = this.createableContactGroups;
				if (groups.length == 1) {
					groupId = groups[0];
				} else {
					this.selectGroupModalOpen = true;
					return;
				}
			}
			this.newContact = new Contact();
			this.newContact.MainAddress = new ContactAddress();
			this.newContact.MainAddress.IsMainAddress = true;
			this.newContact.Addresses = [this.newContact.MainAddress as ContactAddress];
			this.newContact.GroupID = groupId;
			if (this.newContact.ExtraFields){
				DossierConfigService.getExtraFieldNamesConfigs().getContactNamesByGroupID(groupId).setDefaultValues(this.newContact.ExtraFields);
			}
			this.createModal = true;
			this.selectGroupModalOpen = false;
		},
		openBrowseModal(groupId: number) {
			this.selectedGroupId = groupId;
			this.searchModal = true;
		},
		onBrowseItemSelected(item: Contact) {
			this.$emit("input", item);
			this.searchModal = false;
		},
		onUpdateGroupId(id:number){
			this.$emit("update-group",id);
		}
	},
	components: {
		AppContactSearch,
		AppCreateContact,
		AppCustomerBrowse,
		AppSupplierBrowse,
		AppProspectBrowse,
	},
	i18nEx: {
		componentPrefix: "components.contacts"
	}
});
