import { ScriptTableStructureColumns } from "./script-table-structure-columns";
import { IModel } from "../models/model-interface";
import { ModelNamedProperty } from "../models/named-property";
import { ScriptNamedProperty } from "./script-named-property";
import { NamedFilter } from "../models/named-filter";

export class ScriptTableStructure implements IModel {
	columns:ScriptTableStructureColumns[] = [];

	constructor(data?:any){
		if (!data) return;
		this.columns = data.columns.map((c:any)=>new ScriptTableStructureColumns(c));
	}

	public getName(){
		return "script";
	}


	getFilterPrefix(): string {
		return "";
	}

	getField(browseField: string, depth: number = 0): ScriptNamedProperty | undefined {
		if (!browseField) return undefined;
		browseField = browseField.replaceAll(".", "_");
		let res = this.columns.find(c=>c.name == browseField);
		if (res){
			console.log("Found");
			return new ScriptNamedProperty(browseField, res.toModelProperty(), "");
		}
		return undefined;
	}

	getFilterField(filterField: string): ModelNamedProperty | undefined {
		return this.getField(filterField.split(".")[1]);
	}

	getIgnoredFields(): string[] {
		return [];
	}

	getIgnoredFilterFields(): string[] {
		return [];
	}

	getTopLevelFields(): ModelNamedProperty[] {
		return this.columns.map(c=>new ScriptNamedProperty(c.name, c.toModelProperty(), ""));
	}

	browseFieldPathToFilterFieldPath(path: string): string {
		return path;
	}

	filterFieldPathToBrowseFieldPath(path: string): string {
		return path;
	}

	getFilterFields(): ModelNamedProperty[] {
		return this.getTopLevelFields();
	}

	getAvailableNamedFilters(): NamedFilter[] {
		return [];
	}
}