
import Vue from "vue";
import {AWSFile} from "@/models/aws-file";
export default Vue.extend({
	data(){
		return {
			systemFileUrl: ""
		};
	},
	props: {
		file: AWSFile,
		systemFile: File
	},
	computed: {
		src():string{
			if (this.file) return this.file.getUrl();
			return this.systemFileUrl || "";
		},
		imageExtensions():string[] {
			return [
				"jpeg",
				"jpg",
				"png",
				"gif",
				"bmp",
			];
		},
		imageContentTypes():string[]{
			return this.imageExtensions.map(ext=>`image/${ext}`);
		},
		fileType():string {
			if (this.file) {
				return this.file.ContentType;
			}
			if (this.systemFile) {
				if (this.systemFile.name.endsWith(".pdf")){
					return "application/pdf";
				}
				for (let ext of this.imageExtensions){
					if (this.systemFile.name.endsWith(`.${ext}`)){
						return `image/${ext}`;
					}
				}
			}
			return "application/pdf";
		},
		isImageType():boolean {
			return this.imageContentTypes.indexOf(this.fileType) != -1;
		}
	},
	watch: {
		systemFile: {
			immediate: true,
			handler(){
				if (this.systemFileUrl) {
					URL.revokeObjectURL(this.systemFileUrl);
				}
				if (this.systemFile) {
					this.systemFileUrl = URL.createObjectURL(this.systemFile);
				}
			}
		}
	},
	methods: {
		async close(){
			this.$emit("close");
		},
	},
	destroyed(){
		if (this.systemFileUrl) {
			URL.revokeObjectURL(this.systemFileUrl);
		}
	},
	i18nEx: {
		componentPrefix: "components.aws-documents"
	}
});
