
import { AuthService } from "@/services/auth-service";
import Vue from "vue";
import { tokenStore } from "../utils/token-store";

export default Vue.extend({
	data() {
		return {
			selectedDossier: tokenStore.selectedDossier,
			dossiers: tokenStore.accessTokens
		};
	},
	methods: {
		cancel() {
			this.$emit("close");
		},
		selectDossier(name:string) {
			AuthService.selectDossier(name);
			this.$emit("close");
			this.$router.push("/");
		}
	},
	i18nEx: {
		componentPrefix: "components.SelectDossier"
	}
});
