
import Vue from "vue";
export default Vue.extend({
	data(){
		return {
			start: "",
			end: ""
		};
	},
	props: {
		value: {
			type: String,
			required: true
		},
		label: String
	},
	watch: {
		start(){
			this.setValues();
		},
		end(){
			this.setValues();
		}
	},
	methods: {
		setValues(){
			this.$emit("input", JSON.stringify({start: this.start, end: this.end}));
		}
	},
	created(){
		this.setValues();
	},
	i18nEx: {
		componentPrefix: "components.reportengine"
	}
});
