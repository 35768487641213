import { TranslateResult } from "vue-i18n";
import { IModelPropertyClickAction } from "./model-field";
import { i18n } from "@/setup/i18n-setup";
import { ModalService } from "@/services";
import { AwsDocument } from "@/models/base/aws-document";

export function getParentOfProp(target:any, prop:PropertyKey):any{
	let parts = (prop as string).split(".");
	parts.pop();
	for (let part of parts) {
		if (!target) return;
		target = (target as any)[part] as any;
	}
	return target;
}

export const imageClickAction:IModelPropertyClickAction = {
	getName():TranslateResult{
		return i18n.t("common.open-image");
	},
	handler(target:any, prop:PropertyKey, options?:any):void{
		let parts = (prop as string).split(".");
		for (let part of parts) {
			if (!target) return;
			target = (target as any)[part] as any;
		}
		if (options && options.openInNewTab){
			window.open(target.getFileUrl(), "_blank");
			return;
		}
		ModalService.openImageUrl(target);
	},
	optionsComponent: "AppImageClickActionOptionsEdit"
};

export const extraFieldEditClickAction:IModelPropertyClickAction = {
	getName():TranslateResult{
		return i18n.t("common.edit-extra-field");
	},
	handler(target:any, prop:PropertyKey):string{
		return `edit-extra-field/${prop as string}`;
	},
	canBeUsed: (browse, path)=>{
		console.log(path);
		return path.startsWith("ExtraFields.");
	}
};


export function getOpenAwsDocumentsFunc(getDocumentsFunction:(value:any)=>Promise<AwsDocument[]>):IModelPropertyClickAction{
	return {
		getName():TranslateResult{
			return i18n.t("common.open-aws-documents-modal");
		},
		handler(target:any, prop:PropertyKey){
			target = getParentOfProp(target, prop);
			getDocumentsFunction(target).then((docs:AwsDocument[])=>{
				ModalService.openAwsDocuments(docs);
			});
		}
	};
}