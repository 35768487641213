import { getFileUrl } from "@/utils/url";

export class ChatMessage {
	ID: number = 0;
	ConversationID: number = 0;
	Message: string = "";
	UserID: number = 0;
	ThreadID: number = 0;
	FileURL: string = "";
	FileType: string = "";
	FileName: string = "";
	TimeStamp: Date = new Date();

	constructor(data?: any) {
		if (!data) return;
		this.ID = data.ID;
		this.Message = data.Message;
		this.UserID = data.UserID;
		this.ThreadID = data.ThreadID;
		this.FileURL = data.FileURL;
		this.FileType = data.FileType;
		this.FileName = data.FileName;
		this.TimeStamp = new Date(data.TimeStamp * 1000);

	}

	getFileUrl():string {
		return getFileUrl(this.FileURL);
	}
}