export class GridItem {
	row:number = 0;
	col:number = 0;

	getMinHeight():number{
		return 1;
	};

	getMinWidth():number{
		return 1;
	}

	width:number = this.getMinWidth();
	height:number = this.getMinHeight();

	tempRow:number = 0;
	tempCol:number = 0;
	tempWidth:number = 0;
	tempHeight:number = 0;
	visible:boolean = false
	singleColPosition:number = 0;
}