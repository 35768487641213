
import AppProductSkuAutocomplete from "@/components/products/ProductSkuAutocompleteWithoutModal.vue";
import { Product } from "@/models/base";
import { ProductService } from "@/services/product-service";
import Vue from "vue";
import AppEditProduct from "./ProductEditCard.vue";
import { UserConfigService } from "../../services/user-config-service";
import { HttpError } from "@/utils/services/http-error";
import { DossierConfigService } from "@/services/dossier-config-service";

export default Vue.extend({
	data() {
		return {
			toCreateProduct: null as null | Product,
			text: "",
			dontTriggerBlur: false
		};
	},
	props: {
		value: Object as () => Product,
		createModalIfNotExist: Boolean,
		label: [Object, String],
		autoFocus: Boolean,
		disabled: Boolean,
		includeDisabled: Boolean,
		allowMultiple: Boolean,
		removeOnEmpty: Boolean,
		showStockLocation: Number
	},
	computed: {
		isOpened():boolean {
			let el = this.$refs.productSku as any;
			if (!el) return false;
			return el.isOpened;
		}
	},
	methods: {
		focus() {
			(this.$refs.productSku as any).focus();
			this.dontTriggerBlur = false;
		},
		multipleProductsSelected(products:Product[]){
			this.dontTriggerBlur = true;
			this.$emit("inputs", products);
		},
		productSelected(product: Product) {
			this.dontTriggerBlur = true;
			this.$emit("input", product);
		},
		async onBlur() {
			if (this.dontTriggerBlur) {
				this.dontTriggerBlur = false;
				return;
			}
			if ((this.text || "").match(/^\s*$/)) {
				await new Promise(resolve => {
					setTimeout(resolve, 500);
				});
				if ((this.text || "").match(/^\s*$/)) return;
			}
			try {
				let product = await ProductService.findBySku(this.text);
				this.$emit("input", product);
			} catch (e:any) {
				if (!this.createModalIfNotExist) return;
				let err = e as HttpError;
				if (err.key == "ELEMENT_NOT_FOUND") {
					if (!UserConfigService.hasCurrentUserPermission((p)=>p.ProductPermissions.hasWritePermissions())){
						return;
					}
					err.dontShow = true;
					let builder = this.$confirmBuilder(this.$ct("product-not-exists-create"));
					builder.setTitle(this.$t("common.info"));
					let result = await builder.show();
					if (result) {
						this.toCreateProduct = new Product();
						this.toCreateProduct.Sku = this.text;
						if (this.toCreateProduct.ExtraFields){
							DossierConfigService.getExtraFieldNamesConfigs().ProspectNames.setDefaultValues(this.toCreateProduct.ExtraFields);
						}
					}
				}
			}
		},
		cancelCreateProduct() {
			this.toCreateProduct = null;
		},
		async finishCreateProduct(product: Product) {
			product = await ProductService.getProduct(product.ID);
			this.$emit("input", product);
			this.toCreateProduct = null;
		},
		onKeyUp(event:KeyboardEvent) {
			this.dontTriggerBlur = false;
			this.$emit("keyup", event);
		},
		clear() {
			(this.$refs.productSku as any).clear();
		},
		onText(event:string){
			this.text = event;
		},
	},
	components: {
		AppEditProduct,
		AppProductSkuAutocomplete
	},
	i18nEx: {
		componentPrefix: "components.products"
	}
});
