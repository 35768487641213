import { UblDossierConfig } from "@/plugins/ubl/models/ubl-dossier-config";

export class PluginConfig {
	UBL:UblDossierConfig;

	constructor(data:any) {
		this.UBL = new UblDossierConfig(data.UBL);
	}

	getJSON() {
		return {
			UBL: this.UBL.getJSON()
		};
	}
}