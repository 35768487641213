import { EventEmitter } from "@/utils/event-emitter";
import axios, { AxiosInstance, AxiosRequestConfig, InternalAxiosRequestConfig } from "axios";

export function getAuthenticatedHeaders(): any {
	return {
		Authorization: "Bearer " +  GoogleService.getAccessToken()
	};
}


async function authenticate(value:AxiosRequestConfig) {
	await GoogleService.refreshTokensIfRequired();
	let authHeaders = getAuthenticatedHeaders();
	if (!value.headers) {
		value.headers = {};
	}
	value.headers.Authorization = authHeaders.Authorization;
}

function addRequestInterceptors(client:AxiosInstance){
	client.interceptors.request.use(async (value: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> => {
		await authenticate(value);
		if (!value.params) return value;
		for (let key of Object.keys(value.params)) {
			let t = typeof (value.params[key]);
			if (t == "object") {
				value.params[key] = JSON.stringify(value.params[key]);
			}
		}
		return value;
	});
}

export class GoogleServiceClass extends EventEmitter{

	public constructor(){
		super();
		this.createAxiosClient();
	}

	private get tokenResponse():null | google.accounts.oauth2.TokenResponse{
		let result = localStorage.getItem("google-service-token-response");
		if (!result) {
			this._isLoggedIn = false;
			return null;
		}
		this._isLoggedIn = true;
		return JSON.parse(result);
	}

	private set tokenResponse(val:null | google.accounts.oauth2.TokenResponse){
		if (!val) {
			localStorage.removeItem("google-service-token-response");
			this.tokenExpiresAt = null;
			this._isLoggedIn = false;
			return;
		}
		localStorage.setItem("google-service-token-response", JSON.stringify(val));
		this._isLoggedIn = true;
	}

	private get tokenExpiresAt():Date | null {
		let result = localStorage.getItem("google-service-token-response-expires-at");
		if (!result) return null;
		return new Date(result);
	}

	private set tokenExpiresAt(val:null | Date) {
		if (!val){
			localStorage.removeItem("google-service-token-response-expires-at");
			return;
		}
		localStorage.setItem("google-service-token-response-expires-at", val.toJSON());
	}


	private _isLoggedIn:boolean = this.tokenResponse ? true : false;
	private client:null | google.accounts.oauth2.CodeClient = null;
	private tokenClient:null | google.accounts.oauth2.TokenClient = null;
	private readonly CLIENT_ID = "193257751617-ldaa1cuhr7jv9ikv6hlmjlj4dsmikcjm.apps.googleusercontent.com"
	private axiosClient:AxiosInstance = axios;
	public get axios():AxiosInstance{
		return this.axiosClient;
	}

	public getAccessToken():string{
		if (!this.tokenResponse) {
			return "";
		}
		return this.tokenResponse.access_token;
	}


	public get isLoggedIn():boolean {
		return this._isLoggedIn;
	}

	public get loaded():boolean {
		return this.client != null;
	}

	async login():Promise<string> {
		if (!this.client) return "";
		this.client.requestCode();
		return new Promise<string>((resolve, reject)=>{
			this.once("login-result", (code:string, err:Error)=>{
				if (err){
					reject(err);
					return;
				}
				resolve(code);
			});
		});
	}

	async loginToken():Promise<void>{
		if (!this.tokenClient) return;
		this.tokenClient.requestAccessToken();
		return new Promise((resolve, reject)=>{
			this.once("token-login-result", async (token:google.accounts.oauth2.TokenResponse, err:Error)=>{
				if (err){
					reject(err);
					return;
				}
				this.tokenResponse = token;
				let now = new Date();
				now.setSeconds(now.getSeconds()+(token.expires_in as any as number));
				this.tokenExpiresAt = now;
				resolve();
			});
		});
	}


	logout() {
		if (!this.tokenResponse) return;
		google.accounts.oauth2.revoke(this.tokenResponse.access_token, ()=>{});
		this.tokenResponse = null;
		this.emit("auth");
	}

	public async refreshTokensIfRequired(){
		let expires = this.tokenExpiresAt;
		if (!expires) return;
		let now = new Date();
		now.setSeconds(60);
		let shouldRefresh = expires.getTime() < now.getTime();
		if (!shouldRefresh) return;
		await this.loginToken();
	}

	private createAxiosClient(){
		this.axiosClient = axios.create();
		addRequestInterceptors(this.axiosClient);
	}

	async initClient() {
		let ref = this;
		this.client = google.accounts.oauth2.initCodeClient({
			client_id: this.CLIENT_ID,
			scope: [
				"email",
				"profile",
				"https://www.googleapis.com/auth/gmail.send"
			].join(" "),
			ux_mode: "popup",
			callback(response:google.accounts.oauth2.CodeResponse){
				ref.emit("login-result", response.code, null);
				ref.emit("auth");
			},
			error_callback(error:google.accounts.oauth2.ClientConfigError){
				ref.emit("login-result", null, new Error(error.message));
			}
		});

		this.tokenClient = google.accounts.oauth2.initTokenClient({
			client_id: this.CLIENT_ID,
			scope: [
				"email",
				"profile",
				"https://www.googleapis.com/auth/calendar",
			].join(" "),
			callback(response:google.accounts.oauth2.TokenResponse){
				console.log("Token response");
				ref.emit("token-login-result", response, null);
				ref.emit("auth");
			},
			error_callback(error:google.accounts.oauth2.ClientConfigError){
				ref.emit("token-login-result", null, new Error(error.message));
			}
		});

	}
};

export const GoogleService = new GoogleServiceClass();