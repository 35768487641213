
import { DossierConfigService } from "@/services/dossier-config-service";
import { SellCategory } from "@/utils/sell-category";
import Vue from "vue";
import { TranslateResult } from "vue-i18n";
import { ISelectItem } from "winfakt-vue-components";
export default Vue.extend({
	props: {
		value: {
			type: [Array as () => number[]]
		},
		label: [String, Object as () => TranslateResult],
		fancy: Boolean
	},
	computed: {
		sellCategories():SellCategory[] {
			return DossierConfigService.getSellCategories().getCategories();
		},
		items():ISelectItem<number>[] {
			return this.sellCategories.filter(c => c.Active).map((c, i) => {
				return {
					text: c.Name || this.$ct("price-cat", {i: i+1}),
					value: this.sellCategories.indexOf(c) + 1
				} as ISelectItem<number>;
			});
		},
	},
	methods: {
		onInput(value:SellCategory) {
			this.$emit("input", value);
		}
	},
	i18nEx: {
		componentPrefix: "components.SellPriceCategorySelect"
	}
});
