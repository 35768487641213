export class LastOpenedItem{
	private readonly key:string;
	private readonly onSet:(value:any)=>any;

	public constructor(key:string, onSet:(value:any)=>any){
		this.key = `last-opened/${key}`;
		this.onSet = onSet;
	}

	public hasStoredItem():boolean {
		return sessionStorage.getItem(this.key) != null;
	}

	public getLastOpenedItem():any{
		return JSON.parse(sessionStorage.getItem(this.key) || "{}");
	}

	public setLastOpenedItem(item:any){
		item = this.onSet(item);
		sessionStorage.setItem(this.key, JSON.stringify(item));
	}
}
