
import { SaleType } from "@/models/base";
import { SaleService } from "@/services/sale-service";
import Vue from "vue";
import { ISelectItem } from "winfakt-vue-components";
export default Vue.extend({
	data() {
		return {
			mode: "number" as "number" | "object",
			saleTypes: [] as SaleType[],
			selectedValue: 1
		};
	},
	props: {
		value: [Number, Object as () => SaleType],
		fancy: Boolean,
		light: Boolean,
		label: String,
		useObject: Boolean,
		useId: Boolean,
		selectWidth: String,
		ignoredTypeIds: {
			type: Array as () => number[],
			default: () => []
		},
		contactGroupId: Number,
		usePurchaseAndSale: Boolean,
	},
	computed: {
		items():ISelectItem<number>[] {
			return this.saleTypes.filter(t => this.ignoredTypeIds.indexOf(t.ID) == -1).map((t) => {
				let text = t.Name.getTranslation(this.$i18n.locale);
				if (this.usePurchaseAndSale){
					let name = this.$ct("client");
					if (t.ContactGroupID == 2) {
						name = this.$ct("purchase");
					}else if (t.ContactGroupID == 3) {
						name = this.$ct("prospect");
					}
					text = `${text} (${name})`;
				}
				return {
					text,
					value: t.ID
				} as ISelectItem<number>;
			});
		},
		selectedSaleType():SaleType | undefined {
			return this.saleTypes.find(s => s.ID == this.selectedValue);
		},
	},
	watch: {
		value: {
			immediate: true,
			handler(value:number | SaleType) {
				if (this.useObject || this.useId) {
					if (this.saleTypes.length == 0) {
						return;
					}
					if (typeof(value) != this.mode) {
						if (this.mode == "number") {
							let val = value as SaleType;
							this.$emit("input", val.ID);
						} else {
							let val = value as number;
							let type = this.saleTypes.find(s => s.ID == val);
							if (!type) return;
							this.$emit("input", type);
						}
					} else if (typeof(value) == "number") {
						this.selectedValue = value;
					} else {
						this.selectedValue = value.ID;
					}
					return;
				}
				if (typeof(value) == "number") {
					this.mode = "number";
					this.selectedValue = value;
				} else {
					this.mode = "object";
					this.selectedValue = value.ID;
				}
			}
		},
		useObject: {
			immediate: true,
			handler(val:boolean) {
				if (val) {
					this.mode = "object";
				}
			}
		},
		useId: {
			immediate: true,
			handler(val:boolean) {
				if (val) {
					this.mode = "number";
				}
			}
		},
		saleTypes(items:SaleType[]) {
			if (!this.value) {
				if (this.mode == "number") {
					this.$emit("input", items[0].ID);
				} else {
					this.$emit("input", items[0]);
				}
			} else if (typeof(this.value) == "number") {
				this.selectedValue = this.value;
			} else {
				this.selectedValue = this.value.ID;
			}
		},
		contactGroupId: {
			immediate: true,
			handler(){
				if (this.usePurchaseAndSale){
					this.saleTypes = SaleService.getAllTypes();
				}else{
					this.saleTypes = SaleService.getTypes(this.contactGroupId);
				}
			}
		}
	},
	methods: {
		onInput(event:number) {
			this.selectedValue = event;
			if (!this.selectedSaleType) return;
			if (this.mode == "number") {
				this.$emit("input", event);
			} else {
				if (!this.selectedSaleType) return;
				this.$emit("input", this.selectedSaleType);
			}
		}
	},
	i18nEx: {
		componentPrefix: "components.sales"
	}

});
