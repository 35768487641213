import { SummaryRow } from "./summary-row";

export class Summary {
	CreateSummary:boolean = false;
	Rows:SummaryRow[] = [];

	public constructor(data?:any){
		if (!data) return;
		this.CreateSummary = data.CreateSummary;
		this.Rows = (data.Rows as any[] || []).map(d=>new SummaryRow(d));
	}

	public getJSON(){
		return {
			CreateSummary: this.CreateSummary,
			Rows: this.Rows.map(r=>r.getJSON())
		};
	}

	public getColCount():number {
		let max = 0;
		for (let row of this.Rows){
			let result = row.getColCount();
			if (result > max){
				max = result;
			}
		}
		return max;
	}

	public getRowCount():number {
		return this.Rows.length;
	}
}