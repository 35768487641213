import { ILayoutImage } from "../layout-editor";
import { SaleLayoutLogoPosition } from "./logo";

export class SignatureSettings implements ILayoutImage {
	Width:number = 50;
	Height:number = 15;
	X:number = 20;
	Y:number = 20;
	Show:boolean = false;
	Placement: SaleLayoutLogoPosition = "Footer";

	public constructor(data?:any){
		if (!data) return;
		this.Width = data.Width;
		this.Height = data.Height;
		this.X = data.X;
		this.Y = data.Y;
		this.Show = data.Show || false;
		this.Placement = data.Placement;
		if (this.Width < 5){
			this.Width = 5;
		}
		if (this.Height < 5){
			this.Height = 5;
		}
	}

	getImageUrl(): string {
		return "/img/signature.png";
	}

	public getJSON(){
		return {
			Width: Math.round(this.Width),
			Height: Math.round(this.Height),
			X: Math.round(this.X),
			Y: Math.round(this.Y),
			Show: this.Show || false,
			Placement: this.Placement
		};
	}
}