import { TextAlignment, TextStyledProperty } from "../documents/text-styled-property";

export enum PageNumberLocation {
	HIDDEN = 0,
	BOTTOM = 1,
	TOP = 2
}

export class PageNumberSettings {
	public Location:PageNumberLocation = PageNumberLocation.BOTTOM;
	public Style:TextStyledProperty = new TextStyledProperty();
	public AlwaysShow: boolean = false

	public constructor(data?:any){
		if (!data){
			this.Style.TextAlign = TextAlignment.RIGHT;
			return;
		};
		this.Location = data.Location;
		this.AlwaysShow = data.AlwaysShow || false;
		this.Style = new TextStyledProperty(data.Style);
	}

	public getJSON(){
		return {
			Location: this.Location,
			AlwaysShow: this.AlwaysShow,
			Style: this.Style.getJSON(),
		};
	}
}