
import { SaleType } from "@/models/base";
import { SaleService } from "@/services/sale-service";
import Vue from "vue";
import { ISelectItem } from "winfakt-vue-components";
export default Vue.extend({
	data() {
		return {
			mode: "number" as "number" | "object",
			saleTypes: [] as SaleType[],
			selectedValues: [] as number[],
			isSyncing: false
		};
	},
	props: {
		value: Array as ()=>(SaleType | number)[],
		fancy: Boolean,
		light: Boolean,
		label: String,
		useObject: Boolean,
		useId: Boolean,
		selectWidth: String,
		ignoredTypeIds: {
			type: Array as () => number[],
			default: () => []
		},
		contactGroupId: Number,
		usePurchaseAndSale: Boolean,
	},
	computed: {
		items():ISelectItem<number>[] {
			return this.saleTypes.filter(t => this.ignoredTypeIds.indexOf(t.ID) == -1).map((t) => {
				let text = t.Name.getTranslation(this.$i18n.locale);
				if (this.usePurchaseAndSale){
					let name = this.$ct("client");
					if (t.ContactGroupID == 2) {
						name = this.$ct("purchase");
					}else if (t.ContactGroupID == 3) {
						name = this.$ct("prospect");
					}
					text = `${text} (${name})`;
				}
				return {
					text,
					value: t.ID
				} as ISelectItem<number>;
			});
		},
	},
	watch: {
		value: {
			immediate: true,
			handler() {
				if (this.isSyncing) return;
				this.readValues();
			}
		},
		useObject: {
			immediate: true,
			handler(val:boolean) {
				if (val) {
					this.mode = "object";
				}
			}
		},
		useId: {
			immediate: true,
			handler(val:boolean) {
				if (val) {
					this.mode = "number";
				}
			}
		},
		selectedValues: {
			deep: true,
			handler(){
				if (this.isSyncing) return;
				this.writeValues();
			}
		}
	},
	methods: {
		readValues(){
			this.isSyncing = true;
			this.selectedValues = this.value.map(v=>{
				if (typeof(v) == "number"){
					return v;
				}
				return v.ID;
			});
			this.$nextTick(()=>{
				this.isSyncing = false;
			});
		},
		writeValues(){
			this.isSyncing = true;
			this.writeValuesRemove();
			this.writeValuesAdd();
			this.$nextTick(()=>{
				this.isSyncing = false;
			});
		},
		writeValuesRemove(){
			for (let i = this.value.length -1; i >= 0; i--) {
				let id = 0;
				let val = this.value[i];
				if (typeof(val) == "number") {
					id = val;
				}else{
					id = val.ID;
				}
				if (this.selectedValues.indexOf(id) == -1) {
					this.value.splice(i,1);
				}
			}
		},
		writeValuesAdd(){
			for (let id of this.selectedValues) {
				let found = false;
				for (let val of this.value) {
					if (typeof(val) == "number") {
						if (val == id) {
							found = true;
							break;
						}
					}else if (val.ID == id){
						found = true;
						break;
					}
				}
				if (!found) {
					if (this.mode == "number") {
						this.value.push(id);
					}else{
						let t =this.saleTypes.find(s=>s.ID == id);
						if (t) {
							this.value.push(t);
						}
					}
				}
			}
		}

	},
	mounted() {
		if (this.usePurchaseAndSale){
			this.saleTypes = SaleService.getAllTypes();
		}else{
			this.saleTypes = SaleService.getTypes(this.contactGroupId);
		}
		this.readValues();
	},
	i18nEx: {
		componentPrefix: "components.sales"
	}
});
