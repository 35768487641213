export class ChatSettings {
	LastVisitedPath = "";
	BubbleStyle = true;

	constructor(data?: any) {
		if (!data) return;
		this.LastVisitedPath = data.LastVisitedPath;
		this.BubbleStyle = data.BubbleStyle;
	}

	getJSON() {
		return {
			LastVisitedPath: this.LastVisitedPath,
			BubbleStyle: this.BubbleStyle
		};
	}
}