import { Condition } from "../condition/condition";
import { CustomTranslation } from "../custom-translation";
import { TextStyledProperty } from "./text-styled-property";

export class Field {
	Text:CustomTranslation = new CustomTranslation();
	TextAfter:CustomTranslation = new CustomTranslation();
	Prop:string = "";
	Style:TextStyledProperty = new TextStyledProperty();
	Conditions:Condition[] = [];

	public constructor(data?:any){
		if (!data) return;
		this.Text = new CustomTranslation(data.Text);
		this.TextAfter = new CustomTranslation(data.TextAfter);
		this.Prop = data.Prop;
		this.Style = new TextStyledProperty(data.Style);
		this.Conditions = (data.Conditions || []).map((c:any)=>new Condition(c));
	}

	public getJSON():any{
		return {
			Text: this.Text.getJSON(),
			TextAfter: this.TextAfter.getJSON(),
			Prop: this.Prop,
			Style: this.Style.getJSON(),
			Conditions: this.Conditions.map(c=>c.getJSON())
		};
	}
}