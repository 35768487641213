import { TextStyledProperty } from "../documents/text-styled-property";

export enum RequirementsLocation {
	NONE = 0,
	AFTER_TOTALS = 1,
	NEW_PAGE = 2
}

export class SaleRequiremenets {
	public Style:TextStyledProperty = new TextStyledProperty();
	public Location:RequirementsLocation = RequirementsLocation.NONE;

	public constructor(data?:any){
		if (!data) return;
		this.Style = new TextStyledProperty(data.Style);
		this.Location = data.Location;
	}

	public getEditorStyle():any{
		let style = this.Style.getStyle();
		if (this.Location == RequirementsLocation.NEW_PAGE) {
			style.marginBottom = 0;
			style.marginTop = 0;
			style.marginLeft = 0;
			style.marginRight = 0;
			style.flexGrow = 1;
		}
		return style;
	}

	public getJSON(){
		return {
			Style: this.Style.getJSON(),
			Location: this.Location,
		};
	}

}