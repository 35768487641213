import { BrowserInfo } from "@/utils/browser";
import Axios from "axios";
const host = "http://localhost:" + (BrowserInfo.getElectronPort() || 0);

export interface ICashdroConfiguration {
	host:string;
	name:string;
	password:string;
}

export interface CashDroResponse{
	code: number,
	data: string
}

class CashDroClass {

	saveConfiguration(config: ICashdroConfiguration | null){

		if(!config)
			return;

		localStorage.setItem("electron-cashdro-host", config.host);
		localStorage.setItem("electron-cashdro-name", config.name);
		localStorage.setItem("electron-cashdro-password", config.password);
	}

	getConfiguration():ICashdroConfiguration{
		return new Object({
			host: localStorage.getItem(`electron-cashdro-host`) || "",
			name: localStorage.getItem(`electron-cashdro-name`) || "",
			password: localStorage.getItem(`electron-cashdro-password`) || ""
		}) as ICashdroConfiguration;
	}

	async start(amount:number):Promise<CashDroResponse>{
		try {
			let c = this.getConfiguration() as any;
			c.amount = amount;
			let res = await Axios.get(`${host}/payment/start`,{params: c});
			return res.data as CashDroResponse;
		}catch (err) {
			return {
				code: 0,
				data: "string"
			} as CashDroResponse;
		}
	}

	async acknowledge(operation:string):Promise<CashDroResponse>{
		try {
			let c = this.getConfiguration() as any;
			c.operation = operation;
			let res = await Axios.get(`${host}/payment/acknowledge`,{params: c});
			return res.data as CashDroResponse;
		}catch (err) {
			return {
				code: 0,
				data: "string"
			} as CashDroResponse;
		}
	}

	async ask(operation:string):Promise<CashDroResponse>{
		try {
			let c = this.getConfiguration() as any;
			c.operation = operation;
			let res = await Axios.get(`${host}/payment/ask`,{params: c});
			return res.data as CashDroResponse;
		}catch (err) {
			return {
				code: 0,
				data: "string"
			} as CashDroResponse;
		}
	}

	async cancel(operation:string):Promise<[]>{
		let c = this.getConfiguration() as any;
		c.operation = operation;
		await Axios.get(`${host}/payment/cancel`,{params: c});
		return [];
	}

	async test():Promise<CashDroResponse>{
		try {
			let c = this.getConfiguration() as any;
			let res = await Axios.get(`${host}/payment/test`, {timeout: 3000, params: c});
			return res.data as CashDroResponse;
		}catch (err) {
			return {
				code: 0,
				data: "string"
			} as CashDroResponse;
		}
	}

}

export const CashDro = new CashDroClass();
(window as any).cashdrow = CashDro;