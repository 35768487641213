export class EmailTemplateInfo {
	public ID: number=0;
	public Name: string="";
	public IsHTML: boolean=true;
	public Subject: string="";
	public DisplayOrder:number = 0;

	constructor(data: any) {
		if (!data) return;
		this.ID = data.ID;
		this.Name = data.Name;
		this.IsHTML = data.IsHTML;
		this.Subject = data.Subject;
		this.DisplayOrder = data.DisplayOrder;
	}

	clone():EmailTemplateInfo{
		return new EmailTemplateInfo(this);
	}
}
