import Vue, { VueConstructor } from "vue";
import { TranslateResult } from "vue-i18n";
import { ConfirmResult } from "winfakt-vue-components";

type text = string | TranslateResult;

class ConfirmBuilder {
	instance: Vue;
	yesText: text;
	noText: text;
	cancelText: text | undefined;
	message: text;
	title?: text;
	class?: text;

	constructor(instance: Vue, message: text = "") {
		this.instance = instance;
		this.yesText = instance.$t("common.yes");
		this.noText = instance.$t("common.no");
		this.message = message;
	}

	setTitle(title: text): ConfirmBuilder {
		this.title = title;
		return this;
	}

	setClass(className: text): ConfirmBuilder {
		this.class = className;
		return this;
	}

	setWarningTitle(): ConfirmBuilder {
		this.title = this.instance.$t("common.warning");
		this.class = "warning";
		return this;
	}

	setMessage(message: text): ConfirmBuilder {
		this.message = message;
		return this;
	}

	setYesText(yesText: text): ConfirmBuilder {
		this.yesText = yesText;
		return this;
	}

	setNoText(noText: text): ConfirmBuilder {
		this.noText = noText;
		return this;
	}

	setCancelText(cancelText: text | undefined):ConfirmBuilder{
		this.cancelText = cancelText;
		return this;
	}

	async show(): Promise<ConfirmResult> {
		let result = await this.instance.$wf.confirm(this.message, this.yesText, this.noText, this.title, this.class, this.cancelText);
		return result;
	}
}

export function createConfirmBuilder(this: Vue, message: text = ""): ConfirmBuilder {
	return new ConfirmBuilder(this, message);
}

export const confirmBuilderEx = {
	install(vue: VueConstructor, options: any) {
		vue.prototype.$confirmBuilder = createConfirmBuilder;
	}
};
