import Sale from "./sale";
import SaleType from "./sale-type";
import { printBoolean, printLocalDate } from "@/utils/pretty-print";
import { field, print, hidden, nullable, computed } from "@/utils/models/decorator-field";
import { ignoredFields, model } from "@/utils/models/decorator-model";
import { i18n } from "@/setup/i18n-setup";
import {getPositionalPluralization} from "@/utils/i18n-utils";
import { IFrequencyObject } from "@/utils/frequency-object";

export enum FrequencyType {
	Weekly = 1,
	Monthly = 2,
	MonthlyPerWeek = 3,
	Yearly = 4
}

function printEndDate(value:Date):string{
	if (value == null){
		return i18n.t("common.none").toString();
	}
	return printLocalDate(value);
}

function printFrequencyType(value:FrequencyType):string{
	if (value == FrequencyType.Weekly){
		return i18n.t("models.FrequencyType.Weekly").toString();
	}
	if (value == FrequencyType.Monthly){
		return i18n.t("models.FrequencyType.Monthly").toString();
	}
	if (value == FrequencyType.MonthlyPerWeek){
		return i18n.t("models.FrequencyType.MonthlyPerWeek").toString();
	}
	if (value == FrequencyType.Yearly) {
		return i18n.t("models.FrequencyType.Yearly").toString();
	}
	return `ERROR ${value} is not a correct frequency-type`;
}

@model("FrequencyDocument")
@ignoredFields([
	"Sale.MolliePaymentID",
	"Sale.MolliePaymentStatus",
	"Sale.LastBank",
	"Sale.LastBankID",
	"Sale.Contact.MainAddress.Contact",
	"Sale.InvoiceAddress.Contact",
	"Sale.DeliveryAddress.Contact"
])
export default class FrequencyDocument implements IFrequencyObject{

	@field("number")
	public ID:number = 0;

	@field("number")
	public SaleID:number = 0;

	@field("number")
	@print(printFrequencyType)
	public FrequencyType:FrequencyType = FrequencyType.Weekly;

	@field("string")
	public FrequencyMoment:string = "";

	@field("number")
	public Interval:number = 1;

	@field("date")
	@print(printLocalDate)
	public StartDate:Date = new Date();

	@field("date")
	@print(printEndDate)
	@nullable()
	public EndDate:Date | null = null;

	@field("boolean")
	@print(printBoolean)
	public IsAutomatic:boolean = true;

	@field("date")
	@print(printLocalDate)
	@nullable()
	public NextExecutionDate:Date | null = null;

	@field("number")
	public SaleTypeID:number = 1;

	@field("has-one", "SaleType")
	public SaleType:SaleType | null = new SaleType();

	@field("has-one", "Sale")
	public Sale:Sale | null = new Sale();

	@field("boolean")
	@print(printBoolean)
	public SendMailEnabled:boolean = false;

	@field("string")
	public MailTo:string = "";

	@field("string")
	public MailFrom:string = "";

	@field("string")
	public MailBCC:string = "";

	@field("string")
	public MailCC:string = "";

	@field("string")
	public MailSubject:string = "";

	@field("string")
	@hidden()
	public MailContentTemplate:string = "";

	@field("boolean")
	@hidden()
	public SendReadConfirmation:boolean = false;

	@field("string")
	public MollieMandateID:string = "";

	@field("string")
	@computed()
	public get humanFrequencyMoment():string{
		if (this.FrequencyType == FrequencyType.Weekly) {
			let days = this.FrequencyMoment.split("");
			return days.map((d)=>i18n.t(`common.weekday.${d}`).toString()).join(", ");
		}
		if (this.FrequencyType == FrequencyType.Monthly) {
			return i18n.t(`models.FrequencyMoment.nth-of-month`, {number: getPositionalPluralization(parseInt(this.FrequencyMoment))}).toString();
		}
		if (this.FrequencyType == FrequencyType.MonthlyPerWeek){
			let parts = this.FrequencyMoment.split("/");
			let weekday = i18n.t(`common.weekday.${parseInt(parts[0])+1}`);
			return i18n.t(`models.FrequencyMoment.nth-weekday-of-month`, {number: getPositionalPluralization(parseInt(parts[1])), weekday}).toString();
		}
		if (this.FrequencyType == FrequencyType.Yearly) {
			let parts = this.FrequencyMoment.split("/");
			let month = i18n.t(`common.months.${parts[1]}`);
			return `${parts[0]} ${month}`;
		}
		return "ERROR";
	}

	@field("string")
	@computed()
	public get humanInterval():string{
		if (this.FrequencyType == FrequencyType.Weekly){
			return i18n.tc("models.FrequencyMoment.interval-weekly", this.Interval).toString();
		}
		if (this.FrequencyType == FrequencyType.Monthly || this.FrequencyType == FrequencyType.MonthlyPerWeek){
			return i18n.tc("models.FrequencyMoment.interval-monthly", this.Interval).toString();
		}
		if (this.FrequencyType == FrequencyType.Yearly){
			return i18n.tc("models.FrequencyMoment.interval-yearly", this.Interval).toString();
		}
		return "ERROR";
	}

	public constructor(data?:any) {
		this.setStartDate(new Date());
		if (data) {
			this.ID = data.ID;
			this.SaleID = data.SaleID;
			this.FrequencyType = data.FrequencyType;
			this.FrequencyMoment = data.FrequencyMoment;
			this.Interval = data.Interval;
			this.StartDate = new Date(data.StartDate);
			if (data.EndDate) {
				this.EndDate = new Date(data.EndDate);
			}
			this.IsAutomatic = data.IsAutomatic;
			this.SaleTypeID = data.SaleTypeID;
			if (data.SaleType) {
				this.SaleType = new SaleType(data.SaleType);
			}
			if (data.Sale) {
				this.Sale = new Sale(data.Sale);
			}

			this.SendMailEnabled = data.SendMailEnabled;
			this.MailTo = data.MailTo;
			this.MailBCC = data.MailBCC;
			this.MailCC = data.MailCC;
			this.MailFrom = data.MailFrom;
			this.MailSubject = data.MailSubject;
			this.MailContentTemplate = data.MailContentTemplate;

			if (data.NextExecutionDate){
				this.NextExecutionDate = new Date(data.NextExecutionDate);
			}
			this.SendReadConfirmation = data.SendReadConfirmation;
			this.MollieMandateID = data.MollieMandateID;
		}
		if (this.Sale){
			this.Sale.TypeID = 10;
		}
	}

	public setStartDate(date:Date) {
		if (date.getHours() < 6) {
			date.setHours(12);
		}
		this.StartDate = date;
	}

	public getJSON() {
		return {
			ID: this.ID,
			SaleID: this.SaleID,
			FrequencyType: this.FrequencyType,
			FrequencyMoment: this.FrequencyMoment,
			Interval: this.Interval,
			StartDate: this.StartDate.toJSON(),
			EndDate: this.EndDate ? this.EndDate.toJSON() : null,
			IsAutomatic: this.IsAutomatic,
			NextExecutionDate: this.NextExecutionDate ? this.NextExecutionDate.toJSON() : null,
			SaleTypeID: this.SaleTypeID,
			Sale: this.Sale ? this.Sale.getJSON() : null,
			SendMailEnabled: this.SendMailEnabled,
			MailTo: this.MailTo,
			MailBCC: this.MailBCC,
			MailCC: this.MailCC,
			MailFrom: this.MailFrom,
			MailSubject: this.MailSubject,
			MailContentTemplate: this.MailContentTemplate,
			SendReadConfirmation: this.SendReadConfirmation,
			MollieMandateID: this.MollieMandateID
		};
	}
}
