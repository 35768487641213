import { CustomTranslation } from "@/utils/custom-translation";

export class ReminderEmailTemplate {
	ID:number = 0;
	ReminderCount:number = 1;
	Name:string = "";
	Subject:CustomTranslation = new CustomTranslation();
	Content:CustomTranslation = new CustomTranslation();
	IsHTML:boolean = true;

	constructor(data?:any) {
		if (!data) {
			return;
		}

		this.ID = data.ID;
		this.ReminderCount = data.ReminderCount;
		this.Name = data.Name;
		if(data.Subject){
			this.Subject = new CustomTranslation(data.Subject);
		}
		this.Content = new CustomTranslation(data.Content);
		this.IsHTML = data.IsHTML;
	}

	getJSON() {
		return {
			ID: this.ID,
			ReminderCount: this.ReminderCount,
			Name: this.Name,
			Subject: this.Subject.getJSON(),
			Content: this.Content.getJSON(),
			IsHTML: this.IsHTML
		};
	}
}