import Contact from "./contact";
import ContactAddress from "./contact-address";
import SaleExtraFields from "./sale-extra-fields";
import SaleHistory from "./sale-history";
import SaleRow, { ISaleRowGetJsonOptions } from "./sale-row";
import SaleType from "./sale-type";
import { isAboutEqualPrice, roundTo8Decimals } from "@/utils/numbers";
import {
	highlightCell,
	printHumanFloat,
	printSaleStatus,
	printLocalDate,
	printLocalDateTime,
	printBoolean,
	printUser
} from "@/utils/pretty-print";
import { ProductTotal } from "@/utils/product-row";
import { field, hidden, print, nullable, rightAlign, filterValueInput, dependencies, filterOnly, computed, defaultTextIfValueSettings, decimals, onClick } from "@/utils/models/decorator-field";
import { ignoredFields, priorityFields, model, namedFilters } from "@/utils/models/decorator-model";
import { getModel } from "@/utils/models/model";
import { DossierConfigService } from "@/services/dossier-config-service";
import { i18n } from "@/setup/i18n-setup";
import { VatService } from "@/services/vat-service";
import { icon as faIcon } from "@fortawesome/fontawesome-svg-core";
import { HumanFilter } from "@/utils/human-filter";
import { Bank, Car } from ".";
import { TinySaleInfo } from "@/utils/tiny-sale-info";
import { SaleService } from "@/services/sale-service";
import { NamedFilter } from "@/utils/models/named-filter";
import { TranslateResult } from "vue-i18n";
import { SaleAwsDocument } from "./sale-aws-document";
import { IModel } from "@/utils/models/model-interface";
import { PrintContext } from "@/utils/sale-layouts/print-settings";
import { UserConfigService } from "@/services/user-config-service";
import { createUserTextIfValueSettings } from "@/utils/models/default-text-if-value-settings";
import Currency from "@/models/base/currency";
import { Observation } from "@/utils/carpass/observation";
import FrequencyDocument from "./frequency-document";
import { ObservationDescription } from "@/utils/carpass/observation-description";
import { CurrencyMap, CurrencyService } from "@/services/currency-service";
import { getOpenAwsDocumentsFunc } from "@/utils/models/click-action-handlers";
import { AwsDocument } from "./aws-document";

export enum SaleStatus {
	Payed,
	Unpayed,
	Expired,
	Empty,
	Difference
}

export interface ISaleStatus {
	Status: SaleStatus;
	DaysToLate?: number;
}

export interface ISaleGetJsonOptions{
	includeProducts?:boolean
}

export const saleIgnoredFields:string[] = [
	"InvoiceAddress.Contact",
	"DeliveryAddress.Contact",
	"Contact.MainAddress.Contact",
	"LastBank.Invoice",
	"LastBank.Purchase",
	"LastBank.FriendlyPurchaseID",
	"LastBank.PurchaseID",
	"LastBank.Contact",
	"LastBank.Ticket.LastBank",
	"LastBank.Sale.Banks",
	"LastBank.Ticket.Contact",
	"LastBank.Ticket.InvoiceAddress.Contact",
	"LastBank.Ticket.DeliveryAddress.Contact",
	"LastBank.Ticket.CreatedByFrequencyDocument",
	"LastBank.Sale.CreatedByFrequencyDocument",
	"Car.Invoice",
	"Car.Purchase",
	"Car.Supplier.MainAddress.Contact",
	"Car.Contact.MainAddress.Contact",
	"CreatedByFrequencyDocument.Sale"
];

@model("Sale")
@ignoredFields(saleIgnoredFields)
// Fixes an issue where the contact model isn't defined yet
@priorityFields(new Promise((resolve) =>
	requestAnimationFrame(() =>
		resolve(["ComputedFriendlyID", "Type.DisplayedName"].concat(getModel("Contact").getPriorityFields().map((f) => "Contact." + f)))
	)
))
@namedFilters([
	new NamedFilter({
		name: "client-in-list",
		filterToString(filter:HumanFilter):TranslateResult{
			if (filter.Options.indexOf("not-in") != -1){
				return i18n.t("models.friendlyNames.Sale.client-not-in-list-text");
			}
			return i18n.t("models.friendlyNames.Sale.client-in-list-text");
		}
	}),
	new NamedFilter({
		name: "sale-type-in-list",
		filterToString(filter:HumanFilter):TranslateResult{
			if (filter.Options.indexOf("not-in") != -1){
				return i18n.t("models.friendlyNames.Sale.sale-type-not-in-list-text");
			}
			return i18n.t("models.friendlyNames.Sale.sale-type-in-list-text");
		}
	}),
	new NamedFilter({
		name: "e-invoice-sent",
		filterToString(filter:HumanFilter):TranslateResult {
			if (filter.Values.indexOf("true")) {
				return i18n.t("models.friendlyNames.Sale.e-invoice-sent");
			}
			return i18n.t("models.friendlyNames.Sale.e-invoice-not-sent");
		}
	}),
	...Contact.getContactNamedFilters().map((c)=>{
		let originalFilterToString = c.filterToString.bind(c);
		c.filterToString = (filter:HumanFilter):TranslateResult=>{
			let value = originalFilterToString(filter);
			return i18n.t("models.friendlyNames.Sale.contact-has-address", {value});
		};
		let originalGetDisplayName = c.getDisplayName.bind(c);
		c.getDisplayName = (model:IModel):TranslateResult=>{
			let value = originalGetDisplayName(model);
			return i18n.t("models.friendlyNames.Sale.contact-has-address", {value});
		};
		return c;
	}),
])
export default class Sale {
	@field("number")
	public ID: number = 0;

	@field("number")
	@filterValueInput("SaleType")
	@filterOnly()
	public TypeID: number = 1;

	@field("has-one", "SaleType")
	public Type: SaleType = new SaleType();

	@field("number")
	@filterValueInput("Contact")
	public ContactID: number = 0;

	@field("has-one", "Contact")
	public Contact: Contact | null = null;

	@field("string")
	public CompanyName: string = "";

	@field("number")
	@filterValueInput("BookYear")
	public BookYear: number = 0;

	@field("has-many", "SaleRow")
	public Rows: SaleRow[] = [];

	@field("number")
	public VatRegime: number = 1;

	@field("number")
	@hidden()
	public FriendlyID: number = 0;

	@field("string")
	public ComputedFriendlyID: string = "";

	@field("number")
	public PriceCategory: number = 1;

	@field("string")
	public ToPrintMessage: string = "";

	@field("string")
	public ToNotPrintMessage: string = "";

	@field("date")
	@print(printLocalDate)
	public InvoiceDate: Date = new Date();

	@field("date")
	@print(printLocalDate)
	public ExpirationDate: Date = new Date();

	@field("date")
	@nullable()
	@print((value: Date | null): string => {
		if (!value) {
			return i18n.t("common.none").toString();
		}
		return printLocalDate(value);
	})
	public DeliveryDate: Date | null = null;

	@field("number")
	public InvoiceAddressID: number = 0;

	@field("number")
	@nullable()
	public DeliveryAddressID: number | null = null;

	@field("has-one", "ContactAddress")
	public InvoiceAddress: ContactAddress | null = null;

	@field("has-one", "ContactAddress")
	@nullable()
	public DeliveryAddress: ContactAddress | null = null;

	/**
	 * TotalIncVat - Korting Contant
	 */
	@field("number")
	@decimals(2)
	@rightAlign()
	public TotalBase: number = 0;

	@field("number")
	@decimals(2)
	@rightAlign()
	public TotalExcVAT: number = 0;

	@field("number")
	@decimals(2)
	@rightAlign()
	public TotalDiscount: number = 0;

	@field("number")
	@decimals(2)
	@rightAlign()
	public TotalIncVAT: number = 0;

	@field("number")
	@decimals(2)
	@rightAlign()
	public TotalLeeggoed: number = 0;

	@field("number")
	@decimals(2)
	@rightAlign()
	public TotalEcoboniExcVat:number = 0;

	@field("number")
	@decimals(2)
	@rightAlign()
	public TotalEcoboniIncVat:number = 0;

	@field("number")
	@decimals(2)
	@rightAlign()
	public TotalAccijnzenExcVat:number = 0;

	@field("number")
	@decimals(2)
	@rightAlign()
	public TotalAccijnzenIncVat:number = 0;

	@field("number")
	@decimals(2)
	@rightAlign()
	public TotalToPay:number = 0;

	@field("number")
	@print((value: any): string => {
		value = printHumanFloat(value);
		return highlightCell(value);
	})
	@rightAlign()
	public TotalOpen: number = 0;

	@field("number")
	@decimals(2)
	@rightAlign()
	public TotalBuy: number = 0;

	@field("number")
	@decimals(2)
	@rightAlign()
	public TotalVAT: number = 0;

	// eslint-disable-next-line max-lines-per-function
	@field("number")
	@computed()
	@print(printSaleStatus)
	public get Status(): ISaleStatus {
		if (this.TypeID != 1 && this.TypeID != 2) {
			return {
				Status: SaleStatus.Empty
			};
		}
		if (this.TotalOpen < 0 && !isAboutEqualPrice(this.TotalOpen, this.TotalToPay) || (this.TotalOpen < 0 && ((this.TotalOpen*-1) == this.TotalToPay))) {
			return {
				Status: SaleStatus.Difference
			};
		}

		if (this.TotalOpen < 0 && isAboutEqualPrice(this.TotalOpen, this.TotalLeeggoed) && this.TypeID == 1) {
			return {
				Status: SaleStatus.Unpayed
			};
		}

		if (this.TotalOpen <= 0 && this.TypeID == 1) {
			return {
				Status: SaleStatus.Payed
			};
		}

		if (this.TotalOpen == 0 && this.TypeID == 2) {
			return {
				Status: SaleStatus.Payed
			};
		}

		let myDate = new Date();
		let dayDiff = (myDate.getTime() - this.ExpirationDate.getTime()) / (1000 * 60 * 60 * 24);
		if (dayDiff < 0) {
			return {
				Status: SaleStatus.Unpayed
			};
		}
		return {
			Status: SaleStatus.Expired,
			DaysToLate: dayDiff
		};
	}

	@field("number")
	@print((value: Number | null): any => {
		if (!value) {
			return i18n.t("common.none").toString();
		}
		if(value == 9){
			return i18n.t("common.disabled").toString();
		}
		return value;
	})
	public Reminder: number = 0;

	@field("date")
	@nullable()
	@print((value: Date | null): string => {
		if (!value) {
			return i18n.t("common.none").toString();
		}
		return printLocalDate(value);
	})
	public ReminderDate: Date | null = null;

	@field("number")
	@hidden()
	public LayoutID: number = 0;

	@field("number")
	@hidden()
	public CurrencyID: number = 1;

	@field("number")
	public CurrencyRate: number = 1;

	@field("has-one", "Currency")
	public Currency: Currency | null = new Currency();

	@field("string")
	public Lng: string = "nl-be";

	@field("has-many", "SaleHistory")
	public History: SaleHistory[] = [];

	@field("number")
	public ExtraFieldsID: number = 0;

	@field("has-one", "SaleExtraFields")
	public ExtraFields: SaleExtraFields | null = new SaleExtraFields();

	@field("number")
	public CreatedByFrequencyDocumentID: number = 0;

	@field("has-one", "FrequencyDocument")
	public CreatedByFrequencyDocument:FrequencyDocument | null = null;

	@field("date")
	@print(printLocalDate)
	public FrequencyFrom:Date | null = null;

	@field("date")
	@print(printLocalDate)
	public FrequencyTill:Date | null = null;

	@field("number")
	@filterValueInput("Journal")
	public JournalID: number = 1;

	/**
	 * Korting contant
	 */
	@field("number")
	@decimals(2)
	@rightAlign()
	public PaymentBeforeDiscountPercent: number = 0;

	@field("number")
	@decimals(2)
	@rightAlign()
	public PaymentBeforeDiscount: number = 0;

	/**
	 * Korting contant datum
	 */
	@field("date")
	public PaymentBeforeDiscountDate: Date = new Date();

	@field("boolean")
	public UseCreditRestrictionInsteadOfDiscountPercent:boolean = false;

	@field("boolean")
	@print(printBoolean)
	public IsRead: boolean = false;

	@field("string")
	@hidden()
	public SentEmailUUID: string = "";

	@field("boolean")
	@print(printBoolean)
	public HasBeenImportedInOtherSale: boolean = false;

	@field("boolean")
	@print(printBoolean)
	@onClick(getOpenAwsDocumentsFunc(async function(value:any):Promise<AwsDocument[]>{
		let result = await SaleService.getSale(value.ID);
		return result.AWSDocuments;
	}))
	public HasAttachedDocument:boolean = false;

	@field("string")
	@computed()
	@dependencies(["Banks.Type"])
	public get BankBookings(): string {
		return this.Banks.map((b:Bank)=> {
			return b.Type?.Name + ": &euro; " + b.Total.toFixed(2) + " - " + printLocalDate(b.TimeCreated);
		}
		).join(" | ");
	};

	@field("number")
	@filterValueInput("Creator")
	@print(printUser)
	@defaultTextIfValueSettings(createUserTextIfValueSettings("CreatorID"))
	public CreatorID: number = 0;

	@field("string")
	@computed()
	@hidden()
	public get Creator(): string {
		return printUser(this.CreatorID);
	}

	@field("string")
	public Ogm: string = "";

	@field("string")
	@hidden()
	public SignatureImageToken: string = "";

	@field("string")
	public SignedBy: string = "";

	@field("date")
	@nullable()
	public SignedOnDate: Date | null = null;

	@field("boolean")
	@computed()
	@print(printBoolean)
	public get IsSigned(): boolean {
		return this.SignatureImageToken != "";
	}

	@field("date")
	@nullable()
	@print((value: Date | null): string => {
		if (!value) {
			return "";
		}
		return printLocalDateTime(value);
	})
	public LastSendDate: Date | null = null;

	@field("date")
	@nullable()
	@print((value: Date | null): string => {
		if (!value) {
			return "";
		}
		return printLocalDateTime(value);
	})
	public LastPrintDate: Date | null = null;

	@field("string")
	@computed()
	public get Actions(): string {
		let s = "";
		if (this.LastPrintDate) {
			s += '<span class="mr">' + faIcon({prefix: "fas", iconName: "print"}).html[0] + "</span>";
		}
		if (this.LastSendDate) {
			s += faIcon({prefix: "fas", iconName: "envelope"}).html[0];
		}
		if (this.LastSentViaPeppolDate) {
			s += "<span class='st e-inv'><b>E</b></span>";
		}
		return s;
	}

	@field("number")
	public LastBankID:number | null = null;
	@field("has-one", "Bank")
	public LastBank:Bank | null = null;

	@field("number")
	public CrmID:number | null = null;

	@field("array")
	@print((value:TinySaleInfo[]):string=>{
		if (value.length == 0) {
			return i18n.t("common.none").toString();
		}
		return value.map(v=>`${v.ComputedFriendlyID} (${SaleService.getTypeName(v.TypeID)})`).join(", ");
	})
	public ImportedInSales:TinySaleInfo[] = [];

	@field("string")
	public MolliePaymentID:string = ""
	@field("string")
	public MolliePaymentStatus:string = "";

	@field("number")
	@rightAlign()
	@decimals(2)
	public DepositAmount:number = 0.0;

	public ToCreateDeposit:number = 0.0;

	@field("has-many", "Bank")
	public Banks:Bank[] = [];
	public AWSDocuments:SaleAwsDocument[] = [];

	@field("number")
	@filterValueInput("Car")
	public CarID:number | null = null;
	@field("has-one", "Car")
	public Car:Car | null = null;
	@field("number")
	public CarMileage:number = 0.0;

	@field("string")
	@hidden()
	public CarpassRequestId:string = "";

	@field("string")
	public CarpassStatus:string = ""

	@field("json")
	@hidden()
	public CarpassObservationBody:Observation | null = null;

	@field("json")
	@hidden()
	public CarpassObservationDescriptionBody:ObservationDescription[] = [];

	@field("boolean")
	@computed()
	@print(printBoolean)
	public get HasCarpassObservationDescription():boolean {
		return this.CarpassObservationDescriptionBody.length > 0;
	};

	@field("boolean")
	@print(printBoolean)
	public HasBackorder:boolean = false;

	@field("boolean")
	@print(printBoolean)
	public DeliveryComplete:boolean = false;

	@field("boolean")
	@print(printBoolean)
	public IsUblSentToAccountant:boolean = false;

	@field("boolean")
	@print(printBoolean)
	public SentViaSendcloud:boolean = false;

	@field("date")
	@nullable()
	@print(printLocalDate)
	public LastSentViaPeppolDate:Date | null = null;

	@field("json")
	@hidden()
	public Valutas:CurrencyMap = {};


	public UnprocessedFiles:SaleAwsDocument[] = [];
	public toRemoveFiles:SaleAwsDocument[] = [];
	public toUpdateFiles:SaleAwsDocument[] = [];

	// eslint-disable-next-line max-lines-per-function
	public constructor(data?: any) {
		if (!data){
			this.Valutas = CurrencyService.getCurrenciesMap();
			return;
		};
		this.ID = data.ID;
		this.TypeID = data.TypeID;
		if (data.Type) {
			this.Type = new SaleType(data.Type);
		}
		this.ContactID = data.ContactID;
		this.VatRegime = data.VatRegime;
		this.BookYear = data.BookYear;
		if (data.Contact) {
			this.Contact = new Contact(data.Contact);
		}
		this.CompanyName = data.CompanyName;
		if (data.Rows) {
			this.Rows = (data.Rows as any[]).map((r: any) => new SaleRow(r));
			this.sortRows();
		}
		this.FriendlyID = data.FriendlyID;
		this.ComputedFriendlyID = data.ComputedFriendlyID;
		this.InvoiceDate = new Date(data.InvoiceDate);
		this.ExpirationDate = new Date(data.ExpirationDate);
		this.ReminderDate = data.ReminderDate ? new Date(data.ReminderDate) : null;
		if (data.DeliveryDate) {
			this.DeliveryDate = new Date(data.DeliveryDate);
		}
		this.InvoiceAddressID = data.InvoiceAddressID;
		this.DeliveryAddressID = data.DeliveryAddressID || undefined;
		this.PriceCategory = data.PriceCategory;
		this.TotalBase = data.TotalBase;
		this.TotalExcVAT = data.TotalExcVAT;
		this.TotalDiscount = data.TotalDiscount;
		this.TotalIncVAT = data.TotalIncVAT;
		this.TotalLeeggoed = data.TotalLeeggoed;
		this.TotalEcoboniExcVat = data.TotalEcoboniExcVat;
		this.TotalEcoboniIncVat = data.TotalEcoboniIncVat;
		this.TotalAccijnzenExcVat = data.TotalAccijnzenExcVat;
		this.TotalAccijnzenIncVat = data.TotalAccijnzenIncVat;
		this.TotalOpen = data.TotalOpen;
		this.TotalBuy = data.TotalBuy;
		this.TotalVAT = data.TotalVAT;
		this.TotalToPay = data.TotalToPay;
		this.ExtraFieldsID = data.ExtraFieldsID;
		if (data.InvoiceAddress) {
			this.InvoiceAddress = new ContactAddress(data.InvoiceAddress);
		}
		if (data.DeliveryAddress) {
			this.DeliveryAddress = new ContactAddress(data.DeliveryAddress);
		}
		this.ToPrintMessage = data.ToPrintMessage;
		this.ToNotPrintMessage = data.ToNotPrintMessage;
		this.Reminder = data.Reminder;
		this.LayoutID = data.LayoutID;
		this.Lng = data.Lng;

		if (data.History) {
			this.History = data.History.map((h: any) => new SaleHistory(h));
		}

		if (data.ExtraFields) {
			this.ExtraFields = new SaleExtraFields(data.ExtraFields);
		}

		if (data.Currency) {
			this.Currency = new Currency(data.Currency);
		}
		this.CurrencyID = data.CurrencyID;
		this.CurrencyRate = data.CurrencyRate && data.CurrencyRate != 0 ? data.CurrencyRate : 1;

		this.CreatedByFrequencyDocumentID = data.CreatedByFrequencyDocumentID;
		if (data.CreatedByFrequencyDocument) {
			this.CreatedByFrequencyDocument = new FrequencyDocument(data.CreatedByFrequencyDocument);
		}
		if (data.FrequencyFrom) {
			this.FrequencyFrom = new Date(data.FrequencyFrom);
		}
		if (data.FrequencyTill) {
			this.FrequencyTill = new Date(data.FrequencyTill);
		}
		this.JournalID = data.JournalID;

		this.PaymentBeforeDiscountPercent = data.PaymentBeforeDiscountPercent;
		this.PaymentBeforeDiscount = data.PaymentBeforeDiscount;
		this.PaymentBeforeDiscountDate = new Date(data.PaymentBeforeDiscountDate);
		this.UseCreditRestrictionInsteadOfDiscountPercent = data.UseCreditRestrictionInsteadOfDiscountPercent;

		this.IsRead = data.IsRead;
		this.SentEmailUUID = data.SentEmailUUID;

		this.HasBeenImportedInOtherSale = data.HasBeenImportedInOtherSale;

		this.CreatorID = data.CreatorID;
		this.Ogm = data.Ogm;

		this.SignatureImageToken = data.SignatureImageToken;
		this.SignedBy = data.SignedBy;
		if (data.SignedOnDate) {
			this.SignedOnDate = new Date(data.SignedOnDate);
		}

		if (data.LastSendDate) {
			this.LastSendDate = new Date(data.LastSendDate);
		}
		if (data.LastPrintDate) {
			this.LastPrintDate = new Date(data.LastPrintDate);
		}

		this.LastBankID = data.LastBankID;
		this.CrmID = data.CrmID;
		if (this.LastBankID != null && data.LastBank) {
			this.LastBank = new Bank(data.LastBank);
		}

		if (data.ImportedInSales) {
			this.ImportedInSales = data.ImportedInSales.map((s:any)=>new TinySaleInfo(s));
		}

		this.MolliePaymentID = data.MolliePaymentID;
		this.MolliePaymentStatus = data.MolliePaymentStatus;

		this.DepositAmount = data.DepositAmount;

		if (data.AWSDocuments) {
			this.AWSDocuments = (data.AWSDocuments as any[]).map(d=>new SaleAwsDocument(d)).sort((a,b)=>{
				return a.DisplayOrder - b.DisplayOrder;
			});
		}

		if (data.Banks) {
			this.Banks = (data.Banks as any[]).map(b=>new Bank(b));
		}

		this.CarID = data.CarID;
		if (data.Car) {
			this.Car = new Car(data.Car);
		}
		this.CarMileage = data.CarMileage;
		this.CarpassRequestId = data.CarpassRequestId;
		this.CarpassStatus = data.CarpassStatus;

		if (data.CarpassObservationBody) {
			this.CarpassObservationBody = new Observation(data.CarpassObservationBody);
		}
		this.CarpassObservationDescriptionBody = (data.CarpassObservationDescriptionBody || []).map((c:any)=>new ObservationDescription(c));
		this.HasBackorder = data.HasBackorder;
		this.DeliveryComplete = data.DeliveryComplete;
		this.IsUblSentToAccountant = data.IsUblSentToAccountant;
		this.SentViaSendcloud = data.SentViaSendcloud || false;
		this.HasAttachedDocument = data.HasAttachedDocument;
		if (data.LastSentViaPeppolDate) {
			this.LastSentViaPeppolDate = new Date(data.LastSentViaPeppolDate);
		}

		this.Valutas = {...data.Valutas};
	}

	private sortRows(){
		this.Rows = this.Rows.sort((a, b) => a.DisplayOrder - b.DisplayOrder);
	}

	// eslint-disable-next-line max-lines-per-function
	public getJSON(options?:ISaleGetJsonOptions) {
		let rowOptions:ISaleRowGetJsonOptions = {};
		if (options) {
			if (options.includeProducts) {
				rowOptions.includeProduct = true;
			}
		}
		this.Rows.forEach((r, i) => r.DisplayOrder = i);
		return {
			ID: this.ID,
			TypeID: this.TypeID,
			ContactID: this.ContactID,
			CompanyName: this.CompanyName,
			VatRegime: this.VatRegime,
			BookYear: this.BookYear,
			FriendlyID: this.FriendlyID,
			ComputedFriendlyID: this.ComputedFriendlyID,
			InvoiceDate: this.InvoiceDate.toJSON(),
			ExpirationDate: this.ExpirationDate.toJSON(),
			ReminderDate: this.ReminderDate != null ? this.ReminderDate.toJSON() : null,
			DeliveryDate: this.DeliveryDate != null ? this.DeliveryDate.toJSON() : null,
			InvoiceAddressID: this.InvoiceAddressID,
			DeliveryAddressID: this.DeliveryAddressID,
			TotalBase: this.TotalBase,
			TotalExcVAT: this.TotalExcVAT,
			TotalDiscount: this.TotalDiscount,
			TotalIncVAT: this.TotalIncVAT,
			TotalLeeggoed: this.TotalLeeggoed,
			TotalEcoboniExcVat: this.TotalEcoboniExcVat,
			TotalEcoboniIncVat: this.TotalEcoboniIncVat,
			TotalAccijnzenExcVat: this.TotalAccijnzenExcVat,
			TotalAccijnzenIncVat: this.TotalAccijnzenIncVat,
			TotalOpen: this.TotalOpen,
			TotalBuy: this.TotalBuy,
			TotalVAT: this.TotalVAT,
			TotalToPay: this.TotalToPay,
			PriceCategory: this.PriceCategory,
			ToPrintMessage: this.ToPrintMessage,
			ToNotPrintMessage: this.ToNotPrintMessage,
			Reminder: this.Reminder,
			LayoutID: this.LayoutID,
			Lng: this.Lng,
			Rows: this.Rows.map((r) => r.getJSON(rowOptions)),
			History: this.History.map(h => h.getJSON()),
			ExtraFieldsID: this.ExtraFieldsID,
			ExtraFields: this.ExtraFields ? this.ExtraFields.getJSON() : null,
			CreatedByFrequencyDocumentID: this.CreatedByFrequencyDocumentID,
			FrequencyFrom: this.FrequencyFrom ? this.FrequencyFrom.toJSON() : null,
			FrequencyTill: this.FrequencyTill ? this.FrequencyTill.toJSON() : null,
			JournalID: this.JournalID,
			PaymentBeforeDiscountPercent: this.PaymentBeforeDiscountPercent,
			PaymentBeforeDiscount: this.PaymentBeforeDiscount,
			PaymentBeforeDiscountDate: this.PaymentBeforeDiscountDate.toJSON(),
			UseCreditRestrictionInsteadOfDiscountPercent: this.UseCreditRestrictionInsteadOfDiscountPercent,
			IsRead: this.IsRead,
			SentEmailUUID: this.SentEmailUUID,
			HasBeenImportedInOtherSale: this.HasBeenImportedInOtherSale,
			CreatorID: this.CreatorID,
			Ogm: this.Ogm,
			SignatureImageToken: this.SignatureImageToken,
			SignedBy: this.SignedBy,
			SignedOnDate: this.SignedOnDate,
			LastPrintDate: this.LastPrintDate,
			LastSendDate: this.LastSendDate,
			LastBankID: this.LastBankID,
			CrmID: this.CrmID,
			ImportedInSales: this.ImportedInSales.map(s=>s.getJSON()),
			MolliePaymentID: this.MolliePaymentID,
			MolliePaymentStatus: this.MolliePaymentStatus,
			DepositAmount: this.DepositAmount,
			CarID: this.CarID,
			CarMileage: this.CarMileage,
			CarpassRequestId: this.CarpassRequestId,
			CarpassStatus: this.CarpassStatus,
			CarpassObservationDescriptionBody: this.CarpassObservationDescriptionBody.map(o=>o.getJSON()),
			CurrencyID: this.CurrencyID,
			CurrencyRate: this.CurrencyRate,
			HasBackorder: this.HasBackorder,
			DeliveryComplete: this.DeliveryComplete,
			ToCreateDeposit: this.ToCreateDeposit,
			SentViaSendcloud: this.SentViaSendcloud,
			HasAttachedDocument: this.HasAttachedDocument,
			LastSentViaPeppolDate: this.LastSentViaPeppolDate ? this.LastSentViaPeppolDate.toJSON() : null,
			Valutas: {...this.Valutas}
		};
	}

	public getProductTotals(): ProductTotal {
		return {
			rows: this.Rows,
			TotalExcVAT: this.TotalExcVAT,
			TotalIncVAT: this.TotalIncVAT,
			TotalVAT: this.TotalVAT,
			TotalBuy: this.TotalBuy,
			TotalLeeggoed: this.TotalLeeggoed,
			CurrencyRate: this.CurrencyRate
		};
	}

	public enforceVatRegime() {
		let nonEnforcableVatRegimes = [ 1, 7, 12, 13, 14, 15, 16 ];
		if (nonEnforcableVatRegimes.indexOf(this.VatRegime) != -1) return;
		let vatRegime = VatService.getVatRegime(this.VatRegime);
		let vatItem = VatService.getVatByValue(vatRegime.getSaleVat());
		for (let row of this.Rows) {
			row.Vat = vatItem.Value;
		}
		this.calculateTotals();
	}

	// eslint-disable-next-line max-lines-per-function
	public calculateTotals() {
		this.TotalBase = 0.0;
		this.TotalVAT = 0.0;
		this.TotalIncVAT = 0.0;
		this.TotalBuy = 0.0;
		this.TotalExcVAT = 0;
		this.TotalDiscount = 0;
		this.TotalLeeggoed = 0;
		this.TotalEcoboniExcVat = 0;
		this.TotalEcoboniIncVat = 0;
		this.TotalAccijnzenExcVat = 0;
		this.TotalAccijnzenIncVat = 0;
		this.TotalToPay = 0;
		if (this.Rows.length == 0) {
			return;
		}
		for (let row of this.Rows) {
			if(row.getRowConfig().Hidden && !DossierConfigService.getSaleConfigs().AddNotToPrintToTotal){
				continue;
			}
			row.calculateTotals(this);
			this.TotalDiscount += row.getTotalDiscount();
			this.TotalExcVAT += row.TotalPriceExcVat;
			let rowBase = row.TotalPriceExcVat - (row.TotalPriceExcVat / 100.0 * this.PaymentBeforeDiscountPercent);
			if (this.UseCreditRestrictionInsteadOfDiscountPercent) {
				rowBase = row.TotalPriceExcVat + (row.TotalPriceExcVat / 100.0 * this.PaymentBeforeDiscountPercent);
			}
			this.TotalBase += rowBase;
			this.TotalBuy += row.UnitBuyPrice * row.Amount;
			if (this.Contact && this.Contact.GroupID != 2 && this.VatRegime == 7) {
				this.TotalVAT += (rowBase - row.TotalBuyPrice) / 100 * row.Vat;
			} else if (!this.UseCreditRestrictionInsteadOfDiscountPercent) {
				this.TotalVAT += rowBase / 100 * row.Vat;
			}else{
				this.TotalVAT += row.TotalPriceExcVat / 100 * row.Vat;
			}
			this.TotalLeeggoed += (row.LeeggoedTax * row.Amount);
			this.TotalEcoboniExcVat += row.EcoboniTax * row.Amount;
			this.TotalEcoboniIncVat += row.EcoboniTax * row.Amount * (1+ row.Vat/100);
			this.TotalAccijnzenExcVat += row.AccijnzenTax * row.Amount;
			this.TotalAccijnzenIncVat += row.AccijnzenTax * row.Amount * (1+ row.Vat/100);
		}
		this.PaymentBeforeDiscount = this.TotalExcVAT - this.TotalBase;
		this.TotalExcVAT = roundTo8Decimals(this.TotalExcVAT);
		if (this.UseCreditRestrictionInsteadOfDiscountPercent) {
			this.TotalBase += this.TotalVAT;
			this.PaymentBeforeDiscount = this.TotalBase - (this.TotalExcVAT + this.TotalVAT);
		}
		this.TotalBase = roundTo8Decimals(this.TotalBase);
		this.TotalLeeggoed= roundTo8Decimals(this.TotalLeeggoed);
		this.TotalEcoboniExcVat = roundTo8Decimals(this.TotalEcoboniExcVat);
		this.TotalAccijnzenExcVat = roundTo8Decimals(this.TotalAccijnzenExcVat);
		this.TotalBuy = roundTo8Decimals(this.TotalBuy);
		this.TotalVAT = roundTo8Decimals(this.TotalVAT);
		this.TotalIncVAT = this.TotalExcVAT + this.TotalVAT;
		this.TotalToPay = this.TotalIncVAT + this.TotalLeeggoed;
	}

	public getLng(): string {
		if (this.Lng) {
			return this.Lng;
		}
		if (this.InvoiceAddress) {
			if (this.InvoiceAddress.Lng != "") {
				return this.InvoiceAddress.Lng;
			}
		}
		if (this.DeliveryAddress) {
			if (this.DeliveryAddress.Lng != "") {
				return this.DeliveryAddress.Lng;
			}
		}
		return "nl-be";
	}

	public getTypeName(locale: string): string {
		let type = this.Type;
		if (!type) return "";
		return type.Name.getTranslation(locale);
	}

	public getLayoutID(printContext:PrintContext): number {
		if (this.LayoutID) {
			return this.LayoutID;
		}
		let layoutIds = DossierConfigService.getSaleConfig(this.TypeID).getLayoutIds(printContext);
		if (layoutIds.length == 0) {
			return 0;
		}
		return layoutIds[0];
	}

	public getDefaultContactID(): number{
		return UserConfigService.getSaleSettings().getSaleConfig(this.TypeID)?.DefaultContactID || 0;
		//return DossierConfigService.getSaleConfig(this.TypeID).DefaultContactID || 0;
	}

	public showCashierRefundModal():boolean {
		if (this.ID != 0) {
			return false;
		}
		return DossierConfigService.getSaleConfig(this.TypeID).ShowTicketRefundDisplay || false;
	}

	public canBeSignedByClient(): boolean{
		return DossierConfigService.getSaleConfig(this.TypeID).CanBeSignedByClient || false;
	}

	public isSaldoDocument():boolean {
		return this.ComputedFriendlyID.charAt(0)=="-";
	}

	public getOriginalComputedFriendlyIDFromSaldoDocument():string{
		if (!this.isSaldoDocument()) {
			return this.ComputedFriendlyID;
		}
		return this.ComputedFriendlyID.substring(1);
	}

	public getProfit():number{
		return this.TotalBase - this.TotalBuy;
	}

	public canEdit():boolean {
		if (this.IsSigned && !UserConfigService.getSaleSettings().AllowEditOfSignedDocuments) return false;
		return true;
	}
}
