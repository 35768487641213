import { v4 } from "uuid";
import { SummaryCol } from "./summary-col";

export class SummaryRow {
	uid:string = v4();
	Cols:SummaryCol[] = [];

	public constructor(data?:any){
		if (!data) return;
		this.Cols = (data.Cols as any[] || []).map(d=>new SummaryCol(d));
	}

	public getJSON(){
		return {
			Cols: this.Cols.map(c=>c.getJSON())
		};
	}

	public getColCount():number{
		return this.Cols.length;
	}
}