import { DocumentPageSettings } from "../documents/page-settings";

export class SaleLayoutPageSettings extends DocumentPageSettings {
	HideBackgroundImageOnPrint:boolean = false;
	HideBackgroundImageOnEmail:boolean = false;
	HideBackgroundImageOnDownload:boolean = false;
	ShowHeaderOnlyOnFirstPage:boolean = false;
	DontPushTotalsToBottomOfPage:boolean = false;
	SplitDetailRows:boolean = false;

	public constructor(data?:any){
		super(data);
		if (!data) return;
		this.HideBackgroundImageOnDownload = data.HideBackgroundImageOnDownload;
		this.HideBackgroundImageOnEmail = data.HideBackgroundImageOnEmail;
		this.HideBackgroundImageOnPrint = data.HideBackgroundImageOnPrint;
		this.ShowHeaderOnlyOnFirstPage = data.ShowHeaderOnlyOnFirstPage;
		this.DontPushTotalsToBottomOfPage = data.DontPushTotalsToBottomOfPage;
		this.SplitDetailRows = data.SplitDetailRows;
	}

	public getJSON(){
		let result:any = super.getJSON();
		result.HideBackgroundImageOnPrint = this.HideBackgroundImageOnPrint;
		result.HideBackgroundImageOnEmail = this.HideBackgroundImageOnEmail;
		result.HideBackgroundImageOnDownload = this.HideBackgroundImageOnDownload;
		result.ShowHeaderOnlyOnFirstPage = this.ShowHeaderOnlyOnFirstPage;
		result.DontPushTotalsToBottomOfPage = this.DontPushTotalsToBottomOfPage;
		result.SplitDetailRows = this.SplitDetailRows;
		return result;
	}
}