// eslint-disable-next-line max-classes-per-file
import {ICashierDisplayConfiguration} from "@/plugins/cashier/cashdro";

export interface IButton {
	ProductID:number;
	Sku:string;
	Color:string;
	Text:string;

}
export interface IButtonConfig {
	GroupID:number;
	Buttons: IButton[]

}

export class CashierButtonConfig {
	ButtonConfigs: IButtonConfig[] = [];

	public constructor(data?: any) {
		if(!data){
			return;
		}
		this.ButtonConfigs = data.ButtonConfigs;
	}

	public getJSON(): any {
		return {
			ButtonConfigs: this.ButtonConfigs
		};
	}

}

export class CashierConfig {
	Displays:ICashierDisplayConfiguration[] = [];

	public constructor(data?: any) {
		if(!data){
			return;
		}
		this.Displays = data.Displays;

	}

	public getJSON(): any {
		return {
			Displays: this.Displays
		};
	}
}