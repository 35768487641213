
import { ProductGroup } from "@/models/base/product-group";
import { ProductGroupService } from "@/services/product-group-service";
import Vue from "vue";
import AppGroupSelectNode from "./ProductGroupSelectNode.vue";

export default Vue.extend({
	data(){
		return {
			topLevelGroups: [] as ProductGroup[],
		};
	},
	props: {
		multi: Boolean,
		useIds: Boolean,
		value: {
			type: [Object as ()=>ProductGroup, Array as ()=>ProductGroup[] | number[], Number]
		}
	},
	methods: {
		async onClick(group:ProductGroup){
			if (!this.multi){
				if (this.useIds){
					this.$emit("input", group);
				}else{
					this.$emit("input", group);
				}
				return;
			}

			if (!Array.isArray(this.value)) {
				return;
			}

			let value = this.value as any[];
			let toPushValue = group as any;
			if (this.useIds) {
				toPushValue = group.ID;
			}

			let index = value.indexOf(toPushValue);
			if (index == -1) {
				value.push(toPushValue);
			}else{
				value.splice(index, 1);
			}

			for (let i = value.length - 1; i >= 0; i--){
				if (typeof(value[i]) != "object"){
					try{
						await ProductGroupService.getGroupByID(value[i]);
					}catch(err){
						value.splice(i, 1);
					}
				}
			}

			this.$emit("input", this.value);
		}
	},
	async created(){
		this.topLevelGroups = await ProductGroupService.getTopLevelGroups();
	},
	components: {
		AppGroupSelectNode
	},
	i18nEx: {
		componentPrefix: "components.products"
	}
});
