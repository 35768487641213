
import Vue from "vue";
import { IModel } from "../../utils/models/model-interface";
import AppAskedFilterEdit from "./AskedFilterEdit.vue";
import { AskedFilter } from "../../utils/report-engine-settings/asked-filter";
import { ReportEngineSettings } from "../../utils/report-engine-settings/report-engine-settings";
import { TranslateResult } from "vue-i18n";
import { Browse } from "@/models/browse";

export default Vue.extend({
	data(){
		return {
			toEditFilter: null as null | AskedFilter,
			toEditFilterIndex: -1
		};
	},
	props: {
		value: {
			type: Object as ()=>ReportEngineSettings,
			required: true,
		},
		model: {
			type: Object as ()=> IModel,
			required: true,
		}
	},
	computed: {
		browse():Browse | null {
			return this.value.getBrowseIfBrowseDataSource();
		}
	},
	methods: {
		editFilter(i:number){
			this.toEditFilter = new AskedFilter(this.value.AskedFilters[i].getJSON());
			this.toEditFilterIndex = i;
		},
		removeFilter(i:number){
			this.value.AskedFilters.splice(i,1);
		},
		addFilter(){
			let filter = new AskedFilter();
			this.toEditFilter = filter;
			this.toEditFilterIndex = -1;
		},
		confirmEdit(){
			if (!this.toEditFilter) return;
			if (this.toEditFilterIndex == -1){
				this.value.AskedFilters.push(this.toEditFilter);
			}else{
				this.value.AskedFilters[this.toEditFilterIndex] = this.toEditFilter;
			}
			this.toEditFilter = null;
		},
		getFilterText(filter:AskedFilter):TranslateResult{
			return filter.getTranslatedText(this.model, this.browse);
		}
	},
	components: {
		AppAskedFilterEdit
	},
	i18nEx: {
		componentPrefix: "components.reportengine"
	}
});
