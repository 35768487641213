import { DashboardChartData } from "./dashboard-chart-data";
import { DashboardItemType } from "./dashboard-item";

export class DashboardRevenuePerMonthCompareData extends DashboardChartData {
	ToShowYears:number = 2;

	public constructor(data?:any, itemType?:DashboardItemType){
		super(data, itemType);
		if (!data) return;
		this.ToShowYears = parseInt(data.ToShowYears || "2");
		if (Number.isNaN(this.ToShowYears)){
			this.ToShowYears = 2;
		}
	}

	public getJSON(){
		return {
			...super.getJSON(),
			ToShowYears: `${this.ToShowYears}`
		};
	}
}