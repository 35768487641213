import { DocumentPageSettings } from "@/utils/documents/page-settings";
import { ReportHeading } from "@/utils/report-heading";

export class DefaultReportConfig {
	PrintDateOnEachPage: boolean;
	PrintPageNumbers: boolean;
	PageSettings: DocumentPageSettings;
	Heading: ReportHeading;

	constructor(data: any) {
		this.PrintDateOnEachPage = data.PrintDateOnEachPage;
		this.PrintPageNumbers = data.PrintPageNumbers;
		this.PageSettings = new DocumentPageSettings(data.PageSettings);
		this.Heading = new ReportHeading(data.Heading);
	}

	getJSON() {
		return {
			PrintDateOnEachPage: this.PrintDateOnEachPage,
			PrintPageNumbers: this.PrintPageNumbers,
			PageSettings: this.PageSettings.getJSON(),
			Heading: this.Heading.getJSON()
		};
	}
}