import { ReportEngineSettings } from "@/utils/report-engine-settings/report-engine-settings";
import { IReportEngineSettingsWrapper } from "@/utils/report-engine-settings/report-engine-settings-wrapper";
import { Job } from "./job";
import { ReportService } from "@/services/report-service";

export class ReportEngineSettingsModel implements IReportEngineSettingsWrapper {
	ID:number = 0;
	Settings:ReportEngineSettings = new ReportEngineSettings();
	Name:string = "";
	BrowseID:number = 0;

	constructor(data?:any) {
		if (!data) return;
		this.ID = data.ID;
		this.Settings = new ReportEngineSettings(data.Settings);
		this.Name = data.Name;
		this.BrowseID = data.BrowseID;
	}

	getJSON() {
		return {
			ID: this.ID,
			Settings: this.Settings.getJSON(),
			Name: this.Name,
			BrowseID: this.BrowseID
		};
	}

	async generate(pdfType: string, args:string[]): Promise<Job> {
		if (pdfType == "pdf"){
			return ReportService.generateCustomReport(this.Settings, args);
		}
		if (pdfType == "excel"){
			return ReportService.generateCustomReportExcel(this.Settings, args);
		}
		throw new Error("Invalid pdf type");
	}
}