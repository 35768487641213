
import AppCustomTable from "@/components/common/CustomTable.vue";
import { StockLocationConfig } from "@/models/dossier-configs/stock-location";
import { ProductStock } from "@/models/base";
import { DossierConfigService } from "@/services/dossier-config-service";
import { ProductStockService } from "@/services/product-stock-service";
import Vue from "vue";
import { Product } from "@/models/base";
import { ReportService } from "../../services/report-service";
import { BookYearService } from "@/services/book-year-service";

export default Vue.extend({
	data() {
		return {
			reportStart: new Date(),
			reportEnd: new Date(),
			reportModal: false,
			productStock: undefined as any | ProductStock,
		};
	},
	props: {
		product: {
			required: true,
			type: Object as () => Product
		},
		id: {
			required: true,
			type: Number,
		}
	},
	computed: {
		actualStock():any {
			if (!this.productStock) {
				return;
			}
			return this.stockLocationConfig.getSortedLocationNames().map((l: string, i: number) => {
				return {
					text: l,
					value: i + 1,
					stock: this.productStock["Location" + (i + 1) + "Qty"]
				} as any;
			});
		},
		totalStock():number{
			let totalStock = 0;
			this.stockLocationConfig.getSortedLocationNames().forEach((l: string, i: number) => {
				totalStock += this.productStock ? this.productStock["Location" + (i + 1) + "Qty"] as any : 0;
			});
			return totalStock;
		},
		stockLocationConfig():StockLocationConfig {
			return DossierConfigService.getStockLocationConfig();
		}
	},
	methods: {
		async openStockReportModal() {
			let bookyearStartMonth = DossierConfigService.getGeneralConfig().BookYearStartMonth;
			this.reportStart = new Date();
			this.reportStart.setFullYear(new Date().getFullYear(), bookyearStartMonth, 1);
			this.reportEnd = new Date();
			this.reportEnd.setFullYear(new Date().getFullYear(), bookyearStartMonth + 11 , 31);
			this.reportModal = true;
		},
		async downloadStockMovement() {
			let reports = await ReportService.getReportEngineSettingsByBrowseId(4);
			let settings = reports.staticReports.find(r=>r.Settings.DataSource == "standard-script/stock-movement");
			if (!settings){
				throw new Error("Missing report settings");
			}
			if (!this.reportStart || !this.reportEnd){
				throw new Error("Invalid dates");
			}
			let dateRange = JSON.stringify({start: this.reportStart, end: this.reportEnd});
			let sku = JSON.stringify([this.product.Sku || ""]);
			await ReportService.generateCustomReport(settings.Settings, [dateRange, `${BookYearService.selectedBookYear}`, sku]);
			this.reportModal = false;
		}
	},
	async mounted() {
		this.productStock = await ProductStockService.getStocksByIds([this.id]);
	},
	components: {
		AppCustomTable
	},
	i18nEx: {
		componentPrefix: "components.products"
	}
});
