import { ServerConfig } from "@/config/config";
import { Axios } from "@/utils/axios";

export class ScriptServiceClass {
	private readonly url:string = `${ServerConfig.host}/scripts`;

	async runScript(scriptId:number, args:string[]):Promise<string>{
		let result = await Axios.post(`${this.url}/${scriptId}/run`, args);
		return result.data;
	}
}

export const ScriptService = new ScriptServiceClass();