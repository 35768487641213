import { TranslateResult } from "vue-i18n";
import { IConditionableField } from "../condition/conditionable-field";
import { ModelPropertyType } from "../models/model-field";

export class PropSelectItem implements IConditionableField {
	text:string | TranslateResult;
	value:string;
	type:ModelPropertyType;
	filterValueInput?:string;

	public constructor(text:string | TranslateResult, value:string, type:ModelPropertyType = "string", filterValueInput?:string){
		this.text = text;
		this.value = value;
		this.type = type;
		this.filterValueInput = filterValueInput;
	}
}