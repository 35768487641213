export class MailConfig {
	public UserName: string="";
	public Host: string="";
	public Port: number=0;
	public Encryption: string="";
	public Password: string="";

	constructor(data: any) {
		if (!data) return;
		this.UserName = data.username || "";
		this.Host = data.host;
		this.Port = parseInt(data.port);
		this.Encryption = data.encryption;
		this.Password = "";
	}

}
