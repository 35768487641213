
import Vue from "vue";
import AppProductSkuAutocomplete from "@/components/products/ProductSkuAutocomplete.vue";
import { Product } from "@/models/base";
import { ProductService } from "@/services/product-service";

export default Vue.extend({
	data(){
		return {
			product: null as null | Product
		};
	},
	props: {
		label: String,
		value: {
			type: String,
			required: true
		}
	},
	watch: {
		product: {
			handler(){
				if (!this.product) {
					this.$emit("input", "");
				}else{
					this.$emit("input", this.product.Sku);
				}
			}
		}
	},
	async created(){
		if (ProductService.selectedProducts.length > 0){
			this.product = await ProductService.getProduct(ProductService.selectedProducts[0].ID);
		}
	},
	components: {
		AppProductSkuAutocomplete
	}
});
