import { ignoredFields, model } from "@/utils/models/decorator-model";
import { LedgerAccount } from "../ledger-account";
import { Purchase } from "@/models/base/index";
import { decimals, field, nullable, rightAlign } from "@/utils/models/decorator-field";


@model("PurchaseLedger")
@ignoredFields([
	"Purchase.Contact.MainAddress.Contact",
	"Purchase.LastBank.Invoice",
	"Purchase.LastBank.Purchase",
	"Purchase.LastBank.FriendlyPurchaseID",
	"Purchase.LastBank.PurchaseID",
	"Purchase.LastBank.Contact",
	"Purchase.LastBank.Ticket"
])
export class PurchaseLedger {

	@field("number")
	public ID:number = 0;

	@field("number")
	public PurchaseID:number = 0

	@field("string")
	public LedgerCode:string = ""

	@field("number")
	@decimals(2)
	@rightAlign()
	public Amount:number = 0;

	@field("number")
	@decimals(2)
	@rightAlign()
	public VatPercent:number = 0;

	@field("string")
	public Comment:string = "";

	@field("number")
	public BookYear:number = 0;

	@field("has-one", "LedgerAccount")
	@nullable()
	public LedgerAccount:LedgerAccount | null = null;

	@field("has-one", "Purchase")
	@nullable()
	public Purchase:Purchase | null = null;

	public constructor(data?:any) {
		if (!data) return;
		this.ID = data.ID;
		this.PurchaseID = data.PurchaseID;
		this.LedgerCode = data.LedgerCode;
		this.Amount = data.Amount;
		this.VatPercent = data.VatPercent;
		this.Comment = data.Comment;
		this.BookYear = data.BookYear;
		if (data.LedgerAccount){
			this.LedgerAccount = new LedgerAccount(data.LedgerAccount);
		}
		if(data.Purchase){
			this.Purchase = new Purchase(data.Purchase);
		}
	}

	public getJSON(): any{
		return {
			ID: this.ID,
			PurchaseID: this.PurchaseID,
			LedgerCode: this.LedgerCode,
			Amount: this.Amount,
			VatPercent: this.VatPercent,
			Comment: this.Comment,
			BookYear: this.BookYear,
			LedgerAccount: this.LedgerAccount?.getJSON(),
			Purchase: new Purchase(this.Purchase?.getJSON())
		};
	}
}