
import { IModel } from "@/utils/models/model-interface";
import { ModelNamedProperty } from "@/utils/models/named-property";
import { getValidOperatorsForFieldType, HumanFilter } from "@/utils/human-filter";
import Vue from "vue";
import { ISelectItem } from "winfakt-vue-components";
import AppColumnSelect from "./ColumnSelect.vue";
import AppCustomFilterSelector from "./CustomFilterSelector.vue";
import AppHumanFilterEditValue from "./HumanFilterEditValue.vue";
import { Browse } from "@/models/browse";

export default Vue.extend({
	props: {
		value: {
			type: Object as ()=>HumanFilter,
			required: true
		},
		model: {
			type: Object as ()=>IModel,
			required: true
		},
		browse: Object as ()=>Browse,
		removeIsOrOption: Boolean
	},
	computed: {
		selectedField(): ModelNamedProperty | undefined {
			return this.model.getFilterField(this.value.Field);
		},
		isNamedFilter():boolean {
			return this.model.getAvailableNamedFilters().findIndex(f=>f.name == this.value.Field) != -1;
		},
		operators(): ISelectItem<string>[] {
			if (!this.selectedField) return [];
			let validOperators = getValidOperatorsForFieldType(this.selectedField.field);
			return validOperators.map((op) => {
				return {
					text: this.$t("common.filters." + HumanFilter.getTranslationOperator(op)).toString(),
					value: op
				};
			});
		},
		valueAmount(): number {
			return this.value.getValueAmount();
		},
		fieldType():string {
			return this.value.getFilterValueType(this.model, this.browse);
		},
		isBoolean():boolean {
			return this.fieldType == "boolean";
		}
	},
	watch: {
		valueAmount(newVal: number, oldVal: number) {
			if (oldVal == null) {
				return;
			}
			if (newVal == oldVal) {
				return;
			}
			this.createFields();
		},
		"selectedField.field.type"(newVal: string, oldVal: string) {
			if (oldVal == null) {
				return;
			}
			if (newVal == oldVal) {
				return;
			}
			this.createFields();
		},
		isBoolean: {
			immediate: true,
			handler(){
				if (this.isBoolean) {
					this.value.Operator = "=";
				}
			}
		}
	},
	methods: {
		createFields() {
			this.value.Values = [];
			for (let i = 0; i < this.valueAmount; i++) {
				this.value.Values.push("");
			}
			if (this.valueAmount == -1) {
				this.value.Values = [""];
			}
		},
		addValue() {
			this.value.Values.push("");
		},
		removeValue(i: number) {
			this.value.Values.splice(i, 1);
		},
		onField(val:string){
			this.value.Field = val;
			this.value.Options = [];
		}
	},
	mounted() {
		if (this.value.Field != "") {
			return;
		}
		this.createFields();
	},
	components: {
		AppColumnSelect,
		AppCustomFilterSelector,
		AppHumanFilterEditValue
	},
	i18nEx: {
		componentPrefix: "components.models"
	}
});

