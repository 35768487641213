export class ElectronCtepSettings {
	bankId:number = 0;
	enabled:boolean = false;
	mode:"tcp" | "serial" | "connect" = "serial";
	port:number = 9000;
	comPort:string = "";
	connectUrl:string = ""
	selfCertificationModeEnabled:boolean = false;
	openSendTicketAfterPaid:boolean = false;

	public constructor(data?:any){
		if (!data) return;
		this.bankId = data.bankId;
		this.enabled = data.enabled;
		this.mode = data.mode;
		this.port = data.port | 9000;
		this.comPort = data.comPort;
		this.connectUrl = data.connectUrl || "";
		this.selfCertificationModeEnabled = data.selfCertificationModeEnabled;
		this.openSendTicketAfterPaid = data.openSendTicketAfterPaid || false;
	}

	public getJSON(){
		return {
			bankId: this.bankId,
			enabled: this.enabled,
			mode: this.mode,
			port: this.port,
			comPort: this.comPort,
			connectUrl: this.connectUrl,
			selfCertificationModeEnabled: this.selfCertificationModeEnabled,
			openSendTicketAfterPaid: this.openSendTicketAfterPaid
		};
	}
}