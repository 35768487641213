import { PlanModule } from "@/utils/payments/plan-module";

export interface IModuleMetaData {
	[key:string]:string
}

export class Module {
	public ID:number;
	public Name:string;
	public Price:number;
	public PermanentPrice:number;
	public MetaData:IModuleMetaData = {};

	public constructor(data:any, metadata?:IModuleMetaData){
		this.ID = data.ID;
		this.Name = data.Name;
		this.Price = data.Price;
		this.PermanentPrice = data.PermanentPrice;
		if (metadata){
			this.MetaData = metadata;
		}
	}



	public toPlanModule():PlanModule{
		return new PlanModule({ModuleID: this.ID,MetaData: this.MetaData});
	}
}