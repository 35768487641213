import { GoTemplateDataArray } from "@/utils/go-template-data-structure/array";
import { GoTemplateDataObject } from "@/utils/go-template-data-structure/object";
import { GoTemplateDataProperty } from "@/utils/go-template-data-structure/property";
import { ISelectItem } from "winfakt-vue-components";
import { DisplayedPropertyType, IDisplayedProp } from "./root";

export class PropertyNode {
	children:PropertyNode[] = [];
	beginIndex:number = 0;
	endIndex:number = 0;
	availableProperties:ISelectItem<IDisplayedProp>[] = [];
	type:DisplayedPropertyType = "text";

	getBestNode(index:number):PropertyNode {
		for (let node of this.children) {
			if (node.beginIndex < index && node.endIndex > index) {
				return node.getBestNode(index);
			}
		}
		return this;
	}
}

function getProperties(property:GoTemplateDataProperty):ISelectItem<IDisplayedProp>[] {
	if (property.type == "prop") {
		return [
			{
				text: property.getDisplayedName(),
				value: {
					value: `{{${property.getPath()}}}`,
					type: property.getNodeType(),
					args: property.getNodeArgs()
				} as IDisplayedProp
			}
		];
	}
	if (property.type == "object") {
		return (property as GoTemplateDataObject).properties.map(getProperties).reduce(function(val, currentVal) {
			currentVal.push(...val);
			return currentVal;
		}, []);
	}
	if (property.type == "array") {
		let prop = property as GoTemplateDataArray;
		return [
			{
				text: property.getDisplayedName(),
				value: {
					value: `{{range $i, $${prop.alias} := ${prop.getParentPath()}}}\n\n{{end}}`,
					type: "text",
					args: []
				}
			}
		];
	}
	return [];
}

// eslint-disable-next-line max-lines-per-function
export function convertTextToNode(text:string, properties:GoTemplateDataProperty[]):PropertyNode {
	let rootNode = new PropertyNode();
	rootNode.beginIndex = 0;
	rootNode.endIndex = text.length - 1;
	rootNode.availableProperties = properties.map(getProperties).reduce(function(val, currentVal) {
		currentVal.push(...val);
		return currentVal;
	}, []);

	let expression = /\{\{[^\{\}]*\}\}/g;
	let regexResult = expression.exec(text);

	let roots = [
		rootNode
	];

	let parentPropertyLists = [
		[...properties]
	];

	while(regexResult != null) {
		let node = new PropertyNode();
		node.beginIndex = regexResult.index;
		node.endIndex = regexResult.index + regexResult[0].length - 1;
		node.availableProperties = [];

		if (/^\{\{\s*end\s*\}\}/.test(regexResult[0])) {
			let el = roots.pop();
			if (el) {
				el.endIndex = regexResult.index;
			}
			parentPropertyLists.pop();
			regexResult = expression.exec(text);
			continue;
		} else {
			let parentNode = roots[roots.length - 1];
			parentNode.children.push(node);
		}

		if (/^\{\{\s*range/.test(regexResult[0])) {
			roots.push(node);
			node.beginIndex = node.endIndex;
			let matches = regexResult[0].match(/:=.*\}\}/);
			if (!matches) {
				throw new Error("Invalid key at position " + node.beginIndex);
			}
			let propName = matches[0].replace(":=", "").replace(" ", "").replace("}}", "");

			let property = null as null | GoTemplateDataArray;
			for (let i = parentPropertyLists.length - 1; i >= 0; i--) {
				for (let c of parentPropertyLists[i]) {
					if (c.type != "array") {
						continue;
					}
					if ((c as GoTemplateDataArray).getParentPath() == propName) {
						property = c as GoTemplateDataArray;
						break;
					}
				}
				if (property) {
					break;
				}
			}
			if (!property) {
				throw new Error(`Property '${propName}' not found at position ${node.beginIndex}`);
			}


			parentPropertyLists.push([...property.properties]);

			let nodeProps = parentPropertyLists.reduce(function(prev, val) {
				val.push(...prev);
				return val;
			}, []);
			node.availableProperties = nodeProps.map(p => {
				return getProperties(p);
			}).reduce(function(val, currentVal) {
				return currentVal.concat(val);
			}, []);
		}


		regexResult = expression.exec(text);
	}

	return rootNode;
}