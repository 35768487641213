import { ServerConfig } from "@/config/config";
import { Bank } from "@/models/base";
import { Axios } from "@/utils/axios";
import Vue from "vue";

export interface ICodaImportResult {
	Banks:Bank[];
	BeginBalance:number;
	EndBalance:number;
	AccountNumber: string;
}

export const CodaService = new Vue({
	data() {
		return {};
	},
	computed: {
		url(): string {
			return `${ServerConfig.host}/coda`;
		}
	},
	methods: {
		async postCoda(file:File, bankId:number):Promise<ICodaImportResult> {
			let data = new FormData();
			data.append("bank-id", `${bankId}`);
			data.append("file", file, file.name);
			let response = await Axios.post(`${this.url}/parse`, data);
			let result = response.data as ICodaImportResult;
			result.Banks = result.Banks.map((b:any)=>new Bank(b));
			return result;
		},
	},
});