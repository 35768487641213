
import Vue from "vue";
import { SaleLayoutPageSettings } from "../../utils/sale-layouts/page-settings";

export default Vue.extend({
	data() {
		return {
			HideBackgroundImageOnPrint: false,
			HideBackgroundImageOnEmail: false,
			HideBackgroundImageOnDownload: false,
		};
	},
	props: {
		value: {
			type: Object as () => SaleLayoutPageSettings,
			required: true
		}
	},
	watch: {
		logo: {
			immediate: true,
			handler() {
				this.HideBackgroundImageOnPrint = this.value.HideBackgroundImageOnPrint;
				this.HideBackgroundImageOnEmail = this.value.HideBackgroundImageOnEmail;
				this.HideBackgroundImageOnDownload = this.value.HideBackgroundImageOnDownload;
			}
		}
	},
	methods: {
		submit() {
			this.value.HideBackgroundImageOnPrint = this.HideBackgroundImageOnPrint;
			this.value.HideBackgroundImageOnEmail = this.HideBackgroundImageOnEmail;
			this.value.HideBackgroundImageOnDownload = this.HideBackgroundImageOnDownload;
			console.log(this.value);
			this.close();
		},
		close() {
			this.$emit("close");
		}
	},
	i18nEx: {
		componentPrefix: "components.sale-layout-editor"
	}
});
