
import Vue from "vue";
import AppContactSearch from "@/components/contacts/ContactSearch.vue";
import { Contact } from "@/models/base";
import { AllContactsService } from "@/services/all-contacts-service";

export default Vue.extend({
	data(){
		return {
			contact: null as null | Contact
		};
	},
	props: {
		label: String,
		value: {
			type: String,
			required: true
		},
		groupId: Number
	},
	watch: {
		value: {
			async handler(){
				if (!this.value) {
					this.contact = null;
				}else{
					this.contact = await AllContactsService.getContactByID(parseInt(this.value));
				}
			}
		}
	},
	methods: {
		onContact(value:Contact){
			this.contact = value;
			this.$emit("input", `${value.ID}`);
		}
	},
	components: {
		AppContactSearch
	}
});
