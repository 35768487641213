import { TextStyledProperty } from "./text-styled-property";
import { CustomTranslationWithoutFallback } from "../custom-translation-without-fallback";
export class TranslatedStyledProperty {
	public Value: CustomTranslationWithoutFallback = new CustomTranslationWithoutFallback();
	public Style: TextStyledProperty = new TextStyledProperty();
	constructor(data?: any) {
		if (!data) {
			return;
		}
		this.Value = new CustomTranslationWithoutFallback(data.Value);
		this.Style = new TextStyledProperty(data.Style);
	}
	public getJSON() {
		return {
			Value: this.Value.getJSON(),
			Style: this.Style.getJSON(),
		};
	}

	public setText(text:string) {
		this.Value.setText(text);
	}
}
