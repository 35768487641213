export class DossierConfig {
	SettingsKey:string;
	Value:{getJSON():any};

	constructor(key:string, value:any) {
		this.SettingsKey = key;
		this.Value = value;
	}
}

