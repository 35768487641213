
import Vue from "vue";
import { HumanFilter, HumanFilterOperation } from "@/utils/human-filter";
import { v4 } from "uuid";
import { ISelectItem } from "winfakt-vue-components";

export default Vue.extend({
	data(){
		return {
			id: v4(),
			numValue: 0
		};
	},
	props: {
		value: {
			type: Object as ()=>HumanFilter,
			required: true
		},
	},
	computed: {
		allowedOperators():HumanFilterOperation[]{
			return ["=", ">", "<", ">=", "<=", "!="];
		},
		operators():ISelectItem<HumanFilterOperation>[]{
			return this.allowedOperators.map(v=>{
				return {
					text: this.$t("common.filters." + HumanFilter.getTranslationOperator(v)).toString(),
					value: v
				} as ISelectItem<HumanFilterOperation>;
			});
		}
	},
	watch: {
		"value.Operator": {
			immediate: true,
			handler(){
				if (this.allowedOperators.indexOf(this.value.Operator) == -1){
					this.value.Operator = this.allowedOperators[0];
				}
			}
		},
	},
	methods: {
		onInputValue(value:number){
			this.numValue = value;
			this.value.Values[0] = `${value}`;
		}
	},
	mounted(){
		if (this.value.Values[0]){
			this.numValue = parseFloat(this.value.Values[0]);
			if (Number.isNaN(this.numValue)){
				this.numValue == 0;
			}
		}
		if (this.value.Values.length > 1) {
			this.value.Values.splice(1);
		}
	},
	i18nEx: {
		componentPrefix: "components.models"
	}
});
