import { i18n } from "@/setup/i18n-setup";

export interface ICountry {
	code: string;
	name: string;
}


interface countryMap {
	// key is country code
	// value is country name
	[key:string]:string;
}

interface countryLangMap {
	// key is lang
	[key:string]:countryMap;
}

export class CountryIndex {
	private static countries:countryLangMap = {};
	private constructor(){}

	public static getCountryName(code:string, lng:string = i18n.locale):string{
		if (CountryIndex.countries[lng] == null){
			let list = require(`@/assets/json/countries/${lng}.json`) as ICountry[];
			CountryIndex.countries[lng] = {};
			list.forEach(c=>{
				CountryIndex.countries[lng][c.code] = c.name;
			});
		}
		return CountryIndex.countries[lng][code];
	}
}