
import Vue from "vue";
export default Vue.extend({
	props: {
		to: String,
		disabled: Boolean
	},
	methods: {
		onClick() {
			this.$emit("click");
		}
	}
});
