
import { DossierConfigService } from "@/services/dossier-config-service";
import { SellCategory } from "@/utils/sell-category";
import Vue from "vue";
import { TranslateResult } from "vue-i18n";
import { ISelectItem } from "winfakt-vue-components";
export default Vue.extend({
	props: {
		value: {
			type: [Object as () => SellCategory, Number]
		},
		useNumber: Boolean,
		label: [String, Object as () => TranslateResult],
		fancy: Boolean
	},
	computed: {
		sellCategories():SellCategory[] {
			return DossierConfigService.getSellCategories().getCategories();
		},
		items():ISelectItem<SellCategory>[] {
			return this.sellCategories.filter(c => c.Active).map((c, i) => {
				return {
					text: c.Name || this.$ct("price-cat", {i: i+1}),
					value: c
				} as ISelectItem<SellCategory>;
			});
		},
		computedValue():SellCategory {
			if (typeof(this.value) == "number") {
				if (this.value == 0) {
					return this.sellCategories[0];
				}
				return this.sellCategories[this.value - 1];
			}
			return this.value;
		}
	},
	methods: {
		onInput(value:SellCategory) {
			if (this.useNumber) {
				return this.$emit("input", this.sellCategories.indexOf(value) + 1);
			}
			this.$emit("input", value);
		}
	},
	i18nEx: {
		componentPrefix: "components.SellPriceCategorySelect"
	}
});
