
import { Product } from "@/models/base";
import { ProductIntrastatData } from "@/models/base/product-intrastat-data";
import Vue from "vue";

export default Vue.extend({
	props: {
		product: {
			type: Object as ()=>Product,
			required: true
		}
	},
	computed: {
		data():ProductIntrastatData{
			if (!this.product.IntrastatData) return new ProductIntrastatData();
			return this.product.IntrastatData;
		}
	},
	watch: {
		"product.IntrastatData": {
			immediate: true,
			handler(){
				if (!this.product.IntrastatData) {
					this.product.IntrastatData = new ProductIntrastatData();
				}
			}
		}
	},
	i18nEx: {
		componentPrefix: "components.products.intrastat"
	}
});
