
import Vue from "vue";
import { ReportEngineSettings } from "../../utils/report-engine-settings/report-engine-settings";
import { IModel } from "../../utils/models/model-interface";
import { Browse } from "@/models/browse";
import AppHumanFilterListEdit from "@/components/models/HumanFilterListEdit.vue";

export default Vue.extend({
	props: {
		value: {
			type: Object as () => ReportEngineSettings,
			required: true
		},
		model: {
			type: Object as () => IModel,
			required: true
		}
	},
	computed: {
		browse():Browse | null {
			return this.value.getBrowseIfBrowseDataSource();
		}
	},
	components: {
		AppHumanFilterListEdit
	},
	i18nEx: {
		componentPrefix: "components.reportengine"
	}
});
