export class StockScannerConfig {
	CalculateLocations:number[] = [];

	constructor(data:any) {
		this.CalculateLocations = data.CalculateLocations || [];
	}

	getJSON() {
		return {
			...this
		};
	}

}