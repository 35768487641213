import { ServerConfig } from "@/config/config";
import { UserInfo } from "@/models/dossier/user-info";
import { Axios } from "@/utils/axios";
import { DossierService } from "./dossier-service";
import { AuthService } from "./auth-service";
import { BillingAddress } from "@/models/billing-address";

export class CompanyServiceClass{
	readonly url:string = `${ServerConfig.auth}/company`;
	readonly varsUrl:string = `${this.url}/vars`

	async getUsers():Promise<UserInfo[]>{
		let result = await Axios.get(`${this.url}/users`);
		return result.data.map((d:any)=>new UserInfo(d));
	}

	async getUser(id:number):Promise<UserInfo>{
		let result = await Axios.get(`${this.url}/users/${id}`);
		return new UserInfo(result.data);
	}

	async createUser(user:UserInfo, password:string):Promise<UserInfo>{
		let result = await Axios.post(`${this.url}/users`, {User: user.getJSON(), Password: password});
		return new UserInfo(result.data);
	}

	async updateUser(user:UserInfo, password:string = ""):Promise<UserInfo>{
		let result = await Axios.put(`${this.url}/users/${user.ID}`, {User: user.getJSON(), Password: password});
		let u = new UserInfo(result.data);
		DossierService.fetchUsers();
		return u;
	}

	async setUserProfileImg(user:UserInfo, img:File):Promise<UserInfo>{
		let data = new FormData();
		data.set("file", img, img.name);
		let result = await Axios.post(`${this.url}/users/${user.ID}/set-profile-img`, data);
		return new UserInfo(result.data);
	}

	async deleteUser(user:UserInfo):Promise<void>{
		await Axios.delete(`${this.url}/users/${user.ID}`);
	}

	async isWorkspaceNameAvailable(workspaceName:string):Promise<boolean>{
		await Axios.get(`${this.url}/settings/is-workspace-available/${workspaceName}`);
		return true;
	}
	async setWorkspaceName(workspaceName:string):Promise<string>{
		let result = await Axios.post(`${this.url}/settings/set-workspace-url/${workspaceName}`);
		return result.data;
	}

	async getBillingAddress():Promise<BillingAddress>{
		let res = await Axios.get(`${this.url}/invoice-address`);
		return new BillingAddress(res.data);
	}

	async setBillingAddress(address:BillingAddress):Promise<BillingAddress>{
		let res = await Axios.post(`${this.url}/invoice-address`, address.getJSON());
		await AuthService.getUserInfo();
		return new BillingAddress(res.data);
	}


	async setVisitedPricingPage():Promise<void>{
		await Axios.post(`${this.varsUrl}/visited-pricing-page`);
	}

};

export const CompanyService = new CompanyServiceClass();