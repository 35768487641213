import { v4 } from "uuid";
import { HumanFilter } from "./human-filter";

export class HumanFilterNode{
	readonly key:string = v4();
	filter:null | HumanFilter = null;
	children:HumanFilterNode[] = [];
	isMainNode:boolean = false;
	isOr:boolean = false;

	get isGroup():boolean{
		return this.filter == null;
	}

	get debugName():string {
		if (this.isMainNode) {
			return "MAIN";
		}
		if (this.filter){
			return this.filter.getText();
		}
		return "GROUP";
	}

	public static fromFilters(filters:HumanFilter[]):HumanFilterNode {
		let result = new HumanFilterNode();
		let currentNodes = [result];
		for (let filter of filters){
			let node = new HumanFilterNode();
			node.filter = new HumanFilter(filter.getJSON());
			node.isOr = node.filter.IsOr;
			let lowestNode = currentNodes[currentNodes.length-1];
			for (let i = 0; i < filter.StartWhereGroup; i++) {
				let group = new HumanFilterNode();
				group.isOr = node.isOr;
				lowestNode.children.push(group);
				currentNodes.push(group);
				lowestNode = group;
			}
			lowestNode.children.push(node);
			for (let i = 0; i < filter.CloseWhereGroup; i++) {
				if (lowestNode == result) {
					continue;
				}
				currentNodes.pop();
				continue;
			}
		}
		return result;
	}

	public toFilters():HumanFilter[] {
		if (this.filter) {
			this.filter.StartWhereGroup = 0;
			this.filter.CloseWhereGroup = 0;
			let filter = new HumanFilter(this.filter.getJSON());
			filter.IsOr = this.isOr;
			return [filter];
		}
		let result:HumanFilter[] = [];
		for (let child of this.children){
			let filters = child.toFilters();
			if (filters.length == 0) continue;
			if (child.isGroup) {
				filters[0].StartWhereGroup++;
				filters[filters.length - 1].CloseWhereGroup++;
			}
			result.push(...filters);
		}
		if (result.length > 0) {
			result[0].IsOr = this.isOr;
		}
		return result;
	}
}