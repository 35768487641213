import { BookYear } from "@/models/book-year";
import { HumanFilter } from "../human-filter";
import { i18n } from "@/setup/i18n-setup";
import { BookYearService } from "@/services";

export class DashboardYearOverviewPerMonthData {
	public bookYearOverride:string = BookYear.SelectedBookYearValue;
	public title:string = ""
	public incomeFilters:HumanFilter[] = [];
	public costFilters:HumanFilter[] = [];

	public constructor(data?:any){
		if (!data) {
			this.incomeFilters = [
				new HumanFilter({
					Field: "this.TypeID",
					Operator: "in",
					Values: ["1","2"]
				}),
				new HumanFilter({
					Field: "this.ComputedFriendlyID",
					Operator: "!^",
					Values: ["-"]
				})
			];
			this.costFilters = [
				new HumanFilter({
					Field: "this.ComputedFriendlyID",
					Operator: "!^",
					Values: ["-"]
				})
			];
			this.title = i18n.t("components.dashboard.default-titles.year-overview-per-month").toString();
			return;
		}
		if (typeof(data.incomeFilters) == "object") {
			data.incomeFilters = JSON.stringify(data.incomeFilters);
		}
		if (typeof(data.costFilters) == "object") {
			data.costFilters = JSON.stringify(data.costFilters);
		}
		this.bookYearOverride = data.bookYearOverride || BookYear.SelectedBookYearValue;
		this.incomeFilters = (JSON.parse(data.incomeFilters || "[]") || []).map((f:any)=>new HumanFilter(f));
		this.costFilters = (JSON.parse(data.costFilters || "[]") || []).map((f:any)=>new HumanFilter(f));
		this.title = data.title;
	}

	getJSON(){
		return {
			bookYearOverride: this.bookYearOverride,
			incomeFilters: JSON.stringify(this.incomeFilters.map(f=>f.getJSON())),
			costFilters: JSON.stringify(this.costFilters.map(f=>f.getJSON())),
			title: this.title
		};
	}


	public getBookYearOverrideValue():number | undefined {
		if (this.bookYearOverride == BookYear.PreviousBookYearValue){
			return BookYearService.selectedBookYear - 1;
		}
		if (this.bookYearOverride == BookYear.SelectedBookYearValue){
			return undefined;
		}
		return parseInt(this.bookYearOverride);
	}
}