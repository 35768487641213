import { DossierConfigService } from "@/services";
import { field, hidden, nullable } from "@/utils/models/decorator-field";
import { model } from "@/utils/models/decorator-model";
import { isSameDay } from "date-fns";


function getNullableDateFromData(date:any):null | Date {
	if (!date) return null;
	return new Date(date);
}

function getJsonDateFromNullableDate(date:null | Date) {
	if (!date) return null;
	return date.toJSON();
}

@model("ProductPriceCategorySalesDates")
export class ProductPriceCategorySalesDates {
	@field("number")
	@hidden()
	ProductID:number= 0;

	@field("date")
	@nullable()
	public PriceCategory1StartDate:Date | null = null;
	@field("date")
	@nullable()
	public PriceCategory1EndDate:Date | null = null;

	@field("date")
	@nullable()
	public PriceCategory2StartDate:Date | null = null;
	@field("date")
	@nullable()
	public PriceCategory2EndDate:Date | null = null;

	@field("date")
	@nullable()
	public PriceCategory3StartDate:Date | null = null;
	@field("date")
	@nullable()
	public PriceCategory3EndDate:Date | null = null;

	@field("date")
	@nullable()
	public PriceCategory4StartDate:Date | null = null;
	@field("date")
	@nullable()
	public PriceCategory4EndDate:Date | null = null;

	@field("date")
	@nullable()
	public PriceCategory5StartDate:Date | null = null;
	@field("date")
	@nullable()
	public PriceCategory5EndDate:Date | null = null;

	@field("date")
	@nullable()
	public PriceCategory6StartDate:Date | null = null;
	@field("date")
	@nullable()
	public PriceCategory6EndDate:Date | null = null;

	@field("date")
	@nullable()
	public PriceCategory7StartDate:Date | null = null;
	@field("date")
	@nullable()
	public PriceCategory7EndDate:Date | null = null;

	@field("date")
	@nullable()
	public PriceCategory8StartDate:Date | null = null;
	@field("date")
	@nullable()
	public PriceCategory8EndDate:Date | null = null;

	@field("date")
	@nullable()
	public PriceCategory9StartDate:Date | null = null;
	@field("date")
	@nullable()
	public PriceCategory9EndDate:Date | null = null;

	@field("date")
	@nullable()
	public PriceCategory10StartDate:Date | null = null;
	@field("date")
	@nullable()
	public PriceCategory10EndDate:Date | null = null;


	public constructor(data?:any){
		if (!data) return;
		this.ProductID = data.ProductID;
		this.PriceCategory1StartDate = getNullableDateFromData(data.PriceCategory1StartDate);
		this.PriceCategory1EndDate = getNullableDateFromData(data.PriceCategory1EndDate);
		this.PriceCategory2StartDate = getNullableDateFromData(data.PriceCategory2StartDate);
		this.PriceCategory2EndDate = getNullableDateFromData(data.PriceCategory2EndDate);
		this.PriceCategory3StartDate = getNullableDateFromData(data.PriceCategory3StartDate);
		this.PriceCategory3EndDate = getNullableDateFromData(data.PriceCategory3EndDate);
		this.PriceCategory4StartDate = getNullableDateFromData(data.PriceCategory4StartDate);
		this.PriceCategory4EndDate = getNullableDateFromData(data.PriceCategory4EndDate);
		this.PriceCategory5StartDate = getNullableDateFromData(data.PriceCategory5StartDate);
		this.PriceCategory5EndDate = getNullableDateFromData(data.PriceCategory5EndDate);
		this.PriceCategory6StartDate = getNullableDateFromData(data.PriceCategory6StartDate);
		this.PriceCategory6EndDate = getNullableDateFromData(data.PriceCategory6EndDate);
		this.PriceCategory7StartDate = getNullableDateFromData(data.PriceCategory7StartDate);
		this.PriceCategory7EndDate = getNullableDateFromData(data.PriceCategory7EndDate);
		this.PriceCategory8StartDate = getNullableDateFromData(data.PriceCategory8StartDate);
		this.PriceCategory8EndDate = getNullableDateFromData(data.PriceCategory8EndDate);
		this.PriceCategory9StartDate = getNullableDateFromData(data.PriceCategory9StartDate);
		this.PriceCategory9EndDate = getNullableDateFromData(data.PriceCategory9EndDate);
		this.PriceCategory10StartDate = getNullableDateFromData(data.PriceCategory10StartDate);
		this.PriceCategory10EndDate = getNullableDateFromData(data.PriceCategory10EndDate);
	}

	public getJSON(){
		return {
			ProductID: this.ProductID,
			PriceCategory1StartDate: getJsonDateFromNullableDate(this.PriceCategory1StartDate),
			PriceCategory1EndDate: getJsonDateFromNullableDate(this.PriceCategory1EndDate),
			PriceCategory2StartDate: getJsonDateFromNullableDate(this.PriceCategory2StartDate),
			PriceCategory2EndDate: getJsonDateFromNullableDate(this.PriceCategory2EndDate),
			PriceCategory3StartDate: getJsonDateFromNullableDate(this.PriceCategory3StartDate),
			PriceCategory3EndDate: getJsonDateFromNullableDate(this.PriceCategory3EndDate),
			PriceCategory4StartDate: getJsonDateFromNullableDate(this.PriceCategory4StartDate),
			PriceCategory4EndDate: getJsonDateFromNullableDate(this.PriceCategory4EndDate),
			PriceCategory5StartDate: getJsonDateFromNullableDate(this.PriceCategory5StartDate),
			PriceCategory5EndDate: getJsonDateFromNullableDate(this.PriceCategory5EndDate),
			PriceCategory6StartDate: getJsonDateFromNullableDate(this.PriceCategory6StartDate),
			PriceCategory6EndDate: getJsonDateFromNullableDate(this.PriceCategory6EndDate),
			PriceCategory7StartDate: getJsonDateFromNullableDate(this.PriceCategory7StartDate),
			PriceCategory7EndDate: getJsonDateFromNullableDate(this.PriceCategory7EndDate),
			PriceCategory8StartDate: getJsonDateFromNullableDate(this.PriceCategory8StartDate),
			PriceCategory8EndDate: getJsonDateFromNullableDate(this.PriceCategory8EndDate),
			PriceCategory9StartDate: getJsonDateFromNullableDate(this.PriceCategory9StartDate),
			PriceCategory9EndDate: getJsonDateFromNullableDate(this.PriceCategory9EndDate),
			PriceCategory10StartDate: getJsonDateFromNullableDate(this.PriceCategory10StartDate),
			PriceCategory10EndDate: getJsonDateFromNullableDate(this.PriceCategory10EndDate),
		};
	}

	public getStartDateByPriceCategory(cat:number):Date | null {
		let startKey = `PriceCategory${cat}StartDate` as keyof ProductPriceCategorySalesDates;
		let startDate = (this[startKey] || null) as Date | null;
		return startDate;
	}

	public getEndDateByPriceCategory(cat:number):Date | null {
		let endKey = `PriceCategory${cat}EndDate` as keyof ProductPriceCategorySalesDates;
		let endDate = (this[endKey] || null) as Date | null;
		return endDate;
	}

	public setStartDateByPriceCategory(cat:number, value:Date | null) {
		let startKey = `PriceCategory${cat}StartDate` as keyof ProductPriceCategorySalesDates;
		(this[startKey] as any) = value;
	}

	public setEndDateByPriceCategory(cat:number, value:Date | null) {
		let endKey = `PriceCategory${cat}EndDate` as keyof ProductPriceCategorySalesDates;
		(this[endKey] as any) = value;
	}

	public getPriceCategoryForDate(date:Date):number {
		let activePriceCategories = DossierConfigService.getSellCategories().getActiveCategories();
		for (let cat of activePriceCategories){
			let startDate = this.getStartDateByPriceCategory(cat.id);
			if (!startDate) {
				continue;
			}
			if (!isSameDay(startDate, date)) {
				if (startDate.getTime() > date.getTime()) {
					continue;
				}
			}
			let endDate = this.getEndDateByPriceCategory(cat.id);
			if (!endDate) {
				continue;
			}
			if (!isSameDay(endDate, date)) {
				if (endDate.getTime() < date.getTime()) {
					continue;
				}
			}
			return cat.id;
		}
		return 0;
	}
}