
import Vue from "vue";
import AppCountryCodeAutocomplete from "@/components/CountryCodeAutocomplete.vue";
import { TranslateResult } from "vue-i18n";
import { CountryIndex } from "@/utils/country";

export default Vue.extend({
	props: {
		value: {
			type: [String, Array as ()=>string[]],
			required: true
		},
		multi: Boolean,
		label: {
			type: [Object as ()=>TranslateResult, String],
			default():TranslateResult{
				if (this.multi) return this.$ct("country");
				return this.$ct("country");
			}
		}
	},
	computed: {
		singleValue():string | undefined {
			if (typeof(this.value) == "object"){
				return this.value[0];
			}
			return this.value;
		}
	},
	methods: {
		setSingleValue(value:string){
			this.$emit("input", `${value}`);
		},
		getValueAsList():string[]{
			if (typeof(this.value) == "string") {
				var val = [this.value];
			}else{
				var val = [...this.value];
			}
			return val.filter(v=>v != "" && v != null);
		},
		removeCountry(i:number){
			let val = this.getValueAsList();
			val.splice(i, 1);
			this.$emit("input", val);
		},
		addCountry(val:string){
			let list = this.getValueAsList();
			if (list.indexOf(val) != -1){
				return;
			}
			list.push(val);
			this.$emit("input", list);
		},
		getCountryName(code:string):string{
			return CountryIndex.getCountryName(code);
		}
	},
	components: {
		AppCountryCodeAutocomplete
	},
	i18nEx: {
		componentPrefix: "components.models"
	}
});
