import { CustomTranslation } from "@/utils/custom-translation";
import { ILayoutEntity } from "../layout-editor";
import { TranslatedStyledProperty } from "./translated-styled-property";

export class DocumentLayoutHeading implements ILayoutEntity {
	public Heading1 = new TranslatedStyledProperty();
	public Heading2 = new TranslatedStyledProperty();
	public Heading3 = new TranslatedStyledProperty();
	public Heading4 = new TranslatedStyledProperty();
	public Heading5 = new TranslatedStyledProperty();
	public Heading6 = new TranslatedStyledProperty();
	public Heading7 = new TranslatedStyledProperty();
	public Heading8 = new TranslatedStyledProperty();
	public Heading9 = new TranslatedStyledProperty();
	public X = 50;
	public Y = 20;
	public Width = 50;
	public Height = 50;

	constructor(data?: any) {
		if (!data) {
			return;
		}
		for (let i = 1; i <= 9; i++) {
			(this as any)["Heading" + i] = new TranslatedStyledProperty(data["Heading" + i]);
		}
		this.X = data.X;
		this.Y = data.Y;
		this.Width = data.Width;
		this.Height = data.Height;
	}

	public getJSON() {
		let data = {
			X: this.X,
			Y: this.Y,
			Width: this.Width,
			Height: this.Height,
		} as any;
		for (let i = 1; i <= 9; i++) {
			data["Heading" + i] = ((this as any)["Heading" + i] as CustomTranslation).getJSON();
		}
		return data;
	}
}
