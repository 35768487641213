
import Vue from "vue";
import { TranslateResult } from "vue-i18n";
import AppCrmStatusSelect from "@/components/crm/CrmStatusSelect.vue";

export default Vue.extend({
	data(){
		return {
			ready: false
		};
	},
	props: {
		value: {
			type: [String, Array as ()=>string[]],
			required: true
		},
		multi: Boolean,
		label: {
			type: [Object as ()=>TranslateResult, String],
			default():TranslateResult{
				if (this.multi) return this.$ct("journals");
				return this.$ct("journal");
			}
		}
	},
	computed: {
		val():number | number[] {
			if (typeof(this.value) == "string") {
				return this.parseNum(this.value);
			}
			return this.value.map(v=>this.parseNum(v));
		}
	},
	methods: {
		parseNum(num:string):number{
			let result = parseInt(num || "0");
			if (Number.isNaN(result)) return 0;
			return result;
		},
		setValue(val:number | number[]){
			if (this.multi) {
				if (typeof(val) == "number"){
					this.$emit("input",[`${val}`]);
					return;
				}
				this.$emit("input", val.map(v=>`${v}`));
				return;
			}
			if (typeof(val) == "number"){
				this.$emit("input", `${val}`);
				return;
			}
			this.$emit("input", `${val[0]}`);
		}
	},
	mounted(){
		console.log("mounted", typeof(this.value), this.value);
		if (typeof(this.value) == "string"){
			this.$emit("input", `${this.parseNum(this.value)}`);
		}else{
			this.$emit("input", this.value.filter(f=>f!="").map(v=>`${this.parseNum(v)}`));
		}
		this.$nextTick(()=>{
			this.ready = true;
		});
	},
	components: {
		AppCrmStatusSelect
	},
	i18nEx: {
		componentPrefix: "components.models"
	}
});
