export class DossierSettings{
	ID:number = 0;
	HideSaleModifiedHistoryEvent:boolean = false

	public constructor(data?:any){
		if (!data) return;
		this.ID = data.ID;
		this.HideSaleModifiedHistoryEvent = data.HideSaleModifiedHistoryEvent;
	}

	public getJSON(){
		return {
			ID: this.ID,
			HideSaleModifiedHistoryEvent: this.HideSaleModifiedHistoryEvent,
		};
	}
}