
import Vue from "vue";
import AppContactSearch from "@/components/contacts/ContactSearch.vue";
import { HumanFilter } from "@/utils/human-filter";
import { Contact } from "@/models/base";
import { CustomerService } from "@/services/customer-service";
import { ISelectItem } from "winfakt-vue-components";

export default Vue.extend({
	data(){
		return {
			searchValue: null as Contact | null,
			clientCache: [] as Contact[]
		};
	},
	props: {
		value: {
			type: Object as ()=>HumanFilter,
			required: true
		}
	},
	computed: {
		operator():string{
			if (this.value.Options.indexOf("not") == -1) {
				return "in";
			}
			return "not";
		},
		operators():ISelectItem<string>[]{
			return [
				{
					text: this.$ct("client-in-list"),
					value: "in"
				},
				{
					text: this.$ct("client-not-in-list"),
					value: "not-in"
				}
			];
		},
	},
	methods: {
		addToClientCache(contact:Contact){
			if (this.clientCache.find(c=>c.ID == contact.ID)){
				return;
			}
			this.clientCache.push(contact);
			this.value.Values.push(`${contact.ID}`);
		},
		async onContactSelect(contact:Contact){
			this.searchValue = contact;
			this.addToClientCache(contact);
			await this.$nextTick();
			this.searchValue = null;
		},
		removeContact(contact:Contact){
			let index = this.clientCache.indexOf(contact);
			if (index == -1) return;
			this.clientCache.splice(index, 1);
			this.value.Values = this.clientCache.map(c=>`${c.ID}`);
		},
		setOperator(value:string){
			let index = this.value.Options.indexOf("not");
			if (value == "in"){
				if (index == -1) return;
				this.value.Options.splice(index, 1);
			}else{
				if (index != -1) return;
				this.value.Options.push("not");
			}
		},
	},
	async mounted(){
		let ids = this.value.Values.map(v=>parseInt(v || "0")).filter(id=>!Number.isNaN(id) && id != 0);
		if (ids.length == 0) {
			this.value.Values = [];
			return;
		};
		let contacts = await CustomerService.getContactsByIDs(ids);
		this.clientCache = contacts;
		this.value.Values = contacts.map(c=>`${c.ID}`);
	},
	components: {
		AppContactSearch
	},
	i18nEx: {
		componentPrefix: "components.sales",
	}
});
