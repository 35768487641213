import { Sale } from "@/models/base";
import { field, filterValueInput, print } from "@/utils/models/decorator-field";
import { ignoredFields, model } from "@/utils/models/decorator-model";
import { printHumanFloat } from "@/utils/pretty-print";
import { AWSFile } from "./aws-file";
import { saleIgnoredFields } from "./base/sale";

@model("Reminder")
@ignoredFields(saleIgnoredFields.map(s=>`Sale.${s}`))
export class Reminder {
	@field("number")
	ID:number = 0;
	@field("date")
	ReminderDate:Date = new Date();
	@field("number")
	SaleID:number = 0;
	@field("number")
	@filterValueInput("Contact")
	ContactID:number = 0;
	@field("has-one", "Sale")
	Sale:Sale | null = null;
	@field("number")
	ReminderID:number = 1;
	@field("number")
	@print(printHumanFloat)
	Cost:number = 0;
	@field("number")
	BookYear:number = 0;
	@field("number")
	@print(printHumanFloat)
	Interest:number = 0;

	@field("boolean")
	IsSentWithEmail:boolean = false;
	@field("boolean")
	IsDownloaded:boolean = false;
	@field("boolean")
	IsPrinted:boolean = false;

	@field("number")
	FileID:number = 0;

	File:AWSFile | null = null;

	constructor(data?:any) {
		if (!data) {
			return;
		}

		this.ID = data.ID;
		this.ReminderDate = new Date(data.ReminderDate);
		this.SaleID = data.SaleID;
		this.ContactID = data.ContactID;
		this.ReminderID = data.ReminderID;
		this.Cost = data.Cost;
		this.BookYear = data.BookYear;
		if (data.Sale) {
			this.Sale = new Sale(data.Sale);
		}
		this.Interest = data.Interest;
		this.IsSentWithEmail = data.IsSentWithEmail;
		this.IsDownloaded = data.IsDownloaded;
		this.IsPrinted = data.IsPrinted;
		this.FileID = data.FileID;
		if (data.File){
			this.File = new AWSFile(data.File);
		}
	}

	getJSON() {
		return {
			ID: this.ID,
			ReminderDate: this.ReminderDate.toJSON(),
			SaleID: this.SaleID,
			ContactID: this.ContactID,
			ReminderID: this.ReminderID,
			Cost: this.Cost,
			BookYear: this.BookYear,
			Interest: this.Interest,
			IsSentWithEmail: this.IsSentWithEmail,
			IsDownloaded: this.IsDownloaded,
			IsPrinted: this.IsPrinted,
			FileID: this.FileID
		};
	}
}