import { StyledProperty } from "./styled-property";

export enum TextAlignment {
	LEFT="left",
	CENTER="center",
	RIGHT="right",
	JUSTIFY="justify"
}

export enum TextTransform {
	NONE="none",
	CAPITALIZE="capitalize",
	UPPERCASE="uppercase",
	LOWERCASE="lowercase",
	FULL_WIDTH="full-width"
}

export enum WhiteSpace {
	NONE = "",
	BREAK_SPACES = "break-spaces",
	NOWRAP = "nowrap",
	NORMAL = "normal"
}

export class TextStyledProperty extends StyledProperty{
	public Color: string = "black";
	public FontSize: number = 12;
	public Font: string = "";
	public Italic: boolean = false;
	public Bold: boolean = false;
	public Underline: boolean = false;
	public LineThrough: boolean = false;

	public TextTransform: string = "none"
	public TextAlign:TextAlignment = TextAlignment.LEFT;
	public WhiteSpace:WhiteSpace = WhiteSpace.NONE;


	constructor(data?: any) {
		super(data);
		if (!data) {
			return;
		}
		this.Color = data.Color;
		this.Font = data.Font;
		this.FontSize = data.FontSize;
		this.Italic = data.Italic;
		this.Underline = data.Underline;
		this.LineThrough = data.LineThrough;
		this.Bold = data.Bold;
		this.TextTransform = data.TextTransform || "none";
		this.TextAlign = data.TextAlign || TextAlignment.LEFT;
		this.WhiteSpace = data.WhiteSpace;
	}

	public getStyle(): any {
		let val = {
			...super.getStyle(),
			"color": this.Color,
			"font-weight": this.Bold ? "bold" : "normal",
			"text-decoration": this.Underline ? "underline" : (this.LineThrough ?  "line-through" : "inherit" ),
			"font-style": this.Italic ? "italic" : "normal",
			"text-align": this.TextAlign,
			"text-transform": this.TextTransform,
		} as any;
		val["font-size"] = (this.FontSize || 0) + "pt";
		if (this.Font != "" && this.Font != null) {
			val["font-family"] = this.Font;
		}
		if (this.WhiteSpace) {
			val["white-space"] = this.WhiteSpace;
		}
		return val;
	}

	public getJSON(): any {
		return {
			...super.getJSON(),
			Color: this.Color,
			FontSize: this.FontSize,
			Font: this.Font,
			Bold: this.Bold,
			Underline: this.Underline,
			LineThrough: this.LineThrough,
			Italic: this.Italic,
			TextAlign: this.TextAlign,
			TextTransform: this.TextTransform,
			WhiteSpace: this.WhiteSpace
		};
	}
}
