import { LabelLayoutData } from "./label-layout-data";

export class LabelLayoutModel {
	ID:number = 0;
	Name:string = "Nieuwe label";
	BrowseID:number = 0;
	Data:LabelLayoutData | null = null;

	public constructor(data?:any){
		if (!data) return;
		this.ID = data.ID;
		this.Name = data.Name;
		this.BrowseID = data.BrowseID;
		if (data.Data) {
			this.Data = new LabelLayoutData(data.Data);
		}
	}

	public getJSON(){
		return {
			ID: this.ID,
			Name: this.Name.trim(),
			BrowseID: this.BrowseID,
			Data: this.Data
		};
	}
}