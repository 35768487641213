
import { ConditionPropertyType, Operator } from "@/utils/condition/condition";
import Vue from "vue";
import AppSaleTypeSelect from "@/components/sales/SaleTypeSelect.vue";
import AppSelectBankKind from "@/components/bank/SelectBankKind.vue";
import { TranslateResult } from "vue-i18n";

export default Vue.extend({
	props: {
		value: String,
		fieldType: {
			type: Number as ()=> ConditionPropertyType,
			required: true
		},
		hasValue: Boolean,
		operator: {
			type: Number as ()=>Operator,
			required: true
		}
	},
	computed: {
		label():TranslateResult{
			return this.$ct("value");
		},
		isDateDaysFilter():boolean {
			if (this.fieldType != ConditionPropertyType.DATE){
				return false;
			}
			return [Operator.OVER_DAYS, Operator.OVER_DAYS_AGO, Operator.WITHIN_DAYS, Operator.WITHIN_DAYS_AGO].indexOf(this.operator) != -1;
		},
		isDateMonthFilter():boolean {
			if (this.fieldType != ConditionPropertyType.DATE){
				return false;
			}
			return [Operator.OVER_MONTHS, Operator.OVER_MONTHS_AGO, Operator.WITHIN_MONTHS, Operator.WITHIN_MONTHS_AGO].indexOf(this.operator) != -1;
		},
		isDateYearFilter():boolean {
			if (this.fieldType != ConditionPropertyType.DATE){
				return false;
			}
			return [Operator.OVER_YEARS, Operator.OVER_YEARS_AGO, Operator.WITHIN_YEARS, Operator.WITHIN_YEARS_AGO].indexOf(this.operator) != -1;
		},
		isDateHoursFilter():boolean {
			if (this.fieldType != ConditionPropertyType.DATE){
				return false;
			}
			return [Operator.OVER_HOURS_AGO, Operator.OVER_HOURS, Operator.WITHIN_HOURS_AGO, Operator.WITHIN_HOURS].indexOf(this.operator) != -1;
		},
		isDateMinutesFilter():boolean {
			if (this.fieldType != ConditionPropertyType.DATE){
				return false;
			}
			return [Operator.OVER_MINUTES_AGO, Operator.OVER_MINUTES, Operator.WITHIN_MINUTES_AGO, Operator.WITHIN_MINUTES].indexOf(this.operator) != -1;
		},
		isRelativeDateFilter():boolean {
			return this.isDateDaysFilter || this.isDateMonthFilter || this.isDateYearFilter || this.isDateHoursFilter || this.isDateMinutesFilter;
		},
		relativeDateFilterText():TranslateResult{
			if (this.isDateMinutesFilter){
				return this.$ct("common.minutes-text");
			}
			if (this.isDateHoursFilter){
				return this.$ct("common.hours-text");
			}
			if (this.isDateDaysFilter) {
				return this.$ct("common.days-text");
			}
			if (this.isDateMonthFilter){
				return this.$ct("common.months-text");
			}
			return this.$ct("common.years-text");
		},
		isEmpty():boolean{
			return this.fieldType == ConditionPropertyType.EMPTY;
		},
		isBool():boolean {
			return this.fieldType == ConditionPropertyType.BOOL;
		},
		isString():boolean{
			return this.fieldType == ConditionPropertyType.STRING;
		},
		isNumber():boolean {
			return this.fieldType == ConditionPropertyType.NUMBER;
		},
		isDate():boolean {
			return this.fieldType == ConditionPropertyType.DATE;
		},
		isSaleType():boolean {
			return this.fieldType == ConditionPropertyType.SALE_TYPE;
		},
		isBankKind():boolean {
			return this.fieldType == ConditionPropertyType.BANK_KIND;
		},
		numberValue():number{
			let result = parseFloat(this.value);
			if (isNaN(result)){
				return 0;
			}
			return result;
		},
		dateValue():Date{
			let date = new Date(this.value);
			return date;
		}
	},
	watch: {
		fieldType: {
			immediate: true,
			handler(){
				if (this.isNumber || this.isRelativeDateFilter) {
					if (this.value != `${this.numberValue}`) {
						this.onNumberInput(0);
					}
				} else if (this.isSaleType) {
					if (this.value != `${this.numberValue}`) {
						this.onNumberInput(1);
					}
				}else if (this.isDate) {
					if (new Date(this.value).toJSON() != this.dateValue.toJSON()){
						this.onDateInput(new Date());
					}
				}else if (this.isBankKind){
					if (this.value != `${this.numberValue}`) {
						this.onNumberInput(1);
					}
				}else if (this.isBool) {
					if (this.value != "true" && this.value != "false") {
						this.$emit("input", "true");
					}
				}
			}
		}
	},
	methods: {
		onInput(value:string){
			this.$emit("input", value);
		},
		onNumberInput(value:number){
			this.onInput(value.toString());
		},
		onDateInput(date:Date){
			this.onInput(date.toJSON());
		}
	},
	components: {
		AppSaleTypeSelect,
		AppSelectBankKind
	},
	i18nEx: {
		componentPrefix: "components.sale-layout-editor"
	}
});
