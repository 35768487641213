
import AppSearchBrowse from "@/components/browse/SearchBrowse.vue";
import { Product } from "@/models/base";
import { View } from "@/models/view";
import { BrowseService } from "@/services/browse-service";
import { ProductService } from "@/services/product-service";
import { IBrowseBaseDataSource } from "@/utils/browse/browse-base-data-source";
import { IQueryParams } from "@/utils/query-params";
import { FetchedTotal } from "@/utils/views/fetched-total";
import Vue from "vue";
import {HumanFilter} from "@/utils/human-filter";
import {UserConfigService} from "@/services";

export default Vue.extend({
	data() {
		return {
			selectedProducts: [] as Product[],
			generalSettings: UserConfigService.getGeneralSettings(),
		};
	},
	props: {
		single: Boolean,
		searchString: String,
		view: Object as ()=>View,
		filters: {
			type: Array as () => HumanFilter[],
			default: ()=>[]
		},
	},
	computed: {
		dataSource(): IBrowseBaseDataSource<Product> {
			return {
				getSettings: () => BrowseService.getBrowse(4),
				getElements: (params:IQueryParams)=> ProductService.getProducts(params),
				getTotals(view:View):Promise<FetchedTotal[]>{
					return ProductService.getTotals(view);
				},
				browseName: "Product",
				modelName: "Product",
			};
		},
	},
	methods: {
		async onClick() {
			if (this.selectedProducts.length > 0) {
				if (this.single){
					this.$emit("products-selected", this.selectedProducts[0]);
				}else{
					this.$emit("products-selected", this.selectedProducts);
				}
			}
		},
		onSelectChanged(products: Product[]) {
			this.selectedProducts = products;
		}
	},
	components: {
		AppSearchBrowse
	}
});
