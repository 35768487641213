export interface IMail {
	from: string;
	name: string;
	to: string;
	subject: string;
	body: string;
	attachments: (File | Blob)[];
	isHTML?: boolean;
	bcc: string;
	cc:string;
}

export function formdataFromIMail(settings:IMail):FormData{
	let data = new FormData();
	data.set("from", settings.from);
	data.set("to", settings.to);
	data.set("name", settings.name);
	data.set("subject", settings.subject || "");
	data.set("body", settings.body || "");
	data.set("is-html", settings.isHTML ? "true" : "false");
	if (settings.bcc){
		data.set("bcc", JSON.stringify(settings.bcc.replaceAll(",", ";").split(";").map(s=>s.trim())));
	}
	if (settings.cc){
		data.set("cc", JSON.stringify(settings.cc.replaceAll(",", ";").split(";").map(s=>s.trim())));
	}
	for (let i in settings.attachments) {
		let num = parseInt(i) + 1;
		data.set("file" + num, settings.attachments[i]);
		data.set("file-name" + num, (settings.attachments[i] as any).name || "unnamed-file");
	}
	return data;
}

export interface ISendMessageDialogContact{
	name:string;
	value:string;
}

export function splitMails(mails:string):string[]{
	return mails.replaceAll(";",",").replaceAll(" ", ",").split(",").filter(m=>m!="");
}