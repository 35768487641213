
import { TextIfValue, TextIfValueApplyToTotalOption } from "@/utils/report-engine-settings/text-if-value";
import Vue from "vue";
import AppConditionsEdit from "@/components/models/ConditionsEdit.vue";
import { IModel } from "@/utils/models/model-interface";
import { PropSelectGroup } from "@/utils/prop-select/prop-select-group";
import {convertIModelToConditionPropSelectGroup} from "@/utils/prop-select/convert-imodel-to-prop-select-group";
import { TranslateResult } from "vue-i18n";
import { ISelectItem } from "winfakt-vue-components";
import { getEnumKeys, getEnumValueFromKey } from "@/utils/enum";

export default Vue.extend({
	data(){
		return {
			setting: new TextIfValue()
		};
	},
	props: {
		value: {
			type: Object as ()=>TextIfValue,
			required: true
		},
		isNew: Boolean,
		model: {
			type: Object as ()=>IModel,
			required: true
		}
	},
	computed: {
		fields():PropSelectGroup{
			return convertIModelToConditionPropSelectGroup(this.model);
		},
		title():string | TranslateResult{
			if (this.isNew) {
				return this.$ct("add-text-if-value-item");
			}
			return this.$ct("edit-text-if-value-item");
		},
		applyToTotalItems():ISelectItem<TextIfValueApplyToTotalOption>[]{
			return getEnumKeys(TextIfValueApplyToTotalOption).map(k=>{
				return {
					text: this.$ct(`text-if-value-apply-to-total-options.${k}`),
					value: getEnumValueFromKey(TextIfValueApplyToTotalOption, k)
				};
			});
		}
	},
	watch: {
		value: {
			handler(){
				this.setting = new TextIfValue(this.value.getJSON());
			},
			immediate: true
		}
	},
	methods: {
		close(){
			this.$emit("close");
		},
		confirm(){
			this.$emit("input", this.setting);
			this.close();
		},
	},
	components: {
		AppConditionsEdit
	},
	i18nEx: {
		componentPrefix: "components.reportengine"
	}
});
