
import { IModel } from "../../utils/models/model-interface";
import { Group } from "../../utils/report-engine-settings/group";
import AppColumnSelect from "@/components/models/ColumnSelect.vue";
import { FormLike } from "winfakt-vue-components/src/mixins/form-like";
import { Browse } from "@/models/browse";

export default FormLike.extend({
	data(){
		return {
			group: new Group(),
			selectedProp: "",
		};
	},
	props: {
		value: {
			type: Object as ()=>Group,
			required: true
		},
		model: {
			type: Object as ()=>IModel,
			required: true
		},
		browse: {
			type: Object as ()=>Browse
		}
	},
	watch: {
		value: {
			immediate: true,
			deep: true,
			handler(){
				this.group = new Group(this.value.getJSON());
			}
		}
	},
	methods: {
		addProp(){
			this.group.GroupByProps.push(this.selectedProp);
			this.selectedProp = "";
			this.validate();
		},
		save(){
			this.$emit("input", this.group);
		},
		cancel(){
			this.$emit("close");
		},
		validate(){
			this.isValid = true;
			if (this.group.GroupByProps.length == 0){
				this.isValid = false;
				return;
			}
			for (let prop of this.group.GroupByProps){
				if (!prop){
					this.isValid = false;
					return;
				}
			}
		},
		getPropName(prop:string):string{
			let field = this.model.getField(prop);
			if (!field) {
				return prop;
			}
			if (this.browse) {
				let override = this.browse.getColumnNameOverride(field.path);
				if (override) {
					return override;
				}
			}
			return field.getPropertyTranslation();
		},
		removeProp(i:number){
			this.group.GroupByProps.splice(i,1);
			this.validate();
		},
		onCustomTextInfo(){
			this.$wf.dialog(this.$ct("group-custom-text-explenation"), this.$ct("common.ok"), this.$ct("common.info"));
		}
	},
	mounted(){
		this.validate();
	},
	components: {
		AppColumnSelect
	},
	i18nEx: {
		componentPrefix: "components.reportengine"
	}
});
