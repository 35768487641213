import { ServerConfig } from "@/config/config";
import { ProductStock } from "@/models/base";
import { Axios } from "@/utils/axios";
import { HumanFilter } from "@/utils/human-filter";
import Vue from "vue";

export const ProductStockService = new Vue({
	computed: {
		inUrl(): string {
			return ServerConfig.host + "/product-stock";
		},
		stockLocationUrl(): string {
			return ServerConfig.host + "/stock-location";
		}
	},
	methods: {
		async getStocksByIds(ids: number[]): Promise<ProductStock> {
			let result = await Axios.get(this.inUrl, {
				params: {
					limit: ids.length,
					filters: [
						{
							Field: "this.ID",
							Operator: "in",
							Values: ids.map(id => id + "")
						}
					] as HumanFilter[],
				}
			});
			return new ProductStock(result.data.data[0]);
		},
	}
});
