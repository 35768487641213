import { DisplayedPropertyType } from "./root";

export type PropType = "prop" | "object" | "array";

export class GoTemplateDataProperty {
	name:string = "";
	displayedName:string = "";
	parent?:GoTemplateDataProperty;
	type:PropType = "prop";

	getPath():string {
		if (!this.parent) {
			return "." + this.name;
		}
		return this.parent.getPath() + "." + this.name;
	}

	getDisplayedName() {
		if (this.displayedName == "") {
			return this.getPath();
		}
		return this.displayedName;
	}

	getNodeType():DisplayedPropertyType {
		return "text";
	}

	getNodeArgs():any[] {
		return [];
	}
}