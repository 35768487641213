
import { v4 } from "uuid";
import Vue from "vue";
import AppDurationInput from "@/components/common/DurationInput.vue";

export default Vue.extend({
	data(){
		return {
			id: v4()
		};
	},
	props: {
		fieldType: String,
		value: String,
		label: String,
		disabled: Boolean
	},
	computed: {
		computedValue(): any {
			if (this.isString) {
				return this.value;
			}
			if (this.isNumber || this.isDuration) {
				return parseFloat(this.value) || 0;
			}
			if (this.isBoolean) {
				return this.value == "1";
			}
			if (this.isDate) {
				let date = new Date(this.value);
				if (isNaN(date.getTime())){
					this.onDateInput(new Date());
					return new Date();
				}
				return date;
			}
			return this.value;
		},
		isNumber(): boolean {
			return this.fieldType == "number";
		},
		isString(): boolean {
			return this.fieldType == "string";
		},
		isBoolean(): boolean {
			return this.fieldType == "boolean";
		},
		isDate():boolean {
			return this.fieldType == "date";
		},
		isDuration():boolean {
			return this.fieldType == "duration";
		},
		radioName():string{
			return `radio-${this.id}`;
		}
	},
	watch: {
		value: {
			immediate: true,
			handler(){
				if (this.isBoolean) {
					if (this.computedValue && this.value != "1"){
						this.onBooleanInput(true);
					}else if (!this.computedValue && this.value != "0") {
						this.onBooleanInput(false);
					}
					return;
				}
				if (this.isDate){
					return;
				}
				if (this.computedValue + "" != this.value){
					this.onInput(this.computedValue);
				}
			}
		}
	},
	methods: {
		onBooleanInput(value:boolean){
			this.onInput(value?1:0);
		},
		onInput(value: any) {
			this.$emit("input", value + "");
		},
		onDateInput(value:Date){
			value.setHours(value.getHours() + 4);
			this.$emit("input", value.toJSON());
		},
		onEnter(event:KeyboardEvent){
			this.$emit("enter");
		}
	},
	components: {
		AppDurationInput
	},
	i18nEx: {
		componentPrefix: "components.models"
	}
});
