import { model } from "@/utils/models/decorator-model";
import { field} from "@/utils/models/decorator-field";

@model("Currency")
export default class Currency {
	@field("number")
	public ID:number = 0;

	@field("string")
	public Name:string = "";

	@field("string")
	public Symbol:string = "";

	@field("number")
	public Rate:number = 1;

	@field("string")
	public Prefix:string = "";

	public constructor(data?: any) {
		if (!data) return;
		this.ID = data.ID;
		this.Name = data.Name;
		this.Symbol = data.Symbol;
		this.Rate = data.Rate;
		this.Prefix = data.Prefix;
	}


	getJSON(): any {
		return {
			ID: this.ID,
			Name: this.Name,
			Symbol: this.Symbol,
			Rate: this.Rate,
			Prefix: this.Prefix
		};
	}

}
