import { DossierConfigService } from "@/services/dossier-config-service";
import { printBoolean, printLocalDate } from "@/utils/pretty-print";
import Product from "./product";
import { field, nullable, rightAlign, print, filterValueInput, filterOnly, computed, decimals } from "@/utils/models/decorator-field";
import { model, ignoredFields, priorityFields } from "@/utils/models/decorator-model";
import { Contact } from "@/models/base/index";

@model("StockIn")
@ignoredFields([
	"Product.MainGroup.ParentGroup.ParentGroup",
	"Supplier.MainAddress.Contact"
])
@priorityFields(["Sku", "Name", "Qty", "UnitBuyPrice"])
export default class StockIn{
	@field("number")
	public ID:number = 0;

	@field("has-one", "Product")
	@nullable()
	public Product:Product | null = null;

	@field("has-one", "Contact")
	@nullable()
	public Supplier:Contact | null = null;

	@field("number")
	@filterValueInput("Product")
	@filterOnly()
	public ProductID:number = 0;

	@field("number")
	public SupplierID:number = 0;

	@field("number")
	public InvoiceID: number = 0;

	@field("number")
	public LocationID: number = 1;

	@field("string")
	@computed()
	public get Location():string{
		return DossierConfigService.getStockLocationConfig().getLocationName(this.LocationID);
	};

	@field("number")
	@rightAlign()
	@decimals(2)
	public Qty: number = 1;

	@field("string")
	public Sku: string = "";

	@field("string")
	public Name: string = "";

	@field("number")
	@filterValueInput("BookYear")
	public BookYear: number = 0;

	@field("number")
	@decimals(2)
	public UnitBuyPrice: number = 0;

	@field("date")
	@print(printLocalDate)
	public DateCreated: Date = new Date();

	@field("number")
	@computed()
	@decimals(2)
	public get Total(): number{
		return this.UnitBuyPrice * this.Qty;
	};

	@field("number")
	public SaleRowID: number = 0;

	@field("boolean")
	@print(printBoolean)
	public IsBeginStock:boolean = false;

	public constructor(data: any = null) {
		if (data) {
			this.ID = data.ID;
			this.ProductID = data.ProductID;
			this.SupplierID = data.SupplierID;
			this.InvoiceID = data.InvoiceID;
			this.LocationID = data.LocationID;
			this.BookYear = data.BookYear;
			this.Qty = data.Qty;
			this.Sku = data.Sku;
			this.Name = data.Name;
			this.UnitBuyPrice = data.UnitBuyPrice;
			if (data.Product) {
				this.Product = new Product(data.Product);
			}
			if (data.Supplier) {
				this.Supplier = new Contact(data.Supplier);
			}
			this.DateCreated = new Date(data.DateCreated);
			this.SaleRowID = data.SaleRowID;
			this.IsBeginStock = data.IsBeginStock;
		}
	}

	public getJSON(): any {
		return {
			ID: this.ID,
			ProductID: this.ProductID,
			SupplierID: this.SupplierID,
			InvoiceID: this.InvoiceID,
			LocationID: this.LocationID,
			Qty: this.Qty,
			Sku: this.Sku,
			Name: this.Name,
			BookYear: this.BookYear,
			UnitBuyPrice: this.UnitBuyPrice,
			DateCreated: this.DateCreated.toJSON(),
			SaleRowID: this.SaleRowID,
			IsBeginStock: this.IsBeginStock
		};
	}
}
