import { EventEmitter } from "@/utils/event-emitter";

export class UpgradeServiceClass extends EventEmitter {

	public async showUpgradeModal(tier:number) {
		this.emit("show-upgrade-modal",tier);
	}

};

export const UpgradeService = new UpgradeServiceClass();