import { ServerConfig } from "@/config/config";
import { ModuleOnlineBackupFrequency } from "@/models/dossier/module-online-backup";
import { Job } from "@/models/job";
import { Axios } from "@/utils/axios";
import { downloadBlob } from "@/utils/blob";
import axios from "axios";

export interface IBackupValidationResult {
	Valid: boolean;
	DBVersionIncorrect: boolean;
}

export interface IOldBackup {
	Name: string;
	LastModified: Date;
}

export class BackupServiceClass {
	public readonly URL: string = `${ServerConfig.host}/backup`;

	public async canCreateBackup(): Promise<boolean> {
		let result = await Axios.get(`${this.URL}/can-create`);
		return result.data.CanCreate;
	}

	public async createBackup(): Promise<Job> {
		let result = await Axios.post(`${this.URL}/create-backup`);
		return new Job(result.data);
	}

	public async restoreBackup(file: File, encryptionKey:string = ""): Promise<Job> {
		let data = new FormData();
		data.append("file", file);
		let result = await Axios.post(`${this.URL}/restore-backup`, data, {
			params: {
				encryptionKey
			}
		});
		return new Job(result.data);
	}

	public async restoreOldBackup(backupName:string):Promise<Job>{
		let result = await Axios.post(`${this.URL}/restore-previous-backup/${backupName}`);
		return new Job(result.data);
	}

	public async validate(file: File, encryptionKey:string = ""): Promise<IBackupValidationResult> {
		let data = new FormData();
		data.append("file", file);
		let result = await Axios.post(`${this.URL}/validate`, data, { params: {
			encryptionKey
		}});
		return result.data;
	}

	public async getAutoBackupSetting(): Promise<ModuleOnlineBackupFrequency> {
		let result = await Axios.get(`${this.URL}/automatic`);
		return result.data.Frequency;
	}

	public async setAutoBackupSetting(setting: ModuleOnlineBackupFrequency): Promise<ModuleOnlineBackupFrequency> {
		let result = await Axios.post(`${this.URL}/automatic`, {Frequency: setting});
		return result.data.Frequency;
	}

	public async getOldBackups(): Promise<IOldBackup[]> {
		let result = await Axios.get(`${this.URL}/previous-backups`);
		let items:IOldBackup[] = result.data.map((r: IOldBackup) => {
			return {
				Name: r.Name,
				LastModified: new Date(r.LastModified)
			} as IOldBackup;
		});
		return items.sort((a,b)=>b.LastModified.getTime() - a.LastModified.getTime());
	}

	public async downloadOldBackup(backupName: string): Promise<void> {
		let result = await Axios.get(`${this.URL}/previous-backups/${backupName}`);
		result = await axios.get(result.data.Url, {responseType: "blob"});
		let blob = new Blob([result.data], {type: "text/plain"});
		downloadBlob(blob, backupName);
	}
}

export const BackupService = new BackupServiceClass();