export class ContactsConfig{
	DefaultPaymentDays:number = 0;
	DefaultDiscountCashDays:number = 8;
	DefaultCountry:string = "BE";

	public constructor(data:any){
		this.DefaultPaymentDays = data.DefaultPaymentDays;
		this.DefaultDiscountCashDays = data.DefaultDiscountCashDays == undefined ? 8 : data.DefaultDiscountCashDays;
		this.DefaultCountry = data.DefaultCountry || "BE";
	}

	public getJSON(){
		return {
			DefaultPaymentDays: this.DefaultPaymentDays,
			DefaultDiscountCashDays: this.DefaultDiscountCashDays,
			DefaultCountry: this.DefaultCountry
		};
	}
}