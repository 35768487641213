export interface IIncomeChartData {
	Month: number;
	Total: number;
	TotalBuy: number;
	TotalPurchase: number;
}

export interface IChartDataPerMonthPerYear {
	Years:ChartDataPerMonth[]
}

export interface IIncomeChartYear {
	BookYear: number;
	RevenueToday:number;
	Data: IIncomeChartData[];
}

export class ChartDataPerMonth {
	public amountPerMonth: number[] = [];
	public year: number = 0;
	public revenueToday:number = 0


	constructor(data?: IIncomeChartYear) {
		for (let i = 0; i < 12; i++) {
			this.amountPerMonth.push(0);
		}
		if (!data) {
			return;
		}
		this.year = data.BookYear;
		this.revenueToday = data.RevenueToday;
		for (let month of data.Data) {
			this.amountPerMonth[month.Month - 1] = month.Total;
		}
	}

	public getOrderedData(startMonth: number): number[] {
		let arr = [];
		for (let i = 0; i < 12; i++) {
			let endIndex = i - startMonth;
			if (endIndex < 0) {
				endIndex += 12;
			}
			arr[endIndex] = Math.round(this.amountPerMonth[i]);
		}
		return arr;
	}

}
