
import { HumanFilter } from "@/utils/human-filter";
import Vue from "vue";
import AppFilterInput from "./FilterInput.vue";

export default Vue.extend({
	props: {
		value: {
			type: Object as ()=>HumanFilter,
			required: true
		},
	},
	computed: {
		fieldType():string{
			return "boolean";
		}
	},
	components: {
		AppFilterInput
	}
});
