import { v4 } from "uuid";
import { Condition } from "../condition/condition";
import { HighlightStyle } from "./highlight-style";
import { Model } from "../models/model";

export class Highlight {
	uid:string = v4();
	Style:HighlightStyle = new HighlightStyle();
	Conditions:Condition[] = [];

	public constructor(data?:any){
		if (!data) return;
		this.Style = new HighlightStyle(data.Style);
		this.Conditions = (data.Conditions || []).map((c:any)=>new Condition(c));
	}

	public static makeGetFieldNameFunc(model:Model):(val:string)=>string{
		return (val:string)=>{
			let field = model.getField(val);
			if (!field) return "ERROR";
			return field.getPropertyTranslation();
		};
	}

	public toString(model:Model){
		return Condition.conditionsToShortString(Highlight.makeGetFieldNameFunc(model), this.Conditions);
	}

	public getJSON(){
		return {
			Style: this.Style.getJSON(),
			Conditions: this.Conditions.map(c=>c.getJSON())
		};
	}

	getPreloads():string[] {
		let preloads = [];
		for (let condition of this.Conditions){
			let prop = condition.Prop.split(".");
			prop.pop();
			preloads.push(prop.join("."));
			if (condition.UseFieldValue){
				let prop = condition.Value.split(".");
				prop.pop();
				preloads.push(prop.join("."));
			}
		}
		return preloads.filter(p=>p!="");
	}
}