//i18n-setup.js
import Vue, { VueConstructor } from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

export const i18n = new VueI18n({
	locale: "", // set locale
	fallbackLocale: "nl-be",
	silentTranslationWarn: true
	 // set locale messages
});

const loadedLanguages = [] as string[]; // our default language that is preloaded

function setI18nLanguage(lang: string) {
	i18n.locale = lang;
	(document.querySelector("html") as HTMLElement).setAttribute("lang", lang);
	return lang;
}

export function loadLanguageAsync(lang: string) {
	if (lang == "") {
		lang = "nl-be";
	}
	if (i18n.locale !== lang) {

		if (!loadedLanguages.includes(lang)) {
			let messages = {
				common: require(`../lang/${lang}/common.json`),
				models: require(`../lang/${lang}/models.json`),
				components: require(`../lang/${lang}/components.json`),
				views: require(`../lang/${lang}/views.json`),
				errors: require(`../lang/${lang}/errors.json`)
			};
			i18n.setLocaleMessage(lang, messages);
			loadedLanguages.push(lang);
			return setI18nLanguage(lang);
		}
		return Promise.resolve(setI18nLanguage(lang));
	}
	return Promise.resolve(lang);
}




let i18nExPattern = /^((views)|(components)|(common)|(models))\./gm;

function i18nExDoDefaultTranslation(this: Vue, key: string): boolean {
	if (!this.$i18nEx.componentPrefix) {
		return true;
	}
	if (key.match(i18nExPattern)) {
		return true;
	}
	return false;
}

type i18nExOptions = any[] | {[key: string]: any};

export function i18nExCt(this: Vue, key: string, options?: i18nExOptions): VueI18n.TranslateResult {
	if (i18nExDoDefaultTranslation.bind(this)(key)) {
		return this.$t(key, options);
	}
	return this.$t(this.$i18nEx.componentPrefix + "." + key, options);
}

export function i18nExCtc(this: Vue, key: string, choice: number, options?: i18nExOptions): VueI18n.TranslateResult {
	if (i18nExDoDefaultTranslation.bind(this)(key)) {
		return this.$tc(key, choice, options);
	}
	return this.$tc(this.$i18nEx.componentPrefix + "." + key, choice, options);
}

let i18nEx = {
	install(vue: VueConstructor, options: any) {
		vue.mixin({
			beforeCreate(this: Vue) {
				this.$i18nEx = {};
				let options = this.$options;
				if (options.i18nEx) {
					this.$i18nEx = options.i18nEx;
				}
			}
		});

		vue.prototype.$ct = i18nExCt;
		vue.prototype.$ctc = i18nExCtc;
	}
};

Vue.use(i18nEx);