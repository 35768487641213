
import { BankKind } from "@/models/base/bank";
import Vue from "vue";
import { TranslateResult } from "vue-i18n";
import { ISelectItem } from "winfakt-vue-components";

export default Vue.extend({
	props: {
		value: {
			type: Array as ()=>BankKind[]
		},
		label: {
			type: [String, Object as ()=>TranslateResult]
		},
		fancy: Boolean
	},
	computed: {
		bankKinds():ISelectItem<BankKind>[]{
			return [
				{text: this.$ct("bank-kinds.diverse"), value: BankKind.Diverse},
				{text: this.$ct("bank-kinds.sale"), value: BankKind.Sale},
				{text: this.$ct("bank-kinds.purchase"), value: BankKind.Purchase}
			];
		},
		computedLabel():string | TranslateResult{
			return this.label || this.$ct("labels.kind");
		}
	},
	watch: {
		value: {
			immediate: true,
			handler(){
				let changes = false;
				let vals = [...this.value];
				for (let i = vals.length - 1; i>=0;i--){
					let index = this.bankKinds.map(b=>b.value).indexOf(vals[i]);
					if (index == -1) {
						changes = true;
						vals.splice(i, 1);
					}
				}
				if (changes){
					this.onInput(this.value);
				}
			}
		}
	},
	methods: {
		onInput(value:BankKind[]){
			this.$emit("input", value);
		}
	},
	i18nEx: {
		componentPrefix: "components.bank"
	}
});
