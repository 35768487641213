import { AwsDocument } from "./aws-document";

export class ProductAwsDocument extends AwsDocument{
	ProductID:number = 0;

	public constructor(data?:any){
		super(data);
		if (!data) return;
		this.ProductID = data.ProductID;
	}
}