export class KrampConfig {
	Host:string = "";
	Username:string = "";
	Password:string = "";
	SupplierID:number = 0;

	public constructor(data?:any) {
		if (!data) return;
		this.Host = data.Host;
		this.Username = data.Username;
		this.Password = data.Password;
		this.SupplierID = data.SupplierID || 0;
	}

	public getJSON(){
		return {
			Host: this.Host,
			Username: this.Username,
			Password: this.Password,
			SupplierID: this.SupplierID
		};
	}
}