
import { PropSelectGroup } from "@/utils/prop-select/prop-select-group";
import { PropSelectItem } from "@/utils/prop-select/prop-select-item";
import Vue from "vue";
import AppPropSelectItem from "./PropSelectItem.vue";

export default Vue.extend({
	name: "AppPropSelectGroup",
	props: {
		group: {
			type: Object as ()=>PropSelectGroup,
			required: true
		},
		isRoot: Boolean,
		searchText: String,
		hoveredValue: String,
		addEmpty: Boolean
	},
	computed: {
		items():PropSelectItem[]{
			if (this.searchText == ""){
				return this.group.items;
			}
			return this.group.items.filter(i=>i.text.toString().toLowerCase().indexOf(this.searchText.toLowerCase()) != -1);
		},
		isEmpty():boolean{
			return this.items.length == 0;
		},
		emptyItem():PropSelectItem{
			return new PropSelectItem(this.$ct("common.empty"), "");
		}
	},
	methods: {
		onClick(value:string){
			this.$emit("click", value);
		}
	},
	components: {
		AppPropSelectItem
	}
});
