import { IModuleMetaData } from "@/models/dossier/module";

export class PlanModule {
	ModuleID:number = 0;
	MetaData:IModuleMetaData = {};

	public constructor(data:any){
		this.ModuleID = data.ModuleID;
		this.MetaData = data.MetaData || {};
	}

	public getJSON(){
		return {
			ModuleID: this.ModuleID,
			MetaData: this.MetaData
		};
	}

	// Vue reactivity doesn't work on MetaData for some reason.
	// Trigger this after every change to fix it.
	protected triggerVueReactivity(){
		this.MetaData = {...this.MetaData};
	}
}