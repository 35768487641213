import { CustomTranslation } from "@/utils/custom-translation";

export class ReminderColumnNames {
	SaleNr:CustomTranslation = new CustomTranslation({
		Translations: {
			"nl-be": "Factuur",
			"de": "Rechnung",
			"en": "Invoice",
			"fr": "Facture",
		},
		Fallback: "Factuur"
	});
	Date:CustomTranslation = new CustomTranslation({
		Translations: {
			"nl-be": "Datum",
			"de": "Datum",
			"en": "Date",
			"fr": "Date",
		},
		Fallback: "Datum"
	});
	Total:CustomTranslation = new CustomTranslation({
		Translations: {
			"nl-be": "Bedrag",
			"de": "Betrag",
			"en": "Amount",
			"fr": "Montant",
		},
		Fallback: "Bedrag"
	});
	Paid:CustomTranslation = new CustomTranslation({
		Translations: {
			"nl-be": "Betaald",
			"de": "Schon bezahlt",
			"en": "Already paid",
			"fr": "Déjà payé",
		},
		Fallback: "Betaald"
	});
	Interest:CustomTranslation = new CustomTranslation({
		Translations: {
			"nl-be": "Intresten",
			"de": "Zinsen",
			"en": "Interest",
			"fr": "Intérêt",
		},
		Fallback: "Intresten"
	});
	Cost:CustomTranslation = new CustomTranslation({
		Translations: {
			"nl-be": "Kosten",
			"de": "Kosten",
			"en": "Costs",
			"fr": "Frais",
		},
		Fallback: "Kosten"
	});
	TotalOpen:CustomTranslation = new CustomTranslation({
		Translations: {
			"nl-be": "Nog te betalen",
			"de": "Zu bezahlen",
			"en": "Outstanding amount",
			"fr": "Solde à payer",
		},
		Fallback: "Nog te betalen"
	});
	ExpirationDate:CustomTranslation = new CustomTranslation({
		Translations: {
			"nl-be": "Vervaldag",
			"de": "Fälligheitstag",
			"en": "Due date",
			"fr": "Echéance",
		},
		Fallback: "Vervaldag"
	});

	constructor(data?:any) {
		if (!data) return;
		this.SaleNr = new CustomTranslation(data.SaleNr);
		this.Date = new CustomTranslation(data.Date);
		this.Total = new CustomTranslation(data.Total);
		this.Paid = new CustomTranslation(data.Paid);
		this.Interest = new CustomTranslation(data.Interest);
		this.Cost = new CustomTranslation(data.Cost);
		this.TotalOpen = new CustomTranslation(data.TotalOpen);
		this.ExpirationDate = new CustomTranslation(data.ExpirationDate);
	}

	getJSON() {
		return {
			SaleNr: this.SaleNr.getJSON(),
			Date: this.Date.getJSON(),
			Total: this.Total.getJSON(),
			Paid: this.Paid.getJSON(),
			Interest: this.Interest.getJSON(),
			Cost: this.Cost.getJSON(),
			TotalOpen: this.TotalOpen.getJSON(),
			ExpirationDate: this.ExpirationDate.getJSON()
		};
	}
}