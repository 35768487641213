import { ServerConfig } from "@/config/config";
import Axios from "axios";
import { EventEmitter } from "@/utils/event-emitter";

const url = `${ServerConfig.auth}/motd`;

class MotdServiceClass extends EventEmitter {
	async getCurrentMotd():Promise<{[key:string]:string} | null>{
		let response = await Axios.get(url);
		if (!response.data) {
			return null;
		}
		return response.data;
	}
}

export const MotdService = new MotdServiceClass();