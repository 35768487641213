export class ReminderSettings {
	PrintReminderAfterSend:boolean = false;

	public constructor(data?:any){
		if (!data) return;
		this.PrintReminderAfterSend = data.PrintReminderAfterSend || false;
	}

	getJSON(){
		return {
			PrintReminderAfterSend: this.PrintReminderAfterSend
		};
	}
}