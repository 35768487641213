export class AutoBarcode {
	Enabled:boolean = false;
	NextBarcode:number = 0;

	public constructor(data?:any){
		if (!data) return;
		this.Enabled = data.Enabled;
		this.NextBarcode = data.NextBarcode;
	}

	public getJSON(){
		return {
			Enabled: this.Enabled,
			NextBarcode: this.NextBarcode
		};
	}
}