import { i18n } from "@/setup/i18n-setup";

export class ElectronCtepEctError {
	description:string = "";
	code:number = 0;
	causeCode:number = 0;
	extraInformationData:any = null;
	incidentCode:string | null = null;
	rejectionCode:string | null = null;
	stack:string | null = null;

	public constructor(data:any){
		this.description = data.description || data.message;
		this.code = data.code;
		this.causeCode = data.causeCode;
		this.extraInformationData = data.extraInformationData;
		this.incidentCode = data.incidentCode;
		this.rejectionCode = data.rejectionCode;
		this.stack = data.stack;
		console.error(this);
	}

	public getErrorMessage():string{
		if (this.causeCode){
			if (this.causeCode == 19 && this.incidentCode) {
				return i18n.t(`common.ctep-ect-error.incident-code.${this.incidentCode}`).toString();
			}
			return i18n.t(`common.ctep-ect-error.cause-code.${this.causeCode}`).toString();
		}
		if (this.description) {
			let key = `common.ctep-ect-error.description.${this.description}`;
			let message = i18n.t(key).toString();
			if (message == key) {
				return this.description;
			}
			return message;
		}
		return i18n.t(`errors.UNSPECIFIED`, this.description).toString();
	}
}