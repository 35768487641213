
import Vue from "vue";
import { Group } from "../../utils/report-engine-settings/group";
import { ReportEngineSettings } from "../../utils/report-engine-settings/report-engine-settings";
import { IModel } from "../../utils/models/model-interface";
import AppGroupingEdit from "./GroupingEdit.vue";
import AppStyleEditor from "@/components/layout-editor/StyleEditor.vue";
import { Browse } from "@/models/browse";

export default Vue.extend({
	data() {
		return {
			toEditGroup: null as null | Group,
			toEditGroupStyle: null as null | Group,
		};
	},
	props: {
		value: {
			type: Object as () => ReportEngineSettings,
			required: true
		},
		model: {
			type: Object as () => IModel,
			required: true
		}
	},
	computed: {
		browse():Browse | null {
			return this.value.getBrowseIfBrowseDataSource();
		}
	},
	methods: {
		getGroupName(group:Group):string {
			return group.GroupByProps.map(prop=>{
				let field = this.model.getField(prop);
				if (!field)	{
					return prop;
				}
				if (this.browse){
					let override = this.browse.getColumnNameOverride(field.path);
					if (override){
						return override;
					}
				}
				return field.getPropertyTranslation();
			}).join(", ");
		},
		removeGroup(i:number) {
			this.value.Groups.splice(i, 1);
		},
		editGroup(group:Group){
			this.toEditGroup = group;
		},
		addGroup(){
			this.toEditGroup = new Group();
		},
		editGroupConfirm(group:Group){
			if (!this.toEditGroup) return;
			let i = this.value.Groups.indexOf(this.toEditGroup);
			if (i == -1){
				this.value.Groups.push(group);
			}else{
				this.value.Groups[i] = group;
			}
			this.toEditGroup = null;
		},
		editStyle(group:Group){
			this.toEditGroupStyle = group;
		}
	},
	components: {
		AppGroupingEdit,
		AppStyleEditor
	},
	i18nEx: {
		componentPrefix: "components.reportengine"
	}
});
