import { model } from "@/utils/models/decorator-model";
import { field, rightAlign, print } from "@/utils/models/decorator-field";

@model("Vat")
export default class Vat {
	@field("number")
	public ID:number = 0;

	@field("string")
	public Name:string = "";

	@field("number")
	@rightAlign()
	@print((val:number):string=>`${val}%`)
	public Value:number = 0;

	public constructor(data?: any) {
		if (!data) return;
		this.ID = data.ID;
		this.Name = data.Name;
		this.Value = data.Value;
	}


	getJSON(): any {
		return {
			ID: this.ID,
			Name: this.Name,
			Value: this.Value
		};
	}

	getMultiplier(): number {
		return 1 + (this.Value / 100.0);
	}
}
