
import Vue from "vue";
import AppStockLocationSelect from "@/components/StockLocationSelect.vue";


export default Vue.extend({
	data(){
		return {
			val: [1,2,3,4,5,6,7,8,9]
		};
	},
	props: {
		value: String,
		label: String
	},
	watch: {
		val: {
			immediate: true,
			deep: true,
			handler(){
				this.$emit("input", JSON.stringify(this.val));
			}
		},
	},
	mounted(){
		if (this.value){
			this.val = JSON.parse(this.value);
			return;
		}
		this.$emit("input", JSON.stringify(this.val));
	},
	components: {
		AppStockLocationSelect
	}
});
