export interface ITranslation {
	"nl-be"?: string;
	"fr"?: string;
	"en"?: string;
	Fallback: string;
}

type lang = "nl-be" | "fr" | "en"


export class Translation implements ITranslation {
	"nl-be"?: string;
	"fr"?: string;
	"en"?: string;
	Fallback: string = "";

	constructor(data?: any) {
		if (!data) return;
		this["nl-be"] = data["nl-be"];
		this.fr = data.fr;
		this.en = data.en;
		this.Fallback = data.Fallback;

		if (!this.Fallback) {
			this.Fallback = this["nl-be"] || this.en || this.fr || "";
		}
	}

	getTranslation(lang: string): string {
		return this[lang as keyof ITranslation] || this.Fallback;
	}

	setTranslation(value:string, lang:lang){
		this[lang] = value;
	}

	getJSON(): any {
		for (let key of ["nl-be", "fr", "en"] as (keyof ITranslation)[]) {
			if (this[key] == "") {
				(this as any)[key] = undefined;
			}
		}
		return {
			"nl-be": this["nl-be"],
			fr: this.fr,
			en: this.en,
			Fallback: this.Fallback
		};
	}
}