export class ComputedOneOffPricing {
	price:number = 0.0;
	discountPercent:number = 0.0;
	discount:number = 0.0;
	priceWithDiscount:number = 0.0;
	vat:number = 0.0;
	vatPercent:number = 0.0;
	total:number = 0.0;

	public constructor(data:any){
		this.price = data.price;
		this.discountPercent = data.discountPercent;
		this.discount = data.discount;
		this.priceWithDiscount = data.priceWithDiscount;
		this.vat = data.vat;
		this.vatPercent = data.vatPercent;
		this.total = data.total;
	}
}