import { ReportEngineSettings, ReportType } from "@/utils/report-engine-settings/report-engine-settings";
import { IReportEngineSettingsWrapper } from "@/utils/report-engine-settings/report-engine-settings-wrapper";
import { Job } from "./job";
import { ReportService } from "@/services/report-service";
import { v4 } from "uuid";

export class StaticReport implements IReportEngineSettingsWrapper {
	public Name:string;
	public Settings:ReportEngineSettings;
	public browseId:number;
	public library:string;
	public index:number;

	public uid:string = v4();

	public constructor(data:any, browseId:number, index:number){
		this.Name = data.Name;
		this.Settings = new ReportEngineSettings(data.Settings);
		this.index = index;
		this.library = data.library ? data.library : "";
		this.browseId = browseId;
	}

	async generate(reportType: ReportType, args:string[]): Promise<Job> {
		return ReportService.generateStaticReport(this, reportType, args);
	}
}