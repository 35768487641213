import { Folder } from "@/models/tasks/folder";

export class FolderUser {
	ID:number = 0;
	FolderID:number = 0;
	Folder?:Folder = undefined;
	UserID:number = 0;
	OrderNumber:number = 0;
	IsOwner:boolean = false;

	constructor(data?:any) {
		if (!data) return;
		this.ID = data.ID;
		this.FolderID = data.FolderID;
		this.UserID = data.UserID;
		this.OrderNumber = data.OrderNumber;
		this.IsOwner = data.IsOwner;
		if (data.Folder) {
			this.Folder = new Folder(data.Folder);
		}
	}

	getJSON() {
		return {
			ID: this.ID,
			FolderID: this.FolderID,
			UserID: this.UserID,
			OrderNumber: this.OrderNumber,
			IsOwner: this.IsOwner
		};
	}
}