import { Task } from "@/models/tasks/task";
import { getFileUrl } from "@/utils/url";
import { AuthService } from "@/services/auth-service";
import { ChatMessage } from "@/utils/chat-message";
import { ChatMessageFile } from "@/utils/chat-message-file";
import { DossierService } from "@/services/dossier-service";

export class TaskMessage {
	ID:number = 0;
	TaskID:number = 0;
	Message:string = "";
	UserID:number = 0;
	FileURL:string = "";
	FileType:string = "";
	FileName:string = "";
	TimeStamp:Date = new Date();
	Task?:Task;

	constructor(data?:any) {
		if(!data) return;
		this.ID = data.ID;
		this.TaskID = data.TaskID;
		this.Message = data.Message;
		this.UserID = data.UserID;
		this.FileURL = data.FileURL;
		this.FileType = data.FileType;
		this.FileName = data.FileName;
		this.TimeStamp = new Date(data.TimeStamp * 1000);

		if (data.Task) {
			this.Task = new Task(data.Task);
		}
	}

	getFileUrl():string {
		return getFileUrl(this.FileURL);
	}

	toChatMessage():ChatMessage {
		let message = new ChatMessage();
		message.id = `${this.ID}`;
		if (this.UserID == AuthService.wfUser.ID){
			message.userIsSender = true;
		}
		message.message = this.Message;
		if (this.FileURL != ""){
			let file = new ChatMessageFile();
			file.fileName = this.FileName;
			file.fileUrl = this.getFileUrl();
			file.fileType = this.FileType;
			message.files = [file];
		}
		message.dateSent = this.TimeStamp;
		let sender = DossierService.users.find(u=>u.ID == this.UserID);
		if (sender){
			message.senderName = `${sender.Firstname} ${sender.Lastname}`;
			message.senderImageUrl = sender.getProfileImgUrl();
		}
		return message;
	}
}