import { JournalConfig } from "./journal-config";

export class JournalsConfig {
	public Journal1:JournalConfig;
	public Journal2:JournalConfig;
	public Journal3:JournalConfig;
	public Journal4:JournalConfig;
	public Journal5:JournalConfig;
	public Journal6:JournalConfig;
	public Journal7:JournalConfig;
	public Journal8:JournalConfig;
	public Journal9:JournalConfig;
	public Journal10:JournalConfig;
	public Journal11:JournalConfig;
	public Journal12:JournalConfig;
	public Journal13:JournalConfig;
	public Journal14:JournalConfig;
	public Journal15:JournalConfig;

	public constructor(data:any) {
		this.Journal1 = new JournalConfig(data.Journal1);
		this.Journal2 = new JournalConfig(data.Journal2);
		this.Journal3 = new JournalConfig(data.Journal3);
		this.Journal4 = new JournalConfig(data.Journal4);
		this.Journal5 = new JournalConfig(data.Journal5);
		this.Journal6 = new JournalConfig(data.Journal6);
		this.Journal7 = new JournalConfig(data.Journal7);
		this.Journal8 = new JournalConfig(data.Journal8);
		this.Journal9 = new JournalConfig(data.Journal9);
		this.Journal10 = new JournalConfig(data.Journal10);
		this.Journal11 = new JournalConfig(data.Journal11);
		this.Journal12 = new JournalConfig(data.Journal12);
		this.Journal13 = new JournalConfig(data.Journal13);
		this.Journal14 = new JournalConfig(data.Journal14);
		this.Journal15 = new JournalConfig(data.Journal15);
	}

	public getJSON() {
		return {
			Journal1: this.Journal1.getJSON(),
			Journal2: this.Journal2.getJSON(),
			Journal3: this.Journal3.getJSON(),
			Journal4: this.Journal4.getJSON(),
			Journal5: this.Journal5.getJSON(),
			Journal6: this.Journal6.getJSON(),
			Journal7: this.Journal7.getJSON(),
			Journal8: this.Journal8.getJSON(),
			Journal9: this.Journal9.getJSON(),
			Journal10: this.Journal10.getJSON(),
			Journal11: this.Journal11.getJSON(),
			Journal12: this.Journal12.getJSON(),
			Journal13: this.Journal13.getJSON(),
			Journal14: this.Journal14.getJSON(),
			Journal15: this.Journal15.getJSON(),
		};
	}

	private checkIdInRange(journalId:number){
		if (journalId < 1 || journalId > 15) {
			throw new Error("JournalID out of range!");
		}
	}

	public getJournalName(journalId:number):string {
		this.checkIdInRange(journalId);
		return ((this as any)["Journal" + journalId] as JournalConfig).Name;
	}

	public setJournalName(journalId:number, name:string) {
		this.checkIdInRange(journalId);
		((this as any)["Journal" + journalId] as JournalConfig).Name = name;
	}

	public getJournalConfig(journalId:number):JournalConfig{
		this.checkIdInRange(journalId);
		return ((this as any)["Journal" + journalId] as JournalConfig);
	}

	public setJournalConfig(journalId:number, config:JournalConfig){
		this.checkIdInRange(journalId);
		((this as any)["Journal" + journalId] as JournalConfig) = config;
	}

	public getIds():number[] {
		let val = [] as number[];
		for (let i = 1; i <= 15; i++) {
			val.push(i);
		}
		return val;
	}
}