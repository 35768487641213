
import Vue from "vue";
import AppSelectUser from "@/components/users/SelectUser.vue";
import AppMultiSelectUsers from "@/components/users/MultiSelectUsers.vue";
import { TranslateResult } from "vue-i18n";

export default Vue.extend({
	data(){
		return {
			multiSelectValue: [] as number[]
		};
	},
	props: {
		value: {
			type: [String, Array as ()=>string[]],
			required: true
		},
		multi: Boolean,
		label: {
			type: [Object as ()=>TranslateResult, String],
			default():TranslateResult{
				if (this.multi) return this.$ct("users");
				return this.$ct("user");
			}
		}
	},
	computed: {
		singleValue():string | undefined {
			if (typeof(this.value) == "object"){
				return this.value[0];
			}
			return this.value;
		}
	},
	watch: {
		value: {
			immediate: true,
			handler(){
				// Fix empty values in filter
				if (!this.multi) return;
				if (!Array.isArray(this.value)) return;
				for (let i = this.value.length - 1; i>=0; i--){
					if (this.value[i] == "") {
						this.value.splice(i, 1);
					}
				}
				this.multiSelectValue = this.value.map(v=>parseInt(v));
			}
		}
	},
	methods: {
		setSingleValue(value:string){
			this.$emit("input", `${value}`);
		},
		onMultiSelectInput(value:number[]){
			this.$emit("input", value.map(v=>`${v}`));
		}
	},
	components: {
		AppSelectUser,
		AppMultiSelectUsers
	},
	i18nEx: {
		componentPrefix: "components.models"
	}
});
