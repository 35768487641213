import { DossierSettings } from "./dossier-settings";

export class Dossier {
	id:number = 0;
	name:string = "";
	companyId:number = 0;
	vatNumber:string = "";
	streetName:string = "";
	streetNumber:string = "";
	cityName:string = "";
	postalCode:string = "";
	countryCode:string = "BE";
	phone:string = "";
	email:string = "";
	dateCreated:Date = new Date();
	settings:DossierSettings = new DossierSettings();
	settingsId:number = 0;

	constructor(data?:any) {
		if (!data) {
			return;
		}
		this.id = data.ID;
		this.name = data.Name;
		this.companyId = data.CompanyID;
		this.vatNumber = data.VATNumber;
		this.streetName = data.StreetName;
		this.streetNumber = data.StreetNumber;
		this.cityName = data.CityName;
		this.postalCode = data.PostalCode;
		this.countryCode = data.CountryCode;
		this.phone = data.Phone;
		this.email = data.Email;
		this.dateCreated = new Date(data.DateCreated);
		this.settingsId = data.SettingsID;
		this.settings = new DossierSettings(data.Settings);
	}

	getJSON() {
		return {
			ID: this.id,
			Name: this.name,
			CompanyID: this.companyId,
			VATNumber: this.vatNumber,
			StreetName: this.streetName,
			StreetNumber: this.streetNumber,
			CityName: this.cityName,
			PostalCode: this.postalCode,
			CountryCode: this.countryCode,
			Phone: this.phone,
			Email: this.email,
			DateCreated: this.dateCreated.toJSON(),
			SettingsID: this.settingsId,
		};
	}

}