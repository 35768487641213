
import Vue from "vue";
import { Contact } from "@/models/base";

export default Vue.extend({
	props: {
		contact: {type: Object as () => Contact},
	},
	computed: {

	},
	methods: {

	},
	i18nEx: {
		componentPrefix: "components.contacts"
	}
});
