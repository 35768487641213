
import AppMissingModule from "@/components/common/MissingModule.vue";
import { AuthService } from "@/services/auth-service";
import Vue from "vue";
import { ContactExtraFields } from "@/models/base";
import { ExtraFieldNamesConfig } from "../../models/dossier-configs/extra-field-names-config";
import { DossierConfigService } from "../../services/dossier-config-service";
import AppExtraFieldsEdit from "../ExtraFieldsEdit.vue";
import { MODULE } from "@/config/modules";

export default Vue.extend({
	props: {
		value: {
			type: Object as () => ContactExtraFields,
			required: true
		},
		groupId: {
			type: Number,
			required: true
		}
	},
	computed: {
		settings(): ExtraFieldNamesConfig {
			if (this.groupId == 1) {
				return DossierConfigService.getExtraFieldNamesConfigs().ClientNames;
			}
			if (this.groupId == 2) {
				return DossierConfigService.getExtraFieldNamesConfigs().SupplierNames;
			}
			return DossierConfigService.getExtraFieldNamesConfigs().ProspectNames;
		},
		browseName(): string{
			if(this.$route.path.includes("supplier")){
				return "Suppliers";
			}
			if(this.$route.path.includes("prospect")){
				return "Prospects";
			}
			return "Clients";
		},
		isAllowed(): Boolean {
			return AuthService.hasModule(this.moduleId);
		},
		moduleId():number {
			return MODULE.EXTRA_VELDEN;
		}
	},
	methods: {
	},
	components: {
		AppExtraFieldsEdit,
		AppMissingModule
	},
	i18nEx: {
		componentPrefix: "components.contacts"
	}
});
