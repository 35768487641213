import { AuthService } from "@/services/auth-service";

export class PdfS3UploadedFile {
	ID:number = 0;
	FileName:string = "";
	MinDate:Date = new Date();
	MaxDate:Date = new Date();
	Message:string = "";
	BookYear:number = 0;
	PublicKey:string = "";
	FromSelection:boolean = false;

	public constructor(data:any){
		this.ID = data.ID;
		this.FileName = data.FileName;
		this.MinDate = new Date(data.MinDate);
		this.MaxDate = new Date(data.MaxDate);
		this.Message = data.Message;
		this.BookYear = data.BookYear;
		this.PublicKey = data.PublicKey;
		this.FromSelection = data.FromSelection;
	}

	public getPublicLink():string{
		return  `${location.protocol}//${location.host}/pdf-archive-url/${AuthService.wfDossier.id}/${this.PublicKey}`;
	}
}