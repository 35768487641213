
import { Condition, ConditionPropertyType, Operator } from "@/utils/condition/condition";
import { Model } from "@/utils/models/model";
import { ModelNamedProperty } from "@/utils/models/named-property";
import { PropSelectItem } from "@/utils/prop-select/prop-select-item";
import Vue from "vue";
import AppColumnSelect from "./ColumnSelect.vue";
import AppConditionOperatorSelect from "./ConditionOperatorSelect.vue";
import AppConditionValue from "./ConditionValue.vue";
import AppBrowseConditionFieldValue from "./BrowseConditionFieldValue.vue";
import { Browse } from "@/models/browse";

export default Vue.extend({
	props: {
		value: {
			type: Object as ()=>Condition,
			required: true
		},
		model: {
			type: Object as ()=>Model,
			required: true
		},
		browse: {
			type: Object as ()=>Browse,
		},
		allowComputedFields: Boolean
	},
	computed: {
		selectedField(): ModelNamedProperty | undefined {
			return this.model.getField(this.value.Prop);
		},
		selectedFieldPropSelectItem():PropSelectItem {
			if (!this.selectedField) return new PropSelectItem("", "");
			let name = this.selectedField.getPropertyTranslation();
			return new PropSelectItem(name, this.selectedField.path, this.selectedField.field.type, this.selectedField.field.filterValueInput);
		},
		propertyType():ConditionPropertyType {
			if (!this.selectedField) return ConditionPropertyType.BOOL;
			return Condition.convertModelFieldToConditionPropertyType(this.selectedFieldPropSelectItem);
		},
		hasValue():boolean{
			if (this.propertyType == ConditionPropertyType.EMPTY) {
				return false;
			}
			return this.value.Operator != Operator.IS_EMPTY && this.value.Operator != Operator.IS_NOT_EMPTY;
		},
	},
	components: {
		AppColumnSelect,
		AppConditionOperatorSelect,
		AppConditionValue,
		AppBrowseConditionFieldValue
	},
	i18nEx: {
		componentPrefix: "components.models"
	}
});
