import { AwsDocument, IAwsDocumentDataSource } from "./aws-document";
import Bank from "./bank";
import { BankService } from "@/services";

export class BankAwsFile extends AwsDocument{
	BankID:number = 0;
	BookYear:number = 0;

	public constructor(data?:any){
		super(data);
		if (!data) return;
		this.BankID = data.BankID;
		this.BookYear = data.BookYear;
	}

	public static getDataSource(b:Bank):IAwsDocumentDataSource {
		return {
			async getDocuments(){
				if (b.Documents && b.Documents.length > 0) {
					return b.Documents;
				}
				return (await BankService.getBooking(b.ID)).Documents;
			},
			async addDocument(file:File){
				await BankService.addDocumentToBank(b, file);
			},
			async deleteDocument(d:AwsDocument){
				await BankService.removeDocumentFromBank(b, d.ID);
			}
		} as IAwsDocumentDataSource;
	}
}