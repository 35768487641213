export function durationToString(duration:number):string{
	let minutes = durationToMinutes(duration);
	let hours = durationToHours(duration);
	let minutesLeft = minutes % 60;
	let minutesString = `${minutesLeft}`;
	if (minutesString.length == 1) {
		minutesString = `0${minutesString}`;
	}
	let hoursString = `${hours}`;
	if (hoursString.length < 2) {
		hoursString = `0${hoursString}`;
	}
	return `${hoursString}:${minutesString}`;
}

export function durationToSeconds(duration:number) {
	return duration / 1000000000;
}

export function durationToMinutes(duration:number){
	return Math.floor(durationToSeconds(duration)/60);
}

export function durationToHours(duration:number){
	return Math.floor(durationToMinutes(duration)/60);
}

export function durationToProductQty(duration:number):number{
	let extraMinutes = durationToMinutes(duration) % 60;
	return durationToHours(duration) + (extraMinutes / 60);
}

export function hoursAndMinutesToDuration(hours:number, minutes:number):number {
	let allMinutes = hours * 60 + minutes;
	let seconds = allMinutes * 60;
	return seconds * 1000000000;
}

export function stringToDuration(str:string):number {
	if (!isValidDurationString(str)){
		return 0;
	}
	let [hours, minutes] = str.split(":");
	return hoursAndMinutesToDuration(parseInt(hours), parseInt(minutes));
}

export function productQtyToDuration(qty:number):number {
	let hours = Math.floor(qty);
	let minutesDecimal = (qty - hours);
	let minutes = Math.round(60 * minutesDecimal);
	return hoursAndMinutesToDuration(hours, minutes);
}

export function isValidDurationString(str:string):boolean {
	return /^\d+:\d{1,2}$/.test(str);
}