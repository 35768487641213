import {ServerConfig} from "@/config/config";
import {Axios} from "@/utils/axios";

export class ShopServiceClass{
	get url(): string {
		return ServerConfig.host + "/shop";
	}

	async product(id:number){
		await Axios.post(`${this.url}/update/product/${id}`,{});
	}

	async contact(id:number){
		await Axios.post(`${this.url}/update/contact/${id}`,{});
	}
};

export const ShopService = new ShopServiceClass();