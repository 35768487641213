import { BookYearService, DossierConfigService } from "@/services";
import { i18n } from "@/setup/i18n-setup";
import { TranslateResult } from "vue-i18n";

export class ClosedPeriod{
	ID:number = 0;
	Year:number = new Date().getFullYear();
	Month:number = new Date().getMonth() + 1;
	BookYear:number = BookYearService.selectedBookYear;
	AccountingPeriod:number = 1;

	public constructor(data?:any){
		if (!data) return;
		this.ID = data.ID;
		this.Year = data.Year;
		this.Month = data.Month;
		this.AccountingPeriod = data.AccountingPeriod;
	}

	public getJSON(){
		return {
			ID: this.ID,
			Year: this.Year,
			BookYear: this.BookYear,
			Month: this.Month,
			AccountingPeriod: this.AccountingPeriod
		};
	}

	public getText():TranslateResult {
		return i18n.t("components.settings-default.period-with-month", {period: this.AccountingPeriod, month: i18n.t(`common.months.${this.Month}`), year: this.Year });
	}

	setYearAndDateFromAccountingPeriod(){
		let bookYearStartMonth = DossierConfigService.getGeneralConfig().BookYearStartMonth;
		let month = this.AccountingPeriod;
		month = bookYearStartMonth + month;
		let date = new Date();
		date.setFullYear(this.BookYear);
		date.setMonth(month-1);
		this.Year = date.getFullYear();
		this.Month = date.getMonth()+1;
	}
}