import { CustomTranslation } from "@/utils/custom-translation";
import { field, hidden } from "@/utils/models/decorator-field";
import { model } from "@/utils/models/decorator-model";

@model("Unit")
export class Unit {
	@field("number")
	ID:number = 0;
	@field("json")
	@hidden()
	Name:CustomTranslation = new CustomTranslation();
	@field("string")
	DisplayedName:string = "";


	constructor(data?:any){
		if (!data) return;
		this.ID = data.ID;
		this.Name = new CustomTranslation(data.Name);
		this.DisplayedName = data.DisplayedName;
	}

	public getJSON(){
		return {
			ID: this.ID,
			Name: this.Name.getJSON(),
			DisplayedName: this.DisplayedName
		};
	}
}