
import Vue from "vue";
import AppProductProductInGroups from "@/components/products/ProductInGroupFilter.vue";
import AppSaleClientInList from "@/components/sales/ClientInListFilter.vue";
import AppPurchaseClientInList from "@/components/purchases/SupplierInListFilter.vue";
import AppSaleSaleTypeInList from "@/components/sales/SaleTypeListFilter.vue";
import AppSaleEInvoiceSent from "@/components/sales/SaleEInvoiceSentFilter.vue";
import AppContactAddressField from "@/components/contacts/ContactAddressFieldFilter.vue";
import AppPurchaseDocumentContains from "@/components/purchases/PurchaseDocumentContainsFilter.vue";
import AppCustomCheckboxFilter from "./CustomCheckboxFilter.vue";
import AppProductAmountToBeOrdered from "@/components/products/ProductAmountToBeOrderedFilter.vue";
import { Model } from "@/utils/models/model";
import { HumanFilter } from "@/utils/human-filter";
import { NamedFilter } from "@/utils/models/named-filter";

export default Vue.extend({
	props: {
		model: {
			type: Object as ()=>Model,
			required: true,
		},
		value: {
			type: Object as ()=>HumanFilter,
			required: true
		}
	},
	computed: {
		namedFilter():NamedFilter | undefined {
			return this.model.getAvailableNamedFilters().find(f=>f.name == this.value.Field);
		},
		isValueSelected():boolean{
			return this.value.Field != "";
		},
		tag():string{
			if (!this.namedFilter) {
				return `app-${(this.model.modelName || "").toLowerCase()}-${this.value.Field}`;
			}
			return `app-${this.namedFilter.getFilterEditComponentName(this.model)}`;
		}
	},
	components: {
		AppProductProductInGroups,
		AppSaleClientInList,
		AppPurchaseClientInList,
		AppSaleSaleTypeInList,
		AppSalerowClientInList: AppSaleClientInList,
		AppContactAddressField,
		AppPurchaseDocumentContains,
		AppSaleEInvoiceSent,
		AppCustomCheckboxFilter,
		AppProductAmountToBeOrdered
	}
});
