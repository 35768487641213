import { IReportEngineSettingsWrapper } from "./report-engine-settings-wrapper";
import { ReportEngineSettings, ReportType } from "./report-engine-settings";
import { Job } from "@/models/job";
import { ReportService } from "@/services/report-service";

export class DefaultReportEngineWrapper implements IReportEngineSettingsWrapper {
	Settings: ReportEngineSettings;

	public constructor(settings:ReportEngineSettings){
		this.Settings = settings;
	}

	generate(pdfType: ReportType, args: string[]): Promise<Job> {
		if (pdfType == "pdf"){
			return ReportService.generateCustomReport(this.Settings, args);
		}
		if (pdfType == "excel"){
			return ReportService.generateCustomReportExcel(this.Settings, args);
		}
		throw new Error("Invalid pdf type");
	}

}