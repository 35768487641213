import { PlanModulePricing } from "./plan-module-pricing";

export class PlanModulePricingExtraUsers extends PlanModulePricing {
	public getExtraUsers():number{
		return parseInt(this.MetaData["extra-users"] || "0") || 0;
	}

	public setExtraUsers(count:number):void {
		this.MetaData["extra-users"] = `${count}`;
		this.triggerVueReactivity();
	}

	public getComponentName(): string {
		return "extra-users";
	}

	public getPrice():number {
		return this.Price * this.getExtraUsers();
	}

	public getPermanentPrice(): number {
		return this.PermanentPrice * this.getExtraUsers();
	}

	upgrade(metadata:any) {
		this.setExtraUsers(this.getExtraUsers() + (parseInt(metadata["extra-users"]) || 0));
	}

}