import { GridItem } from "../grid/grid-item";

export class DashboardPlacement extends GridItem{

	public constructor(data?:any){
		super();
		if (!data) return;
		this.row = data.Row || 0;
		this.col = data.Col || 0;
		this.width = data.Width || 1;
		this.height = data.Height || 1;
	}

	public getJSON():any{
		return {
			Row: this.row,
			Col: this.col,
			Width: this.width,
			Height: this.height
		};
	}
}