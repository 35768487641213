import { MODULE } from "@/config/modules";
import { PlanModulePricing } from "./plan-module-pricing";
import {PlanModulePricingExtraUsers} from "./plan-module-pricing-extra-users";

export class PlanModulePricingFactory {
	private constructor(){}

	public static create(data:any):PlanModulePricing {
		if (data.ModuleID == MODULE.EXTRA_USERS) {
			return new PlanModulePricingExtraUsers(data);
		}
		return new PlanModulePricing(data);
	}

	public static createFromModuleIdAndMetadata(moduleId:number, metadata?:any):PlanModulePricing{
		let data = {
			ModuleID: moduleId,
			MetaData: metadata
		};
		return PlanModulePricingFactory.create(data);
	}
}