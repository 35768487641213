export class BrowseFavouriteColumn {
	Path:string = "";
	Name:string = "";
	PathParts:string[] = [];
	ParentPath:string = ""

	public constructor(data?:any){
		if (!data) return;
		this.Path = data.Path || "";
		this.Name = data.Name || "";
		this.PathParts = this.Path.split(".");
		let tempPath = [...this.PathParts];
		tempPath.pop();
		this.ParentPath = tempPath.join(".");
	}

	public getJSON(){
		return {
			Path: this.Path,
			Name: this.Name
		};
	}
}