
import { BrowseStateBase } from "@/utils/browse/browse-state-base";
import Vue from "vue";
import { ISelectItem } from "winfakt-vue-components";
import { View } from "@/models/view";

export default Vue.extend({
	props: {
		state: {
			required: true,
			type: Object as () => BrowseStateBase
		}
	},
	computed: {
		items(): ISelectItem<any>[] {
			let items = this.state.browse.Views.map(v => {
				return {value: v.ID, text: v.Name};
			});
			return items;
		},
		tabs(): string[] {
			return this.items.slice(0, 3).map(i => i.text.toString() as string);
		},
		value(): Number {
			return this.state.querySettings.view.ID;
		},
		selectedTabIndex(): number {
			for (let i = 0; i < this.items.length; i++) {
				if (this.items[i].value.ID == this.value) {
					return i;
				}
			}
			return -1;
		}
	},
	methods: {
		tabClicked(index: number) {
			this.onInput(this.items[index].value.ID);
		},
		onInput(id: Number) {
			this.state.selectedItems.splice(0, this.state.selectedItems.length);
			this.state.setView(this.state.browse.Views.find(i => (i.ID == id)) || new View());
			this.state.fetch();
		},
		onInputFromMenu(id: Number) {
			this.onInput(id);
		},
		newViewClicked() {
			let browse = this.state.browse;
			this.$router.push(`/settings/browse/${browse.ID}/view/new`);
		},
		editViewClicked() {
			let browse = this.state.browse;
			this.$router.push(
				`/settings/browse/${browse.ID}/view/${this.value}`
			);
		}
	},
	i18nEx: {
		componentPrefix: "components.browse"
	}
});
