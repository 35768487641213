
import { MODULE } from "@/config/modules";
import { AWSFile } from "@/models/aws-file";
import { AuthService } from "@/services/auth-service";
import { AWSFileService } from "@/services/aws-file-service";
import Vue from "vue";
import AppPreview from "@/components/documents/Preview.vue";
import { AwsDocument } from "@/models/base/aws-document";
import AppMissingModule from "@/components/common/MissingModule.vue";
import { downloadBlob } from "@/utils/blob";
import AppFileUpload from "@/components/FileUploadDropAndProgress.vue";

export default Vue.extend({
	data(){
		return {
			previewFile: null as null | AWSFile,
			previewSystemFile: null as null | File
		};
	},
	props: {
		files: {
			type: Array as ()=>AwsDocument[],
			required: true
		},
		toAddFiles: {
			type: Array as ()=>File[],
			required: true
		},
		toRemoveFiles: {
			type: Array as ()=>AwsDocument[],
			required: true
		},
		hideIfNoModule: Boolean
	},
	computed: {
		isAllowed():boolean {
			return AuthService.hasModule(this.moduleId);
		},
		moduleId():number {
			return MODULE.DOCUMENTENBEHEER;
		},
		showMissingModule():boolean {
			return !this.isAllowed && !this.hideIfNoModule;
		},
		showPreview():boolean {
			return this.previewFile != null || this.previewSystemFile != null;
		}
	},
	methods: {
		async openPreview(file: AwsDocument){
			if (!file.File) return;
			this.previewFile = file.File;
		},
		async download(file:AwsDocument){
			if (!file.File) return;
			await AWSFileService.downloadFile(file.File.Token, file.File.Name);
		},
		removeFile(index:number){
			let [file] = this.files.splice(index, 1);
			this.toRemoveFiles.push(file);
		},
		openToUploadFilePreview(file:File){
			this.previewSystemFile = file;
		},
		downloadToUploadFile(file:File){
			downloadBlob(file, file.name);
		},
		closePreview(){
			this.previewFile = null;
			this.previewSystemFile = null;
		},
		removeToUploadFile(i:number){
			this.toAddFiles.splice(i, 1);
		},
		onFile(file:File){
			this.toAddFiles.push(file);
		},
	},
	components: {
		AppMissingModule,
		AppPreview,
		AppFileUpload
	},
	i18nEx: {
		componentPrefix: "components.aws-documents"
	}
});
