import { i18n } from "@/setup/i18n-setup";
import { SellCategory } from "@/utils/sell-category";

export interface ISellCategoryWithID {
	id:number;
	settings:SellCategory;
}

export class SellCategories {
	Category1: SellCategory = new SellCategory({});
	Category2: SellCategory = new SellCategory({});
	Category3: SellCategory = new SellCategory({});
	Category4: SellCategory = new SellCategory({});
	Category5: SellCategory = new SellCategory({});
	Category6: SellCategory = new SellCategory({});
	Category7: SellCategory = new SellCategory({});
	Category8: SellCategory = new SellCategory({});
	Category9: SellCategory = new SellCategory({});
	Category10: SellCategory = new SellCategory({});

	constructor(data?: any) {
		if (!data) return;
		for (let  i = 1; i <= 10; i++) {
			(this as any)["Category" + i] = new SellCategory(data["Category" + i]);
		}
	}

	getJSON() {
		return {
			Category1: this.Category1.getJSON(),
			Category2: this.Category2.getJSON(),
			Category3: this.Category3.getJSON(),
			Category4: this.Category4.getJSON(),
			Category5: this.Category5.getJSON(),
			Category6: this.Category6.getJSON(),
			Category7: this.Category7.getJSON(),
			Category8: this.Category8.getJSON(),
			Category9: this.Category9.getJSON(),
			Category10: this.Category10.getJSON()
		};
	}

	getCategories(): SellCategory[] {
		return [
			this.Category1,
			this.Category2,
			this.Category3,
			this.Category4,
			this.Category5,
			this.Category6,
			this.Category7,
			this.Category8,
			this.Category9,
			this.Category10
		];
	}

	getCategoryName(index: number): string {
		return (this as any)["Category" + index].Name || i18n.t("common.Category", {num: index}).toString();
	}

	getActiveCategories():ISellCategoryWithID[] {
		return this.getCategories().map((c, i):ISellCategoryWithID => {
			return {
				id: i + 1,
				settings: c
			} as ISellCategoryWithID;
		}).filter(s => s.settings.Active);
	}
}