export class MollieConfig {
	BankID:number = 1;
	ConnectedWithMollie:boolean = false;
	ProfileID:string = "";

	public constructor(data?:any){
		if (!data) return;
		this.BankID = data.BankID;
		this.ConnectedWithMollie = data.ConnectedWithMollie;
		this.ProfileID = data.ProfileID;
	}

	public getJSON():any{
		return {
			BankID: this.BankID,
			ProfileID: this.ProfileID
		};
	}
}