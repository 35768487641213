import { v4 } from "uuid";
import { Model } from "../models/model";


export class QrCode{
	key:string = v4();
	Prop:string = "";
	PreText:string = "";
	PostText:string = ""

	X:number = 0;
	Y:number = 0;
	Width:number = 10;
	Height:number = 10;

	public constructor(data?:any) {
		if (!data) return;
		this.Prop = data.Prop;
		this.PreText = data.PreText;
		this.PostText = data.PostText;
		this.X = data.X;
		this.Y = data.Y;
		this.Width = data.Width;
		this.Height = data.Height;
	}


	public getJSON():any{
		return {
			Prop: this.Prop,
			PreText: this.PreText,
			PostText: this.PostText,
			X: this.X,
			Y: this.Y,
			Width: this.Width,
			Height: this.Height
		};
	}

	public getPropName(model:Model):string{
		if (this.Prop){
			let field = model.getField(this.Prop);
			if (field) {
				return field.getPropertyTranslation();
			}
		}
		return "";
	}

	public getAsText(model:Model):string{
		return `${this.PreText}${this.getPropName(model)}${this.PostText}`;
	}

	public getName(model:Model):string{
		return this.getAsText(model) || "LEEGE QR CODE";
	}

	private computePropText(record:any):string {
		if (!this.Prop) return "";
		let propParts = this.Prop.split(".");
		let propValue = record;
		for (let part of propParts){
			if (!propValue) return "";
			propValue = propValue[part];
		}
		return `${propValue || ""}`;
	}

	public compute(record:any):string{
		let value =  `${this.PreText}${this.computePropText(record)}${this.PostText}`;
		console.log(value);
		if (!value) return "";
		let div = document.createElement("div");
		div.className = "qrcode-container";
		div.style.setProperty("width", `${this.Width}mm`);
		div.style.setProperty("height", `${this.Height}mm`);
		div.style.setProperty("left", `${this.X}mm`);
		div.style.setProperty("top", `${this.Y}mm`);
		div.setAttribute("data-value", value);
		return div.outerHTML;
	}
}