import { ContactService, onLastOpenedItemSetFunction } from "@/utils/services/contact-service";
import { ServerConfig } from "@/config/config";
import { LastOpenedItem } from "@/utils/last-opened";
import { Contact } from "@/models/base";
import { Axios } from "@/utils/axios";

class ProspectServiceClass extends ContactService {
	protected routerPath: string = "/contacts/prospects";
	protected url: string = `${ServerConfig.host}/prospects`;
	public lastOpenedItem = new LastOpenedItem("contacts/prospect", onLastOpenedItemSetFunction);

	public async toClient(prospects:number | Contact | (number |Contact)[]):Promise<Contact[]> {
		let data:number[] = [];
		if (typeof(prospects) == "number") {
			data = [prospects];
		}else  if (!Array.isArray(prospects)) {
			data = [prospects.ID];
		}else{
			data = prospects.map(p=>{
				if (typeof(p) == "number") {
					return p;
				}
				return p.ID;
			});
		}
		let result = await Axios.post(`${this.url}/convert-to-client`, data);
		return result.data.map((c:any)=>new Contact(c));
	}
}

export const ProspectService = new ProspectServiceClass();