import { i18n } from "@/setup/i18n-setup";
import { IModelProperty } from "@/utils/models/model-field";

export abstract class ModelNamedProperty {
	path: string;
	field: IModelProperty;
	modelName: string;

	constructor(path:string, field:IModelProperty, modelName:string) {
		this.path = path;
		this.field = field;
		this.modelName = modelName;
	}

	getPropertyName():string {
		return this.path.split(".").pop() || "";
	}

	getPropertyTranslation():string {
		if (this.field.getTranslatedName != null) {
			let result = this.field.getTranslatedName();
			if (result) {
				return result;
			}
		}
		return i18n.t(`models.friendlyNames.${this.modelName}.${this.getPropertyName()}`).toString();
	}

	abstract pathToFilterPath():string
}
