// eslint-disable-next-line max-classes-per-file
import { Translation } from "@/utils/translation";

export class StandardArg {
	Value:string = "";
	Name:string = "";
	Type:string = "";

	public constructor(data?:any){
		if (!data) return;
		this.Value = data.Value;
		this.Name = data.Name;
		this.Type = data.Type;
	}
}

export class ExtraArgOption {
	Value:string = "";
	Text:string = "";

	public constructor(data:any){
		this.Value = data.Value;
		this.Text = data.Text;
	}
}

export class ExtraArg {
	Name:string = "";
	Type:string = "";
	FriendlyName:Translation = new Translation();
	Decimals:number | null = null;
	DefaultValue:any = null;
	Options:ExtraArgOption[] = [];

	public constructor(data?:any){
		this.Name = data.Name;
		this.Type = data.Type;
		this.FriendlyName = new Translation(data.FriendlyName);
		this.DefaultValue = data.DefaultValue;
		this.Decimals = data.Decimals;
		this.Options = (data.Options || []).map((d:any)=>new ExtraArgOption(d));
	}

	hasOption(optionText:string):boolean {
		return this.Options.findIndex(o=>o.Text == optionText) != -1;
	}
}

export class Script {
	ID:number = 0;
	Name:string = "";
	ScriptType:string = "";
	StandardArgs:StandardArg[] = [];
	ExtraArgs:ExtraArg[] = [];
	Icon:string = ""

	public constructor(data?:any){
		if (!data) return;
		this.ID = data.ID;
		this.Name = data.Name;
		this.ScriptType = data.ScriptType;
		this.StandardArgs = ((data.StandardArgs || []) as any[]).map(s=>new StandardArg(s));
		this.ExtraArgs = ((data.ExtraArgs || []) as any[]).map(s=>new ExtraArg(s));
		this.Icon = data.Icon;
	}

	getValueOfStandardArg(argName:string):string {
		let i =this.StandardArgs.findIndex(a=>a.Name==argName);
		return this.StandardArgs[i].Value;
	}

}