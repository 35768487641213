
import { Browse } from "@/models/browse";
import { HumanFilterNode } from "@/utils/human-filter-node";
import { IModel } from "@/utils/models/model-interface";
import Vue from "vue";
import { TranslateResult } from "vue-i18n";

export default Vue.extend({
	name: "AppHumanFilterListEditNode",
	data(){
		return {
			isDragging: false,
			dragOffsetX: 0,
			dragOffsetY: 0,
			mouseX: 0,
			mouseY: 0,
			dragWidth: 0,
			dragDepth: 0,
			draggingIsInChild: 0,
			childrenDragDepth: 0
		};
	},
	props: {
		node: {
			type: Object as ()=>HumanFilterNode,
			required: true
		},
		model: {
			type: Object as ()=>IModel,
			required: true
		},
		browse: {
			type: Object as ()=>Browse,
		},
		mainNode: Boolean,
		parentNode: {
			type: Object as ()=>HumanFilterNode
		},
		somethingIsBeingDragged: Boolean,
		isFirstNode: Boolean,
	},
	computed: {
		nodeName():string | TranslateResult{
			if (this.node.filter){
				return this.node.filter.getTranslatedText(this.model, this.browse);
			}
			return this.$ct("group");
		},
		style():any{
			if (this.isDragging){
				return {
					top: `${this.mouseY - this.dragOffsetY}px`,
					left: `${this.mouseX - this.dragOffsetX}px`,
					width: `${this.dragWidth}px`
				};
			}
			return {
			};
		},
		draggingAbove():boolean{
			return this.draggingIsInChild == 0 && this.dragDepth > 0 && this.somethingIsBeingDragged && this.childrenDragDepth == 0;
		},
		andOrText():string{
			if (this.node.isOr){
				return this.$ct("common.or").toString().toUpperCase();
			}
			return this.$ct("common.and").toString().toUpperCase();
		}
	},
	watch: {
		somethingIsBeingDragged(){
			if (!this.somethingIsBeingDragged) {
				this.dragDepth = 0;
				this.draggingIsInChild = 0;
				this.childrenDragDepth = 0;
			}
		}
	},
	methods: {
		setIsOr(value:boolean){
			this.node.isOr = value;
			this.$emit("emit-changes");
		},
		editFilter(){
			this.$emit("edit", this.node);
		},
		removeFilter(){
			this.$emit("delete");
		},
		onEdit(node:HumanFilterNode){
			this.$emit("edit", node);
		},
		removeNode(i:number){
			this.node.children.splice(i,1);
			this.$emit("emit-changes");
		},
		onHandleLongTouch(event:TouchEvent){
			if (this.somethingIsBeingDragged) return;
			if (event.touches.length > 1) return;
			event.preventDefault();
			event.stopPropagation();
			if (!this.isDragging){
				window.addEventListener("touchmove", this.onHandleTouchMove);
				window.addEventListener("touchend", this.onHandleTouchEnd);
			}
			let touch = event.touches[0];
			this.startDragging(touch.pageX, touch.pageY);
		},
		removeDragEvents(){
			window.removeEventListener("mouseup", this.onHandleMouseUp);
			window.removeEventListener("mousemove", this.onHandleMouseMove);
			window.removeEventListener("touchmove", this.onHandleTouchMove);
			window.removeEventListener("touchend", this.onHandleTouchEnd);
		},
		onHandleMouseDown(event:MouseEvent){
			if (this.somethingIsBeingDragged) return;
			if (event.button != 0) return;
			event.preventDefault();
			event.stopPropagation();
			if (!this.isDragging){
				window.addEventListener("mousemove", this.onHandleMouseMove);
				window.addEventListener("mouseup", this.onHandleMouseUp);
			}
			this.startDragging(event.pageX, event.pageY);
		},
		startDragging(pageX:number, pageY:number){
			this.isDragging = true;
			let root = this.$el.getBoundingClientRect();
			this.dragOffsetX = pageX - root.x;
			this.dragOffsetY = pageY - root.y;
			this.dragWidth = root.width;

			this.$emit("drag-start", this.node, this.parentNode);
		},
		onHandleTouchMove(event:TouchEvent){
			if (!this.isDragging) return;
			if (event.touches.length == 0){
				this.onHandleTouchEnd(event);
				return;
			}
			let touch = event.touches[0];
			this.onDragMove(touch.pageX, touch.pageY);
		},
		onHandleTouchEnd(event:TouchEvent){
			if (!this.isDragging) return;
			this.removeDragEvents();
			this.isDragging = false;
			this.$emit("drag-stop");
		},
		onHandleMouseUp(event:MouseEvent){
			if (!this.isDragging) return;
			this.removeDragEvents();
			this.isDragging = false;
			this.$emit("drag-stop");
		},
		onHandleMouseMove(event:MouseEvent){
			if (!this.isDragging) return;
			if (event.buttons % 2 != 1) { // Check if left mouse button is pressed
				this.onHandleMouseUp(event);
			}
			this.onDragMove(event.pageX, event.pageY);
		},
		onDragMove(pageX:number, pageY:number){
			this.mouseX = pageX;
			this.mouseY = pageY;
		},
		onDropInRoot(event:Event){
			if (this.isDragging) return;
			event.stopPropagation();
			let parentNode = this.parentNode;
			if (this.childrenDragDepth > 0) {
				parentNode = this.node;
			}
			this.$emit("drag-drop", parentNode, this.node);
			this.$emit("drag-stop");
		},
		onDragEnter(){
			this.dragDepth++;
			if (!this.somethingIsBeingDragged) {
				this.dragDepth = 0;
				return;
			}
			if (this.dragDepth == 1){
				this.$emit("drag-enter");
			}
		},
		onDragLeave(){
			this.dragDepth--;
			if (!this.somethingIsBeingDragged) return;
			if (this.dragDepth < 0){
				this.dragDepth = 0;
			}
			if (this.dragDepth == 0){
				this.$emit("drag-leave");
			}
		},
		onChildrenDragEnter(){
			if (!this.somethingIsBeingDragged) return;
			this.childrenDragDepth++;
		},
		onChildrenDragLeave(){
			if (!this.somethingIsBeingDragged) return;
			this.childrenDragDepth--;
			if (this.dragDepth < 0){
				this.dragDepth = 0;
			}
		}
	},
	destroyed(){
		this.removeDragEvents();
	},
	i18nEx: {
		componentPrefix: "components.models"
	}
});
