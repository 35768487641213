
import Vue from "vue";
import { HumanFilter } from "@/utils/human-filter";
import { ISelectItem } from "winfakt-vue-components";

export default Vue.extend({
	props: {
		value: {
			type: Object as ()=>HumanFilter,
			required: true
		}
	},
	computed: {
		searchValue():string{
			if (this.value.Values.length == 0){
				return "";
			}
			return this.value.Values[0];
		},
		operator():string{
			if (this.value.Options.indexOf("not") == -1) {
				return "contains";
			}
			return "not-contains";
		},
		operators():ISelectItem<string>[]{
			return [
				{
					text: this.$ct("document-contains"),
					value: "contains"
				},
				{
					text: this.$ct("document-does-not-contain"),
					value: "not-contains"
				}
			];
		},
	},
	methods: {
		setOperator(value:string){
			let index = this.value.Options.indexOf("not");
			if (value == "in"){
				if (index == -1) return;
				this.value.Options.splice(index, 1);
			}else{
				if (index != -1) return;
				this.value.Options.push("not");
			}
		},
		setSearchValue(val:string){
			if (this.value.Values.length == 0){
				this.value.Values.push(val);
			}else{
				this.value.Values[0] = val;
			}
		}
	},
	async mounted(){

	},
	i18nEx: {
		componentPrefix: "components.purchases",
	}
});
