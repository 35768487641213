
import AppSearchBrowse from "@/components/browse/SearchBrowse.vue";
import { Contact } from "@/models/base";
import { IBrowseBaseDataSource } from "@/utils/browse/browse-base-data-source";
import Vue from "vue";
import { Browse } from "@/models/browse";
import { View } from "@/models/view";
import { AllContactsService } from "@/services/all-contacts-service";
import { IQueryParams } from "@/utils/query-params";
import { ViewSetting } from "@/models/user-configs/view-setting";
import { ViewColumn } from "@/utils/views/view-column";
import { FetchedTotal } from "@/utils/views/fetched-total";
import { IBrowseWithExtraData } from "@/services/browse-service";

export default Vue.extend({
	computed: {
		dataSource():IBrowseBaseDataSource<Contact> {
			let elem = this;
			return {
				getSettings: async ():Promise<IBrowseWithExtraData>=>{
					return {
						browse: elem.getBrowse(),
						viewSetting: elem.getViewSettings(),
						extraButtons: [],
						scriptButtons: []
					};
				},
				getElements: (params:IQueryParams)=>{
					return AllContactsService.getContacts(params);
				},
				async getTotals(view:View):Promise<FetchedTotal[]>{
					return [];
				},
				browseName: "Clients",
				modelName: "Contact"
			};
		}
	},
	methods: {
		onClick(contact:Contact) {
			this.$emit("click", contact);
		},
		getBrowse():Browse{
			return new Browse({
				ID: -1,
				Table: "Contact",
				Name: {
					"nl-be": this.$ct("contacts"),
					Fallback: "Contacten"
				},
				Views: [this.getView()],
			});
		},
		getView():View{
			let view = new View();
			view.Table = "Contact";
			view.Name = "Alle contacten";
			view.Columns = this.getViewColumns();
			return view;
		},
		getViewColumns():ViewColumn[]{
			return [
				this.getKlNrViewColumn(),
				this.getCompanyNameViewColumn(),
				this.getNameViewColumn(),
				this.getAddressViewColumn(),
				this.getPostalAndCityViewColumn(),
				this.getCountryCodeViewColumn(),
				this.getPhoneViewColumn(),
			];
		},
		getKlNrViewColumn():ViewColumn{
			let col = new ViewColumn();
			col.Name = this.$ct("models.friendlyNames.Contact.ComputedFriendlyID").toString();
			col.Width = "80px";
			col.Property = "ComputedFriendlyID";
			return col;
		},
		getCompanyNameViewColumn():ViewColumn{
			let col = new ViewColumn();
			col.Name = this.$ct("models.friendlyNames.ContactAddrss.CompanyName").toString();
			col.Width = "150px";
			col.Property = "MainAddress.CompanyName";
			return col;
		},
		getNameViewColumn():ViewColumn{
			let col = new ViewColumn();
			col.Name = this.$ct("models.friendlyNames.ContactAddress.FullName").toString();
			col.Width = "220px";
			col.Property = "MainAddress.FullName";
			return col;
		},
		getAddressViewColumn():ViewColumn{
			let col = new ViewColumn();
			col.Name = this.$ct("models.friendlyNames.ContactAddrss.AddressLine1").toString();
			col.Width = "220px";
			col.Property = "MainAddress.AddressLine1";
			return col;
		},
		getPostalAndCityViewColumn():ViewColumn{
			let col = new ViewColumn();
			col.Name = this.$ct("models.friendlyNames.ContactAddrss.PostalAndCity").toString();
			col.Width = "137";
			col.Property = "MainAddress.PostalAndCity";
			return col;
		},
		getCountryCodeViewColumn():ViewColumn{
			let col = new ViewColumn();
			col.Name = this.$ct("models.friendlyNames.ContactAddrss.CountryCode").toString();
			col.Width = "129px";
			col.Property = "MainAddress.CountryCode";
			return col;
		},
		getPhoneViewColumn():ViewColumn{
			let col = new ViewColumn ();
			col.Name = this.$ct("models.friendlyNames.ContactAddrss.Phone").toString();
			col.Width = "80px";
			col.Property = "MainAddress.Phone";
			return col;
		},
		getViewSettings():ViewSetting{
			return new ViewSetting({
				ViewID: 0,
				OrderColumn: "ComputedFriendlyID",
				OrderAscending: true,
				Limit: 50,
			});
		}
	},
	components: {
		AppSearchBrowse
	},
	i18nEx: {
		componentPrefix: "components.contacts"
	}
});
