import { SaleLayout } from "@/utils/sale-layouts/sale-layout";

export class SaleLayoutConf {
	ID:number = 0;
	Name:string = "";
	ContactGroupID:number = 1;
	Data:SaleLayout = new SaleLayout();

	constructor(data?:any) {
		if (!data) {
			return;
		}
		this.ID = data.ID;
		this.Name = data.Name;
		this.ContactGroupID = data.ContactGroupID;
		this.Data = new SaleLayout(data.Data);
	}

	getJSON() {
		return {
			ID: this.ID,
			Name: this.Name,
			ContactGroupID: this.ContactGroupID,
			Data: this.Data.getJSON(),
		};
	}
}

