import { TranslateResult } from "vue-i18n";

class ToolbarServiceClass {
	private _title:string | TranslateResult = "";
	arguments:any[] = [];
	drawer:boolean | number =  false;

	get title():string | TranslateResult{
		return this._title;
	}
	set title(value:string | TranslateResult){
		this._title = value;
		if (this._title.toString().match(/^\s*$/)) {
			document.title = "Winfakt";
			return;
		}
		document.title = "Winfakt - " + this._title.toString();
	}


	constructor(){
		window.addEventListener("touchstart", (e:TouchEvent) => {
			if (e.touches.length != 1) return;
		}, false);
	}


	public getIsDrawerOpen():boolean {
		if (!this.drawer) return false;
		if (this.drawer == true) return true;
		if (this.drawer > 0) return true;
		return false;
	}

};

export const ToolbarService = new ToolbarServiceClass();