import { CustomTranslation } from "@/utils/custom-translation";
import { field, hidden } from "@/utils/models/decorator-field";
import { model } from "@/utils/models/decorator-model";
import { AWSFile } from "../aws-file";

@model("ProductGroupModel")
export class ProductGroupModel {
	@field("number")
	ID:number = 0;

	@field("string")
	Name:string = "";

	@field("number")
	@hidden()
	LeftKey:number = 0;

	@field("number")
	@hidden()
	RightKey:number = 0

	@field("number")
	@hidden()
	Level:number = 0;

	@field("json")
	@hidden()
	Translations:CustomTranslation = new CustomTranslation();

	@field("number")
	ParentGroupID:number = 0;

	@field("has-one", "ProductGroupModel")
	ParentGroup:ProductGroupModel | null = null;

	@field("boolean")
	ShowOnDisplay:boolean = false;

	@field("number")
	@hidden()
	ImageAWSFileID:number | null = null;

	ImageAWSFile:AWSFile | null = null;

	public constructor(data?:any){
		if (!data) return;
		this.ID = data.ID;
		this.Name = data.Name;
		this.LeftKey = data.LeftKey;
		this.RightKey = data.RightKey;
		this.Level = data.Level;
		this.Translations = new CustomTranslation(data.Translations);
		this.ParentGroupID = data.ParentGroupID;
		this.ParentGroup = new ProductGroupModel(data.ParentGroup);
		this.ShowOnDisplay = data.ShowOnDisplay;
		this.ImageAWSFileID = data.ImageAWSFileID;
		if (data.ImageAWSFile) {
			this.ImageAWSFile = new AWSFile(data.ImageAWSFile);
		}
	}
}