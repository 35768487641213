import { ServerConfig } from "@/config/config";
import { Vat } from "@/models/base";
import { Axios } from "@/utils/axios";
import { MainSocketService } from "./main-socket-service";
import { UserConfigService } from "./user-config-service";
import { EventEmitter } from "@/utils/event-emitter";
import { IInitAppResponse } from "./init-service";

export interface IVatRegimes{
	getId():number;
	getSaleVat():number;
}

export enum PurchaseVatRegime {
	NORMALE_BTW= 1,
	BTW_MEDECONTRACTANT= 2,
	AANKOOP_UIT_ANDERE_LIDSTATEN_BINNEN_DE_EG= 3,
	AANKOOP_BUITEN_DE_EG= 4,
	VRIJ_VAN_BTW_CLASSIC= 5,
	VRIJ_VAN_BTW= 6,
	AAN_0= 12,
	AAN_6= 13,
	AAN_12= 14,
	AAN_21= 15,
	VRIJSTELLING_WBTW = 17,
}

export enum VatRegime {
	NORMAAL = 1,
	BTW_MEDECONTRACTANT = 2,
	VERKOOP_AAN_ANDERE_LIDSTATEN_BINNEN_DE_EG = 3,
	VERKOOP_BUITEN_DE_EG = 4,
	TRANSPORT_ONDER_T_DOCUMENT = 5,
	VRIJ_VAN_BTW = 6,
	BTW_OP_2DE_HANDS_VERKOPEN = 7,
	ANDERE_VOOR_VAK_47 = 8,
	DIENSTEN_AAN_ANDERE_LIDSTATEN_BINNEN_DE_EG = 9,
	DIENSTEN_BUITEN_DE_EG = 10,
	BTW_MEDECONTRACTANT_BINNEN_DE_EG_44 = 11,
	AAN_0 = 12,
	AAN_6 = 13,
	AAN_12 = 14,
	AAN_21 = 15,
	AAN_6_BTW_RENOVATIE = 16,
	VRIJSTELLING_WBTW = 17,

	//Vrijstelling van BTW volgens artikel 42, §1 eerste lid 2° WBTW.

}

export class VatServiceClass extends EventEmitter {
	private initialized:boolean = false;
	private vats:Vat[] = [];
	private get url():string{
		return `${ServerConfig.host}/vat`;
	}
	getVats():Vat[] {
		return this.vats;
	}

	getVat(vatID: number): Vat {
		// Fixes public links
		if (vatID == 0){
			return new Vat({ID: 0, Value: 0, Name: "0"});
		}
		let vats = this.getVats();
		let res = vats.find(v => v.ID == vatID);
		if (!res) {
			throw new Error(`Vat with id ${vatID} not found`);
		}
		return res;
	}

	async createVat(vat:Vat):Promise<Vat>{
		let result = await Axios.post(this.url, [vat.getJSON()]);
		await this.refreshVats();
		return new Vat(result.data[0]);
	}

	async updateVat(vat:Vat):Promise<Vat>{
		let result = await Axios.put(this.url, [vat.getJSON()]);
		await this.refreshVats();
		return new Vat(result.data[0]);
	}

	async deleteVat(vat:Vat):Promise<void>{
		await Axios.delete(`${this.url}/${vat.ID}`);
		await this.refreshVats();
	}

	getVatByValue(value:number):Vat {
		let vats = this.getVats();
		let vat = vats.find(v => v.Value == value);
		if (!vat) {
			throw Error(`Vat with value "${value}" does not exist`);
		}
		return vat;
	}

	getVatById(value:number):Vat {
		let vats = this.getVats();
		let vat = vats.find(v => v.ID == value);
		if (!vat) {
			throw Error(`Vat with value "${value}" does not exist`);
		}
		return vat;
	}

	async refreshVats():Promise<void>{
		let result = await Axios.get(ServerConfig.host + "/vat?limit=100");
		this.vats = result.data.data.map((data: any) => new Vat(data));
		this.emit("vats-refreshed");
	}

	init(data:IInitAppResponse){
		this.vats = data.Vats;
		if (!this.initialized){
			this.initialized = true;
			MainSocketService.on("/vats-updated", ()=>this.refreshVats());
		}
	}

	getVatRegimeCount(): number {
		return 16;
	}

	getVatRegime(id:number):IVatRegimes{
		let result = this.getVatRegimes().find(v=>v.getId() == id);
		if (!result){
			throw new Error(`VatRegime ${id} not found`);
		}
		return result;
	}

	getDefaultVat():Vat {
		try{
			return this.getVat(4);
		}catch(err){
			return this.vats[0];
		}
	}

	// eslint-disable-next-line max-lines-per-function
	getVatRegimes():IVatRegimes[] {
		let result:IVatRegimes[] = [
			{
				getId: ()=>1,
				getSaleVat: ()=> VatService.getVat(UserConfigService.getSaleSettings().DefaultNewSaleVatID).Value
			},
			{
				getId: ()=>2,
				getSaleVat: ()=> 0
			},
			{
				getId: ()=>3,
				getSaleVat: ()=>0
			},
			{
				getId: ()=>4,
				getSaleVat: ()=>0
			},
			{
				getId: ()=>5,
				getSaleVat: ()=>0
			},
			{
				getId: ()=>6,
				getSaleVat: ()=>0
			},
			{
				getId: ()=>7,
				getSaleVat: ()=>21
			},
			{
				getId: ()=>8,
				getSaleVat: ()=>0
			},
			{
				getId: ()=>9,
				getSaleVat: ()=>0
			},
			{
				getId: ()=>10,
				getSaleVat: ()=>0
			},
			{
				getId: ()=>11,
				getSaleVat: ()=>0
			},
			{
				getId: ()=>17,
				getSaleVat: ()=>0
			},
			{
				getId: ()=>12,
				getSaleVat: ()=>0
			},
			{
				getId: ()=>13,
				getSaleVat: ()=>6
			},
			{
				getId: ()=>14,
				getSaleVat: ()=>12
			},
			{
				getId: ()=>15,
				getSaleVat: ()=>21
			},
			{
				getId: ()=>16,
				getSaleVat: ()=>6
			},
		];

		let order = UserConfigService.getSaleSettings().VatRegimeDisplayOrder;
		if (order.length > 0){
			result = result.sort((a,b)=>{
				let indexA = order.indexOf(a.getId());
				let indexB = order.indexOf(b.getId());
				if (indexA == -1 && indexB == -1) {
					return 0;
				}
				if (indexA == -1) {
					return 1;
				}
				if (indexB == -1) {
					return -1;
				}
				return indexA - indexB;
			});
		}

		return result;
	}

	// eslint-disable-next-line max-lines-per-function
	getPurchaseVatRegimes():IVatRegimes[] {
		let result:IVatRegimes[] = [
			{
				getId: ()=>1,
				getSaleVat: ()=> VatService.getVat(UserConfigService.getSaleSettings().DefaultNewSaleVatID).Value
			},
			{
				getId: ()=>2,
				getSaleVat: ()=>0
			},
			{
				getId: ()=>3,
				getSaleVat: ()=>0
			},
			{
				getId: ()=>4,
				getSaleVat: ()=>0,
			},
			{
				getId: ()=>5,
				getSaleVat: ()=>0,
			},
			{
				getId: ()=>6,
				getSaleVat: ()=>0
			},
			{
				getId: ()=>12,
				getSaleVat: ()=>0
			},
			{
				getId: ()=>13,
				getSaleVat: ()=>6
			},
			{
				getId: ()=>14,
				getSaleVat: ()=>12
			},
			{
				getId: ()=>15,
				getSaleVat: ()=>21
			},
			{
				getId: ()=>17,
				getSaleVat: ()=>0
			}
		];

		let order = UserConfigService.getSaleSettings().PurchaseVatRegimeDisplayOrder;
		if (order.length > 0){
			result = result.sort((a,b)=>{
				let indexA = order.indexOf(a.getId());
				let indexB = order.indexOf(b.getId());
				if (indexA == -1 && indexB == -1) {
					return 0;
				}
				if (indexA == -1) {
					return 1;
				}
				if (indexB == -1) {
					return -1;
				}
				return indexA - indexB;
			});
		}
		return result;
	}
};

export const VatService = new VatServiceClass();