export class WfOnlineApiKey {
	public ID:number = 0;
	public DossierID:number = 0;
	public UserID:number = 0;
	public Name:string = "";

	public constructor(data:any) {
		this.ID = data.ID;
		this.DossierID = data.DossierID;
		this.UserID = data.UserID;
		this.Name = data.Name;
	}
}