import { PurchaseService } from "@/services";
import { AwsDocument, IAwsDocumentDataSource } from "./aws-document";
import Purchase from "./purchase";

export class PurchaseAwsFile extends AwsDocument {
	PurchaseID:number = 0;
	BookYear:number = 0;

	public constructor(data?:any){
		super(data);
		if (!data) return;
		this.PurchaseID = data.PurchaseID;
		this.BookYear = data.BookYear;
	}


	public static getDataSource(purchase:Purchase):IAwsDocumentDataSource {
		return {
			async getDocuments(){
				if (purchase.Documents && purchase.Documents.length > 0) {
					return purchase.Documents;
				}
				return (await PurchaseService.getPurchase(purchase.ID)).Documents;
			},
			async addDocument(file:File){
				await PurchaseService.addDocumentToPurchase(purchase, file);
			},
			async deleteDocument(d:AwsDocument){
				await PurchaseService.removeDocumentFromPurchase(purchase, d.ID);
			}
		} as IAwsDocumentDataSource;
	}
}