import { field } from "@/utils/models/decorator-field";
import { model } from "@/utils/models/decorator-model";

@model("CrmAssignedUser")
export class CrmAssignedUser {
	@field("number")
	ID:number = 0;
	@field("number")
	CrmID:number = 0;
	@field("number")
	UserID:number = 0;

	public constructor(data?:any){
		if (!data) return;
		this.ID = data.ID;
		this.CrmID = data.CrmID;
		this.UserID = data.UserID;
	}

	getJSON() {
		return {
			ID: this.ID,
			CrmID: this.CrmID,
			UserID: this.UserID
		};
	}
}