
import { Browse } from "@/models/browse";
import { UserConfigService } from "@/services/user-config-service";
import { BrowseState } from "@/utils/browse/browse-state";
import { HumanFilter } from "@/utils/human-filter";
import { IQueryFilters } from "@/utils/query-params";
import { FormLike } from "winfakt-vue-components/src/mixins/form-like";
import { getModel, Model } from "@/utils/models/model";
import AppFilterEdit from "@/components/models/HumanFilterEdit.vue";

export default FormLike.extend({
	data() {
		let settings = UserConfigService.getBrowseSearchSettings();
		return {
			activeFilterTab: 0,
			showSearchSettings: false,
			filters: {
				filters: [],
				searchQuery: "",
				autoWildCardSearch: true,
				searchByAllColumns: false
			} as IQueryFilters,
			columns: settings.getSettings(this.state.browse),
		};
	},

	props: {
		state: {
			required: true,
			type: Object as () => BrowseState
		}
	},

	computed: {
		searchIcon(): string {
			return this.showSearchSettings ? "chevron-down" : "chevron-up";
		},
		browse(): Browse {
			return this.state.browse;
		},
		currentModel(): Model {
			if (this.browse) {
				return getModel(this.browse.Table);
			}
			return getModel("Contact");
		},
		tabs(): any {
			return this.$ct("SearchTabs");
		},
		activeFilter(): HumanFilter | undefined {
			return this.filters.filters[this.activeFilterTab];
		},
		friendlyColumns() {

		},
		model(): Model {
			return this.browse.type;
		},
	},

	methods: {
		focus() {
			setTimeout(() => {
				let el = this.$refs.quickSearch as HTMLInputElement;
				el.focus();
			}, 200);
		},
		close() {
			this.state.showSearch = false;
		},

		addFilter() {
			this.filters.filters.push(
				new HumanFilter({
					Field: "",
					Operator: "=",
					Values: [""]
				})
			);
			this.activeFilterTab = this.filters.filters.length - 1;
			this.filters.searchQuery = "";
		},

		removeFilter(i: number) {
			if (!this.filters.filters) return;
			this.filters.filters.splice(i, 1);
			this.activeFilterTab = this.filters.filters.length - 1;
			if (this.activeFilterTab == -1)
				this.state.filterModalActiveTab = 0;

		},
		onSearchQueryInput(event:string){
			this.filters.searchQuery = event;
			this.filters.filters = [];
		},
		submit() {
			this.filters.searchQuery = (this.filters.searchQuery || "").replace("*", "%");
			this.state.selectedItems.splice(0, this.state.selectedItems.length);
			this.state.setFilters(this.filters);
			this.state.fetch();
			this.close();
		},
		tabChange(i: number) {
			if (i == 1 && this.filters.filters.length == 0){
				this.addFilter();
			}
		},
		getFriendlyName(column: string): string {
			let namedFilter = this.model.getAvailableNamedFilters().find(f => f.name == column);
			if (namedFilter) {
				return namedFilter.getDisplayName(this.model).toString();
			}
			let field = this.model.getFilterField(column);
			if (!field) return column;
			return field.getPropertyTranslation();
		},
	},
	mounted() {
		this.filters = this.state.getFilters();
	},
	components: {
		AppFilterEdit
	},
	i18nEx: {
		componentPrefix: "components.browse"
	}
});
