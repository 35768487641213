import { v4 } from "uuid";
import { Model } from "../models/model";

export class SaleEditRowExtraInfoField{
	private uid:string = v4();
	Field:string = "Sku";
	Label:string = ""

	public constructor(data?:any){
		if (!data) return;
		this.Field = data.Field;
		this.Label = data.Label;
	}

	public getId():string{
		return this.uid;
	}

	public getJSON(){
		return {
			Field: this.Field,
			Label: this.Label.trim()
		};
	}

	public getDisplayName(productModel:Model):string{
		if (this.Label) {
			return this.Label;
		}
		let field= productModel.getField(this.Field);
		if (!field) return "ERROR";
		return field.getPropertyTranslation();
	}

}