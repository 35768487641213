import { ServerConfig } from "@/config/config";
import { Unit } from "@/models/unit";
import { Axios } from "@/utils/axios";
import { IInitAppResponse } from "./init-service";
import { MainSocketService } from "./main-socket-service";

export class UnitServiceClass {
	private units:Unit[] = [];
	public readonly URL:string = `${ServerConfig.host}/unit`
	private initialized:boolean = false;

	public getUnits():Unit[]{
		return this.units;
	}

	public async getUnit(id:number):Promise<Unit>{
		let result = await Axios.get(`${this.URL}/${id}`);
		return new Unit(result.data);
	}

	public async postUnit(unit:Unit):Promise<Unit>{
		let result = await Axios.post(`${this.URL}`, unit.getJSON());
		return new Unit(result.data);
	}

	public async putUnit(unit:Unit):Promise<Unit>{
		let result = await Axios.put(`${this.URL}/${unit.ID}`, unit.getJSON());
		return new Unit(result.data);
	}

	public async deleteUnit(unit:Unit):Promise<void>{
		await Axios.delete(`${this.URL}/${unit.ID}`);
	}

	public async fetchUnits():Promise<Unit[]>{
		let result = await Axios.get(`${this.URL}`);
		this.units.splice(0, this.units.length);
		this.units.push(...(result.data as any[]).map((u:any)=>new Unit(u)));
		return this.units;
	}

	public init(data:IInitAppResponse){
		this.units = data.Units;
		if (!this.initialized) {
			this.initialized = true;
			MainSocketService.on("/unit/new", this.fetchUnits.bind(this));
			MainSocketService.on("/unit/update", this.fetchUnits.bind(this));
			MainSocketService.on("/unit/delete", this.fetchUnits.bind(this));
		}
	}
}

export const UnitService = new UnitServiceClass();

