import { ChatMessage } from "@/models/chat/chat-message";
import { Participant } from "@/models/chat/participant";

export class Thread {
	ID: number = 0;
	UserID: number = 0;
	Title: string = "";
	Closed: boolean = false;
	TimeStamp: Date = new Date();
	Messages: ChatMessage[] = [];
	Participants: Participant[] = [];
	Pinned: boolean = false;
	ConversationID: number = 0;
	Unread: number = 0;

	constructor(data?: any) {
		if (!data) return;
		this.ID = data.ID;
		this.UserID = data.UserID;
		this.Closed = data.Closed;
		this.TimeStamp = new Date(data.TimeStamp * 1000);
		this.Title = data.Title;
		this.Pinned = data.Pinned;
		this.ConversationID = data.ConversationID;
		this.Unread = data.Unread;


		if (data.Messages) {
			this.Messages = data.Messages.map((m: any) => new ChatMessage(m));
		}

		if (data.Participants) {
			this.Participants = data.Participants.map((p: any) => new Participant(p));
		}

	}

	getJSON() {
		return {
			ID: this.ID,
			UserID: this.UserID,
			Closed: this.Closed,
			TimeStamp: this.TimeStamp,
			Messages: this.Messages,
			Participants: this.Participants,
			Title: this.Title,
			Pinned: this.Pinned,
			ConversationID: this.ConversationID
		};
	}

}