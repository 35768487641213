export enum TireType {
	SUMMER="SUMMER",
	WINTER="WINTER",
	ALL_SEASONS="ALL_SEASONS"
}

export class Tire {
	brand:string = "";
	size:number = 12;
	type:TireType = TireType.SUMMER;
	quantity:number = 4;

	public constructor(data?:any) {
		if (!data) return;
		this.brand = data.brand;
		this.size = data.size;
		this.type = data.type;
		this.quantity = data.quantity;
	}

	public getJSON(){
		return {
			brand: this.brand,
			size: this.size,
			type: this.type,
			quantity: this.quantity
		};
	}

	public getText():string{
		return `${this.quantity} x ${this.brand} ${this.type}`;
	}
}