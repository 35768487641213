
import { ContactAddress } from "@/models/base";
import { Contact } from "@/models/base";
import { ContactAutofill } from "@/models/contact-autofill";
import { FormLike } from "winfakt-vue-components/src/mixins/form-like";

export default FormLike.extend({
	data() {
		return {
			test: false,
			selectedFields: {} as { [key: string]: boolean }
		};
	},
	props: {
		autofillData: Object as () => ContactAutofill,
		contact: Object as () => Contact
	},
	computed: {
		keys(): string[] {
			let allowedKeys = [
				"maatschappelijkeNaam",
				"address",
				"postal",
				"city",
				"firstName",
				"lastName",
				"tel",
				"email",
				"fax",
				"country"
			];
			let keys = Object.keys(this.autofillData);
			return keys.filter(key => allowedKeys.indexOf(key) != -1)
				.filter((key) => (this.autofillData as any)[key] != null);
		}
	},
	methods: {
		close() {
			this.$emit("close");
		},
		getAutofillData(key:any):any{
			return (this.autofillData as any)[key];
		},
		// eslint-disable-next-line max-lines-per-function
		submit() {

			let mainAddress = this.contact.MainAddress;
			if (this.autofillData.country == "BE")
				this.contact.Vat = "BE" + this.autofillData.ondernemingsnummer;
			if (!mainAddress) {
				this.contact.MainAddress = new ContactAddress();
				mainAddress = this.contact.MainAddress;
			}
			if (this.selectedFields.firstName) {
				mainAddress.FirstName = this.autofillData.firstName || "";
			}
			if (this.selectedFields.lastName) {
				mainAddress.LastName = this.autofillData.lastName || "";
			}
			if (this.selectedFields.maatschappelijkeNaam) {
				mainAddress.CompanyName = this.autofillData.maatschappelijkeNaam || "";
			}
			if (this.selectedFields.address) {
				mainAddress.AddressLine1 = this.autofillData.address || "";
			}
			if (this.selectedFields.postal) {
				mainAddress.Postal = this.autofillData.postal || "";
			}
			if (this.selectedFields.city) {
				mainAddress.City = this.autofillData.city || "";
			}
			if (this.selectedFields.tel) {
				mainAddress.Phone = this.autofillData.tel || "";
			}
			if (this.selectedFields.email) {
				mainAddress.Email = this.autofillData.email || "";
			}
			if (this.selectedFields.fax) {
				mainAddress.Fax = this.autofillData.fax || "";
			}
			if (this.selectedFields.country) {
				mainAddress.CountryCode = this.autofillData.country || "";
			}
			this.$emit("close");
		},
		onValue(key: string, value: boolean) {
			this.selectedFields[key] = value;
			this.$forceUpdate();
		}
	},
	created() {
		for (let key of this.keys) {
			this.selectedFields[key] = true;
		}

		this.$forceUpdate();
	},
	i18nEx: {
		componentPrefix: "components.contacts"
	}
});
