import { isStringNullOrWhitespace } from "@/utils/strings";
import { canBeFilteredNummerically, field, hidden, nullable } from "@/utils/models/decorator-field";
import { model } from "@/utils/models/decorator-model";

@model("BankType")
export default class BankType{
	@field("number")
	public ID:number = 0;

	@field("string")
	public Name:string = "";

	@field("string")
	@canBeFilteredNummerically()
	public LedgerNumber:string = "";

	@field("string")
	public AccountNumber:string = "";

	@field("string")
	@nullable()
	public Iban:string | null = "";

	@field("string")
	@nullable()
	public Bic:string | null = "";

	@field("boolean")
	@nullable()
	public ShowOnCashierDisplay:boolean = false;

	@field("boolean")
	public ShowInAccounting:boolean = false;

	@field("string")
	@hidden()
	public SepaFileNameFormat:string = "sepa-export-{DD}-{MM}-{YYYY}_{hh}-{mm}";

	public constructor(data?:any){
		if (!data){
			return;
		}
		this.ID = data.ID;
		this.Name = data.Name;
		this.LedgerNumber = data.LedgerNumber;
		this.AccountNumber = data.AccountNumber;
		this.Iban = data.Iban;
		this.Bic = data.Bic;
		this.ShowOnCashierDisplay = data.ShowOnCashierDisplay;
		this.ShowInAccounting = data.ShowInAccounting;
		this.SepaFileNameFormat = data.SepaFileNameFormat;
	}

	public getJSON() {
		return {
			ID: this.ID,
			Name: this.Name,
			LedgerNumber: this.LedgerNumber,
			AccountNumber: this.AccountNumber,
			Iban: isStringNullOrWhitespace(this.Iban) ? null : this.Iban,
			Bic: isStringNullOrWhitespace(this.Bic) ? null : this.Bic,
			ShowOnCashierDisplay: this.ShowOnCashierDisplay,
			ShowInAccounting: this.ShowInAccounting,
			SepaFileNameFormat: this.SepaFileNameFormat
		};
	}

	public isSameIban(iban:string):boolean{
		if (!this.Iban && !iban) return true;
		if (!this.Iban) return false;
		let thisIban = this.Iban.replaceAll(/\s/g, "").replaceAll(".", "").toUpperCase();
		let otherIban = this.Iban.replaceAll(/\s/g, "").replaceAll(".", "").toUpperCase();
		return thisIban == otherIban;
	}

	public getName():string{
		if (this.Name) {
			return this.Name;
		}
		return `Bank ${this.ID}`;
	}
}