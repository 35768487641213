
import Vue from "vue";
import AppBookYearSelect from "@/components/bookyear/SelectBookYear.vue";
import { BookYearService } from "@/services/book-year-service";
import AppArgInputMonthRangeOrQuarterSelect from "./ArgInputMonthRangeOrQuarterSelect.vue";
import AppArgInputMonthOrQuarterSelect from "./ArgInputMonthOrQuarterSelect.vue";
import AppArgInputDateRange from "./ArgInputDateRange.vue";
import AppArgInputLedgerRange from "./ArgInputLedgerRange.vue";
import AppArgInputProductSku from "./ArgInputProductSku.vue";
import AppArgInputProductSkuList from "./ArgInputProductSkuList.vue";
import AppArgInputStockLocationList from "./ArgInputStockLocationList.vue";
import AppArgInputJournal from "./ArgInputJournal.vue";
import AppArgInputContactList from "./ArgInputContactList.vue";
import AppArgInputContact from "./ArgInputContact.vue";
import AppArgInputBankType from "./ArgInputBankType.vue";
import AppSellPriceCategorySelect from "@/components/SellPriceCategorySelect.vue";
import { ExtraArg } from "@/models/script";
import { DossierConfigService } from "@/services/dossier-config-service";
import { ISelectItem } from "winfakt-vue-components";

export default Vue.extend({
	data(){
		return {
			ready: false
		};
	},
	props: {
		value: {
			type: [Number, String, Date, Boolean, Array],
		},
		arg: {
			type: Object as ()=>ExtraArg,
			required: true
		},
	},
	computed: {
		label():string{
			return this.arg.FriendlyName.getTranslation(this.$i18n.locale);
		},
		selectOptions():ISelectItem<string>[]{
			return this.arg.Options.map(o=>{
				return {
					text: o.Text,
					value: o.Value
				};
			});
		}
	},
	watch: {
		"arg.Type": {
			immediate: true,
			async handler(){
				if (this.arg.Type == "bookyear"){
					this.onInput(`${BookYearService.selectedBookYear}`);
				}else if (this.arg.Type == "priceCategory"){
					this.onInput(DossierConfigService.getSellCategories().getActiveCategories()[0].id);
				}
				await this.$nextTick();
				this.ready = true;
			}
		},
	},
	methods: {
		onInput(value:any){
			this.$emit("input", value);
		}
	},
	components: {
		AppBookYearSelect,
		AppArgInputMonthRangeOrQuarterSelect,
		AppArgInputMonthOrQuarterSelect,
		AppArgInputDateRange,
		AppArgInputProductSku,
		AppArgInputProductSkuList,
		AppArgInputStockLocationList,
		AppArgInputContactList,
		AppArgInputContact,
		AppArgInputJournal,
		AppArgInputBankType,
		AppSellPriceCategorySelect,
		AppArgInputLedgerRange
	}
});
