import { Module } from "./dossier/module";
import { MODULE } from "@/config/modules";
import { ModuleFactory } from "./dossier/module-factory";
import { AuthService } from "@/services/auth-service";

export class Company {
	Name:string = "";
	OwnerID:number = 0;
	Tier:number = 1;
	ActiveUntil:Date = new Date();
	IsDemo:boolean = true;
	Blocked:boolean = true;
	WorkspaceURL:string | null = null;
	Modules:Module[] = [];
	WfOfflineID:string = "";
	DateCreated:Date = new Date();
	ServicePackExpirationDate:Date | null = null;
	FixedRenewalPrice:number =  0;

	public constructor(data?:any){
		if (!data) return;
		this.Name = data.Name;
		this.OwnerID = data.OwnerID;
		this.Tier = data.Tier;
		this.ActiveUntil = new Date(data.ActiveUntil * 1000);
		this.IsDemo = data.IsDemo;
		this.Blocked = data.Blocked;
		this.WorkspaceURL = data.WorkspaceURL;
		this.WfOfflineID = data.WfOfflineID;
		this.FixedRenewalPrice = data.FixedRenewalPrice;
		this.DateCreated = new Date(data.DateCreated);
		if (data.ServicePackExpirationDate){
			this.ServicePackExpirationDate = new Date(data.ServicePackExpirationDate);
		}
		if (data.Modules){
			this.Modules = data.Modules.map((m:any)=>ModuleFactory.create(m.Module, m.MetaData));
		}
	}

	public isPermanentlyActive():boolean{
		return this.ActiveUntil.getTime() == 0;
	}

	public isExpired():boolean{
		if (this.ActiveUntil.getTime() == 0){
			return false;
		}
		return this.ActiveUntil < new Date();
	}

	hasModule(moduleId: number): boolean {
		if (AuthService.isDevModeEnabled) {
			let ignoredModules = [MODULE.TOT_99_JOURNALEN];
			if (ignoredModules.indexOf(moduleId) == -1) {
				return true;
			}
		}
		// Niet doen. Als je modules wilt activeren bij starters dan gaat dit niet werken
		// 	if (this.Tier == Tier.STARTER){
		// 		return false;
		// 	}
		for (let mod of this.Modules) {
			if (mod.ID == moduleId) {
				return true;
			}
		}
		return false;
	}

	getMaxExtraUsersCount():number{
		for (let mod of this.Modules){
			if (mod.ID == MODULE.EXTRA_USERS){
				let val = parseInt(mod.MetaData["extra-users"]);
				if (isNaN(val)){
					return 0;
				}
				return val;
			}
		}
		return 0;
	}

	getMaxExtraDossiersCount():number {
		for (let mod of this.Modules){
			if (mod.ID == MODULE.EXTRA_DOSSIERS) {
				return parseInt(mod.MetaData["extra-dossiers"]) || 0;
			}
		}
		return 0;
	}
}