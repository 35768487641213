
import { LabelLayoutModel } from "@/models/label-layout/label-layout-model";
import { LabelLayoutService } from "@/services";
import { HumanFilter } from "@/utils/human-filter";
import { printHtml } from "@/utils/pdf";
import Vue from "vue";
import { BrowseStateBase } from "../../utils/browse/browse-state-base";

export default Vue.extend({
	data(){
		return {
			isPrinting: false
		};
	},
	props: {
		state: {
			type: Object as ()=>BrowseStateBase,
			required: true
		}
	},
	computed: {
		layouts():LabelLayoutModel[] {
			return this.state.labelLayouts;
		}
	},
	methods: {
		close(){
			this.state.printLabelsModalOpen = false;
		},
		async printWithLayout(layout:LabelLayoutModel){
			if (this.state.selectedItems.length == 0) return this.close();
			if (this.isPrinting) return;
			this.isPrinting = true;
			try{
				layout = await LabelLayoutService.getById(layout.ID);
				if (!layout.Data) return;
				let filter = new HumanFilter();
				filter.Field = `this.ID`;
				filter.Operator = "in";
				filter.Values = this.state.selectedItems.map(i=>`${i.ID}`);
				let records = await this.state.dataSource.getElements({
					filters: [
						filter
					],
					limit: 500,
					offset: 0,
					orderBy: "ID",
					orderDirection: "ASC",
					view: this.state.querySettings.view,
					dontSaveViewSettings: true,
					additionalPreloads: layout.Data.Data.Preloads
				});
				let result = layout.Data.Data.compute(records.data);
				console.log(result);
				printHtml(result);
				this.close();
			}catch(err){

			}
			this.isPrinting = false;
		}
	},
	i18nEx: {
		componentPrefix: "components.browse"
	}
});
