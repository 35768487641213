export class Admin {
	id:number;
	email:string;
	name:string;
	imageUrl:string;

	constructor(data:any) {
		this.id = data.id;
		this.email = data.email;
		this.name = data.name;
		this.imageUrl = data.imageUrl;
	}
}