import Sale from "./sale";
import {printBoolean, printDuration, printLocalDate, printWithoutHtmlTags} from "@/utils/pretty-print";
import { ignoredFields, model, namedFilters } from "@/utils/models/decorator-model";
import { field, rightAlign, print, hidden, filterValueInput, computed, nullable, decimals, canBeFilteredNummerically } from "@/utils/models/decorator-field";
import { HumanFilter } from "@/utils/human-filter";
import { NamedFilter } from "@/utils/models/named-filter";
import { TranslateResult } from "vue-i18n";
import { i18n } from "@/setup/i18n-setup";
import { durationToProductQty } from "@/utils/duration";
import Product from "./product";
import { SaleService } from "@/services";


export interface RowConfig {
	Bold: boolean;
	Hidden: boolean;
	SubTotal: boolean;
}

export interface ISaleRowGetJsonOptions{
	includeProduct?:boolean;
}

@model("SaleRow")
@ignoredFields([
	"Sale.InvoiceAddress.Contact",
	"Sale.DeliveryAddress.Contact",
	"Sale.Contact.MainAddress.Contact",
	"Sale.LastBank.Invoice",
	"Sale.LastBank.Purchase",
	"Sale.LastBank.Contact",
	"Sale.LastBank.Ticket.Contact",
	"Product.MainGroup.ParentGroup.ParentGroup",
	"Sale.Car.Invoice",
	"Sale.Car.Purchase",
	"Sale.Car.Supplier.MainAddress.Contact",
	"Sale.Car.Contact.MainAddress.Contact",
	"Sale.CreatedByFrequencyDocument.Sale",
	"Sale.LastBank.Ticket.CreatedByFrequencyDocument",
	"Sale.LastBank.Sale.CreatedByFrequencyDocument",
])
@namedFilters([
	new NamedFilter({
		name: "client-in-list",
		filterToString(filter: HumanFilter): TranslateResult {
			if (filter.Options.indexOf("not-in") != -1) {
				return i18n.t("models.friendlyNames.SaleRow.client-not-in-list");
			}
			return i18n.t("models.friendlyNames.SaleRow.client-in-list");
		}
	})
])
export default class SaleRow {
	@field("number")
	public ID: number = 0;

	@field("number")
	@rightAlign()
	@decimals(2)
	public Amount: number = 1;

	@field("number")
	@rightAlign()
	@decimals(2)
	public Amount1:number = 1;

	@field("number")
	@rightAlign()
	@decimals(2)
	public Amount2:number = 1;

	@field("string")
	public ProductSku: string = "";

	@field("string")
	@print(printWithoutHtmlTags)
	@hidden()
	public ProductDescription: string = "";

	@field("string")
	@print(printWithoutHtmlTags)
	public ProductDescriptionStripped:string = ""

	@field("number")
	@rightAlign()
	@decimals(2)
	public UnitPrice: number = 0;

	@field("boolean")
	@computed()
	public get IsTaxRow(): boolean {
		let w = ["bebat", "recupel", "auvibel"];
		return w.some(w => this.ProductDescription.toLowerCase().includes(w));
	}

	@field("number")
	@computed()
	@rightAlign()
	@decimals(2)
	public get NettoPrice(): number {
		return this.Amount * ((this.UnitPrice)  * (1 - (this.Discount / 100.0)) + this.EcoboniTax + this.AccijnzenTax) ;
	}

	@field("number")
	@computed()
	@rightAlign()
	@decimals(2)
	@hidden()
	public get VatPrice(): number {
		return this.NettoPrice * (this.Vat / 100.0);
	}

	@field("number")
	@computed()
	@rightAlign()
	@decimals(2)
	public get BrutoPrice(): number {
		return this.NettoPrice + this.VatPrice;
	}

	@field("number")
	@rightAlign()
	@decimals(2)
	public Discount: number = 0.0;

	@field("number")
	@rightAlign()
	@decimals(2)
	public Vat: number = SaleService.getDefaultNewSaleVat().Value;

	@field("number")
	public SaleID: number = 0;

	@field("number")
	@hidden()
	public DisplayOrder: number = 0;

	@field("number")
	@rightAlign()
	@decimals(2)
	public UnitBuyPrice: number = 0;

	@field("number")
	@filterValueInput("BookYear")
	public BookYear: number = 0;

	@field("json")
	@hidden()
	public SaleRowConfig: RowConfig = {
		Bold: false,
		Hidden: false,
		SubTotal: false
	};

	@field("has-one", "Sale")
	public Sale: Sale | null = null;

	@field("number")
	@rightAlign()
	@decimals(2)
	public TotalBuyPrice: number = 0;
	@field("number")
	@rightAlign()
	@decimals(2)
	public TotalPriceExcVat: number = 0;
	@field("number")
	@rightAlign()
	@decimals(2)
	public TotalPriceIncVat: number = 0;
	@field("number")
	@rightAlign()
	@decimals(2)
	public TotalVat: number = 0;
	@field("number")
	@rightAlign()
	@decimals(2)
	public TotalProfit: number = 0;
	@field("number")
	@rightAlign()
	@decimals(2)
	public TotalPriceExcVatPerUnit:number = 0;

	public ImportedSaleID: number = 0;

	@field("string")
	public UnitName: string = "";

	@field("string")
	@canBeFilteredNummerically()
	public LedgerAccountCode: string = "";


	@field("duration")
	@print(printDuration)
	public Duration:number = 0;

	@field("number")
	@decimals(2)
	@rightAlign()
	public Backorder:number = 0;

	@field("number")
	@decimals(2)
	@rightAlign()
	public Delivered:number = 0;

	@field("number")
	@decimals(2)
	@rightAlign()
	public LastDelivered:number = 0;

	@field("number")
	@decimals(2)
	@rightAlign()
	public LeeggoedTax:number = 0;

	@field("number")
	@decimals(2)
	@rightAlign()
	public EcoboniTax:number = 0;

	@field("number")
	@decimals(2)
	@rightAlign()
	public AccijnzenTax:number = 0;

	@field("boolean")
	@print(printBoolean)
	public Undeliverable:boolean = false;


	@field("has-one", "Product")
	public Product:Product | null = null;

	@field("date")
	@nullable()
	@print((value: Date | null): string => {
		if (!value) {
			return i18n.t("common.none").toString();
		}
		return printLocalDate(value);
	})
	public DeliveryDate:Date | null = null;

	// to delete deposits on create
	public DepositIDs:number[] = [];

	public CreatedByAutoSaleRowSettingID:number = 0;

	public getTotalDiscount(): number {
		return (this.UnitPrice / 100 * this.Discount) * this.Amount;
	}

	public getTotalWithVatWithTaxes():number{
		return this.TotalPriceIncVat + (this.Amount * this.LeeggoedTax);
	}

	public calculateTotals(sale:Sale): void {
		this.Amount = this.Amount1 * this.Amount2;
		this.TotalPriceExcVat = (this.UnitPrice - (this.UnitPrice / 100 * this.Discount) + this.EcoboniTax + this.AccijnzenTax) * this.Amount;
		this.TotalBuyPrice = this.UnitBuyPrice * this.Amount;
		this.TotalProfit = ((this.TotalPriceExcVat - this.EcoboniTax - this.AccijnzenTax) * this.Amount) - this.TotalBuyPrice;
		if (sale.Contact && sale.Contact.GroupID != 2 && sale.VatRegime == 7){
			this.TotalPriceIncVat = this.TotalPriceExcVat + (this.TotalProfit / 100 * this.Vat);
		}else{
			this.TotalPriceIncVat = this.TotalPriceExcVat + (this.TotalPriceExcVat / 100 * this.Vat);
		}
		this.TotalVat = this.TotalPriceIncVat - this.TotalPriceExcVat;
	}

	// eslint-disable-next-line max-lines-per-function
	public constructor(data?: any) {
		if (!data) return;
		this.ID = data.ID;
		this.Amount = data.Amount;
		this.Amount1 = data.Amount1;
		this.Amount2 = data.Amount2;
		this.ProductSku = data.ProductSku;
		this.ProductDescription = data.ProductDescription;
		this.UnitPrice = data.UnitPrice;
		this.Discount = data.Discount;
		this.Vat = data.Vat;
		this.SaleID = data.SaleID;
		this.UnitBuyPrice = data.UnitBuyPrice;
		this.DisplayOrder = data.DisplayOrder;
		this.BookYear = data.BookYear;
		this.SaleRowConfig = data.SaleRowConfig;
		this.TotalBuyPrice = data.TotalBuyPrice;
		this.TotalPriceExcVat = data.TotalPriceExcVat;
		this.TotalPriceIncVat = data.TotalPriceIncVat;
		this.TotalProfit = data.TotalProfit;
		this.TotalVat = data.TotalVat;
		this.TotalPriceExcVatPerUnit = data.TotalPriceExcVatPerUnit;
		this.LeeggoedTax = data.LeeggoedTax || 0;
		this.EcoboniTax = data.EcoboniTax || 0;
		this.AccijnzenTax = data.AccijnzenTax || 0;
		this.UnitName = data.UnitName;
		this.ImportedSaleID = data.ImportedSaleID;
		this.LedgerAccountCode = data.LedgerAccountCode;
		this.Duration = data.Duration;
		this.Backorder = data.Backorder;
		this.Delivered = data.Delivered;
		this.LastDelivered = data.LastDelivered;
		this.Undeliverable = data.Undeliverable;
		this.ProductDescriptionStripped = data.ProductDescriptionStripped;
		if(this.Duration > 0){
			this.Amount = durationToProductQty(this.Duration);
		}
		if (data.Sale) {
			this.Sale = new Sale(data.Sale);
		}
		if (data.Product){
			this.Product = new Product(data.Product);
		}
		if (data.DeliveryDate) {
			this.DeliveryDate = new Date(data.DeliveryDate);
		}
		this.DepositIDs = [...(data.DepositIDs || [])];
		this.CreatedByAutoSaleRowSettingID = data.CreatedByAutoSaleRowSettingID || 0;
	}

	public getRowConfig(): RowConfig {
		return this.SaleRowConfig;
	}

	// eslint-disable-next-line max-lines-per-function
	public getJSON(options?:ISaleRowGetJsonOptions) {
		let result:any = {
			ID: this.ID,
			Amount: this.Amount,
			Amount1: this.Amount1,
			Amount2: this.Amount2,
			ProductSku: this.ProductSku,
			ProductDescription: this.ProductDescription,
			UnitPrice: this.UnitPrice,
			Discount: this.Discount,
			Vat: this.Vat,
			SaleID: this.SaleID,
			DisplayOrder: this.DisplayOrder,
			UnitBuyPrice: this.UnitBuyPrice,
			BookYear: this.BookYear,
			SaleRowConfig: this.SaleRowConfig,
			ImportedSaleID: this.ImportedSaleID,
			TotalBuyPrice: this.TotalBuyPrice,
			TotalPriceExcVat: this.TotalPriceExcVat,
			TotalPriceIncVat: this.TotalPriceIncVat,
			TotalProfit: this.TotalProfit,
			Totalvat: this.TotalVat,
			LeeggoedTax: this.LeeggoedTax,
			EcoboniTax: this.EcoboniTax,
			AccijnzenTax: this.AccijnzenTax,
			UnitName: this.UnitName,
			LedgerAccountCode: this.LedgerAccountCode,
			Duration: this.Duration,
			DepositIDs: [...this.DepositIDs],
			Backorder: this.Backorder,
			Delivered: this.Delivered,
			LastDelivered: this.LastDelivered,
			Undeliverable: this.Undeliverable,
			TotalPriceExcVatPerUnit: this.TotalPriceExcVatPerUnit,
			DeliveryDate: this.DeliveryDate ? this.DeliveryDate.toJSON() : null,
			ProductDescriptionStripped: this.ProductDescriptionStripped,
			CreatedByAutoSaleRowSettingID: this.CreatedByAutoSaleRowSettingID
		};
		if (!options) return result;
		if (options.includeProduct && this.Product) {
			result.Product = this.Product.getJSON();
		}
		return result;
	}

	public static newEmptyRow(DefaultQtyOnEmtpyRow:number=1): SaleRow {
		let row = new SaleRow();
		if(DefaultQtyOnEmtpyRow!=1){
			row.Amount = DefaultQtyOnEmtpyRow;
			row.Amount1 = DefaultQtyOnEmtpyRow;
			row.Amount2 = DefaultQtyOnEmtpyRow;
		}
		return row;
	}

	public setAmount(value:number){
		this.Amount = value;
		this.Amount1 = value;
		this.Amount2 = 1.0;
	}
}
