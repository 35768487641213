import { FriendlyNumberConfig } from "./friendly-number-config";

export class JournalConfig {
	public Name:string = "";
	public PurchaseFriendlyNumberConfig: null | FriendlyNumberConfig = null;
	public SellFriendlyNumberConfig:null | FriendlyNumberConfig = null;

	public constructor(data?:any) {
		if (!data) return;
		this.Name = data.Name;
		if (data.PurchaseFriendlyNumberConfig){
			this.PurchaseFriendlyNumberConfig = new FriendlyNumberConfig(data.PurchaseFriendlyNumberConfig);
		}
		if (data.SellFriendlyNumberConfig){
			this.SellFriendlyNumberConfig = new FriendlyNumberConfig(data.SellFriendlyNumberConfig);
		}
	}

	public getJSON(){
		return {
			Name: this.Name,
			PurchaseFriendlyNumberConfig: this.PurchaseFriendlyNumberConfig ? this.PurchaseFriendlyNumberConfig.getJSON() : null,
			SellFriendlyNumberConfig: this.SellFriendlyNumberConfig ? this.SellFriendlyNumberConfig.getJSON() : null
		};
	}
}