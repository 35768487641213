
import { AuthService } from "@/services/auth-service";
import { AuthSocketService } from "@/services/auth-socket-service";
import { MotdService } from "@/services/motd-service";
import Vue from "vue";
export default Vue.extend({
	data(){
		return {
			value: null as null | {[key:string]:string},
			closed: true
		};
	},
	computed: {
		text():string{
			if (!this.value){
				return "";
			}
			return this.value[this.$i18n.locale] || this.value["nl-be"];
		}
	},
	methods: {
		close(){
			this.closed = true;
		}
	},
	async created(){
		this.value = await MotdService.getCurrentMotd();
		if (this.value){
			this.closed = false;
		}
		AuthSocketService.on("motd", (value:{[key:string]:string})=>{
			this.value = value;
			this.closed = false;
		});
		AuthService.on("new-frontend-version", async ()=>{
			let key = "new-frontend-version-warning";
			await AuthService.waitForAppReady();
			if(AuthService.wfCompany.ServicePackExpirationDate != null && AuthService.wfCompany.ServicePackExpirationDate < new Date()){
				key = "new-frontend-expired-warning";
			}

			this.value = {
				"nl-be": this.$ct(key).toString()
			};
			this.closed = false;
		});
	},
	i18nEx: {
		componentPrefix: "components.motd"
	}
});
