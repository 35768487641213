export class LanguagesConfig {
	LanguageNames: string[] = [];

	constructor(data?: any) {
		if (!data) return;
		this.LanguageNames = data.LanguageNames || [];
	}

	getJSON() {
		this.LanguageNames = this.LanguageNames.filter((lang, i) => {
			return this.LanguageNames.indexOf(lang) == i;
		});
		return {
			LanguageNames: this.LanguageNames
		};
	}
}