import { Contact } from "@/models/base";
import {field, filterValueInput, hidden, print} from "./models/decorator-field";
import { model } from "./models/decorator-model";
import { printHumanFloat } from "./pretty-print";
import Currency from "@/models/base/currency";
import {CurrencyService} from "@/services/currency-service";

@model("ProductSupplier")
export class ProductSupplier {
	@field("number")
	public ID:number = 0;
	@field("number")
	@filterValueInput("ContactSupplier")
	public ContactID: number = 0;
	@field("number")
	@print(val=>printHumanFloat(val))
	public NettoPrice: number = 0.0;
	@field("number")
	@print(val=>printHumanFloat(val))
	public CatalogPrice: number = 0.0;
	@field("string")
	public Brand: string = "";
	@field("string")
	public ManufacturerCode: string = "";
	@field("string")
	public SupplierCode: string = "";
	@field("boolean")
	public IsMainSupplier: boolean = false;
	@field("number")
	public MinOrderQty: number = 0;

	@field("has-one", "Contact")
	public Contact:Contact | null = null;

	@field("number")
	@hidden()
	public CurrencyID: number = 1;

	@field("number")
	public CurrencyRate: number = 1;

	@field("has-one", "Currency")
	public Currency: Currency | null = new Currency();

	constructor(data: any = null) {
		if (!data) {
			return;
		}
		this.ID = data.ID;
		this.ContactID = data.ContactID;
		this.NettoPrice = data.NettoPrice;
		this.CatalogPrice = data.CatalogPrice;
		this.ManufacturerCode = data.ManufacturerCode;
		this.SupplierCode = data.SupplierCode;
		this.Brand = data.Brand;
		this.IsMainSupplier = data.IsMainSupplier;
		this.MinOrderQty = data.MinOrderQty || 0;
		this.CurrencyID = data.CurrencyID && data.CurrencyID != 0 ? data.CurrencyID : 1;
		this.CurrencyRate = data.CurrencyRate && data.CurrencyRate != 0 ? data.CurrencyRate : 1;
		if (data.Contact) {
			this.Contact = new Contact(data.Contact);
		}
	}

	public getJSON(): any {
		return {
			ID: this.ID,
			ContactID: this.ContactID,
			NettoPrice: this.NettoPrice,
			CatalogPrice: this.CatalogPrice,
			Brand: this.Brand,
			ManufacturerCode: this.ManufacturerCode,
			SupplierCode: this.SupplierCode,
			IsMainSupplier: this.IsMainSupplier,
			MinOrderQty: this.MinOrderQty,
			CurrencyID: this.CurrencyID,
			CurrencyRate: this.CurrencyRate
		};
	}

	public getSupplierName():string {
		if (this.Contact) {
			return this.Contact.getDisplayName();
		}
		return "";
	}

	get getCurrencyRate(): number {
		let currencies = CurrencyService.getCurrencies();
		if (currencies.length == 0){
			return 1;
		}
		let foundCurrency = currencies.filter((c:Currency) => c.ID == this.CurrencyID)[0];
		if (!foundCurrency) return 1;
		return foundCurrency.Rate || 1;
	}
}
