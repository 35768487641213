import Contact from "../base/contact";
import CrmStatus from "@/models/crm/crm-status";
import { computed, dependencies, field, filterValueInput, print } from "@/utils/models/decorator-field";
import { printLocalDate,printLocalDateTime } from "@/utils/pretty-print";
import { ignoredFields, model } from "@/utils/models/decorator-model";
import CrmHistory from "@/models/crm/crm-history";
import { DossierService } from "@/services/dossier-service";
import { CrmAssignedUser } from "./crm-assigned-users";
import { AuthService } from "@/services/auth-service";
import { i18n } from "@/setup/i18n-setup";

@model("Crm")
@ignoredFields(["FriendlyID", "Contact.MainAddress.Contact"])

export default class Crm {

	@field("number")
	public ID: number = 0;

	@field("number")
	public ContactID: number = 0;

	@field("has-one", "Contact")
	public Contact: Contact | null = null;

	@field("has-one", "CrmStatus")
	@print((value: CrmStatus | null): string => {
		return value?.Name || "";
	})
	public Status: CrmStatus | null = null;


	@field("number")
	@print((value:number):string => {
		return i18n.t("components.crm.priorities."+value).toString() || "";
	})
	public Priority: number = 2;

	@field("string")
	@computed()
	public get PriorityColors(): string {

		let colors = {1: "#41c391",2: "orange",3: "#e53a23"};
		return "<span style='margin: 0px auto; display:block; width:20px;height: 20px; border-radius: 2px; background-color: "
			+ (colors as any)[this.Priority]
			+ "'></span>";
	}

	@field("number")
	@filterValueInput("CrmStatus")
	public StatusID: number = 1;

	@field("string")
	public Description: string = "";

	@field("date")
	@print(printLocalDate)
	public TimeStamp: Date = new Date();

	@field("date")
	@print((value:Date|null):string => {
		if (!value){
			return i18n.t("common.none").toString();
		}
		return printLocalDateTime(value);
	})
	public TimeSheduled: Date | null = null;

	@field("has-many", "CrmHistory")
	public History: CrmHistory[] = [];

	@field("has-many", "CrmAssignedUser")
	public AssignedUsers: CrmAssignedUser[] = [];

	@field("number")
	public CreatorID: number = 0;

	@field("string")
	@computed()
	public get Creator(): string {
		let user = DossierService.getUser(this.CreatorID);
		return user.getUserString();
	}

	@field("string")
	@computed()
	@dependencies(["AssignedUsers"])
	public get AssignedList(): string {
		return this.AssignedUsers.map(a => {
			let user = DossierService.getUser(a.UserID);
			let c = a.UserID == AuthService.wfUser.ID ? "me" : "";
			return `<span class='in `+c+`'>` + user.getUserInitials() +
					`<div class="w-tooltip">`+user.getUserFullName()+`</div></span>`;
		}).join("");
	}


	constructor(data?: any) {
		if (!data) return;

		this.ID = data.ID;
		this.ContactID = data.ContactID;
		this.Priority = data.Priority;
		this.Description = data.Description;
		this.TimeStamp = new Date(data.TimeStamp);
		this.TimeSheduled = data.TimeSheduled ? new Date(data.TimeSheduled) : null;
		this.StatusID = data.StatusID;
		this.CreatorID = data.CreatorID;
		if (data.Contact) {
			this.Contact = new Contact(data.Contact);
		}
		if (data.Status) {
			this.Status = new CrmStatus(data.Status);
		}
		if (data.History) {
			this.History = data.History.reverse().map((h: any) => new CrmHistory(h));
		}
		if (data.AssignedUsers) {
			this.AssignedUsers = data.AssignedUsers.map((u: any) => new CrmAssignedUser(u));
		}

	}


	getJSON() {
		return {
			ID: this.ID,
			ContactID: this.ContactID,
			Priority: this.Priority,
			Description: this.Description,
			TimeStamp: this.TimeStamp ? this.TimeStamp.toJSON() : null,
			TimeSheduled: this.TimeSheduled ? this.TimeSheduled.toJSON() : null,
			StatusID: this.StatusID,
			CreatorID: this.CreatorID,
			Status: this.Status,
			Contact: this.Contact,
			History: this.History.map(h => h.getJSON()),
			AssignedUsers: this.AssignedUsers.map(u => u.getJSON())
		};
	}


}