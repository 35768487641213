
import Vue from "vue";
import { getModel, Model,maxGetFieldsDepth } from "../../utils/models/model";
import { ModelPropertyType } from "../../utils/models/model-field";
import { ModelNamedProperty } from "../../utils/models/named-property";
import { IModel } from "../../utils/models/model-interface";
import { ISelectItem } from "winfakt-vue-components";
import { browseFieldPathToFilterFieldPath, filterFieldPathToBrowseFieldPath } from "@/utils/models/utils";
import { Browse, IColumnNameOverride } from "@/models/browse";
import AppColumnSelectEditNodeItem from "./ColumnSelectEditNodeItem.vue";
import { BrowseFavouriteColumns } from "@/models/browse-favourite-columns";
import { IProperty } from "@/utils/models/property";

interface IHasOneProperty {
	name: string;
	path: string;
	fullName: string;
	model: Model;
}

export default Vue.extend({
	name: "AppColumnSelectEditNode",
	data(){
		return {
			open: false
		};
	},
	props: {
		model: {
			type: Object as () => IModel,
			required: true
		},
		fullPath: {
			type: String,
			default: ""
		},
		ignoredPaths: {
			type: Array as () => string[],
			default: () => []
		},
		name: {
			type: String,
			default: ""
		},
		depth: {
			type: Number,
			default: 0
		},
		filterText: {
			type: String,
			default: ""
		},
		fullName: {
			type: String,
			default: ""
		},
		filterableOnly: {
			type: Boolean
		},
		browse: Object as ()=>Browse,
		selectedValue: {
			type: String,
			default: ""
		},
		isServerColumn: Boolean,
		nummericOnly: Boolean,
		favourites: Object as ()=>BrowseFavouriteColumns,
		showFavourites: Boolean,
		canFavourite: Boolean,
		previewValue: {
			type: Object as any
		},
		isInitialRender: Boolean
	},
	computed: {
		openIcon():string{
			if (this.open){
				return "minus";
			}
			return "plus";
		},
		browseFullPath():string{
			if (!this.filterableOnly){
				return this.fullPath;
			}
			return filterFieldPathToBrowseFieldPath(this.fullPath);
		},
		showTitle():boolean{
			if (!this.name) return false;
			if (this.showFavourites){
				return this.hasDirectFavourites;
			}
			return true;
		},
		hasFavourites():boolean{
			if (!this.favourites) return false;
			if (this.browseFullPath == ""){
				return this.favourites.Columns.length>0;
			}
			let previewPath = this.browseFullPath + ".";
			for (let fav of this.favourites.Columns){
				if (fav.Path.startsWith(previewPath)){
					return true;
				}
			}
			return false;
		},
		hasDirectFavourites():boolean{
			if (!this.favourites) return false;
			for (let fav of this.favourites.Columns){
				if (fav.ParentPath == this.browseFullPath){
					return true;
				}
			}
			return false;
		},
		hideCompletly():boolean {
			return this.showFavourites && !this.hasFavourites;
		},
		namedFilters():ISelectItem<string>[] {
			if (!this.filterableOnly) return [];
			if (this.nummericOnly) return [];
			if (this.depth > 0) return [];

			let filters = this.model.getAvailableNamedFilters();
			return filters.map(f=>{
				return {
					text: f.getDisplayName(this.model),
					value: f.name
				};
			}).filter(f => {
				for (let path of this.ignoredPaths) {
					if (f.value.startsWith(path)) {
						return false;
					}
				}
				return true;
			});
		},
		fields(): IProperty[] {
			let filteredTypes = ["has-one", "has-many"] as ModelPropertyType[];
			let fields = this.model
				.getTopLevelFields()
				.filter(f =>{
					return filteredTypes.indexOf(f.field.type) == -1;
				});
			if (!this.filterableOnly){
				fields = fields.filter(f=>!f.field.filterOnly);
			}
			if (this.filterableOnly || this.isServerColumn) {
				fields = fields.filter(f=>{
					if (f.field.isComputed || f.field.notFilterable) {
						return false;
					}
					return true;
				});
			}

			if (this.nummericOnly){
				fields = fields.filter(f=>f.field.type == "number");
			}

			return fields.map(f => {
				let path = this.getPathForField(f);
				return {
					name: this.getTranslatedFieldName(f),
					path,
					active: path == this.selectedValue,
					print: f.field.print
				} as IProperty;
			}).filter(f => {
				for (let path of this.ignoredPaths) {
					if (f.path.startsWith(path)) {
						return false;
					}
				}
				return true;
			});
		},
		subFields(): IHasOneProperty[] {
			if (this.depth > maxGetFieldsDepth-1) {
				return [];
			}
			let fields = this.model.getTopLevelFields().filter(f => f.field.type == "has-one");
			return fields.map(f => {
				let name = this.getTranslatedFieldName(f);
				return {
					name,
					path: this.getPathForField(f),
					model: getModel(f.field.modelName as string),
					fullName: this.fullName ? this.fullName + " / " + name : name
				} as IHasOneProperty;
			}).filter(f => {
				for (let path of this.ignoredPaths) {
					if (this.filterableOnly){
						path = browseFieldPathToFilterFieldPath(path).replace(".", "_");
					}
					if (f.path.startsWith(path)) {
						return false;
					}
				}
				return true;
			});
		},
		columnNameOverrides():IColumnNameOverride[] {
			if (this.browse != null) {
				return this.browse.getColumnNameOverrides();
			}
			return [];
		},
	},
	watch: {
		showFavourites(){
			if (this.showFavourites){
				this.open = true;
				return;
			}
			let selectedValueAsBrowsePath = this.selectedValue;
			if (this.filterableOnly){
				selectedValueAsBrowsePath = filterFieldPathToBrowseFieldPath(selectedValueAsBrowsePath);
			}
			if (selectedValueAsBrowsePath.startsWith(this.browseFullPath + ".")){
				this.open = true;
				return;
			}
			if (this.fullPath != "" && this.fullPath != "this"){
				this.open = false;
				return;
			}
		}
	},
	methods: {
		getTranslatedFieldName(field: ModelNamedProperty): string {
			let path = this.getPathForField(field);
			if (this.filterableOnly){
				path = filterFieldPathToBrowseFieldPath(path);
			}
			for (let columnNameOverride of this.columnNameOverrides) {
				if (columnNameOverride.key == path) {
					return columnNameOverride.label;
				}
			}
			return field.getPropertyTranslation();
		},
		getPathForField(field: ModelNamedProperty): string {
			if (this.fullPath == "") {
				return field.getPropertyName();
			}
			if (this.filterableOnly){
				if (field.field.type == "has-one"){
					return `${this.fullPath}_${field.getPropertyName()}`;
				}
				return `${this.fullPath}.${field.getPropertyName()}`;
			}
			return `${this.fullPath}.${field.getPropertyName()}`;
		},
		onField(path:string){
			this.$emit("input", path);
		},
		onName(name: string) {
			this.$emit("name", name);
		},
		onFieldClick(path: string, name: string) {
			this.onField(path);
			this.onName(name);
		},
		isNamedFilterActive(name:string):boolean{
			return this.selectedValue == name;
		},
	},
	created(){
		if (this.fullPath == "" || this.fullPath == "this" || this.showFavourites){
			this.open = true;
		}
	},
	mounted(){
		if (this.isInitialRender && this.selectedValue.startsWith(this.fullPath)){
			this.open = true;
		}
	},
	components: {
		AppColumnSelectEditNodeItem
	}
});
