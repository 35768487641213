import { model } from "@/utils/models/decorator-model";
import { field } from "@/utils/models/decorator-field";

@model("CrmHistoryCall")

export default class CrmHistoryCall {

	@field("string")
	public Message: string = "";

	@field("string")
	public Phone: string = "";

	@field("string")
	public Contact: string = "";

	constructor(data?: any) {
		if (!data) return;
		this.Message = data.Message;
		this.Phone = data.Phone;
		this.Contact = data.Contact;
	}


	getJSON() {
		return {
			Message: this.Message,
			Phone: this.Phone,
			Contact: this.Contact,
		};
	}
}
