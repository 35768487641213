
import Vue from "vue";
import { IModel } from "../../utils/models/model-interface";
import AppColumnSelect from "@/components/models/ColumnSelect.vue";
import { OrderColumn } from "../../utils/report-engine-settings/order-column";
import { Browse } from "@/models/browse";

export default Vue.extend({
	data(){
		return {
			sorting: new OrderColumn()
		};
	},
	props: {
		value: {
			type: Object as ()=>OrderColumn,
			required: true
		},
		model: {
			type: Object as ()=>IModel,
			required: true
		},
		browse: {
			type: Object as ()=>Browse
		}
	},
	watch: {
		value: {
			immediate: true,
			deep: true,
			handler(){
				this.sorting = new OrderColumn(this.value.getJSON());
			}
		}
	},
	methods: {
		save(){
			this.$emit("input", this.sorting);
		},
		cancel(){
			this.$emit("close");
		},
	},
	components: {
		AppColumnSelect
	},
	i18nEx: {
		componentPrefix: "components.reportengine"
	}
});
