import { Price } from "./price";

export enum PriceFrequencyType {
	QUARTER = 0,
	YEAR = 1,
	PERMANENT = 2,
	DEMO = 3,
}

export class Pricing {
	yearInterval: boolean = false;
	lifeTime: boolean = false;
	discount: number = 0;
	customerDiscount: number = 0;
	price: Price = new Price();
	permanentPrice: Price = new Price();
	pricePerUser: number = 0;
	permanentPricePerUser: number = 0;
	pricePerDossier: number = 0;
	permanentPricePerDossier: number = 0;

	constructor(data?: any) {
		if (!data) return;
		this.discount = data.discount;
		this.customerDiscount = data.customerDiscount;
		this.price = new Price(data.price);
		this.permanentPrice = new Price(data.permanentPrice);
		this.yearInterval = data.yearInterval;
		this.lifeTime = data.lifeTime;
		this.pricePerUser = data.pricePerUser;
		this.permanentPricePerUser = data.permanentPricePerUser;
		this.pricePerDossier = data.pricePerDossier;
		this.permanentPricePerDossier = data.permanentPricePerDossier;
	}

	getDiscountPrice(): Price {

		let d = this.yearInterval ? (1 - (this.discount / 100)) : 1;

		return new Price({
			"tier0": Math.floor(this.price.tier0 * d),
			"tier1": Math.floor(this.price.tier1 * d),
			"tier2": Math.floor(this.price.tier2 * d),
		});

	}

	getPermanentPrice(): Price {


		return new Price({
			"tier0": Math.floor(this.permanentPrice.tier0),
			"tier1": Math.floor(this.permanentPrice.tier1),
			"tier2": Math.floor(this.permanentPrice.tier2),
		});

	}

}
