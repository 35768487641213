import { ResultDataAddedFromPreviousDeclarations } from "./result-data-added-from-previous-declarations";

export class VatDeclarationResultData {
	Month:number = 0.0;
	Quarter:number = 0.0;
	Year:number = 0.0;
	GridValues:{[key:string]:string}
	ToAddGrid56:number = 0.0;
	ToAddGrid57:number = 0.0;

	Grid56:number = 0.0;
	Grid57:number = 0.0;
	Grid81:number = 0.0;
	Grid82:number = 0.0;
	Grid83:number = 0.0;
	Grid86:number = 0.0;
	Grid87:number = 0.0;
	Grid88:number = 0.0;

	DateCreated:Date = new Date();

	AddedFromPreviousDeclarations:ResultDataAddedFromPreviousDeclarations = new ResultDataAddedFromPreviousDeclarations();

	public constructor(data:any) {
		this.Month = data.Month;
		this.Quarter = data.Quarter;
		this.Year = data.Year;
		this.GridValues = data.GridValues;
		this.Grid56 = data.Grid56;
		this.Grid57 = data.Grid57;
		this.Grid81 = data.Grid81;
		this.Grid82 = data.Grid82;
		this.Grid83 = data.Grid83;
		this.Grid86 = data.Grid86;
		this.Grid87 = data.Grid87;
		this.Grid88 = data.Grid88;
		this.ToAddGrid56 = data.ToAddGrid56 || 0;
		this.ToAddGrid57 = data.ToAddGrid57 || 0;
		this.AddedFromPreviousDeclarations = new ResultDataAddedFromPreviousDeclarations(data.AddedFromPreviousDeclarations);
		this.DateCreated = new Date(data.DateCreated);
	}

	public getJSON(){
		return {
			Month: this.Month,
			Quarter: this.Quarter,
			Year: this.Year,
			GridValues: this.GridValues,
			DateCreated: this.DateCreated.toJSON()
		};
	}

	public getValue(grid:number):string {
		return this.GridValues[`${grid}`] || "";
	}

	public getNegativeValuesToAddToNextDeclaration():{value:number, grid:number}[] {
		let fields = ResultDataAddedFromPreviousDeclarations.getCoppiedFields();
		return fields.map(f=>{
			return {
				value: this[("Grid"+f) as keyof VatDeclarationResultData] as number,
				grid: f
			};
		}).filter(f=>f.value < 0);
	}

	public getValuesToAddForNextDeclarations():{value:number, grid:number}[] {
		let result:{value:number, grid:number}[] = [];
		if (this.ToAddGrid56 != 0){
			result.push({value: this.ToAddGrid56, grid: 56});
		}
		if (this.ToAddGrid57 != 0){
			result.push({value: this.ToAddGrid57, grid: 57});
		}
		return result;
	}
}