import { ServerConfig } from "@/config/config";
import { IUserConfig, UserConfig } from "@/models/user-config";
import { BrowseSearchSettings } from "@/models/user-configs/browse-searches";
import { CalendarSettings } from "@/models/user-configs/calendar-settings";
import { ChatSettings } from "@/models/user-configs/chat-settings";
import { GeneralUserConfig } from "@/models/user-configs/general-user-config";
import { AuthService } from "@/services/auth-service";
import { Axios } from "@/utils/axios";
import { loadLanguageAsync } from "@/setup/i18n-setup";
import { MainSocketService } from "./main-socket-service";
import { Permissions } from "@/models/user-configs/permissions";
import { SaleSettings } from "@/models/user-configs/sale-settings";
import { PluginUserConfig } from "@/models/user-configs/plugin-config";
import { DashboardSettings } from "@/models/user-configs/dashboard-settings";
import { EventEmitter } from "@/utils/event-emitter";
import {CashierSettings} from "@/models/user-configs/cashier-settings";
import { IInitAppResponse } from "./init-service";
import { ReminderSettings } from "@/models/user-configs/reminder-settings";
import { DashboardTab } from "@/utils/dashboard/dashboard-tab";

const url = ServerConfig.host + "/user-config";
const socketUrl = "/user-config/";

export class UserConfigServiceClass extends EventEmitter{
	private configs:{[key: string]: IUserConfig} = {};

	constructor() {
		super();
		requestAnimationFrame(() => {
			AuthService.on("logout", () => {
				this.configs = {};
			});
			MainSocketService.on(`${socketUrl}update`, (data: any) => {
				let config = new UserConfig(data);
				this.refreshConfigs(config);
				this.emit(`config-update`, config);
			});
		});
	}

	getConfigValue(key:string):any {
		let val = this.configs[key];
		if (val) {
			return val.Value;
		}
		return {};
	}

	getGeneralSettings(): GeneralUserConfig {
		return new GeneralUserConfig(this.getConfigValue("general"));
	}

	getSaleSettings(): SaleSettings {
		return new SaleSettings(this.getConfigValue("sales"));
	}
	getBrowseSearchSettings(): BrowseSearchSettings {
		return new BrowseSearchSettings(this.getConfigValue("browse-search"));
	}

	getCalendarSettings(): CalendarSettings {
		return new CalendarSettings(this.getConfigValue("calendars"));
	}

	getChatSettings(): ChatSettings {
		return new ChatSettings(this.getConfigValue("chat"));
	}

	getPermissionSettings(): Permissions {
		return new Permissions(this.getConfigValue("permissions"));
	}

	getPluginSettings():PluginUserConfig {
		return new PluginUserConfig(this.getConfigValue("plugins"));
	}

	getCashierSettings():CashierSettings {
		return new CashierSettings(this.getConfigValue("cashier"));
	}

	getDashboardSettings():DashboardSettings{
		return new DashboardSettings(this.getConfigValue("dashboard"));
	}

	getReminderSettings():ReminderSettings{
		return new ReminderSettings(this.getConfigValue("reminder-conf"));
	}

	async getConfigs(): Promise<void> {
		let result = await Axios.get(`${url}`, {params: {limit: 40}});
		let configs = result.data.data.map((d: any) => new UserConfig(d)) as IUserConfig[];
		configs.forEach((conf) => {
			this.configs[conf.SettingsKey] = conf;
		});
	}

	async setConfigDataByKey(key: string, data: {getJSON(): any}): Promise<any> {
		let result = await Axios.put(`${url}/key/${key}`, data.getJSON());
		return result.data;
	}

	async setGeneralSettings(settings: GeneralUserConfig): Promise<GeneralUserConfig> {
		this.configs.general.Value = await this.setConfigDataByKey("general", settings);
		this.refreshConfigs();
		return this.getGeneralSettings();
	}

	async setCashierSettings(settings: CashierSettings): Promise<CashierSettings> {
		this.configs.cashier.Value = await this.setConfigDataByKey(`cashier`, settings);
		this.refreshConfigs();
		return this.getCashierSettings();
	}

	async setBrowseSearchSettings(settings: BrowseSearchSettings): Promise<BrowseSearchSettings> {
		this.configs["browse-search"].Value = await this.setConfigDataByKey(`browse-search`, settings);
		this.refreshConfigs();
		return this.getBrowseSearchSettings();
	}

	async setCalendarSettings(settings:CalendarSettings):Promise<CalendarSettings> {
		this.configs.calendars.Value = await this.setConfigDataByKey(`calendars`, settings);
		this.refreshConfigs();
		return this.getCalendarSettings();
	}

	async setChatSettings(settings:ChatSettings):Promise<ChatSettings> {
		this.configs.chat.Value = await this.setConfigDataByKey(`chat`, settings);
		this.refreshConfigs();
		return this.getChatSettings();
	}

	async setPluginSettings(settings:PluginUserConfig):Promise<PluginUserConfig> {
		this.configs.plugins.Value = await this.setConfigDataByKey(`plugins`, settings);
		this.refreshConfigs();
		return this.getPluginSettings();
	}

	async setDashboardSettings(settings:DashboardSettings):Promise<DashboardSettings>{
		this.configs.dashboard.Value = await this.setConfigDataByKey(`dashboard`, settings);
		this.refreshConfigs();
		return this.getDashboardSettings();
	}

	async setSaleSettings(settings:SaleSettings):Promise<SaleSettings> {
		this.configs.sales.Value = await this.setConfigDataByKey("sales", settings);
		this.refreshConfigs();
		return this.getSaleSettings();
	}

	async setReminderSettings(settings:ReminderSettings):Promise<ReminderSettings>{
		this.configs["reminder-conf"].Value = await this.setConfigDataByKey("reminder-conf", settings);
		this.refreshConfigs();
		return this.getReminderSettings();
	}

	refreshConfigs(newConfig?: IUserConfig) {
		let newConf = {} as { [key: string]: IUserConfig };
		for (let key of Object.keys(this.configs)) {
			newConf[key] = this.configs[key];
		}
		if (newConfig) {
			newConf[newConfig.SettingsKey] = newConfig;
		}
		this.configs = newConf;
		this.emit("configs-updated");
	}

	async init(data:IInitAppResponse) {
		data.UserConfigs.forEach((conf) => {
			this.configs[conf.SettingsKey] = conf;
		});
		loadLanguageAsync(this.getGeneralSettings().SelectedLang);
	}

	async getUsersPermissions(userId:number):Promise<Permissions>{
		let result = await Axios.get(`${url}/permissions/${userId}`);
		return new Permissions(result.data);
	}

	async setUsersPermissions(userId:number, permissions:Permissions){
		let result = await Axios.put(`${url}/permissions/${userId}`, permissions.getJSON());
		return new Permissions(result.data);
	}

	hasCurrentUserPermission(permissionCheckFunc:(permissions:Permissions)=>boolean):boolean {
		if (AuthService.wfCompany && AuthService.wfUser){
			if (AuthService.wfUser.ID == AuthService.wfCompany.OwnerID){
				return true;
			}
		}
		if (this.getPermissionSettings().IsAdmin){
			return true;
		}
		return permissionCheckFunc(this.getPermissionSettings());
	}

	async setDashboardLastOpenedTabIndex(index:number):Promise<void>{
		await Axios.post(`${url}/dashboard/set-last-opened-tab`, {Index: index});
	}

	async addDashboardTabsToUsers(tabs:DashboardTab[], userIds:number[]):Promise<void>{
		await Axios.post(`${url}/dashboard/add-to-users`, {Tabs: tabs.map(t=>t.getJSON()), UserIDs: userIds});
	}

	async setDashboardOfUsers(settings:DashboardSettings, userIds:number[]):Promise<void>{
		await Axios.post(`${url}/dashboard/set-to-users`, {Conf: settings.getJSON(), UserIDs: userIds});
	}
};

export const UserConfigService = new UserConfigServiceClass();