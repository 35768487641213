export class SendcloudConfig{
	PublicKey:string = "";
	PrivateKey:string = "";

	public constructor(data?:any){
		this.PublicKey = data.PublicKey;
		this.PrivateKey = data.PrivateKey;
	}

	public getJSON(){
		return{
			PublicKey: this.PublicKey,
			PrivateKey: this.PrivateKey
		};
	}
}