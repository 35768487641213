
import Vue from "vue";
import { AllContactsService } from "@/services/all-contacts-service";
import { Contact } from "@/models/base";
import { HumanFilter } from "@/utils/human-filter";

export default Vue.extend({
	data() {
		return {
			foundContacts: [] as Contact[],
			input: "",
		};
	},
	props: {
		value: Object as () => Contact,
		groupId: {
			type: [Number, Array as () => number[]],
		},
		fancy: Boolean,
		label: [String, Object],
		clearOnEnter: Boolean,
		disabled: Boolean,
		icon: String,
		autoFocus: Boolean
	},
	computed: {
		computedGroupIds(): number[] {
			if (typeof (this.groupId) == "object") {
				return this.groupId;
			}
			return [this.groupId];
		}
	},
	watch: {
		async input(input: string) {
			if (input.match(/^\s*$/)) {
				this.foundContacts = [];
				return;
			}
			this.foundContacts = await AllContactsService.searchByName(this.input, false, this.getGroupExtraFilters());
		},
		value: {
			immediate: true,
			handler(contact?: Contact) {
				if (this.value) {
					this.input = this.value.getDisplayName();
				} else {
					this.input = "";
				}
			}
		}
	},
	methods: {
		getGroupExtraFilters():HumanFilter[]{
			if (this.groupId == null) {
				return [];
			}
			if (typeof(this.groupId) == "number"){
				return [
					new HumanFilter({
						Field: "this.GroupID",
						Operator: "=",
						Values: [`${this.groupId}`]
					}),
				];
			}
			return [
				new HumanFilter({
					Field: "this.GroupID",
					Operator: "in",
					Values: this.groupId.map(g=>`${g}`)
				})
			];
		},
		onBlur() {
			this.foundContacts = [];
			if (this.value) {
				if (this.value.MainAddress) {
					this.input = this.value.getDisplayName();
				}
			}else{
				this.input = "";
			}
			this.$emit("blur");
		},
		onFocus() {
			this.input = "";
		},
		selectContact(contact: Contact) {

			this.$emit("input", contact);
			if (this.clearOnEnter) {
				this.input = "";
			}
			this.blur();
		},
		focus() {
			(this.$refs.input as any).focus();
		},
		blur() {
			(this.$refs.input as any).blur();
		},
		searchContacts() {
			this.$emit("searchContacts");
		}
	},
	mounted() {
		if (this.autoFocus) {
			this.focus();
		}
	},
	i18nEx: {
		componentPrefix: "components.contact-search"
	}
});
