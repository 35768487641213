import Contact from "./contact";
import { computed, field, filterValueInput, hidden, nullable, print } from "@/utils/models/decorator-field";
import { ignoredFields, priorityFields, model } from "@/utils/models/decorator-model";
import { printBoolean } from "@/utils/pretty-print";
import { DossierConfigService } from "@/services/dossier-config-service";
import { AuthService } from "@/services/auth-service";
import { getExtraFieldKeyNames } from "@/utils/models/extra-fields";
import { i18n } from "@/setup/i18n-setup";
import { CountryIndex } from "@/utils/country";

@model("ContactAddress")
@ignoredFields(["Contact.MainAddress", "Contact.Addresses"])
@priorityFields([
	"FirstName",
	"LastName",
	"FullName",
	"FullNameReverse",
	"CompanyOrFullName",
	"CompanyName",
	"Email",
	"AddressLine1",
	"AddressLine2",
	"City",
	"Postal",
	"PostalAndCity",
	"Reference",
	"Phone",
	"Mobile",
	"Fax",
] as Array<keyof ContactAddress>)
export default class ContactAddress{
	@field("number")
	public ID:number = 0;

	@field("number")
	@hidden()
	public ContactID:number = 0;

	@field("string")
	public Label:string = ""

	@field("string")
	public FirstName:string = "";

	@field("string")
	public LastName:string = "";

	@field("string")
	@nullable()
	public CompanyName:string | null = null;

	@field("has-one", "Contact")
	@nullable()
	public Contact:Contact | null = null;

	@field("string")
	public AddressLine1:string = "";

	@field("string")
	public AddressLine2:string = "";

	@field("string")
	public Postal:string = "";

	@field("string")
	public City:string = "";

	@field("string")
	public Email:string = "";

	@field("string")
	public Phone:string = "";

	@field("string")
	public Mobile:string = "";

	@field("string")
	public Fax:string = "";

	@field("string")
	@filterValueInput("CountryCode")
	public CountryCode:string = "BE";

	@field("string")
	@computed()
	public get CountryName():string{
		return CountryIndex.getCountryName(this.CountryCode);
	}

	@field("string")
	public Lng:string = "nl-be";

	@field("string")
	public FullName:string = "";

	@field("string")
	public FullNameReverse:string = "";

	@field("string")
	public CompanyOrFullName:string = "";

	@field("string")
	public PostalAndCity:string = "";

	@field("string")
	public Reference:string = "";

	@field("boolean")
	@print(printBoolean)
	public IsMainAddress:boolean = false;

	@field("string")
	@computed()
	public get Name():string{
		if (this.Label.trim()) {
			return this.Label;
		}
		if (this.CompanyName && this.FullNameReverse){
			return `${this.CompanyName} (${this.FullNameReverse})`;
		}
		return this.CompanyName || this.FullNameReverse;
	}

	public constructor(data?:any){
		if (!data) {
			if (AuthService.isLoggedIn){
				try {
					this.CountryCode = DossierConfigService.getContactsConfig().DefaultCountry || "BE";
				}catch (e) {

				}
			}
			return;
		}
		this.ID = data.ID;
		this.ContactID = data.ContactID;
		this.Label = data.Label;
		this.FirstName = data.FirstName;
		this.LastName = data.LastName;
		this.CompanyName = data.CompanyName;
		this.Contact = data.Contact;
		this.AddressLine1 = data.AddressLine1;
		this.AddressLine2 = data.AddressLine2;
		this.Postal = data.Postal;
		this.City = data.City;
		this.Email = data.Email;
		this.Phone = data.Phone;
		this.Mobile = data.Mobile;
		this.Fax = data.Fax;
		this.FullName = data.FullName;
		this.FullNameReverse = data.FullNameReverse;
		this.CompanyOrFullName = data.CompanyOrFullName;
		this.PostalAndCity = data.PostalAndCity;
		this.Reference = data.Reference;
		this.Lng = data.Lng;
		this.CountryCode = data.CountryCode;
		this.IsMainAddress = data.IsMainAddress;

		if (data.Contact) {
			this.Contact = new Contact(data.Contact);
		}
	}

	getJSON() {
		return {
			ID: this.ID,
			ContactID: this.ContactID,
			Label: this.Label,
			FirstName: this.FirstName,
			LastName: this.LastName,
			CompanyName: this.CompanyName,
			Contact: this.Contact,
			AddressLine1: this.AddressLine1,
			AddressLine2: this.AddressLine2,
			Postal: this.Postal,
			City: this.City,
			Email: this.Email,
			Phone: this.Phone,
			Mobile: this.Mobile,
			Fax: this.Fax,
			Lng: this.Lng,
			CountryCode: this.CountryCode,
			Reference: this.Reference,
			IsMainAddress: this.IsMainAddress,
		};
	}

	toAddressString(): string {
		return `${this.AddressLine1} ${this.Postal} ${this.City}`;
	}

	toFullAddressString(): string {
		return `${this.Name} ${this.toAddressString()}`;
	}

	public getDisplayedName():string{
		if (this.Label) return this.Label;
		return this.CompanyOrFullName;
	}

	private static getMapFieldPropNamesWithExtraFields():string[]{
		return  [
			"ID",
			"NR",
			"FirstName",
			"LastName",
			"FullName",
			"FullNameReverse",
			"CompanyOrFullName",
			"CompanyOrLastName",
			"PostalAndCity",
			"Reference",
			"CompanyName",
			"AddressLine1",
			"AddressLine2",
			"Number",
			"Box",
			"Postal",
			"City",
			"Email",
			"Phone",
			"Mobile",
			"Fax",
			"Lng",
			"CountryCode",
			"CountryName",
			"CommercialVat",
			"Vat"
		];
	}

	public static getMapFields(contactGroupId:number = 0):{prop:string, name:string}[]{
		let result:{prop:string, name:string}[] = ContactAddress.getMapFieldPropNamesWithExtraFields().map(prop=>{
			return {prop, name: i18n.t(`models.map.ContactAddress.${prop}`).toString()};
		});
		if (contactGroupId){
			let conf = DossierConfigService.getExtraFieldNamesConfigs().getContactNamesByGroupID(contactGroupId);
			for (let field of conf.getActiveFields()){
				result.push({prop: `ExtraField${field}`, name: conf[field] as string});
			}
		}else {
			let items = [
				...getExtraFieldKeyNames("Num").map(key=>`ExtraField${key}`),
				...getExtraFieldKeyNames("Text").map(key=>`ExtraField${key}`),
				...getExtraFieldKeyNames("Bool").map(key=>`ExtraField${key}`),
				...getExtraFieldKeyNames("Time").map(key=>`ExtraField${key}`),
			].map(prop=>{
				return {prop, name: i18n.t(`models.map.ContactAddress.${prop}`).toString()};
			});
			result.push(...items);
		}
		return result;
	}
}

