
import Vue from "vue";
import { DocumentPageSettings } from "../../utils/documents/page-settings";
import AppPageSettingsBackgroundImage from "../document-settings/PageSettingsBackgroundImage.vue";
import AppPageSettingsDefault from "../document-settings/PageSettingsDefault.vue";
import AppPageSettingsFont from "../document-settings/PageSettingsFont.vue";
import AppSideBySideSettings from "./SideBySideSettings.vue";

export default Vue.extend({
	props: {
		value: {
			type: Object as () => DocumentPageSettings,
			required: true
		}
	},
	components: {
		AppPageSettingsBackgroundImage,
		AppPageSettingsDefault,
		AppPageSettingsFont,
		AppSideBySideSettings
	},
	i18nEx: {
		componentPrefix: "components.reportengine"
	}
});
