import { i18n } from "@/setup/i18n-setup";
import { CustomTranslation } from "@/utils/custom-translation";
import { IEmailTemplate } from "@/utils/email-template";
import { GoTemplateDataLink } from "@/utils/go-template-data-structure/link";
import { GoTemplateDataObject } from "@/utils/go-template-data-structure/object";
import { GoTemplateDataRoot } from "@/utils/go-template-data-structure/root";
import { GoTemplateDataString } from "@/utils/go-template-data-structure/string";

export class ClientPortalEmailTemplate implements IEmailTemplate{
	Name    :string = "New template"
	Subject :CustomTranslation = new CustomTranslation();
	Content :CustomTranslation = new CustomTranslation();
	IsHTML  :boolean = true;

	public constructor(data?:any){
		if (!data) return;
		this.Name = data.Name;
		this.Subject = new CustomTranslation(data.Subject);
		this.Content = new CustomTranslation(data.Content);
		this.IsHTML = data.IsHTML;
	}

	getSubjectData(): GoTemplateDataRoot {
		let t = (val:string)=>i18n.t(val);
		return new GoTemplateDataRoot([
			new GoTemplateDataString("ClientPortalUrl", t("models.email-templates.client-portal.root.ClientPortalUrl")),
			new GoTemplateDataLink("ClientPortalUrl", t("models.email-templates.sale.root.ClientPortalUrlDifferentText"), t("models.email-templates.sale.root.ClientPortalUrl")),
			new GoTemplateDataObject("Contact", [
				new GoTemplateDataString("Nr", t("models.email-templates.client-portal.contact.Nr")),
				new GoTemplateDataString("Info", t("models.email-templates.client-portal.contact.Info")),
				new GoTemplateDataString("CompanyName", t("models.email-templates.client-portal.contact.CompanyName")),
				new GoTemplateDataString("FirstName", t("models.email-templates.client-portal.contact.FirstName")),
				new GoTemplateDataString("LastName", t("models.email-templates.client-portal.contact.LastName")),
				new GoTemplateDataString("AddressLine1", t("models.email-templates.client-portal.contact.AddressLine1")),
				new GoTemplateDataString("AddressLine2", t("models.email-templates.client-portal.contact.AddressLine2")),
				new GoTemplateDataString("Number", t("models.email-templates.client-portal.contact.Number")),
				new GoTemplateDataString("Box", t("models.email-templates.client-portal.contact.Box")),
				new GoTemplateDataString("Postal", t("models.email-templates.client-portal.contact.Postal")),
				new GoTemplateDataString("Email", t("models.email-templates.client-portal.contact.Email")),
				new GoTemplateDataString("Phone", t("models.email-templates.client-portal.contact.Phone")),
				new GoTemplateDataString("Fax", t("models.email-templates.client-portal.contact.Fax")),
				new GoTemplateDataString("City", t("models.email-templates.client-portal.contact.City")),
				new GoTemplateDataString("CountryCode", t("models.email-templates.client-portal.contact.CountryCode")),
				new GoTemplateDataString("FullName", t("models.email-templates.client-portal.contact.FullName")),
				new GoTemplateDataString("FullNameReverse", t("models.email-templates.client-portal.contact.FullNameReverse")),
				new GoTemplateDataString("CompanyOrFullName", t("models.email-templates.client-portal.contact.CompanyOrFullName"))
			])
		]);
	}

	getContentData(): GoTemplateDataRoot {
		return this.getSubjectData();
	}


	public getJSON(){
		return {
			Name: this.Name,
			Subject: this.Subject.getJSON(),
			Content: this.Content.getJSON(),
			IsHTML: this.IsHTML
		};
	}
}